import React from 'react';
import {
	Router,
	Redirect
} from "react-router-dom";
import { connect } from "react-redux";
import Header from './Header';
import Sidebar from './Sidebar';
import ContentRouter from './ContentRouter';
import { history } from '../index';
import LayoutTost from "./LayoutTost";
import WelcomeModal from "../components/welcome/WelcomeModal";

class Layout extends React.PureComponent {
	constructor(props) {
		super(props);
	}

	render() {
		if (!this.props.user) {
			return <Redirect to="/login" />;
		}

		return (
			<Router history={history}>
    			<div id="wrapper">
					<Sidebar/>
						
			        <div id="content-wrapper" className="d-flex flex-column">
			            <div id="content">
							<Header/>	        
			                <div className="container-fluid">
								<ContentRouter/>
			                </div>
							{this.layoutTost()}
							{this.renderWelcomeCarrierModal()}
	            		</div>
	
			            <footer className="sticky-footer bg-white"></footer>
		    	    </div>
			    </div>
	
			    <a className="scroll-to-top rounded" href="#page-top">
			        <i className="fas fa-angle-up"></i>
			    </a>
			</Router>
		);
	}

	layoutTost() {
		return <LayoutTost/>
	}

	renderWelcomeCarrierModal() {
		return <WelcomeModal/>
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.auth.user,
		storeAll: state
	};
}

export default connect(mapStateToProps)(Layout);
