import React from "react";

class Loading extends React.PureComponent {
	constructor(props) {
		super(props);
	}

	render() {
		return (<pre>"Загрузка..."</pre>);
	}
}

export default Loading;