import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Form, Row, Col } from "react-bootstrap";

import StoreWrapper from '../../form/StoreWrapper';
import { requiredValidator } from "../../../validators/simpleValidators";
import BaseForm from '../../form/BaseForm';
import FormGroup from '../../form/FormGroup';
import { setData } from '../../../actions/form';
import Loading from '../../Loading';
import FormValidator from '../../../validators/FormValidator';
import dictionaryService from '../../../services/AbstractDictionaryService';

class Dictionary extends StoreWrapper {
	constructor(props) {
		super(props);
	}
	render() {
		return (
			<DictionaryInnerConnected {...this.props} store={this.store} />
		);
	}
}

function mapStateToProps(state) {
	return {
		errors: state.errors,
		model: state.model
	};
}

class DictionaryInner extends BaseForm {
	constructor(props) {
		super(props);
		this.configureValidators();
	}

	configureValidators() {
		this.useValidatorFor(requiredValidator, "value");
		this.validator.addValidator("value", this.valueUniqueValidator.bind(this));
	}

	async valueUniqueValidator() {
		const value = this.props.model.value;
		if (!value) {
			return FormValidator.PROMISE_OK;
		}
		const isValid = await dictionaryService.isValueUnique(this.getId(), value, this.props.requestType);
		return isValid ? FormValidator.OK : "запись с таким названием уже зарегистрирована в системе";
	}

	getId() {
		return this.props.location.state?.id || new URLSearchParams(this.props.location.search).get('id');
	}

	load() {
		const id = this.getId();
		if (id) {
			dictionaryService.read(id, this.props.requestType).then(data => {
				this.props.store.dispatch(setData(data, this.props.location.state?.action));
			});
		}
	}

	handleSubmit(e) {
		e.preventDefault();
		this.submit(() =>
			dictionaryService.save(this.props.model, this.props.requestType).then(
				() => this.props.history.push(this.props.pathname)));
	}

	getTabs() {
		const tabs = [];
		if (this.getId()) {
			tabs.push(this.getAuditTab(this.getId(), this.props.auditType));
		}
		return tabs;
	}

	getFormTabTitle() {
		const name = this.props.model.value || (this.props.model.id > 0 ? this.props.model.id : '');
		return this.props.tabTitle + " " + name;
	}

	renderForm() {
		const isReady = !this.getId() || this.props.model.id;
		if (!isReady) {
			return (<Loading/>);
		}
		return (
			<Form>
				<Row>
					<Col lg={6} md={12}>
						<FormGroup title="Наименование" name="value" store={this.props.store} required />
					</Col>					
				</Row>								
				{this.renderSaveCancelButtons()}
			</Form>
		);
	}
}

const DictionaryInnerConnected = withRouter(connect(mapStateToProps)(DictionaryInner));

export default Dictionary;