import {Alert, Button, Modal} from "react-bootstrap";
import React, {PureComponent} from "react";
import contractService from "../../../services/ContractService";
import "./ConfirmationAgreementForm.css"

class ConfirmationAgreementForm extends PureComponent {

    constructor(props) {
        super(props);
        this.onClickYes = this.onClickYes.bind(this);
        this.onClickNo = this.onClickNo.bind(this);

        this.state = {
            sending: false
        }
    }

    onChange() {
        this.props.onChange("agreement", this.props.contractAgreement);
        this.props.setShowConfirmation(false);
    }

    delete() {
        this.props.onChange("agreement", "");
        this.props.setShowConfirmation(false);
        this.props.onChangeContractor()
    }

    onClickYes() {
        this.props.quoteAgreement === "clear"
            ? this.delete()
            : this.onChange()
    }

    onClickNo() {
        this.props.setShowConfirmation(false)
        this.props.quoteAgreement === "clear" && this.props.onChangeContractor()
    }

    render() {
        const {quoteAgreement} = this.props;
        const isExistsQuoteAgreement =  quoteAgreement === "clear";
        const title = isExistsQuoteAgreement
            ? "Очистить условия при смене заказчика?"
            : "Добавить условия заказчика в заявку?";

        return <Alert className={"confirmationAgreementAlert"}  key={"info"} variant={"info"}>
            {title}
            <div>
                <Button variant="info" size="sm"
                        onClick={this.onClickYes}>Да</Button>
                <Button variant="info" size="sm"
                        onClick={this.onClickNo}>Нет</Button>
            </div>
        </Alert>
    }
}

export default ConfirmationAgreementForm;