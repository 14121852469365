import { API_EXCEPTION_PUSH, API_EXCEPTION_POP } from "../actions/types";

const initialState = { api: [] };

export default function (state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case API_EXCEPTION_PUSH:
			return {
				// show only latest 4 errors to prevent overflow
				api: [...state.api.slice(-3), payload],
			};
		case API_EXCEPTION_POP:
			const apiExceptions = state.api ? state.api.filter(e => e !== payload) : [];
			return {
				api: [...apiExceptions],
			};
		default:
			return state;
	}
}