import BaseForm from "./components/form/BaseForm";
import './SimpleElectronicSignature.css';

class SimpleElectronicSignature extends BaseForm {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="privacy-policy">
                <div className="container">
                    <h2 className="text-center m-4">СОГЛАШЕНИЕ ОБ ИСПОЛЬЗОВАНИИ ПРОСТОЙ ЭЛЕКТРОННОЙ ПОДПИСИ
                        (ПЭП)
                    </h2>
                    <ol style={{display: "flex", flexDirection: "column", gap: "30px"}}>
                        <div><b>Дата размещения:</b> «1» сентября 2024 г.</div>
                        <div align="justify">ООО «АРТТЭК» (ИНН: 2348040119) (далее – «<b>Владелец Платформы</b>») и
                            Пользователь заключили настоящее соглашение об использовании простой электронной подписи
                            (ПЭП) (далее – «<b>Соглашение</b>»), полностью и безоговорочно присоединились к условиям
                            Соглашения путем совершения конклюдентных действий по Регистрации на Платформе на следующих
                            условиях.
                        </div>
                        <div align="justify">Соглашение действительно в электронном виде и не требует двустороннего
                            подписания.
                        </div>
                        <li>
                            <b>ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ</b>
                            <ol>
                                <div className="normal-text">
                                    <div className="justified-text"><b>1.1. Платформа</b> – информационная система,
                                        включающая
                                        программно-аппаратный комплекс, состоящий из сайта, размещенного на доменном
                                        имени _______________, программы для ЭВМ, баз данных, дискового пространства и
                                        оборудования.
                                    </div>
                                </div>
                                <div className="normal-text">
                                    <div className="justified-text"><b>1.2. Владелец Платформы</b> – Общество с
                                        ограниченной
                                        ответственностью «АРТТЭК».
                                    </div>
                                </div>
                                <div className="normal-text">
                                    <div className="justified-text"><b>1.3. Пользователь</b> – юридическое лицо или
                                        индивидуальный
                                        предприниматель, иностранное юридическое лицо, а также физическое лицо и
                                        объединения лиц, имеющие право осуществлять предпринимательскую деятельность без
                                        образования юридического лица согласно применимому к их деятельности праву,
                                        прошедший процедуру Регистрации на Платформе в порядке, предусмотренном
                                        Лицензионным соглашением.
                                    </div>
                                </div>
                                <div className="normal-text">
                                    <div className="justified-text"><b>1.4. Аутентификация</b> – процедура подтверждения
                                        принадлежности простой электронной подписи (ПЭП) Пользователю, удостоверение
                                        правомочности обращения Пользователя через Платформу для совершения юридически
                                        значимых действий.
                                    </div>
                                </div>
                                <div className="normal-text">
                                    <div className="justified-text"><b>1.5. Простая электронная подпись (ПЭП)</b> –
                                        информация в
                                        электронной форме, которая присоединена к другой информации в электронной форме
                                        (подписываемой информации) или иным образом связана с такой информацией,
                                        формируемая без использования криптографических преобразований и подтверждающая
                                        факт формирования электронной подписи определенным лицом посредством
                                        использования кодов, паролей или иных средств.
                                    </div>
                                </div>
                                <div className="normal-text">
                                    <div className="justified-text"><b>1.6. Зарегистрированный номер телефона</b> –
                                        номер
                                        мобильного телефона, указанный и подтвержденный Пользователем с помощью
                                        SMS-ключа в процессе Регистрации.
                                    </div>
                                </div>
                                <div className="normal-text">
                                    <div className="justified-text"><b>1.7. Зарегистрированный адрес электронной
                                        почты</b> – адрес
                                        электронной почты Пользователя, указанный и подтвержденный Пользователем в
                                        процессе Регистрации на Платформе либо в ходе последующего изменения данных в
                                        Личном кабинете в соответствии с установленной процедурой.
                                    </div>
                                </div>
                                <div className="normal-text">
                                    <div className="justified-text"><b>1.8. Закон «Об электронной подписи»</b> –
                                        Федеральный закон
                                        РФ № 63-ФЗ «Об электронной подписи» от 6 апреля 2011 г.
                                    </div>
                                </div>
                                <div className="normal-text">
                                    <div className="justified-text"><b>1.9. Логин</b> – номер телефона или адрес
                                        электронной
                                        почты, указанный Пользователем в процессе Регистрации и используемый для
                                        авторизации Пользователя в целях доступа к Личному кабинету.
                                    </div>
                                </div>
                                <div className="normal-text">
                                    <div className="justified-text"><b>1.10. Личный кабинет</b> – закрытый от публичного
                                        доступа
                                        раздел Платформы, предназначенный для совершения Пользователем действий по
                                        получению информации от Платформы, заключению и исполнению договоров, а также
                                        для совершения иных действий на Платформе. Действия в Личном кабинете доступны
                                        после введения Логина и Пароля.
                                    </div>
                                </div>
                                <div className="normal-text">
                                    <div className="justified-text"><b>1.11. Пароль</b> – сохраняемое Пользователем в
                                        тайне от
                                        третьих лиц символьное обозначение, предоставленное ему в процессе Регистрации
                                        или определенное им впоследствии и используемое для авторизации Пользователя в
                                        целях доступа к Личному кабинету.
                                    </div>
                                </div>
                                <div className="normal-text">
                                    <div className="justified-text"><b>1.12. Регистрация</b> – совершение Пользователем
                                        на
                                        Платформе последовательности действий, указанных в разделе 4 Лицензионного
                                        соглашения. При регистрации Пользователя ему присваиваются соответствующие Логин
                                        и Пароль.
                                    </div>
                                </div>
                                <div className="normal-text">
                                    <div className="justified-text"><b>1.13. Система</b> – совокупность программных
                                        средств,
                                        используемых Платформой в целях поддержания функционирования Платформы,
                                        обеспечения информационного обмена между Пользователем и Платформой, ведения
                                        учета Пользователей, а также протоколирования действий, совершаемых
                                        Пользователями на Платформе.
                                    </div>
                                </div>
                                <div className="normal-text">
                                    <div className="justified-text"><b>1.14. Стороны</b> – Владелец Платформы и
                                        Пользователь.
                                    </div>
                                </div>
                                <div className="normal-text">
                                    <div className="justified-text"><b>1.15. Электронный документ</b> – документ в
                                        электронно-цифровой форме, подписанный электронной подписью.
                                    </div>
                                </div>
                            </ol>
                            <div className="normal-text">Иные термины и выражения, используемые в настоящем
                                Соглашении, имеют значение, которое придается им в законах и иных нормативных актах
                                Российской Федерации, Пользовательском соглашении, Договоре комиссии и иными
                                соглашениями между Сторонами.
                            </div>
                        </li>
                        <li>
                            <b>ПРЕДМЕТ</b>
                            <ol>
                                <div className="normal-text">
                                    <div className="justified-text"><b>2.1.</b> Соглашение определяет порядок и условия
                                        применения ПЭП
                                        Пользователя в процессе использования Платформы
                                    </div>
                                </div>
                                <div className="normal-text">
                                    <div className="justified-text"><b>2.2.</b> Соглашение регулирует отношения,
                                        возникающие как между
                                        Пользователями Платформы, так и между Пользователем Платформы и Владельцем
                                        Платформы, в том числе для заключения, изменения и исполнения ими договоров,
                                        договоров-заявок, заявок (соглашений), заключаемых с использованием функционала
                                        Платформы.
                                    </div>
                                </div>
                                <div className="normal-text">
                                    <div className="justified-text"><b>2.3.</b> Настоящее Соглашение определяет права и
                                        обязанности
                                        Сторон, возникающие в связи с формированием, отправкой и получением электронных
                                        документов с использованием Платформы.
                                    </div>
                                </div>
                            </ol>
                        </li>
                        <li>
                            <b>ИСПОЛЬЗОВАНИЕ И ПРОВЕРКА ПЭП</b>
                            <ol>
                                <div className="normal-text">
                                    <div className="justified-text"><b>3.1.</b> Руководствуясь положениями ч. 2 ст. 160
                                        ГК РФ и ч. 2 ст.
                                        6 Закона «Об электронной подписи», Стороны договорились о том, что все
                                        документы, соответствующие требованиям настоящего Соглашения, считаются
                                        подписанными собственноручно Пользователем.
                                    </div>
                                </div>
                                <div className="normal-text">
                                    <div className="justified-text"><b>3.2.</b> Стороны признают, что документ
                                        подписывается ПЭП
                                        Пользователя или действие на Платформе совершается самим Пользователем при
                                        условии успешного прохождения процедуры Аутентификации, предусмотренной п. 3.3.
                                        настоящего Соглашения, при наличии следующих условий:
                                    </div>
                                </div>
                                <div className="normal-text">
                                    <div className="justified-text"><b>3.2.1. </b> Пользователь авторизован в Личном
                                        кабинете с
                                        помощью Логина и Пароля.
                                    </div>
                                </div>
                                <div className="normal-text">
                                    <div className="justified-text"><b>3.2.2. </b> Пользователь ввел СМС-код,
                                        отправленный
                                        Платформой на Зарегистрированный номер телефона или адрес электронной
                                        почты.
                                    </div>
                                </div>
                                <div className="normal-text">
                                    <div className="justified-text"><b>3.2.3. </b>Юридически значимое действие
                                        Пользователя на
                                        Платформе является совершенным Пользователем, если его осуществление
                                        подтверждено одноразовым SMS-ключом, направленным на Зарегистрированный
                                        номер телефона Пользователя, для чего Пользователь вводит SMS-ключ в
                                        специальное поле на Платформе, о чем в Системе сохраняется информация.
                                    </div>
                                </div>
                                <div className="normal-text">
                                    <div className="justified-text"><b>3.2.4. </b>ПЭП считается проверенной, если сверка
                                        сгенерированного Системой SMS-ключа и направленного на
                                        Зарегистрированный номер телефона, с введенным на Платформе SMS-ключом
                                        прошла успешно.
                                    </div>
                                </div>
                                <div className="normal-text">
                                    <div className="justified-text"><b>3.3.</b> SMS-ключ предоставляется Пользователю
                                        Платформой путем
                                        направления СМС-сообщения, содержащего соответствующий ключ, на
                                        Зарегистрированный номер телефона. Таким образом, SMS-ключ считается
                                        предоставленным лично Пользователю с сохранением его конфиденциальности.
                                    </div>
                                </div>
                                <div className="normal-text">
                                    <div className="justified-text"><b>3.4.</b> Стороны соглашаются, что указанный в п.
                                        3.2 настоящего
                                        Соглашения способ определения Пользователя (Аутентификации), создавшего
                                        электронный документ, является достаточным для цели достоверной идентификации
                                        Пользователя и исполнения настоящего Соглашения.
                                    </div>
                                </div>
                                <div className="normal-text">
                                    <div className="justified-text"><b>3.5.</b> Стороны договорились, что любая
                                        информация, подписанная
                                        ПЭП Пользователя, признается электронным документом, равнозначным документу на
                                        бумажном носителе, подписанному собственноручной подписью Пользователя и,
                                        соответственно, порождает идентичные такому документу юридические последствия. В
                                        частности, любое юридически значимое волеизъявление Пользователя в рамках
                                        заключенных между Сторонами соглашений, которое выражено в электронном
                                        документе, соответствующем требованиям п. 3.2 и 3.3 настоящего Соглашения,
                                        порождает такие же юридические последствия, как если бы оно было зафиксировано
                                        на бумажном носителе.
                                    </div>
                                </div>
                                <div className="normal-text">
                                    <div className="justified-text"><b>3.6.</b> Риск неправомерного подписания
                                        электронного документа
                                        третьими лицами с использованием ПЭП Пользователя несет Пользователь, которому
                                        принадлежит электронная подпись. Владелец Платформы не несет ответственности
                                        перед Пользователем в случае неправомерного подписания электронного документа
                                        третьими лицам с использованием ПЭП Пользователя.
                                    </div>
                                </div>
                                <div className="normal-text">
                                    <div className="justified-text"><b>3.7.</b> Пользователь одобряет все действия,
                                        совершенные в
                                        результате обмена электронными документами, подписанными ПЭП Пользователя, и
                                        принимает на себя все права и обязанности, связанные с совершением указанных
                                        действий.
                                    </div>
                                </div>
                                <div className="normal-text">
                                    <div className="justified-text"><b>3.8.</b> В целях сохранения сведений о юридически
                                        значимых
                                        действиях, совершенных Сторонами, Владелец Платформы осуществляет хранение
                                        электронных документов, которые были созданы, переданы и / или приняты Сторонами
                                        в процессе использования Платформы, в течение 3 (трех) лет с момента создания
                                        документа.
                                    </div>
                                </div>
                            </ol>
                        </li>
                        <li>
                            <b>ОБЯЗАННОСТИ СТОРОН И КОНФИДЕНЦИАЛЬНОСТЬ</b>
                            <ol>
                                <div className="normal-text">
                                    <div className="justified-text"><b>4.1.</b> Пользователь обязан:</div>
                                </div>
                                <div className="normal-text">
                                    <div className="justified-text"><b>4.1.1.</b> не разглашать информацию о
                                        конфиденциальных
                                        SMS-ключах, получаемых Пользователем в целях использования ПЭП, а также
                                        предпринимать все меры, необходимые для сохранения этих сведений в
                                        тайне;
                                    </div>
                                </div>
                                <div className="normal-text">
                                    <div className="justified-text"><b>4.1.2.</b> не предоставлять третьим лицам доступ
                                        к
                                        Зарегистрированному адресу электронной почты, Зарегистрированному номеру
                                        телефона, а также предпринимать все меры для того, чтобы Логин и Пароль
                                        не стали известны третьим лицам;
                                    </div>
                                </div>
                                <div className="normal-text">
                                    <div className="justified-text"><b>4.1.3.</b> не передавать третьим лицам SIM-карту,
                                        которая
                                        обеспечивает возможность использовать Зарегистрированный номер телефона,
                                        а также предпринимать все меры, необходимые для того, чтобы третьи лица
                                        не получили возможность использования указанной SIM-карты;
                                    </div>
                                </div>
                                <div className="normal-text">
                                    <div className="justified-text"><b>4.1.4.</b> незамедлительно сообщать Владельцу
                                        Платформы о
                                        нарушении секретности сведений, указанных в п.п. 4.1.1-4.1.3 по адресу:
                                        _________________.
                                    </div>
                                </div>
                                <div className="normal-text">
                                    <div className="justified-text"><b>4.2.</b> Пользователь полностью несет риск всех
                                        неблагоприятных
                                        последствий, которые могут наступить в связи с неисполнением обязанностей,
                                        предусмотренных п. 4.1, в том числе риски, связанные с негативными последствиями
                                        недобросовестных действий третьих лиц, получивших вышеуказанную информацию.
                                    </div>
                                </div>
                                <div className="normal-text">
                                    <div className="justified-text"><b>4.3.</b> Владелец Платформы обязана:</div>
                                </div>
                                <div className="normal-text">
                                    <div className="justified-text"><b>4.3.1.</b> обеспечивать конфиденциальность
                                        информации о
                                        SMS-ключах Пользователей. Доступ к сведениям об SMS-ключах, Логину и
                                        Паролю, закрепленных за Пользователем, существует исключительно у
                                        уполномоченных сотрудников Владельца Платформы;
                                    </div>
                                </div>
                                <div className="normal-text">
                                    <div className="justified-text"><b>4.3.2.</b> обеспечивать неизменность подписанного
                                        ПЭП
                                        Пользователя документа/факта совершения Пользователем определенных
                                        действий на Платформе. Владелец Платформы также осуществляет хранение
                                        электронных документов, которые были созданы, переданы и / или приняты
                                        Пользователем в процессе использования Платформы.
                                    </div>
                                </div>
                                <div className="normal-text">
                                    <div className="justified-text"><b>4.4.</b> Пользователь обязуется незамедлительно
                                        сообщать Владельцу
                                        Платформы о прекращении использования Зарегистрированного номера телефона или
                                        адреса электронной почты через Личный кабинет или по иным каналам связи.
                                    </div>
                                </div>
                                <div className="normal-text">
                                    <div className="justified-text"><b>4.5.</b> Владелец Платформы приостанавливает
                                        действие ПЭП или
                                        блокирует Личный кабинет Пользователя путем внесения соответствующих изменений в
                                        Систему в случае получения сообщения, указанного в пункте 4.1.4 настоящего
                                        Соглашения, или прекращения отношений с Пользователем по его заявлению.
                                    </div>
                                </div>
                            </ol>
                        </li>
                        <li>
                            <b>ОТВЕТСТВЕННОСТЬ СТОРОН</b>
                            <ol>
                                <div className="normal-text">
                                    <div className="justified-text"><b>5.1.</b> Стороны несут ответственность за
                                        невыполнение или
                                        ненадлежащее выполнение своих обязанностей по настоящему Соглашению в пределах
                                        суммы причиненного другой стороне реального ущерба.
                                    </div>
                                </div>
                                <div className="normal-text">
                                    <div className="justified-text"><b>5.2.</b> Стороны не несут ответственность за
                                        неисполнение либо
                                        ненадлежащее исполнение своих обязанностей по настоящему Соглашению, если
                                        соответствующее нарушение обусловлено ненадлежащим исполнением своих
                                        обязанностей другой Стороной или вызвано воздействием обстоятельств
                                        непреодолимой силы.
                                    </div>
                                </div>
                            </ol>
                        </li>
                        <li>
                            <b>ПОРЯДОК ПРИСОЕДИНЕНИЯ К СОГЛАШЕНИЮ И ЕГО ИЗМЕНЕНИЕ</b>
                            <ol>
                                <div className="normal-text">
                                    <div className="justified-text"><b>6.1.</b> Соглашения о ПЭП является
                                        волеизъявлением сторон
                                        возникающие между Пользователем Платформы и Владельцем Платформы и считается
                                        принятым Пользователем в момент акцепта при Регистрации на Платформе.
                                    </div>
                                </div>
                                <div className="normal-text">
                                    <div className="justified-text"><b>6.2.</b> Владелец Платформы вправе в
                                        одностороннем порядке
                                        изменять (дополнять) условия настоящего Соглашения. Новая редакция Соглашения
                                        доводится до сведения Пользователей посредством опубликования новой редакции
                                        соответствующего документа на Платформе. Все изменения вступают в силу в дату
                                        размещения на Платформе, если более длительный срок вступления изменений в силу
                                        не определен дополнительно при публикации новой редакции Соглашения, и не
                                        требуют подписания со стороны Пользователей. Стороны соглашаются и признают, что
                                        внесение Владельцем Платформы изменений (дополнений) в Соглашение влечет за
                                        собой изменение (дополнение) Соглашения, заключенного ранее между Сторонами и
                                        действующего на дату вступления в силу изменений (дополнений).
                                    </div>
                                </div>
                                <div className="normal-text">
                                    <div className="justified-text"><b>6.3.</b> Пользователи обязуются самостоятельно
                                        следить за
                                        изменениями Соглашения путем периодического ознакомления с актуальной редакцией
                                        непосредственно перед формированием и направлением каждого электронного
                                        документа на Платформе. Пользователь самостоятельно отвечает за любые
                                        последствия, наступившие в связи с не ознакомлением с настоящим Соглашением.
                                    </div>
                                </div>
                                <div className="normal-text">
                                    <div className="justified-text"><b>6.4.</b> В случае несогласия с внесенными в
                                        настоящее Соглашение
                                        изменениями Пользователь обязан прекратить использование Платформы. Продолжение
                                        использования Платформы после вступления в силу изменений настоящего Соглашения
                                        означает принятие Стороной условий новой редакции Соглашения.
                                    </div>
                                </div>
                            </ol>
                        </li>
                        <li>
                            <b>ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</b>
                            <ol>
                                <div className="normal-text">
                                    <div className="justified-text"><b>7.1.</b> Настоящее Соглашение действует до
                                        момента отказа
                                        Пользователя от Лицензионного соглашения, но в любом случае не менее срока
                                        действия договоров, заключенных с использованием ПЭП.
                                    </div>
                                </div>
                                <div className="normal-text">
                                    <div className="justified-text"><b>7.2.</b> В случае возникновения споров Стороны
                                        установили
                                        следующее:
                                    </div>
                                </div>
                                <div className="normal-text">
                                    <div className="justified-text"><b>7.2.1</b> спор о наличии и/или подлинности ЭП –
                                        бремя
                                        доказывания лежит на Стороне, не соглашающейся с наличием ПЭП и/или
                                        подлинностью ПЭП соответственно;
                                    </div>
                                </div>
                                <div className="normal-text">
                                    <div className="justified-text"><b>7.2.2</b> спор о факте внесения изменений в
                                        электронный
                                        документ после его подписания ПЭП – бремя доказывания лежит на Стороне,
                                        утверждающей, что в данный документ были внесены изменения
                                    </div>
                                </div>
                                <div className="normal-text">
                                    <div className="justified-text"><b>7.2.3</b> в случае возникновения между Сторонами
                                        споров,
                                        связанных с использованием ПЭП, Владелец Платформы использует
                                        программное обеспечение (Систему), которое использовалось при
                                        формировании и для проверки ПЭП, и выносит свое решение о подлинности
                                        ПЭП. Пользователь вправе оспорить данное решение в судебном порядке.
                                    </div>
                                </div>
                            </ol>
                        </li>
                        <li>
                            <b>РЕКВИЗИТЫ ВЛАДЕЛЬЦА ПЛАТФОРМЫ</b>
                            <div className="closed-div">
                                <div>Общество с ограниченной ответственностью «АРТТЭК»</div>
                                <div>Юридический адрес: 127247 г. Москва, вн. тер. г. муниципальный округ Дмитровский,
                                    шоссе Дмитровское д. 107, к.1, помещ. 256
                                </div>
                                <div>Почтовый адрес: 350072 Краснодарский край, г. Краснодар ул. Автомобильная 1/1 оф.
                                    27.
                                </div>
                                <div>ИНН 2348040119; КПП 771301001; ОГРН 1182375021927</div>
                                <div>Расчётный счет: 40702810930000020448</div>
                                <div>Корреспондентский счет: 30101810100000000602</div>
                                <div>БИК банка: 040349602</div>
                                <div>Банк: КРАСНОДАРСКОЕ ОТДЕЛЕНИЕ N8619 ПАО СБЕРБАНК</div>
                            </div>
                        </li>
                    </ol>
                </div>
            </div>
        );
    }
}

export default SimpleElectronicSignature;
