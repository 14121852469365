import React from "react";
import { Form } from "react-bootstrap";

class Password extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {show: !this.props.id};
	}
	
	showControls() {
		this.setState({show: true});	
	}
	
	renderControls() {
		return (<div>
			<Form.Control size="sm" type="password" name="password" placeholder="пароль" 
				onChange={e => this.props.onChange("password", e.target.value)} />
			<Form.Control size="sm" type="password" name="password2" placeholder="повтор пароля" 
				onChange={e => this.props.onChange("password2", e.target.value)}/>
		</div>);
	}
	
	render() {
		return (
			<Form.Group>
				<Form.Label>Пароль</Form.Label>
				{this.state.show ? this.renderControls() :
					<div><a href="#" onClick={this.showControls.bind(this)}>сменить пароль</a></div>
				}
			</Form.Group>
		);
	}
}

export default Password;