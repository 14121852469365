
import BaseSelect from "./BaseSelect";
import addressService from '../../services/AddressService';
import optionsService from '../../services/OptionsService';

class AddressSelect extends BaseSelect {

	fetchOptions() {
		return addressService.addresses({contractorId: this.props.contractor.id, city: this.props.city.value});
	}
	
	componentDidUpdate(prevProps) {
		if (prevProps.city?.id !== this.props.city?.id) {
			this.loaded = false;
			this.props.onChange(optionsService.createEmptyOption());
			this.setState({ options: [] });
		}
	}
}

export default AddressSelect;