import BaseForm from "../../form/BaseForm";
import {Button, Card, Table} from "react-bootstrap";
import React from "react";
import DatePicker from "react-datepicker";
import ru from "date-fns/locale/ru";
import DictionarySelect from "../../form/DictionarySelect";
import positionHistoryService from "../../../services/PositionHistoryService";
import Modal from "react-bootstrap/Modal";
import {securityService} from "../../../services/SecurityService";
import dateTimeService from "../../../services/DateTimeService";

class PositionHistoryTable extends BaseForm {
    constructor(props) {
        super(props);

        this.state = {
            isEdit: false
        }
    }

    componentDidMount() {
        this.props.userId && positionHistoryService.getUserPositionHistory(this.props.userId)
            .then(positionHistory => this.setState({positionHistory}));
    }

    renderDatePicker(stateKey, row) {
        const selected = this.state.positionHistory?.find(ph => ph.id === row)[stateKey];

        const setDate = date => {
            if (date) {
                const onlyDate = date?.toISOString().substring(0, 10);
                const currentUser = securityService.getUser();

                let ph = this.updatePositionHistoryState(currentUser?.fio, row);
                ph[stateKey] = onlyDate;

                positionHistoryService.updatePositionHistory(
                    {
                        positionHistoryId: row,
                        [stateKey]: onlyDate,
                        redactorUserId: currentUser?.id,
                        editDateTime: dateTimeService.createDateAsUTC(ph.editDateTime)
                    }
                );
            }

        }

        return this.datePicker(selected, setDate);
    }


    updatePositionHistoryState(redactorName, row) {
        const now = new Date();

        const positionHistory = this.state.positionHistory;
        let ph = positionHistory.find(ph => ph.id === row);

        ph.editDateTime = now;
        ph.redactor = redactorName;

        this.setState({ positionHistory, isEdit: !this.state.isEdit });

        return ph;
    }

    renderDictionarySelect(name, value, row) {
        const setSelected = selected => {
            const currentUser = securityService.getUser();

            let ph = this.updatePositionHistoryState(currentUser?.fio, row)
            ph[name.toLowerCase() + "Id"] = selected.id;
            ph[name.toLowerCase() + "Value"] = selected.value;

            positionHistoryService.updatePositionHistory(
                {
                    positionHistoryId: row,
                    [name.toLowerCase() + "Id"]: selected.id,
                    redactorUserId: currentUser?.id,
                    editDateTime: dateTimeService.createDateAsUTC(ph.editDateTime)
                }
            );
        }

        return this.dictionarySelect(name, setSelected);
    }

    datePicker(selected, onChange) {
        return <DatePicker
            selected={selected ? new Date(selected) : null}
            dateFormat="yyyy-MM-dd"
            locale={ru}
            autoFocus
            shouldCloseOnSelect={true}
            onChange={onChange}
        />
    }

    dictionarySelect(name, onChange) {
        return <DictionarySelect
            menuPosition={"fixed"}
            closeMenuOnScroll={true}
            onChange={onChange}
            optionsType={name}
        />
    }

    renderCell(col, row, value) {
        const key = row + col;

        if (this.state.focusKey === key && this.state.isEdit === true) {
            switch (col) {
                case "c2": return <td key={key}>
                    {this.renderDatePicker("startDate", row)}
                </td>
                case "c3": return <td key={key}>
                    {this.renderDatePicker("endDate", row)}
                </td>
                case "c4": return <td key={key}>
                    {this.renderDictionarySelect("DEPARTMENT", value, row)}
                </td>
                case "c5": return <td key={key}>
                    {this.renderDictionarySelect("POSITION", value, row)}
                </td>
            }
        }

        return <td key={key} onClick={() => this.setKeyAndOpenOrCloseEdit(key)}>{value}</td>
    }

    setKeyAndOpenOrCloseEdit(key) {
        this.setState({ focusKey: key, isEdit: !this.state.isEdit })
    }

    renderAddButton() {
        return <Button onClick={() => this.setState({showModal: true})} size="sm" variant="outline-primary">Добавить</Button>
    }

    addLine() {
        const data = {
            crmUserId: this.props.userId,
            startDate: this.state.newLineStartDate,
            endDate: this.state.newLineEndDate,
            departmentId: this.state.newLineDepartment?.id,
            positionId: this.state.newLinePosition?.id,
            editDateTime: new Date()
        }

        positionHistoryService.addPositionHistory(data)
            .then(positionHistory => {
                this.setState({
                    positionHistory,
                    showModal: false,
                    newLineStartDate: null,
                    newLineEndDate: null,
                    newLineDepartment: null,
                    newLinePosition: null
                });
            });
    }

    deleteLine(positionHistoryId) {
        positionHistoryService.deletePositionHistory(positionHistoryId)
            .then(() =>  this.setState({positionHistory: this.state.positionHistory.filter(o => o.id !== positionHistoryId)}));
    }

    renderModal() {
        return <Modal size="lg"
                      aria-labelledby="example-modal-sizes-title-sm"
                      show={this.state.showModal}
                      onHide={() => this.setState({showModal: false})}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Новая запись
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="flex-space-between">
                <div>
                    <div>Дата начала</div>
                    <div style={{marginRight: "15px"}}>{this.datePicker(this.state["newLineStartDate"], date => this.setState({ newLineStartDate: date }))}</div>
                </div>
                <div>
                    <div>Дата окончания</div>
                    <div style={{marginRight: "15px"}}>{this.datePicker(this.state["newLineEndDate"], date => this.setState({ newLineEndDate: date }))}</div>
                </div>
                <div>
                    <div>Подразделение</div>
                    <div style={{marginRight: "15px", width: "250px"}}>{this.dictionarySelect("DEPARTMENT", position => this.setState({ newLineDepartment: position }))}</div>
                </div>
                <div>
                    <div>Должность</div>
                    <div style={{width: "350px"}}>{this.dictionarySelect("POSITION", position => this.setState({ newLinePosition: position }))}</div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button title={"Необходимо добавить дату начала и должность"}
                        style={{ marginRight: "45%" }}
                        onClick={() => this.addLine() }
                        size="sm" variant="primary"
                        disabled={!this.state.newLineStartDate || !this.state.newLinePosition}
                >
                    Добавить
                </Button>
            </Modal.Footer>
        </Modal>
    }

    FIELDS_NAME = [
        "Дата начала",
        "Дата окончания",
        "Подразделение",
        "Должность",
        "Редактор",
        "Время изменения",
        "Удалить"
    ]

    render() {
        return <Card style={{ marginTop: "15px" }}>
            <Card.Header style={{ display: "flex", justifyContent: "space-between" }}>
                <h5>История должностей и подразделений</h5>
                <div>{this.renderAddButton()}</div>
            </Card.Header>
            <Card.Body style={{padding: 0}}>
                <Table striped bordered hover size="sm">
                    <thead style={{fontWeight: "bold"}}>
                        <tr>
                            {this.FIELDS_NAME.map(fn => <td>{fn}</td>)}
                        </tr>
                    </thead>
                    <tbody>
                    {
                        this.state?.positionHistory?.map(
                            ph => <tr>
                                {this.renderCell("c2", ph.id, ph.startDate)}
                                {this.renderCell("c3", ph.id, ph.endDate)}
                                {this.renderCell("c4", ph.id, ph.departmentValue)}
                                {this.renderCell("c5", ph.id, ph.positionValue)}
                                {this.renderCell("c6", ph.id, ph.redactor)}
                                {this.renderCell("c7", ph.id, new Date(ph.editDateTime).toLocaleString())}
                                <td style={{textAlign: "center", width: "60px"}}>
                                    <i className={"fa fa-times clear-icon"} onClick={() => this.deleteLine(ph.id) }></i>
                                </td>
                            </tr>
                        )
                    }
                    </tbody>
                </Table>
                {this.renderModal()}
            </Card.Body>
        </Card>
    }
}

export default PositionHistoryTable;