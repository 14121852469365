import React from "react";
import {Form, Table} from "react-bootstrap";
import {connect} from "react-redux";
import DtPicker from "../form/DtPicker";
import TimePicker from "../form/TimePicker";
import DictionarySelect from "../form/DictionarySelect";
import AddressSelect from "../form/AddressSelect";
import CitySelect from "../form/CitySelect";
import { setValue } from "../../actions/form";
import Util from "../../Util";

import "./css/RouteTable.css";
import optionsService from "../../services/OptionsService";
import {RoutePoint} from "../../Const";
import {NavLink} from "react-router-dom";
import {securityService} from "../../services/SecurityService";

function mapStateToProps(state) {
	const user = {
		...state.user,
		isClient: securityService.isClient(state.user)
	}

	return {
		user: user,
		routePoints: state.model.routePoints,
		errors: state.errors
	};
}
class RouteTable extends React.PureComponent {

	static FIELD_DT = "dt";
	static FIELD_CITY = "city";
	static FIELD_CARGO_TYPE = "cargoType";
	static FIELD_WIDTH = "width";
	static FIELD_HEIGHT = "height";
	static FIELD_LENGTH = "length";
	static FIELD_WEIGHT = "weight";
	static FIELD_VOLUME = "volume";
	static FIELD_TIME_TO = "timeTo";
	static FIELD_ADDRESS = "address";
	static FIELD_CONTACT = "contact";
	static FIELD_CARCASS = "carcass";
	static FIELD_POSITION = "position";
	static FIELD_TIME_FROM = "timeFrom";
	static FIELD_POINT_TYPE = "pointType";
	static FIELD_TEMPERATURE = "temperature";
	static FIELD_DESCRIPTION = "description";
	static FIELD_LOADING_TYPE = "loadingType";
	static ARRIVAL_DT = "arrivalDt";
	static DEPARTURE_DT = "departureDt";

	static FIELDS = {
		[RouteTable.FIELD_POSITION]: { title: "#" },
		[RouteTable.FIELD_POINT_TYPE]: { title: "Тип", className: "point-type", dictionary: "POINT_TYPE" },
		[RouteTable.FIELD_DT]: { title: "Дата",  type: "date" },
		[RouteTable.FIELD_TIME_FROM]: { title: "Время с", type: "time" },
		[RouteTable.FIELD_TIME_TO]: { title: "Время до", type: "time" },
		[RouteTable.ARRIVAL_DT]: { title: "Дата прибытия факт", type: "dateTime" },
		[RouteTable.DEPARTURE_DT]: { title: "Дата отъезда факт", type: "dateTime" },
		[RouteTable.FIELD_CITY]: { title: "Город", type: "city" },
		[RouteTable.FIELD_ADDRESS]: { title: "Адрес", type: "address" },
		["link"]: { title: "→", type: "link"},
		[RouteTable.FIELD_CONTACT]: { title: "Контактное лицо" },
		[RouteTable.FIELD_CARCASS]: { title: "Тип кузова", dictionary: "CARCASS" },
		[RouteTable.FIELD_LOADING_TYPE]: { title: "Тип загрузки", className: "loading-type", dictionary: "LOADING_TYPE" },
		[RouteTable.FIELD_TEMPERATURE]: { title: "Темп. режим" },
		[RouteTable.FIELD_CARGO_TYPE]: { title: "Груз/Упаковка", className: "cargo-type", dictionary: "CARGO_TYPE" },
		[RouteTable.FIELD_WEIGHT]: { title: "Вес, тонн", type: "number" },
		[RouteTable.FIELD_VOLUME]: { title: "Объем, метров кубических", type: "number" },
		[RouteTable.FIELD_LENGTH]: { title: "Длина, метров" },
		[RouteTable.FIELD_WIDTH]: { title: "Ширина, метров" },
		[RouteTable.FIELD_HEIGHT]: { title: "Высота, метров" },
		[RouteTable.FIELD_DESCRIPTION]: { title: "Дополнительно" },
	};

	static get FIELD_SET_FULL() { return Object.keys(RouteTable.FIELDS); }; // all fields
	static FIELD_SET_DEFAULT = [
		RouteTable.FIELD_POSITION,
		RouteTable.FIELD_POINT_TYPE,
		RouteTable.FIELD_DT,
		RouteTable.FIELD_TIME_FROM,
		RouteTable.FIELD_TIME_TO,
		RouteTable.ARRIVAL_DT,
		RouteTable.DEPARTURE_DT,
		RouteTable.FIELD_CITY,
		RouteTable.FIELD_ADDRESS,
		RouteTable.FIELD_CARCASS,
		RouteTable.FIELD_LOADING_TYPE,
		RouteTable.FIELD_TEMPERATURE,
		RouteTable.FIELD_CARGO_TYPE,		
		RouteTable.FIELD_WEIGHT,
		RouteTable.FIELD_VOLUME
	];

	static get defaultProps() {
		return {
			fieldset: RouteTable.FIELD_SET_DEFAULT
		}
	}

	constructor(props) {
		super(props);
		
		this.state = {editor: null};
		
		this.columns = this.props.fieldset.map(key => { 
			return { 
				field: key, 
				...RouteTable.FIELDS[key] 
			};
		});
		
		this.onChange = this.onChange.bind(this);
		this.mouseListener = this.mouseListener.bind(this);
		
		this.routeTable = React.createRef();
	}

	mouseListener(e) {
		if (!this.routeTable.current.contains(e.target)) {
			this.addRemoveMouseListener(false);
			this.setState({editor: null});
		}
	}

	addRemoveMouseListener(listen) {
		const addRemove = listen ? document.body.addEventListener : document.body.removeEventListener;
		addRemove("mousedown", this.mouseListener, true);
	}

	openEditor(position, field) {
		if (this.props.user.isClient === true || this.props.readOnly) {
			return;
		}

		this.addRemoveMouseListener(true);
		this.setState({"editor": {position, field}})
	}
	
	renderHeader() {
		return (
			<thead>
				<tr>
					{this.columns.map(col => 
						<th key={col.field} className={col.className || col.field}>
							{col.title}
						</th>
					)}
					<th className="actions"/>
				</tr>
			</thead>
		);
	}

	renderRow(point) {
		const editor = this.state.editor,
			showEditor = editor && editor.position === point.position;
		return (
			<tr key={point.position}>
				{this.columns.map(col => {
					const isEditor = showEditor && editor.field === col.field && col.field !== RouteTable.FIELD_POSITION,
						className = (col.className || col.field) + (isEditor ? " editor" : "");
					const handler = () => !isEditor && col.type !== "link" && this.openEditor(point.position, col.field);
					return ( 
						<td key={col.field} className={className} tabIndex={0}
								onClick={() => handler()} onFocus={() => handler()}>
							{isEditor ? this.renderEditor(point, col) : this.renderValue(point, col)}
						</td>);
					})
				}
				{this.renderActions(point)}
			</tr>
		);
	}
	
	renderActions(point) {
		return (
			<td className="actions">
				{point.position > 0 &&
					<i className="fas fa-arrow-up" title="вверх" onClick={() => this.shift(point, true)}/>}
				{point.position < this.props.routePoints.length - 1 && 
					<i className="fas fa-arrow-down" title="вниз" onClick={() => this.shift(point, false)}/>}
				{this.isShowRemovePointButton(point) && !this.props.user.isClient &&
					<i className="fas fa-times" title="удалить" onClick={() => this.removeRow(point)}/>}
			</td>);
	}

	isShowRemovePointButton(point) {
		return point.pointType.id === RoutePoint.POINT_TYPE_CUSTOMS || (this.props.routePoints
			&& this.props.routePoints.filter(p => p.pointType.id === point.pointType.id).length > 1);
	}

	removeRow(point) {
		const routePoints = this.props.routePoints;
		routePoints.removeAt(point.position);
		routePoints.filter(p => p.position > point.position).forEach(point => point.position--);
		this.onChange("routePoints", [...routePoints]);
	}

	shift(point, up) {
		if (this.props.user.isClient === true) {
			return;
		}

		const routePoints = this.props.routePoints,
			position = point.position,
			anotherPosition = point.position + (up ? -1 : 1),
			anotherPoint = routePoints[anotherPosition];

		const newPoint = {id: point.id, position: position, ...this.getRouteProps(anotherPoint)};
		const newAnotherPoint = {id: anotherPoint.id, position: anotherPosition, ...this.getRouteProps(point)};

		routePoints[position] = newPoint;
		routePoints[anotherPosition] = newAnotherPoint;
		routePoints.sort((a, b) => (a.position > b.position) ? 1 : -1)

		this.onChange("routePoints", [...routePoints]);
	}

	getRouteProps(point) {
		let {id, position,  ...rest} = point;
		return rest;
	}

	renderValue(point, col) {
		const obj = point[col.field];
		if (obj === null) {
			return null;
		}
		if ("date" === col.type) {
			return obj ? this.formatDate(Util.formatUTC(obj, true)) : "";
		}
		if (RouteTable.FIELD_POSITION === col.field) {
			return obj + 1;
		}
		if ("dateTime" === col.type) {
			return obj ? new Date(point[col.field])?.toLocaleString() : "";
		}

		const isAddress = col.type === "address";
		const isNoCoordinates = point.address?.latitude === null || point.address?.longitude === null;

		if (col.type === "link" && isNoCoordinates) {
			return this.renderLinkToAddress(point);
		}

		return <font title={isAddress && isNoCoordinates && "Не указаны координаты"}
					 color={isAddress && isNoCoordinates && "red"}>
					{obj && Object.keys(obj).includes("value") ? obj.value : obj}
			   </font>;
	}

	renderLinkToAddress(point) {
		return <NavLink to={"/control-panel/contractors/contractor?id=" + this.props.contractor?.id + "&activeTab=addresses&&activeAddress=" + point.address?.id}
						target={"_blank"}
		>
			<i className="link fas fa-fw fa-link"></i>
		</NavLink>
	}
	
	renderEditor(point, col) {
		if ("date" === col.type) {
			return this.renderDatePicker(point, col.field);
		}
		if ("time" === col.type) {
			return this.renderTimePicker(point, col.field);
		}
		const name = this.formatFieldName(point.position, col.field);
		if ("address" === col.type) {
			return this.renderAddressSelector(point, name, col.field);
		}
		if ("city" === col.type) {
			return this.renderCitySelector(point, name, col.field);
		}
		if (col.dictionary) {
			return this.renderDictionary(point, name, col);
		}
		//TODO: и если пользователь логист, руководитель или администратор
		if ("dateTime" === col.type) {
			return this.renderDateTimePicker(point, col.field);
		}
		const type = col.type || "text",
			maxLength = 255;
		
		return (
			<Form.Control size="sm" type={type} name={name} onChange={e => this.onChange(name, e.target.value)}
				value={point[col.field]} maxLength={maxLength}/>
		);
	}

	renderCitySelector(point, name, field) {
		const onChange = value => {
			this.onChange(name, value);
			this.onChange(name.replace("city", "address"), optionsService.createEmptyOption());
		};
		return (<CitySelect
				name={name}
				menuPosition={"fixed"}
				closeMenuOnScroll={true}
				readOnly={!this.props.contractor}
				contractor={this.props.contractor}
				onChange={onChange}
				value={point[field]} />
		);
	}

	renderAddressSelector(point, name, field) {
		return (<AddressSelect
				name={name}
				menuPosition={"fixed"}
				closeMenuOnScroll={true}
				readOnly={!point.city?.id}
				contractor={this.props.contractor}
				city={point.city}
				onChange={value => this.onChange(name, value)}
				value={point[field]} />
		);
	}

	renderDictionary(point, name, col) {
		return (<DictionarySelect
				menuPosition={"fixed"}
				closeMenuOnScroll={true}
				name={name}
				onChange={value => this.onChange(name, value)}
				optionsType={col.dictionary}
				value={point[col.field]} />
		);		
	}
	
	formatDate(dt) {
		return `${dt.getDate().pad(2)}.${(1 + dt.getMonth()).pad(2)}.${dt.getFullYear()}`;	
	}
	
	formatTime(dt) {
		return dt != null ? `${dt.getHours().pad(2)}:${dt.getMinutes().pad(2)}` : null;	
	}

	renderDatePicker(point, field) {
		const name = this.formatFieldName(point.position, field);
		return (
			<DtPicker
				showTimeSelect={false}
				value={point[field]}
				name={name}
				onChange={newValue => this.onChange(name, newValue)} />
		);
	}

	renderTimePicker(point, field) {
		const name = this.formatFieldName(point.position, field);
		return (
			<TimePicker
				isClearable={true}
				value={point[field]}
				name={name}
				onChange={newValue => this.onChange(name, this.formatTime(newValue))} />
		);
	}

	renderDateTimePicker(point, field) {
		const name = this.formatFieldName(point.position, field);
		return (
			<DtPicker
				showTimeSelect={true}
				value={point[field]}
				name={name}
				onChange={newValue => this.onChange(name, newValue)}
			/>
		);
	}

	formatFieldName(position, field) {
		return `routePoints.${position}.${field}`;
	}
	
	onChange(name, value) {
		this.props.store.dispatch(setValue(name, value));	
	}
	
	render() {
		return (
			<Table ref={this.routeTable} striped bordered hover size="sm" className="data-table route-table">
				{this.renderHeader()}
				<tbody>
					{this.props.routePoints.map(point => this.renderRow(point))}
				</tbody>
			</Table>
		);
	}
	
	componentWillUnmount() {
		this.addRemoveMouseListener(false);
	}
	
}

export default connect(mapStateToProps)(RouteTable);
