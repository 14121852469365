import {axios} from '../axios';

class PersonService {

	async list(params) {
		const response = await axios.post(`/api/persons/list`, params);
		return response.data;
	}

	async read(id) {
		const response = await axios.get(`/api/persons/${id}`);
		return response.data;
	}
	
	async readByPhone(phone) {
		const response = await axios.get(`/api/persons/phone/${phone}`);
		return response.data;
	}

	async options(contractor) {
		const response = await axios.post("/api/persons/options", {contractor, pageSize: 100, sort: {field: "fio"}});
		return response.data.data;
	}
	
	async isEmailUnique(id, email) {
		const response = await axios.post(`/api/persons/email/unique`, {id, value: email});
		return response.data;
	}

	async isPassportNumberUnique(id, passportNumber) {
		const response = await axios.post(`/api/persons/passport-number/unique`, {id, value: passportNumber});
		return response.data;
	}

	async isPhoneUnique(id, phone) {
		const response = await axios.post(`/api/persons/phone/unique`, {id, value: phone});
		return response.data;
	}
	
	async isInnUnique(id, inn) {
		const response = await axios.post(`/api/persons/inn/unique`, {id, value: inn});
		return response.data;
	}

	async getPersonByDriverId(id) {
		const response = await axios.get(`/api/persons/driver/${id}`);
		return response.data;
	}
}

export default new PersonService();