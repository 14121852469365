import React, {PureComponent} from "react";
import {Button} from "react-bootstrap";
import cls from "./AuctionBidButton.module.css";
import {classNames} from "../../../../helpers/classNames";

export class AuctionBidButton extends PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            isDisabled,
            bid,
            onClick,
            className
        } = this.props;

        return (
            <Button
                variant="success"
                disabled={isDisabled}
                onClick={onClick}
                className={classNames(cls.wrapper, {}, [className])}
            >
                <div className={cls.button}>
                    <span>Сделать ставку</span>

                    <span>
                        <span>₽</span>

                        <span>
                            {bid}
                        </span>
                    </span>
                </div>
            </Button>
        )
    }
}