import {axios} from '../axios';

class CommentReportService{

    async commentsList(dt,user,department,contractor) {
        const response = await axios.post('/api/report/comment/list', {dt,user,department,contractor});
        return response.data;
    }

    async delete(id) {
        const response = await axios.delete(`api/report/comment/${id}`);
        return response.data;
    }

    async save(period,idUserTo,idUserFrom,departmentId,contractorId,message) {
        const response = await axios.post('/api/report/comment', {period,idUserTo,idUserFrom,departmentId,contractorId,message});
        return response.data;
    }

    async update(id,period,idUserTo,idUserFrom,departmentId,contractorId,message) {
        const response = await axios.post('/api/report/comment', {id,period,idUserTo,idUserFrom,departmentId,contractorId,message});
        return response.data;
    }
}

export default new CommentReportService();