import BaseForm from "../../../form/BaseForm";
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import FormGroup from "../../../form/FormGroup";
import React from "react";
import {connect} from "react-redux";
import {setData, setValue} from "../../../../actions/form";
import StoreWrapper from "../../../form/StoreWrapper";
import {setToastObjAC} from "../../../../reducers/toastObj";
import contactService from "../../../../services/ContactService";
import FormValidator from "../../../../validators/FormValidator";
import personService from "../../../../services/PersonService";
import {emailValidator, phoneValidator, requiredValidator} from "../../../../validators/simpleValidators";

function mapStateToProps(state) {
    return {
        errors: state.errors,
        model: state.model
    };
}

class ContactForm extends StoreWrapper {
    constructor(props) {
        super(props);
    }

    static get FIELD_ID() { return 'id' };
    static get FIELD_FIO() { return 'fio' };
    static get FIELD_POSITION() { return 'position' };
    static get FIELD_EXECUTIVE() { return 'executive' };
    static get FIELD_DOCUMENT() { return 'document' };
    static get FIELD_PHONE() { return 'phone' };
    static get FIELD_EMAIL() { return 'email' };
    static get FIELD_CONTRACTORS() { return 'contractors' };
    static get FIELD_IS_DRIVER() { return 'isDriver' };

    render() {
        return (
            <ContactFormInnerConnected {...this.props} store={this.store} />
        );
    }
}

class ContactFormInner extends BaseForm {

    constructor(props) {
        super(props);

        this.getFieldValue = this.getFieldValue.bind(this);
        this.configureValidators();
    }

    configureValidators() {
        this.useValidatorFor(requiredValidator, ContactForm.FIELD_FIO)
        this.useValidatorFor(emailValidator.bind(this), ContactForm.FIELD_EMAIL);
        this.useValidatorFor(phoneValidator.bind(this), ContactForm.FIELD_PHONE);
        this.validator.addValidator(ContactForm.FIELD_EMAIL, this.emailUniqueValidator.bind(this));
        this.validator.addValidator(ContactForm.FIELD_PHONE, this.phoneUniqueValidator.bind(this));
    }

    async emailUniqueValidator() {
        const {model} = this.props;
        const email = model.email;
        if (!email) {
            return FormValidator.PROMISE_OK;
        }
        const isValid = await personService.isEmailUnique(model.id, email);
        return isValid ? FormValidator.OK : "этот email уже зарегистрирован в системе";
    }

    async phoneUniqueValidator() {
        const {model} = this.props;
        if (!model.phone) {
            return FormValidator.PROMISE_OK;
        }
        const data = await personService.isPhoneUnique(model.id, model.phone);
        return data.unique
            ? FormValidator.OK
            :
            <div>
                <div>
                    <p> этот телефон уже зарегистрирован в системе </p>
                    <p> { data.info } </p>
                    <p> перенести данные из имеющегося контакта? </p>
                </div>
                <Button onClick={() => this.handleUpdate()} size="sm" variant="primary" className="btn-xs"><span>Принять</span></Button>
                <span> или </span>
                <Button onClick={() => this.handleClearField(ContactForm.FIELD_PHONE)} size="sm" variant="danger" className="btn-xs"><span>Отклонить</span></Button>
            </div>
    }

    async handleUpdate() {
        const {model} = this.props;
        const contact = await contactService.readByPhone(model.phone);
        this.setModel(contact);
    }

    handleClearField(field) {
        this.onChange(field, '');
    }

    render() {
        const {model, banEditDriver} = this.props;
        if (!model) {
            return;
        }
        const isDriver = model.isDriver === true;
        const isForbiddenEditing = banEditDriver && isDriver;
        return (
            <Form>
                <Card className="m-1 bg-light">
                    <Card.Body>
                        <Row>
                            <Col lg={3} md={6} sm={12}>
                                <FormGroup
                                    title="ФИО"
                                    name={ContactForm.FIELD_FIO}
                                    store={this.props.store}
                                    readOnly={isForbiddenEditing}
                                    onChangeHandler={value => this.onChangeContact(ContactForm.FIELD_FIO, value)}
                                    required
                                />
                            </Col>
                            <Col></Col>
                        </Row>

                        <Row>
                            <Col lg={3} md={6} sm={12}>
                                <FormGroup
                                    title="Должность"
                                    name={ContactForm.FIELD_POSITION}
                                    store={this.props.store}
                                    readOnly={isForbiddenEditing}
                                    onChangeHandler={value => this.onChangeContact(ContactForm.FIELD_POSITION, value)}
                                />
                            </Col>
                            <Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>&nbsp;</Form.Label>
                                        <Form.Check
                                            label="Должностное лицо"
                                            id={ContactForm.FIELD_EXECUTIVE}
                                            name={ContactForm.FIELD_EXECUTIVE}
                                            checked={this.getFieldValue(ContactForm.FIELD_EXECUTIVE)}
                                            onChange={(e) => this.onChange(ContactForm.FIELD_EXECUTIVE, e.target.checked)}
                                            disabled={isForbiddenEditing} />
                                    </Form.Group>
                                </Col>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={3} md={6} sm={12}>
                                <FormGroup
                                    title="Действует на основании"
                                    name={ContactForm.FIELD_DOCUMENT}
                                    store={this.props.store}
                                    readOnly={isForbiddenEditing}
                                    onChangeHandler={value => this.onChangeContact(ContactForm.FIELD_DOCUMENT, value)}
                                />
                            </Col>
                            <Col></Col>
                        </Row>

                        <Row>
                            <Col lg={3} md={6} sm={12}>
                                <FormGroup
                                    title="Номер телефона"
                                    type="phone"
                                    name={ContactForm.FIELD_PHONE}
                                    store={this.props.store}
                                    readOnly={isForbiddenEditing}
                                    onChangeHandler={value => this.onChangeContact(ContactForm.FIELD_PHONE, value)}
                                />
                            </Col>
                            <Col></Col>
                        </Row>

                        <Row>
                            <Col lg={3} md={6} sm={12}>
                                <FormGroup
                                    title="E-mail"
                                    name={ContactForm.FIELD_EMAIL}
                                    store={this.props.store}
                                    readOnly={isForbiddenEditing}
                                    onChangeHandler={value => this.onChangeContact(ContactForm.FIELD_EMAIL, value)}
                                />
                            </Col>
                            <Col></Col>
                        </Row>

                        <Row className="justify-content-left">
                            <Col className="col-auto"></Col>
                            <Col className="col-auto">
                                {this.renderSaveButton()}
                            </Col>
                            {!isDriver && <Col className="col-auto">
                                {this.renderClearButton()}
                            </Col>}
                        </Row>
                    </Card.Body>
                </Card>
            </Form>
        );
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setSending(true);
        this.submit(() => contactService.save(this.props.model)
            .then(() => this.setSending(false)));
    }

    handleClear(e) {
        e.preventDefault();
        const {model} = this.props;
        Object.keys(model).forEach(key => {
            if (key !== ContactForm.FIELD_ID) {
                const value = key === ContactForm.FIELD_EXECUTIVE ? false : '';
                this.onChange(key, value);
            }
        });
    }

    onChange(field, value) {
        this.onChangeContact(field, value);
        this.props.store.dispatch(setValue(field, value));
    }

    onChangeContact(field, value) {
        const {onChangeContact} = this.props;
        if (onChangeContact) {
            onChangeContact(field, value);
        }
    }

    async componentDidMount() {
        await this.initPersonModel();
    }

    async initPersonModel() {
        const {contactModel} = this.props;
        if (contactModel) {
            this.setModel(contactModel);
            return;
        }

        const contactId = this.getContactId();
        if (contactId) {
            const contact = await contactService.read(contactId);
            this.setModel(contact);
        }
    }

    setModel(model) {
        this.props.store.dispatch(setData(model, this.props.location.state?.action));
    }

    getContactId() {
        const {model} = this.props;
        return this.fromLocationState(ContactForm.FIELD_ID) || new URLSearchParams(this.props.location.search).get("id") || model.id;
    }

    fromLocationState(field) {
        return this.props.location.state?.[field];
    }

    getFieldValue(field) {
        const contact = this.props.model;
        let value;
        if (contact) {
            value = contact[field];
        }
        return value === null || value === undefined ? "" : value;
    }
}

const ContactFormInnerConnected = connect(mapStateToProps, {setToastObjAC})(ContactFormInner);

export default connect(mapStateToProps)(ContactForm);