import React from "react";
import { connect } from 'react-redux';
import {Badge, Card} from "react-bootstrap";

import { requiredValidator } from "../../../validators/simpleValidators";
import {setData, setValue} from "../../../actions/form";
import addressService from "../../../services/AddressService";
import FormGroup from "../../form/FormGroup";
import BaseForm from "../../form/BaseForm";
import StoreWrapper from "../../form/StoreWrapper";
import "./style.css";
import mapService from "../../../services/MapService";
import Modal from "react-bootstrap/Modal";
import contractorService from "../../../services/ContractorService";

class Address extends StoreWrapper {

    constructor(props) {
        super(props);
    }

    static get FIELD_ID() { return 'id' };
    static get RADIUS_KM() { return 'radiusKilometers' };
    static get FIELD_CITY() { return 'cityDto.value' };
    static get FIELD_DISTRICT() { return 'district' };
    static get FIELD_DISTRICT_VALUE() { return 'district.value' };
    static get FIELD_OLD_CITY() { return 'city' };
    static get FIELD_CITY_DTO() { return 'cityDto' };
    static get FIELD_ADDR() { return 'fullAddress' };
    static get FIELD_LATITUDE() { return 'latitude' };
    static get FIELD_LONGITUDE() { return 'longitude' };
    static get FIELD_FIAS_ID() { return 'fiasId' };
    static get FIELD_CONTRACTOR() { return 'contractor' };
    static get FIELD_COUNTRY_ISO() { return 'countryIso' };
    static get FIELD_REGION_ISO() { return 'regionIso' };
    static get REGION_NAME() { return 'region' };
    static get REGION_NAME_VALUE() { return 'region.value' };
    static get FIELD_REGION_OBJ() {return 'region'}
    static get TYPE_ADDRESS() { return 'address' };
    static get TYPE_ORIGINAL_ADDRESS() { return 'original-address' };
    static get TYPE_NORMALIZED_ORIGINAL_ADDRESS() { return 'normalized-original-address' };

    render() {
        return (
            <AddressInnerConnected {...this.props} store={this.store} />
        );
    }
}

function mapStateToProps(state, ownProps) {
    const model = Object.keys(state.model).length !== 0 && state.model;
    return {
        errors: state.errors,
        model: model || ownProps.model || {}
    };
}

class AddressInner extends BaseForm {

    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.configureValidators();
        this.getChanges = this.getChanges.bind(this);
        this.initMap = this.initMap.bind(this);
        this.afterSubmit = this.afterSubmit.bind(this);
        this.searchControlListener = this.searchControlListener.bind(this);

        this.state = {
            showMap: true,
            showSuccess: false,
            regionNotFound: false,
            problemCoords: null
        }
    }

    map = null;

    formatFieldName(field) {
        return this.props.fieldNameFormatter ? this.props.fieldNameFormatter(field) : field;
    }

    configureValidators() {
        this.useValidatorFor(
            requiredValidator,
            this.formatFieldName(Address.FIELD_ADDR),
            this.formatFieldName(Address.REGION_NAME),
            this.formatFieldName(Address.FIELD_LONGITUDE),
            this.formatFieldName(Address.FIELD_LATITUDE),
        );
    }

    clearValidators() {
        this.validator.validationMap = [];
    }

    onChange(field, value) {
        this.props.store.dispatch(setValue(this.formatFieldName(field), value));
    }

    getCurrentAddress() {
        let addresses = this.props.model.addresses;
        const currentIndex = this.props.currentIndex;

        if (addresses && currentIndex >= 0) {
            let currentAddress = addresses[currentIndex];
            if (currentAddress) {
                if (currentAddress.contractor === null) {
                    currentAddress.contractor = this.props.contractor.id;
                }
            }
            delete currentAddress.addresses;

            return currentAddress;
        }
        return null;
    }

    handleSubmit(e) {
        e.preventDefault();
        this.submit(() => {
            addressService.save(this.getCurrentAddress())
                .then(() => this.afterSubmit());
        });
    }

    afterSubmit() {
        const routeIndex = this.props.addAddRouteIndex ? this.props.addAddRouteIndex : 0

        if(this.props.isQuoteAddress && !this.props.model.multiRoute) {
            const stateAddr = this.state.address;

            const addressCity = {
                id: stateAddr.cityDto ? stateAddr.cityDto.id : stateAddr.region.id,
                value: `${stateAddr.region.value} ${stateAddr.cityDto ? ", " + stateAddr.cityDto.value : ""}`
            }

            contractorService.read(this.props.contractor.id).then(data => {
                data.addresses.map(addr => {
                    if (addr.longitude === parseFloat(stateAddr.longitude) && addr.latitude === parseFloat(stateAddr.latitude)) {
                        const address = {
                            id: addr.id,
                            latitude: addr.latitude,
                            longitude: addr.longitude,
                            value: addr.fullAddress
                        }
                        this.props.onChangeRoutePoint(`routePoints.${routeIndex}.city`, addressCity)
                        this.props.onChangeRoutePoint(`routePoints.${routeIndex}.address`, address)
                    }
                })
            })
        }
        this.updateAddresses();
        this.setState({showSuccess: true})
        this.props.handleClose()
        this.props.setToastObjAC({show: true, textHeader: "Адрес сохранен!", delay: 3000})
    }

    handleClear(e) {
        e.preventDefault();
        this.clearAddress();
    }

    clearAddress() {
        Object.keys(this.getCurrentAddress())
            .filter(key => key !== Address.FIELD_ID)
            .forEach(key => this.onChange(key, null));
    }

    load() {
        this.props.store.dispatch(setData(this.props.model, this.props.location?.state?.action));
    }
    componentDidMount() {
        if(this.props.isQuoteAddress) {
            const addresses = this.props.addresses;
            addresses.unshift({
                [Address.FIELD_ADDR]: '',
                [Address.FIELD_LATITUDE]: null,
                [Address.FIELD_LONGITUDE]: null,
                [Address.FIELD_FIAS_ID]: null,
                [Address.FIELD_COUNTRY_ISO]: null,
                [Address.FIELD_REGION_ISO]: null,
                [Address.FIELD_CONTRACTOR]: this.props.contractor.id
            });
            super.onChange("addresses", [...addresses]);
        }
        this.getChanges()
    }

    getPlacemarkText(address) {
        const{regionName, cityName, districtName, fullAddress} = address
        return (`${regionName}${districtName ? `, ${districtName}` : ""}${cityName ? `, ${cityName}` : ""} ${fullAddress}`) || "Регион за пределами РФ"
    }

    getChanges() {
        this.load(this.props.currentIndex);
        this.showMap()
        this.clearMap();
        const address = this.props.addresses[this.props.currentIndex];
        const mainDataAddr = {regionName: address?.region?.value, cityName: address?.cityName?.value, districtName: address?.district?.value, fullAddress: address?.fullAddress}

        if (this.map && address.latitude && address.longitude && this.state.showMap) {
            this.map.panTo([ address.latitude, address.longitude ])
                .then(() => this.addPlacemark([address.latitude, address.longitude],
                    this.getPlacemarkText(mainDataAddr)));
        }

        this.clearValidators();
        this.configureValidators();
    }

    componentDidUpdate(prevProps) {
        if (this.props.currentIndex !== prevProps.currentIndex) {
            this.getChanges()
        }
    }

    clearMap() {
        if (this.map) {
            this.map.geoObjects.removeAll();
        }
    }

    showMap = () => {
        if (this.state.readyToShowMap === undefined || this.state.readyToShowMap === true) {
            mapService.setScriptToHtmlPage()
                .then(() => {
                    this.setState({ readyToShowMap: true });
                    this.initMap();
                })
                .catch(() => this.setState({ readyToShowMap: false }));
        }
    }

    initMap() {
        ymaps.ready(() => {
            const address = this.props.addresses[this.props.currentIndex];
            const defaultCoordinate = [ 45.035483, 38.975285 ]; // Краснодар

            let center = address.latitude && address.longitude
                ? [ address.latitude, address.longitude ]
                : defaultCoordinate;

            this.map = new ymaps.Map("yandex-map", {
                center: center,
                zoom: 10,
                controls: ['searchControl']
            }, {
                // Будет производиться поиск по топонимам и организациям.
                searchControlProvider: 'yandex#search'
            });

            this.searchControlListener();

            this.map.cursors.push('arrow');
            if (address.latitude && address.longitude) {
                let addressString = "";

                if (address.city) {
                    addressString = address.city;
                } else {
                    const region = address?.region?.value || "";
                    const district = address?.district?.value ? `, ${address.district.value}` : "";
                    const cityDto = address?.cityDto?.value ? `, ${address.cityDto.value}` : "";
                    const fullAddress = address.fullAddress ? `, ${address.fullAddress}` : "";

                    addressString = `${region}${district}${cityDto}${fullAddress}`;
                }

                addressString = addressString.trim().replace(/^,/, '');

                this.addPlacemark([ address.latitude, address.longitude ], addressString);
            }
            this.setClickOnMap();
        });
    }

    getClearAddress(data) {
        const {regionName, cityName, districtName, fullAddress} = data

        let updatedFullAddress = fullAddress;

        if (regionName) {
            const regionRegex = new RegExp(regionName, 'g');
            updatedFullAddress = updatedFullAddress.replace(regionRegex, '');
        }

        if (cityName) {
            const cityRegex = new RegExp(cityName, 'g');
            updatedFullAddress = updatedFullAddress.replace(cityRegex, '');
        }

        if (districtName) {
            const districtRegex = new RegExp(districtName, 'g');
            updatedFullAddress = updatedFullAddress.replace(districtRegex, '');
        }

        return updatedFullAddress.replace(/,\s*,/g, ',').replace(/,\s*,/g, ',').replace(/^\s*,|,\s*$/g, '').trim();
    }

    // Обработчик поиска на карте по адресу
    searchControlListener() {
        const searchControl = this.map.controls.get('searchControl');
        searchControl.events.add('resultselect', (event) => {
            const index = event.get('index');
            searchControl.getResult(index).then((result) => {
                const coords = result.geometry.getCoordinates();

                // Преобразование координат в адрес
                ymaps.geocode(coords)
                    .then((response) => {
                        const geoObject = response.geoObjects.get(0);

                        const {cityName, districtName, regionName} = this.getGeocodeData(geoObject)

                        let fullAddress = geoObject.getAddressLine();
                        const latitude = coords[0].toPrecision(8);
                        const longitude = coords[1].toPrecision(8);

                        addressService.getAddressDetails({regionName, cityName, districtName}).then(res => {
                            const addrData = {regionName, cityName, districtName, fullAddress}
                            const updatedFullAddress = this.getClearAddress(addrData)
                            addrData.fullAddress = updatedFullAddress
                            const placemarkText = this.getPlacemarkText(addrData)

                            this.updateAddress(
                                latitude,
                                longitude,
                                updatedFullAddress,
                                res.region,
                                res?.cityDto.value === ' ' ? null : res.cityDto,
                                res.district
                            );
                            this.addPlacemark([latitude, longitude], placemarkText);

                        })
                    })
                    .catch((error) => {
                        console.error('Geocoding error:', error);
                    });
            });
        });
    }

    getGeocodeData (geoObject) {
        const regionName = geoObject
            ?.properties.get('metaDataProperty.GeocoderMetaData.AddressDetails.Country.AdministrativeArea')
            ?.AdministrativeAreaName;

        let cityName = null;
        let districtName = null;

        geoObject.properties.get('metaDataProperty.GeocoderMetaData.Address.Components').forEach(function(component) {
            if (component.kind === 'locality') {
                cityName = component.name;
            }
            if (component.kind === 'district' || component.kind === 'subAdministrativeArea') {
                districtName = component.name;
            }
        });

        // Если район не был найден в Components, получаем его из AddressDetails
        if (!districtName) {
            const administrativeArea = geoObject
                ?.properties.get('metaDataProperty.GeocoderMetaData.AddressDetails.Country.AdministrativeArea');

            if (administrativeArea) {
                // Попробуем получить район из SubAdministrativeArea, если такой существует
                const subAdministrativeArea = administrativeArea.SubAdministrativeArea;
                if (subAdministrativeArea) {
                    districtName = subAdministrativeArea.SubAdministrativeAreaName;
                }

                // Попробуем получить район из Locality, если SubAdministrativeArea не существует
                if (!districtName && administrativeArea.Locality) {
                    const locality = administrativeArea.Locality;

                    // Исключаем возможность присвоения имени улицы как района
                    if (locality.Thoroughfare) {
                        districtName = null;
                    }
                    if (!districtName && locality.DependentLocality) {
                        districtName = locality.DependentLocality.DependentLocalityName;
                    }
                }
            }
        }

        return {cityName, districtName, regionName}
    }

    // Обработчик поиска на карте по клику
   setClickOnMap() {
        this.map.events.add('click', async e => {
            const coords = e.get('coords');
            this.clearMap();
            await this.recognizeCoords(coords);
        });
    }

    async recognizeCoords(coords) {
        const geoObject = await this.getGeoObjectFromCoords(coords);
        const geocodeData = this.getGeocodeData(geoObject);
        geocodeData.fullAddress = geoObject.getAddressLine();

        // Запись координат, по которым не удалось распознать регион.
        const {problemCoords} = this.state;
        if (!problemCoords && !geocodeData.regionName) {
            this.setState({problemCoords: coords, regionNotFound: true});
            return;
        } else if (problemCoords) {
            this.setState({problemCoords: null, regionNotFound: false});
            const address = this.getCurrentAddress();
            geocodeData.regionName = address.region.value;
        }

        const addressDetails = await this.convertingCoordinateToAddress(geocodeData);
        await this.updateAddressAndAddPlaceMark(coords, geocodeData, addressDetails);
    }

    async getGeoObjectFromCoords(coords) {
        const response = await ymaps.geocode(coords);
        return response.geoObjects.get(0);
    }

    async convertingCoordinateToAddress(geocodeData) {
        const {regionName, cityName, districtName} = geocodeData;
        return await addressService.getAddressDetails({regionName, cityName, districtName});
    }

    async updateAddressAndAddPlaceMark(coords, geocodeData, addressDetails) {
        const latitude = coords[0].toPrecision(8);
        const longitude = coords[1].toPrecision(8);
        const updatedFullAddress = this.getClearAddress(geocodeData);

        geocodeData.fullAddress = updatedFullAddress;
        const placeMarkText = this.getPlacemarkText(geocodeData);

        this.updateAddress(
            latitude,
            longitude,
            updatedFullAddress,
            addressDetails.region,
            addressDetails.cityDto.value === "null" ? null : addressDetails.cityDto,
            addressDetails.district
        );

        this.addPlacemark([latitude, longitude], placeMarkText);
    }

    addPlacemark(coordinate, label) {
        const placemark = new ymaps.Placemark(
            coordinate,
            {
                balloonContentHeader: coordinate[0] + ", " + coordinate[1],
                balloonContentBody: label
            }
        );

        this.map.geoObjects.add(placemark);
        placemark.balloon.open();
    }

    updateAddress(latitude, longitude, address, regionObj, city, district) {
        this.onChange(Address.FIELD_LATITUDE, latitude);
        this.onChange(Address.FIELD_LONGITUDE, longitude);
        this.onChange(Address.FIELD_ADDR, address);
        this.onChange(Address.FIELD_FIAS_ID, undefined);
        this.onChange(Address.FIELD_COUNTRY_ISO, undefined);
        this.onChange(Address.FIELD_DISTRICT, district)
        this.onChange(Address.FIELD_CITY_DTO, city);
        this.onChange(Address.FIELD_REGION_ISO, undefined);
        this.onChange(Address.FIELD_REGION_OBJ, regionObj);
        this.setState({
            address: {
                latitude : latitude,
                longitude : longitude,
                fullAddress : address,
                countryIso : undefined,
                regionIso : undefined,
                region: regionObj,
                cityDto: city,
                fiasId : undefined,
                district: district
            }
        });
    }

    updateAddresses() {
        let addresses = this.props.addresses;
        addresses[this.props.currentIndex] = this.getCurrentAddress();

        this.onChange("addresses", addresses);
        this.props.reRender();
    }

    renderEditCard() {
        const address = this.props.addresses[this.props.currentIndex];
        const {regionNotFound} = this.state;
        return (
            <div>
                <Modal.Header closeButton>
                    <Modal.Title>Адрес</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{width: "100%"}}><div className={"d-sm-flex"}>
                    <Card className="m-sm-1 m-0 bg-light" style={{minWidth: "35%"}}>
                        <Card.Body style={{position: "relative"}}>
                            <div>
                                { regionNotFound &&
                                    <Badge variant="danger">
                                        Не удалось определить регион, пожалуйста, выберите его из списка
                                    </Badge>
                                }
                                <FormGroup
                                    title="Регион"
                                    name={regionNotFound ? this.formatFieldName(Address.REGION_NAME) : this.formatFieldName(Address.REGION_NAME_VALUE)}
                                    type={regionNotFound ? "dictionary" : "text"}
                                    optionsType="REGION"
                                    store={this.props.store}
                                    required
                                    readOnly={!regionNotFound}
                                    onChangeHandler={() => this.recognizeCoords(this.state.problemCoords)}
                                />
                            </div>
                            <div>
                                <FormGroup
                                    title="Район"
                                    name={this.formatFieldName(Address.FIELD_DISTRICT_VALUE)}
                                    store={this.props.store}
                                    readOnly={true}/>
                            </div>
                            <div>
                                <FormGroup
                                    title="Город"
                                    name={this.formatFieldName(Address.FIELD_CITY)}
                                    store={this.props.store}
                                    defaultValue={!address.cityDto && address.city}
                                    readOnly={true}/>
                            </div>
                            <div>
                                <FormGroup
                                    title="Адрес"
                                    name={this.formatFieldName(Address.FIELD_ADDR)}
                                    store={this.props.store}
                                    required/>
                            </div>
                            <div className="flex-space-between">
                                <div style={{width: "50%"}}>
                                    <FormGroup
                                        title="Широта"
                                        name={this.formatFieldName(Address.FIELD_LATITUDE)}
                                        store={this.props.store}
                                        readOnly={true}
                                        required/>
                                </div>
                                <div style={{width: "50%"}}>
                                    <FormGroup
                                        title="Долгота"
                                        name={this.formatFieldName(Address.FIELD_LONGITUDE)}
                                        store={this.props.store}
                                        readOnly={true}
                                        required/>
                                </div>
                            </div>
                            <div>
                                <FormGroup
                                    title="Радиус автофактирования км."
                                    name={this.formatFieldName(Address.RADIUS_KM)}
                                    type={"number"}
                                    onChangeHandler={e => this.onChange(Address.RADIUS_KM, parseInt(e))}
                                    store={this.props.store}/>
                            </div>
                            <div style={{display: "flex", justifyContent: "center"}}>
                                <div>
                                    {this.renderClearButton()}
                                </div>
                            </div>
                        </Card.Body>
                        <div style={{fontSize: "medium", fontWeight: "bold", textAlign: "center"}}>
                            {"Выберете точку на карте"}
                        </div>
                    </Card>
                    {this.state.showMap &&
                        <Card className="m-sm-1 m-0 col-12 col-sm-7 bg-light p-0"
                              style={{width: "800px", height: "500px"}}>
                            <Card.Body style={{padding: 0, width: "100%"}}>
                                <div id="yandex-map" style={{minWidth: "100%"}}></div>
                            </Card.Body>
                        </Card>}
                </div></Modal.Body>
                <Modal.Footer>
                    <div style={{margin: "0 auto"}}>
                        {this.renderSaveButton()}
                    </div>
                </Modal.Footer>
            </div>
        );
    }

    render() {
        return this.renderEditCard();
    }

}

const AddressInnerConnected = connect(mapStateToProps)(AddressInner);
export default Address;