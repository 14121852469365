import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Form, Row, Col } from "react-bootstrap";

import StoreWrapper from '../../form/StoreWrapper';
import { requiredValidator } from "../../../validators/simpleValidators";
import BaseForm from '../../form/BaseForm';
import FormGroup from '../../form/FormGroup';
import { setData } from '../../../actions/form';
import Loading from '../../Loading';
import FormValidator from '../../../validators/FormValidator';
import dictionaryService from '../../../services/AbstractDictionaryService';
import departmentService from "../../../services/DepartmentService";
import React from "react";
import {setToastObjAC} from "../../../reducers/toastObj";


class Department extends StoreWrapper {
	constructor(props) {
		super(props);
	}
	render() {
		return (
			<DepartmentInnerConnected {...this.props} store={this.store} />
		);
	}
}

function mapStateToProps(state) {
	return {
		errors: state.errors,
		model: state.model
	};
}

class DepartmentInner extends BaseForm {
	constructor(props) {
		super(props);
		this.configureValidators();

		this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
	}

	configureValidators() {
		this.useValidatorFor(requiredValidator, "value", "forwarder");
		this.validator.addValidator("value", this.valueUniqueValidator.bind(this));
	}

	async valueUniqueValidator() {
		const value = this.props.model.value;
		const forwarderId = this.props.model.forwarder?.id
		if (!value || !forwarderId) {
			return FormValidator.PROMISE_OK;
		}
		const isValid = await dictionaryService.isValueUnique(this.getId(), value, forwarderId, this.props.requestType);
		return isValid ? FormValidator.OK : "запись с таким названием уже зарегистрирована в системе";
	}

	getId() {
		return this.props.location.state?.id || new URLSearchParams(this.props.location.search).get('id');
	}

	async load() {
		const id = this.getId();
		if (id) {
			const model = await departmentService.read(id);
			this.props.store.dispatch(setData(model, this.props.location.state?.action));
		} else {
			this.initModel();
		}
	}

	initModel() {
		this.onChange("active", false);
	}

	handleSubmit(e) {
		e.preventDefault();
		this.submit(() =>
			dictionaryService.save(this.props.model, this.props.requestType)
				.then(() => {
					this.props.history.push(this.props.pathname);
					this.afterSubmit();
				})
		);
	}

	afterSubmit() {
		const data = {show: true, textHeader: "Подразделение сохранено!", delay: 3000};
		if(!this.props.model.id) {
			data.textHeader = "Подразделение добавлено!"
		}
		this.load();
		this.props.setToastObjAC(data);
	}

	getTabs() {
		const tabs = [];
		if (this.getId()) {
			tabs.push(this.getAuditTab(this.getId(), this.props.auditType));
		}
		return tabs;
	}

	getFormTabTitle() {
		const name = this.props.model.value || (this.props.model.id > 0 ? this.props.model.id : '');
		return this.props.tabTitle + " " + name;
	}

	onChangeCheckbox(fieldName, e) {
		this.onChange(fieldName, e.target.checked);
	}

	renderForm() {
		const isReady = !this.getId() || this.props.model.id;
		if (!isReady) {
			return (<Loading/>);
		}
		return (
			<Form>
				<Row>
					<Col>
						<FormGroup title="Наименование" name="value" store={this.props.store} required />
					</Col>
					<Col>
						<FormGroup title="Почтовый адрес" name="address" store={this.props.store} />
					</Col>
					<Col>
						<FormGroup title="Экспедитор"
								   name="forwarder"
								   type="dictionary"
								   optionsType="FORWARDER"
								   store={this.props.store}
								   required
						/>
					</Col>
					<Col>
						<FormGroup title="Активен"
								   name="active"
								   type="checkbox"
								   store={this.props.store}
								   defaultChecked={this.props.model.active}
								   onClick={(e) => this.onChangeCheckbox("active", e)} />
					</Col>
				</Row>								
				{this.renderSaveCancelButtons()}
			</Form>
		);
	}
}

const DepartmentInnerConnected = withRouter(connect(mapStateToProps)(DepartmentInner));

export default connect(mapStateToProps, {setToastObjAC})(Department);