import './Toast.css'
import {PureComponent} from "react";

export class Toast extends PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            title,
            message
        } = this.props;

        return (
            <div className="the-toast">
                <h2 className="the-toast__title">{title}</h2>

                <p className="the-toast__message">{message}</p>
            </div>
        )
    }
}