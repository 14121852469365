import React, {PureComponent} from "react";
import {Form, InputGroup} from "react-bootstrap";
import {classNames} from "../../../helpers/classNames";
import cls from "./AuctionBidInput.module.css";

export class AuctionBidInput extends PureComponent {
    constructor(props) {
        super(props);
    }

    setCurrentBidHandler = (inc = true) => {
        const {
            bid,
            setBid,
            bidStep
        } = this.props;

        let newBid;

        if (bidStep) {
            newBid = inc ? bid + bidStep : bid - bidStep;
        } else {
            newBid = inc ? bid + 1 : bid - 1;
        }

        setBid(newBid)
    }

    changeCurrentBidHandler = (event) => {
        const {
            setBid,
        } = this.props;

        const value = event.target.value;

        if (value.length > 10) return;

        const num = parseInt(value)

        if (!num || num < 0) return;

        setBid(num);
    }

    render() {
        const {
            isDisabled,
            bid
        } = this.props;

        return (
            <InputGroup>
                <InputGroup.Text>Ваша ставка</InputGroup.Text>

                <div
                    className={classNames(cls.counterButton, {}, [cls.minusButton])}
                    onClick={() => this.setCurrentBidHandler(false)}
                >
                    -
                </div>

                <Form.Control
                    type="number"
                    className={cls.currentBidInput}
                    disabled={isDisabled}
                    value={bid.toString()}
                    onChange={this.changeCurrentBidHandler}
                />

                <div
                    className={classNames(cls.counterButton, {}, [cls.plusButton])}
                    onClick={this.setCurrentBidHandler}
                >
                    +
                </div>

                <InputGroup.Text className={cls.currentBidSign}>₽</InputGroup.Text>
            </InputGroup>
        )
    }
}