import {toast} from "react-toastify";
import {Toast} from "../../components/ui/Toast/Toast";
import React from "react";

export class ToastService {
    static toastContent = (props) => {
        return (
            React.createElement(Toast, props, null)
        )
    }

    static info({title = "Информация", message}) {
        const content = this.toastContent({title, message})
        toast.info(content)
    }

    static success({title = "Успех", message}) {
        const content = this.toastContent({title, message})
        toast.success(content)
    }

    static warning({title = "Предупреждение", message}) {
        const content = this.toastContent({title, message})
        toast.warning(content)
    }

    static error({title = "Ошибка", message}) {
        const content = this.toastContent({title, message})
        toast.error(content)
    }

    static default({title = "Уведомление", message}) {
        const content = this.toastContent({title, message})
        toast(content)
    }
}