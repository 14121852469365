export default function useSharedWorker(onMessage = () => {
}) {
    const workerScriptPath = new URL('../workers/sharedWorker.js', import.meta.url)
    const worker = new SharedWorker(workerScriptPath);

    const port = worker.port;

    port.onmessage = function (event) {
        const message = event.data;

        onMessage(message)
    };

    port.start();

    return {
        sharedWorker: worker
    }
}