import React from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import DataTable from '../../table/DataTable';
import vehicleMarkService from "../../../services/VehicleMarkService";

class Marks extends React.Component {

    constructor(props) {
        super(props);
    }

    fetch(params) {
        return vehicleMarkService.list(params);
    }

    openAddForm() {
        window.open(this.props.pathname, "_blank")
    }

    openEditForm(row) {
        window.open(this.props.pathname + `?id=${row.id}`, "_blank")
    }

    render() {
        return (
            <DataTable {...this.props}
                       title = {this.props.title || "Все записи"}
                       sort = {{field: 'value', dir: 'asc'}}
                       openAddForm = {this.openAddForm.bind(this)}
                       openEditForm = {this.openEditForm.bind(this)}
                       fetch = {this.fetch.bind(this)}
                       columns = {[
                           { title: "#", field: "id", className: "id", filter: DataTable.STRING_FILTER },
                           { title: "Название", field: "value", filter: DataTable.STRING_FILTER }
                       ]}
            />
        );
    }
}

export default withRouter(connect(DataTable.mapStateToProps)(Marks));
