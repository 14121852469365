import {axios} from '../axios';

class OperationalReportService {
    static BASE_PATH = "/api/operational-report";

    async post(path, data) {
        const response = await axios.post(`${OperationalReportService.BASE_PATH}/${path}`, data);
        return response.data;
    }

    async getRegions() {
        const response = await axios.get(`${OperationalReportService.BASE_PATH}/regions`);
        return response.data;
    }

    async getDocumentStatusReport(path, data) {
        const response = await axios.get(`${OperationalReportService.BASE_PATH}/${path}/${data}`);
        return response.data;
    }

    async getDepartments() {
        const response = await axios.get(`${OperationalReportService.BASE_PATH}/departments-by-user-permission`);
        return response.data;
    }

    async getLogistics() {
        const response = await axios.get(`${OperationalReportService.BASE_PATH}/logistics-by-user-permission`);
        return response.data;
    }

    async getCommercials() {
        const response = await axios.get(`${OperationalReportService.BASE_PATH}/commercials-by-user-permission`);
        return response.data;
    }

    async getContractors() {
        const response = await axios.get(`${OperationalReportService.BASE_PATH}/contractors`);
        return response.data;
    }

    async getDowntimeReport(data) {
        const response = await axios.post(`${OperationalReportService.BASE_PATH}/downtime-report`, data);
        return response.data;
    }
}

export default new OperationalReportService();