import {axios} from "../axios";

class SmsService {

    async getBalance() {
        const response = await axios.get(`/v1/sms/balance`);
        return response.data.body;
    }

    async sendSms(data) {
        const response = await axios.post(`/v1/sms/send`, data);
        return response.data.body;
    }

    async listOfSms(data) {
        const response = await axios.post(`/v1/sms/list`, data);
        return response.data;
    }
}

export default new SmsService();