import React, {PureComponent} from "react";
import {Button, Card, Form} from "react-bootstrap";
import { send, balance } from "../../../actions/sms";
import { connect } from 'react-redux'
import {SET_TOAST_OBJ} from "../../../actions/types";

class SmsSenderSettings extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            phone: '',
            text: '',
            balance: 0,
            errors: {}
        };
    }

    requestToBalance() {
        const { dispatch } = this.props;
        dispatch(balance())
            .then((res) => { this.setState({ balance: res }); })
            .catch(() => { this.setState({ balance: 0 }) });
    }

    componentDidMount() {
        this.requestToBalance()
    }

    sendSms() {
        const { dispatch } = this.props;
        dispatch(
            send({
                phone: this.state.phone,
                text: this.state.text,
            })
        ).then(() => {
            this.requestToBalance();
            const data = {show: true, textHeader: "Сообщение отправлено!", delay: 3000};
            this.props.dispatch({type: SET_TOAST_OBJ, data});
        });
    }

    getBalanceColor() {
        if (this.state?.balance > 500) {
            return "#00f204";
        }

        if (this.state?.balance < 500 && this.state?.balance > 0) {
            return "orange";
        }

        return "red";
    }

    renderButton(label, type, onClick) {
        return <Form.Group className="mb-3">
            <Button variant={type}
                    style={{ width: "100%" }}
                    onClick={onClick}>
                {label}
            </Button>
        </Form.Group>
    }

    render() {
        return <Form>
            <Card>
                <Card.Header className="flex-space-between">
                    <h3>SMS API (sms4b)</h3>
                    <h3 style={{color: this.getBalanceColor()}}>
                        Баланс: {this.state?.balance}
                    </h3>
                </Card.Header>
                <Card.Body >
                    <Form className="flex-space-between">
                        <div style={{ width: "48%"}}>
                            <Form>
                                <Form.Group className="mb-3">
                                    <Form.Label>Номер телефона получателя</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="+7 111 111 11 11"
                                        name="phone"
                                        onChange={ e => this.setState({ phone: e.target.value }) }
                                    />
                                </Form.Group>
                                &nbsp;
                                <Form.Group className="mb-3">
                                    <Form.Label>Текст сообщения</Form.Label>
                                    <Form.Control as="textarea"
                                        name="text"
                                        onChange={ e => this.setState({ text: e.target.value }) }
                                        rows={7}
                                    />
                                </Form.Group>

                                {this.renderButton("Отправить", "success", () => this.sendSms())}
                            </Form>
                        </div>
                    </Form>
                </Card.Body>
            </Card>
        </Form>
    }
}

function mapStateToProps(state) {
	return {
		errors: state.errors
	};
}

export default connect(mapStateToProps)(SmsSenderSettings);
