import React from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import DataTable from '../../table/DataTable';
import departmentService from "../../../services/DepartmentService";
import TableCell from "../../table/TableCell";

class Departments extends React.PureComponent {
	constructor(props) {
		super(props);
	}

	fetch(params) {
		return departmentService.list(params);
	}

	openAddForm() {
		this.props.history.push(this.props.pathname);
	}

	openEditForm(row) {
		this.props.history.push({ pathname: this.props.pathname, state: { id: row.id } });
	}
	
	render() {
		return (
			<DataTable {...this.props}
				title = {this.props.title || "Все записи"}
				sort = {{field: 'value', dir: 'asc'}}
				openAddForm = {this.openAddForm.bind(this)}
				openEditForm = {this.openEditForm.bind(this)}
				fetch = {this.fetch.bind(this)}
				columns = {[
					{ title: "#", field: "id", className: "id", filter: DataTable.STRING_FILTER },
					{ title: "Название", field: "value", filter: DataTable.STRING_FILTER },
					{ title: "Активен", field: "active", className: "yes-no", formatter: TableCell.booleanFormatter,
						filter: DataTable.BOOLEAN_FILTER}
				]}
			/>
		);
	}
}
export default withRouter(connect(DataTable.mapStateToProps)(Departments));