import * as actionTypes from './auctionActionTypes';

export const setCurrentBid = (value) => {
    return {
        type: actionTypes.SET_BID,
        payload: value
    }
}

export const setData = (data) => ({
    type: actionTypes.SET_DATA,
    payload: data
});

export const setErrors = (data) => ({
    type: actionTypes.SET_ERRORS,
    payload: data
});

export const fetchData = () => ({
    type: actionTypes.FETCH_DATA
});

export const fetchDataSuccess = (data) => ({
    type: actionTypes.FETCH_DATA_SUCCESS,
    payload: data
});

export const fetchDataFailure = (error) => ({
    type: actionTypes.FETCH_DATA_FAILURE,
    payload: error
});

export const makeBid = () => ({
    type: actionTypes.MAKE_BID
});

export const makeBidSuccess = (data) => ({
    type: actionTypes.MAKE_BID_SUCCESS,
    payload: data
});

export const makeBidFailure = (error) => ({
    type: actionTypes.MAKE_BID_FAILURE,
    payload: error
});

