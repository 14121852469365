import * as actionTypes from './auctionActionTypes'
import {initialState} from "./auctionInitialState";

const auctionReducer = (state = initialState.auction, action) => {
    switch (action.type) {
        case actionTypes.SET_DATA:
            return {
                ...state,
                ...action.payload
            };
        case actionTypes.SET_ERRORS:
            return {
                ...state,
                error: action.payload
            };
        case actionTypes.SET_BID:
            return {
                ...state,
                currentBid: action.payload
            };
        case actionTypes.FETCH_DATA:
            return {
                ...state,
                isLoading: true,
                error: null
            };
        case actionTypes.FETCH_DATA_SUCCESS:
            return {
                ...state,
                ...action.payload,
                isLoading: false
            };
        case actionTypes.FETCH_DATA_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            };
        case actionTypes.MAKE_BID:
            return {
                ...state,
                isLoading: true,
                error: null
            };
        case actionTypes.MAKE_BID_SUCCESS:
            return {
                ...state,
                ...action.payload,
                isLoading: false
            };
        case actionTypes.MAKE_BID_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            };
        default:
            return state;
    }
}

export default auctionReducer;