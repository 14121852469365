import React from "react";
import {Row, Col, Form, Card, Button} from "react-bootstrap";
import BaseForm from "../../form/BaseForm";
import FormValidator from "../../../validators/FormValidator";
import FileUploader from "../../FileUploader";
import ValidationErrors from "../../../validators/ValidationErrors";
import { ENUM_NAME } from "../../../Enums";
import organizationService from "../../../services/OrganizationService";
import { ANGEL, securityService } from "../../../services/SecurityService";

class UploadForm extends BaseForm {

	constructor(props) {
		super(props);

		this.typeValidator = this.typeValidator.bind(this);
		this.uniqueTypeValidator = this.uniqueTypeValidator.bind(this);
		this.configureValidators();
	}

	static DOC_TYPE_SIGN = { id: 22, value: "Факсимиле руководителя" };
	static DOC_TYPE_SIGN_ACCOUNTANT = { id: 23, value: "Факсимиле бухгалтера" };
	static DOC_TYPE_STAMP = { id: 24, value: "Печать" };
	static EMAIL_SIGNATURE = { id: 25, value: "Email подпись" };
	static SIGN_KAMENSKY = { id: 26, value: "Факсимиле Каменского" };

	configureValidators() {
		this.validator.addValidator("documentAttachments", this.typeValidator);
		this.validator.addValidator("documentAttachments", this.uniqueTypeValidator);
	}

	getDocumentAttachments() {
		return this.props.model.documentAttachments.filter(a => a.removed === false);
	}

	typeValidator() {
		return this.getDocumentAttachments().every(a => !!a.type) ? FormValidator.OK : "укажите 'Тип документа' в каждой строке";
	}

	uniqueTypeValidator() {
		const arr = this.getDocumentAttachments();
		return new Set(arr.map(a => a.type)).size === arr.length ? FormValidator.OK : "'Тип документа' должен быть уникальным для каждой строки";
	}

	handleSubmit(e) {
		e.preventDefault();
		this.submit(() =>
			organizationService.saveDocumentAttachments(this.props.model.id, this.props.model.documentAttachments)
		);
		this.setSending(false);
	}

	handleInit(e) {
		e.preventDefault();
		this.submit(() =>
			organizationService.init()
		);
		this.setSending(false);
	}

	//	TODO: remove after init
	renderInitButton() {
		return (<Button onClick={this.handleInit.bind(this)} size="sm" variant="primary" className="pull-right" disabled={this.state.sending}>
			<span>Заполнить</span>
		</Button>);
	}

	render() {
		return (
			<Form>
				<Card className="documentAttachments">
					<Card.Body>
						<Row>
							<Col>
								<FileUploader
									store={this.props.store}
									name="documentAttachments"
									showFileName={false}
									showType={true}
									filesLimit={5}
									optionsType={ENUM_NAME.ORG_ATTACHMENT_TYPE}
									acceptedFileTypes={{ "image/*": [".png"] }}
									fileNameFunction={(attachment) => { return attachment.originalName }}
								/>
							</Col>
						</Row>
						<ValidationErrors errors={this.props.errors["documentAttachments"]} />
					</Card.Body>
				</Card>
				<br/>
				<Row className="justify-content-center">
					<Col className="col-auto">
						{this.renderSaveButton()}
					</Col>

					{/*	TODO: remove after init  */}
					{securityService.hasRole(securityService.getUser(), ANGEL) && <Col className="col-auto">
						{this.renderInitButton()}
					</Col>}
				</Row>
			</Form>
		);
	}

}

export default UploadForm;
