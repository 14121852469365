import React from "react";

const ReadOnlyContainer = props => {

	const iterateOverChildren = (children) => {
		return React.Children.map(children, (child) => {
			if (!React.isValidElement(child)) {
				return child;
			}
			const hasChildren = child.props?.hasOwnProperty("children");
			const forceEditable = child.props?.forceEditable;
			return React.cloneElement(child, {
				...child.props,
				...(props.readOnly && !hasChildren && !forceEditable && { readOnly: true }),
				children: iterateOverChildren(child.props.children)
			});
		});
	};

    return (
		props.fullyCovered
			? <div {...props.readOnly && containerProps} >
				<div style={{...props.readOnly && styles.overlap}} />
				{props.children}
			</div>
			: iterateOverChildren(props.children)
    );
};

const styles = {	
    readOnly: {               
		pointerEvents: "none",
		position: "relative",
		MozUserSelect: "none",
		WebkitUserSelect: "none",
		msUserSelect: "none"
    },	
	overlap: {
		width: "100%",
  		height: "100%",
		position: "absolute",
		zIndex: 2,		
		top: 0,
  		left: 0,
		backgroundColor: "#999",
		opacity: 0.2,
		borderRadius: "2px"
	}
}

const containerProps = {
  	style: styles.readOnly,
	tabIndex: "0",
  	onKeyDown: (e) => e.preventDefault()
};

ReadOnlyContainer.defaultProps = {
  	readOnly: true
};

export default ReadOnlyContainer;