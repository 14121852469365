import BaseSelect from "./BaseSelect";
import optionsService from '../../services/OptionsService';

class DictionarySelect extends BaseSelect {
	constructor(props) {
		super(props);
	}
	
	load() {
		this.setState({ loading: true });
		const fetch = this.props.fetchOptions ? this.props.fetchOptions() : this.fetchOptions();
		fetch.then(options => {
			options = this.props.filteredOptions || options || this.props.defaultOptions;
			const data = this.props.all ? [{id: -1, value: this.props.all}, ...options] : options;
			this.setState({ loading: false, options: this.props.optionsFilter ? data.filter(this.props.optionsFilter) : data });
		});
	}

	fetchOptionByValue() {
		return optionsService.loadByValue(this.props.optionsType, this.props.value)
	}
	
	fetchOptions() {
		return optionsService.load(this.props.optionsType, this.props.activeOnly);
	}	
	
	getViewLink() {
		if (["CONTRACTOR", "CONTRACTOR_CARRIER", "CONTRACTOR_CLIENT", "CONTRACTOR_CONTRACT", "CARRIER_CONTRACT"].indexOf(this.props.optionsType) >= 0) {
			return "/control-panel/contractors/contractor?id=" + this.props.value.id;
		}
		return super.getViewLink();
	}
}

export default DictionarySelect;