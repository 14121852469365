import {axios} from '../axios';

class PaymentAccountService {

	async deleteFromContractor(paymentAccount) {
		const response = await axios.post('/api/control-panel/payment-accounts/delete/contractor', paymentAccount);
 		return response.data;
    }

 	async saveToContractor(paymentAccount) {
		const call = paymentAccount.id ? axios.put : axios.post,
 			response = await call("/api/control-panel/payment-accounts/contractor", paymentAccount);
 		return response.data;
    }

	async deleteFromOrganization(paymentAccount) {
		const response = await axios.post('/api/control-panel/payment-accounts/delete/org', paymentAccount);
 		return response.data;
    }

	async saveToOrganization(paymentAccount) {
		const call = paymentAccount.id ? axios.put : axios.post,
 			response = await call("/api/control-panel/payment-accounts/org", paymentAccount);
 		return response.data;
    }

	async options(params) {
		const response = await axios.post("/api/payment-accounts/options", {...params, pageSize: 300, sort: {field: "account"}});
		return response.data.data || [];
	}
	
}

export default new PaymentAccountService();