import React, {PureComponent} from "react";
import cls from "./TheInput.module.css";
import {Form} from "react-bootstrap";
import {classNames} from "../../../helpers/classNames";
import FieldErrors from "../FieldError/FieldError";

export class TheInput extends PureComponent {
    render() {
        const {
            label,
            className,
            value,
            id,
            type = "text",
            errors,
            onChange,
        } = this.props;

        return (
            <div className={classNames(cls.input, {}, [className])}>
                <Form.Label htmlFor={id}>{label}</Form.Label>

                <Form.Control
                    type={type}
                    id={id}
                    className="form-control form-control-sm"
                    value={value}
                    onChange={onChange}
                />

                {(!!errors?.length) && <FieldErrors errors={errors}/>}
            </div>
        )
    }
}