import BaseForm from "../../form/BaseForm";
import {Button, Col, Form, Row} from "react-bootstrap";
import FormGroup from "../../form/FormGroup";
import React from "react";
import {userService} from "../../../services/UserService";
import smsService from "../../../services/SmsService";
import {DRIVER} from "../../../services/SecurityService";

class CrmUserInnerDriver extends BaseForm {
    constructor(props) {
        super(props);

        this.state = { loading: false, seconds: 0 };

        this.startTimer = this.startTimer.bind(this);
        this.sendPassword = this.sendPassword.bind(this);
    }

    startTimer() {
        this.interval = setInterval(() => {
            this.setState(prevState => ({seconds: prevState.seconds - 1}));
        }, 1000);
    }

    componentDidMount() {
        if (!this.getUserId()) {
            this.onChange("crmUser", {});
        }
    }

    render() {
        return (
            <Form>
                {this.renderFioRow()}
                {this.renderContactsRow()}
                {this.renderButtons()}
            </Form>
        );
    }

    renderFioRow() {
        return (
            <Row>
                <Col lg={4} md={12}>
                    <FormGroup title="Фамилия" name="lastName" store={this.props.store} required readOnly/>
                </Col>
                <Col lg={4} md={12}>
                    <FormGroup title="Имя" name="firstName" store={this.props.store} readOnly/>
                </Col>
                <Col lg={4} md={12}>
                    <FormGroup title="Отчество" name="middleName" store={this.props.store} readOnly/>
                </Col>
            </Row>
        );
    }

    renderContactsRow() {
        return (
            <Row>
                <Col lg={2} md={12}>
                    <FormGroup title="Email" type="email" name="email" store={this.props.store} readOnly/>
                </Col>
                <Col lg={2} md={12}>
                    <FormGroup title="Номер телефона" type="phone" name="phone" store={this.props.store} readOnly/>
                </Col>
                <Col lg={3} md={12}>
                    <FormGroup title="Представление наименования" name={this.formatFieldName("viewName" )} store={this.props.store} readOnly/>
                </Col>
                <Col lg={1} md={12}>
                    <FormGroup title="ID" name={this.formatFieldName("id")} store={this.props.store} readOnly />
                </Col>
                <Col>
                    <FormGroup
                        title="Активен"
                        name={this.formatFieldName("active")}
                        type="checkbox"
                        store={this.props.store}
                        defaultChecked={this.props.model.crmUser?.active}
                        onClick={(e) => this.onChange(this.formatFieldName("active"), e.target.checked)} />
                </Col>
            </Row>
        );
    }

    renderButtons() {
        if (!this.getUserId()) {
            return this.renderCreateAccountButton();
        }
        return (
            <div className="text-center">
                {this.renderSendPasswordButton()}
                {this.renderSaveButton()}
                &nbsp;
                {this.renderCancelButton("Выйти")}
            </div>
        );
    }

    renderSendPasswordButton() {
        return (
            <Button onClick={this.sendPassword} size="sm" variant="primary" className="mr-4" disabled={this.state.seconds > 0}>
                <span>{this.state.seconds > 0 ? this.state.seconds : "Отправить новый пароль"}</span>
            </Button>

        )
    }

    async sendPassword() {
        this.startTimer();
        this.setState({loading: true, seconds: 60});
        const newPassword =  this.generateNewPassword();
        const message = "Пароль для входа CRM/Bursa: " + newPassword;
        const passwordReset =  await userService.changePassword({userId: this.getUserId(), password: newPassword});
        if (passwordReset) {
            await smsService.sendSms({phone: this.props.model.phone, text: message});
            const data = {show: true, textHeader: "Пароль отправлен!", delay: 3000};
            this.props.setToast(data);
            this.setState({loading: false});
        }
    }

    renderCreateAccountButton() {
        return (
            <div className="text-center">
                <Button size={"sm"} onClick={() => this.createAccountAndSendPassword()} disabled={this.state.loading}>
                    {this.state.loading && (<span className="spinner-border spinner-border-sm"></span>)}
                    <span>Сохранить и отправить пароль пользователю</span>
                </Button>
            </div>
        );
    }

    async createAccountAndSendPassword() {
        this.setState({loading: true});
        const crmUser = {
            password: this.generateNewPassword(),
            roles: [DRIVER],
            ...this.props.model.crmUser,
            ...this.getPerson()
        };
        const created = await userService.createUserForDriver(crmUser);
        if (created) {
            this.afterSubmit(created);
        }
    }

    getPerson() {
        const {model} = this.props;
        return {
            personId: model.personId,
            firstName: model.firstName,
            lastName: model.lastName,
            middleName: model.middleName,
            email: model.email,
            phone: model.phone,
            contractors: model.contractors,
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({loading: true});
        this.submit(() => {
            userService.save(this.props.model.crmUser)
                .then(crmUser => this.afterSubmit(crmUser))
                .finally(() => this.setSending(false));
        });
    }

    afterSubmit(crmUser) {
        const data = {show: true, textHeader: "Пользователь сохранен!", delay: 3000};
        if(!this.getUserId()) {
            data.textHeader = "Пользователь создан!"
        }
        this.onChange("personUpdate", crmUser.driver.personUpdate);
        this.onChange("update", crmUser.driver.update);
        this.onChange("crmUser", crmUser);
        this.setState({loading: false});
        this.props.setToast(data);
        this.props.load();
    }

    generateNewPassword() {
        const length = 8;
        const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        let password = "";
        for (let i = 0, n = charset.length; i < length; ++i) {
            password += charset.charAt(Math.floor(Math.random() * n));
        }
        return password;
    }

    getUserId() {
        return this.props.model.crmUser?.id;
    }

    formatFieldName(field) {
        return `crmUser.${field}`;
    }
}

export default CrmUserInnerDriver;
