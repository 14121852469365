import FormValidator from "./FormValidator";

const REGEX_LINK = /((((https?:\/\/)(www\.)?)|www\.)[-a-zA-Z0-9+&@#/%?=~_|,!:.;]*[-a-zA-Z0-9+@#/%=&_|])/;
const REGEX_EMAIL = /^[a-zA_Z0-9._%+-]+@[a-zA_Z0-9.-]+\.[a-zA_Z]{2,6}$/;
const REGEX_PHONE_MASK = /^((7|8)\d{10}|77\d{9}|375\d{9})$/;


const requiredValidator = data => {
	return Promise.resolve(!!data ? FormValidator.OK : "Обязательное поле");
};

const phoneValidator = phone => {
	const valid = !phone || phone.match(REGEX_PHONE_MASK);
	return Promise.resolve(valid ? FormValidator.OK : "Неверный формат номера телефона");
}

const decimalValidator = data => {
	const valid = !data || (!isNaN(data) && data >= 0 && data < 999999999);
	return Promise.resolve(valid ? FormValidator.OK : "Некорректный формат");
};

const positiveIntValidator = data => {
	const valid = !data || (!isNaN(data) && data >= 0 && data < 999999999 && Math.round(data) === Number(data));
	return Promise.resolve(valid ? FormValidator.OK : "Некорректный формат");
};

const moneyValidator = decimalValidator;

const percentValidator = data => {
	const valid = !data || (!isNaN(data) && data > 0 && data <= 100);
	return Promise.resolve(valid ? FormValidator.OK : "Некорректный формат");
};

const innValidator = inn => {
	const valid = !inn || (inn.match(/^\d+$/) && (inn.length === 10 || inn.length === 12));
	return Promise.resolve(valid ? FormValidator.OK : "Некорректный формат ИНН");
};

const kppValidator = kpp => {
	const valid = !kpp || kpp.match(/^\d{9}$/);
	return Promise.resolve(valid ? FormValidator.OK : "Некорректный формат КПП");
};

const licenseValidator = license => {
	const valid = !license || license.match(/^\d{10}$/) || license.match(/^\d{4} \d{6}$/);
	return Promise.resolve(valid ? FormValidator.OK : "Некорректный формат Вод. удостоверения");
};

const snilsValidator = snils => {
	const valid = !snils || snils.match(/^\d{11}$/) || snils.match(/^\d{3}-\d{3}-\d{3} \d{2}$/);
	return Promise.resolve(valid ? FormValidator.OK : "Некорректный формат СНИЛС");
};

const passportNumberValidator = passportNumber => {
	const valid = !passportNumber || passportNumber.match(/^\d{10}$/) || passportNumber.match(/^\d{4} \d{6}$/);
	return Promise.resolve(valid ? FormValidator.OK : "Некорректный формат номера паспорта");
};

const ogrnValidator = ogrn => {
	const valid = !ogrn || (ogrn.match(/^\d+$/) && (ogrn.length === 13 || ogrn.length === 15));
	return Promise.resolve(valid ? FormValidator.OK : "Некорректный формат ОГРН");
};

const bikValidator = (bik) => {
	const isValid = !bik || /^\d{8,9}$/.test(bik);
	return Promise.resolve(isValid ? FormValidator.OK : "Некорректный формат БИК");
};

const bankAccountValidator = account => {
	const valid = !account || account.match(/^\d{20}$/);
	return Promise.resolve(valid ? FormValidator.OK : "Некорректный формат номера счета");
};

const vehicleNumberValidator = number => {
	const valid = !number || number.match(/^((([а-яА-Яa-zA-Z]{1}\d{3}[а-яА-Яa-zA-Z]{2})|([а-яА-Яa-zA-Z]{2}\d{3,4}))\(\d{2,3}\))$/);
	return Promise.resolve(valid ? FormValidator.OK : "Некорректный формат номера тс");
};

const vehicleVinValidator = vin => {
	const valid = !vin || vin.match(/^\w{17}$/);
	return Promise.resolve(valid ? FormValidator.OK : "Некорректный формат VIN");
};

const linkValidator = link => {
	const valid = !link || link.match(REGEX_LINK);
	return Promise.resolve(valid ? FormValidator.OK : "Некорректный формат ссылки");
};

const emailValidator = email => {
	const valid = !email || email.match(REGEX_EMAIL);
	return Promise.resolve(valid ? FormValidator.OK : "Некорректный формат email");
};


export {requiredValidator, moneyValidator, decimalValidator, innValidator, kppValidator, ogrnValidator,
	bikValidator, bankAccountValidator, positiveIntValidator, percentValidator, vehicleNumberValidator,
	vehicleVinValidator, linkValidator, emailValidator, phoneValidator, licenseValidator, snilsValidator,
	passportNumberValidator};
