import BaseForm from "../../form/BaseForm";
import organizationContractorHistoryService from "../../../services/OrganizationContractorHistoryService";
import {Button, Card, Table} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import ru from "date-fns/locale/ru";
import DictionarySelect from "../../form/DictionarySelect";
import React from "react";

class OrganizationContractorHistoryTable extends BaseForm {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        organizationContractorHistoryService.getOrganizationContractorHistory(this.props.organizationId)
            .then(response => this.setState({ organizationContractorHistory: response }));
    }

    renderAddButton() {
        return <Button onClick={() => this.openModal()}
                       size="sm"
                       variant="outline-primary">
            Добавить
        </Button>
    }

    openModal(e) {
        e && this.setModelById(e.target.id);

        this.setState({showModal: true})
    }

    setModelById(id) {
        const selected = this.state.organizationContractorHistory?.find(o => o.id === Number(id));

        this.setState({
            id: id,
            organization: { id: this.props.organizationId },
            contractor: selected.contractor,
            startedDateTime: selected.startedDateTime
        });
    }

    deleteLine(id) {
        organizationContractorHistoryService.deleteOrganizationContractorHistory(id)
            .then(() =>  this.setState({organizationContractorHistory: this.state.organizationContractorHistory.filter(o => o.id !== id)}));
    }

    submit() {
        const data = this.getData();
        const response = this.state.id
                ? organizationContractorHistoryService.updateOrganizationContractorHistory(data)
                : organizationContractorHistoryService.addOrganizationContractorHistory(data)

        response.then(response => this.clearModelAndUpdateList(response));
    }

    getData() {
        return {
            id: this.state.id,
            organization: { id: this.props.organizationId },
            contractor: this.state.contractor,
            startedDateTime: this.state.startedDateTime
        };
    }

    clearModelAndUpdateList(organizationContractorHistory) {
        this.setState({
            organizationContractorHistory,
            id: null,
            showModal: false,
            contractor: null,
            startedDateTime: null
        });
    }

    FIELDS_NAME = [
        "Организация",
        "Контрагент",
        "Дата начала"
    ]

    datePicker(selected, onChange) {
        return <DatePicker
            selected={selected ? new Date(selected) : null}
            dateFormat="dd.MM.yyyy HH:mm"
            showTimeSelect={true}
            locale={ru}
            autoFocus
            shouldCloseOnSelect={true}
            onChange={onChange}
        />
    }

    dictionarySelect(name, onChange) {
        return <DictionarySelect
            menuPosition={"fixed"}
            closeMenuOnScroll={true}
            value={this.state.contractor}
            onChange={onChange}
            optionsType={name}
        />
    }

    renderModal() {
        return <Modal size="lg"
                      show={this.state.showModal}
                      onHide={() => this.setState({showModal: false})}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Новая запись
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="flex-space-between">
                <div>
                    <div>Контрагент</div>
                    <div style={{marginRight: "15px", width: "250px"}}>
                        {this.dictionarySelect("CONTRACTOR", contractor => this.setState({ contractor: contractor }))}
                    </div>
                </div>
                <div>
                    <div>Дата начала</div>
                    <div style={{marginRight: "15px"}}>
                        {this.datePicker(this.state["startedDateTime"], date => this.setState({ startedDateTime: date }))}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer style={{ display: "flex", justifyContent: "center" }}>
                <Button title={"Необходимо контрагента и время начала"}
                        onClick={() => this.submit()}
                        size="sm" variant="primary"
                        disabled={!this.state.contractor || !this.state.startedDateTime}
                >
                    Сохранить
                </Button>
            </Modal.Footer>
        </Modal>
    }

    renderTableData(id, value) {
        return <td id={id} onDoubleClick={e => this.openModal(e)}>{value}</td>
    }

    render() {
        return <Card>
            {this.renderModal()}
            <Card.Header style={{ display: "flex", justifyContent: "space-between" }}>
                <h5>История соответствия организаци к контрагенту</h5>
                <div>{this.renderAddButton()}</div>
            </Card.Header>
            <Card.Body style={{padding: 0}}>
                <Table striped bordered hover size="sm">
                    <thead style={{fontWeight: "bold"}}>
                    <tr>
                        {this.FIELDS_NAME.map(fn => <td>{fn}</td>)}
                    </tr>
                    </thead>
                    <tbody>
                    {
                        this.state?.organizationContractorHistory?.map(h =>
                            <tr>
                                {this.renderTableData(h.id, h.organization.value)}
                                {this.renderTableData(h.id, h.contractor.value)}
                                {this.renderTableData(h.id, new Date(h.startedDateTime).toLocaleString())}
                                <td style={{textAlign: "center", width: "60px"}}>
                                    <i className={"fa fa-times clear-icon"} onClick={() => this.deleteLine(h.id) }></i>
                                </td>
                            </tr>
                        )
                    }
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    }
}

export default OrganizationContractorHistoryTable;