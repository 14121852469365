import React, {Fragment, PureComponent} from "react";
import {securityService} from "../../services/SecurityService";

class WarningMessage extends PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const {variant, message, title} = this.props;
        const user = securityService.getUser();
        const isExternal = securityService.isExternal(user);
        if (!isExternal) {
            return <Fragment/>;
        }

        return (
            <div className={`n-${variant}`}>
                <text>{title}</text>
                <text>
                    {message}
                </text>
            </div>
        );
    }
}

export default WarningMessage;
