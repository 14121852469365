import {RSocketWebSocket} from "../services/SocketService/RSocketWebSocket";
import {setSocket} from "../store/SocketStore/socketActions";
import store from "../store";
import {env} from "../env";

export async function useSocket() {
    const socket = await createSocketConnection();
    store.dispatch(setSocket(socket))
    return socket;
}

async function createSocketConnection() {
    const socket = new RSocketWebSocket();
    const websocketUrl = env.REACT_APP_WS_URL ?? 'ws://localhost:7000'
    await socket.connect(websocketUrl);
    return socket
}

