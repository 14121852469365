export const AUCTION_BID_TYPE_OPTIONS = [
    {label: "свободная", value: "free"},
    {label: "фиксированная", value: "fixed"}
];

export const AUCTION_NDS_OPTIONS = [
    {label: "без НДС", value: "no_nds"},
    {label: "с НДС", value: "nds"}
];

export const initialState = {
    auctionForm: {
        id: null,

        auctionBidType: AUCTION_BID_TYPE_OPTIONS[0].value,
        auctionNdsType: AUCTION_NDS_OPTIONS[0].value,
        firstPrice: 0,
        minPrice: 0,
        status: "",
        timeStart: "",
        timeFinish: "",

        bidStep: 0,
        isOneBidOnly: false,
        isBuyoutAvailable: true,

        isLoading: false,
        error: null,

        validationErrors: {
            firstPrice: [],
            minPrice: [],
            bidStep: [],
            timeStart: [],
            timeFinish: []
        },

        auctionBidTypeOptions: AUCTION_BID_TYPE_OPTIONS,
        auctionNdsOptions: AUCTION_NDS_OPTIONS
    }
};