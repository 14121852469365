import BaseForm from "../../form/BaseForm";
import SLImport from "./SLImport";
import React from "react";
import {Button, Card, Col, Form, Row, Table} from "react-bootstrap";
import exportImportService from "../../../services/ExportImportService";
import {NavLink} from "react-router-dom";
import TableCell from "../../table/TableCell";
import {SET_TOAST_OBJ} from "../../../actions/types";
import StoreWrapper from "../../form/StoreWrapper";
import {connect} from "react-redux";
import {setToastObjAC} from "../../../reducers/toastObj";

class SupplyImport extends StoreWrapper {
    constructor(props) {
        super(props);
    }

    static get FIELD_LOADING_DT() { return 'loadingDt' };
    static get ACTUAL_LOADING_DT() { return 'actualLoadingDt' };
    static get UNLOADING_DT() { return 'unLoadingDt' };
    static get CLIENT_QUOTE_NUMBER() { return 'clientQuoteNumber' };
    static get DRIVER_FIO() { return 'driverFio' };
    static get VEHICLE_NUMBER() { return 'vehicleNumber' };
    static get KM() { return 'km' };
    static get EXPENSES() { return 'expenses' };
    static get HOURS() { return 'hours' };
    static get CLIENT_PRICE_BEFORE() { return 'clientPriceBefore' };
    static get CLIENT_PRICE_AFTER() { return 'clientPriceAfter' };
    static get QUOTE_ID() { return 'quoteId' };
    static get QUOTE_NUMBER() { return 'quoteNumber' };
    static get QUOTE_COUNT() { return 'quoteCount' };


    render() {
        return (
            <SupplyImportInnerConnected {...this.props} store={this.store} />
        );
    }
}

function mapGlobalStateToProps(state) {
    return {
        toastObj2: state.toastObj
    };
}

function mapStateToProps(state) {
    return {
        errors: state.errors,
        model: state.model,
        toastObj: state.toastObj
    };
}

class SupplyImportInner extends BaseForm {
    constructor(props) {
        super(props);

        this.handleImport = this.handleImport.bind(this);

        this.state = {
            data: null,
            selectedRows: [],
            isLoading: false,
            isImporting: false,
            selectAll: false,
            editCell: {},
            tableHeight: this.getTableHeight()
        }

        this.editCellRef = React.createRef();
    }

    resizeListener() {
        this.setState({tableHeight: this.getTableHeight()});
    }

    getTableHeight() {
        return window.innerHeight - 170;
    }

    handleImport(type, file) {
        this.setState({isLoading: true});
        exportImportService.loadQuoteListBySupplyFile(file)
            .then(response => this.setState({data: response, isLoading: false}));
    }

    handleImportClientPrice() {
        this.setState({isImporting: true});
        exportImportService.importClientPriceFromSupplies(this.state.selectedRows)
            .then(count => {
                this.setState({isImporting: false});
                const data = { show: true, delay: 3000, textHeader:`Импорт прошел успешно, обновлено заявок: ${count}`, className: "bg-success" };
                this.props.dispatch({type: SET_TOAST_OBJ, data});
            })
            .catch(() => {
                this.setState({isImporting: false});
                const data = { show: true, delay: 3000, textHeader: `Импорт завершился с ошибкой`, className: "bg-danger" };
                this.props.dispatch({type: SET_TOAST_OBJ, data});
            });
    }

    handleSelectAllRows() {
        const rows = this.state.data,
            isSelectAll = this.state.selectAll,
            rowsWithQuoteId = rows.filter(row => row.quoteId);

        this.setState({selectedRows: isSelectAll ? [] : rowsWithQuoteId, selectAll: !isSelectAll});
        rows.forEach(row => document.getElementById(row.id).checked = row.quoteId ? !isSelectAll : false);
    }

    handleRowSelect(supply) {
        let selectedRows = this.state.selectedRows;
        if (selectedRows.some(row => row.id === supply.id)) {
            selectedRows = selectedRows.filter(row => row.id !== supply.id);
        } else {
            selectedRows = [...this.state.selectedRows, supply];
        }
        this.setState({ selectedRows: selectedRows });
    }

    setCellEditor(e, row) {
        e.preventDefault();
        this.setState({
            editCell: { row: row }
        });
    }

    formatCell(row, field) {
        const value = row[field],
            editCell = this.state.editCell;
        if (editCell && row && row.id === editCell.row?.id) {
            return (<input
                type="number"
                min="0"
                autoFocus={true}
                ref={r => this.editCellRef = r}
                defaultValue={value}
                onBlur={e => this.completeCellEditor(e, row, field)}
            />);
        } else {
            return TableCell.decimalFormatter(value);
        }
    }

    completeCellEditor(e, row, field) {
        const value = 1 * this.editCellRef.value,
            selectedRows = this.state.selectedRows || [],
            data = this.state.data;
        if (value >= 0) {
            row[field] = value;
            const editedData = this.replaceArrayElement(data, row)
            const editedSelectedRows = this.replaceArrayElement(selectedRows, row)
            this.setState({ data: editedData, selectedRows: editedSelectedRows, editCell: {} });
        } else {
            this.setState({ editCell: {} });
        }
    }

    replaceArrayElement(array, element) {
        return array && array.length > 0
            ? array.map(item => item.id === element.id ? element : item)
            : [];
    }

    renderFileImport() {
        return (
            <React.Fragment>
                <SLImport
                    title={"Поставки"}
                    handleImport={this.handleImport}
                    isBusy={false}
                    isLoading={this.state.isLoading}
                ></SLImport>
            </React.Fragment>
        );
    }

    renderSelectButtons() {
        return (
            <div>
                <Form.Check
                    label="Выбрать все"
                    disabled={!this.state.data}
                    onClick={() => this.handleSelectAllRows()}
                />
            </div>
        );
    }

    renderHeader() {
        const rows = this.state.selectedRows,
            isDisabled = !rows || rows.length === 0,
            isImporting = this.state.isImporting;
        return (
          <Row>
              <Col>
                  <Button variant="outline-success" size="sm" disabled={isDisabled || isImporting} onClick={() => this.handleImportClientPrice()}>
                      {isImporting && (<span className="spinner-border spinner-border-sm"></span>)}
                      <i className="fa fa-upload"/> Выполнить импорт
                  </Button>
              </Col>
              <Col><h4 className="text-center p-0">Поставки</h4></Col>
              <Col></Col>
          </Row>
        );
    }

    renderTable() {
        const supplies = this.state.data;
        return (
            <Card className="m-1 bg-light merge">
                <Card.Header>{this.renderHeader()}</Card.Header>
                <Card.Body style={{overflow: "auto", maxHeight: this.state.tableHeight}}>
                    <div>
                        <Table title="Поставки" striped bordered hover size="sm" className="selectable supply-table">
                            <thead>
                            <tr className={"text-center"}>
                                <th className="pr-2" style={{whiteSpace: "nowrap"}}>{this.renderSelectButtons()}</th>
                                <th className="col-1">Номер поставки</th>
                                <th className="col-1">Номер заявки</th>
                                <th className="col-1">Дата погрузки</th>
                                <th className="col-1">Факт загрузки</th>
                                <th className="col-1">Дата выгрузки</th>
                                <th className="col-1">ФИО водителя</th>
                                <th className="col-1">Гос. рег. знак автомобиля</th>
                                <th className="col-1">Км</th>
                                <th className="col-1">Переменные расходы без  НДС, руб. в час</th>
                                <th className="col-1">Количество часов на выполнение рейса</th>
                                <th className="col-1">Ставка до</th>
                                <th className="col-1">Ставка после</th>
                            </tr>
                            </thead>
                            <tbody>
                            {supplies && supplies.map(this.renderSupplyTableRow.bind(this))}
                            </tbody>
                        </Table>
                    </div>
                </Card.Body>
            </Card>
        );
    }

    renderSupplyTableRow(row, index) {
        return (
        <tr key={`supply-table-row-${index}`} style={{whiteSpace: "nowrap"}}>
                {this.renderSelectColumn(row)}
                <td>{row[SupplyImport.CLIENT_QUOTE_NUMBER]}</td>
                <td>{this.renderQuoteNumber(row)}</td>
                <td>{row[SupplyImport.FIELD_LOADING_DT]}</td>
                <td>{row[SupplyImport.ACTUAL_LOADING_DT]}</td>
                <td>{row[SupplyImport.UNLOADING_DT]}</td>
                <td>{row[SupplyImport.DRIVER_FIO]}</td>
                <td>{row[SupplyImport.VEHICLE_NUMBER]}</td>
                <td>{row[SupplyImport.KM]}</td>
                <td>{row[SupplyImport.EXPENSES]}</td>
                <td>{row[SupplyImport.HOURS]}</td>
                <td>{this.renderClientPriceBefore(row)}</td>
                <td onClick={(e) => this.setCellEditor(e, row)}>
                    {this.formatCell(row,[SupplyImport.CLIENT_PRICE_AFTER])}
                </td>
            </tr>
        );
    }

    validateQuoteData(quoteCount) {
        let result = null;
        if (quoteCount === 0) {
            result = "";
        } else if (quoteCount > 1) {
            result = <span className={"text-danger"}>К этому номеру привязано больше 1 заявки!</span>;
        }
        return result;
    }

    renderQuoteNumber(supply) {
        const quoteCount = supply[SupplyImport.QUOTE_COUNT];
        const quoteNumber = supply[SupplyImport.QUOTE_ID] + ";" + supply[SupplyImport.QUOTE_NUMBER];
        const message = this.validateQuoteData(quoteCount);
        return message ??
            (
                <NavLink to={`/quotes/quote?id=${supply.quoteId}`} target={"_blank"}>
                    <span>{quoteNumber}</span>
                </NavLink>
            );
    }

    renderClientPriceBefore(supply) {
        const quoteCount = supply[SupplyImport.QUOTE_COUNT];
        const message = this.validateQuoteData(quoteCount);
        return message ?? <span>{supply[SupplyImport.CLIENT_PRICE_BEFORE]}</span>;
    }

    renderSelectColumn(supply) {
        const id = supply.id;
        return (
            <React.Fragment>
                <td className="actions">
                    <Form.Check
                        id={id}
                        disabled={supply[SupplyImport.QUOTE_COUNT] === 0 || !supply.quoteId}
                        onClick={() => this.handleRowSelect(supply)}
                    />
                </td>
            </React.Fragment>
        );
    }

    render() {
        return (
            <Form>
                <Row>
                    <Col lg={4} className="ml-1 mb-3">
                        { this.renderFileImport() }
                    </Col>
                </Row>
                <Row>
                    <Col>
                        { this.renderTable() }
                    </Col>
                </Row>
            </Form>
        );
    }
}
const SupplyImportInnerConnected = connect(mapStateToProps, {setToastObjAC})(SupplyImportInner);
export default connect(mapGlobalStateToProps)(SupplyImport);
