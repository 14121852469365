import React from "react";
import { connect } from "react-redux";
import {Form, Row, Col, Dropdown, Tab, Alert, Button, Modal} from "react-bootstrap";

import quoteService from "../../../services/QuoteService";
import {
    requiredValidator,
    moneyValidator,
    positiveIntValidator,
    decimalValidator
} from "../../../validators/simpleValidators";
import StoreWrapper from "../../form/StoreWrapper";
import BaseForm from "../../form/BaseForm";
import {AuditTable, QUOTE} from "../../AuditTable/AuditTable";
import QuoteForm from "./QuoteForm";
import FinalQuoteForm from "./FinalQuoteForm";
import { setData } from "../../../actions/form";
import Loading from "../../Loading";
import docService, {PDF, XLSX, DOCX, EFES, EMPTYFOOTER} from "../../../services/DocService";
import UploadForm from "../UploadForm";
import {
    securityService,
    COMMERCIAL,
    CARRIER,
    DRIVER,
    LOGISTIC,
    APPROVAL,
    CLIENT,
    HEAD_OF_DEPARTMENT,
    ADMIN
} from "../../../services/SecurityService";
import contractorService from "../../../services/ContractorService";
import {userService} from "../../../services/UserService";
import personService from "../../../services/PersonService";
import {RoutePoint, Attachment, RequestStatus, QuoteType, TaskType, Option} from "../../../Const";
import {
	attachmentsValidator,
	carrierContractValidator,
	clientContractValidator,
	clientOrganizationValidator,
	carrierOrganizationValidator,
	clientContractsValidator,
	carrierContractsValidator,
	fromDateBeforeToValidator,
	loadUnloadDatesValidator,
	routeValidator,
	statusValidator,
	temperatureValidator,
	truckNumberValidator,
	statusFreeValidator,
	carrierValidator,
	carrierPriceValidator,
	driverValidator,
	kilometreValidator,
	driverValidatorAsAppr,
	clientOrganizationValidatorAsAppr,
	carrierOrganizationValidatorAsAppr,
	carrierValidatorAsAppr,
	carrierPriceValidatorAsAppr,
	departmentValidator,
	carrierPaymentDelayValidator,
	freeToValidator,
	statusAtWorkValidator,
	statusFreeEmptyFieldsValidator,
	contractorPriceValidator,
	freeCarrierOrganizationValidator,
	lockedValidator,
	statusRequiresSignatureValidator,
    logisticDepManagerValidator
} from "../../../validators/quoteValidators";
import moment from "moment";
import EmailForm from "../../email/EmailForm";
import emailService from "../../../services/EmailService";
import TaskTable from "../../tasktracker/task/TaskTable";
import MonitoringMapQuote from "../../map/MonitoringMapQuote";
import vehicleService from "../../../services/VehicleService";
import {setToastObjAC} from "../../../reducers/toastObj";
import CarrierQuoteForm from "./CarrierQuoteForm";
import organizationService from "../../../services/OrganizationService";
import organizationContractorHistoryService from "../../../services/OrganizationContractorHistoryService";
import {NavLink} from "react-router-dom";
import Auction from "../../Auction/Auction/Auction";
import AuctionSettings from "../../Auction/AuctionSettings/AuctionSettings";
import Enums from "../../../Enums";

function mapGlobalStateToProps(state, ownProps) {
    return {
        user: state.auth.user,
        model: ownProps.location?.state?.model || {}
    };
}

class Quote extends StoreWrapper {
    constructor(props) {
        super(props);

        if (Object.keys(this.props.model).length !== 0) {
            this.store.dispatch(setData(this.props.model, this.props.location?.state?.action));
        }
    }

    render() {
        return (
            <QuoteInnerConnected {...this.props} store={this.store}/>
        );
    }
}

function mapStateToProps(state) {
    const user = {
        ...state.user,
        isDriver: securityService.hasRoleButAngel(state.user, DRIVER),
        isCarrier: securityService.hasRoleButAngel(state.user, CARRIER),
        isClient: securityService.hasRoleButAngel(state.user, CLIENT),
        isCommercial: securityService.hasRoleButAngel(state.user, COMMERCIAL),
        isLogistic: securityService.hasRoleButAngel(state.user, LOGISTIC)
    }
    return {
        errors: state.errors,
        model: state.model,
        user: user,
        copy: state.copy
    };
}

class QuoteInner extends BaseForm {

    constructor(props) {
        super(props);

		this.handleSubmit = this.handleSubmit.bind(this);
		this.addLoading = this.addLoading.bind(this);
		this.addUnloading = this.addUnloading.bind(this);
		this.addCustoms = this.addCustoms.bind(this);
		this.openTaskForm = this.openTaskForm.bind(this);
		this.isSameQuoteUserForwarder = this.isSameQuoteUserForwarder.bind(this);
		this.configureValidators();
		this.initDefaultValues();
        this.getAgreementTab = this.getAgreementTab.bind(this);
        this.getWasActual = this.getWasActual.bind(this);

        this.state = {
            mainClientContractExists: true,
            mainCarrierContractExists: true,
            isPassportDataExists: false,
            showModalForSendMail: false,
            isLoadingTaskData: false,
            vehiclesIsNotApproved: false,
            preStatus: {},
            mainQuote: null,
            wasActual: false
        }
    }

	initDefaultValues() {
		if (this.getQuoteId()) {
			return;
		}
		this.initDefaultCommercialDepManager();
		this.initDefaultLogisticDepManager();
		this.initDefaultRoutePoints();
		this.initDefaultStatus();
		this.onChange("quantity", 1);
	}

	initDefaultStatus() {
		this.onChange("status", RequestStatus.ACTUAL);
	}

    initDefaultRoutePoints() {
        this.onChange("routePoints", RoutePoint.createDefaultRoutePoints());
    }

    initDefaultCommercialDepManager() {
        if (this.props.user.isCommercial) {
            this.onChange("commercialDepManager", {id: this.props.user.id, value: this.props.user.fio});
        }
    }

    initDefaultLogisticDepManager() {
        if (this.props.user.isLogistic) {
            this.onChange("logisticDepManager", {id: this.props.user.id, value: this.props.user.fio});
        }
    }

    configureValidators() {
        this.useValidatorFor(requiredValidator,
            "status",
            "source",
            "dt",
            "expireDt",
            "commercialDepManager",
            "organization",
            "contractor"
        );
        this.useValidatorFor(moneyValidator,
            "priceInitial",
            "contractorPrice",
            "carrierPrice",
            "contractorExpenses",
            "carrierExpenses",
            "debtCarrier",
            "gsmDiscount",
            "gsmStrait",
            "carrierGsm",
            "cargoPrice",
            "clientPrepayment"
        );
        this.useValidatorFor(positiveIntValidator,
            "quantity",
            "contractorPaymentDelay",
            "carrierPaymentDelay"
        );
        this.useValidatorFor(decimalValidator, "routePoints.0.weight");

        this.useDirectModelValidatorFor(fromDateBeforeToValidator, "expireDt");
        this.useDirectModelValidatorFor(routeValidator, "route");
        this.useDirectModelValidatorFor(loadUnloadDatesValidator, "route");
        this.useDirectModelValidatorFor(temperatureValidator, "route");
        this.useDirectModelValidatorFor(attachmentsValidator, "route");
        this.useDirectModelValidatorFor(statusValidator, "status");
        this.useDirectModelValidatorFor(statusFreeValidator, "status");
        this.useDirectModelValidatorFor(statusRequiresSignatureValidator, "status", "carrierOrganization", "clientContract", "carrier", "carrierContract", "driver", "truck");
        this.useDirectModelValidatorFor(lockedValidator, "status");

        this.useDirectModelValidatorFor(clientContractValidator, "clientContract");
        this.useDirectModelValidatorFor(carrierContractValidator, "carrierContract");
        this.useDirectModelValidatorFor(clientContractsValidator, "contractor");
        this.useDirectModelValidatorFor(carrierContractsValidator, "carrier");
        this.useDirectModelValidatorFor(truckNumberValidator, "truck");

		this.useDirectModelValidatorFor(clientOrganizationValidator, "organization");
		this.useDirectModelValidatorFor(carrierOrganizationValidator, "carrierOrganization");
		this.useDirectModelValidatorFor(freeCarrierOrganizationValidator, "carrierOrganization");
		this.useDirectModelValidatorFor(carrierValidator, "carrier");
		this.useDirectModelValidatorFor(carrierPriceValidator, "carrierPrice");
		this.useDirectModelValidatorFor(driverValidator, "driver");
		this.useDirectModelValidatorFor(kilometreValidator, "kilometre");
		this.useDirectModelValidatorFor(departmentValidator, "department");
		this.useDirectModelValidatorFor(carrierPaymentDelayValidator, "carrierPaymentDelay");
		this.useDirectModelValidatorFor(freeToValidator, "freeTo");
		this.useDirectModelValidatorFor(statusAtWorkValidator, "status", "logisticDepManager");
		this.useDirectModelValidatorFor(statusFreeEmptyFieldsValidator, "driver", "truck", "carrier");
		this.useDirectModelValidatorFor(contractorPriceValidator, "contractorPrice");
		this.useDirectModelValidatorFor(logisticDepManagerValidator, "logisticDepManager")
	}

    configureCheckApprovedValidators(isChecked) {
        if (isChecked) {
            this.useDirectModelValidatorFor(driverValidatorAsAppr, "driver");
            this.useDirectModelValidatorFor(clientOrganizationValidatorAsAppr, "organization");
            this.useDirectModelValidatorFor(carrierOrganizationValidatorAsAppr, "carrierOrganization");
            this.useDirectModelValidatorFor(carrierValidatorAsAppr, "carrier");
            this.useDirectModelValidatorFor(carrierPriceValidatorAsAppr, "carrierPrice");
            this.useDirectModelValidatorFor(driverValidatorAsAppr, "driver");
        } else {
            this.validator.deleteValidator("driver", driverValidatorAsAppr)
            this.validator.deleteValidator("organization", clientOrganizationValidatorAsAppr)
            this.validator.deleteValidator("carrierOrganization", carrierOrganizationValidatorAsAppr)
            this.validator.deleteValidator("carrier", carrierValidatorAsAppr)
            this.validator.deleteValidator("carrierPrice", carrierPriceValidatorAsAppr)
            this.validator.deleteValidator("driver", driverValidatorAsAppr)
        }
    }

    hasStatus(status) {
        return this.props.model.status?.id === status.id;
    }

    async isNotApprovedVehicles(truck, trailer) {
        if (trailer === null && truck) {
            const data = await vehicleService.isNotApprovedTruckOnly(truck.id);
            return this.setState({vehiclesIsNotApproved: data});
        }
        const data = await vehicleService.isNotApproved(truck?.id, trailer?.id)
        return this.setState({vehiclesIsNotApproved: data});
    }

    async load() {
        const id = this.getQuoteId();
        const hasPreloadedModel = Object.keys(this.props.model).length;
        if (id && !hasPreloadedModel) {
            const quote = await quoteService.byId(id);
            const action = new URLSearchParams(this.props.location.search).get("action")
            const authorizedToCopy = !this.props.user.isDriver && !this.props.user.isCarrier;
            if (action === "copy" && authorizedToCopy) {
                const copiedQuote = await quoteService.resetFieldsInActiveEntity(quote);
                this.props.store.dispatch(setData(copiedQuote, action));
            } else {
                this.props.store.dispatch(setData(quote, action));
            }
            if (this.showDocs()) {
                await this.isNotApprovedVehicles(quote?.truck, quote?.trailer);
            }

            this.initPreStatus();

            this.loadLastDateTimeSendingEmail(EmailForm.CONTRACTOR);
            this.loadLastDateTimeSendingEmail(EmailForm.CARRIER);

            if (new URLSearchParams(this.props.location.search).get("mode") === "creation") {
                this.createLinkedQuote();
            }
        }
    }

    initPreStatus() {
        const status = this.props.model.status || null;
        this.setState({preStatus: status});
    }

    getQuoteId() {
        return this.props.location.state?.id || this.props.model?.id || new URLSearchParams(this.props.location.search).get("id");
    }

    getType() {
        return this.props.type || QuoteType.QUOTE;
    }

    isActual() {
        return this.props.model.status?.id === RequestStatus.ACTUAL.id;
    }

    isAtWork() {
        return this.props.model.status?.id === RequestStatus.AT_WORK.id;
    }

    isRequiresSignature() {
        return this.props.model.status?.id === RequestStatus.ON_SIGNING.id;
    }

    redirectOrStay(res) {
        const data = {show: true, textHeader: "Заявка сохранена!", delay: 3000};
        if (this.showDocs() || !this.isActual()) {
            this.setSending(false);
        }
        if (this.props.model.id === undefined || this.props.model.id === null || this.props.model.id <= 0) {
            this.onChange("id", res.id);
            this.redirectToBackOrDefaultUrl(`/quotes/quote?id=${res.id}`);
            this.load()
            data.textHeader = "Заявка создана!"
        }
        this.props.setToastObjAC(data)
    }

    async afterSubmit(quote) {
        if (!quote) {
            return;
        }
        this.onChange('forwarder', quote.forwarder);
        this.onChange('createdAt', quote.createdAt);
        this.onChange('version', quote.version);
        this.onChange('auctionId', quote.auctionId);
        if (quote.mainQuote) {
            this.onChange('mainQuote.version', quote.mainQuote?.version);
        }
        this.setState({preStatus: quote.status});
        this.redirectOrStay(quote);
    }

    handleSubmit(e) {
        e.preventDefault();
        this.configureCheckApprovedValidators(false);
        const model = this.prepareModel();
        const baseOn = new URLSearchParams(this.props.location.search).get("baseOn") === 'true';

        // TODO here we make auctionId nullable to prevent two quotes point on one auction
        const requestData = {...model, auctionId: null};

        this.submit(() => {
                quoteService.save(requestData, baseOn)
                    .catch(() => this.setSending(false))
                    .then(async res => {
                        await this.afterSubmit(res);
                    })
            }
        );
    }

    prepareModel() {
        let model = this.props.model;
        const isNewLinkedQuote = !model.id && model.mainQuote;

        if (isNewLinkedQuote && this.isRequiresSignature()) {
            model.routePoints.forEach(rp => {
                rp.id = null;
                rp.quote = null;
            });
        }

        return model;
    }

	warningAlertMainContract() {
		if (!this.isActual()) {
			return (
				<Row>
					<Col>
						{ !this.state.mainClientContractExists && !this.props.model.clientContract &&
							this.renderAlert("У заказчика отсутствует основной договор с выбранной организацией.")
						}
					</Col>
					<Col>
						{this.props.model.carrierOrganization && !this.state.mainCarrierContractExists && !this.props.model.carrierContract &&
							this.renderAlert("У перевозчика отсутствует основной договор с выбранной организацией.")
						}
					</Col>
				</Row>
			);
		}
	}

    renderAlert(message) {
        return (
            <Alert variant={"warning"}>{message}</Alert>
        );
    }

    checkDownloadDocRights() {
        switch (this.props.model.status?.value) {
            case RequestStatus.APPROVED.value:
            case RequestStatus.NOT_APPROVED.value:
            case RequestStatus.ON_LOADING.value:
            case RequestStatus.LOADED.value:
            case RequestStatus.ON_WAY.value:
            case RequestStatus.ON_WAY_TO_LOADING.value:
            case RequestStatus.ON_WAY_TO_UNLOADING.value:
            case RequestStatus.ON_UNLOADING.value:
            case RequestStatus.UNLOADED.value:
            case RequestStatus.PROBLEM.value:
                return true;
            default:
                return false;
        }
    }

    checkViewSectionRights(type) {
        const isDriver = this.props.user.isDriver;
        const isCarrier = this.props.user.isCarrier;
        switch (type) {
            case Attachment.DOC_TYPE_CLIENT:
                return !isDriver && !isCarrier;
            case Attachment.DOC_TYPE_CARRIER:
                return !isDriver;
            case Attachment.DOC_TYPE_PROCURATION:
                return true;
            case Attachment.DOC_TYPE_CLIENT_BILL:
                return !isDriver;
            case Attachment.DOC_TYPE_CARRIER_BILL:
                return !isDriver;
            case Attachment.DOC_TYPE_CARRIER_ACT:
                return !isDriver;
            default:
                return true;
        }
    }

    showDocs() {
        return this.getQuoteId() && this.checkDownloadDocRights();
    }

	async openTaskForm() {
		if (this.props.model.department) {
			this.setState({isLoadingTaskData: true});
			const data = {
				department: this.props.model.department,
				reporters: await userService.readByDepartment(this.props.model?.department.value),
				quoteNumber: this.props.model?.id + ";" + this.props.model?.number,
				assignees: this.props.model.logisticDepManager,
				commercialDepManager: this.props.model.commercialDepManager,
				client: this.props.model.contractor,
				carrier: this.props.model.carrier
			};
			this.props.history.push({ pathname: "/task-tracker/task", state: { data: data } });
			this.setState({isLoadingTaskData: false});
		}
	}

    renderTaskButton() {
        return !securityService.hasSingleRole(this.props.user, APPROVAL) && !securityService.hasSingleRole(this.props.user, LOGISTIC) && !this.props.user.isClient &&
            <Button className="mr-4" variant="outline-primary" size="sm" onClick={this.openTaskForm}
                    disabled={this.state.isLoadingTaskData}>
                {this.state.isLoadingTaskData && (<span className="spinner-border spinner-border-sm mr-1"></span>)}
                <i className="fas fa-plus mr-1"></i>
                <span>Создать задачу</span>
            </Button>
    }

    renderAddLinkedQuoteButton() {
        const userForwarder = securityService.getUser().forwarder;
        const quoteForwarder = this.props.model.forwarder;
        const forwarderIsDifferent = userForwarder?.id !== quoteForwarder?.id;
        const isFree = RequestStatus.FREE.value === this.props.model?.status?.value;
        const canRender = securityService.anyRoles(this.props.user, ADMIN, LOGISTIC, HEAD_OF_DEPARTMENT) && forwarderIsDifferent && isFree;
        return canRender &&
            <Button className="mr-4" variant="outline-primary" size="sm"
                    href={"/quotes/quote?id=" + this.props.model.id + "&mode=creation&baseOn=true"}
                    target="_blank"
            >
                <i className="fas fa-plus mr-1"></i>
                <span>Создать заявку на основании</span>
            </Button>
    }

    createLinkedQuote() {
        const crmUser = securityService.getUser();
        const model = this.props.model;
        const carrierOrganization = this.props.model.carrierOrganization;

        this.onChange("forwarder", crmUser.forwarder);
        this.onChange("mainQuote", model);
        this.onChange("status", RequestStatus.ON_SIGNING);
        this.onChange("contractorPrice", model.carrierPrice);
        this.onChange("carrierPrice", null);
        this.onChange("id", null);
        this.onChange("organization", null);
        this.onChange("department", null);
        this.onChange("clientQuoteNumber", model.id);

		this.onChange("commercialDepManager", null);
		this.onChange("logisticDepManager", null);
		this.onChange("attachments", null);
        this.onChange("signingStatus", Option.of(Enums.SIGNING_STATUS.NOT_SIGNED));

        if (carrierOrganization && carrierOrganization.id) {
            organizationContractorHistoryService.getLastOrganizationsContractor(carrierOrganization.id)
                .then(contractor => {
                    this.onChange("carrierOrganization", null);
                    this.onChange("contractor", contractor)
                });
        }
    }

	renderSaveCancelButtons() {
		const canSave = this.checkCanSave();

		return <div className="text-center" style={{ whiteSpace: "nowrap" }}>
				{canSave && this.getQuoteId() && this.renderTaskButton()}
				{this.renderAddLinkedQuoteButton()}
				{canSave && this.renderSaveButton()}
				&nbsp;
				{canSave && this.renderCancelButton("Выйти")}
			</div>
	}

    isSameQuoteUserForwarder() {
        const userForwardId = securityService.getUser().forwarder?.id;
        const quoteForwarderId = this.props.model?.forwarder?.id;
        return userForwardId === quoteForwarderId
    }

    checkCanSave() {
        const quoteForwarder = this.props.model.forwarder;
        const crmUser = securityService.getUser();
        const userForwarder = crmUser.forwarder;

        const isCarrier = securityService.isCarrier(crmUser);
        const isClient = securityService.isClient(crmUser);
        const forwarderIsDifferent = quoteForwarder && quoteForwarder.id !== userForwarder?.id;

        return !isClient && (!forwarderIsDifferent || isCarrier);
    }

    async openModalForSendEmail(contractorType) {
        if (this.props.model.clientContract && this.state.isPassportDataExists && await this.verificationBeforeGenerationClientDocs()) {
            this.setState({
                contractorType: contractorType,
                contractor: this.props.model[contractorType],
                showModalForSendMail: true
            });
        }
    }

    renderModalForSendMail() {
        const organizationId = this.state.contractorType === EmailForm.CARRIER
            ? this.props.model.carrierOrganization?.id
            : this.props.model.organization?.id;

        return <Modal show={this.state.showModalForSendMail}
                      onHide={() => this.setState({showModalForSendMail: false})}
                      backdrop="static"
                      keyboard={false}>
            <EmailForm contractor={this.state.contractor}
                       contractorType={this.state.contractorType}
                       quoteId={this.getQuoteId()}
                       organizationId={organizationId}>
            </EmailForm>
        </Modal>
    }

    loadLastDateTimeSendingEmail(contractorType) {
        const name = contractorType === EmailForm.CARRIER
            ? EmailForm.SENT_TIME_TO_CARRIER
            : EmailForm.SENT_TIME_TO_CONTRACTOR;

        emailService.getSentMessageTime(this.getQuoteId(), contractorType)
            .then(response => {
                if (response) this.setState({[name]: " Письмо отправлено " + response.replace("T", " в ")});
            });
    }

	renderAgreementDocsAndSaveCancel() {
		return (
				<Row>
					{this.renderModalForSendMail()}
					<Col>
						{this.showDocs() && <div className="text-left">
							{this.checkViewSectionRights(Attachment.DOC_TYPE_CLIENT) &&
								<Dropdown className="d-inline">
									<Dropdown.Toggle size="sm" variant="success" id="dropdown-basic">
										Заявка (З)
									</Dropdown.Toggle>
									<Dropdown.Menu>
										<Dropdown.Item onClick={() => this.openModalForSendEmail(EmailForm.CONTRACTOR)}
													   disabled={this.state.vehiclesIsNotApproved}>E-mail</Dropdown.Item>
										<Dropdown.Divider />
										<Dropdown.Item onClick={this.generateClientAgreement.bind(this, PDF)}
													   disabled={this.state.vehiclesIsNotApproved}>Заявка (З).pdf</Dropdown.Item>
										<Dropdown.Divider />
										<Dropdown.Item onClick={this.generateClientAgreement.bind(this, XLSX)}
													   disabled={this.state.vehiclesIsNotApproved}>Заявка (З).xlsx</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
							}
							&nbsp;
							{
								<Dropdown className="d-inline">
									<Dropdown.Toggle size="sm" variant="success" id="dropdown-basic">
										Документы (З)
									</Dropdown.Toggle>
									<Dropdown.Menu>
										{this.checkViewSectionRights(Attachment.DOC_TYPE_CLIENT_BILL) &&
											<React.Fragment>
												<Dropdown.Item onClick={this.generateClientBill.bind(this, PDF)}
															   disabled={this.state.vehiclesIsNotApproved}>Счёт заказчика.pdf</Dropdown.Item>
												<Dropdown.Divider/>
												<Dropdown.Item onClick={this.generateClientBill.bind(this, XLSX)}
															   disabled={this.state.vehiclesIsNotApproved}>Счёт заказчика.xlsx</Dropdown.Item>
											</React.Fragment>
										}
									</Dropdown.Menu>
								</Dropdown>
							}
						</div>}
						<span>{this.state[EmailForm.SENT_TIME_TO_CONTRACTOR]}</span>
					</Col>
					<Col>
						{this.renderSaveCancelButtons()}
					</Col>
					{!this.props.user.isClient && <Col>
							{this.showDocs() && <div className="text-right">
								<span>{this.state[EmailForm.SENT_TIME_TO_CARRIER]} </span>
								{this.checkViewSectionRights(Attachment.DOC_TYPE_CARRIER) &&
									<Dropdown className="d-inline">
										<Dropdown.Toggle size="sm" variant="success" id="dropdown-basic">
											Заявка (П)
										</Dropdown.Toggle>
										<Dropdown.Menu>
											<Dropdown.Item onClick={() => {this.openModalForSendEmail(EmailForm.CARRIER)}}
														   disabled={this.state.vehiclesIsNotApproved}>E-mail</Dropdown.Item>
											<Dropdown.Divider />
											<Dropdown.Item onClick={this.generateCarrierAgreement.bind(this, PDF)}
														   disabled={this.state.vehiclesIsNotApproved}>Заявка (П).pdf</Dropdown.Item>
											<Dropdown.Divider />
											<Dropdown.Item onClick={this.generateCarrierAgreement.bind(this, DOCX)}
														   disabled={this.state.vehiclesIsNotApproved}>Заявка (П).docx</Dropdown.Item>
											<Dropdown.Divider />
											<Dropdown.Item onClick={this.generateCarrierAgreement.bind(this, XLSX)}
														   disabled={this.state.vehiclesIsNotApproved}>Заявка (П).xlsx</Dropdown.Item>
										</Dropdown.Menu>
									</Dropdown>}
								&nbsp;
								{
									<Dropdown className="d-inline">
										<Dropdown.Toggle size="sm" variant="success" id="dropdown-basic">
											Документы (П)
										</Dropdown.Toggle>
										<Dropdown.Menu className="dropdown-menu-show">
											{this.checkViewSectionRights(Attachment.DOC_TYPE_PROCURATION) &&
												<React.Fragment>
													<Dropdown.Item onClick={this.generateProcuration.bind(this, PDF)}
																   disabled={this.state.vehiclesIsNotApproved}>Доверенность на водителя.pdf</Dropdown.Item>
													<Dropdown.Divider/>
													<Dropdown.Item onClick={this.generateProcuration.bind(this, EFES)}
																   disabled={this.state.vehiclesIsNotApproved}>Доверенность на водителя (ЭФЕС).pdf</Dropdown.Item>
													<Dropdown.Divider />
													<Dropdown.Item onClick={this.generateProcuration.bind(this, EMPTYFOOTER)}
																   disabled={this.state.vehiclesIsNotApproved}>Доверенность на водителя без факсимиле (ЭФЕС).pdf</Dropdown.Item>
													<Dropdown.Divider />
													<Dropdown.Item onClick={this.generateProcuration.bind(this, DOCX)}
																   disabled={this.state.vehiclesIsNotApproved}>Доверенность на водителя.docx</Dropdown.Item>
													<Dropdown.Divider/>
													<Dropdown.Item onClick={this.generateProcuration.bind(this, XLSX)}
																   disabled={this.state.vehiclesIsNotApproved}>Доверенность на водителя.xlsx</Dropdown.Item>
													<Dropdown.Divider/>
												</React.Fragment>
											}
											{this.checkViewSectionRights(Attachment.DOC_TYPE_CARRIER_BILL) &&
												<React.Fragment>
													<Dropdown.Item onClick={this.generateCarrierBill.bind(this)}
																   disabled={this.state.vehiclesIsNotApproved}>Счет перевозчика.xlsx</Dropdown.Item>
													<Dropdown.Divider/>
												</React.Fragment>
											}
											{this.checkViewSectionRights(Attachment.DOC_TYPE_CARRIER_ACT) &&
												<React.Fragment>
													<Dropdown.Item onClick={this.generateCarrierAct.bind(this)}
																   disabled={this.state.vehiclesIsNotApproved}>Акт перевозчика.xlsx</Dropdown.Item>
													<Dropdown.Divider/>
												</React.Fragment>
											}
										</Dropdown.Menu>
									</Dropdown>
								}
							</div>}
						</Col>
					}
				</Row>
		);
	}

    async generateCarrierAgreement(type) {
        await this.isNotApprovedVehicles(this.props.model?.truck, this.props.model?.trailer);
        if (this.state.vehiclesIsNotApproved) {
            alert("Транспортное средство не согласованно.");
        } else {
            docService.getQuoteCarrierAgreement(this.getQuoteId(), type);
        }
    }

    async generateClientAgreement(type) {
        if (await this.verificationBeforeGenerationClientDocs()) {
            docService.getQuoteClientAgreement(this.getQuoteId(), type);
        }
    }

    async verificationBeforeGenerationClientDocs() {
        let result = true;
        await this.isNotApprovedVehicles(this.props.model?.truck, this.props.model?.trailer);
        if (this.state.vehiclesIsNotApproved) {
            result = false;
            alert("Транспортное средство не согласованно.");
        }
        if (!this.props.model.clientContract) {
            result = false;
            alert("У заказчика отсутствует договор.");
        }
        if (!this.state.isPassportDataExists) {
            result = false;
            alert("Отсутствуют паспортные данные водителя.");
        }
        return result;
    }

	async generateProcuration(type) {
		await this.isNotApprovedVehicles(this.props.model?.truck, this.props.model?.trailer)
		if (this.state.vehiclesIsNotApproved) {
			alert("Транспортное средство не согласованно.");
		} else {
            switch (type) {
                case EFES:
                    docService.getEfesProcuration(this.getQuoteId())
                    break
                case EMPTYFOOTER:
                    docService.getEmptyFooterProcuration(this.getQuoteId())
                    break
                default:
                    docService.getProcuration(this.getQuoteId(), type);
                    break
            }
		}
	}

    async generateClientBill(type) {
        await this.isNotApprovedVehicles(this.props.model?.truck, this.props.model?.trailer)
        if (this.state.vehiclesIsNotApproved) {
            alert("Транспортное средство не согласованно.");
        } else {
            quoteService.verificationBeforePrintClientBill(this.getQuoteId())
                .then(() => docService.getClientBill(this.getQuoteId(), type));
        }
    }

    async generateCarrierBill() {
        await this.isNotApprovedVehicles(this.props.model?.truck, this.props.model?.trailer)
        if (this.state.vehiclesIsNotApproved) {
            alert("Транспортное средство не согласованно.");
        } else {
            quoteService.checkCarrierContractExistence(this.getQuoteId())
                .then(() => docService.getCarrierBill(this.getQuoteId()));
        }
    }

    async generateCarrierAct() {
        await this.isNotApprovedVehicles(this.props.model?.truck, this.props.model?.trailer)
        if (this.state.vehiclesIsNotApproved) {
            alert("Транспортное средство не согласованно.");
        } else {
            docService.getCarrierAct(this.getQuoteId());
        }
    }

    getWasActual(prevProps) {
        return prevProps.model.status !== this.props.model.status && prevProps?.model?.status?.id === RequestStatus.ACTUAL.id
    }

    componentDidUpdate(prevProps) {
        super.componentDidUpdate(prevProps);

        this.getWasActual(prevProps) && this.setState({wasActual: true})

        if (!this.isActual()
            && (this.props.model.contractor !== prevProps.model.contractor || this.props.model.carrier !== prevProps.model.carrier
                || this.props.model.status !== prevProps.model.status)) {
            this.checkExistingData();
        }

        if (this.isAtWork() && !this.props.model.logisticDepManager) {
            this.initDefaultLogisticDepManager();
        }
    }

    checkExistingData() {
        const quoteId = this.getQuoteId();
        const contractorId = this.props.model.contractor?.id;
        const carrierId = this.props.model.carrier?.id || 0;

        if (quoteId && contractorId) {
            contractorService.mainContractExists(quoteId, contractorId, carrierId).then(data =>
                this.setState({
                    mainClientContractExists: data.clientContractExists,
                    mainCarrierContractExists: data.carrierContractExists
                }));
        }

		if (this.props.model.driver?.id) {
			personService.getPersonByDriverId(this.props.model.driver.id)
				.then(response =>
					this.setState({isPassportDataExists: response.passportNumber !== null && response.passportIssuedBy !== null && response.passportIssuedDate})
				);
		}
	}

    changeDepartmentByManager(id) {
        if (!!this.props.model.logisticDepManager
            && this.isActual()) {
            userService.read(id).then(user => this.onChange("department", user.departments[0]));
        }
    }

	getTabs() {
		const tabs = [];
		if (this.getQuoteId() && !this.props.copy) {
			const isDriver = this.props.user.isDriver;
			const isCarrier = this.props.user.isCarrier;
			const isClient = this.props.user.isClient;
			if (!this.isActual() && this.isSameQuoteUserForwarder()) {
				tabs.push(this.getAttachmentsTab());
                tabs.push(this.getAgreementTab());
			}
			if (!isDriver && !isCarrier && !isClient && this.isSameQuoteUserForwarder()) {
				tabs.push(this.getAuditTab(this.getQuoteId(), QUOTE, "История изменений", "log"));
			}

            tabs.push(this.getMonitoringTab());
            tabs.push(this.getTaskTabTitle());

            // Tab with auction settings for admin only
            if (this.props.user.roles.includes("ADMIN")) {
                tabs.push(this.getAuctionSettingsTab())
            }

            // Tab with auction for all users
            tabs.push(this.getAuctionTab())
        }
        return tabs;
    }

    updateState = async () => {
        const id = this.getQuoteId();

        if (id) {
            const quote = await quoteService.byId(id);

            if (quote) {
                this.props.store.dispatch(setData(quote));
            }
        }
    }

    getAuctionSettingsTab() {
        const quote = this.prepareModel()
        const quoteId = quote?.id;
        const auctionId = quote?.auctionId;

        // Here you can control access to the auction settings tab
        if (!this.props.user.roles.includes("ADMIN")) return;

        return this.props.model.routePoints && (
            <Tab
                key="auction-settings"
                eventKey="auction-settings"
                title="Настройки аукциона"
                mountOnEnter
                unmountOnExit
            >
                <AuctionSettings
                    quoteId={quoteId}
                    auctionId={auctionId}
                    onSuccess={this.updateState}
                />
            </Tab>
        );
    }

    getAuctionTab() {
        const quote = this.prepareModel()
        const auctionId = quote?.auctionId;

        if (!auctionId) return;

        const requestData = {
            customerOrganization: quote?.contractor?.value,
            transporterOrganization: quote?.forwarder?.value,
            sender: {
                name: "-",
                address: quote?.routePoints?.[0]?.address?.value,
                city: quote?.routePoints?.[0]?.city?.value,
            },
            receiver: {
                name: "-",
                address: quote?.routePoints?.[1]?.address?.value,
                city: quote?.routePoints?.[1]?.city?.value,
            },
            cargo: {
                weight: quote?.routePoints?.[0]?.weight,
                type: quote?.routePoints?.[0]?.cargoType?.value,
                distance: quote?.kilometre ?? "-",
            },
            transport: {
                type: quote?.routePoints?.[0]?.carcass?.value,
            }
        }

        return this.props.model.routePoints && (
            <Tab
                key="auction"
                eventKey="auction"
                title="Аукцион"
                mountOnEnter
                unmountOnExit
            >
                <Auction auctionId={auctionId} requestData={requestData}/>
            </Tab>
        );
    }

    getAuditTab(entityId, source, title, key) {
        return (
            <Tab key={key} eventKey={key} title={title}>
                {this.state.activeTab === key && <AuditTable sourceId={entityId} source={source}/>}
            </Tab>
        );
    }

    getAttachmentsTab() {
        return (
            <Tab key="attachments" eventKey="attachments" title="Сканкопии документов">
                {this.state.activeTab === "attachments" &&
                    <UploadForm {...this.props}
                                store={this.props.store}
                                hideRemove={this.props.user.isCarrier}
                                setToastObjAC={this.props.setToastObjAC}
                                readOnly={this.props.user.isClient}/>}
            </Tab>
        );
    }

    getAgreementTab() {
        const agreementDiv = document.querySelector(".agreementMessage")
        agreementDiv && (agreementDiv.innerHTML = this.props.model.agreement)
        return  (
            <Tab key="agreement" eventKey="agreement" title="Условия заказчика">
                <Form.Group>
                    {
                        this.props.model.agreement ?
                            <div
                                className="agreementMessage"
                                style={{
                                    padding: "10px",
                                    backgroundColor: "white",
                                    border: "1px solid rgba(0,0,0,.125)",
                                    borderRadius: "5px"
                                }}>
                            </div>
                            : <div></div>
                    }
                </Form.Group>
            </Tab>
        );
    }

	getMonitoringTab() {
		return this.props.model.routePoints && (
			<Tab key={"map"} eventKey={"map"} title={"Мониторинг"} mountOnEnter unmountOnExit>
				<MonitoringMapQuote quoteId={this.getQuoteId()}></MonitoringMapQuote>
			</Tab>
		);
	}

    getFormTabTitle() {
        const quoteNumber = this.props.model.number || (this.props.model.id > 0 ? this.props.model.id : "");
        return (this.props.model.finalDt ? "Заявка " : "Предварительная заявка ")
            + quoteNumber
            + (this.props.copy ? " (копия)" : "");
    }

    getTaskTabTitle() {
        const quoteNumber = this.props.model?.id + ";" + this.props.model?.number;
        const index = quoteNumber.indexOf(';');
        const id = quoteNumber.substring(0, index);
        const applyRoleRestriction = !securityService.isCarrier();

        return applyRoleRestriction &&
            <Tab key={"tasks"}
                 eventKey={"tasks"}
                 title={` Задачи (${this.props.model.taskCount ? this.props.model.taskCount : 0}) `}
                 mountOnEnter unmountOnExit
            >
                <TaskTable {...this.props} type={TaskType.TYPE_TASKS()} quoteNumber={id}/>
            </Tab>
    }

	renderForm() {
		const {model} = this.props;
		const isReady = (!this.getQuoteId() || model.id || model.mainQuote) && model !== {};
		if (!isReady) {
			return (<Loading/>);
		}
		return this.renderInnerForm();
	}

    renderInnerForm() {
        const showFinal = !this.isActual();
        const isCarrier = securityService.isCarrier();

        const quoteForwarder = this.props.model.forwarder;
        const userForwarder = securityService.getUser().forwarder;
        const forwarderIsDifferent = quoteForwarder && quoteForwarder.id !== userForwarder?.id;

        let quoteInnerForm = <div>
			{this.warningAlertMainContract()}
			<Form>
				{showFinal
					? <FinalQuoteForm store={this.props.store} user={this.props.user}
									  preStatus={this.state.preStatus}
                                      wasActual={this.state.wasActual}
									  addLoading={this.addLoading}
									  addUnloading={this.addUnloading}
									  addCustoms={this.addCustoms}
									  vehiclesIsNotApproved={this.state.vehiclesIsNotApproved}/>
					: <QuoteForm {...this.props} store={this.props.store}
								 addLoading={this.addLoading}
								 addUnloading={this.addUnloading}
								 addCustoms={this.addCustoms} />}
				<br />
				{this.renderAgreementDocsAndSaveCancel()}
			</Form>
		</div>

        if (isCarrier || forwarderIsDifferent) {
            quoteInnerForm = <CarrierQuoteForm {...this.props} setToast={this.props.setToastObjAC}/>;
        }

        return quoteInnerForm;
	}

    addLoading() {
        this.onChange("routePoints", [...RoutePoint.addLoading(this.props.model.routePoints)]);
    }

    addUnloading() {
        this.onChange("routePoints", [...RoutePoint.addUnloading(this.props.model.routePoints)]);
    }

    addCustoms() {
        this.onChange("routePoints", [...RoutePoint.addCustoms(this.props.model.routePoints)]);
    }

}

const QuoteInnerConnected = connect(mapStateToProps)(QuoteInner);

export default connect(mapGlobalStateToProps, {setToastObjAC})(Quote);