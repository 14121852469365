import React, {PureComponent} from "react";
import "./OperationalReport.css";
import operationalReportService from "../../services/OperationalReportService";
import {Card, Collapse, Table} from "react-bootstrap";
import OperationalReport from "./OperationalReport";
import {securityService} from "../../services/SecurityService";

class DocumentStatus extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            sending: false,
            data: []
        }
    }

    componentDidMount() {
        operationalReportService.getDocumentStatusReport("document-status", securityService.getUser().id)
            .then(response => this.setState({data: response}));
    }

    USER_LAST_NAME = { id: "userLastName", name: "Фамилия пользователя" }
    DEPARTMENT_VALUE = { id: "departmentValue", name: "Подразделение" }
    CREATED_QUOTE_DATE = { id: "createdQuoteDate", name: "Время создания заявки" }
    QUOTE_ID = { id: "quoteId", name: "ID заявки" }
    CLIENT_QUOTE = { id: "clientQuote", name: "Заявка заказчика" }
    CARRIER_QUOTE = { id: "carrierQuote", name: "Заявка перевозчика" }

    TABLE_FIELDS = [
        this.CREATED_QUOTE_DATE,
        this.QUOTE_ID,
        this.CLIENT_QUOTE,
        this.CARRIER_QUOTE
    ]

    openQuoteForm(quoteId) {
        this.props.history.push({ pathname: "/quotes/quote", state: { id: quoteId } });
    }

    groupByField(array, field) {
        return array.reduce((acc, obj) => {
            const key = obj[field];
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(obj);
            return acc;
        }, {});
    }

    renderTableData(quote, field) {
        if (field.id === this.CREATED_QUOTE_DATE.id) {
            return quote[field.id].substring(0, 19).replace("T", " в ");
        }

        if (field.id === this.CARRIER_QUOTE.id || field.id === this.CLIENT_QUOTE.id) {
            if (quote[field.id]) {
                return <font color="darkseagreen"><b>✓</b></font>;
            }

            return <font color="palevioletred">✘</font>;
        }

        return quote[field.id];
    }

    renderCardWithTableForUser(department, logisticName, logisticQuotes) {
        return <Card style={{paddingLeft: "20px"}}>

            <Card.Header style={{cursor: "pointer"}}
                         onClick={() => this.setState({[logisticName + department]: !this.state[logisticName + department]})}>
                <div className="logistic-panel">
                    <span>{logisticName}</span>
                    <span>{logisticQuotes.length + OperationalReport.getQuotesName(logisticQuotes.length) +
                        " (" + logisticQuotes.filter(q => !q.clientQuote).length + " заказчика и "
                             + logisticQuotes.filter(q => !q.carrierQuote).length + " перевозчика)"}
                    </span>
                </div>
            </Card.Header>

            <Collapse in={this.state[logisticName + department]}>
                <Card.Body style={{marginLeft: "20px", padding: 0}}>

                    <Table striped bordered hover size="sm">
                        <thead><tr>{this.TABLE_FIELDS.map(field => <td><b>{field.name}</b></td>)}</tr></thead>
                        <tbody>
                        {logisticQuotes.map(quote =>
                            <tr onDoubleClick={() => this.openQuoteForm(quote.quoteId)}>
                                {this.TABLE_FIELDS.map(field =>
                                    <td>{this.renderTableData(quote, field)}</td>)}
                            </tr>
                        )}
                        </tbody>
                    </Table>

                </Card.Body>
            </Collapse>
        </Card>
    }

    render() {
        return OperationalReport.mapAndFilterField(this.state.data, this.DEPARTMENT_VALUE.id).map(department =>
                <Card>
                    <Card.Header style={{cursor: "pointer", backgroundColor: "#ebebeb"}}
                             onClick={() => this.setState({[department]: !this.state[department]})}>
                        <div><span><b>{department}</b></span></div>
                    </Card.Header>

                    <Collapse in={this.state[department]}>
                        <Card.Body style={{padding: 0}}>

                            <Table striped bordered hover size="sm">
                                <tbody>
                                    {Object.entries(this.groupByField(this.state.data?.filter(o => o.departmentValue === department), this.USER_LAST_NAME.id))
                                        .map(logistic => this.renderCardWithTableForUser(department, logistic[0], logistic[1]))}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Collapse>

            </Card>)

    }
}

export default DocumentStatus;