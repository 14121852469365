import React from "react";
import DataTable from "../table/DataTable";
import quoteStatusHistoryService from "../../services/QuoteStatusHistoryService";
import TableCell from "../table/TableCell";
import BaseForm from "../form/BaseForm";
import Report from "./Report";
import {RequestStatus} from "../../Const";

class QuoteStatusHistoryReport extends BaseForm {

    fetch(params) {
        return  quoteStatusHistoryService.getReport(params);
    }

    columns = [
        { title: "Номер заявки", name: "quoteId", className: "id", hideFilter: true },
        { title: "Дата создания заявки", name: "createdAt", formatter: TableCell.dtFormatter, hideFilter: true },
        { title: "Подразделение", name: "department", type: "dictionary", optionsType: "DEPARTMENT", activeOnly: false, multiSelect: true },
        { title: "Менеджер ОЛ", name: "logisticDepManager", type: "dictionary", optionsType: "CRM_USER", role: "LOGISTIC", activeOnly: false },
        { title: "Адрес загрузки", name: "loadingPointAddress", className: "overflow", hideFilter: true },
        { title: "Адрес разгрузки", name: "unloadingPointAddress", className: "overflow", hideFilter: true },
        { title: "Водитель", name: "driver", filter: DataTable.STRING_FILTER },
        { title: "Изменение статусов водителем", name: "isDriverChangeStatus", type: "yes-no", formatter: TableCell.booleanFormatter },
        { title: "Текущий статус заявки", name: "quoteStatus", type: "dictionary", optionsType: "REQUEST_STATUS",
            filteredOptions: RequestStatus.getLogisticDepManagerQuoteStatuses(), activeOnly: false, multiSelect: true },
        { title: "Кто сменил последний статус", name: "whoChangeLastStatus", hideFilter: true }
    ]

    render() {
        return <Report {...this.props}
                       title = {"Отчёт по изменению статусов заявок"}
                       fetch = {this.fetch.bind(this)}
                       columns = {this.columns}
                       from = {{ title: "С", name: "from", type: "datetime" }}
                       to = {{ title: "По", name: "to", type: "datetime" }}
                       requiredFields={["from", "to"]}
                       disabledFetchXLS={true}
                       doubleClickOnTableRow={(event, row) => { window.open( `/quotes/quote?id=${row.quoteId}`, "_blank")} }
        />
    }
}

export default QuoteStatusHistoryReport;