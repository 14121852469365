import BaseForm from "../../form/BaseForm";
import {Accordion, Badge, Card, Col, ListGroup, Row} from "react-bootstrap";
import React from "react";
import CarrierQuoteForm from "./CarrierQuoteForm";

class QuoteRoute extends BaseForm {
    constructor(props) {
        super(props);
    }

    render() {
        const {quote} = this.props;
        return (
            <Card>
                {this.renderRouteHeader()}
                <Card.Body className={"p-0"}>
                    {quote.routePoints.map(rp => this.renderRoutePointRow(rp))}
                </Card.Body>
            </Card>
        );
    }

    renderRouteHeader() {
        if (this.props.renderHeader) {
            return this.props.renderHeader();
        }
        return (
            <Card.Header>
                <Card.Title>
                    <Row>
                        <Col>
                            Маршрут
                        </Col>
                    </Row>
                </Card.Title>
            </Card.Header>
        );
    }

    renderRoutePointRow(rp) {
        const routPointStyle = {
            backgroundColor: rp.departureDt && "#c3e6cb",
            border: rp.arrivalDt && !rp.departureDt && "2px solid #c3e6cb",
            cursor: "pointer"
        }

        return (
            <Accordion>
                <Card>
                    <Card.Header style={routPointStyle}>
                        <div className={"d-flex flex-space-between"}>
                            <div className={"mr-5"}>
                                <div>
                                    <Badge variant={this.isLoadingPoint(rp) ? "info" : "primary"}>
                                        {rp.pointType.value}
                                    </Badge>
                                    &nbsp;
                                    <font color={this.isLoadingPoint(rp) ? "#17a2b8" : "#007bff"}>
                                        {this.normalizeDateForRoute(rp.dt)}
                                        {rp.timeFrom || rp.timeTo ? "," : ""}
                                        {rp.timeFrom ? " c " + rp.timeFrom : ""}
                                        {rp.timeTo ? " до " + rp.timeTo: ""}
                                    </font>
                                </div>
                                <b>{rp.city?.value}</b>
                                <div>{rp.address?.value}</div>
                            </div>
                            <ListGroup horizontal>
                                {this.renderAlert(rp.carcass?.value, "carcass")}
                                {rp.temperature && this.renderAlert(rp.temperature, "temperature")}
                                {this.renderAlert(rp.cargoType?.value, "cargoType")}
                                {this.renderAlert(rp.weight, "weight")}
                                {this.renderAlert(rp.volume, "volume")}
                            </ListGroup>
                        </div>
                    </Card.Header >
                    <Accordion.Collapse eventKey="1">
                        <Card.Body>
                            {(rp.height || rp.length || rp.width) && <h6><Badge>Размеры груза</Badge></h6>}
                            <ListGroup horizontal>
                                {this.renderAlert(rp.height, 'height')}
                                {this.renderAlert(rp.length, 'length')}
                                {this.renderAlert(rp.width, 'width')}
                            </ListGroup>
                            {(rp.arrivalDt || rp.departureDt) && <h6><Badge>Фактическая дата прибытия / отъезда</Badge></h6>}
                            <ListGroup horizontal>
                                {this.renderAlert(rp.arrivalDt && this.normalizeDateForFreeTo(rp.arrivalDt), 'arrivalDt')}
                                {this.renderAlert(rp.departureDt && this.normalizeDateForFreeTo(rp.departureDt), 'departureDt')}
                            </ListGroup>
                            {(rp.contact || rp.description) && <h6><Badge>Подробности</Badge></h6>}
                            <ListGroup>
                                {this.renderAlert(rp.contact, 'contact')}
                                {this.renderAlert(rp.description, 'description')}
                            </ListGroup>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        );
    }

    isLoadingPoint(routePoint) {
        return routePoint.pointType.id === 1;
    }

    normalizeDateForRoute(date) {
        return new Date(date).toLocaleString("ru-RU",{ day: "numeric", month: "long" });
    }

    normalizeDateForFreeTo(date) {
        return new Date(date).toLocaleString("ru-RU", { day: "numeric", month: "long", hour: "numeric", minute: "numeric" });
    }

    renderAlert(value, key, postfix) {
        if (value) {
            const alertStyle = {
                flex: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                color: "black"
            }

            return <ListGroup.Item
                variant="light"
                style={alertStyle}
            >
                <Badge style={{padding: "0 0 2px 0"}}>{CarrierQuoteForm.FIELD_LABELS[key] || key}</Badge>
                {value}{postfix ? postfix : <></>}
            </ListGroup.Item>
        }
    }
}

export default QuoteRoute;
