import { SHOW_WELCOME_MODAL, HIDE_WELCOME_MODAL } from "../actions/types";

const initialState = {};

export default function(state = initialState, action) {
    const { type } = action;

    switch (type) {
        case SHOW_WELCOME_MODAL:
            return true;

        case HIDE_WELCOME_MODAL:
            return false;

        default:
            return state;
    }
}
