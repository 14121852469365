
import BaseSelect from "./BaseSelect";
import vehicleService from '../../services/VehicleService';

class VehicleSelect extends BaseSelect {
	constructor(props) {
		super(props);
	}

	enumToOption(vtypes) {		
		if(!Array.isArray(vtypes))
			vtypes = [vtypes];
		vtypes = vtypes.map(function(vtype) {
			if (vtype && vtype.value)
				return {value: vtype.value}			
		});	
		return vtypes || {};		
	}

	fetchOptions() {
		return vehicleService.options({contractor: this.props.contractor, vtypes: this.enumToOption(this.props.vtype)});
	}
	
	getViewLink() {
		if (["truck", "trailer"].indexOf(this.props.name) >= 0) {
			return "/control-panel/vehicles/vehicle/?id=" + this.props.value.id;
		}						
		return super.getViewLink();
	}
	
	componentDidUpdate(prevProps) {
		if (prevProps.contractor !== this.props.contractor) {
			this.loaded = false;
			this.onChange(null);
			this.setState({ options: [] });
		}
	}
}

export default VehicleSelect;