import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import quoteService from "../../../services/QuoteService";
import exportImportService from "../../../services/ExportImportService";
import Util from "../../../Util"
import {
	ADMIN,
	COMMERCIAL,
	LOGISTIC,
	HEAD_OF_DEPARTMENT,
	REGIONAL_DIRECTOR,
	securityService
} from '../../../services/SecurityService';
import DataTable from '../../table/DataTable';
import TableCell from '../../table/TableCell';
import "../css/Quotes.css"
import { ThreeDots } from "react-loader-spinner";
import {Forwarder, QuoteType, RequestStatus, PaymentType} from "../../../Const";
import {Col, Row} from "react-bootstrap";
import DropdownItem from "react-bootstrap/DropdownItem";
import moment from "moment";
import QuoteService from "../../../services/QuoteService";
import LocalStorageService from "../../../services/LocalStorageService";
import localStorageService from "../../../services/LocalStorageService";
import {guestColumns} from "./QuoteColumns";
import {ENUM_NAME, PAYMENT_TYPES, SIGNING_STATUSES} from "../../../Enums";

const DELAY_DAYS = 7;

class Quotes extends React.PureComponent {

	constructor(props) {
		super(props);
		this.columns = this.columns.bind(this);
		this.getQuoteStatuses = this.getQuoteStatuses.bind(this);
		this.getColumns = this.getColumns.bind(this);
		this.isSameExpeditor = this.isSameExpeditor.bind(this);
		this.state = {
			tableHeight: this.getTableHeight(),
			dataTableAlerts: [],
			dataTableNotifications: [],
			dataTableAlertsLoaded: false,
			columns: []
		}
	}

	checkType(type) {
		return this.props.type === type;
	}

	static mapStateToProps(state, ownProps) {
		const storedPropProvider = (prop, defaultProvider) => state[prop]
				|| localStorageService.extract(ownProps.title, prop, ownProps[prop])
				|| (defaultProvider ? defaultProvider() : null),
			hiddenColumnsProvider = () =>
				ownProps.columns?.filter(col => col.hidden).map(col => col.key || col.field);
		return {
			sort: storedPropProvider('sort'),
			search: storedPropProvider('search', () => ''),
			filter: storedPropProvider('filter'),
			page: storedPropProvider('page', () => 0),
			showFilter: !!state.filterConfig,
			hiddenColumns: storedPropProvider('hiddenColumns', hiddenColumnsProvider),
			columns: DataTable.sortColumns(ownProps.title, ownProps.columns)
		};
	}

	getQuoteStatuses() {
		const activeTab = LocalStorageService.extract(this.props.title, 'activeFilterTab') || 'ALL'
		return this.getFilterTabsByQuoteType().filterTabs.find(tab => tab.key === activeTab).value
	}
	getStatuses() {
		let user = securityService.getUser();
		switch (this.props.type) {
			case QuoteType.QUOTE:
				return this.getQuoteStatuses();
			case QuoteType.MONITORING:
				if (securityService.anyRoles(user, HEAD_OF_DEPARTMENT, REGIONAL_DIRECTOR)) {
					return RequestStatus.getMonitoringHeadOfDeptRequestStatuses();
				} else if (securityService.isLogistic(user)) {
					return RequestStatus.getMonitoringLogisticRequestStatuses();
				}
				break;
			case QuoteType.FREE:
				return [RequestStatus.FREE];
			default:
				return []; 
		}
	}
	
	columns() {
		const isGuest = securityService.isGuest();
		return isGuest ? guestColumns() : this.defaultColumns();
	};

	defaultColumns() {
		const isCarrier = securityService.isCarrier();
		const isDriver = securityService.isDriver();
		const isClient = securityService.isClient();
		const isArttekForwarder = securityService.forwarderIsArttek(Forwarder.ARTTEK());
		const isExchangeOrFree = LocalStorageService.extract(this.props.title, 'activeFilterTab') === 'EXCHANGE'
			|| this.props.type === QuoteType.FREE;
		const isDisplay = !isArttekForwarder && isExchangeOrFree;
		return [
			{ title: "#", field: "id", filter: DataTable.STRING_FILTER },
			{ title: "Номер заявки", field: "number", className: "overflow", formatter: (value, row) => value || row.id, editable: true, filter: DataTable.STRING_FILTER },
			{ title: "Статус", field: "status", filter: DataTable.DICTIONARY_FILTER, optionsType: "REQUEST_STATUS", filteredOptions: this.getStatuses(), multiSelect: true },
			{...!isCarrier && { title: "Подразделение", field: "department", filter: DataTable.DICTIONARY_FILTER, optionsType: "DEPARTMENT", activeOnly: false, multiSelect: true }},
			{ ...!isClient && !isCarrier && { title: "Источник", field: "source", filter: DataTable.DICTIONARY_FILTER, optionsType: "REQUEST_SOURCE" }},
			{ title: "Дата", field: "createdAt", formatter: TableCell.dtFormatter, filter: DataTable.DATE_RANGE_FILTER },
			{ ...!isClient && !isCarrier && { title: "Дата согласования", field: "finalDt", formatter: TableCell.dtFormatter, filter: DataTable.DATE_RANGE_FILTER }},
			{ ...!isClient && !isCarrier && { title: "Действует до", field: "expireDt", formatter: TableCell.dtFormatter, filter: DataTable.DATE_RANGE_FILTER }},
			{ ...!isCarrier && { title: "Срок актуальности", field: "remaining", key: "remaining", sortable: false, formatter: (value, row) => this.calculateRemainingTime(row), className: this.getDeadlineClassName }},
			{ ...!isDisplay && {title: "Номер заявки заказчика", field: "clientQuoteNumber", filter: DataTable.STRING_FILTER }},
			{ ...!isDisplay && { title: "Дата заявки заказчика", field: "clientQuoteDt", formatter: TableCell.dtFormatter, filter: DataTable.DATE_RANGE_FILTER }},
			{ ...!isClient && { title: "Номер заявки перевозчика", field: "carrierQuoteNumber", filter: DataTable.STRING_FILTER }},
			{ ...!isClient && { title: "Дата заявки перевозчика", field: "carrierQuoteDt", formatter: TableCell.dtFormatter, filter: DataTable.DATE_RANGE_FILTER }},
			{ ...!isCarrier && { title: "Менеджер КО", field: "commercialDepManager", filter: DataTable.USER_FILTER, role: "COMMERCIAL", activeOnly: false }},
			{ title: "Менеджер ОЛ", field: "logisticDepManager", filter: DataTable.USER_FILTER, role: "LOGISTIC", activeOnly: false },
			{ ...isArttekForwarder && !isDriver && !isCarrier && !isDisplay && { title: "Организация заказчика", field: "organization", filter: DataTable.DICTIONARY_FILTER, optionsType: isExchangeOrFree ? "ORGANIZATION" : "FORWARDER_ORGANIZATION", activeOnly: false }},
			{ ...!isClient && { title: "Организация перевозчика", field: "carrierOrganization", hidden: true, filter: DataTable.DICTIONARY_FILTER, optionsType: isExchangeOrFree ? "ORGANIZATION" : "FORWARDER_ORGANIZATION", activeOnly: false  }},
			{  ...!isDriver && !isCarrier && { title: "Заказчик", field: "contractor", filter: DataTable.DICTIONARY_FILTER, optionsType: "CONTRACTOR_CLIENT",  activeOnly: false }},
			{ ...!isClient && { title: "Перевозчик", field: "carrier", hidden: true, filter: DataTable.DICTIONARY_FILTER, optionsType: "CONTRACTOR_CARRIER",  activeOnly: false  }},
			{ title: "Водитель", field: "driver", hidden: true, filter: DataTable.STRING_FILTER  },
			{ title: "Транспорт", field: "transport", sortable: false, hidden: true, filter: DataTable.STRING_FILTER  },
			{ ...!isDriver && !isCarrier && !isClient && !isDisplay && { title: "Ставка стартовая", field: "priceInitial", filter: DataTable.NUMERIC_RANGE_FILTER }},
			{ ...!isDriver && !isCarrier && !isClient && !isDisplay && { title: "Ставка заказчика", field: "contractorPrice", filter: DataTable.NUMERIC_RANGE_FILTER }},
			{ ...!isDriver && !isCarrier && !isClient && { title: "Ставка перевозчику", field: "carrierPrice", hidden: true, filter: DataTable.NUMERIC_RANGE_FILTER  }},
			{ ...!isDriver && !isCarrier && !isDisplay && { title: "Дата оплаты заказчику", field: "contractorPaymentDt", formatter: TableCell.dayFormatter, sortable: true, hidden: true, filter: DataTable.DATE_RANGE_FILTER  }},
			{ ...!isClient && { title: "Дата оплаты перевозчику", field: "carrierPaymentDt", formatter: TableCell.dayFormatter, sortable: true, hidden: true, filter: DataTable.DATE_RANGE_FILTER  }},
			{ ...!isCarrier && !isDisplay && { title: "Условия оплаты заказчику", field: "contractorPaymentMode", sortable: false, hidden: true, filter: DataTable.DICTIONARY_FILTER, optionsType: "PAYMENT_MODE"  }},
			{ ...!isClient && { title: "Условия оплаты перевозчику", field: "carrierPaymentMode", sortable: false, hidden: true, filter: DataTable.DICTIONARY_FILTER, optionsType: "PAYMENT_MODE"  }},
			{ ...!isDriver && !isCarrier && !isDisplay && { title: "Доп. расходы по заказчику", field: "contractorExpenses", sortable: false, hidden: true }},
			{ ...!isClient && { title: "Доп. расходы по перевозчику", field: "carrierExpenses", sortable: false, hidden: true }},
			{ ...!isClient && { title: "Лимит ГСМ", field: "carrierGsm", sortable: false, hidden: true   }},
			{ ...!isClient && { title: "Пролив ГСМ", field: "gsmStrait", sortable: false, hidden: true   }},
			{ ...!isClient && { title: "% пролива ГСМ", field: "carrierGsmPercentage", sortable: false, hidden: true   }},
			{ ...!isDriver && !isCarrier && !isDisplay && { title: "Итоговая сумма заказчика", field: "contractorFinalPrice", sortable: false, hidden: true  }},
			{ ...!isDriver && !isClient && { title: "Итоговая сумма перевозчику", field: "carrierFinalPrice", sortable: false, hidden: true  }},
			{ title: "Тип оплаты", field: "paymentType", formatter: TableCell.enumFormatter(PAYMENT_TYPES), sortable: false, filter: DataTable.ENUM_FILTER, optionsType: ENUM_NAME.PAYMENT_TYPE, multiSelect: true },
			{ title: "Статус подписания", field: "signingStatus", formatter: TableCell.enumFormatter(SIGNING_STATUSES), sortable: false, filter: DataTable.ENUM_FILTER, optionsType: ENUM_NAME.SIGNING_STATUS },
			{ title: "ТТН", field: "ttnNumber", sortable: false, hidden: true, filter: DataTable.STRING_FILTER  },
			{ title: "Статус ТТН", field: "ttnStatus", sortable: false, hidden: true, filter: DataTable.DICTIONARY_FILTER, optionsType: "TTN_STATUS"  },
			{ title: "Дата ТТН", field: "ttnDt", formatter: TableCell.dayFormatter, sortable: false, hidden: true, filter: DataTable.DATE_RANGE_FILTER  },
			{ title: "Дата загрузки", field: "loadingDt", filter: DataTable.DATE_RANGE_FILTER, formatter: (value) => TableCell.dtFormatter(value) },
			{ title: "Адрес загрузки", field: "loadingAddress", className: "overflow", filter: DataTable.ADDRESS_FILTER },
			{ title: "Дата разгрузки", field: "unloadingDt", filter: DataTable.DATE_RANGE_FILTER, formatter: (value) => TableCell.dtFormatter(value) },
			{ title: "Адрес разгрузки", field: "unloadingAddress", className: "overflow", filter: DataTable.ADDRESS_FILTER },
			{ title: "Тип кузова", field: "carcass", sortable: false, filter: DataTable.DICTIONARY_FILTER, optionsType: "CARCASS" },
			{ ...!isCarrier && { title: "Дата факт. оплаты заявки", field: "paymentDt", formatter: TableCell.dayFormatter, sortable: true, filter: DataTable.DATE_RANGE_FILTER }},
			{ title: "Тип загрузки", field: "loadingType", sortable: false, filter: DataTable.DICTIONARY_FILTER, optionsType: "LOADING_TYPE" },
			{ title: "Тип груза", field: "cargoType", sortable: false, hidden: true, filter: DataTable.DICTIONARY_FILTER, optionsType: "CARGO_TYPE" },
			{ title: "Вес", field: "weight", sortable: false },
			{ title: "Объем", field: "volume", sortable: false },
			{ title: "Темп. режим", field: "temperature", sortable: false },
			{ title: "Описание", field: "description", hidden: true, filter: DataTable.STRING_FILTER },
			{ ...!isCarrier && !isDisplay && { title: "Вид оплаты договора заказчика", field: "clientContractPaymentMode", sortable: false, filter: DataTable.DICTIONARY_FILTER, optionsType: "PAYMENT_MODE" }},
			{ ...!isCarrier && !isDisplay && { title: "Договор заказчика", field: "clientContract",className: "overflow", sortable: false, filter: DataTable.STRING_FILTER  }},
			{ ...!isClient && { title: "Договор перевозчика", field: "carrierContract",className: "overflow", sortable: false, filter: DataTable.STRING_FILTER  }},
			{ ...!isDriver && !isCarrier && !isDisplay && { title: "Сканкопии 'Заявка Заказчика'", field: "hasClientQuoteAttachments", className: "yes-no", formatter: TableCell.booleanFormatter, filter: DataTable.BOOLEAN_FILTER, sortable: false }},
			{ ...!isDriver && !isClient && { title: "Сканкопии 'Заявка Перевозчика'", field: "hasCarrierQuoteAttachments", className: "yes-no", formatter: TableCell.booleanFormatter, filter: DataTable.BOOLEAN_FILTER, sortable: false }},
			{ ...!isDriver && !isCarrier && !isClient && { title: "Сопроводительные документы", field: "hasAccompanyingDocsAttachments", className: "yes-no", formatter: TableCell.booleanFormatter, filter: DataTable.BOOLEAN_FILTER, sortable: false }},
			{ ...!isDriver && !isCarrier && !isDisplay && { title: "Счёт заказчика", field: "hasClientBillAttachments", className: "yes-no", formatter: TableCell.booleanFormatter, filter: DataTable.BOOLEAN_FILTER, sortable: false }},
			{ ...!isDriver && !isCarrier && !isClient && { title: "Копировать заявку", field: "action", formatter: (value, row) => this.renderActions(row), sortable: false }}
		];
	};

	calculateRemainingTime(row) {
		switch (row.status.value) {
			case "Актуальная":
			case "Биржа":
				return Util.calculateRemainingTime(row.expireDt);
			default:
				return "";
		}
	}

	renderActions(row) {
		if (row.status.value === RequestStatus.FREE.value) {
			return;
		}
		return (
			<span className="actions">
                <button className="fa-border" title="Копировать заявку" onClick={() => this.openCopyForm(row)}>Копировать заявку</button>
            </span>
		);
	}
	
	fetch(params) {
		params.statuses = this.getStatuses();
		if (this.props.fetch) {
			return this.props.fetch(params);
		}

		if (this.props.type === QuoteType.MONITORING) {
			const lastValidDt = Util.minusDays(new Date(), DELAY_DAYS);
			params.loadingDt = {from: null, to: lastValidDt};
			params.unloadingDt = {from: null, to: lastValidDt};
		}

		if (this.props.type === QuoteType.FREE) {
			params.status = new Array(RequestStatus.FREE);
		}

		return quoteService.list(params);
	}

	fetchXLS(params) {
		return exportImportService.xlsQuoteExport(params);
	}

	getPathname() {
		return this.props.location.pathname === "/" ? "/quotes/quote" : `${this.props.type}/quote`;
	}

	openAddForm() {
		window.open(this.getPathname(), "_blank");
	}

	openEditForm(row) {
		window.open(this.getPathname() + `?id=${row.id}`, "_blank");
	}

	openCopyForm(row) {
		window.open(this.getPathname() + `?id=${row.id}&action=copy`, '_blank');
	}
	
	onChange(name, value) {
		const field = name === "id" ? "number" : name,
			row = this.state.data.filter(r => r.id === this.state.editor.id).first();
		row[field] = value;
		this.setState({
			editor: {...this.state.editor, value}
		});	
	}

	resizeListener() {
		this.setState({tableHeight: this.getTableHeight()});
	}

	addRemoveResizeListener(listen) {
		const addRemove = listen ? document.body.addEventListener : document.body.removeEventListener;
		addRemove("resize", this.resizeListener.bind(this), true);
	}

	componentWillMount() {
		this.addRemoveResizeListener(true);
	}

	getTableHeight() {
		return window.innerHeight - 170;
	}

	componentWillUnmount() {
		this.addRemoveResizeListener(false);
	}
	
	componentDidMount() {
		if (securityService.anyRolesButAngel(securityService.getUser(), LOGISTIC)) {
			let statuses = [];
			if (securityService.anyRoles(securityService.getUser(), HEAD_OF_DEPARTMENT, REGIONAL_DIRECTOR, ADMIN)) {
				statuses = RequestStatus.getMonitoringHeadOfDeptRequestStatuses();
			} else if (securityService.anyRolesButAngel(securityService.getUser(), LOGISTIC)) {
				statuses = RequestStatus.getMonitoringLogisticRequestStatuses();
			}
			const lastValidDt = Util.minusDays(new Date(), DELAY_DAYS);
			quoteService.list({
				page: 0,
				pageSize: 2147483647, // Integer.MAX_VALUE
				statuses: statuses,
				loadingDt: {from: null, to: lastValidDt},
				unloadingDt: {from: null, to: lastValidDt}
			}).then(response => this.setState({
				dataTableAlerts: this.formatMonitoringErrors(response.data),
				dataTableNotifications: this.formatMonitoringErrors(response.data),
				dataTableAlertsLoaded: true
			}));
		}
	}

	formatMonitoringErrors(errors) {
		return errors
			.map(error => {
				const statusId = error.status.id;
				if (error.loadingDt && Util.isPassed(Util.plusDays(error.loadingDt, DELAY_DAYS)) &&
					RequestStatus.AFTER_LOADING_STATUS_IDS.indexOf(statusId) >= 0) {
					return { id: error.id, value: "Необходимо перевести заявку в один из статусов: 'На погрузке', 'Погрузилась', 'В пути', 'Проблема'" };
				} else if (error.unloadingDt && Util.isPassed(Util.plusDays(error.unloadingDt, DELAY_DAYS)) &&
					RequestStatus.AFTER_UNLOADING_STATUS_IDS.indexOf(statusId) >= 0) {
					return { id: error.id, value: "Необходимо перевести заявку в один из статусов: 'Разгрузилась', 'Срыв', 'Проблема'" };
				}
			})
			.filter(error => !!error);
	}
	
	getMonitoringMode() {
		return localStorage.getItem("monitoringMode") === "true";
	}
	
	renderMonitoringRestriction() {	
		return (
			<tr>
				<td colSpan="99" className="text-center">в пункте меню "Мониторинг" сократите количество неактуализированных заявок до 10 штук</td>
			</tr>
		);
	}

	getFilterTabsByQuoteType() {
		let field = "";
		let filterTabs = null;

		switch (this.props.type) {
			case QuoteType.QUOTE: {
				field = "statuses";
				filterTabs = this.getQuoteFilterTabs();
				break;
			}
			case QuoteType.FREE: {
				field = "paymentType";
				filterTabs = this.getFreeFilterTabs();
			}
		}

		return {field: field, filterTabs: filterTabs};
	}

	getQuoteFilterTabs() {
		return [
			{key: "ALL", title: "Все", value: RequestStatus.getQuoteRequestStatuses()},
			{key: "ACTUAL", title: "Актуальная", value: RequestStatus.getActualStatuses()},
			{key: "AT_WORK", title: "В работе", value: RequestStatus.getAtWorkStatuses()},
			{key: "ON_WAY", title: "В пути", value: RequestStatus.getOnWayStatuses()},
			{key: "EXCHANGE", title: "Биржа", value: RequestStatus.getExchangeStatuses()},
			{key: "COMPLETED", title: "Завершенные", value: RequestStatus.getCompletedStatuses()}
		];
	}

	getFreeFilterTabs() {
		return [
			{key: "ALL", title: "Все", value: PaymentType.ALL},
			{key: "AUCTION", title: "Аукцион", value: [PaymentType.AUCTION]},
			{key: "FIXED_BET", title: "Фиксированная ставка", value: [PaymentType.FIXED_BET]}
		];
	}

	getColumns() {
		this.setState({columns: this.columns().filter(col => { return Object.keys(col).length !== 0 })})
	}

	isSameExpeditor(row) {
		return securityService.getUser().forwarder.id === row?.forwarder?.id
	}

	contextMenuObj = [
		{title:"Копировать заявку", action: row => this.openCopyForm(row), isVisible: row => this.isSameExpeditor(row)},
		{title: "Открыть заявку", action: row => this.openEditForm(row), isVisible: () => true}
	]

	render() {
		const isLogistic = securityService.isLogistic(securityService.getUser());
		if (isLogistic && this.getMonitoringMode() && !this.state.dataTableAlertsLoaded) {
			return (
				<div className="loader">
					<ThreeDots height="80" width="80" radius="9" color="#4e73df" visible={true} />
				</div>
			);
		} else {
			const isQuote = this.checkType(QuoteType.QUOTE) && this.getMonitoringMode();
			const isMonitoring = this.checkType(QuoteType.MONITORING) && this.getMonitoringMode();
			const hasMonitoringRestriction = isLogistic && isQuote && this.state.dataTableAlerts.length > 10;
			const {field, filterTabs} = this.getFilterTabsByQuoteType();
			return (
				<div>
					<Row>
						<Col>
							<DataTable {...this.props}
									   contextMenuObj={this.contextMenuObj}
									   filterTabs={filterTabs}
									   defaultFilterParam={field}
									   serviceFilterTabs={QuoteService}
									   title = {this.props.title}
									   openAddForm = {this.openAddForm.bind(this)}
									   openEditForm = {this.openEditForm.bind(this)}
									   fetch = {!hasMonitoringRestriction && this.fetch.bind(this)}
									   fetchXLS = {this.fetchXLS.bind(this)}
									   tableHeaderRenderer = {this.renderTableHeader}
									   getQuoteStatuses={this.getQuoteStatuses}
									   tableBodyRenderer = {hasMonitoringRestriction && this.renderMonitoringRestriction}
									   features = { this.props.features || {
										   xls: true, // TODO: !securityService.hasSingleRole(securityService.getUser(), LOGISTIC),
										   canAdd: (!isMonitoring && !(isLogistic && isQuote && this.state.dataTableAlerts.length >= 20))
											   && securityService.anyRoles(securityService.getUser(), ADMIN, COMMERCIAL, LOGISTIC, HEAD_OF_DEPARTMENT, REGIONAL_DIRECTOR),
										   showAlertCenter: isLogistic && (isQuote || isMonitoring),
										   renderMonitoringRestriction: isLogistic && isQuote && this.state.dataTableAlerts.length > 10
									   }}
									   resizable = {true}
									   columns = {this.columns().filter(col => { return Object.keys(col).length !== 0 })}
									   style = {{maxHeight: this.state.tableHeight}}
									   tableClassName = "no-line-break"
									   dataTableAlerts = {this.state.dataTableAlerts}
									   dataTableNotifications = {this.state.dataTableNotifications}
									   alertClickHandler = {this.openEditForm.bind(this)}
									   alertItemTitle={"Заявка"}
									   getColumns = {this.getColumns}
							/>
						</Col>
					</Row>
				</div>
			);
		}
	}
}
export default withRouter(connect(Quotes.mapStateToProps)(Quotes));