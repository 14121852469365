import { connect } from 'react-redux';
import {Form, Row, Col, Button, Card, Table} from "react-bootstrap";
import BaseForm from '../../form/BaseForm';
import motivationService from "../../../services/MotivationService";
import StoreWrapper from '../../form/StoreWrapper';
import {HEAD_OF_DEPARTMENT, securityService} from "../../../services/SecurityService";
import React from "react";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import {setValue} from "../../../actions/form";
import Modal from "react-bootstrap/Modal";
import "./Motivation.css";
import Util from "../../../Util";
import CoefficientTable from "./CoefficientTable";
import SalaryTable from "./SalaryTable";
import WagesReportTable from "./WagesReportTable";
import PaidQuotes from "./PaidQuotes";
import {RequestStatus} from "../../../Const";

function mapStateToProps(state) {
    return {
        errors: state.errors,
        model: state.model
    };
}

class Motivation extends StoreWrapper {
    constructor(props) {
        super(props);
    }

    static USER_ID = "userId";
    static REVENUE = "revenue";
    static PROFITABILITY = "profitability";
    static FINAL_PROFITABILITY = "finalProfitability";
    static REVENUE_PLAN = "revenuePlan";
    static MARGIN = "margin";
    static MARGIN_PLAN = "marginPlan";
    static FUEL_AMOUNT = "fuelAmount";
    static GSM_STRAIT = "gsmStrait";
    static FUEL_DISCOUNT = "fuelDiscount";
    static DUTY = "duty";
    static RECOVERABLE_VAT = "recoverableVAT";
    static SALARY = "salary";
    static WORKING_DAYS = "workingDays";
    static LOCAL_DATE = "localDate";
    static DEPARTMENT = "department";
    static DEPARTMENT_ID = "departmentId";
    static ROLE = "role";
    static FIO = "fio";
    static QUOTES_NUMBER = "quotesNumber";
    static PENALTY_M = "penaltyM";
    static PENALTY_D = "penaltyD";
    static INTEGRAL_INDICATOR = "integralIndicator";
    static PAID_QUOTES_MOTIVATION = "paidQuotesMotivation";
    static PAID_QUOTES_MOTIVATION_WITH_GSM = "paidQuotesMotivationWithGSM";
    static MOTIVATION_PART = "motivationalPart";
    static DEDUCTED_MARGIN = "deductedMargin";
    static TOTAL = "total";
    static GSM_DISCOUNT = "gsmDiscount";

    render() {
        return (
            <MotivationInnerConnected {...this.props} store={this.store} />
        );
    }
}

class MotivationInner extends BaseForm {
    static FIELDS = [
        { key: Motivation.REVENUE, value: "Выручка (сумма)" },
        { key: Motivation.PROFITABILITY, value: "Рентабельность (грязная %)" },
        { key: Motivation.REVENUE_PLAN, value: "План по выручке (сумма)" },
        { key: Motivation.MARGIN_PLAN, value: "План по марже (сумма)" },
        { key: Motivation.FUEL_AMOUNT, value: "ГСМ (cумма)" },
        { key: Motivation.GSM_DISCOUNT, value: "Скидка на ГСМ (cумма)" },
        { key: Motivation.DUTY, value: "Дежурства (cумма)" },
        { key: Motivation.RECOVERABLE_VAT, value: "НДС перевозчиков к возмещению (cумма)" },
        { key: Motivation.SALARY, value: "Оклад (cумма)" },
        { key: Motivation.WORKING_DAYS, value: "Рабочих дней (натуральное число)" }
    ];

    constructor(props) {
        super(props);

        const localDate = this.getCurrentDate()

        this.state = {
            user: securityService.getUser(),
            userId: securityService.getUser().id,
            localDate,
            data: {},
            result: {},
            toggle: false,
            showCoefficientRedactorModal: false,
            showSalaryRedactorModal: false,
            showReportTableModal: false,
            margin: undefined,
            departmentId: undefined,
            quotesNumber: undefined,
            paidQuotesMotivation: undefined,
            disabledMonthButton: false,
            isForPaidQuotes: false,
            top: []
        };

        this.props.store.dispatch(setValue(Motivation.USER_ID, this.state.userId));
        this.props.store.dispatch(setValue(Motivation.LOCAL_DATE, localDate.toISOString()));
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        this.setPerformance(true);
    }

    getCurrentDate() {
        const now = new Date(),
            localDate = new Date(now.getFullYear(), now.getMonth(), 1),
            offset = localDate.getTimezoneOffset();
        localDate.setTime(localDate.getTime() - offset * 60000);

        return localDate;
    }

    setPerformance(isFact) {
        const quoteStatusIdList = RequestStatus.getQuoteStatusIdsByToggleButton(this.state.toggle);
        const data = {
            dt: this.state.localDate,
            quoteStatusIdList: quoteStatusIdList,
            userId: this.state.userId,
            isFact: isFact,
            isForPaidQuotes: false
        }

        motivationService.post("performance", data)
            .then(response => {
                this.setState({result: response});
                this.onChange(Motivation.DEPARTMENT, response.department);
                this.onChange(Motivation.FIO, response.fio);
                this.onChange(Motivation.QUOTES_NUMBER, response.quotesNumber);
                this.onChange(Motivation.PAID_QUOTES_MOTIVATION, response.paidQuotesMotivation);

                MotivationInner.FIELDS.map(field => {
                    this.onChange(field.key, response[field.key] === null ? 0 : response[field.key]);
                });

                const isAllFieldsAreFilled = MotivationInner.FIELDS
                    .map(field => this.props.model[field.key])
                    .every(it => !!it || it === 0);

                if (!isAllFieldsAreFilled) {
                    return;
                }

                this.setState({disabledMonthButton: false});
            });

        motivationService.post("topLogistic", {dt: this.state.localDate, quoteStatusIdList: RequestStatus.getQuoteStatusIdsByToggleButton(true)})
            .then(response => {
                this.setState({top: response})
            });
    }

    clear() {
        MotivationInner.FIELDS.map(field => this.onChange(field.key, 0));
    }

    handleSubmit(e) {
        e.preventDefault();

        this.props.model.margin = this.state.margin;
        this.props.model.role = this.state.result?.role;
        this.props.model.departmentId = this.state.result?.departmentId;
        this.props.model.penaltyM = this.state.result.penaltyM;
        this.props.model.penaltyD = this.state.result.penaltyD;

        this.submit(() => motivationService.post("", {motivation: this.props.model, isForPaidQuotes: false})
            .then((response) => {
                this.setState({result: response});
                this.setSending(false);
            }));
    }

    showCoefficientRedactorModal(show) {
        this.setState({showCoefficientRedactorModal: show});
    }

    showSalaryRedactorModal(show) {
        this.setState({showSalaryRedactorModal: show});
    }

    showReportTableModal(show, isForPaidQuotes) {
        if (isForPaidQuotes) {
            this.setState({isForPaidQuotes: true});
        } else {
            this.setState({isForPaidQuotes: false});
        }

        this.setState({showReportTableModal: show});
    }

    showPaidQuotesModal(show) {
        this.setState({showPaidQuotesModal: show});
    }

    render() {
        if (this.state.showReportTableModal) {
            return (
                <>
                    <Button style={{width: "100%"}} variant="light" onClick={() => this.setState({showReportTableModal: false})}>
                        {"( ⇐ вернуться ) " + this.formatMonth(this.state.localDate)}
                    </Button>
                    <WagesReportTable
                        dt={this.props.model.localDate}
                        userId={this.props.model.userId}
                        isForPaidQuotes={this.state.isForPaidQuotes} />
                </>);
        }

        return (
            <Col style={{display: "flex", flexWrap: "wrap"}}>

                <Modal size="lg"
                       aria-labelledby="example-modal-sizes-title-sm"
                       show={this.state.showCoefficientRedactorModal}
                       onHide={() => this.showCoefficientRedactorModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Коэффициенты {String(this.props.model.localDate).substring(0, 7)}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <CoefficientTable dt={this.props.model.localDate} />
                    </Modal.Body>
                </Modal>

                <Modal show={this.state.showSalaryRedactorModal}
                       onHide={() => this.showSalaryRedactorModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Оклады
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <SalaryTable/>
                    </Modal.Body>
                </Modal>

                <Modal show={this.state.showPaidQuotesModal}
                       onHide={() => this.showPaidQuotesModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Заявки
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <PaidQuotes dt={this.props.model.localDate}
                                    userId={this.props.model.userId}
                                    departmentId={this.state.result.departmentId}
                                    isHead={this.state.user.roles.includes(HEAD_OF_DEPARTMENT)}/>
                    </Modal.Body>
                </Modal>

                <div>
                    {this.renderChangeMonth()}
                    <Form style={{width: "600px"}}>
                        <Row style={{display: "flex", justifyContent: "space-between"}}>
                            <Col style={{paddingLeft: 0}}>
                                <BootstrapSwitchButton
                                    toggle={this.state.toggle}
                                    onstyle="outline-primary"
                                    offstyle="outline-secondary"
                                    size="sm"
                                    onChange={value => {this.setState({toggle: value})}}
                                />
                                <span> Учитывать заявки в процессе</span>
                            </Col>
                            <Col style={{textAlign: "right", paddingRight: 0}}>
                                {this.optionButton("Факт", () => this.setPerformance(true))}
                                {this.optionButton("Тренд", () => this.setPerformance(false))}
                                {this.optionButton("Очистить", () => this.clear())}
                            </Col>
                        </Row>
                        {MotivationInner.FIELDS.map(field => this.inputFields(field))}
                        <br/>
                        <Row>
                            <Button style={{width: "100%", marginBottom: "25px"}} onClick={this.handleSubmit.bind(this)} type="submit" size="sm"
                                    variant="primary" className="pull-right" disabled={this.state.sending}>
                                {this.state.sending && (<span className="spinner-border spinner-border-sm"></span>)}
                                <span>Рассчитать</span>
                            </Button>
                        </Row>
                    </Form>
                </div>
                <Col>
                    {(securityService.isAdmin(this.state.user) || securityService.isSupervisor(this.state.user)) &&
                        <Col style={{textAlign: "right", paddingRight: "30px", marginBottom: "-30px"}}>
                            {this.optionButton("Настройка коэффициентов", () => this.showCoefficientRedactorModal(true))}
                            {this.optionButton("Настройка оклада", () => this.showSalaryRedactorModal(true))}
                        </Col>
                    }
                    <Col>
                        <Col style={{marginTop : "-5px"}} className="mb-1">
                            <Col className="calculate-label">Рассчёт:</Col>
                        </Col>
                        <Col className="mb-1">
                            <Card body>
                                <div style={{display: "flex", textAlign: "center", flexWrap: "wrap"}}>
                                    {this.totalBlock("Маржа (чистая)", this.state.result.margin)}
                                    {this.totalBlock("Мотивационная часть", this.state.result.motivationalPart)}
                                    {this.totalBlock("Итог", this.state.result.total)}
                                </div>
                            </Card>
                        </Col>
                    </Col>
                    {this.renderReportCard()}
                    {this.renderTopOfLogistics()}
                    {this.renderWageReportButtons()}
                </Col>
            </Col>
        )
    }

    renderTopOfLogistics() {
        return (
            <Col>
                <Col>
                    <Col className="calculate-label">Топ логистов месяца:</Col>
                    <Table striped bordered size="sm">
                        <thead>
                            <tr style={{backgroundColor: "white"}}>
                                <td>☆</td>
                                <td>Подразделение</td>
                                <td>ФИО логиста</td>
                                <td>Выручка</td>
                                <td>Маржа (чистая)</td>
                            </tr>
                        </thead>
                        <tbody>
                        {this.state.top?.map(l => (
                            <tr>
                                <td>{this.state.top?.indexOf(l) + 1}</td>
                                <td>{l.department}</td>
                                <td>{l.fio}</td>
                                <td>{Number(l.revenue).toLocaleString()}</td>
                                <td>{Number(l.margin).toLocaleString()}</td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </Col>
            </Col>
        );
    }

    renderReportCard() {
        const REPORT_1 = [
            { key: "Подразделение: ", value: this.state.result.department },
            { key: "ФИО: ", value: this.state.result.fio },
            { key: "Количество заявок: ", value: this.state.result.quotesNumber },
            { key: "Выручка: ", value: Number(this.props.model.revenue).toLocaleString() },
            { key: "Рентабельность (чистая): ", value: this.state.result.finalProfitability + " %" },
            { key: "Маржа (чистая): ", value: Math.round(this.state.result.margin).toLocaleString() },
            { key: "План по выручке: ", value: Number(this.props.model.revenuePlan).toLocaleString() },
            { key: "План по Марже: ", value: Number(this.props.model.marginPlan).toLocaleString() }
        ];
        const REPORT_2 = [
            { key: "Лимит ГСМ: ", value: Number(this.props.model.fuelAmount).toLocaleString() },
            { key: "Скидка ГСМ: ", value: Number(this.props.model.gsmDiscount).toLocaleString() },
            { key: "НДС к возмещению: ", value: Number(this.props.model.recoverableVAT).toLocaleString() },
            { key: "Дежурства: ", value: Number(this.props.model.duty).toLocaleString() },
            { key: "Штрафы: ", value: Number(this.state.result.penaltyD + this.state.result.penaltyM).toLocaleString() },
            { key: "Оклад: ", value: Number(this.props.model.salary).toLocaleString() },
            { key: "Рабочих дней: ", value: this.props.model.workingDays },
            { key: "Рассчитанная ЗП: ", value: Math.round(this.state.result.total).toLocaleString() },
            { key: "Мотивационная часть по оплаченным заявкам: ", value: Math.round(this.state.result.paidQuotesMotivation).toLocaleString() }
        ];
        const halfReport = (REPORT) => <div style={{width: "50%"}}>
            <ul>
                {REPORT.map(r => r.key === "Мотивационная часть по оплаченным заявкам: "
                    ? <li
                        className="paidQuotes"
                        onClick={() => this.showPaidQuotesModal(true)}>
                        {r.key}<b style={{color: "red"}}>{r.value}</b>
                    </li>
                    : <li>{r.key}<b style={{color: "red"}}>{r.value}</b></li>
                )}
            </ul>
        </div>;

        return (<>
            <Col>
                <Col className="mb-1">
                    <Col className="calculate-label">Отчёт:</Col>
                </Col>
                <Col className="mb-1">
                    <Card body>
                        <div style={{display: "flex"}}>
                            {halfReport(REPORT_1)}
                            {halfReport(REPORT_2)}
                        </div>
                    </Card>
                </Col>
            </Col>
        </>);
    }

    renderWageReportButtons() {
        return <Col style={{paddingTop: "15px", textAlign: "right"}}>
            <Button style={{width: "200px", marginRight: "5px"}} size="sm" variant="success" id="dropdown-basic"
                    onClick={() => this.showReportTableModal(true, false)}>
                Открыть отчёт по заявкам за месяц
            </Button>
            <Button style={{width: "200px", marginRight: "5px"}} size="sm" variant="success" id="dropdown-basic"
                    onClick={() => this.showReportTableModal(true, true)}>
                Открыть отчёт по оплаченным заявкам
            </Button>
            {(securityService.isAdmin(this.state.user) || securityService.isSupervisor(this.state.user)) &&
                <Button style={{width: "200px"}} size="sm" variant="primary" id="dropdown-basic"
                        onClick={() => this.calculateWagesForReport()}>
                    Рассчитать зарплаты по оплаченным заявкам
                </Button>
            }
        </Col>;
    }

    calculateWagesForReport() {
        const data = {
            dt: this.state.localDate,
            userId: this.state.userId
        };

        motivationService.post("deleteWages", data).then(
            motivationService.post("calculateWages", data)
        );
    }


    renderChangeMonth() {
        return (
            <div style={{textAlign: "center", fontSize: "large"}}>
                <button
                    disabled={this.state.disabledMonthButton}
                    className="change-month"
                    onClick={e => this.changeMonth(e,-1)}>
                    &lt;&lt;
                </button>
                <span>{this.formatMonth(this.state.localDate)}</span>
                <button
                    disabled={this.state.disabledMonthButton}
                    className="change-month"
                    onClick={e => this.changeMonth(e,1)}>
                    &gt;&gt;
                </button>
            </div>
        );
    }

    async changeMonth(e, step) {
        e.preventDefault();

        this.setState({disabledMonthButton: true});

        const dt = this.state.localDate;
        dt.setMonth(dt.getMonth() + step);

        const localDate = new Date(dt.getTime());
        this.setState(localDate);
        this.props.store.dispatch(setValue(Motivation.LOCAL_DATE, localDate.toISOString()));
        this.setPerformance(true);
    }

    formatMonth(dateToChange) {
        const date = Util.formatIsoDate(dateToChange),
            parts = date.split('-');
        return parts[0] + '-' + parts[1];
    }

    inputFields(field) {
        return (
            <Row>
                <Form.Label className="calculate-label"
                            htmlFor={field.key}>{field.value}
                </Form.Label>
                <Form.Control type="number"
                              title={field.value}
                              name={field.key}
                              placeholder={field.value}
                              step="1" min="-999999999" max="999999999"
                              onChange={e => this.onChange(e.target.name, e.target.value)}
                              value={this.props.model[field.key]}
                />
            </Row>
        );
    }

    totalBlock(label, value) {
        return <Col style={{width: "50%"}}>
            <div>
                <label>{label}</label>
                <p
                    className="form-control-plaintext margin-profit green">
                    {Math.round(value)}
                </p>
            </div>
        </Col>
    }

    optionButton(label, action) {
        return <Button
            style={{marginLeft: "5px", marginBottom: "25px"}}
            variant="outline-primary"
            size="sm"
            onClick={action}
            disabled={this.formatMonth(this.state.localDate) !== this.formatMonth(this.getCurrentDate()) && label === "Тренд"}>
            <span>{label}</span>
        </Button>
    }
}

const MotivationInnerConnected = connect(mapStateToProps)(MotivationInner);

export default Motivation;