import mapService from "../../services/MapService";

class MarkerIcons {
    ROUTE_POINT_IMG_PATH = "https://i.postimg.cc/tTfwT6yZ/point.png";
    FREE_TRUCK_IMG_PATH = "https://i.postimg.cc/7ZfXjQZy/green-marker-truck-3.png";
    BUSY_TRUCK_IMG_PATH = "https://i.postimg.cc/3NQs369N/black-marker-truck-2.png";
    END_ROUTE_IMG_PATH = "https://i.postimg.cc/C5z8vTpf/end.png";
    START_ROUTE_IMG_PATH = "https://i.postimg.cc/PJ9w93dV/start.png";
    CHANGE_STATUS_IMG_PATH = "https://i.postimg.cc/wMqQzB60/change-status.png";

    getIconsForDriver(dateTime) {
        return {
            iconLayout: "default#image",
            iconImageHref: this.getDriverPlacemarkColor(dateTime),
            iconImageSize: [40, 40],
            iconImageOffset: [-20, -40]
        }
    }

    getDriverPlacemarkColor(dt) {
        return mapService.isActive(dt)
            ? this.FREE_TRUCK_IMG_PATH
            : this.BUSY_TRUCK_IMG_PATH;
    }

    getRouteColor() {
        return "ff66cc";
    }

    getImageSettingsForActualRoute(arrayLength, iteration) {
        let imageSettings = {
            iconLayout: "default#image",
            iconImageSize: [60, 30],
            iconImageOffset: [-30, -30]
        }

        if (iteration === 0) {
            imageSettings.iconImageHref = this.START_ROUTE_IMG_PATH;
        } else if (iteration === (arrayLength - 1)) {
            imageSettings.iconImageHref = this.END_ROUTE_IMG_PATH;
        } else {
            imageSettings.iconImageHref = this.ROUTE_POINT_IMG_PATH;
            imageSettings.iconImageSize = [8, 8];
            imageSettings.iconImageOffset = [-4, -4];
        }

        return imageSettings;
    }

    getImageSettingsForStatusChanges() {
        return {
            iconLayout: "default#image",
            iconImageHref: this.CHANGE_STATUS_IMG_PATH,
            iconImageSize: [40, 40],
            iconImageOffset: [-20, -40]
        }
    }
}

export default MarkerIcons;