import React, { PureComponent } from "react";

class ValidationErrors extends PureComponent {
	constructor(props) {
		super(props);
	}
	
	render() {
		if (!this.props.errors || this.props.errors.length === 0) {
			return (null);
		}
		const errors = Array.isArray(this.props.errors) ? this.props.errors : [this.props.errors];
		return (
			<div className="validation alert alert-danger" role="alert">
				{errors.map((err, idx) => <small key={idx}>{err}<br/></small>)}
			</div>
		);
	}
}
export default ValidationErrors;