import React from "react";
import {connect} from 'react-redux';
import {Badge, Button, Card, Col, Container, Form, Row, Table} from "react-bootstrap";
import BaseForm from '../../form/BaseForm';
import addressService from "../../../services/AddressService";
import {
    ADMIN,
    ANGEL,
    HEAD_OF_DEPARTMENT,
    REGIONAL_DIRECTOR,
    securityService
} from "../../../services/SecurityService";
import FormValidator from "../../../validators/FormValidator";
import Address from "./Address";
import Modal from "react-bootstrap/Modal";

function mapStateToProps(state, ownProps) {
    const {id, name, value} = ownProps.componentSource === 'contractor' ? state.model : state.model.contractor;

    const user = {
        ...state.user,
        isClient: securityService.isClient(state.user)
    }

    return {
        addresses: state.model.addresses,
        errors: state.errors,
        user: user,
        contractor: {
            id: id,
            value: name || value,
        },
    };
}

class Addresses extends BaseForm {

    constructor(props) {
        super(props);
        this.state = {
            currentIndex: -1,
            selectedRows: []
        }

        this.handleAdd = this.handleAdd.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
        this.handleClose = this.handleClose.bind(this);

        this.renderAddressesTable = this.renderAddressesTable.bind(this);

        this.initCurrentIndex = this.initCurrentIndex.bind(this);
        this.setCurrentIndex = this.setCurrentIndex.bind(this);
    }

    initCurrentIndex() {
        this.setCurrentIndex(this.props.addresses.length ? 0 : -1);
    }

    setCurrentIndex(index) {
        this.setState({currentIndex: index});
    }

    handleAdd() {
        const isCanAdd = this.props.addresses.length === 0 || this.props.addresses[0]?.latitude;
        if(isCanAdd) {
            const addresses = this.props.addresses;
            addresses.unshift({
                [Address.FIELD_ADDR]: '',
                [Address.FIELD_LATITUDE]: null,
                [Address.FIELD_LONGITUDE]: null,
                [Address.FIELD_FIAS_ID]: null,
                [Address.FIELD_COUNTRY_ISO]: null,
                [Address.FIELD_REGION_ISO]: null,
                [Address.FIELD_CONTRACTOR]: this.props.contractor.id
            });
            super.onChange("addresses", [...addresses]);
            this.selectAddressesIndex(0);
        } else {
            this.props.setToastObjAC({show: true, textHeader: "Заполните пустой адрес!", delay: 3000})
        }
    }

    async handleRemove(index) {
        const addresses = this.props.addresses;
        if (addresses[index].id) {
            this.setSending(true);
            const deleteResult = await addressService.delete(this.props.contractor.id, addresses[index].id);
            if (deleteResult.success) {
                addresses.removeAt(index);
                super.onChange("addresses", [...addresses]);
                this.initCurrentIndex();
            } else {
                alert(deleteResult.message);
            }
            this.setSending(false);
        } else {
            addresses.removeAt(index);
            super.onChange("addresses", [...addresses]);
            this.initCurrentIndex();
        }
    }

    formatFieldName(field) {
        return `${this.getFieldNamePrefix()}.${field}`;
    }

    getFieldNamePrefix() {
        return `addresses.${this.state.currentIndex}`;
    }

    selectAddressesIndex(index) {
        this.setState({
            addresses: this.props.addresses[index],
            currentIndex: index,
            showModal: true
        });
    }

    //	TODO: remove after fill in
    renderISOAutoFillInButton() {
        return (<Button onClick={this.handleISOFillIn.bind(this)} size="sm" variant="primary" className="pull-right" disabled={this.state.sending}>
            {this.state.sending && (<span className="spinner-border spinner-border-sm"></span>)}
            <span>Заполнить ISO-коды</span>
        </Button>);
    }

    //	TODO: remove after fill in
    handleISOFillIn(e) {
        e.preventDefault();
        this.setSending(true);
        addressService.fillInISOCodesOfClientContractors().then((r) =>
            this.setSending(false)
        );
    }

    setActiveIndexByUri() {
        const activeAddressId = new URLSearchParams(this.props.location.search).get("activeAddress");
        if (activeAddressId) {
            for (let i = 0; i < this.props.addresses?.length; i++) {
                if (Number(this.props.addresses?.[i].id) === Number(activeAddressId)) {
                    this.setCurrentIndex(i);
                    return;
                }
            }
        }
    }

    handleClose() {
        this.setState({showModal: false})
    }

    render() {
        this.setActiveIndexByUri();

        return (
            <Form>
                <Container fluid>
                    <Row>
                        <Col>
                            {this.state.currentIndex >= 0 && !this.props.user.isClient &&
                                <Modal show={this.state.showModal} style={{minWidth: "100%"}} className={"modal-lg"} onHide={this.handleClose}>
                                    <Address
                                        {...this.props}
                                        type={Address.TYPE_ADDRESS}
                                        currentIndex={this.state.currentIndex}
                                        fieldNameFormatter={this.formatFieldName.bind(this)}
                                        reRender={() => this.forceUpdate()}
                                        handleClose={() => this.handleClose()}
                                    />
                                </Modal>
                            }
                        </Col>
                    </Row>
                    <Row className="text-right">
                        <Col>
                            {this.renderAddressesTable()}
                        </Col>
                    </Row>

                    {/*	TODO: remove after fill in */}
                    <Row className="justify-content-center">
                        {securityService.hasRole(securityService.getUser(), ANGEL) && <Col className="col-auto">
                            {this.renderISOAutoFillInButton()}
                        </Col>}
                    </Row>
                </Container>
            </Form>
        );
    }

    renderAddressesTable() {
        const addresses = this.props.addresses;
        if (!addresses || !addresses.length) {
            return (
                <Row className="text-center">
                    <Col className="form-group">
                        <div className="mb-2">Адреса отсутсвуют</div>
                        <div>
                            <Button onClick={this.handleAdd} size="sm" variant="primary">
                                <span>добавить</span>
                            </Button>
                        </div>
                    </Col>
                </Row>
            );
        }
        return (
            <Card className="m-1 bg-light merge">
                <Card.Header className="flex-space-between">
                    <div style={{width: "50%"}}>
                        <Form.Control type="string"
                                      size="sm"
                                      placeholder={"Найти..."}
                                      onChange={e => this.setState({searchString: e.target.value})}/>
                    </div>
                    <div>
                        <Button onClick={this.handleAdd}
                                size="sm"
                                variant="primary"
                                className="pull-right"
                                disabled={this.props.user.isClient}>
                            <span>добавить</span>
                        </Button>
                    </div>
                </Card.Header>
                <Card.Body style={{height: this.props.user.isClient ? "100vh" : "40vh", overflowY: "auto", padding: 0}}>
                    <div>
                        <Table bordered hover size="sm" className="selectable addresses-table">
                            <thead>
                            <tr>
                                <th className="col-3">Регион</th>
                                <th className="col-3">Район</th>
                                <th className="col-3">Город</th>
                                <th className="col-4">Адрес</th>
                                <th className="col-1">Широта</th>
                                <th className="col-1">Долгота</th>
                                <th className="col-1">Радиус км.</th>
                                <th className="col-1 text-center"></th>
                            </tr>
                            </thead>
                            <tbody>
                            {addresses.map(this.renderAddressesTableRow.bind(this))}
                            </tbody>
                        </Table>
                    </div>
                </Card.Body>
            </Card>
        );
    }

    renderAddressesTableRow(addr, index) {
        const filter = this.state.searchString === undefined ||
            addr["city"]?.toLowerCase()?.includes(this.state.searchString?.toLowerCase()) ||
            addr[Address.FIELD_CITY_DTO]?.value?.toLowerCase()?.includes(this.state.searchString?.toLowerCase()) ||
            addr["region"]?.value?.toLowerCase()?.includes(this.state.searchString?.toLowerCase()) ||
            addr[Address.FIELD_DISTRICT]?.value?.toLowerCase()?.includes(this.state.searchString?.toLowerCase()) ||
            addr[Address.FIELD_ADDR]?.toLowerCase()?.includes(this.state.searchString?.toLowerCase());
        const handler = () => {
            this.selectAddressesIndex(index);
        }
        return filter && (
            <tr key={`addresses-table-row-${index}`}
                className={index === this.state.currentIndex ? "active" : null}>
                <td>{addr["region"]?.value}</td>
                <td>{addr[Address.FIELD_DISTRICT]?.value}</td>
                <td>{addr[Address.FIELD_CITY_DTO]?.value || addr["city"]}</td>
                <td>{addr[Address.FIELD_ADDR]}</td>
                <td>{addr[Address.FIELD_LATITUDE]}</td>
                <td>{addr[Address.FIELD_LONGITUDE]}</td>
                <td>{addr[Address.RADIUS_KM]}</td>
                <td className="col-1 text-center">
                    <Button onClick={() => handler()}
                            size="sm"
                            variant="primary"
                            className="btn-xs">
                        <span>Изменить</span>
                    </Button>
                </td>
                <td className="col-1 text-center">
                    <Button onClick={() => this.handleRemove(index)}
                            size="sm"
                            variant="danger"
                            className="btn-xs"
                            disabled={this.props.user.isClient}>
                        <span>Удалить</span>
                    </Button>
                </td>
            </tr>
        );
    }

    renderSelectColumn(address) {
        const id = address[Address.FIELD_ID];
        return (
            <React.Fragment>
                <td className="text-center actions">
                    <Form.Check
                        id={id}
                        onClick={() => this.handleRowSelect(address)}
                    />
                </td>
                <td className="text-center actions">
                    <Form.Check
                        id={id}
                        type="radio"
                        checked={this.state.mergeRow?.id === id}
                        onClick={() => this.setState({ mergeRow: address })}
                    />
                </td>
            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps)(Addresses);
