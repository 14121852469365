import React from "react";
import { connect } from 'react-redux';
import DataTable from '../../table/DataTable';

import forwarderService from "../../../services/ForwarderService";

class Forwarders extends React.PureComponent {
	constructor(props) {
		super(props);
	}

	fetch(params) {
		return forwarderService.list(params);
	}

	openAddForm() {
		window.open("/control-panel/forwarders/forwarder", "_blank");
	}

	openEditForm(row) {
		window.open(`/control-panel/forwarders/forwarder?id=${row.id}`, "_blank")
	}
	
	render() {
		return (
			<DataTable {...this.props}
				title = "Экспедиторы"
				sort = {{field: 'name', dir: 'asc'}}
				openAddForm = {this.openAddForm.bind(this)}
				openEditForm = {this.openEditForm.bind(this)}
				fetch = {this.fetch.bind(this)}
				columns = {[
					{ title: "#", field: "id", className: "id", filter: DataTable.STRING_FILTER },
					{ title: "Название", field: "value", filter: DataTable.STRING_FILTER }
				]}
			/>
		);
	}
}
export default connect(DataTable.mapStateToProps)(Forwarders);