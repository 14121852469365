import { connect } from 'react-redux';
import React, {Fragment} from "react";

import {Form, Card, Row, Col, Button, Alert} from "react-bootstrap";

import FormGroup from '../../form/FormGroup';
import RouteTable from '../RouteTable';
import RouteTableButtons from '../RouteTableButtons';
import ValidationErrors from "../../../validators/ValidationErrors";
import { setValue } from '../../../actions/form';
import quoteService from "../../../services/QuoteService";
import {
	securityService,
	ANGEL,
	ADMIN,
	COMMERCIAL,
	HEAD_OF_DEPARTMENT,
	REGIONAL_DIRECTOR,
	DOCUMENTS, LOGISTIC,
} from "../../../services/SecurityService";
import Enums, { VEHICLE_TYPES } from "../../../Enums";

import driverService from "../../../services/DriverService";
import ReadOnlyContainer from "../../ReadOnlyContainer";
import ContractsForm from '../../control-panel/contractors/ContractsForm';
import clsx from "clsx";
import "../css/FinalQuoteForm.css"
import contractService from "../../../services/ContractService";
import optionsService from "../../../services/OptionsService";
import CollapseBlock from "../../CollapseBlock";
import {RequestStatus, PaymentMode, Option, ConfirmationType} from "../../../Const";
import moment from "moment";
import {NavLink} from "react-router-dom";
import contractorService from "../../../services/ContractorService";
import positionHistoryService from "../../../services/PositionHistoryService";
import Util from "../../../Util";
import departmentService from "../../../services/DepartmentService";
import Select from "react-select";
import ConfirmationAgreementForm from "./ConfirmationAgreementForm";
import QuoteSignatureModal from "./QuoteSignatureModal";
import confirmationCodeService from "../../../services/ConfirmationCodeService";

function mapStateToProps(state) {
	return {
		model: state.model,
		errors: state.errors
	};
}

class FinalQuoteForm extends React.PureComponent {

	static FIELD_CARRIER_GSM = "carrierGsm";
	static FIELD_CARRIER_PAYMENT_DELAY = "carrierPaymentDelay";
	static FIELD_CARRIER_PAYMENT_MODE = "carrierPaymentMode";
	static FIELD_CARRIER_VAT_MODE = "carrierVatMode";
	static FIELD_CONTRACTOR_PAYMENT_DELAY = "contractorPaymentDelay";
	static FIELD_CONTRACTOR_PAYMENT_MODE = "contractorPaymentMode";
	static FIELD_CONTRACTOR_VAT_MODE = "contractorVatMode";
	static FIELD_TRUCK = "truck";
	static FIELD_TRAILER = "trailer";

	static CONTRACT_FIELD_MAP_CONTRACTOR = {
		[FinalQuoteForm.FIELD_CONTRACTOR_PAYMENT_DELAY]: ContractsForm.FIELD_PAYMENT_DELAY,
		[FinalQuoteForm.FIELD_CONTRACTOR_PAYMENT_MODE]: ContractsForm.FIELD_PAYMENT_MODE,
		[FinalQuoteForm.FIELD_CONTRACTOR_VAT_MODE]: ContractsForm.FIELD_VAT_MODE
	}
	static CONTRACT_FIELD_MAP_CARRIER = {
		[FinalQuoteForm.FIELD_CARRIER_PAYMENT_DELAY]: ContractsForm.FIELD_PAYMENT_DELAY,
		[FinalQuoteForm.FIELD_CARRIER_PAYMENT_MODE]: ContractsForm.FIELD_PAYMENT_MODE,
		[FinalQuoteForm.FIELD_CARRIER_VAT_MODE]: ContractsForm.FIELD_VAT_MODE
	}
	static FIELD_MAP_VEHICLES = {
		[FinalQuoteForm.FIELD_TRUCK]: FinalQuoteForm.FIELD_TRUCK,
		[FinalQuoteForm.FIELD_TRAILER]: FinalQuoteForm.FIELD_TRAILER
	}

	static get CONTRACTOR_WITH_VAT_ID() { return 2 };
	static get CONTRACTOR_CLIENT_ID() { return 1 }
	static get CONTRACTOR_CARRIER_ID() { return 2 }

	constructor(props) {
		super(props);

		this.onChange = this.onChange.bind(this);
		this.carrierGsmByGsmPercentage = this.carrierGsmByGsmPercentage.bind(this);
		this.showDriverInfo = this.showDriverInfo.bind(this);
		this.filterDepartments = this.filterDepartments.bind(this);
		this.setShowConfirmation = this.setShowConfirmation.bind(this);
		this.onChangeContractor = this.onChangeContractor.bind(this);
		this.getCarrierPrice = this.getCarrierPrice.bind(this);
		this.onChangeComPerc = this.onChangeComPerc.bind(this);
		this.getCommissionPercentage = this.getCommissionPercentage.bind(this);
		this.updateCarrierPrice = this.updateCarrierPrice.bind(this);
		this.checkContractAgreement = this.checkContractAgreement.bind(this);
		this.setDefaultContracts = this.setDefaultContracts.bind(this);

		this.state = {
			showCarrierExpensesDesc: false,
			showContractorExpensesDesc: false,
			data: this.props.model.marginProfitabilityResponse,
			clientContractTitle: "Договор",
			carrierContractTitle: "Договор",
			isDriverFree: true,
			logisticDepartments: [],
			commissionPercentage: 0,
			showConfirmation: false,
			quoteAgreement: "",
			showSignatureModal: false
		};
	}

	async showDriverInfo(e, forceDisplay) {
		const display = !this.state.driverInfo;
		this.setState({driverInfo: null});
		if (display || forceDisplay) {
			const driverId = this.props.model.driver?.id,
				driverInfo = driverId ? await driverService.info(driverId) : null;
			driverInfo && this.setState({driverInfo});
		}
	}
	
	onChange(name, value) {
		this.props.store.dispatch(setValue(name, value));	
	}
	
	carrierGsmByGsmPercentage(gsmPercentage) {
		if (gsmPercentage < 0) {
			gsmPercentage = 0;
		}
		if (gsmPercentage > 100) {
			gsmPercentage = 100;
		}
		const carrierPrice = this.props.model.carrierPrice;
		this.onChange(FinalQuoteForm.FIELD_CARRIER_GSM, carrierPrice > 0 ? (carrierPrice * gsmPercentage * 0.01).toFixed(2) : '');
	}

	setDefaultContract(contractorId, fieldMap, contractorTypeId, organizationId) {
		if (contractorId && organizationId) {
			const contractorType = contractorTypeId === FinalQuoteForm.CONTRACTOR_CLIENT_ID ? "client" : "carrier";
			const contractType = contractorType + "Contract";
			contractService.getDefaultContract(contractorId, contractorTypeId, organizationId)
				.then(contract => {
					this.onChange(contractType, contract ? contract : optionsService.createEmptyOption());
					this.updateFieldsFromContract(contract, fieldMap, contractorTypeId);
				});
		}
	}

	setCommercialDepManager(contractorId) {
		if (contractorId) {
			contractorService.read(contractorId)
				.then(contractor => this.onChange("commercialDepManager",
					contractor ? contractor.commercialDepManager : optionsService.createEmptyOption()));
		}
	}

	async updateFieldsFromContract(contract, fieldMap, contractorTypeId) {
		if (contract?.id) {
			const contractDto = await contractService.read(contract.id);
			this.updateContractFields(contractDto, fieldMap);
			this.checkContractAgreement(contractDto);
			this.setContractTitle(contract, contractorTypeId);
		}
	}

	updateContractFields(contract, fieldMap) {
		Object.entries(fieldMap).forEach(([key, value]) => {
			this.onChange(key, contract[value]);
		});
	}

	updateFieldsFromFieldMap(response, fieldMap) {
		Object.keys(fieldMap).forEach(key => this.onChange(key, response[key]));
	}

	checkContractAgreement(contract) {
		contractService.read(contract?.id).then(contract => {
			if (contract.agreement
				&& this.htmlToText(contract.agreement) !== ""
				&& contract.agreement !== this.props.model.agreement
			) {
				this.setState({contractAgreement: contract.agreement})
				this.setState({quoteAgreement:  "add"});
				this.setShowConfirmation(true);
			}
		});
	}

	clearContractAgreement(value) {
		this.setState({activeContractor: value}, () => {
			if(this.props.model.agreement !== "") {
				this.setShowConfirmation(true)
				this.setState({quoteAgreement: "clear"});
			} else {
				this.onChangeContractor()
			}
		})
	}

	async setContractTitle(contract, contractorTypeId) {
		let contractTitle = 'Договор';
		if (contract?.id) {
			const counter = ContractsForm.DEFAULT_DRAFT_COUNTER - await contractService.getCounter(contract?.id);
			const ending = ContractsForm.getCorrectEnding(counter);
			contractTitle = contract.value.indexOf("драфт") >= 0
				? `Договор, доступно ${counter > 0 ? counter : 0} ${ending}`
				: contractTitle;
		}
		contractorTypeId === FinalQuoteForm.CONTRACTOR_CLIENT_ID
			? this.setState({clientContractTitle: contractTitle})
			: this.setState({carrierContractTitle: contractTitle});
	}

	async updatePaymentDateWithDelay(delayField) {
		const dtField = delayField.replace('Delay', 'Dt'),
			delay = this.props.model[delayField] || 0;
		this.onChange(dtField, await quoteService.calculatePaymentDateWithDelay(this.props.model.id, delayField, delay));
	}

	componentDidMount() {
		this.getCommissionPercentage()

		this.setContractTitle(this.props.model.clientContract, FinalQuoteForm.CONTRACTOR_CLIENT_ID);
		this.setContractTitle(this.props.model.carrierContract, FinalQuoteForm.CONTRACTOR_CARRIER_ID);

		const crmUser = securityService.getUser();
		if (securityService.isLogistic(crmUser) && this.props.model.logisticDepManager) {
			departmentService.listByCrmUserId(crmUser.id)
				.then(departments => this.setState({ logisticDepartments: departments }));
		}
		this.setState({preStatus: this.props.model.status});
	}

	setDefaultContracts() {
		if (this.props.model.contractor && this.props.model.organization && !this.props.model.clientContract) {
			this.setDefaultContract(this.props.model.contractor?.id, FinalQuoteForm.CONTRACT_FIELD_MAP_CONTRACTOR,
				FinalQuoteForm.CONTRACTOR_CLIENT_ID, this.props.model.organization?.id);
		}
		if (this.props.model.carrier && this.props.model.carrierOrganization && !this.props.model.carrierContract?.id) {
			this.setDefaultContract(this.props.model.carrier?.id, FinalQuoteForm.CONTRACT_FIELD_MAP_CARRIER,
				FinalQuoteForm.CONTRACTOR_CARRIER_ID, this.props.model.carrierOrganization?.id);
		}
	}

	componentDidUpdate(prevProps) {
		if(this.props.wasActual) {
			this.setDefaultContracts()
		}

		if (prevProps.model !== this.props.model) {
			this.driverDidUpdate(prevProps.model);
			quoteService.calculateMarginProfitability(this.props.model).then(res => this.setState({ data: res }));
		}
	}

	driverDidUpdate(prevModel) {
		if (this.state.driverInfo && prevModel.driver !== this.props.model.driver) {
			this.showDriverInfo(null, true);
		}	
	}

	canViewLink() {
		return !securityService.isDriver(this.props.user) && !this.props.user.isClient;
	}

    isFree() {
        return this.props.model?.status?.id === RequestStatus.FREE.id;
    }

    isRequiresSignature() {
        return this.props.model?.status?.id === RequestStatus.ON_SIGNING.id;
    }

	isQuoteCanceled() {
		if (RequestStatus.getCancelStatuses().includes(this.props.model.status?.id)) {
			return securityService.isLogistic(this.props.user)
				|| securityService.isCommercial(this.props.user)
				|| securityService.hasRoleButAngel(this.props.user, DOCUMENTS)
				|| securityService.isHeadOfDepartment(this.props.user)
				|| securityService.isRegionalDirector(this.props.user);
		}
		return false;
	}

	isAvailableForUpdate() {
		const isCarrier = securityService.isCarrier(this.props.user);
		const isDriver = securityService.isDriver(this.props.user);

		const quoteForwarder = this.props.model.forwarder;
		const userForwarder = securityService.getUser().forwarder;

		const forwarderIsDifferent = quoteForwarder && quoteForwarder.id !== userForwarder?.id;
		const readOnlyForDifferentForwarder = !isCarrier && forwarderIsDifferent;

		if (isCarrier || isDriver || readOnlyForDifferentForwarder || this.isQuoteCanceled()) {
			return false;
		}
		const isSuperUser = securityService.anyRoles(this.props.user, ANGEL, ADMIN, HEAD_OF_DEPARTMENT, REGIONAL_DIRECTOR, DOCUMENTS);
		const isLogisticDepManager = securityService.isLogistic(this.props.user);
		const isCommercial = securityService.isCommercial(this.props.user);
		const hasAnyPreStatus = RequestStatus.getPreRequestStatuses().includes(this.props.model.status?.value);
		const hasAnyPostStatus = RequestStatus.getPostRequestStatuses().includes(this.props.model.status?.value);
		if (isSuperUser) {
			return hasAnyPostStatus || hasAnyPreStatus;
		} else if (isLogisticDepManager || isCommercial) {
			return !hasAnyPostStatus || hasAnyPreStatus;			
		}
		return false;
	}

	canEditExpenses() {
		return securityService.hasRole(this.props.user, COMMERCIAL) &&
			[RequestStatus.APPROVED, RequestStatus.ON_LOADING,
				RequestStatus.ON_WAY_TO_LOADING, RequestStatus.ON_WAY_TO_UNLOADING,
				RequestStatus.ON_UNLOADING, RequestStatus.UNLOADED
			].map(s => s.value).indexOf(this.props.model.status?.value) >= 0;
	}

	fetchContractorOptionsByOrg(optionsType, org) {
		if (org?.id) {
			return optionsService.loadContractorOptionsByOrganizationId(optionsType, org.id);
		}
		return Promise.resolve();
	}

	copyClientPrice() {
		this.onChange("clientPrepayment", this.props.model.contractorPrice)
	}

	filterDepartments(option) {
		const departments = this.state.logisticDepartments;
		return departments.length > 0 ? departments.some(d => d.id === option.id) : true;
	}

	getCarrierPrice(price) {
		return parseFloat((price / 100 * (100 - this.state?.commissionPercentage)).toFixed(2));
	}



	onChangeContPrice(value) {
		this.state?.commissionPercentage
			&& this.onChange("carrierPrice", this.getCarrierPrice(value))
	}

	htmlToText(value) {
		const tempDiv = document.createElement('div');
		tempDiv.innerHTML = value;
		return tempDiv.textContent || tempDiv.innerText || "";
	}

	customStyles = {
		control: (provided, state) => ({
			...provided,
			minHeight: '28px',
			height: '28px'
		}),

		valueContainer: (provided, state) => ({
			...provided,
			height: '28px',
			padding: '0 6px'
		}),

		input: (provided, state) => ({
			...provided,
			margin: '0px',
		}),
		indicatorsContainer: (provided, state) => ({
			...provided,
			height: '28px',
		}),
	};

	async onChangeComPerc(event) {
		const value = event.target.value;
		const regex = /^\d*\.?\d?$/;
		if(regex.test(value)) {
			await this.setState({"commissionPercentage": value})
			this.onChangeContPrice(this.props.model.contractorPrice)
		}
	}

	setShowConfirmation(isShow) {
		this.setState( {showConfirmation: isShow});
	}

	onChangeContractor() {
		this.setDefaultContract(
			this.state?.activeContractor?.id, FinalQuoteForm.CONTRACT_FIELD_MAP_CONTRACTOR,
			FinalQuoteForm.CONTRACTOR_CLIENT_ID, this.props.model.organization?.id);
		this.setCommercialDepManager(this.state?.activeContractor?.id);
	}

	renderClientCell() {
		const readOnlyForLogistic = securityService.isLogistic(this.props.user) && this.isRequiresSignature();
		const isNotContractorOrganizationSelected = this.props.model.organization === null || this.props.model.organization === undefined;

		return(
			<Card>
				<Card.Body>
					<Row>
						<Col>
							<FormGroup title="Организация заказчика"
									   name="organization"
									   store={this.props.store}
									   type="dictionary"
									   optionsType="FORWARDER_ORGANIZATION"
									   onChangeHandler={(value) => {this.setDefaultContract(
										   this.props.model.contractor?.id, FinalQuoteForm.CONTRACT_FIELD_MAP_CONTRACTOR,
										   FinalQuoteForm.CONTRACTOR_CLIENT_ID, value.id);
									   }}
							/>
						</Col>
					</Row>
					<Row>
						<Col>
							<FormGroup title="Заказчик"
								name="contractor"
								store={this.props.store}
								type="dictionary"
								optionsType="CONTRACTOR_CONTRACT"
							    fetchOptions={() => this.fetchContractorOptionsByOrg("CONTRACTOR_CONTRACT", this.props.model.organization)}
							 	showViewIcon={this.canViewLink()}
								onChangeHandler={value => this.clearContractAgreement(value)}
							    readOnly={readOnlyForLogistic || isNotContractorOrganizationSelected}
							/>
						</Col>
						<Col>
							<FormGroup title={this.state.clientContractTitle}
								name="clientContract"
								store={this.props.store}
								contractor={this.props.model.contractor}
								contractorTypeId={FinalQuoteForm.CONTRACTOR_CLIENT_ID}
								organization={this.props.model.organization}
								type="contract"
								readOnly={!this.props.model.contractor || !this.props.model.organization}
								onChangeHandler={(value) => {this.updateFieldsFromContract(
									value, FinalQuoteForm.CONTRACT_FIELD_MAP_CONTRACTOR, FinalQuoteForm.CONTRACTOR_CLIENT_ID);
								}}
							/>
						</Col>
						<Col>
							<FormGroup title="Контактное лицо"
								name="contractorContact"
								store={this.props.store}
								contractor={this.props.model.contractor}
								type="person"
								readOnly={readOnlyForLogistic}
							/>
						</Col>
					</Row>
					<Row>
						<Col>
							<Col> {this.state?.showConfirmation
								&& <ConfirmationAgreementForm onChange={this.onChange.bind(this)}
										contract={this.props.model.clientContract}
										contractAgreement={this.state?.contractAgreement}
										onChangeContractor={this.onChangeContractor}
										setShowConfirmation={this.setShowConfirmation}
										quoteAgreement={this.state.quoteAgreement}/>
							}
							</Col>
						</Col>
					</Row>
					<Row>
						<Col>
							<Row>
								<Col className="ml-1 d-flex">
									<FormGroup title="Ставка заказчика"
									    name="contractorPrice"
									    onChangeHandler={value => this.onChangeContPrice(value)}
									    store={this.props.store}
									    type="money"
										readOnly={readOnlyForLogistic || !!this.props.model.mainQuote}
									/>
									<div style={{width: "50%", marginBottom: "1rem", display: "flex", flexDirection: "column", justifyContent: "end"}}>
										<div style={{marginBottom: ".5rem"}}>Проц. комиссии</div>
										<Form.Control size="sm" type="decimal" name={this.props.name} onChange={e => this.onChangeComPerc(e)}
													  min={0}
													  value={(this.state?.commissionPercentage)}
													  maxLength={100}/>
									</div>
								</Col>
								{this.props.model.contractorPaymentMode?.id === PaymentMode.PREPAYMENT().id &&
									<Col className="mr-1">
										<FormGroup title="Предоплата"
										    name="clientPrepayment"
										    store={this.props.store}
										    type="number"
										    showCopyIcon={true}
											copyProcess={() => this.copyClientPrice()}
										/>
									</Col>
								}
							</Row>
						</Col>
						<Col>
							<FormGroup title="Режим НДС"
								name="contractorVatMode"
								store={this.props.store}
								type="dictionary"
								optionsType="VAT_MODE"
								readOnly={readOnlyForLogistic}
							/>
						</Col>
						<Col>
							<FormGroup title="Условия оплаты"
								name="contractorPaymentMode"
								store={this.props.store}
								type="dictionary"
								optionsType="PAYMENT_MODE"
								readOnly={readOnlyForLogistic}
							/>
						</Col>
					</Row>
					<Row>
						<Col>
							<FormGroup title="Количество"
								name="quantity"
								store={this.props.store}
								type="number"
								readOnly={readOnlyForLogistic}
							/>
						</Col>
						<Col>
							<FormGroup title="Доп. расходы"
								name="contractorExpenses"
								store={this.props.store}
								type="money"
							    forceEditable={this.canEditExpenses()}
								readOnly={readOnlyForLogistic}
							/>
							<div className="form-group-ext">
								<Button variant="outline-primary" size="sm" className="text-right" 
									onClick={() => this.setState({showContractorExpensesDesc: !this.state.showContractorExpensesDesc})}>
									текст
								</Button>
							</div>
						</Col>
						<Col>
							<FormGroup title="Отсрочка платежа"
								name={FinalQuoteForm.FIELD_CONTRACTOR_PAYMENT_DELAY}
								store={this.props.store}
								type="number"
								readOnly={readOnlyForLogistic}
							/>
						</Col>
					</Row>
					<Row>
						<Col>
							<FormGroup title="Итоговая ставка"
								value={this.getFinalBet()}
								type="text"
								readOnly={true}
							/>						
						</Col>
						{this.state.showContractorExpensesDesc &&
							<Col>
								<FormGroup title="Доп. расходы (расшифровка)"
									name="contractorExpensesDesc"
									store={this.props.store}
									type="text"
								    forceEditable={this.canEditExpenses()}
									readOnly={readOnlyForLogistic}
								/>
							</Col>
						}
						{!this.state.showContractorExpensesDesc &&
							<Col>
								<FormGroup title="Фактическая оплата"
									name="paymentDt"
									store={this.props.store}
									type="date"
									 readOnly={true}
								/>
							</Col>
						}
						<Col>
							<FormGroup title="Плановая оплата"
								name="contractorPaymentDt"
								store={this.props.store}
								type="date"
								readOnly={!securityService.isAdmin(this.props.user)}
							/>
						</Col>
					</Row>
				</Card.Body>
			</Card>
		);
	}

	renderClientQuoteNumber() {
		const readOnlyForLogistic = securityService.isLogistic(this.props.user) && this.isRequiresSignature();

		return(
			<Card>
				<Card.Body>
					<Row>
						<Col>
							<CollapseBlock title="Номер заявки заказчика">
								<Row>
									<Col>
										<FormGroup
											title="Номер заявки заказчика"
											name="clientQuoteNumber"
											store={this.props.store}
											readOnly={readOnlyForLogistic}
										/>
									</Col>
									<Col>
										<FormGroup
											title="Дата заявки заказчика"
											name="clientQuoteDt"
											type="date"
											store={this.props.store}
											readOnly={readOnlyForLogistic}
										/>
									</Col>
								</Row>
							</CollapseBlock>
						</Col>
					</Row>
					<Row>
						<Col>
							<FormGroup
								type="plainText"
								value={`
									Номер: ${this.props.model.clientQuoteNumber || "отсутствует"},
									дата: ${(this.props.model.clientQuoteDt && moment(this.props.model.clientQuoteDt).format("DD.MM.yyyy")) || "отсутствует"}
								`}
							/>
						</Col>
					</Row>
				</Card.Body>
			</Card>);
	}

	renderCarrierQuoteNumber() {
		const readOnlyForLogistic = securityService.isLogistic(this.props.user) && this.isRequiresSignature();

		return (
			<Card>
				<Card.Body>
					<Row>
						<Col>
							<CollapseBlock title="Номер заявки перевозчика">
								<Row>
									<Col>
										<FormGroup
											title="Номер заявки перевозчика"
											name="carrierQuoteNumber"
											store={this.props.store}
											readOnly={readOnlyForLogistic}
										/>
									</Col>
									<Col>
										<FormGroup
											title="Дата заявки перевозчика"
											name="carrierQuoteDt"
											type="date"
											store={this.props.store}
											readOnly={readOnlyForLogistic}
										/>
									</Col>
								</Row>
							</CollapseBlock>
						</Col>
					</Row>
				</Card.Body>
			</Card>);
	}

	getFinalBet() {
		return this.props.model.contractorPrice && this.props.model.quantity ?
			this.props.model.contractorPrice * this.props.model.quantity : "";
	}

	getProfitStyle(profit) {
		return profit < 0 ? "red" : "green";
	}

	getCommissionPercentage(carrierPrice) {
		const contractorPrice = this.props.model.contractorPrice;
		const carrierPriceVal = carrierPrice || this.props.model.carrierPrice
		if(contractorPrice && carrierPriceVal) {
			const pers = 100 - (100 / (contractorPrice / carrierPriceVal))
			this.setState({commissionPercentage: pers.toFixed(1)})
		} else {
			this.setState({commissionPercentage: 0})
		}
	}

	getProfitClassName(profit) {
		return clsx("margin-profit", this.getProfitStyle(profit));
	}

	updateCarrierPrice(value) {
		this.getCommissionPercentage(value)
	}

	renderCarrierCell() {
		const carrierPrice = this.props.model.carrierPrice,
			carrierGsm = this.props.model.carrierGsm,
			gsmPercentage = carrierPrice > 0 ? (carrierGsm * 100 / carrierPrice).toFixed(2) : '';
		const isDriver = securityService.isDriver();
		const isCarrier = securityService.isCarrier();
		const isNotCarrierOrganizationSelected = this.props.model.carrierOrganization === null || this.props.model.carrierOrganization === undefined;
		return(
			<Card>
				<Card.Body>
					<Row>
						<Col lg={8}>
							<FormGroup title="Организация перевозчика"
								name="carrierOrganization"
								store={this.props.store}
								type="dictionary"
								optionsType="FORWARDER_ORGANIZATION"
								onChangeHandler={(value) => {this.setDefaultContract(
									this.props.model.carrier?.id, FinalQuoteForm.CONTRACT_FIELD_MAP_CARRIER,
									FinalQuoteForm.CONTRACTOR_CARRIER_ID, value.id);
								}}
							/>
						</Col>
						{!isDriver && !isCarrier &&
							<React.Fragment>
									<div style={{display: "flex"}}>
										<div>
											<div style={{whiteSpace: "nowrap"}}>Маржа (чистая)</div>
											<br />
											<div style={{whiteSpace: "nowrap"}}>Рентабельность (чистая)</div>
										</div>
										<div style={{marginTop: "-12px", textAlign: "right"}}>
											<div className={`form-control-plaintext ${this.getProfitClassName(this.state.data?.margin)}`}>
												{this.state.data?.margin.toLocaleString()}
											</div>
											<div className={`form-control-plaintext ${this.getProfitClassName(this.state.data?.profitability)}`}>
												{this.state.data?.profitability + "%"}
											</div>
										</div>
									</div>
							</React.Fragment>
						}
					</Row>
					<Row>
						<Col>
							<FormGroup title="Перевозчик"
								name="carrier"
								store={this.props.store}
								type="dictionary"
								optionsType="CARRIER_CONTRACT"
								fetchOptions={() => this.fetchContractorOptionsByOrg("CARRIER_CONTRACT", this.props.model.carrierOrganization)}
							 	showViewIcon={this.canViewLink()}
							    showClearIcon={RequestStatus.getPreRequestStatusIds().includes(this.props.model.status?.id)}
								readOnly={isNotCarrierOrganizationSelected}
							    onChangeHandler={value => {
									this.setDefaultContract(
										value?.id,
										FinalQuoteForm.CONTRACT_FIELD_MAP_CARRIER,
										FinalQuoteForm.CONTRACTOR_CARRIER_ID,
										this.props.model.carrierOrganization?.id);
								}
							}
							/>
						</Col>
						<Col>
							<FormGroup title={this.state.carrierContractTitle}
								name="carrierContract"
								store={this.props.store}
								contractor={this.props.model.carrier}
								contractorTypeId={FinalQuoteForm.CONTRACTOR_CARRIER_ID}
								organization={this.props.model.carrierOrganization}
								type="contract"
								readOnly={!this.props.model.carrier || !this.props.model.carrierOrganization}
								onChangeHandler={(value) => {this.updateFieldsFromContract(
									value, FinalQuoteForm.CONTRACT_FIELD_MAP_CARRIER, FinalQuoteForm.CONTRACTOR_CARRIER_ID);
								}}
							/>
						</Col>
						<Col>
							<FormGroup title="Контактное лицо"
								name="carrierContact"
								store={this.props.store}
								contractor={this.props.model.carrier}
								type="person"
							/>
						</Col>
					</Row>
					<Row>
						{!isDriver &&
							<Col>
								<FormGroup title="Ставка перевозчика"
								   name="carrierPrice"
								   store={this.props.store}
								   onChangeHandler={this.updateCarrierPrice}
								   type="money"
								/>
							</Col>
						}
						<Col>
							<FormGroup title="Режим НДС"
								name="carrierVatMode"
								store={this.props.store}
								type="dictionary"
								optionsType="VAT_MODE"
							/>
						</Col>
						<Col>
							<FormGroup title="Условия оплаты"
								name="carrierPaymentMode"
								store={this.props.store}
								type="dictionary"
								optionsType="PAYMENT_MODE"
							/>
						</Col>
					</Row>
					<Row>
						<Col>
							<FormGroup title="Доп. расходы"
								name="carrierExpenses"
								store={this.props.store}
								type="money"
							    forceEditable={this.canEditExpenses()}
							/>
							<div className="form-group-ext">
								<Button variant="outline-primary" size="sm" className="text-right" 
									onClick={() => this.setState({showCarrierExpensesDesc: !this.state.showCarrierExpensesDesc})}>
									текст
								</Button>
							</div>
						</Col>
						<Col>
							<FormGroup title="Доля ГСМ от ставки (%)"
								value={gsmPercentage}
								onChangeHandler={this.carrierGsmByGsmPercentage}
								type="decimal"
							/>
						</Col>
						<Col>
							<FormGroup title="Отсрочка платежа"
								name={FinalQuoteForm.FIELD_CARRIER_PAYMENT_DELAY}
								store={this.props.store}
								type="number"
							/>
						</Col>
					</Row>
					<Row>
						{this.state.showCarrierExpensesDesc &&
						<Col>
							<FormGroup title="Доп. расходы (расшифровка)"
								name="carrierExpensesDesc"
								store={this.props.store}
								type="text"
							    forceEditable={this.canEditExpenses()}
							/>
						</Col>}
						{!this.state.showCarrierExpensesDesc &&
						<Col>
							<FormGroup title="Долг"
								name="debtCarrier"
								store={this.props.store}
								type="money"
								readOnly={true}
							/>
						</Col>}
						<Col>
							<FormGroup title="Скидка на ГСМ"
								name="gsmDiscount"
								store={this.props.store}
								type="money"
								readOnly={true}
							/>
						</Col>
						<Col>
							<FormGroup title="Пролив ГСМ"
								name="gsmStrait"
								store={this.props.store}
								type="money"
								readOnly={true}
							/>
						</Col>
						<Col>
							<FormGroup title="Лимит ГСМ"
								name={FinalQuoteForm.FIELD_CARRIER_GSM}
								store={this.props.store}
								type="money"
							/>
						</Col>
						<Col>
							<FormGroup title="Дата оплаты"
								name="carrierPaymentDt"
								store={this.props.store}
								type="date"
								readOnly={!securityService.isAdmin(this.props.user)}
							/>
						</Col>
					</Row>
				</Card.Body>
			</Card>
		);
	}

	renderCargoPriceCell() {
		const isNotApproved = this.props.model?.status?.id === RequestStatus.NOT_APPROVED.id;
		const readOnlyForLogistic = securityService.isLogistic(this.props.user) && this.isRequiresSignature();

		return(
			<Card>
				<Card.Body>
					<Row>
						<Col>
							<FormGroup title="Подразделение"
									   name="department"
									   type="dictionary"
									   optionsType="DEPARTMENT"
									   optionsFilter={securityService.isLogistic() ? this.filterDepartments : null}
									   store={this.props.store}
									   readOnly={(!securityService.anyRoles(this.props.user, ADMIN, HEAD_OF_DEPARTMENT)
										   && RequestStatus.getPostRequestStatuses().includes(this.props.model.status.id))}
									   required
							/>
						</Col>
						<Col>
							<FormGroup title="Менеджер ОЛ"
									   name="logisticDepManager"
									   type="user"
									   role="LOGISTIC"
									   store={this.props.store}
									   readOnly={!securityService.anyRoles(this.props.user, ADMIN, HEAD_OF_DEPARTMENT, REGIONAL_DIRECTOR, COMMERCIAL)
										   && RequestStatus.getPreRequestStatusIds().includes(this.props.model.status.id)}
									   onChangeHandler={(value) => this.changeLogisticDepManager(value.id)}
							/>
						</Col>
					</Row>
					<Row>
						<Col>
							<FormGroup
							title="Источник"
							placeholder="Источник"
						    name="source"
						    type="dictionary"
							optionsType="REQUEST_SOURCE"
							store={this.props.store}
							readOnly={readOnlyForLogistic}
							/>
						</Col>
						<Col>
							<FormGroup title="Менеджер КО"
									   name="commercialDepManager"
									   type="user"
									   role="COMMERCIAL"
									   store={this.props.store}
									   readOnly={!securityService.anyRoles(this.props.user, ADMIN, HEAD_OF_DEPARTMENT)}
							/>
						</Col>
					</Row>
					<Row>
						<Col>
							<FormGroup title="Номер заявки"
								value={this.props.model?.id + ";" + this.props.model?.number}
							    readOnly={true}
							/>
						</Col>
						<Col>
							<FormGroup title="Стоимость груза"
								name="cargoPrice"
								store={this.props.store}
								type="money"
								readOnly={readOnlyForLogistic}
							/>
						</Col>
						<Col>
							<FormGroup title="Километры"
									   name="kilometre"
									   store={this.props.store}
									   type="decimal"
									   readOnly={readOnlyForLogistic}
							/>
						</Col>
					</Row>
				</Card.Body>
			</Card>
		);
	}

	changeLogisticDepManager(id) {
		positionHistoryService.getCurrentUserDepartment(id)
			.then(department => this.onChange("department", department));
	}

	handleDriverUpdate(driver) {
		if (driver?.id) {
			this.driverValidator(driver)?.then(isFree => this.setState({isDriverFree: isFree}));
			quoteService.findLastQuoteByDriver(driver)
				.then(response => this.updateFieldsFromFieldMap(response, FinalQuoteForm.FIELD_MAP_VEHICLES));
		}
	}
	warningAlertDriver(){
		return (<Alert variant={"warning"}>{"Водитель на выбранное время находится на другой заявке"}</Alert>);
	}

	warningAlertTruck(){
		return (<Alert variant={"danger"}>{"Тягач или Полуприцеп не согласован - печатные формы не доступны"}</Alert>);
	}

	driverValidator(driver){
		const requestStatuses = RequestStatus.getDriverValidatorQuoteStatuses().map(s => s.id);

		const model = this.props.model;

		if (driver && model.routePoints && requestStatuses.includes(model.status.id)) {

			const loading = model.routePoints[0];
			const unloading = model.routePoints[model.routePoints.length - 1];

			const start = loading?.timeFrom
				? loading?.dt.substring(0, 11) + loading?.timeFrom
				: loading?.dt;
			const end = unloading?.timeTo
				? unloading?.dt.substring(0, 11) + unloading?.timeTo
				: unloading?.timeFrom
					? unloading?.dt.substring(0, 11) + unloading?.timeFrom
					: unloading?.dt;

			return driverService.checkDriverIsFree(model?.id, driver?.id, start, end);
		}
	}


	renderTransportCell() {
		const showClearIcon = RequestStatus.getPreRequestStatusIds().includes(this.props.model.status?.id);
		return(
			<Card>
				<Card.Body>
					<Row>
						<Col>
							<FormGroup title="Водитель"
								name="driver"
								store={this.props.store}
								type="driver"
								optionsType="DRIVER"
								showViewIcon={this.canViewLink()}
							    showClearIcon={showClearIcon}
							   	onChangeHandler={(value) => this.handleDriverUpdate(value)}
							/>
							{this.props.model.driver?.id && <div style={{width: 50, position: 'absolute', right: 13, zIndex: 10, pointerEvents: "all"}} className="form-group-ext">
									<Button variant="outline-primary" size="sm" className="text-right" 
										onClick={this.showDriverInfo}>
										инфо
									</Button>
								</div>}
							<Col>
							{this.state.driverInfo && <Form.Group className="text-center">
								ИНН: {this.state.driverInfo.inn || "отсутствует"}, 
								Тел.: {this.state.driverInfo.phone || "отсутствует"}
							</Form.Group>}
							</Col>
						</Col>
						<Col>
							<FormGroup title="Тягач"
								name="truck"
								vtype={[VEHICLE_TYPES.TRUCK, VEHICLE_TYPES.VAN, VEHICLE_TYPES.BUS]}
								store={this.props.store}
								type="vehicle"
							    showClearIcon={showClearIcon}
								showViewIcon={this.canViewLink()}
							/>
						</Col>
						<Col>
							<FormGroup title="Полуприцеп"
								name="trailer"
								vtype={VEHICLE_TYPES.TRAILER}
								store={this.props.store}
								type="vehicle"
								showClearIcon={showClearIcon}
								showViewIcon={this.canViewLink()}
							/>
						</Col>
					</Row>
				</Card.Body>
			</Card>
		);
	}

	renderStatus() {
		const isDriver = securityService.isDriver(),
			isCarrier = securityService.isCarrier();
		return this.props.model.status &&
			<FormGroup title="Статус"
				name="status"
				type="status"
				store={this.props.store}
				forceEditable={isCarrier || isDriver}
				readOnly={this.checkVerificationRightsToEditStatus()}
			/>
	}

	checkVerificationRightsToEditStatus() {
		const {preStatus} = this.props;
		const crmUser = securityService.getUser();
		let result = true;

		const test = (status) => preStatus.value === status.value;
		if (securityService.isAdmin(crmUser)) {
			return false;
		} else if (test(RequestStatus.ACTUAL)) {
			result = false;
		} else if (test(RequestStatus.APPROVED)) {
			result = !securityService.hasRole(crmUser, HEAD_OF_DEPARTMENT, LOGISTIC);
		} else if (test(RequestStatus.NOT_APPROVED)) {
			result = !securityService.anyRoles(crmUser, HEAD_OF_DEPARTMENT, LOGISTIC);
		} else if (test(RequestStatus.CANCELED)) {
			result = !securityService.hasRole(crmUser, HEAD_OF_DEPARTMENT);
		} else if (test(RequestStatus.REJECTED)) {
			result = !securityService.anyRoles(crmUser, HEAD_OF_DEPARTMENT, COMMERCIAL);
		} else if (test(RequestStatus.BROKEN)) {
			result = !securityService.hasRole(crmUser, HEAD_OF_DEPARTMENT);
		} else if (test(RequestStatus.UNLOADED)) {
			result = !securityService.hasRole(crmUser, HEAD_OF_DEPARTMENT);
		} else if (test(RequestStatus.ON_LOADING)) {
			result = !securityService.anyRoles(crmUser, HEAD_OF_DEPARTMENT, LOGISTIC);
		} else if (test(RequestStatus.ON_WAY)) {
			result = !securityService.anyRoles(crmUser, HEAD_OF_DEPARTMENT, LOGISTIC);
		} else if (test(RequestStatus.ON_UNLOADING)) {
			result = !securityService.anyRoles(crmUser, HEAD_OF_DEPARTMENT, LOGISTIC);
		} else if (test(RequestStatus.WASTED)) {
			//
		} else if (test(RequestStatus.ON_SIGNING)) {
			result = !securityService.anyRoles(crmUser, HEAD_OF_DEPARTMENT, COMMERCIAL, LOGISTIC);
		} else if (test(RequestStatus.ON_WAY_TO_LOADING)) {
			result = !securityService.anyRoles(crmUser, HEAD_OF_DEPARTMENT, LOGISTIC);
		} else if (test(RequestStatus.ON_WAY_TO_UNLOADING)) {
			result = !securityService.anyRoles(crmUser, HEAD_OF_DEPARTMENT, LOGISTIC);
		} else if (test(RequestStatus.FREE)) {
			result = !securityService.anyRoles(crmUser, HEAD_OF_DEPARTMENT, COMMERCIAL);
		} else if (test(RequestStatus.REMOVED_FROM_PUBLICATION)) {
			result = !securityService.anyRoles(crmUser, HEAD_OF_DEPARTMENT, COMMERCIAL);
		} else if (test(RequestStatus.AT_WORK)) {
			result = !securityService.anyRoles(crmUser, HEAD_OF_DEPARTMENT, LOGISTIC);
		} else if (test(RequestStatus.BOOKED_BY_CARRIER)) {
			result = !securityService.anyRoles(crmUser, HEAD_OF_DEPARTMENT, COMMERCIAL);
		} else if (test(RequestStatus.DECLINED_BY_CARRIER)) {
			result = !securityService.anyRoles(crmUser, HEAD_OF_DEPARTMENT, COMMERCIAL);
		}

		return result;
	}

	renderTTNCell() {
		return (
			<Card>
				<Card.Body>
					<Row>
						<Col>
							<FormGroup title="Статус ТТН"
									   name="ttnStatus"
									   type="dictionary"
									   optionsType="TTN_STATUS"
									   store={this.props.store}
									   readOnly={!securityService.anyRoles(this.props.user, ADMIN, DOCUMENTS)}
							/>
						</Col>
						<Col>
							<FormGroup title="Номер ТТН"
									   name="ttnNumber"
									   store={this.props.store}
									   readOnly={this.props.user.isClient}
							/>
						</Col>
						<Col>
							<FormGroup title="Дата ТТН"
									   name="ttnDt"
									   type="date"
									   store={this.props.store}
									   readOnly={!securityService.anyRoles(this.props.user, ADMIN, DOCUMENTS)}
							/>
						</Col>
					</Row>
				</Card.Body>
			</Card>
		)
	}


	renderBottomCell() {
		return(
			<Card>
				<Card.Body>
					<Row>
						<Col>
							<FormGroup title="Дата создания заявки"
								name="createdAt"
								type="datetime"
								store={this.props.store}
								readOnly={true}
							/>
						</Col>
						<Col>
							{this.renderStatus()}
						</Col>
						{(this.props.model.status.id === RequestStatus.FREE.id) &&
							<div className="flex-space-between" style={{maxWidth: "200px"}}>
								<FormGroup title="Свободная до"
										   name="freeTo"
										   type="datetime"
										   store={this.props.store}
										   required
								/>
								<div style={{alignSelf: "center"}}>{Util.calculateRemainingTime(this.props.model.freeTo)}</div>
							</div>}
						<Col>
							<FormGroup title="Тип оплаты"
									   name="paymentType"
									   store={this.props.store}
									   type="enum"
									   optionsType="PAYMENT_TYPE"
							/>
							{(this.props.model.status.id === RequestStatus.FREE.id) &&
								<div
									style={{alignSelf: "center", paddingLeft: "10px", paddingTop: "30px"}}>
									{Util.calculateRemainingTime(this.props.model.freeTo)}
								</div>}
						</Col>
						<Col>
							<FormGroup title="Статус подписания"
									   name="signingStatus"
									   store={this.props.store}
									   type="enum"
									   optionsType="SIGNING_STATUS"
							/>
						</Col>
					</Row>
					<Row>
						<Col>
							{this.renderMainQuoteInfo()}
						</Col>
					</Row>
				</Card.Body>
			</Card>);

	}

	renderMainQuoteInfo() {
		const {id, mainQuote, signingStatus} = this.props.model;

		const currentQuoteIsSigning = Enums.SIGNING_STATUS[signingStatus?.value]?.value === Enums.SIGNING_STATUS.SIGNED.value;
		if (!mainQuote || !currentQuoteIsSigning) {
			return <Fragment/>
		}

		const mainQuoteSigningStatus = Enums.SIGNING_STATUS[mainQuote.signingStatus?.value].label;
		const mainQuoteIsSigning = mainQuoteSigningStatus === Enums.SIGNING_STATUS.SIGNED.label;
		const isManager = securityService.hasRole(securityService.getUser(), LOGISTIC, COMMERCIAL);
		const show = !mainQuoteIsSigning && isManager;
		return (
			<span className="h6 ml-2">
				Статус подписания основной заявки: {mainQuoteSigningStatus}
				&nbsp;
				{ id && id > 0 &&
					<Button hidden={!show} size="sm" onClick={() => this.setState({showSignatureModal: true})}>
						Подписать
					</Button>
				}
			</span>
		);
	}

	renderQuoteSignatureModal() {
		const {showSignatureModal} = this.state;
		if (!showSignatureModal) {
			return <Fragment/>
		}

		return (
			<QuoteSignatureModal
				show={showSignatureModal}
				onClose={() => this.setState({showSignatureModal: false})}
				submit={(request) => this.signMainQuote(request)}
				quote={this.props.model}
			/>
		);
	}

	async signMainQuote(request) {
		const {model} = this.props;
		const contract = await contractService.read(request.contract.id);

		let updatedQuote = {};
		Object.assign(updatedQuote, model.mainQuote);

		updatedQuote.signingStatus = Option.of(Enums.SIGNING_STATUS.SIGNED);
		updatedQuote.status = RequestStatus.BOOKED_BY_CARRIER;
		updatedQuote.carrier = request.carrier;
		updatedQuote.carrierContract = request.contract;
		updatedQuote.carrierPaymentDelay = updatedQuote.carrierPaymentDelay ?? contract.paymentDelay;
		updatedQuote.driver = model.driver;
		updatedQuote.truck = model.truck;
		updatedQuote.trailer = model.trailer;

		return confirmationCodeService.submitCodeAndSaveQuote(updatedQuote, request.code, ConfirmationType.BOOKING);
	}

	renderDescription() {
		return (
			<Card>
				<Card.Body>
					<Form.Group>
						<label>Описание заявки</label>
						<Form.Control disabled={this.props.user.isClient}
									  className="form-control form-control-sm"
									  as="textarea" rows="3"
									  name="description"
									  value={this.props.model.description || ''}
									  onChange={e => this.onChange('description', e.target.value)} />
					</Form.Group>
				</Card.Body>
				<Card.Footer>Описание</Card.Footer>
			</Card>
		);
	}

	renderSignInfoCell() {
		return (
			<Card className="sign">
				<Card.Body>
					<Row>
						<Col lg={4} md={6}>
							{this.props.model.signed && <React.Fragment>
								<div style={{color: "green"}}>Заявка подписана <i
									className="link fas fa-fw fa-check"></i></div>
							</React.Fragment>}
						</Col>
						<Col lg={4} md={6}>
							{this.props.model.signedBy && <React.Fragment>
								<div>Подписал:</div>
								<div>{this.props.model.signedBy.value}</div>
							</React.Fragment>}
						</Col>
						<Col lg={4} md={6}>
							{this.props.model.signedAt && <React.Fragment>
								<div>Дата подписания:</div>
								<div>{moment(this.props.model.signedAt).format("DD.MM.yyyy HH:mm")}</div>
							</React.Fragment>}
						</Col>
					</Row>
				</Card.Body>
				<Card.Footer>
					Подписание заявки
				</Card.Footer>
			</Card>
		);
	}

	renderRouteTable() {
		const routeErrors = this.props.errors["route"];
		const readOnly = securityService.isLogistic(this.props.user) && this.isFree() || this.isRequiresSignature();

		return (
			<Card className="route">
				<Card.Header>
					<span className="add-links">
						<NavLink to={"/control-panel/contractors/contractor?id=" + this.props.model.contractor?.id + "&activeTab=addresses"}
								 target={"_blank"}
						>
							<Button variant="outline-primary"
									size="sm"
							>
								<i className="fas fa-plus mr-1"></i>Адрес
							</Button>
						</NavLink>
					</span>

					{!readOnly && <RouteTableButtons
						addLoading={this.props.addLoading.bind(this)}
						addUnloading={this.props.addUnloading.bind(this)}
						addCustoms={this.props.addCustoms.bind(this)}
					/>}
				</Card.Header>
				<Card.Body>
					<RouteTable store={this.props.store}
								readOnly={readOnly}
								contractor={this.props.model.contractor}
								fieldset={RouteTable.FIELD_SET_FULL}/>
					<ValidationErrors errors={routeErrors} />
				</Card.Body>
			</Card>
		);
	}
	
	render() {
		const isClient = securityService.isClient();
		const isCarrier = securityService.isCarrier();
		const isDriver = securityService.isDriver();
		const isExternal = securityService.isExternal();
		const isNotApproved = this.props.model?.status?.id === RequestStatus.NOT_APPROVED.id;
		const isLogistic = securityService.isLogistic();
		return (
			<React.Fragment>
				{this.renderQuoteSignatureModal()}
				<Row>
					{!isCarrier && !isDriver &&
						<Col lg={isClient ? 12 : 6} md={12}>
							<ReadOnlyContainer readOnly={!this.isAvailableForUpdate()}>
								{this.renderClientCell()}
							</ReadOnlyContainer>
						</Col>
					}
					{!isClient &&
						<Col lg={isCarrier || isDriver ? 12 : 6} md={12}>
							<ReadOnlyContainer readOnly={!this.isAvailableForUpdate()}>
								{this.renderCarrierCell()}
							</ReadOnlyContainer>
						</Col>
					}
				</Row>
				<Row>
					{!isExternal &&
						<Col lg={isClient ? 12 : 6} md={12}>
							<ReadOnlyContainer readOnly={!this.isAvailableForUpdate()}>
								{this.renderCargoPriceCell()}
							</ReadOnlyContainer>
						</Col>
					}
					<Col lg={isExternal ? 12 : 6} md={12}>
						<ReadOnlyContainer
							readOnly={isCarrier && isNotApproved ? false : !this.isAvailableForUpdate()}>
							{this.renderTransportCell()}
						</ReadOnlyContainer>
					</Col>
				</Row>

				<Row>
					{(!isExternal || isClient) &&
						<Col lg={6} md={12}>
							<ReadOnlyContainer readOnly={!this.isAvailableForUpdate() && !isLogistic}>
								{this.renderClientQuoteNumber()}
							</ReadOnlyContainer>
						</Col>
					}
					{(!isExternal || isCarrier) &&
						<Col lg={6} md={12}>
							<ReadOnlyContainer readOnly={!this.isAvailableForUpdate()}>
								{this.renderCarrierQuoteNumber()}
								{this.state.isDriverFree === false && this.warningAlertDriver()}
								{this.props.vehiclesIsNotApproved === true && this.warningAlertTruck()}
							</ReadOnlyContainer>
						</Col>
					}
				</Row>
				<Row>
					<Col lg={12}>
						<ReadOnlyContainer readOnly={isCarrier || isDriver}>
							{this.renderTTNCell()}
						</ReadOnlyContainer>
					</Col>
				</Row>
				<Row>
					<Col lg={12}>
						<ReadOnlyContainer readOnly={isCarrier || isDriver} >
							{this.renderBottomCell()}
						</ReadOnlyContainer>
					</Col>
				</Row>
				<Row>
					<Col lg={12}>
						<ReadOnlyContainer readOnly={isCarrier || isDriver} fullyCovered>
							{this.renderRouteTable()}
						</ReadOnlyContainer>
					</Col>
				</Row>
				<Row>
					<Col lg={12}>
						<ReadOnlyContainer readOnly={isCarrier || isDriver} fullyCovered>
							{this.renderDescription()}
						</ReadOnlyContainer>
					</Col>
				</Row>
				{isCarrier && this.props.model?.signed && <Row>
					<Col lg={12} md={12}>
						{this.renderSignInfoCell()}
					</Col>
				</Row>}
			</React.Fragment>
		);
	}
}

export default connect(mapStateToProps)(FinalQuoteForm);
