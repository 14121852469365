class LocalStorageService {

	constructor() {
		const stored = localStorage.getItem("StoredConfigs");
		this.store = stored ? JSON.parse(stored) : {};
	}
	
	getArray(key, field) {
		return this.extract(key, field, []);
	}

	getObject(key, field) {
		return this.extract(key, field, {});
	}

	extract(key, field, defaultValue) {
		const forObject = this.store[key];
		return forObject ? forObject[field] : defaultValue;
	}

	contains(key) {
		const forObject = this.store[key];
		return !!forObject;
	}

	updateObject(key, field, obj, child) {
		if (!this.store[key]) {
			this.store[key] = {};
		}
		this.store[key][child ? `${child}_${field}` : field] = obj
		localStorage.setItem("StoredConfigs", JSON.stringify(this.store));
	}
}

export default new LocalStorageService();