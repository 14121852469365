import { connect } from "react-redux";
import React from "react";
import { Table } from "react-bootstrap";
import reportService from "../../services/ReportService";
import Util from "../../Util";
import Loading from "../Loading";
import motivationService from "../../services/MotivationService";
import {securityService} from "../../services/SecurityService";
import Ssp from "../reports/Ssp";
import {userService} from "../../services/UserService";

class LogisticReportTable extends React.PureComponent {

    constructor(props) {
        super(props);

        const now = new Date(),
            dt = new Date(now.getFullYear(), now.getMonth(), 1),
            offset = dt.getTimezoneOffset();

        dt.setTime(dt.getTime() - offset * 60000);

        this.state = { dt, userId: securityService.getUser().id, isHeadOfDepartment: false }

        userService.read(this.state.userId)
            .then(response => {
                this.setState({isHeadOfDepartment: response.roles.some(role => role === "HEAD_OF_DEPARTMENT" || role === "REGIONAL_DIRECTOR")});
            })

        this.rows = [
            {title: "Выручка", name: "Выручка", value: 0, isAbs: true },
            {title: "Рент.НДС", name: "Рентабельность по НДСным Перевозчикам, %", value: 0 },
            {title: "Рент.БНДС", name: "Рентабельность по без-НДСным Перевозчикам, %", value: 0 },
            {title: "Маржа", name: "Маржа", value: 0, isAbs: true },
            {title: "Доля НДСых", name: "Доля НДСных Перевозчиков, %", value: 0 },
            {title: "Норма ГСМ", name: "Норма зачета ГСМ, %", value: 0 },
            {title: "ГСМ", name: "Сумма зачета ГСМ", value: 0, isAbs: true },
            {title: "Кол-во", name: "Количество заявок", value: 0, isAbs: true }
        ];
    }
    static get REQUEST_STATUS_UNLOADED() { return 8 };

    async fetch(dt) {
        return await reportService.ssp(Util.formatIsoDate(dt || this.state.dt),
            [LogisticReportTable.REQUEST_STATUS_UNLOADED]);
    }

    async componentDidMount() {
        const result = await this.fetch();
        this.setState({ data: result.data });
    }

    getBackgroundColor(plan, value) {
        const classResolver = () => {
            if (value >= plan || 1 * plan === 0) {
                return "darkseagreen";
            } else {
                return "palevioletred";
            }
        }
        return `${classResolver()}`;
    }

    renderBody(row) {
        return (
            <tr key={row.title}>
                {!this.props.isMinimized &&
                    <td style={{color: "white", border: "none"}}>{row.title}</td>
                }
                <td style={{color: "white", border: "none", backgroundColor: row.backColor}}>{row.value?.toLocaleString()}</td>
            </tr>
        )
    }

    getMotivation() {
        const data = { dt: this.state.dt, quoteStatusIdList: [Ssp.REQUEST_STATUS_UNLOADED], userId: this.state.userId };
        motivationService.post("current", data)
            .then((response) => {
                this.setState({motivation: response});
            });
    }

    render() {
        const isReady = this.state.data;
        if (!isReady) {
            return (<Loading/>);
        }

        const filteredData = this.state.data.filter(elem => this.state.isHeadOfDepartment ? elem.userId === 0 : elem.userId > 0);
        for (let i = 0; i < this.rows.length; i++) {
            for (let j = 0; j < filteredData.length; j++) {
                if (this.rows[i].name === filteredData[j].counter) {
                    this.rows[i].value = filteredData[j]?.fact;
                    if (this.rows[i].isAbs) {
                        this.rows[i].backColor = this.getBackgroundColor(filteredData[j]?.plan, filteredData[j]?.trend);
                    } else {
                        this.rows[i].backColor = this.getBackgroundColor(filteredData[j]?.plan, filteredData[j]?.fact);
                    }
                    filteredData.splice(j, 1);
                }
            }
        }

        return (
            <Table striped bordered hover size="sm" style={{border: "none"}}>
                <tbody>{this.rows.filter(row => !!row.backColor).map(row => this.renderBody(row))}</tbody>
                {securityService.isLogistic(this.state.crmUser) &&
                    <tbody>
                        {this.renderMotivationTable({key: "wage", value: "Мотивация"}, Math.round(this.state.motivation?.total).toLocaleString())}
                        {this.renderMotivationTable({key: "paidQuotes", value: "За опл. заявки"}, Math.round(this.state.motivation?.paidQuotesMotivation).toLocaleString())}
                    </tbody>
                }
            </Table>
        )
    }

    renderMotivationTable(name, method) {
        if (this.state.motivation === undefined) {
            this.getMotivation();
        }

        return (
            <tr key={name.key}>
                {!this.props.isMinimized &&
                    <td style={{color: "white", border: "none"}}>
                        {name.value}
                    </td>
                }
                <td className="wage" style={{border: "none", backgroundColor: this.getBackgroundColor(1, 1)}}>
                    {method}
                </td>
            </tr>
        );
    }
}
export default connect()(LogisticReportTable);