import {PureComponent} from "react";
import {ToastContainer} from "react-toastify";

export class ToastWrapper extends PureComponent {
    constructor(props) {
        super(props);

        this.toastSettings = {
            position:"bottom-right",
            autoClose: 5000,
            newestOnTop: false,
            pauseOnHover: true,
            theme: "colored"
        }
    }

    render() {
        return (
            <ToastContainer
                {...this.toastSettings}
            />
        )
    }
}