import React from "react";
import { connect } from 'react-redux';
import { Button, Row, Col, Table, Form, Container, Card } from "react-bootstrap";
import { requiredValidator, bikValidator, bankAccountValidator } from "../../../validators/simpleValidators";
import BaseForm from '../../form/BaseForm';
import { setValue } from '../../../actions/form';
import FormGroup from "../../form/FormGroup";
import { setErrors } from '../../../actions/form';
import dadataService from "../../../services/DadataService";

function mapStateToProps(state) {
	return {
		paymentAccounts: state.model.paymentAccounts,
		errors: state.errors,
		owner: {
			id: state.model.id,
			value: state.model.name,
		},
	};
}

class PaymentAccounts extends BaseForm {

	static get FIELD_ID() { return 'id' };
	static get FIELD_ACCOUNT() { return 'account' };
	static get FIELD_BANK() { return 'bank' };
	static get FIELD_BIK() { return 'bik' };
	static get FIELD_CORRESPONDENT_ACCOUNT() { return 'correspondentAccount' };
	static get FIELD_CONTRACTOR() { return 'owner' };

	constructor(props) {
		super(props);
		this.state = {
			currentIndex: null,
		}
		this.onChange = this.onChange.bind(this);
		this.getFieldValue = this.getFieldValue.bind(this);
		this.handleAdd = this.handleAdd.bind(this);
		this.handleRemove = this.handleRemove.bind(this);
		this.renderPaymentAccountTable = this.renderPaymentAccountTable.bind(this);
		this.renderPaymentAccountTableRow = this.renderPaymentAccountTableRow.bind(this);
	}

	configureValidators() {
		this.useValidatorFor(requiredValidator, this.formatFieldName(PaymentAccounts.FIELD_ACCOUNT));
		this.useValidatorFor(bankAccountValidator, this.formatFieldName(PaymentAccounts.FIELD_CORRESPONDENT_ACCOUNT), this.formatFieldName(PaymentAccounts.FIELD_ACCOUNT));
		if (this.props.applyInternalValidator) {
			this.useValidatorFor(this.props.applyInternalValidator(bikValidator.bind(this)), this.formatFieldName(PaymentAccounts.FIELD_BIK));
		}
	}

	componentDidMount() {
		this.updateCurrentIndex(0);
	}

	componentDidUpdate() {
		const lengthCurr = this.props.paymentAccounts ? this.props.paymentAccounts.length >= 0 ? this.props.paymentAccounts.length : null : null;
		if (this.state.currentIndex === null || lengthCurr - 1 < this.state.currentIndex) {
			this.updateCurrentIndex();
		}
	}

	updateCurrentIndex(index) {
		const paymentAccounts = this.props.paymentAccounts;
		this.setState({
			currentIndex: paymentAccounts && paymentAccounts.length ? (index >= 0 ? index : paymentAccounts.length - 1) : 0
		}, () => this.configureValidators());
	}

	handleAdd() {
		const paymentAccounts = this.props.paymentAccounts;
		paymentAccounts.push({
			[PaymentAccounts.FIELD_DT]: new Date(),
		});
		super.onChange("paymentAccounts", [...paymentAccounts]);
		this.selectPaymentAccountIndex(paymentAccounts.length - 1)
	}

	handleRemove(index) {
		const paymentAccounts = this.props.paymentAccounts;
		if (paymentAccounts[index].id) {
			this.setSending(true);				
			paymentAccounts[index].owner = this.props.owner;
			this.props.delete(paymentAccounts[index])		
				.then(() => this.setSending(false));
		}
		paymentAccounts.removeAt(index);
		super.onChange("paymentAccounts", [...paymentAccounts]);
	}

	async handleSubmit(e) {
		e.preventDefault();
		let paymentAccount = this.getCurrentPaymentAccount();	
		paymentAccount.owner = this.props.owner;
		this.setSending(true);
		const result = await this.props.save(paymentAccount);
		if (!result.success) {
			alert(result.message);
		} else {
			this.props.setToastObjAC({show: true, textHeader: "Р/Счет сохранен!", delay: 3000})
		}
		this.setSending(false);
	}

	handleClear(e) {
		e.preventDefault();
		const currentPaymentAccount = this.getCurrentPaymentAccount();
		Object.keys(currentPaymentAccount).forEach(key => {
			if (key !== PaymentAccounts.FIELD_ID) {
				this.onChange(key, null);
			}
		});
	}

	async validateAutoFillin() {
		const isBik = await this.validator.validateField('bik');
		this.props.store.dispatch(setErrors(isBik ? [] : this.validator.results));
		return isBik;
	}

	async getFillInData() {
		const paymentAccount = this.props.paymentAccounts[this.state.currentIndex];
		return await dadataService.fetchBank({
			bik: paymentAccount.bik,
		});
	}
	
	onChange(field, value) {
		this.props.store.dispatch(setValue(this.formatFieldName(field), value));
	}

	formatFieldName(field) {
		return `${this.getFieldNamePrefix()}.${field}`;
	}

	getFieldNamePrefix() {
		return `paymentAccounts.${this.state.currentIndex}`;
	}

	selectPaymentAccountIndex(index) {
		this.setState({
			paymentAccount: this.props.paymentAccounts[index],
			currentIndex: index,
		});
	}

	getCurrentPaymentAccount() {
		const paymentAccounts = this.props.paymentAccounts;
		let paymentAccount;
		if (paymentAccounts && this.state.currentIndex >= 0) {
			paymentAccount = paymentAccounts[this.state.currentIndex];
		}
		return paymentAccount;
	}

	getFieldValue(field) {
		const paymentAccount = this.getCurrentPaymentAccount();
		let value;
		if (paymentAccount) {
			value = paymentAccount[field];
		}
		return value === null || value === undefined ? "" : value;
	}

	render() {
		return (

			<Form>
				<Container fluid>
					<Row>
						<Col>
							{this.renderPaymentAccountForm()}
						</Col>
					</Row>
					<br />
					<Row className="text-right">
						<Col>
							{this.renderPaymentAccountTable()}
						</Col>
					</Row>
				</Container>
			</Form>
		);
	}

	renderPaymentAccountTable() {
		const paymentAccounts = this.props.paymentAccounts;
		if (!paymentAccounts || !paymentAccounts.length) {
			return (
				<Row className="text-center">
					<Col className="form-group">
						<div className="mb-2">Расчетные счета отсутствуют</div>
						<div>
							<Button onClick={this.handleAdd} size="sm" variant="primary">
								<span>добавить</span>
							</Button>
						</div>
					</Col>
				</Row>
			);
		}
		return (
			<Row>
				<Col>
					<Col>
						<Table bordered hover size="sm" className="selectable">
							<thead>
								<tr>
									<th className="col-3">Р/счет</th>
									<th className="col-3">Банк</th>
									<th className="col-2">Бик</th>
									<th className="col-3">Кор.счет</th>
									<th className="col-1 text-center">
										<Button onClick={this.handleAdd} size="sm" variant="primary" className="btn-xs">
											<span>добавить</span>
										</Button>
									</th>
								</tr>
							</thead>
							<tbody>
								{paymentAccounts.map(this.renderPaymentAccountTableRow)}
							</tbody>
						</Table>
					</Col>
				</Col>
			</Row>
		);
	}

	renderPaymentAccountTableRow(paymentAccount, index) {
		const handler = () => this.selectPaymentAccountIndex(index);
		return (
			<tr key={`paymentAccount-table-row-${index}`} onClick={() => handler()} onFocus={() => handler()} className={index === this.state.currentIndex ? "active" : null}>
				<td>{paymentAccount[PaymentAccounts.FIELD_ACCOUNT]}</td>
				<td>{paymentAccount[PaymentAccounts.FIELD_BANK]}</td>
				<td>{paymentAccount[PaymentAccounts.FIELD_BIK]}</td>
				<td>{paymentAccount[PaymentAccounts.FIELD_CORRESPONDENT_ACCOUNT]}</td>
				<td className="col-1 text-center">
					<Button onClick={() => this.handleRemove(index)} size="sm" variant="danger" className="btn-xs">
						<span>удалить</span>
					</Button>
				</td>
			</tr>
		);
	}

	renderPaymentAccountForm() {
		if (!this.getCurrentPaymentAccount()) {
			return;
		}
		return (
			<Card className="m-1 bg-light">
				<Card.Header>
					<h5 className="m-0">
						{`Расчетный счет № ${this.getFieldValue(PaymentAccounts.FIELD_ACCOUNT)}`}
					</h5>
				</Card.Header>
				<Card.Body>
					<Row>
						<Col lg={3} md={4}>
							<FormGroup
								title="Р/счет"
								name={this.formatFieldName(PaymentAccounts.FIELD_ACCOUNT)}
								store={this.props.store}
								required />
						</Col>
						<Col lg={3} md={4}>
							<FormGroup
								title="Банк"
								name={this.formatFieldName(PaymentAccounts.FIELD_BANK)}
								store={this.props.store} />
						</Col>
						<Col lg={3} md={4}>
							<FormGroup
								title="БИК"
								name={this.formatFieldName(PaymentAccounts.FIELD_BIK)}
								store={this.props.store} />
						</Col>
						<Col lg={3} md={4}>
							<FormGroup
								title="Корр. счет"
								name={this.formatFieldName(PaymentAccounts.FIELD_CORRESPONDENT_ACCOUNT)}
								store={this.props.store} />
						</Col>
					</Row>

					<Row className="justify-content-center">
						<Col className="col-auto">
							{/* {this.renderSaveButton()} */}
							{this.renderAutoFillInSaveCancelButtons()}
						</Col>
						<Col className="col-auto">
							{this.renderClearButton()}
						</Col>
					</Row>
				</Card.Body>
			</Card>
		);
	}

}

export default connect(mapStateToProps)(PaymentAccounts);