import React, {PureComponent} from "react";
import "./OperationalReport.css";
import {Button, Card, Form, Table} from "react-bootstrap";
import DatePicker from "react-datepicker";
import ru from "date-fns/locale/ru";
import dateTimeService from "../../services/DateTimeService";
import motivationService from "../../services/MotivationService";
import operationalReportService from "../../services/OperationalReportService";

class Downtime extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            periodStart: dateTimeService.subtractHours(new Date(), 168),
            periodEnd: new Date(),
            downtimeHours: 12,
            sending: false,
            searchString: ""
        }
    }

    TABLE_FIELDS = [
        { id: "transportNumber", name: "Гос. номер ТС" },
        { id: "address", name: "Адрес" },
        { id: "day", name: "Дата" },
        { id: "periodStart", name: "Время начала стоянки" },
        { id: "periodEnd", name: "Время окончания стоянки" },
        { id: "downtime", name: "Количество часов стоянки" }
    ]

    loadData() {
        const data = {
            period: {
                start: this.state.periodStart,
                end: this.state.periodEnd
            },
            downtimeHours: this.state.downtimeHours
        }

        this.getDowntimeReport(data);
    }

    getDowntimeReport(data) {
        this.setState({ sending: true });

        operationalReportService.getDowntimeReport(data)
            .then(downtimeReport => this.setState({ downtimeReport }))
            .finally(() => this.setState({ sending: false }));
    }

    downloadReport() {
        motivationService.downloadFile(
            "Простои ТС (" +  this.state.periodStart?.toLocaleString() + " - " + this.state.periodEnd?.toLocaleString() + ").xlsx",
            "xlsx",
            {headers: this.TABLE_FIELDS, content: this.state.downtimeReport}
        );
    }

    renderResponseTable() {
        return <Card>
            <Card.Header>
                <div className="flex-space-between">
                    <div style={{ width: "20%" }}>
                        <Form.Control type="string"
                                      size="sm"
                                      placeholder="Фильтр по Гос. номер ТС"
                                      onChange={e => this.setState({ searchString: e.target.value })} />
                    </div>
                    <div>{this.props.title}</div>
                    <Button size="sm"
                            variant="outline-success"
                            disabled={!this.state.downtimeReport || this.state.downtimeReport.length === 0}
                            onClick={() => this.downloadReport()}>
                         <span><i className="fa fa-download"/>&nbsp;
                            Скачать отчёт в формате xlsx ({this.state.downtimeReport ? this.state.downtimeReport.length : 0} записей)
                        </span>
                    </Button>
                </div>
            </Card.Header>
            <Card.Body style={{padding: 0}}>
                <Table striped bordered hover size="sm">
                    <thead style={{fontWeight: "bold"}}>
                        <tr>
                            {this.TABLE_FIELDS.map(field => <td>{field.name}</td>)}
                        </tr>
                    </thead>
                    <tbody>
                    {this.state.downtimeReport?.map(row => this.filterByTransportNumber(row["transportNumber"]) &&
                        <tr>{this.TABLE_FIELDS.map(field =>
                            <td>
                                {this.normalizeValueByField(row[field.id], field.id)}
                            </td>)}
                        </tr>
                    )}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    }

    filterByTransportNumber(transportNumber) {
        return this.state.searchString?.length === 0 || transportNumber.toLowerCase().includes(this.state.searchString?.toLowerCase());
    }

    normalizeValueByField(value, field) {
        if (value && (field === "periodStart" || field === "periodEnd")) {
            return new Date(value).toLocaleString();
        }

        return value;
    }

    renderDatePicker(label, stateKey) {
        return <Form.Group style={{marginBottom: 0}}>
            <Form.Label>{label}</Form.Label>&nbsp;
            <DatePicker
                dateFormat="d MMMM yyyy HH:mm"
                showTimeSelect={true}
                locale={ru}
                selected={this.state[stateKey]}
                onChange={date => this.setState({[stateKey] : date})}/>
        </Form.Group>
    }

    renderSubmitFiltersButton() {
        return <div>
            <Button variant="outline-primary"
                    size="sm"
                    style={{marginTop: "32px"}}
                    onClick={() => this.loadData()}
                    disabled={this.state.sending}>
                {this.state.sending && (<span className="spinner-border spinner-border-sm"></span>)}
                <span> Применить</span>
            </Button>
        </div>
    }

    renderInputField() {
        return <div>
            <Form.Label style={{whiteSpace : "noWrap"}}>Количество часов простоя</Form.Label>
            <Form.Control size="sm"
                          type="number"
                          style={{width: "200px"}}
                          placeholder={"Часы"}
                          defaultValue={this.state.downtimeHours}
                          onChange={e => this.setState({downtimeHours: e.target.value})} />
        </div>
    }

    render() {
        return <div>
            <Card.Header style={{ display: "flex" }}>
                {this.renderDatePicker("От", "periodStart")}&nbsp;
                {this.renderDatePicker("До", "periodEnd")}&nbsp;
                {this.renderInputField()}&nbsp;
                {this.renderSubmitFiltersButton()}
            </Card.Header>
            {this.renderResponseTable()}
        </div>
    }
}

export default Downtime;