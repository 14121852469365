import BaseForm from "../../form/BaseForm";
import {Button, Col, Collapse, Form, Spinner, Table} from "react-bootstrap";
import motivationService from "../../../services/MotivationService";
import Ssp from "../../reports/Ssp";
import {HEAD_OF_DEPARTMENT, LOGISTIC, securityService} from "../../../services/SecurityService";
import React from "react";
import Motivation from "./Motivation";
import Modal from "react-bootstrap/Modal";
import PaidQuotes from "./PaidQuotes";
import SalaryTable from "./SalaryTable";
import Select from "react-select";

class WagesReportTable extends BaseForm {
    constructor(props) {
        super(props);

        this.state = {
            reports: null,
            sending: true,
            user: securityService.getUser(),
            isEdit: false,
            key: null,
            editingIsOpen: false
        };
    }

    WITHOUT_DEPARTMENT_ID = 16;
    checkReports = null;
    editTimer = null;

    loadReports(isForPaidQuotes) {
        const data = {
            dt: this.props.dt,
            quoteStatusIdList: [Ssp.REQUEST_STATUS_UNLOADED],
            userId: this.props.userId,
            isFact: true
        };

        this.checkReports = setTimeout(() => this.loadReports(isForPaidQuotes), 3000);

        motivationService.post(isForPaidQuotes ? "reportsByPaidQuotes" : "reports", data)
            .then(response => {
                if (isForPaidQuotes && response.length === 0) {
                    this.checkReports;
                } else {
                    clearTimeout(this.checkReports);
                    this.setState({reports: this.calculateTotal(response)});
                    this.setSending(false)
                }
            });
    }

    componentDidMount() {
        this.loadReports(this.props.isForPaidQuotes);
    }

    componentWillUnmount() {
        clearTimeout(this.checkReports);
        clearTimeout(this.editTimer);
        this.startEdit(false);
    }

    TABLE_COLUMNS = [
        {key: Motivation.LOCAL_DATE, value: "Дата"},
        {key: Motivation.DEPARTMENT, value: "Подразделение"},
        {key: Motivation.ROLE, value: "Должность"},
        {key: Motivation.FIO, value: "ФИО"},
        {key: Motivation.QUOTES_NUMBER, value: "Кол-во заявок"},
        {key: Motivation.REVENUE, value: "Выручка"},
        {key: Motivation.REVENUE_PLAN, value: "План по выручке"},
        {key: Motivation.PROFITABILITY, value: "Рентабель- ность (грязная %)"},
        {key: Motivation.FINAL_PROFITABILITY, value: "Рентабель- ность (чистая %)"},
        {key: Motivation.MARGIN, value: "Маржа (чистая)"},
        {key: Motivation.MARGIN_PLAN, value: "План по марже"},
        {key: Motivation.FUEL_AMOUNT, value: "Пролив ГСМ (из заявок)"},
        {key: Motivation.GSM_STRAIT, value: "Пролив ГСМ (вручную)"},
        {key: Motivation.FUEL_DISCOUNT, value: "Скидка ГСМ (вручную)"},
        {key: Motivation.GSM_DISCOUNT, value: "Скидка ГСМ (из заявок)"},
        {key: Motivation.RECOVERABLE_VAT, value: "НДС к возмещению"},
        {key: Motivation.PENALTY_M, value: "Штраф (мотива- ция)"},
        {key: Motivation.PENALTY_D, value: "Штраф (докумен- ты)"},
        {key: Motivation.SALARY, value: "Оклад"},
        {key: Motivation.DUTY, value: "Дежурства"},
        {key: Motivation.INTEGRAL_INDICATOR, value: "Интеграль- ный показатель"},
        {key: Motivation.WORKING_DAYS, value: "Рабочих дней"},
        {key: Motivation.PAID_QUOTES_MOTIVATION, value: "Мотив. часть без ГСМ"},
        {key: Motivation.PAID_QUOTES_MOTIVATION_WITH_GSM, value: "Мотив. часть с ГСМ из заявок"},
        {key: Motivation.MOTIVATION_PART, value: "Мотив. часть по факт. проливу ГСМ"},
        {key: Motivation.TOTAL, value: "Рассчитанная ЗП"}
    ];

    filterForDisplay(col) {
        return col.key !== Motivation.LOCAL_DATE && col.key !== Motivation.USER_ID && col.key !== Motivation.PAID_QUOTES_MOTIVATION_WITH_GSM && col.key !== Motivation.PAID_QUOTES_MOTIVATION
            && (this.props.isForPaidQuotes
                ? col.key !== Motivation.INTEGRAL_INDICATOR && col.key !== Motivation.MARGIN_PLAN && col.key !== Motivation.REVENUE_PLAN
                : col.key !== Motivation.DEDUCTED_MARGIN && col.key !== Motivation.GSM_STRAIT && col.key !== Motivation.FUEL_DISCOUNT);
    }

    getClassForTableCell(col, userId) {
        if (this.props.isForPaidQuotes && col.key === Motivation.QUOTES_NUMBER && !this.isTotalField(userId)) {
            return "table-cell-quotes-number";
        }

        if (col.key === Motivation.QUOTES_NUMBER
            || col.key === Motivation.PROFITABILITY
            || col.key === Motivation.FINAL_PROFITABILITY
            || col.key === Motivation.WORKING_DAYS
            || col.key === Motivation.PENALTY_M
            || col.key === Motivation.PENALTY_D
            || col.key === Motivation.DUTY
            || col.key === Motivation.SALARY
            || col.key === Motivation.INTEGRAL_INDICATOR
            || col.key === Motivation.FUEL_DISCOUNT) {
            return "table-cell-sm";
        }

        if (col.key === Motivation.DEPARTMENT
            || col.key === Motivation.FIO) {
            return "table-cell-lg";
        }

        if (col.key === Motivation.ROLE) {
            return "table-cell-xlg";
        }

        return "table-cell-md";
    }

    setTableRow(r) {
        return <div style={{display: "flex"}} key={r.userId + r.departmentId + r.role}>
            {this.TABLE_COLUMNS.map(col =>
                {
                    if (!this.isTotalField(r.userId) && col.key === Motivation.ROLE
                        && this.isCanEdit() && r.departmentId !== this.WITHOUT_DEPARTMENT_ID
                        && this.props.isForPaidQuotes && false) {
                        return this.renderSelectPosition(col, r);
                    }

                    return this.filterForDisplay(col) &&
                        <td className={this.getClassForTableCell(col, r.userId)}
                            onClick={() => this.props.isForPaidQuotes
                            && col.key === Motivation.QUOTES_NUMBER
                            && !this.isTotalField(r.userId)
                                ? this.showPaidQuotesModal(true, r.userId, r.departmentId, r.role === HEAD_OF_DEPARTMENT)
                                : null}
                            key={col.key + r.userId + r.departmentId + r.role}>
                            {this.editCell(col, r)}
                        </td>
                }
            )}
        </div>;
    }

    renderSelectPosition(col, r) {
        return <Form.Group className="table-cell-xlg" style={{marginBottom: 0}}>
            <Form.Control id="position" size="sm" as="select" defaultValue={r.role}
                          onChange={e => this.updateMotivationForUser(e.target.value, col, r)}>
                <option value="LOGISTIC">Логист</option>
                <option value="HEAD_OF_DEPARTMENT">Руководитель подразделения</option>
            </Form.Control>
        </Form.Group>;
    }

    showPaidQuotesModal(show, userId, departmentId, isHead) {
        this.setState({userId: userId});
        this.setState({departmentId: departmentId});
        this.setState({isHead: isHead});
        this.setState({showPaidQuotesModal: show});
    }

    renderHeader() {
        return (
            <thead className="wages-table-thead">
                <div>
                    {this.TABLE_COLUMNS.map(col =>
                        this.filterForDisplay(col) &&
                        <td className={this.getClassForTableCell(col, 0)} key={col.key}>
                            <b>{col.value}</b>
                        </td>
                    )}
                </div>
            </thead>
        );
    }

    renderBody() {
        const departments = Array.from(new Set(this.state.reports?.map(r => r.departmentId)));
        return <tbody>
        {departments.map(d => {
            const stateKey = "department" + d;

            if (this.state.reports.length === 1) {
                return this.setTableRow(this.state.reports[0]);
            }

            return (
                <>
                    <div className="department-row-total"
                         onClick={() => this.setState({[stateKey] : !this.state[stateKey]})}
                    >
                        {this.state.reports?.map(r => {
                            if (d === r.departmentId && r.userId === 0) {
                                return this.setTableRow(r);
                            }
                        })}
                    </div>
                    <Collapse in={this.state[stateKey]}>
                        <div className="table-cell">
                            {this.state.reports?.map(r => {
                                if (d === r.departmentId && r.userId !== 0) {
                                    return this.setTableRow(r);
                                }
                            })}
                        </div>
                    </Collapse>
                </>
            );
        })}
        {this.state.reports?.map(r => {
                if (r.departmentId === this.WITHOUT_DEPARTMENT_ID) {
                    return this.setTableRow(r);
                }
            })}
        </tbody>
    }

    render() {
        window.onbeforeunload = () => this.componentWillUnmount();

        return (<>
            <Modal show={this.state.showPaidQuotesModal}
                   onHide={() => this.showPaidQuotesModal(false, 0, 0, false)}>
                <Modal.Header style={{width: "100%"}} closeButton>
                    <Modal.Title>
                        {"Заявки"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PaidQuotes dt={this.props.dt}
                                userId={this.state.userId}
                                departmentId={this.state.departmentId}
                                isHead={this.state.isHead}/>
                </Modal.Body>
            </Modal>

            <Form className="wage-reports">
                <Table className="wage-reports-table" striped bordered size="sm">
                    {this.renderHeader()}
                    {this.renderBody()}
                </Table>
                {this.state.sending &&
                    <div style={{textAlign: "center", padding: "10px"}}>
                        <p>{this.props.isForPaidQuotes ? "Проверка наличия данных" : "Рассчёт зарплаты"}</p>
                        <Spinner animation="border"/>
                    </div>
                }
                {this.isAdminOrSupervisor() &&
                    <Col style={{padding: "5px", textAlign: "center"}}>
                        <Button variant="success" onClick={() => this.getMotivationReport()}
                                disabled={this.state.sending}>
                            {this.state.sending && (<span className="spinner-border spinner-border-sm"></span>)}
                            <span> ⇓ Скачать отчёт в формате xlsx</span>
                        </Button>
                        {this.state.editingIsOpen
                            ? <Button style={{marginLeft: "15px"}}
                                      variant="outline-danger"
                                      onClick={() => this.startEdit(false)}>
                                Закрыть редактирование
                            </Button>
                            : <Button style={{marginLeft: "15px"}}
                                      variant="outline-primary"
                                      onClick={() => this.startEdit(true)}>
                                Открыть редактирование
                            </Button>}
                    </Col>}
            </Form>
        </>);
    }

    startEdit(isEdit) {
        motivationService.post("editReport",{userId: this.props.userId, dt: this.props.dt, isEdit: isEdit})
            .then(response => {
                if (response) {
                    this.setState({editingIsOpen: true});
                    this.editTimer = setTimeout(() => this.startEdit(false), 600_000);
                    if (this.props.isForPaidQuotes) {
                        this.loadReports(true);
                    }
                } else {
                    this.setState({editingIsOpen: false});
                }
            });
    }

    isAdminOrSupervisor() {
        return securityService.isAdmin(this.state.user) || securityService.isSupervisor(this.state.user);
    }

    isCanEdit() {
        return this.state.editingIsOpen && this.state.isEdit && this.isAdminOrSupervisor();
    }

    isCellForEdit(col) {
        return col.key === Motivation.DUTY || col.key === Motivation.WORKING_DAYS || col.key === Motivation.FUEL_DISCOUNT
            || col.key === Motivation.GSM_STRAIT || col.key === Motivation.PENALTY_M || col.key === Motivation.PENALTY_D
            || col.key === Motivation.SALARY ||(this.props.isForPaidQuotes && col.key === Motivation.ROLE);
    }

    isTotalField(userId) {
        return userId == null || userId === 0;
    }

    editCell(col, r) {
        const key = col.key + r.userId + r.departmentId + r.role;

        return this.state.key === key
        && this.isCanEdit()
        && this.isCellForEdit(col)
        && !this.isTotalField(r.userId)
            ? this.renderRedactor(col, r, key)
            : <div className={"table-content-static " + (r.role === HEAD_OF_DEPARTMENT ? "table-content-static-head" : "")}
                   style={this.isCellForEdit(col) ? {width: "70px"} : {width: "100%"}}
                   onClick={() => this.openOrCloseFieldEditor(key, true)}>
                {this.getValueByFieldKey(col, r)}
            </div>
    }

    isNotDirectorOrRegionalHead(role) {
        return role !== SalaryTable.DIRECTOR_OF_LOGISTIC && role !== SalaryTable.REGIONAL_HEAD;
    }

    renderRedactor(col, r, key) {
        return col.key === "role" && this.isNotDirectorOrRegionalHead(r.role)
            ? <Select options={[ { label: "Логист", value: LOGISTIC }, { label: "Руководитель", value: HEAD_OF_DEPARTMENT } ]}
                      placeholder="Изменить..."
                      onChange={selected => this.updateMotivationForUser(selected.value, col, r)}>
            </Select>
            : <input
                className="table-content-edit"
                style={{width: "70px", color: "red"}}
                type="number"
                defaultValue={r[col.key]}
                onBlur={() => this.openOrCloseFieldEditor(key, false)}
                autoFocus
                onChange={(event) => this.updateMotivationForUser(event.target.value, col, r)}
            />
    }

    updateMotivationForUser(value, col, r) {
        r[col.key] = value;
        const data = {
            motivation: r,
            isForPaidQuotes: this.props.isForPaidQuotes
        };

        motivationService.post("userPayroll", data).then(motivation => {
            r[Motivation.ROLE] = motivation.role;
            r[Motivation.MOTIVATION_PART] = Number(motivation.motivationalPart);
            r[Motivation.TOTAL] = Number(motivation.total);
            r[Motivation.MARGIN] = Number(motivation.margin);
            r[Motivation.FINAL_PROFITABILITY] = Number(motivation.finalProfitability);

            this.calculateTotal(this.state.reports);
        }).catch(() => this.loadReports(this.props.isForPaidQuotes));
    }

    calculateTotal(reports) {
        this.getSumBy(reports, true);
        this.getSumBy(reports, false);

        return reports;
    }

    getSumBy(reports, isForDepartments) {
        const VAT = 0.2 / 1.2;
        const COLUMNS_WITHOUT_HEAD = [Motivation.MARGIN, Motivation.GSM_STRAIT, Motivation.GSM_DISCOUNT, Motivation.FUEL_DISCOUNT];
        const COLUMNS = [Motivation.MARGIN, Motivation.GSM_STRAIT, Motivation.FUEL_DISCOUNT, Motivation.GSM_DISCOUNT,
            Motivation.PENALTY_M, Motivation.PENALTY_D, Motivation.SALARY, Motivation.DUTY,
            Motivation.WORKING_DAYS, Motivation.PAID_QUOTES_MOTIVATION_WITH_GSM,
            Motivation.PAID_QUOTES_MOTIVATION, Motivation.PAID_QUOTES_MOTIVATION_WITH_GSM,
            Motivation.MOTIVATION_PART, Motivation.TOTAL, Motivation.FINAL_PROFITABILITY
        ];

        reports?.filter(r => isForDepartments ? r[Motivation.USER_ID] === 0 : r[Motivation.DEPARTMENT_ID] === 0).forEach(r => {
            COLUMNS.forEach(key => {
                r[key] = 0;
                r[key] = reports
                    .filter(copy => (isForDepartments
                            ? copy[Motivation.DEPARTMENT_ID] === r[Motivation.DEPARTMENT_ID]
                            : copy[Motivation.USER_ID] === 0)
                        && (COLUMNS_WITHOUT_HEAD.includes(key)
                            ? copy[Motivation.ROLE] !== HEAD_OF_DEPARTMENT
                            : true))
                    .map(copy => Number(copy[key]) + (key === Motivation.MARGIN ? Number(copy[Motivation.DEDUCTED_MARGIN]) : 0))
                    .reduce((a, b) => Number(a) + Number(b));
            });

            r[Motivation.PROFITABILITY] = (Number(r[Motivation.MARGIN]) + Number(r[Motivation.FUEL_DISCOUNT])
                    - (this.props.isForPaidQuotes ? Number(r[Motivation.GSM_STRAIT]) : Number(r[Motivation.FUEL_AMOUNT])) * VAT)
                / Number(r[Motivation.REVENUE]);
            r[Motivation.PROFITABILITY] = isNaN(r[Motivation.PROFITABILITY]) ? 0 : r[Motivation.PROFITABILITY];
            r[Motivation.FINAL_PROFITABILITY] = Number(r[Motivation.MARGIN]) / Number(r[Motivation.REVENUE]);
            r[Motivation.FINAL_PROFITABILITY] = isNaN(r[Motivation.FINAL_PROFITABILITY]) ? 0 : r[Motivation.FINAL_PROFITABILITY];
        });
    }

    getValueByFieldKey(col, r) {
        if (col.key === Motivation.ROLE) {
            return this.roleToString(r[col.key]);
        }

        if (this.isStringField(col.key) || col.key === Motivation.INTEGRAL_INDICATOR) {
            return r[col.key];
        }

        return Number(Number(r[col.key]).toFixed(2)).toLocaleString();
    }

    isStringField(field) {
        return field === Motivation.LOCAL_DATE
            || field === Motivation.DEPARTMENT
            || field === Motivation.FIO;
    }

    roleToString(role) {
        switch (role) {
            case LOGISTIC:
                return "Логист";
            case HEAD_OF_DEPARTMENT:
                return "Руководитель";
            case SalaryTable.DIRECTOR_OF_LOGISTIC:
                return "Директор по логистике";
            case SalaryTable.REGIONAL_HEAD:
                return "Региональный руководитель";
            case "":
                return "";
        }
    }

    openOrCloseFieldEditor(key, isOpen) {
        this.setState({isEdit: isOpen, key});
    }

    getMotivationReport() {
        let reports = new Array(this.state.reports);
        reports[0].map(r => {
            delete r.id;
            delete r.new;
            delete r[Motivation.DEPARTMENT_ID];
            delete r[Motivation.USER_ID];
            delete r[Motivation.DEDUCTED_MARGIN];
            r.role = this.roleToString(r.role);
        });

        motivationService.downloadFile(
            "ЗП-" + this.props.dt.substring(0, 7) + ".xlsx",
            "xlsx",
            {headers: this.TABLE_COLUMNS, content: reports[0]}
        );
    }
}

export default WagesReportTable;