import {axios} from '../axios';

class VehicleMarkService {

    constructor(server) {
        this.server = server;
    }
    static BASE_PATH = "/control-panel/vehicle-mark";

    async list(params) {
        const response = await axios.post(`${VehicleMarkService.BASE_PATH}/list`, params);
        return response.data;
    }

    async read(id) {
        const response = await axios.get(`${VehicleMarkService.BASE_PATH}/${id}`);
        return response.data;
    }

    async save(params) {
        const call = params.id ? axios.put : axios.post;
        const response = await call(`${VehicleMarkService.BASE_PATH}/`, params);
        return response.data;
    }

    async options() {
        const response = await axios.get(`${VehicleMarkService.BASE_PATH}/options`);
        return response.data;
    }
}

export default new VehicleMarkService();