import {axios} from '../axios';

class AbstractDictionaryService {

	constructor(server) {
		this.server = server;
	}
	static BASE_PATH = "/api/control-panel/dict";

	getBasePath() {
		return AbstractDictionaryService.BASE_PATH;
	}

	async list(params, requestType) {
		const response = await axios.post(`${this.getBasePath()}/${requestType}/list`, params);
		return response.data;
	}

	async entityList(params, requestType) {
		const response = await axios.post(`${this.getBasePath()}/${requestType}/entity/list`, params);
		return response.data;
	}

	async read(id, requestType) {
		const response = await axios.get(`${this.getBasePath()}/${requestType}/${id}`);
		return response.data;
	}

	async save(entity, requestType) {
		const call = entity.id ? axios.put : axios.post,
			response = await call(`${this.getBasePath()}/${requestType}`, entity);
		return response.data;
	}

	async isValueUnique(id, value, forwarderId, requestType) {
		const request = { id, value: value, forwarderId: forwarderId };
		const response = await axios.post(`${this.getBasePath()}/${requestType}/value/unique`, request);
		return response.data;
	}

}

export default new AbstractDictionaryService();