import BaseForm from "../../form/BaseForm";
import {Button, Col, Form} from "react-bootstrap";
import Notification from "../../Notification";
import FormGroup from "../../form/FormGroup";
import React from "react";
import {connect} from "react-redux";
import contractorService from "../../../services/ContractorService";

function mapStateToProps(state) {
    return {
        errors: state.errors,
        contractor: {
            id: state.model.id,
            value: state.model.name,
        },
    };
}
class CopyContractorAddress extends BaseForm {
    constructor(props) {
        super(props);
        this.state = {showModal: false, notifyMessage: "", contractorToCopy: null}
    }

    handleClose() {
        this.setState({showModal: false});
    };

    handleCopy(e) {
        e.preventDefault();
        this.setSending(true);
        contractorService.copyContractorAddress(this.state.contractorToCopy, this.props.contractor)
            .then(count => {
                this.setState({showModal: true, notifyMessage: `Скопировано ${count} адресов.`});
            });
        this.setSending(false);
    }

    renderCopyButton() {
        return (
            <Button variant={"primary"} size={"sm"} className={"ml-4"} onClick={e => this.handleCopy(e)}>Копирвать</Button>
        );
    }

    render() {
        return (
            <Form className={"mt-1"}>
                <Notification showModal={this.state.showModal} onClose={e => this.handleClose(e)} message={this.state.notifyMessage}/>
                <Col lg={3}>
                    <FormGroup title="От куда копировать"
                               type="dictionary"
                               optionsType="CONTRACTOR"
                               placeholder={"контрагент"}
                               value={this.state.contractorToCopy}
                               onChangeHandler={(value) => {this.setState({contractorToCopy: value})}}
                               required
                    />
                </Col>
                {this.renderCopyButton()}
            </Form>
        );
    }
}
export default connect(mapStateToProps) (CopyContractorAddress);