import { connect } from 'react-redux';
import { Form, Row, Col } from "react-bootstrap";
import vehicleService from "../../../services/VehicleService";
import { securityService, ADMIN, APPROVAL } from '../../../services/SecurityService';
import { requiredValidator, vehicleNumberValidator, vehicleVinValidator } from "../../../validators/simpleValidators";
import StoreWrapper from '../../form/StoreWrapper';
import BaseForm from '../../form/BaseForm';
import FormGroup from '../../form/FormGroup';
import Loading from '../../Loading';
import { setData } from '../../../actions/form';
import FormValidator from '../../../validators/FormValidator';
import { ENUM_NAME } from '../../../Enums';
import { VEHICLE } from '../../AuditTable/AuditTable';
import FileUploader from '../../FileUploader';
import Util from "../../../Util";
import {setToastObjAC} from "../../../reducers/toastObj";
import React from "react";
import WarningMessage from "../../form/WarningMessage";

function mapGlobalStateToProps(state) {
	return {}
}

class Vehicle extends StoreWrapper {
	
	static get DEFAULT_APPROVAL_STATUS() { return { id: 0, value: "NEW" } };
	static get VTYPE_TRUCK() { return { id: 0, value: "TRUCK" } };

	constructor(props) {
		super(props);
	}

	render() {
		return (
			<VehicleInnerConnected {...this.props} store={this.store} />
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		errors: state.errors,
		model: state.model
	};
}
class VehicleInner extends BaseForm {
	constructor(props) {
		super(props);
			
		this.configureValidators();
		this.applyInternalValidator = this.applyInternalValidator.bind(this);
		this.afterSubmit = this.afterSubmit.bind(this);
	}

	configureValidators() {
		this.useValidatorFor(requiredValidator, "number", "vehicleMark", "vtype", "carcass");
		this.validator.addValidator('newTypeVehicleModel', this.modelValidator.bind(this));
		this.validator.addValidator("cubage", this.applyInternalValidator(this.nonTruckRequiredValidator.bind(this, "cubage")));								
		this.validator.addValidator("tonnage", this.applyInternalValidator(this.nonTruckRequiredValidator.bind(this, "tonnage")));
		this.validator.addValidator("vin", this.vehicleVinValidator.bind(this, "vin"));
		this.validator.addValidator('number', this.uniqueNumberValidator.bind(this));
		this.validator.addValidator('vin', this.uniqueVinValidator.bind(this));
		this.validator.addValidator('number', this.applyInternalValidator(this.vehicleNumberValidator.bind(this)));
	}

	modelValidator() {
		const vehicleModel = this.props.model.newTypeVehicleModel;
		return  vehicleModel && vehicleModel.id ? FormValidator.OK : "Обязательное поле";
	}

	vehicleVinValidator() {
		const {vin, isOldVin, isMissingVin} = this.props.model;
		if (!vin && !isMissingVin) {
			return "Обязательное поле";
		}
		const valid = !vin || (!isOldVin && vin.match(/^\w{17}$/)) || (isOldVin && vin.match(/^\w{16}$/));
		return Promise.resolve(valid ? FormValidator.OK : "некорректный формат VIN");
	}
	async nonTruckRequiredValidator(field) {		
		return this.props.model.vtype?.id === Vehicle.VTYPE_TRUCK.id ? FormValidator.OK : requiredValidator(this.props.model[field]);	
	};
	
	async vehicleNumberValidator() {
		return vehicleNumberValidator(this.props.model.number);
	};

	async uniqueNumberValidator() {
		const isValid = await vehicleService.isUniqueNumber(this.getVehicleId(), this.props.model.number);
		return isValid ? FormValidator.OK : "ТС указанной модели с таким номером уже зарегистрировано в системе";
	}
	
	async uniqueVinValidator() {
		const {isMissingVin} = this.props.model;
		if (isMissingVin) {
			return FormValidator.OK;
		}
		const isValid = await vehicleService.isUniqueVin(this.getVehicleId(), this.props.model.vin);
		return isValid ? FormValidator.OK : "ТС указанной модели с таким VIN уже зарегистрировано в системе";
	}
	
	applyInternalValidator(validator) {
		return value => this.execInternalValidator(validator, value);	
	}
	
	async execInternalValidator(validator, value) {		
		return this.props.model.external ? "" : validator(value);
	}

	getVehicleId() {
		return this.props.location.state?.id 
			|| this.props.model?.id
			|| new URLSearchParams(this.props.location.search).get('id');
	}

	load() {
		const id = this.getVehicleId();
		if (id) {
			vehicleService.read(id).then(data => {
				this.props.store.dispatch(setData(data, this.props.location.state?.action));
			});
		} else {			
			this.props.store.dispatch(setData({ approvalStatus: Vehicle.DEFAULT_APPROVAL_STATUS }));
		}
	}

	isNotMarkSelected() {
		return this.props.model.vehicleMark === null || this.props.model.vehicleMark === undefined;
	}

	renderVehicleIdRow() {
		return (
			<Row>
				<Col lg={1} md={12}>
					<FormGroup title="ID" name="id" store={this.props.store} readOnly />
				</Col>
			</Row>
		);
	}

	renderWarning() {
		return (
			<WarningMessage
				variant="blue"
				message={"После внесения изменений, элемент справочника нельзя будет выбрать в заявке до проверки службы безопасности."}
			/>
		);
	}

	renderVehicleDataRow() {
		const {isMissingVin} = this.props.model;
		return (
			<Row>
				<Col lg={3} md={12}>
					<FormGroup
                        title="VIN"
                        name="vin"
                        store={this.props.store}
                        maxLength={17}
                        required={!isMissingVin}
                        readOnly={isMissingVin} />
				</Col>
				<Col lg={3} md={12}>
					<FormGroup title="Номер" name="number" store={this.props.store}  required />
				</Col>
			</Row>
		);
	}

	renderActiveBarsRow() {
		return (
			<Row>
				<Col lg={3} md={12}>
					<Row className={"ml-1"}>
						<FormGroup
							title="Старый формат VIN"
							name="isOldVin"
							type="checkbox"
							store={this.props.store}
							defaultChecked={this.props.model.isOldVin}
							onClick={(e) => this.onChange("isOldVin", e.target.checked)}
							asRow={true}
							boxLeftSide={true}
						/>
						<FormGroup
							title="VIN отсутствует"
							name="isMissingVin"
							type="checkbox"
							store={this.props.store}
							defaultChecked={this.props.model.isMissingVin}
							onClick={(e) => this.onChangeIsMissingVin(e.target.checked)}
							asRow={true}
							boxLeftSide={true}
						/>
					</Row>
				</Col>
				<Col lg={3} md={12}>
					<FormGroup
						title="Номер не РФ"
						name="external"
						type="checkbox"
						store={this.props.store}
						onClick={(e) => this.onChange("external", e.target.checked)}
						asRow={true}
						boxLeftSide={true}
					/>
				</Col>
			</Row>
		);
	}

	onChangeIsMissingVin(checked) {
		if (checked) {
			this.onChange("vin", null);
		}
		this.onChange("isMissingVin", checked);
	}

	renderMarkAndModelRow() {
		return (
			<Row>
				<Col lg={3} md={12}>
					<FormGroup title="Марка"
							   name="vehicleMark"
							   type="vehicleMarkModel"
							   optionsType="VEHICLE_MARK"
							   store={this.props.store}
							   required
					/>
				</Col>
				<Col lg={3} md={12}>
					<FormGroup title="Модель (Новая версия)"
							   name="newTypeVehicleModel"
							   type="vehicleMarkModel"
							   optionsType="VEHICLE_MODEL"
							   store={this.props.store}
							   markId={this.props.model.vehicleMark?.id}
							   readOnly={this.isNotMarkSelected()}
							   required
					/>
				</Col>
				<Col lg={3} md={12}>
					<FormGroup title="Модель (Старая версия)" name="model" store={this.props.store} readOnly />
				</Col>
			</Row>
		);
	}
	
	renderVehicleConfigRow() {
		return (
			<Row>		
				<Col lg={3} md={12}>
					<FormGroup title="Тип ТС" 
						name="vtype" 
						type="enum"
						optionsType={ENUM_NAME.VEHICLE_TYPE}
						store={this.props.store}
						required
					/>
				</Col>
				<Col lg={3} md={12}>
					<FormGroup title="Тип кузова" 
						placeholder="Тип кузова"
						name="carcass"
						type="dictionary"
						optionsType="CARCASS"
						store={this.props.store}
						required
					/>
				</Col>
				<Col lg={3} md={12}>
					<FormGroup title="Кубатура" 
						name="cubage" 
						store={this.props.store} 
						required={this.props.model.vtype?.id !== Vehicle.VTYPE_TRUCK.id}
					 />
				</Col>
				<Col lg={3} md={12}>
					<FormGroup 
						title="Тоннаж" 
						name="tonnage" 
						store={this.props.store} 
						required={this.props.model.vtype?.id !== Vehicle.VTYPE_TRUCK.id}
					 />
				</Col>
			</Row>
		);
	}
	
	renderVehicleOwnershipRow() {
		return (
			<Row>
				<Col lg={3} md={12}>
					<FormGroup title="Серия, номер ПТС" name="ptsNumber" store={this.props.store} />
				</Col>
				<Col lg={3} md={12}>
					<FormGroup title="Дата выдачи ПТС" type="date" name="ptsIssuedDate" store={this.props.store} />
				</Col>
				<Col lg={3} md={12}>
					<FormGroup title="Тип собственности" 
						placeholder="Тип собственности"
						name="ownershipType"
						type="dictionary"
						optionsType="OWNERSHIP_TYPE"
						store={this.props.store}
					/>
				</Col>
				<Col lg={3} md={12}>
					<FormGroup title="ФИО собственника" name="ownerFio" store={this.props.store}/>
				</Col>
			</Row>
		);
	}

	renderContractorRow() {
		return (
			<Row>
				<Col lg={3} md={12}>
					<FormGroup placeholder="Контрагенты"
						title="Контрагенты"
						name="contractors"
						store={this.props.store}
						type="dictionary"
						optionsType="CONTRACTOR_CARRIER"
					    multiSelect={true}
					/>
				</Col>
				<Col lg={3} md={12}>
					<FormGroup title="Согласование" 
						name="approvalStatus" 
						type="enum"
						optionsType={ENUM_NAME.APPROVAL_STATUS}
						readOnly={securityService.anyRoles(this.props.user, ADMIN, APPROVAL) === false}
						store={this.props.store}
					/>
				</Col>
			</Row>
		);	
	}
	
	renderUploadFormRow() {
		return (
			<Row>				
				<Col lg={6} md={12}>
					<FileUploader
						store={this.props.store}
						name="attachments"
						title="Сканкопии документов"
						fileNameFunction={(attachment, index) => Util.formatFileName(attachment.originalName, "ТС_" + this.props.model.number, index)}
					/>
				</Col>
				<Col lg={6} md={12}>
					<FormGroup title="Комментарий" name="comment" store={this.props.store} type="textarea" rows={2}/>
				</Col>
			</Row>			
		);	
	}

	handleSubmit(e) {
		e.preventDefault();
		this.submit(() =>
			vehicleService.save(this.props.model).then( vehicle => {
				this.afterSubmit(vehicle)
			}));
	}

	onCancel() {
		this.redirectToBackOrDefaultUrl("/control-panel/vehicles")
	}

	afterSubmit(res) {
		const data = {show: true, textHeader: "Транспорт сохранен!", delay: 3000};
		if(!this.getVehicleId()) {
			this.redirectToBackOrDefaultUrl({payload: "/control-panel/vehicles/vehicle", state:{id: res.id}})
			data.textHeader = "Транспорт добавлен!"
		}
		this.load()
		this.onChange('version', res.version);
		this.props.setToastObjAC(data);
	}

	getTabs() {
		const tabs = [];
		if (this.getVehicleId()) {
			tabs.push(this.getAuditTab(this.getVehicleId(), VEHICLE));
		}
		return tabs;
	}

	getFormTabTitle() {
		const number = this.props.model.number || (this.props.model.id > 0 ? this.props.model.id : ''),
			title = "ТС " + number;
		return title;
	}
	
	renderForm() {
		const isReady = !this.getVehicleId() || this.props.model.id;
		if (!isReady) {
			return (<Loading/>);
		}
		return (
			<Form>
                {this.renderWarning()}
                {this.renderVehicleIdRow()}
				{this.renderVehicleDataRow()}
				{this.renderActiveBarsRow()}
				{this.renderMarkAndModelRow()}
				{this.renderVehicleConfigRow()}
				{this.renderVehicleOwnershipRow()}
				{this.renderContractorRow()}
				{this.renderUploadFormRow()}
				{this.renderSaveCancelButtons()}
			</Form>			
		);
	}	
}


const VehicleInnerConnected = connect(mapStateToProps)(VehicleInner);

export default connect(mapGlobalStateToProps, {setToastObjAC})(Vehicle);