import React, {PureComponent} from "react";
import "./Timer.css"

class Timer extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0
        };

        this.interval = null;
    }

    componentDidMount() {
        if(this.props.deadline) {
            this.interval = setInterval(() => this.getTime(this.props.deadline), 1000);
        }
    }

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval)
        }
    }

    getTime = (deadline) => {
        const time = Date.parse(deadline) - Date.now();

        this.setState(() => ({
            days: Math.floor(time / (1000 * 60 * 60 * 24)),
            hours: Math.floor((time / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((time / 1000 / 60) % 60),
            seconds: Math.floor((time / 1000) % 60)
        }))
    }

    render() {
        const {
            days,
            hours,
            minutes,
            seconds
        } = this.state;

        return (
            <div className="timer" role="timer">
                <div className="timer__box">
                    <p id="day" className="timer__value">{days < 10 ? "0" + days : days}</p>
                    <span className="timer__text">Дней</span>
                </div>

                <div className="timer__box">
                    <p id="hour" className="timer__value">{hours < 10 ? "0" + hours : hours}</p>
                    <span className="timer__text">Часов</span>
                </div>

                <div className="timer__box">
                    <p id="minute" className="timer__value">{minutes < 10 ? "0" + minutes : minutes}</p>
                    <span className="timer__text">Минут</span>
                </div>

                <div className="timer__box">
                    <p id="second" className="timer__value">{seconds < 10 ? "0" + seconds : seconds}</p>
                    <span className="timer__text">Секунд</span>
                </div>
            </div>
        )
    }
}

export default Timer;