import React from "react";
import { connect } from 'react-redux';

import vehicleService from '../../../services/VehicleService';
import DataTable from '../../table/DataTable';
import { VEHICLE_TYPES, ENUM_NAME } from '../../../Enums';
import TableCell from "../../table/TableCell";
import {COMMERCIAL, LOGISTIC, securityService} from "../../../services/SecurityService";
import {EXTERNAL} from "../../../services/UserService";

class Vehicles extends React.PureComponent {

	fetch(params) {
		return vehicleService.list(params);
	}

	openAddForm() {
		window.open("/control-panel/vehicles/vehicle", "_blank")
	}

	openEditForm(row) {
		window.open(`/control-panel/vehicles/vehicle?id=${row.id}`)
	}

	checkMinimalSearch() {
		return securityService.hasOnlyRoles(this.props.user, LOGISTIC, COMMERCIAL);
	}

	checkIfCanAdd() {
		const user = securityService.getUser();
		const roles = securityService.getRolesByType(EXTERNAL);
		return !roles.some(role => securityService.hasRole(user, role));
	}

	render() {
		return (
			<DataTable {...this.props}
				title = "Транспортные средства"
				sort = {{field: 'newTypeVehicleModel', dir: 'asc'}}
				openAddForm = {this.openAddForm.bind(this)}
				openEditForm = {this.openEditForm.bind(this)}
				fetch = {this.fetch.bind(this)}
				features = {{
                    checkMinimalSearch: this.checkMinimalSearch(),
                    canAdd: this.checkIfCanAdd()
                }}
				columns = {[
					{ title: "#", field: "id", className: "id", filter: DataTable.STRING_FILTER },
					{ title: "Марка", field: "vehicleMark", filter: DataTable.DICTIONARY_FILTER, optionsType: "VEHICLE_MARK" },
					{ title: "Модель (Новая версия)", field: "newTypeVehicleModel", filter: DataTable.DICTIONARY_FILTER, optionsType: "VEHICLE_MODEL" },
					{ title: "Модель (Старая версия)", field: "model", filter: DataTable.STRING_FILTER },
					{ title: "Номер", field: "number", filter: DataTable.STRING_FILTER },
					{ title: "VIN", field: "vin", filter: DataTable.STRING_FILTER },
					{ title: "Тип", field: "vtype", filter: DataTable.ENUM_FILTER, 
						optionsType: ENUM_NAME.VEHICLE_TYPE,
						formatter: TableCell.enumFormatter(VEHICLE_TYPES) },
					{ title: "Тип кузова", field: "carcass", filter: DataTable.DICTIONARY_FILTER, 
						optionsType: "CARCASS" },
					{ title: "Тип собственности", field: "ownershipType", filter: DataTable.DICTIONARY_FILTER,
						optionsType: "OWNERSHIP_TYPE" },
					{ title: "Контрагенты", field: "contractors", filter: DataTable.DICTIONARY_FILTER, optionsType: 'CONTRACTOR_CARRIER',
						multiSelect: true, formatter: value => TableCell.arrayFormatter(value), sortable: false }
				]}
			/>
		);
	}
	
}

export default connect(DataTable.mapStateToProps)(Vehicles);
