import {axios} from '../axios';

class ApprovalService {
	
	async list(params) {
		const response = await axios.post("/api/control-panel/approval/list", params);
		return response.data;
	}
	
	async counts() {
		const response = await axios.get("/api/control-panel/approval/counts");
		return response.data;
	}
}

export default new ApprovalService();