import React from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { userService, GENERAL, EXTERNAL } from '../../../services/UserService';
import {COMMERCIAL, LOGISTIC, securityService} from '../../../services/SecurityService';
import DataTable from '../../table/DataTable';
import TableCell from '../../table/TableCell';

class Users extends React.PureComponent {
	constructor(props) {
		super(props);
		this.getColumns = this.getColumns.bind(this);
	}

	formatRoles(roles) {
		return TableCell.arrayFormatter(
			roles.map(role => securityService.getRoleName(role))
		);	
	}

	fetch(params) {
		if (!params.roles) {
			params.roles = securityService.getRolesByType(this.props.type);
		}
		return userService.list(params);
	}

	openAddForm() {
		window.open(`/control-panel/${this.props.type || GENERAL}/user`, "_blank")
	}

	openEditForm(row) {
		window.open(`/control-panel/${this.props.type || GENERAL}/user?id=${row.id}`, "_blank")
	}

	checkMinimalSearch() {
		return securityService.hasOnlyRoles(this.props.user, LOGISTIC, COMMERCIAL);
	}

	getColumns() {
		this.setState({columns: this.columns().filter(col => { return Object.keys(col).length !== 0 })})
	}

	columns() {
		return [
			{ title: "#", field: "id", className: "id", filter: DataTable.STRING_FILTER },
			{ title: "Ф.И.О.", field: "fio", filter: DataTable.STRING_FILTER },
			{ title: "Email", field: "email", filter: DataTable.STRING_FILTER },
			{ title: "Телефон", field: "phone", filter: DataTable.STRING_FILTER },
			{ ...this.props.type === GENERAL
					? { title: "Подразделения", field: "departments", filter: DataTable.DICTIONARY_FILTER,
						optionsType: "DEPARTMENT", activeOnly: false, multiSelect: true, sortable: false }
					: { title: "Перевозчики", field: "contractors", filter: DataTable.DICTIONARY_FILTER, optionsType: 'CONTRACTOR_CARRIER',
						multiSelect: true, formatter: value => TableCell.arrayFormatter(value), sortable: false }
			},
			{ ...this.props.type === GENERAL && { title: "Отделы", field: "divisions", filter: DataTable.DICTIONARY_FILTER,
					optionsType: "DIVISION", activeOnly: false, multiSelect: true, sortable: false }},
			{ title: "Роли", field: "roles", filter: DataTable.ROLES_FILTER, filteredOptions: securityService.getRolesByType(this.props.type),
				formatter: value => this.formatRoles(value), sortable: false },
			{ title: "Активен", field: "active", className: "yes-no",
				formatter: TableCell.booleanFormatter, filter: DataTable.BOOLEAN_FILTER },
			{ title: "Экспедитор", field: "forwarder", filter: DataTable.DICTIONARY_FILTER, optionsType: 'FORWARDER' },
		];
	};

	render() {
		return (
			<DataTable {...this.props}
			    title = {this.props.title}
				sort = {{field: 'fio', dir: 'asc'}}
				openAddForm = {this.openAddForm.bind(this)}
				openEditForm = {this.openEditForm.bind(this)}
				fetch = {this.fetch.bind(this)}
				features = {{ checkMinimalSearch: this.checkMinimalSearch() }}
				columns = {this.columns().filter(col => { return Object.keys(col).length !== 0 })}
			    getColumns={this.getColumns}
			/>
		);
	}
}

export default withRouter(connect(DataTable.mapStateToProps)(Users));
