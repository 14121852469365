import React from "react";
import { connect } from 'react-redux';

import contractService from '../../../services/ContractService';
import DataTable from '../../table/DataTable';
import TableCell from "../../table/TableCell";
import ContractsForm from "../contractors/ContractsForm";

class Contracts extends React.PureComponent {

    fetch(params) {
        return contractService.list(params);
    }

    openEditForm(row) {
        window.open(`/control-panel/contractors/contractor?id=${row.contractor.id}&activeTab=contracts&contractId=${row.id}`, "_blank")
    }

    render() {
        return (
            <DataTable {...this.props}
                title = "Договоры"
                sort = {{field: ContractsForm.FIELD_ID, dir: 'asc'}}
                fetch = {this.fetch.bind(this)}
                openEditForm = {this.openEditForm.bind(this)}
                columns = {[
                    { title: "#", field: ContractsForm.FIELD_ID, className: "id", filter: DataTable.STRING_FILTER },
                    { title: "Номер", field: ContractsForm.FIELD_NUMBER, filter: DataTable.STRING_FILTER },
                    { title: "Вид договора", field: ContractsForm.FIELD_CONTRACT_TYPE, filter: DataTable.DICTIONARY_FILTER, optionsType: "CONTRACT_TYPE" },
                    { title: "Дата", field: ContractsForm.FIELD_DT, formatter: TableCell.dtFormatter, filter: DataTable.DATE_RANGE_FILTER },
                    { title: "Действует с", field: ContractsForm.FIELD_VALID_FROM, formatter: TableCell.dtFormatter, filter: DataTable.DATE_RANGE_FILTER },
                    { title: "Действует до", field: ContractsForm.FIELD_VALID_TO, formatter: TableCell.dtFormatter, filter: DataTable.DATE_RANGE_FILTER },
                    { title: "Основной контракт", field: ContractsForm.FIELD_MAIN_CONTRACT, formatter: TableCell.booleanFormatter, filter: DataTable.BOOLEAN_FILTER },
                    { title: "Документы для оплаты", field: ContractsForm.FIELD_PAYMENT_DOCUMENTS, filter: DataTable.STRING_FILTER },
                    { title: "Отсрочка платежа", field: ContractsForm.FIELD_PAYMENT_DELAY, filter: DataTable.NUMERIC_RANGE_FILTER },
                    { title: "Тип отсрочки", field: ContractsForm.FIELD_PAYMENT_DELAY_TYPE, filter: DataTable.DICTIONARY_FILTER, optionsType: "PAYMENT_DELAY_TYPE" },
                    { title: "Вид оплаты", field: ContractsForm.FIELD_PAYMENT_MODE, filter: DataTable.DICTIONARY_FILTER, optionsType: "PAYMENT_MODE" },
                    { title: "Статус договора", field: ContractsForm.FIELD_CONTRACT_STATUS, filter: DataTable.DICTIONARY_FILTER, optionsType: "CONTRACT_STATUS" },
                    { title: "Форма оплаты", field: ContractsForm.FIELD_VAT_MODE, filter: DataTable.DICTIONARY_FILTER, optionsType: "VAT_MODE" },
                    { title: "Есть дополнительное соглашение", field: ContractsForm.FIELD_SUPPLEMENTARY_AGREEMENT, formatter: TableCell.booleanFormatter, filter: DataTable.BOOLEAN_FILTER },
                    { title: "Количество дней на возврат ТТН", field: ContractsForm.FIELD_TTN_RETURN_DAYS, filter: DataTable.NUMERIC_RANGE_FILTER },

                    { title: "Контрагент", field: ContractsForm.FIELD_CONTRACTOR, filter: DataTable.DICTIONARY_FILTER, optionsType: "CONTRACTOR", activeOnly: false },
                    { title: "Организации контрагента", field: "contractorOrganizations", formatter: TableCell.optionsFormatter,
                                                            filter: DataTable.DICTIONARY_FILTER, optionsType: "ORGANIZATION", activeOnly: false },
                    { title: "В лице", field: ContractsForm.FIELD_CONTRACTOR_AUTHORITY, filter: DataTable.STRING_FILTER },
                    { title: "Должность", field: ContractsForm.FIELD_CONTRACTOR_AUTHORITY_POSITION, filter: DataTable.STRING_FILTER },
                    { title: "Действует на основании", field: ContractsForm.FIELD_CONTRACTOR_ACTION_BASIS, filter: DataTable.STRING_FILTER },
                    { title: "Р. счет", field: ContractsForm.FIELD_PAYMENT_ACCOUNT, filter: DataTable.STRING_FILTER },

                    { title: "Организация", field: ContractsForm.FIELD_ORGANIZATION,  filter: DataTable.DICTIONARY_FILTER, optionsType: "ORGANIZATION", activeOnly: false },
                    { title: "В лице", field: ContractsForm.FIELD_ORGANIZATION_AUTHORITY, filter: DataTable.STRING_FILTER },
                    { title: "Должность", field: ContractsForm.FIELD_ORGANIZATION_AUTHORITY_POSITION, filter: DataTable.STRING_FILTER },
                    { title: "Действует на основании", field: ContractsForm.FIELD_ORGANIZATION_ACTION_BASIS, filter: DataTable.STRING_FILTER },
                    { title: "Р. счет организации", field: ContractsForm.FIELD_ORGANIZATION_PAYMENT_ACCOUNT, filter: DataTable.STRING_FILTER }
                ]}
            />
        );
    }
}

export default connect(DataTable.mapStateToProps)(Contracts);
