import React, {PureComponent} from "react";
import mapService from "../../services/MapService";
import {Button, Card, Form} from "react-bootstrap";
import DatePicker from "react-datepicker";
import ru from "date-fns/locale/ru";
import "./MapStyle.css";
import dateTimeService from "../../services/DateTimeService";
import MarkerIcons from "./MarkerIcons";

class MonitoringMapQuote extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            invalidAddress: "",
            startPeriod: dateTimeService.subtractHours(new Date(), 1),
            endPeriod: new Date(),
            showFactRoute: false,
            showStatuses: false
        }
    }
    map = null;
    markerIcons = new MarkerIcons();
    STATUS_CHANGES_STATE = "statusChanges";
    ACTUAL_ROUTE_STATE = "userFactRoute";

    componentDidMount() {
        mapService.getQuoteAddress(this.props.quoteId)
            .then(addresses => {
                const coordinates = this.getCoordinatesFromAddresses(addresses);
                mapService.setScriptToHtmlPage().then(() => {
                    ymaps.ready(() => {
                        this.removeSpinner();
                        this.initMap(coordinates);
                        this.drawPlanRoute(coordinates);
                    });
                });
            })
            .catch(() => {
                this.removeSpinner();
                this.setState({invalidAddress: "Координаты адресов не распознаны"});
            });
    }

    loadFactUserRouteByQuoteId(data, showStatuses) {
        const request = {
            quoteId: data.quoteId ? data.quoteId : this.props.quoteId,
            startPeriod: data.startPeriod ? data.startPeriod : dateTimeService.createDateAsUTC(this.state.startPeriod),
            endPeriod: data.endPeriod ? data.endPeriod : dateTimeService.createDateAsUTC(this.state.endPeriod)
        }

        mapService.getDriverActualRouteByQuoteId(request)
            .then(route => {
                this.drawFactRoute(route, showStatuses);
            });
    }

    removePlacemarksByStateKey(...stateKeys) {
        stateKeys.forEach(stateKey => {
            if (this.state[stateKey]) {
                this.map.geoObjects.remove(this.state[stateKey]);
                this.setState({ [stateKey] : null });
            }
        })
    }

    drawFactRoute(route, showStatuses) {
        if (this.state.showFactRoute) {
            this.removePlacemarksByStateKey(this.ACTUAL_ROUTE_STATE);
            const geoObjects = new ymaps.GeoObjectCollection({}, { preset: this.ACTUAL_ROUTE_STATE });

            for (let i = 0; i < route.length; i++) {
                const placemark = new ymaps.Placemark(
                    [ route[i].latitude, route[i].longitude ],
                    { hintContent: new Date(route[i].dt).toLocaleString() },
                    this.markerIcons.getImageSettingsForActualRoute(route.length, i)
                );

                geoObjects.add(placemark);
            }

            this.map.geoObjects.add(geoObjects);
            this.setState({ [this.ACTUAL_ROUTE_STATE]: geoObjects });
        }

        if (showStatuses) {
            this.addPlacemarkersWithStatuses(route);
        }
    }

    setStatusPoints(statusChanges, route) {
        this.removePlacemarksByStateKey(this.STATUS_CHANGES_STATE);
        const geoObjects = new ymaps.GeoObjectCollection({}, { preset: this.STATUS_CHANGES_STATE });
        const points = [];

        statusChanges?.map(sc => {
            let minTimeDifferent = new Date().getTime();
            let latitude = null;
            let longitude = null;
            let time = null;

            route.forEach(r => {
                const different = Math.abs(new Date(r.dt).getTime() - new Date(sc.dt).getTime());
                if (different <= minTimeDifferent) {
                    minTimeDifferent = different;
                    latitude = r.latitude;
                    longitude = r.longitude;
                    time = r.dt;
                }
            });

            let point = points.find(p => p.latitude === latitude && p.longitude === longitude);
            if (point) {
                point.content = point.content + "<div>(" + new Date(sc.dt).toLocaleString() + ") " + sc.oldStatusValue + " → " + sc.newStatusValue + "</div>";
            } else {
                points.push({
                    latitude: latitude,
                    longitude: longitude,
                    dt: time,
                    content: "<div>(" + new Date(sc.dt).toLocaleString() + ") " + sc.oldStatusValue + " → " + sc.newStatusValue + "</div>"
                })
            }

        });

        points.forEach(p => geoObjects.add(
                new ymaps.Placemark(
                    [ p?.latitude, p?.longitude ],
                    {
                        balloonContentHeader: new Date(p.dt).toLocaleString(),
                        balloonContentBody: p.content,
                        balloonContentFooter: p?.latitude + " " + p?.longitude
                    },
                    this.markerIcons.getImageSettingsForStatusChanges()
                )
            )
        );

        this.map.geoObjects.add(geoObjects);
        this.setState({ [this.STATUS_CHANGES_STATE]: geoObjects });
    }

    addPlacemarkersWithStatuses(route) {
        mapService.getQuoteStatusesChanges(this.props.quoteId)
            .then(statusChanges => {
                this.setStatusPoints(statusChanges, route);
            });
    }

    getCoordinatesFromAddresses(addresses) {
        return addresses
            .map(a => [a.latitude, a.longitude])
            .filter(c => c[0] !== null || c[1] !== null);
    }

    removeSpinner() {
        document.getElementById("yandex-map-load-spinner").remove();
    }

    initMap(coordinates) {
        this.map = new ymaps.Map("yandex-map", {
            center: coordinates,
            zoom: 10
        });

        this.map.setBounds(ymaps.util.bounds.fromPoints(coordinates));
    }

    drawPlanRoute(coordinates) {
        this.removePlacemarksByStateKey("userPlanRoute");
        const geoObjects = new ymaps.GeoObjectCollection({}, { preset: "userPlanRoute" });

        geoObjects.add(new ymaps.multiRouter.MultiRoute(
            { referencePoints: coordinates },
            { routeActiveStrokeColor: "ff66cc" }
        ));

        this.setState({ userPlanRoute: geoObjects });
        this.map.geoObjects.add(geoObjects);
    }

    changePeriod(date, stateKey) {
        const data = { [stateKey]: date }
        this.loadFactUserRouteByQuoteId(data, this.state.showStatuses);
        this.setState(data);
    }

    showOrHidePlacemarks(stateKey) {
        if (stateKey === "showFactRoute") {
            if (this.state[stateKey]) {
                this.removePlacemarksByStateKey(this.ACTUAL_ROUTE_STATE);
            } else {
                this.loadFactUserRouteByQuoteId({}, this.state.showStatuses);
            }
        } else {
            if (this.state[stateKey]) {
                this.removePlacemarksByStateKey(this.STATUS_CHANGES_STATE);
            } else {
                this.loadFactUserRouteByQuoteId({}, !this.state.showStatuses);
            }
        }

        this.setState({ [stateKey] : !this.state[stateKey] });
    }

    renderDatePicker(stateKey) {
        return <Form.Group style={{width: "130px", margin: 0}}>
            <DatePicker
                className="map-date-picker"
                dateFormat="dd.MM.yyyy HH:mm"
                showTimeSelect={true}
                locale={ru}
                selected={this.state[stateKey]}
                onChange={date => this.changePeriod(date, stateKey)
                }/>
        </Form.Group>
    }

    renderPeriodSelector() {
        return <div className="period">
            &nbsp;{"с"}&nbsp;{this.renderDatePicker("startPeriod")}
            &nbsp;{"по"}&nbsp;{this.renderDatePicker("endPeriod")}
        </div>
    }

    showOrHideButton(label, stateKey) {
        return <Button
            variant={this.state[stateKey] ? "primary" : "outline-primary"}
            onClick={() => this.showOrHidePlacemarks(stateKey)}
        >
            {(this.state[stateKey] ? "Скрыть " : "Показать ") + label}
        </Button>
    }

    render() {
        return <Card>
            <Card.Header className="flex-space-between">
                <div>{this.renderPeriodSelector()}</div>
                <div>
                    {this.showOrHideButton("статусы", "showStatuses")}
                    &nbsp;
                    {this.showOrHideButton("фактический маршрут", "showFactRoute")}
                </div>
            </Card.Header>
            <Card.Body style={{padding: 0}}>
                <div id="yandex-map" style={{width: "100%", height: "75vh"}}>
                    <div style={{textAlign: "center"}}>
                        {this.state.invalidAddress}
                    </div>
                    <div id="yandex-map-load-spinner"
                         style={{position: "relative", marginLeft: "40vw"}}
                         className="spinner-border spinner-border-lg">
                    </div>
                </div>
            </Card.Body>
        </Card>
    }
}

export default MonitoringMapQuote;