import React, {Fragment} from "react";
import DataTable from "../table/DataTable";
import {connect} from "react-redux";
import surveyService from "../../services/survey/SurveyService";
import TableCell from "../table/TableCell";
import Enums, {ENUM_NAME, SURVEY_STATUSES, SURVEY_TYPES} from "../../Enums";
import SurveyForm from "./form/card/SurveyForm";

class Surveys extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            columns: []
        }
    }

    render() {
        const {title} = this.props;
        const {columns} = this.state || [];
        if (!columns || columns.length === 0) {
            return <Fragment/>
        }

        return (
            <DataTable
                title={title}
                openAddForm = {this.openAddForm.bind(this)}
                openEditForm = {this.openEditForm.bind(this)}
                fetch = {this.fetch.bind(this)}
                columns = {columns}
                features = {{ search: false, canAdd: this.checkIfCanAdd() }}
            />
        );
    }

    checkIfCanAdd() {
        return !!this.props.type;
    }

    openAddForm() {
        const type = this.props.type || Enums.SURVEY_TYPE[row.type];
        localStorage.setItem("surveyType", JSON.stringify(type));
        this.props.history.push({ pathname: "/survey/form", state: { type: this.props.type }});
    }

    openEditForm(row) {
        const type = this.props.type || Enums.SURVEY_TYPE[row.type];
        localStorage.setItem("surveyType", JSON.stringify(type));
        window.open("/survey/form" + `?id=${row.id}`, "_blank", );
    }

    async fetch(params) {
        const paramsWithFilter = this.rebuildParams(params);
        const response = await surveyService.list(paramsWithFilter);
        return this.getData(response);
    }

    rebuildParams(params) {
        const {type} = this.props;

        params.type = type ? type.value : params.type;
        params.sort = this.formatSort(params.sort);

        return this.appendFilter(params);
    }

    appendFilter(params) {
        const {columns} = this.state;
        const colFields = columns.map(col => col.field);

        return Object.keys(params).reduce((acc, key) => {
            if (colFields.includes(key) && params[key]) {
                acc.filter[key] = this.formatFilterByType(params, key);
            } else {
                acc[key] = params[key];
            }
            return acc;
        }, {filter: {}});
    }

    formatFilterByType(params, key) {
        const {columns} = this.state;
        const enumFields = columns.filter(col => col.filter === DataTable.ENUM_FILTER).map(col => col.field);
        const multiSelectFields = columns.filter(col => col.multiSelect === true).map(col => col.field);

        const isMultiSelect = multiSelectFields.includes(key);
        const isEnumField = enumFields.includes(key);

        let result = params[key];
        if (isMultiSelect) {
            result = this.formatMultiSelectFilter(params[key]);
        } else if (isEnumField) {
            result = params[key].value;
        }
        return result;
    }

    formatMultiSelectFilter(param) {
        if (!param || param.length === 0) {
            return param;
        }
        return param.map(obj => obj.value).join(",");
    }

    async componentDidMount() {
        await this.setColumns();
    }

    async setColumns() {
        const {type} = this.props;
        return type ? await this.loadAndSetTableColumns(type) : this.setDefaultColumns();
    }

    async loadAndSetTableColumns(type) {
        const columns = await surveyService.getForm(type?.value) || [];
        const tableColumns = columns
            .filter(col => this.excludeFilter(col))
            .sort((col1, col2) => col1.sort - col2.sort)
            .map(col => this.formatColumn(col));

        tableColumns.unshift({ title: "Наименование", field: "search", filter: DataTable.STRING_FILTER });
        tableColumns.unshift({ title: "Согласована", field: "solution", className: "yes-no",
            filter: DataTable.BOOLEAN_FILTER, formatter: TableCell.strBooleanFormatter });
        tableColumns.unshift({ title: "Статус", field: "status", filter: DataTable.ENUM_FILTER,
            optionsType: ENUM_NAME.SURVEY_STATUS, formatter: TableCell.enumFormatter(SURVEY_STATUSES), multiSelect: true });
        tableColumns.unshift({ title: "#", field: "id", className: "id", filter: DataTable.STRING_FILTER });
        this.setState({columns: tableColumns});
    }

    excludeFilter(col) {
        return !this.typeIsFile(col.type) && col.code !== SurveyForm.FILED_CONTRACTOR_TYPE;
    }

    typeIsFile(type) {
        return type === SurveyForm.FIELD_TYPE_FILE;
    }


    formatColumn(col) {
        return {
            title: col.name,
            field: col.code,
            filter: col.filterType,
            optionsType: col.optionType,
            multiSelect: col.multiSelect,
            formatter: this.getColFormatter(col)
        };
    }

    getColFormatter(col) {
        return TableCell.getFormatter(col.formatterType);
    }

    setDefaultColumns() {
        const columns =  [
            { title: "#", field: "id", className: "id overflow", filter: DataTable.STRING_FILTER },
            { title: "Статус", field: "status", filter: DataTable.ENUM_FILTER, optionsType: ENUM_NAME.SURVEY_STATUS,
                formatter: TableCell.enumFormatter(SURVEY_STATUSES), multiSelect: true},
            { title: "Согласована", field: "solution", className: "yes-no", filter: DataTable.BOOLEAN_FILTER,
                formatter: TableCell.strBooleanFormatter },
            { title: "Тип", field: "type", filter: DataTable.ENUM_FILTER, optionsType: ENUM_NAME.SURVEY_TYPE,
                formatter: TableCell.enumFormatter(SURVEY_TYPES) },
            { title: "Наименование", field: "search", filter: DataTable.STRING_FILTER },
            { title: "Фамилия", field: "lastName", filter: DataTable.STRING_FILTER },
            { title: "Имя", field: "firstName", filter: DataTable.STRING_FILTER },
            { title: "Отчество", field: "middleName", filter: DataTable.STRING_FILTER },
            { title: "Телефон", field: "phone", filter: DataTable.STRING_FILTER },
            { title: "Гос. номер", field: "number", filter: DataTable.STRING_FILTER },
            { title: "ИНН", field: "inn", filter: DataTable.STRING_FILTER }
        ];

        this.setState({columns: columns});
    }

    formatSort(sort) {
        if (!sort) {
            return null;
        }
        const dir = sort.dir.toUpperCase();
        return { [sort.field]: dir };
    }

    async getData(response) {
        const rows = response.data;
        rows.map(row => this.getPropertiesFromMetadata(row));

        return Promise.resolve({data: rows, pages: response.pages});
    }

    getPropertiesFromMetadata(row) {
        const metadata = row.metadata;
        delete row.metadata;

        Object.keys(metadata).forEach(key => {
            if (Array.isArray(metadata[key])) {
                metadata[key] = metadata[key].map(it => it?.value || it);
            }
            row[key] = metadata[key];
        });

        return row;
    }

}

export default connect(DataTable.mapStateToProps)(Surveys);