import React from "react";
import {Button, Card, Col, Form, Row, Spinner, Table} from "react-bootstrap";
import FormGroup from "../form/FormGroup";
import BaseForm from "../form/BaseForm";
import StoreWrapper from "../form/StoreWrapper";
import {connect} from "react-redux";
import CollapseBlock from "../CollapseBlock";
import TableCell from "../table/TableCell";
import "./Report.css"
import {requiredValidator} from "../../validators/simpleValidators";

/**
 * Отчёт.
 * @param title {string} наименование отчёта.
 * @param fetch {promise} функция получения данных для отчёта.
 * @param fetchXLS {promise} функция экспорта данных в excel.
 * @param columns {[object]} колонки таблицы.
 * @param from {object} период "С".
 * @param to {object} период "По".
 * @param requiredFields {[string]} перечисление названий полей, к которым должна примениться валидация обязательных полей.
 * @param doubleClickOnTableRow (event, row) => {} принимает функцию, которая выполняется при двойном нажатии на строку.
 *
 * columns - массив объектов. Используется в теле таблицы и фильтрах.
 * Объект должен иметь свойства как компонент ../form/FormGroup для корректного отображения в таблице и работы в панеле фильтров.
 * Если для вашего объекта не нашлось подходящего типа в FormGroup, то его необходимо там реализовать.
 * Если колонка не должна быть сортируемой, то необходимо добавить свойство sort: false.
 * Если для колонки не нужен филтьр, то необходимо добавить свойство filter: false.
 * Если у свойства объекта специфичная структура или перед отображением в таблице его нужно отформатировать,
 *  то необходимо добавить свойство formatter: --функция форматирования--
 *
 *  from, to - структура как у column.
 */
class Report extends StoreWrapper {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <ReportInnerConnected {...this.props} store={this.store} />
        );
    }

}

function mapStateToProps(state) {
    return {
        errors: state.errors,
        model: state.model
    };
}

class ReportInner extends BaseForm {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            sort: null,
            isSending: false
        }

        this.configureValidators();
        this.getData = this.getData.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
    }

    configureValidators() {
        if (this.props.requiredFields) {
            this.useValidatorFor(requiredValidator, ...this.props.requiredFields);
        }
    }

    fetch(params) {
        return this.props.fetch ? this.props.fetch(params) : Promise.resolve({data: []});
    }

    getData() {
        this.setState({ isSending: true })

        this.submit(() => {
            this.fetch({sort: this.state.sort, ...this.props.model})
                .then(response => {
                    this.setState({data: response})
                })
                .finally(() => this.setState({ isSending: false }));
        });
    }

    clearFilters() {
        this.props.columns.map(col => this.onChange(col.name, null));
    }

    handleSort(col) {
        if (this.state.data.length > 0) {
            const field = col.name;
            const dir = this.state.sort && field === this.state.sort.field && this.state.sort.dir === "asc" ? "desc" : "asc";
            this.setState({sort: {field: field, dir: dir}});
            this.getData();
        }
    }

    isSortedBy(field, dir) {
        return this.state.sort && field === this.state.sort.field && this.state.sort.dir === dir;
    }

    renderFormGroup(col, asRow) {
        return (
          <FormGroup
              title={col.title}
              name={col.name}
              type={col.type}
              selectType={col.selectType}
              optionsType={col.optionsType}
              store={this.props.store}
              value={col.value}
              activeOnly={col.activeOnly}
              readOnly={col.readOnly}
              multiSelect={col.multiSelect}
              filteredOptions={col.filteredOptions}
              onChange={col.onChange}
              showClearIcon={true}
              asRow={asRow}
              server={col.server}
          />
        );
    }

    renderDateRangeFilter() {
        const { from, to } = this.props;
        return(
            <Row className={"mt-2"}>
                <Col lg={2} >
                    { this.renderFormGroup(from, true) }
                </Col>
                <Col lg={2}>
                    { this.renderFormGroup(to, true) }
                </Col>
                <Col lg={2}>
                    { this.renderApplyButton() }
                </Col>
            </Row>
        );
    }

    renderApplyButton() {
        const someFieldIsEmpty = !this.props.requiredFields.every(f => this.props.model[f]);

        return (
            <div className={"d-flex"}>
                <Button disabled={someFieldIsEmpty || this.state.isSending}
                        variant="outline-primary"
                        size="sm"
                        onClick={this.getData}>
                    {this.state.isSending && <span className="spinner-border spinner-border-sm"></span>} Применить
                </Button>
                &nbsp;
                <Button variant="outline-secondary" size="sm" onClick={this.clearFilters}>
                    Очистить
                </Button>
            </div>
        );
    }

    renderFilterPanel() {
        const columns = this.props.columns.filter(col => col.filter !== false);
        return (
            <CollapseBlock title="Фильтры">
                <Card>
                    <Card.Body>
                        <Row>
                            {columns.filter(col => !col.hideFilter).map(col => {
                                return (
                                    <Col lg={2}>
                                        { this.renderFormGroup(col) }
                                    </Col>
                                );
                            })}
                        </Row>
                    </Card.Body>
                </Card>
            </CollapseBlock>
        );
    }

    renderHeader() {
        return (
            <div>
                { this.renderDateRangeFilter() }
                { this.renderFilterPanel() }
            </div>
        );
    }

    renderPeriod() {
        const from = this.props.model[this.props.from.name];
        const to = this.props.model[this.props.to.name];
        const dtFrom = from ? TableCell.dtFormatter(from) : null;
        const dtTo = to ? TableCell.dtFormatter(to) : null;
        let period = "Период: ";

        if (dtFrom && dtTo) {
            period += `${dtFrom} - ${dtTo}`;
        } else if (dtFrom) {
            period += dtFrom;
        } else if (dtTo) {
            period += dtTo;
        }

        return period;
    }

    renderRowCount() {
        return (
            <Button variant="outline-success"
                    size="sm"
                    onClick={() => this.props.fetchXLS(this.state.data)}
                    disabled={this.state.disabledFetchXLS}>
                <i className="fa fa-download"/> <span>Кол-во записей: {this.state.data.length}</span>
            </Button>
        );
    }

    renderBody() {
        return (
            <Card>
                <Card.Header>
                    <Row>
                        <Col>{this.renderPeriod()}</Col>
                        <Col className={"font-weight-bold h5 text-center"}>{this.props.title}</Col>
                        <Col className={"text-right"}>{this.renderRowCount()}</Col>
                    </Row>
                </Card.Header>
                <Card.Body style={{overflow: "auto"}}>
                    <Table striped bordered hover size="sm">
                        {this.renderTableHeader()}
                        {this.renderTableBody()}
                    </Table>
                </Card.Body>
            </Card>
        );
    }

    renderTableHeader() {
        return (
            <thead className={"font-weight-bold"}>
                <tr>
                    {this.props.columns.map(col => this.renderTh(col))}
                </tr>
            </thead>
        );
    }

    renderTh(col) {
        const sortable = col.sort === false ? "" : "sortable";
        const className = col.className ? col.className + " " + sortable : sortable;
        return (
            <th key={col.name} onClick={() => this.handleSort(col)} className={className}>
                {col.title}
                {this.isSortedBy(col.name, 'asc') && <i className="fas fa-fw fa-arrow-up" />}
                {this.isSortedBy(col.name, 'desc') && <i className="fas fa-fw fa-arrow-down" />}
            </th>
        );
    }

    renderTableRow(row, index) {
        return (
            <tr key={index} onDoubleClick={(e) => this.props?.doubleClickOnTableRow(e, row)}>
                {this.props.columns.map(col => {
                    const key = col.key || col.name;
                    return (
                        <td key={key + '-' + index} className={col.className}>
                            {this.renderTableCell(row, col)}
                        </td>
                    );
                })}
            </tr>
        );
    }

    renderTableCell(row, col) {
        const value = row[col.name];

        if (typeof value === "object" && !Array.isArray(value)) {
            return this.renderObject(col, row);
        }
        return this.renderPrimitive(col, row);
    }

    renderTableBody() {
        let index = 0;
        return (
            <tbody>
                {this.state.data.map(row => this.renderTableRow(row, index++))}
            </tbody>
        );
    }

    renderObject(col, row) {
        const value = row[col.name],
            isDictionary = value && (value.id === 0 || value.id) && value.value;
        if (isDictionary || isDictionary === "") {
            return this.renderPrimitive(col, row, value.value);
        }
        return this.renderPrimitive(col, row);
    }

    renderPrimitive(col, row, value) {
        const valueSafe = value ?? row[col.name];
        return col.formatter ? col.formatter(valueSafe, row) : valueSafe;
    }

    render() {
        return (
            <Form>
                { this.renderHeader() }
                <br></br>
                { this.state.isSending ? <Spinner animation="border" variant="primary" /> : this.renderBody() }
            </Form>
        );
    }
}

const ReportInnerConnected= connect(mapStateToProps)(ReportInner);
export default Report;
