import {GET_BALANCE_FAIL, GET_BALANCE_SUCCESS, MESSAGE_SET, SMS_SEND_FAIL, SMS_SEND_SUCCESS} from "./types";

import SmsService from "../services/SmsService";

export const send = (request) => async (dispatch) => {
    try {
        const response = await SmsService.sendSms(request);
        dispatch({type: SMS_SEND_SUCCESS});
        return Promise.resolve(response);
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) ||
            error.message || error.toString();

        dispatch({type: SMS_SEND_FAIL});
        dispatch({type: MESSAGE_SET, payload: message});
        return Promise.reject();
    }
};

export const balance = () => async (dispatch) => {
    try {
        const data = await SmsService.getBalance();
        dispatch({type: GET_BALANCE_SUCCESS});
        return Promise.resolve(data);
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) ||
            error.message || error.toString();

        dispatch({type: GET_BALANCE_FAIL});
        dispatch({type: MESSAGE_SET, payload: message});
        return Promise.reject();
    }
};
