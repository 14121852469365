
export const SET_BID = 'SET_BID';

export const SET_DATA = 'SET_DATA';
export const SET_ERRORS = 'SET_ERRORS';

export const FETCH_DATA = 'FETCH_DATA';
export const FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS';
export const FETCH_DATA_FAILURE = 'FETCH_DATA_FAILURE';

export const MAKE_BID = 'MAKE_BID';
export const MAKE_BID_SUCCESS = 'MAKE_BID_SUCCESS';
export const MAKE_BID_FAILURE = 'MAKE_BID_FAILURE';