import React, {PureComponent} from "react";
import {Button, Form, Modal} from "react-bootstrap";
import contractorService from "../../services/ContractorService";
import {securityService} from "../../services/SecurityService";
import emailService from "../../services/EmailService";
import Select from "react-select";

class EmailForm extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            sending: false,
            isSent: false,
            contracts: [],
            attachments: []
        }
    }

    static CONTRACTOR = "contractor";
    static CARRIER = "carrier";
    SELECTED_ATTACHMENTS = "selectedAttachments";
    SELECTED_CONTRACTS = "selectedContracts";
    static SENT_TIME_TO_CARRIER = "sentTimeToCarrier"
    static SENT_TIME_TO_CONTRACTOR = "sentTimeToContractor"

    componentDidMount() {
        this.loadData();
    }

    validateEmail(email) {
        const validEmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        return email.match(validEmailRegex);
    }

    loadData() {
        contractorService.getContacts(this.props.contractor.id)
            .then(response => {
                const contacts = response
                    ?.filter(c => c.email !== null)
                    .map(c => ({...c, email: c.email.trim()}));
                if (contacts.length > 0) this.setState({recipient: contacts[0].id});
                this.setState({contacts: contacts});
            });

        contractorService.read(this.props.contractor.id)
            .then(data => this.setState({contracts: data.contracts, attachments: data.attachments}));
    }

    sendEmail() {
        const recipientPersonDto = this.state.contacts.find(c => Number(c.id) === Number(this.state.recipient));

        if (!this.validateEmail(recipientPersonDto.email)) {
            alert("Адрес почты \"" + recipientPersonDto.email + "\" записан не верно");
            return;
        }

        const data = {
            senderUserId: securityService.getUser().id,
            recipientPersonDto: recipientPersonDto,
            subject: this.state.messageSubject,
            text: this.state.messageText,
            quoteId: this.props.quoteId,
            contractorType: this.props.contractorType,
            contractorId: this.props.contractor.id,
            organizationId: this.props.organizationId,
            attachments: this.state[this.SELECTED_CONTRACTS]?.concat(this.state[this.SELECTED_ATTACHMENTS])
        }

        this.setState({sending: true});
        emailService.send(data)
            .then(() => this.setState({isSent: true, sending: false}));
    }

    renderMultiSelect(label, options, stateKey) {
        return <Form.Group>
            <Form.Label>{label}</Form.Label>
            <Select options={options}
                    placeholder="Прикрепить файлы..."
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(selected) => this.setSelectedField(selected, stateKey)}>
            </Select>
        </Form.Group>
    }

    attachmentsToSelectOptions(attachments) {
        return attachments
            .filter(a => !a.removed)
            .map(a => ({value: a.fileName, label: a.originalName}));
    }

    setSelectedField(selected, stateKey) {
        this.setState({[stateKey]: selected?.map(s =>  ({fileName: s.value, originalName: s.label}))});
    }

    render() {
        return <>
            <Modal.Header closeButton>
                <Modal.Title>Отправка документов на почту</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group>
                    <Form.Label>Выберете получателя:</Form.Label>
                    <Form.Control as="select" onChange={event => this.setState({recipient: event.target.value})}>
                        {this.state.contacts?.filter(c => c.email !== null)
                                .map(c => <option value={c.id}>{c.fio + " - " + c.email}</option>)}
                    </Form.Control>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Заголовок сообщения:</Form.Label>
                    <Form.Control type="text" onChange={event => this.setState({messageSubject: event.target.value})}/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Текст сообщения:</Form.Label>
                    <Form.Control as="textarea" rows={4} onChange={event => this.setState({messageText: event.target.value})}/>
                </Form.Group>
                <p>Вложения: Договор-заявка.pdf{this.props.contractorType === EmailForm.CONTRACTOR ? ", Доверенность.pdf" : ""}</p>

                {this.state.contracts.map(contract => this.renderMultiSelect(
                    "Договор № " + contract.number,
                    this.attachmentsToSelectOptions(contract.attachments),
                    this.SELECTED_CONTRACTS
                ))}

                {this.renderMultiSelect(
                    "Вложения: ",
                    this.attachmentsToSelectOptions(this.state.attachments),
                    this.SELECTED_ATTACHMENTS
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={this.state.isSent || this.state.sending || this.state.contacts?.length === 0}
                        variant="primary" onClick={() => this.sendEmail()}>
                    {this.state.sending && <span className="spinner-border spinner-border-sm"></span>}
                    {this.state.isSent ? "Отправлено" : " Отправить"}
                </Button>
            </Modal.Footer>
        </>
    }
}

export default EmailForm;