import React from "react";
import {Button, Col, Dropdown, ProgressBar, Row} from "react-bootstrap";
import DtPicker from "../../form/DtPicker";
import Select from "react-select";

class DatatablaXlsExport extends React.PureComponent {

	constructor(props) {
		super(props);
		this.state = {
			isShow: false,
			isSuccessToDownload: true,
			isDisabledBtn: false
		};
		this.mouseListener = this.mouseListener.bind(this);
		this.onToggle = this.onToggle.bind(this);
		this.dropDown = React.createRef();
		this.divContainer = React.createRef();
		this.onChangeTime = this.onChangeTime.bind(this);
		this.handleExport = this.handleExport.bind(this);
		this.onChangeStatus = this.onChangeStatus.bind(this);
		this.getStatuses = this.getStatuses.bind(this);
	}

	async onToggle() {
		const isShow = !this.state.isShow;
		this.setState({
			isShow: isShow
		});
	}

	componentDidUpdate() {
		this.setState({isSuccessToDownload: this.isReadyToExport()})
	}

	renderProgress() {
		return (
			<React.Fragment>
				<div>{this.props.progressTitle}</div>
				<ProgressBar now={this.props.progress}/>
			</React.Fragment>
		);
	}

	onChangeTime(e, field) {
		field === "fromTime"
			? this.setState({fromTime: e})
			: this.setState({toTime: e})
	}

	getStatuses() {
		const statuses = this.props.getQuoteStatuses()
		return statuses.map(status => {
			return {id: status.id, value: status.value, label: status.value}
		})
	}

	getDifferenceTime() {
		if(this.state.fromTime && this.state.toTime) {
			const startDate = new Date(this.state.fromTime);
			const endDate = new Date(this.state.toTime);

			// Вычисление разницы в миллисекундах
			const diffTime = Math.abs(endDate - startDate);
			// Преобразование миллисекунд в дни
			return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
		}
		return false
	}

	isReadyToExport() {
		return this.state?.fromTime && this.state?.toTime && this.getDifferenceTime() < 31
	}

	async handleExport() {
		this.setState({isSuccessToDownload: this.isReadyToExport()})
		if(this.isReadyToExport()) {
			await this.props.onChangeXlsExportInterval({from: this.state.fromTime, to: this.state.toTime})
			this.props.handleExport()
		}
	}

	onChangeStatus(e) {
		this.setState({status: e})
	}


	renderActions() {
		const isDownloadXls = this.props.isDownloadXls

		return (
			<React.Fragment>
				<div className="text-center mb-3">
					<Button variant="outline-success" disabled={isDownloadXls} size="sm" className="btn-block mb-1 ml-0"
							onClick={this.handleExport}>
						{isDownloadXls ? "Загрузка..." : "Выполнить"}
					</Button>
					<Row className={"mb-1"}>
						<Col className={"pr-0"}>
							<DtPicker
								value={this.state.fromTime}
								placeholder={"От..."}
								isClearable={true}
								name={"fromTime"}
								onChange={e => this.onChangeTime(e, "fromTime")}
							/>
						</Col>
						<Col className={"pl-0"}>
							<DtPicker
								value={this.state.toTime}
								placeholder={"До..."}
								isClearable={true}
								name={"toTime"}
								onChange={e => this.onChangeTime(e, "toTime")}
							/>
						</Col>
					</Row>
					{!this.state.isSuccessToDownload &&
						<div className="alert alert-danger"
							 role="alert">{this.getDifferenceTime() > 30 ? "Период должен быть меньше 31 дня!" : "Заполните период!"}</div>}
					<Row>
						<Col>
							<Select
								placeholder="Выберите статусы"
								name="statuses"
								onChange={this.props.onChangeXlsExportStatuses}
								options={this.getStatuses()}
								isMulti={true}
								hideSelectedOptions={false}
								blurInputOnSelect={false}
								closeMenuOnSelect={false}
								value={this.props.xlsExportStatuses}/>
						</Col>
					</Row>
				</div>
			</React.Fragment>
		);
	}

	mouseListener(e) {
		if (!this.divContainer.current?.contains(e.target) && !this.dropDown.current?.contains(e.target)) {
			this.onToggle();
		}
	}

	addRemoveMouseListener(listen) {
		const addRemove = listen ? document.body.addEventListener : document.body.removeEventListener;
		addRemove('mousedown', this.mouseListener, true);
	}

	calculatePosition() {
		const rect = this.dropDown.current.getBoundingClientRect(),
			pos = {top: rect.y + rect.height, right: rect.x + rect.width};
		return pos;
	}

	createPositionStyle() {
		const pos = this.calculatePosition(),
			width = 280,
			left = pos.right - width;
		return {
			width: `${width}px`,
			top: pos.top + 4,
			left
		};
	}

	render() {
		if (this.state.isShow) {
			this.addRemoveMouseListener(true);
		} else {
			this.addRemoveMouseListener(false);
		}
		return (
			<React.Fragment>
				<Dropdown className="d-inline mx-2" onToggle={this.onToggle}>
					<Dropdown.Toggle variant="outline-success" size="sm" ref={this.dropDown}>
						XLS
					</Dropdown.Toggle>
					{this.state.isShow &&
						<div className='filter text-left' style={this.createPositionStyle()} ref={this.divContainer}>
							{this.props.isBusy === true && this.renderProgress()}
							{this.props.isBusy !== true && this.renderActions()}
						</div>}
				</Dropdown>
			</React.Fragment>
		);
	}
}

export default DatatablaXlsExport;
