import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from "html-to-draftjs";
import ContentState from "draft-js/lib/ContentState";
import {EditorState} from "draft-js";
import {useEffect, useState} from "react";
import React from "react";
import {Form} from "react-bootstrap";

// Функция преображения html разметки в draft

const htmlToDraftBlocks = (html) => {
    const blocksFromHtml = htmlToDraft(html);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
}

function TextEditor (props) {
    const [value, setValue] = useState(null)

    useEffect(() => {
        if(typeof props.value === "string") {
            setValue(htmlToDraftBlocks(props.value))
        } else {setValue(props.value)}
    }, [props.value]);

    const {minHeight, maxHeight} = props;
    return (
        <>
            <div style={{paddingLeft: "5px"}}>
                <Form.Label>{props.title}</Form.Label>
                <Editor
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="redBackground"
                    editorState={value}
                    readOnly={props.readOnly}
                    onEditorStateChange={props.onEditorStateChange}
                    editorStyle={{ "minHeight": minHeight || "150px","maxHeight": maxHeight || "350px", "overflowY": "auto", "background": "white", "padding": "0 10px", "borderRadius": "2px", "border": "1px solid #F1F1F1", "position": "relative" ,"zIndex": "0" }}
                    toolbar={props.emogi ? {
                        options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history'],
                    } : {
                        options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'image', 'remove', 'history'],
                    }}
                />
            </div>
        </>
    );
}

export default TextEditor