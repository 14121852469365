import * as actionTypes from './bidsHistoryActionTypes'
import {initialState} from "./bidsHistoryInitialState";

const bidsHistoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_BIDS:
            return {
                ...state,
                bidsHistory: action.payload
            };
        case actionTypes.SET_ERROR:
            return {
                ...state,
                error: action.payload
            };
        case actionTypes.SET_LOADING:
            return {
                ...state,
                isLoading: action.payload
            };
        case actionTypes.FETCH_DATA:
            return {
                ...state,
                isLoading: true,
                error: null
            };
        case actionTypes.FETCH_DATA_SUCCESS:
            return {
                ...state,
                ...action.payload,
                isLoading: false
            };
        case actionTypes.FETCH_DATA_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            };
        default:
            return state;
    }
}

export default bidsHistoryReducer;