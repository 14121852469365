class FormValidator {

	static PROMISE_OK = Promise.resolve("");
	static OK = "";

	constructor(validationMap) {
		this.validationMap = validationMap || {};
		this.results = {};
	}

	addValidator(field, validator) {
		const arr = this.validationMap[field];
		if (!arr) {
			this.validationMap[field] = validator;
		} else {
			this.validationMap[field] = this.safeArray(arr).concat(validator);
		}
	}

	deleteValidator(field, validator) {
		const arr = this.validationMap[field];
		if (arr) {
			this.validationMap[field] = this.safeArray(arr).filter(item => item != validator);
		}
	}
	
	async validate() {
		this.results = {};
		for (const key of Object.keys(this.validationMap)) {
			const runs = this.validationMap[key];
			const result = [];
			for (const run of this.safeArray(runs)) {
				const res = await run();
				res && !result.includes(res) && result.push(res);
			}
			this.results[key] = result;			
		} 
		return this.isValid();
	}

	async validateField(name) {
		const runs = this.validationMap[name];
		const result = [];
		for (const run of this.safeArray(runs)) {
			const res = await run();
			res && result.push(res);
		}
		this.results[name] = result;
		this.results = {...this.results};
		return !this.hasError(name);
	}
	
	safeArray(arr) {
		return arr 
			? (Array.isArray(arr) ? arr : [arr])
			: [];	
	}
	
	isValid() {
		const errors = Object.keys(this.results).flatMap(key => this.results[key]);
		return errors.length === 0;
	}
	
	isInvalid(key) {
		return this.hasError(key) || FormValidator.OK;
	}
	
	hasError(key) {
		return this.results[key] && this.results[key].length;
	}
}

export default FormValidator;