import React from "react";
import {connect} from 'react-redux';
import {Button, Col, Form, Row, Tab} from "react-bootstrap";
import {NavLink} from "react-router-dom";

import personService from "../../../services/PersonService";
import driverService from "../../../services/DriverService";
import {ADMIN, APPROVAL, securityService} from '../../../services/SecurityService';
import {
	innValidator,
	phoneValidator,
	requiredValidator,
	licenseValidator,
	snilsValidator,
	emailValidator,
	passportNumberValidator
} from "../../../validators/simpleValidators";
import StoreWrapper from '../../form/StoreWrapper';
import BaseForm from '../../form/BaseForm';
import FormGroup from '../../form/FormGroup';
import {setData} from '../../../actions/form';
import Loading from '../../Loading';
import FormValidator from '../../../validators/FormValidator';
import { APPROVAL_STATUSES, ENUM_NAME } from '../../../Enums';
import { DRIVER } from '../../AuditTable/AuditTable';
import FileUploader from '../../FileUploader';
import Util from "../../../Util";
import {setToastObjAC} from "../../../reducers/toastObj";
import WarningMessage from "../../form/WarningMessage";
import CrmUserInnerDriver from "./CrmUserInnerDriver";

function mapGlobalStateToProps(state) {
	return {}
}

class Driver extends StoreWrapper {

	static get DEFAULT_APPROVAL_STATUS() { return { id: 0, value: "NEW" } };

	static get FIELD_ID() { return 'id' };
	static get FIELD_PERSON_ID() { return 'personId' };
	static get FIELD_LAST_NAME() { return 'lastName' };
	static get FIELD_FIRST_NAME() { return 'firstName' };
	static get FIELD_MIDDLE_NAME() { return 'middleName' };
	static get FIELD_PHONE() { return 'phone' };
	static get FIELD_EMAIL() { return 'email' };
	static get FIELD_BIRTHDAY() { return 'birthday' };
	static get FIELD_PASSPORT_NUMBER() { return 'passportNumber' };
	static get FIELD_PASSPORT_ISSUED_BY() { return 'passportIssuedBy' };
	static get FIELD_PASSPORT_ISSUED_DATE() { return 'passportIssuedDate' };
	static get FIELD_LICENCE() { return 'license' };
	static get FIELD_LICENCE_ISSUED_DATE() { return 'licenseIssuedDate' };
	static get FIELD_INN() { return 'inn' };
	static get FIELD_SNILS() { return 'snils' };
	static get FIELD_COMMENT() { return 'comment' };
	static get FIELD_ACTIVE() { return 'active' };
	static get FIELD_FOREIGNER() { return 'foreigner' };
	static get FIELD_APPROVAL_STATUS() { return 'approvalStatus' };
	static get FIELD_ATTACHMENTS() { return 'attachments' };
	static get FIELD_CONTRACTORS() { return 'contractors' };

	constructor(props) {
		super(props);
	}

	render() {
		return (
			<DriverInnerConnected {...this.props} store={this.store} />
		);
	}
}

function mapStateToProps(state, ownProps) {
	let model = state.model;

	if (ownProps.isInner === true && !model.driver) {
		model.driver = {};
	}

	return {
		errors: state.errors,
		model: model
	};

}
class DriverInner extends BaseForm {
	constructor(props) {
		super(props);
		this.configureValidators();

		this.onChangeActive = this.onChangeActive.bind(this);
		this.onChangeForeigner = this.onChangeForeigner.bind(this);
		this.applyInternalValidator = this.applyInternalValidator.bind(this);
		this.handleUpdate = this.handleUpdate.bind(this);
		this.afterSubmit = this.afterSubmit.bind(this)
	}

	configureValidators() {
		this.useValidatorFor(
			requiredValidator, this.formatFieldName(Driver.FIELD_LAST_NAME),
			this.formatFieldName(Driver.FIELD_PASSPORT_NUMBER), this.formatFieldName(Driver.FIELD_LICENCE),
			this.formatFieldName(Driver.FIELD_PASSPORT_ISSUED_BY), this.formatFieldName(Driver.FIELD_PASSPORT_ISSUED_DATE)
		);
		this.useValidatorFor(innValidator, this.formatFieldName(Driver.FIELD_INN));
		this.useValidatorFor(licenseValidator, this.formatFieldName(Driver.FIELD_LICENCE));
		this.useValidatorFor(snilsValidator, this.formatFieldName(Driver.FIELD_SNILS));
		this.useValidatorFor(emailValidator, this.formatFieldName(Driver.FIELD_EMAIL));
		this.useValidatorFor(passportNumberValidator, this.formatFieldName(Driver.FIELD_PASSPORT_NUMBER));
		this.useValidatorFor(this.applyInternalValidator(phoneValidator.bind(this)), "phone");
		this.validator.addValidator(this.formatFieldName(Driver.FIELD_EMAIL), this.emailUniqueValidator.bind(this));
		this.validator.addValidator(this.formatFieldName(Driver.FIELD_PHONE), this.phoneUniqueValidator.bind(this));
		this.validator.addValidator(this.formatFieldName(Driver.FIELD_PASSPORT_NUMBER), this.passportNumberUniqueValidator.bind(this));
		this.validator.addValidator(this.formatFieldName(Driver.FIELD_LICENCE), this.licenseUniqueValidator.bind(this));
		this.validator.addValidator(this.formatFieldName(Driver.FIELD_INN), this.innUniqueValidator.bind(this));
		this.validator.addValidator(this.formatFieldName(Driver.FIELD_EMAIL), this.emailOrPhoneRequiredValidator.bind(this));
	}

	applyInternalValidator(validator) {
		return value => this.execInternalValidator(validator, value);
	}

	async execInternalValidator(validator, value) {
		return this.props.model.foreigner ? FormValidator.OK : validator(value);
	}

	emailOrPhoneRequiredValidator() {
		const isValid = this.props.isInner || !!this.props.model.email || !!this.props.model.phone;
		return Promise.resolve(isValid ? FormValidator.OK : "укажите email или телефон");
	}

	async emailUniqueValidator() {
		const email = this.props.model.email;
		if (!email) {
			return FormValidator.OK;
		}
		const data = await personService.isEmailUnique(this.props.model.personId, email);
		return data.unique ? FormValidator.OK : "этот email уже зарегистрирован в системе " + data.info;
	}

	async passportNumberUniqueValidator() {
		const {passportNumber} = this.props.model;
		const formatted = passportNumber?.replace(/\D/g, '');
		const result = await this.uniquePersonValidator(
			personService.isPassportNumberUnique,
			formatted,
			"этот паспорт уже зарегистрирован в системе"
		);
		const isBanned = await driverService.checkBlacklistDriver(this.props.model.id, this.props.model.passportNumber);
		return isBanned ? "водитель с таким паспортом в черном списке" : result;
	}

	async phoneUniqueValidator() {
		if (!this.props.model.phone) {
			return FormValidator.PROMISE_OK;
		}
		const data = await personService.isPhoneUnique(this.props.model.personId, this.props.model.phone);
		return data.unique
			? FormValidator.OK
			: this.renderUniqueValidatorMessage(data);
	}

	renderUniqueValidatorMessage(data) {
		if (this.props.model.id) {
			return <span> этот телефон уже зарегистрирован в системе </span>;
		} else {
			return (
				<div>
					<div>
						<p> этот телефон уже зарегистрирован в системе </p>
						<p> { data.info } </p>
						<p> перенести данные из имеющегося контакта? </p>
					</div>
					<Button onClick={() => this.handleUpdate()} size="sm" variant="primary" className="btn-xs"><span>Принять</span></Button>
					<span> или </span>
					<Button onClick={() => this.onChange("phone", "")} size="sm" variant="danger" className="btn-xs"><span>Отклонить</span></Button>
				</div>
			);
		}
	}

	async innUniqueValidator() {
		return this.uniquePersonValidator(
			personService.isInnUnique,
			this.props.model.inn,
			"этот ИНН уже зарегистрирован в системе"
		);
	}

	async licenseUniqueValidator() {
		const license = this.props.model.license;
		if (!license) {
			return FormValidator.PROMISE_OK;
		}
		const isValid = await driverService.isLicenseUnique(this.props.model.id, license);
		return isValid ? FormValidator.OK : "это вод. удостоверение уже зарегистрировано в системе";
	}

	async uniquePersonValidator(serviceFn, value, nonUniqueMessage) {
		if (!value) {
			return FormValidator.PROMISE_OK;
		}
		const isValid = await serviceFn(this.props.model.personId, value);
		return isValid ? FormValidator.OK : nonUniqueMessage;
	}

	getDriverId() {
		if (this.props.isInner === true) {
			return this.props.model.driver.id;
		}

		return this.props.location.state?.id
			|| this.props.model?.id
			|| new URLSearchParams(this.props.location.search).get('id');
	}

	handleUpdate() {
		personService.readByPhone(this.props.model.phone).then(person => {
			this.onChange(this.formatFieldName(Driver.FIELD_PERSON_ID), person.id);
			this.onChange(this.formatFieldName(Driver.FIELD_FIRST_NAME), person.firstName);
			this.onChange(this.formatFieldName(Driver.FIELD_LAST_NAME), person.lastName);
			this.onChange(this.formatFieldName(Driver.FIELD_MIDDLE_NAME), person.middleName);
			this.onChange(this.formatFieldName(Driver.FIELD_EMAIL), person.email);
			this.onChange(this.formatFieldName(Driver.FIELD_PHONE), person.phone);
			this.onChange(this.formatFieldName(Driver.FIELD_PASSPORT_NUMBER), person.passportNumber);
			this.onChange(this.formatFieldName(Driver.FIELD_PASSPORT_ISSUED_BY), person.passportIssuedBy);
			this.onChange(this.formatFieldName(Driver.FIELD_PASSPORT_ISSUED_DATE), person.passportIssuedDate);
			this.onChange(this.formatFieldName(Driver.FIELD_INN), person.inn);
			this.onChange(this.formatFieldName(Driver.FIELD_SNILS), person.snils);
			this.onChange(this.formatFieldName(Driver.FIELD_BIRTHDAY), person.birthday);
			this.onChange(this.formatFieldName(Driver.FIELD_COMMENT), person.comment);
			this.onChange(this.formatFieldName(Driver.FIELD_CONTRACTORS), person.contractors);
		});
	}

	formatFieldName(field) {
		return this.props.isInner ? `driver.${field}` : field;
	}

	load() {
		if (!this.props.isInner) {
			const id = this.getDriverId();
			if (id) {
				driverService.read(id).then(data => {
					this.props.store.dispatch(setData(data, this.props.location.state?.action));
				});
			} else {
				this.onChange(this.formatFieldName("approvalStatus"), Driver.DEFAULT_APPROVAL_STATUS);
			}
		} else {
			if (!this.getDriverId()) {
				this.onChange("driver", {});
				this.onChange(this.formatFieldName("approvalStatus"), Driver.DEFAULT_APPROVAL_STATUS);
			}
		}
	}

	renderWarning() {
		return (
			<WarningMessage
				variant="blue"
				message={"После внесения изменений, элемент справочника нельзя будет выбрать в заявке до проверки службы безопасности."}
			/>
		);
	}

	renderFioRow() {
		return (
			<Row>
				<Col lg={4} md={12}>
					<FormGroup title="Фамилия" name={this.formatFieldName(Driver.FIELD_LAST_NAME)} store={this.props.store} required />
				</Col>
				<Col lg={4} md={12}>
					<FormGroup title="Имя" name={this.formatFieldName(Driver.FIELD_FIRST_NAME)} store={this.props.store} />
				</Col>
				<Col lg={4} md={12}>
					<FormGroup title="Отчество" name={this.formatFieldName(Driver.FIELD_MIDDLE_NAME)} store={this.props.store} />
				</Col>
			</Row>
		);
	}

	renderPassportRow() {
		return (
			<Row>
				<Col lg={3} md={12}>
					<FormGroup title="Номер паспорта" mask={"1111 111111"} name={this.formatFieldName(Driver.FIELD_PASSPORT_NUMBER)} store={this.props.store} maxLength={10} required />
				</Col>
				<Col lg={1} md={12}>
					<Col>
						<Form.Group>
							<Form.Label>Паспорт не РФ</Form.Label>
							<Form.Check id="foreigner" name={this.formatFieldName(Driver.FIELD_FOREIGNER)} defaultChecked={this.props.model.foreigner} onClick={this.onChangeForeigner} />
						</Form.Group>
					</Col>
				</Col>
				<Col lg={4} md={12}>
					<FormGroup title="Дата выдачи паспорта" type="date" name={this.formatFieldName(Driver.FIELD_PASSPORT_ISSUED_DATE)}  store={this.props.store} required/>
				</Col>
				<Col lg={4} md={12}>
					<FormGroup title="Кем выдан паспорт" name={this.formatFieldName(Driver.FIELD_PASSPORT_ISSUED_BY)}  store={this.props.store} required/>
				</Col>
			</Row>
		);
	}

	renderDocumentsRow() {
		return (
			<React.Fragment>
				<Row>
					<Col lg={4} md={12}>
						<FormGroup title="Вод. удостоверение" mask={"1111 111111"} name={this.formatFieldName(Driver.FIELD_LICENCE)} store={this.props.store} maxLength={10} required />
					</Col>
					<Col lg={4} md={12}>
						<FormGroup title="Дата выдачи вод. удостоверения" type="date" name={this.formatFieldName(Driver.FIELD_LICENCE_ISSUED_DATE)} store={this.props.store} />
					</Col>
					<Col lg={4} md={12}>
						<FormGroup title="Дата рождения" type="date" name={this.formatFieldName(Driver.FIELD_BIRTHDAY)} store={this.props.store}/>
					</Col>
				</Row>
				<Row>
					<Col lg={4} md={12}>
						<FormGroup title="ИНН" name={this.formatFieldName(Driver.FIELD_INN)} store={this.props.store} maxLength={12}/>
					</Col>
					<Col lg={4} md={12}>
						<FormGroup title="СНИЛС" mask={"111-111-111 11"} name={this.formatFieldName(Driver.FIELD_SNILS)} store={this.props.store} maxLength={11}/>
					</Col>
				</Row>
			</React.Fragment>
		);
	}

	renderContactsRow() {
		return (
			<Row>
				<Col lg={4} md={12}>
					<FormGroup title="Email" type="email" name={this.formatFieldName(Driver.FIELD_EMAIL)} store={this.props.store} />
				</Col>
				<Col lg={4} md={12}>
					<FormGroup title="Телефон" type={this.formatFieldName(Driver.FIELD_PHONE)} name={this.formatFieldName(Driver.FIELD_PHONE)} store={this.props.store} />
				</Col>
			</Row>
		);
	}

	onChangeActive(e) {
		this.onChange(this.formatFieldName(Driver.FIELD_ACTIVE), e.target.checked);
	}

	onChangeForeigner(e) {
		this.onChange(this.formatFieldName(Driver.FIELD_FOREIGNER), e.target.checked);
	}

	readOnlyApprovalStatus() {
		if (securityService.hasRole(this.props.user, APPROVAL) && this.props.model?.approvalStatus?.value === APPROVAL_STATUSES.BLACK_LIST.value) {
			return true;
		}
		return !securityService.anyRoles(this.props.user, ADMIN, APPROVAL);
	}

	renderCheckboxesRow() {
		return (
			<Row>
				<Col lg={4} md={12}>
					<FormGroup title="Контрагенты"
					   placeholder="Контрагенты"
					   name={this.formatFieldName(Driver.FIELD_CONTRACTORS)}
					   store={this.props.store}
					   type="dictionary"
					   optionsType="CONTRACTOR_CARRIER"
					   multiSelect={true}
					/>
				</Col>
				<Col lg={4} md={12}>
					<FormGroup title="Согласование"
					   name={this.formatFieldName(Driver.FIELD_APPROVAL_STATUS)}
					   type="enum"
					   optionsType={ENUM_NAME.APPROVAL_STATUS}
					   readOnly={this.readOnlyApprovalStatus()}
					   store={this.props.store}
					/>
				</Col>
				<Col lg={4} md={12}>
					<Col>
						<Form.Group>
							<Form.Label>{this.props.isInner ? 'Водитель Активен' : 'Активен'}</Form.Label>
							<Form.Check id="active" name={this.formatFieldName(Driver.FIELD_ACTIVE)} defaultChecked={this.props.model.active} onChange={this.onChangeActive} />
						</Form.Group>
					</Col>
				</Col>
			</Row>
		);
	}

	renderExternalUserLink() {
		const {crmUser} = this.props.model;
		return (
			<div className="text-center">
				<NavLink
					to={`/control-panel/external-users/user?${crmUser?.id ? `id=${crmUser?.id}` : `driverId=${this.props.model.id}`}`}
					style={{zIndex: 10, pointerEvents: "all"}}>
					<i className="link fas fa-fw fa-link"></i><span>Учетная запись водителя в CRM</span>
				</NavLink>
			</div>
		);
	}

	getDriversName() {
		return (this.props.model.lastName || "") + "_" + (this.props.model.firstName || "") + "_" + (this.props.model.middleName || "");
	}

	renderUploadFormRow() {
		return (
			<Row>
				<Col lg={6} md={12}>
					<FileUploader
						store={this.props.store}
						name={this.formatFieldName(Driver.FIELD_ATTACHMENTS)}
						title="Сканкопии документов"
						fileNameFunction={(attachment, index) => Util.formatFileName(attachment.originalName, "Водитель_" + this.getDriversName(), index)}
					/>
				</Col>
				<Col lg={6} md={12}>
					<FormGroup title="Комментарий" name={this.formatFieldName(Driver.FIELD_COMMENT)} store={this.props.store} type="textarea" rows={3} />
				</Col>
			</Row>
		);
	}

	handleSubmit(e) {
		e.preventDefault();
		this.submit(() =>
			driverService.save(this.props.model).then(res => this.afterSubmit(res)));
	}

	onCancel() {
		this.redirectToBackOrDefaultUrl('/control-panel/drivers')
	}

	afterSubmit(res) {
		const data = {show: true, textHeader: "Водитель сохранен!", delay: 3000};
		if(!this.props.model.id) {
			this.redirectToBackOrDefaultUrl({payload: '/drivers/driver', state:{id: res.id}});
			data.textHeader = "Водитель создан!"
		}
		this.props.setToastObjAC(data)
		this.onChange('version', res.version);
		this.load();
	}

	getTabs() {
		if (!this.props.isInner) {
			const tabs = [];
			if (this.getDriverId()) {
				tabs.push(this.getAuditTab(this.getDriverId(), DRIVER));
				tabs.push(this.getAccountTab());
			}
			return tabs;
		}
	}

	getAccountTab() {
		const {crmUser} = this.props.model;
		const user = securityService.getUser();
		const isCarrier = securityService.isCarrier(user);
		if (!isCarrier || (!crmUser && this.state.activeTab !== "account")) {
			return;
		}
		return (
			<Tab key="account" eventKey="account" title="Учетная запись">
				<CrmUserInnerDriver {...this.props} load={() => this.load()} setToast={data => this.props.setToastObjAC(data)}/>
			</Tab>
		);
	}

	getFormTabTitle() {
		const user = this.props.model.lastName || (this.props.model.id > 0 ? this.props.model.id : '');
		return "Водитель " + user;
	}

	renderCreateAccountButton() {
		const user = securityService.getUser();
		const isCarrier = securityService.isCarrier(user);
		if (!isCarrier || this.props.model.crmUser) {
			return;
		}
		return (
			<Button size="sm" variant="primary" onClick={() => this.setState({activeTab: "account"})}>
				Создать учетную запись водителя
			</Button>
		);
	}

	renderForm() {
		let isReady = this.props.isInner ? this.getDriverId() : !this.getDriverId() || this.props.model.id;
		if (!isReady) {
			return (<Loading/>);
		}
		const renderRows = () => {
			return (
				<React.Fragment>
					{!this.props.isInner && this.renderWarning()}

					{!this.props.isInner && this.renderFioRow()}

					{!this.props.isInner && this.renderContactsRow()}

					{this.renderPassportRow()}

					{this.renderDocumentsRow()}

					{this.renderCheckboxesRow()}

					{this.renderUploadFormRow()}
				</React.Fragment>
			)
		};
		return (
			{...this.props.isInner
					? {...renderRows()}
					: <Form>
						{renderRows()}
						<Row className={"justify-content-center"}>
							{this.renderSaveCancelButtons()}
							&nbsp;
							{this.renderCreateAccountButton()}
						</Row>
						<br/>
						{securityService.isAdmin(securityService.getUser()) && this.renderExternalUserLink()}
					</Form>
			}
		);
	}
}

const DriverInnerConnected = connect(mapStateToProps)(DriverInner);
export {DriverInnerConnected as DriverInner};
export default connect(mapGlobalStateToProps, {setToastObjAC})(Driver)
