import React from "react";
import { Button } from "react-bootstrap";
import {securityService} from "../../services/SecurityService";

class RouteTableButtons extends React.PureComponent {

	constructor(props) {
		super(props);
		this.addLoading = this.addLoading.bind(this);
		this.addUnloading = this.addUnloading.bind(this);
		this.addCustoms = this.addCustoms.bind(this);
	}

	addLoading() {
		this.props.addLoading();
	}

	addUnloading() {
		this.props.addUnloading();
	}

	addCustoms() {
		this.props.addCustoms();
	}

	render() {
		const isClient = securityService.isClient();
		return (
			<span className="add-links">
				<Button variant="outline-primary" size="sm" onClick={this.addLoading} disabled={isClient}>
					<i className="fas fa-plus mr-1"></i>
					Загрузка
				</Button>
				<Button variant="outline-primary" size="sm" onClick={this.addCustoms} disabled={isClient}>
					<i className="fas fa-plus mr-1"></i>
					Таможня
				</Button>
				<Button variant="outline-primary" size="sm" onClick={this.addUnloading} disabled={isClient}>
					<i className="fas fa-plus mr-1"></i>
					Разгрузка
				</Button>
			</span>
		);
	}
}

export default RouteTableButtons;