import { NOTIFICATION_SET, NOTIFICATION_CLEAR } from "../actions/types";

const initialState = {};

export default function(state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case NOTIFICATION_SET:
			return { notification: payload };

		case NOTIFICATION_CLEAR:
			return { notification: "" };

		default:
			return state;
	}
}
