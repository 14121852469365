import {axios} from '../../axios';

class SurveyService {

    static BASE_PATH = "/api/v1/survey";
    static ADMIN_BASE_PATH = "/api/v1/survey/admin";

    constructor(server) {
        this.server = server;
    }

    async read(id) {
        const response = await axios.get(SurveyService.BASE_PATH + `/${id}`);
        return response.data;
    }

    async getForm(type) {
        const response = await axios.get(SurveyService.BASE_PATH + `/form-data/${type}`);
        return response.data;
    }

    async list(params) {
        const response = await axios.post(SurveyService.BASE_PATH + "/list", params);
        return response.data;
    }

    async save(surveyId, surveyRequest) {
        return surveyId ? await this.update(surveyId, surveyRequest) : await this.create(surveyRequest);
    }

    async create(survey) {
        const response = await axios.post(SurveyService.BASE_PATH + "/create", survey);
        return response.data;
    }

    async update(surveyId, body) {
        const response = await axios.put(SurveyService.BASE_PATH + `/${surveyId}/update`, body);
        return response.data;
    }

    async adminUpdate(surveyId, surveyRequest, adminRequest) {
        const response = await axios.put(SurveyService.ADMIN_BASE_PATH + `/${surveyId}/update`, {surveyRequest, adminRequest});
        return response.data;
    }

    async updateStatus(surveyId, status) {
        const response = await axios.put(SurveyService.BASE_PATH + `/${surveyId}/update/status/${status}`);
        return response.data;
    }
}

export default new SurveyService();