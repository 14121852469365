import React, {PureComponent} from "react";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import "./OverlayHint.css"
import {ReactComponent as QuestionCircle} from "./icon/question-circle.svg";

class OverlayNumberHint extends PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={this.renderTooltip()}>
                <Button  variant={"link"} size={"sm"} className={"shadow-none"} style={{fontSize:"0px"}}>
                    <QuestionCircle className={"mb-1"}/>
                </Button>
            </OverlayTrigger>
        )
    }

    renderTooltip() {
        return (
            <Tooltip id="question-tooltip">
                <span className={"d-flex text-left"}>
                    A123AA(12)<br/>
                    A123AA(123)<br/>
                    AA123(12)<br/>
                    AA123(123)<br/>
                    AA1234(12)<br/>
                    AA1234(123)<br/>
                </span>

            </Tooltip>
        );
    }
}

export default OverlayNumberHint;
