import React from "react";
import { connect } from 'react-redux';

import originalAddressService from '../../../services/OriginalAddressService';
import DataTable from '../../table/DataTable';
import BaseForm from "../../form/BaseForm";

class OriginalAddresses extends BaseForm {

    constructor(props) {
        super(props);
    }

    fetch(params) {
        params.normalizedList = this.isNormalizedList();
        return originalAddressService.list(params);
    }

    openEditForm(row) {
        this.props.history.push({pathname: this.getPathname(), state: { id: row.id }});
    }

    getPathname() {
        return this.isNormalizedList() ? "/control-panel/normalized-original-addresses/original-address" : "/control-panel/original-addresses/original-address";
    }

    isNormalizedList() {
        return this.props.isNormalizedList;
    }

    render() {
        return (
            <DataTable {...this.props}
                       title = {this.isNormalizedList() ? "Распознанные адреса" : "Нераспознанные адреса"}
                       sort = {this.isNormalizedList() ?  { field: "id", dir: "asc" } : { field: "usageCount", dir: "desc" }}
                       fetch = {this.fetch.bind(this)}
                       openEditForm = {this.openEditForm.bind(this)}
                       columns = {[
                           { title: "#", field: "id", className: "id" },
                           { title: "Контрагент", field: "contractor", filter: DataTable.DICTIONARY_FILTER, optionsType: "CONTRACTOR_CLIENT", activeOnly: false, sortable: false },

                           { title: "Город", field: "city", filter: DataTable.STRING_FILTER, sortable: false },
                           { title: "Адрес", field: "fullAddress", filter: DataTable.STRING_FILTER, sortable: false },

                           { ...this.isNormalizedList() && { title: "Город соответствия", field: "normalizedCity", filter: DataTable.STRING_FILTER, sortable: false }},
                           { ...this.isNormalizedList() && { title: "Адрес соответствия", field: "normalizedFullAddress", filter: DataTable.STRING_FILTER, sortable: false }},

                           { ...!this.isNormalizedList() && { title: "Использование в заявках", field: "usageCount" }},

                       ].filter(col => { return Object.keys(col).length !== 0 })}
            />
        );
    }
}

export default connect(DataTable.mapStateToProps)(OriginalAddresses);