import StoreWrapper from "../../form/StoreWrapper";
import BaseForm from "../../form/BaseForm";
import Loading from "../../Loading";
import {Col, Form, Row} from "react-bootstrap";
import FormGroup from "../../form/FormGroup";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import React from "react";
import {requiredValidator} from "../../../validators/simpleValidators";
import {setData} from "../../../actions/form";
import vehicleModelService from "../../../services/VehicleModelService";
import vehicleMarkService from "../../../services/VehicleMarkService";
import {setToastObjAC} from "../../../reducers/toastObj";


function mapGlobalStateToProps(state, ownProps) {
}

class Model extends StoreWrapper {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <ModelInnerConnected {...this.props} store={this.store}/>
        );
    }
}

function mapStateToProps(state) {
    return {
        model: state.model,
        errors: state.errors
    };
}

class ModelInner extends BaseForm {

    constructor(props) {
        super(props);
        this.configureValidators();
    }

    configureValidators() {
        this.useValidatorFor(requiredValidator, "mark", "value");
    }

    getId() {
        return this.props.location.state?.id || new URLSearchParams(this.props.location.search).get('id');
    }
    load() {
        const id = this.getId();
        if (id) {
                vehicleModelService.read(id).then(data => {
                this.props.store.dispatch(setData(data, this.props.location.state?.action));
            });
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        this.submit(() =>
            vehicleModelService.save(this.props.model)
                .then(() => this.props.history.push(this.props.pathname)));
    }

    getTabs() {
        const tabs = [];
        if (this.getId()) {
            tabs.push(this.getAuditTab(this.getId(), this.props.auditType));
        }
        return tabs;
    }

    getFormTabTitle() {
        const name = this.props.model.value || (this.props.model.id > 0 ? this.props.model.id : '');
        return this.props.tabTitle + " " + name;
    }

    renderForm() {
        const isReady = !this.getId() || this.props.model.id;
        if (!isReady) {
            return (<Loading/>);
        }
        return (
            <Form>
                <Row>
                    <Col lg={6} md={12}>
                        <FormGroup title="Привязка к марке"
                                   name="mark"
                                   type="vehicleMarkModel"
                                   optionsType="VEHICLE_MARK"
                                   fetchOptions={vehicleMarkService.options}
                                   store={this.props.store}
                                   required
                        />
                    </Col>
                    <Col lg={6} md={12}>
                        <FormGroup
                            title="Наименование"
                            name="value"
                            store={this.props.store}
                            required />
                    </Col>
                </Row>
                {this.renderSaveCancelButtons()}
            </Form>
        );
    }
}

const ModelInnerConnected = withRouter(connect(mapStateToProps)(ModelInner));

export default connect(mapGlobalStateToProps, {setToastObjAC})(Model);