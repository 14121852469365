import {axios} from '../axios';

class OrganizationService {

	static BASE_PATH = "/api/control-panel/organizations";

	async list(params) {
		const response = await axios.post(`${OrganizationService.BASE_PATH}/list`, params);
		return response.data;
	}

	async read(id) {
		const response = await axios.get(`${OrganizationService.BASE_PATH}/${id}`);
		return response.data;
	}

	async save(organization) {
		const call = organization.id ? axios.put : axios.post,
			response = await call(OrganizationService.BASE_PATH, organization);
		return response.data;
	}

	async isValueUnique(id, value) {
		const response = await axios.post(`${OrganizationService.BASE_PATH}/value/unique`, { id, value: value });
		return response.data;
	}

	async isInnUnique(id, value) {
		const response = await axios.post(`${OrganizationService.BASE_PATH}/inn/unique`, { id, value: value });
		return response.data;
	}

	async saveDocumentAttachments(id, documentAttachments) {
		const response = await axios.post(`${OrganizationService.BASE_PATH}/${id}/document-attachments`, documentAttachments);
		return response.data;
	}

	//	TODO: remove after init
	async init() {
		const response = await axios.post(`${OrganizationService.BASE_PATH}/init/document-attachments`);
		return response.data;
    }

	async isLocked(id) {
		const response = await axios.get(`${OrganizationService.BASE_PATH}/${id}/is-locked`);
		return response.data;
	}
}

export default new OrganizationService();