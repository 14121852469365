import BaseSelect from "../BaseSelect";
import taskService from "../../../services/tasktracker/TaskService";

class TaskSelect extends BaseSelect {
	constructor(props) {
		super(props);
	}

	static get PARENT_SELECT() { return 'parent' };
	static get PREVIOUS_SELECT() { return 'previous' };

	fetchOptions() {
		switch (this.props.taskType) {
			case TaskSelect.PARENT_SELECT:
				return taskService.options();
			case TaskSelect.PREVIOUS_SELECT:
				return taskService.loadParentSubtasks(this.props.currentTaskId, this.props.parentTaskId);
		}
	}

	getViewLink() {
		if (this.props.id) {
			return "/task-tracker/task?id=" + this.props.id;
		}
		return super.getViewLink();
	}
}

export default TaskSelect;