import React from "react";
import Select, { components } from "react-select";
import { NavLink } from 'react-router-dom';

class BaseSelect extends React.PureComponent {

	static get MENU_CLASS_NAME() { return "base-select__menu-list";}

	constructor(props) {
		super(props);
		this.state = {
			options: [],
			loading: false
		}
		this.loaded = false;
		this.load = this.load.bind(this);
		this.onChange = this.onChange.bind(this);
		this.getCloseMenuOnScroll = this.getCloseMenuOnScroll.bind(this);

		this.createStyles();
	}

	createStyles() {
		this.customStyles = {
			menu: (base) => ({
				...base,
				width: "max-content",
				maxWidth: "380px",
				minWidth: "100%"
			}),
			singleValue: (provided, state) => ({
				...provided,
				opacity: 1,
				color: state.isDisabled ? 'hsl(0,0%, 50%)' : 'black',
				transition: 'opacity 300ms',
				...this.getSingleValueStyleOverrides(provided, state)
			}),
			...(this.props.multiSelect && {
				control: (provided, state, q) => ({
					...provided,
					border: state.selectProps.isInvalid ? 'solid 1px red' : provided.border
				})
			}),
			...(!this.props.multiSelect && {
				control: (provided, state, q) => ({
					...provided,
					width: '100%',
					height: 'calc(1.5em + .5rem + 2px)',
					padding: '.25rem .5rem',
					fontSize: '.75rem',
					borderRadius: '.2rem',
					minHeight: 'auto',
					border: state.selectProps.isInvalid ? 'solid 1px red' : provided.border
				}),
				valueContainer: (provided) => ({
					...provided,
					top: '-8px',
				}),
				indicatorsContainer: (provided) => ({
					...provided,
					position: 'relative',
					top: '-8px',
				})
			})
		};
	}

	getSingleValueStyleOverrides(provided, state) {
		// implement in child class if there style overrides
	};
	
	fetchOptionByValue() {
		// implement in child class
	}

	fetchOptions() {
		// implement in child class	
	}

	load() {
		if (!this.loaded) {
			this.setState({ loading: true });
			this.fetchOptions().then(options => {
				this.loaded = true;
				const data = this.props.all ? [{id: -1, value: this.props.all}, ...options] : options;
				this.setState({ loading: false, options: this.props.optionsFilter ? data.filter(this.props.optionsFilter) : data });
			});
		}
	}

	onChange(option) {
		this.props.onChange(option);
	}

	getViewLink() {
		return "/";
	}

	renderCustomButton() {
		return <React.Fragment>
			&nbsp;{this.props.renderCustomButton()}
		</React.Fragment>;
	}

	renderViewIcon() {
		return <React.Fragment>
			&nbsp;<NavLink target={"_blank"} to={this.getViewLink()} style={{zIndex: 10, pointerEvents: "all"}}><i className="link fas fa-fw fa-link"></i></NavLink>
		</React.Fragment>;
	}

	renderLabel(value) {
		return value;
	}

	getCloseMenuOnScroll(event) {
		return this.props.closeMenuOnScroll && (!event.target.classList || !(event.target.classList.contains(BaseSelect.MENU_CLASS_NAME)));
	}

	filterOption(option, inputValue) {
		const label = option.label.toLowerCase();
		const value = option.data.value.toLowerCase();
		const input = inputValue.toLowerCase();

		return label.includes(input) || value.includes(input);
	}

	render() {
		return (
			<React.Fragment>
				{this.props.showViewIcon && this.props.value && this.renderViewIcon()}
				{this.props.renderCustomButton && this.renderCustomButton()}
				<Select
					value={this.props.value}
					isSearchable={true}
					onBlurResetsInput={false}
					onCloseResetsInput={false}
					isLoading={this.state.loading}
					options={this.state.options}
					onFocus={this.load}
					styles={this.customStyles}
					loadingMessage={() => "загрузка"}
					noOptionsMessage={() => "нет данных"}
					placeholder={this.props.placeholder || Option.EMPTY_VALUE}
					onChange={this.onChange}
					isInvalid={this.props.isInvalid}
					getOptionLabel={option => this.renderLabel(option.value)}
					getOptionValue={option => option.id}
					isDisabled={this.props.readOnly}
					menuPosition={this.props.menuPosition}
					closeMenuOnScroll={this.getCloseMenuOnScroll}
					components={{ MenuList, Option }}
					isMulti={this.props.multiSelect}
					hideSelectedOptions={false}
					blurInputOnSelect={this.props.multiSelect ? false : true}
					closeMenuOnSelect={this.props.multiSelect ? false : true}
					backspaceRemovesValue={false}
					filterOption={this.filterOption}
				/>
			</React.Fragment>
		);
	}
}

class MenuList extends React.PureComponent {

	// to overlap parent with "position: relative" and "overflow: scroll/hidden"
	// this componenet is used in combination with menuPosition = {"fixed"} and closeMenuOnScroll returning true
	// (because of display specifics of "fixed" positioned element)

	constructor(props) {
		super(props);
	}

	render() {
		const {children, ...otherProps} = this.props;
		return (
			<components.MenuList className={BaseSelect.MENU_CLASS_NAME} {...otherProps}>
				{children}
			</components.MenuList>
		);
	}
}

class Option extends React.PureComponent {
	constructor(props) {
		super(props);
	}

	static get EMPTY_VALUE() { return '(выбрать)'};

	render() {
		const { isMulti, isSelected, label, ...otherProps } = this.props;
		const isNoCoordinate = this.props?.data.latitude === null || this.props?.data.longitude === null;

		return (
			<components.Option {...otherProps}>
				{isMulti
					?
						<React.Fragment>
							<div className={"d-flex"}>
								<input type="checkbox" checked={isSelected} onChange={() => null} />
								<label className={"ml-1 mt-2"}>{label}</label>
							</div>
						</React.Fragment>

					: <font title={isNoCoordinate && "Не указаны координаты"}
							color={isNoCoordinate && "red"}>
						{label}
					</font>

				}
			</components.Option>
		);
	}
}

export default BaseSelect;