import {axios, updateDefaultAuthHeader } from '../axios';

class AuthService {
	async login(username, password) {
		const response = await axios.post("/auth/signin", { username, password });
		if (response.data.accessToken) {
			localStorage.setItem("user", JSON.stringify(response.data));
		}
		return response.data;
	}

	async register(form) {
		const response = await axios.post("/auth/register", form);
		return response.data;
	}

	async confirmCode(form) {
		const response = await axios.post("/auth/confirm", form);
		return response.data;
	}

	async smsRequest(form) {
		const response = await axios.post("/auth/sms-request", form);
		return response.data;
	}

	logout() {
		localStorage.removeItem("user");
		updateDefaultAuthHeader();
	}
}

export default new AuthService();
