import BaseAsyncSelect from './BaseAsyncSelect'
import addressService from "../../../services/AddressService";

class AddressAsyncSelect extends BaseAsyncSelect {

	constructor(props) {
		super(props);
	}

	async loadOptions(inputText, callback) {
		const minimalSearch = 3;
		const response = inputText.length >= minimalSearch ? await addressService.getTypedAddressOptions(inputText) : [];
		callback(response);
	}
}

export default AddressAsyncSelect;
