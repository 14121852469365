import React, {PureComponent} from "react";
import {classNames} from "../../../helpers/classNames";
import cls from "./FieldError.module.css";

class FieldErrors extends PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            className,
            errors
        } = this.props;

        return (
            <div className={classNames(cls.errors, {}, [className])} role="alert">
                {errors.map((error, index) => (
                    <small key={index}>{error}<br/></small>
                ))}
            </div>
        );
    }
}

export default FieldErrors;