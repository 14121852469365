// messages can be customized
const REQUIRED_FIELD_TEXT = "Обязательное поле";
const GREATER_THAN_SMTH = "Это поле должно быть больше ";
const GREATER_THAN_NULL = GREATER_THAN_SMTH + 0;


export default function auctionFormValidator(formData, excluded = []) {
    const errors = {};

    const keys = Object.keys(formData).filter(key => !excluded.includes(key));

    keys.forEach(field => {
            const value = formData[field];

            errors[field] = [];

            if (
                typeof value !== "boolean"
                && !value
                && field !== 'status'
            ) {
                errors[field].push(REQUIRED_FIELD_TEXT);
            }

            if (
                value <= 0 && (
                    field === "firstPrice"
                    || field === "minPrice"
                    || field === "bidStep"
                )
            ) {
                errors[field].push(GREATER_THAN_NULL);
            }

            if (
                field === "firstPrice"
                && formData["minPrice"] > value
            ) {
                errors[field].push(GREATER_THAN_SMTH + "Минимальная ставка");
            }

            if (
                field === "timeStart"
                && new Date(value) < Date.now()
            ) {
                errors[field].push("Недопустимая дата");
            }
        }
    )

    return errors
}