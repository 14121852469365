import React, {PureComponent} from "react";
import "./OperationalReport.css";
import {Button, Card, Form} from "react-bootstrap";
import OperationalReport from "./OperationalReport";
import DatePicker from "react-datepicker";
import ru from "date-fns/locale/ru";
import Select from "react-select";
import operationalReportService from "../../services/OperationalReportService";
import Ssp from "../reports/Ssp";
import LineChart from "../charts/LineChart";
import FunnelChart from "../charts/FunnelChart";
import AreaChart from "../charts/AreaChart";
import dateTimeService from "../../services/DateTimeService";

class CommercialReport extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            sending: false,
            department: this.ANY,
            logistic: this.ANY,
            commercial: this.ANY,
            contractor: this.ANY,
            status: this.ANY
        }
    }

    ANY = { value: 0, label: OperationalReport.ANY };
    STATUSES = [
        this.ANY,
        {value: Ssp.REQUEST_STATUS_APPROVED, label: "Согласована"},
        {value: Ssp.REQUEST_STATUS_UNLOADED, label: "Разгрузилась"},
        {value: Ssp.REQUEST_STATUS_LOADED, label: "Погрузилась"},
        {value: Ssp.REQUEST_STATUS_ON_LOADING, label: "На погрузке"},
        {value: Ssp.REQUEST_STATUS_ON_WAY, label: "В Пути"},
        {value: Ssp.REQUEST_STATUS_ON_UNLOADING, label: "На разгрузке"}
    ];

    LINE_CHARTS = [
        "quotesNumber",
        "revenue",
        "pureMargin",
        "grossMargin",
        "pureProfitability"
    ];
    FUNNEL_CHART = "salesFunnel";
    AREA_CHART = "areaFunnel";

    componentDidMount() {
        const chartBoxWidth = document.getElementById("content")?.offsetWidth - 70;
        this.setState({chartBoxWidth: chartBoxWidth});

        operationalReportService.getDepartments()
            .then(response => this.setState({departments: response}));

        operationalReportService.getLogistics()
            .then(response => this.setState({logistics: response}));

        operationalReportService.getCommercials()
            .then(response => this.setState({commercials: response}));

        operationalReportService.getContractors()
            .then(response => this.setState({contractors: response}));
    }

    arrayToSelectOptions(array) {
        let res = array?.map(a => ({value: a.id, label: a.value}));
        if (res?.length > 1) res?.unshift(this.ANY);
        return res;
    }

    arrayToOptionsForLogistics(array) {
        let res = array
            ?.filter(u => u.departments.map(d => d.id).includes(this.state.department.value) || this.state.department.value === 0)
            .map(a => ({value: a.id, label: a.lastName + " " + a.firstName?.substring(0, 1) + ". "}));
        if (res?.length > 1) res?.unshift(this.ANY);
        return res;
    }

    arrayToOptionsForCommercial(array) {
        let res = array
            ?.map(a => ({value: a.id, label: a.lastName + " " + a.firstName?.substring(0, 1) + ". "}));
        if (res?.length > 1) res?.unshift(this.ANY);
        return res;
    }

    arrayToOptionsForContractors(array) {
        let res = array
            ?.map(c => ({value: c.id, label: c.name}));
        if (res?.length > 1) res?.unshift(this.ANY);
        return res;
    }

    selectRow(stateKey, selected) {
        this.setState({[stateKey]: selected});

        if (stateKey === "department") {
            this.setState({logistic: this.ANY});
        }
    }

    loadData() {
        const to = this.state.to
            ? this.state.to
            : new Date();
        const from = this.state.from
            ? this.state.from
            : dateTimeService.subtractMonths(new Date(), 12);

        const data = {
            departmentId: this.state.department.value,
            logisticId: this.state.logistic.value,
            commercialId: this.state.commercial.value,
            contractorId: this.state.contractor.value,
            statuses: [this.state.status.value],
            from: dateTimeService.createDateAsUTC(from),
            to: dateTimeService.createDateAsUTC(to)
        }

        this.setState({sending: true});
        operationalReportService.post("commercial", data)
            .then(response => {
                for(let i = 0; i < response[0].length; i++) {
                    this.setState({[this.LINE_CHARTS[i]]: response[0][i]});
                }
                this.setState({[this.FUNNEL_CHART]: response[1]});
                this.setState({[this.AREA_CHART]: response[2]});
            })
            .finally(() => this.setState({sending: false}));
    }

    renderFilterPanel() {
        return <Card.Header>
            <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-between"}}>
                {this.renderSelect("Подразделение", "department", this.arrayToSelectOptions(this.state.departments))}
                {this.renderSelect("Логист", "logistic", this.arrayToOptionsForLogistics(this.state.logistics))}
                {this.renderSelect("Коммерсант", "commercial", this.arrayToOptionsForCommercial(this.state.commercials))}
                {this.renderSelect("Заказчик", "contractor", this.arrayToOptionsForContractors(this.state.contractors))}
                {this.renderSelect("Статус", "status", this.STATUSES)}
                {this.renderDatePicker("От", "from", dateTimeService.subtractMonths(new Date(), 12))}
                {this.renderDatePicker("До", "to", new Date())}
                {this.renderSubmitFiltersButton()}
            </div>
        </Card.Header>
    }

    renderSelect(label, stateKey, options, isForLogistics) {
        return <Form.Group style={{marginBottom: 0, width: "250px"}}>
            <Form.Label>{label}</Form.Label>
            <Select options={options}
                    placeholder={label}
                    hideSelectedOptions={false}
                    value={this.state[stateKey]}
                    onChange={selected => isForLogistics
                        ? this.selectRow(stateKey, selected)
                        : this.setState({[stateKey]: selected})}>
            </Select>
        </Form.Group>
    }

    renderSubmitFiltersButton() {
        return <div style={{marginTop: "30px"}}>
            <Button variant="outline-primary"
                    size="md"
                    onClick={() => this.loadData()}
                    disabled={this.state.sending}>
                {this.state.sending && (<span className="spinner-border spinner-border-sm"></span>)}
                <span> Применить</span>
            </Button>
        </div>
    }

    renderDatePicker(label, stateKey, defaultValue) {
        return <Form.Group style={{marginBottom: 0, width: "90px"}}>
            <Form.Label>{label}</Form.Label>
            <DatePicker
                className="operational-report-date-picker general-report-date-picker"
                dateFormat="dd.MM.yyyy"
                locale={ru}
                selected={this.state[stateKey] ? this.state[stateKey] : defaultValue}
                onChange={date => this.setState({[stateKey] : date})}/>
        </Form.Group>
    }

    renderLineChart(stateKey, color, label) {
        return <Card>
            {this.state[stateKey] &&
                <LineChart data={this.state[stateKey]}
                           withPoint={false}
                           color={color}
                           width={this.state.chartBoxWidth / 5.46}
                           height={200}
                           leftLabel={label}>
                </LineChart>}
        </Card>
    }

    renderFunnelChart(stateKey) {
        return <Card>
            {this.state[stateKey] &&
                <FunnelChart
                    data={this.state[stateKey]}
                    width={this.state.chartBoxWidth}
                    height={400}>
                </FunnelChart>}
        </Card>
    }

    renderAreaChart(stateKey) {
        return <Card>
            {this.state[stateKey] &&
                <AreaChart
                    data={this.state[stateKey]}
                    width={this.state.chartBoxWidth}
                    height={600}>
                </AreaChart>}
        </Card>
    }

    render() {
        return <div>
            {this.renderFilterPanel()}
            <div className="charts-row">
                {this.renderAreaChart(this.AREA_CHART)}
            </div>
            <div className="charts-row">
                {this.renderLineChart("quotesNumber","purple", "Количество заявок")}
                {this.renderLineChart("revenue","orange", "Выручка")}
                {this.renderLineChart("pureMargin","red", "Маржа чистая")}
                {this.renderLineChart("grossMargin","crimson", "Маржа валовая")}
                {this.renderLineChart("pureProfitability","green", "Рентибальность чистая")}
            </div>
            <div className="charts-row">
                {this.renderFunnelChart(this.FUNNEL_CHART)}
            </div>
        </div>
    }
}

export default CommercialReport;