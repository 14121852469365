import React from "react";

class AlertCenter extends React.PureComponent {
	
	constructor(props) {
		super(props);
		
		this.state = {
			alertsExpanded: false
		}		
				
		this.toggleAlertCenter = this.toggleAlertCenter.bind(this);
		this.mouseListener = this.mouseListener.bind(this);

		this.alertCenter = React.createRef();
	}
	
	mouseListener(e) {
		if (!this.alertCenter.current.contains(e.target)) {
			this.toggleAlertCenter();
		}
	}

	addRemoveMouseListener(listen) {
		const addRemove = listen ? document.body.addEventListener : document.body.removeEventListener;
		addRemove('mousedown', this.mouseListener, true);
	}

	toggleAlertCenter() {
		const expanded = !this.state.alertsExpanded;
		this.addRemoveMouseListener(expanded);
		this.setState({ alertsExpanded: expanded });
	}

	componentWillUnmount() {
		this.addRemoveMouseListener(false);
	}
	
	renderAlerts() {
		let index = 0;
		return (
			this.props.alerts?.map(alert => this.renderAlert(alert, index++))
		);		
	}

	renderAlert(alert, index) {
		return (
			<React.Fragment key={index}>			
				<a className="dropdown-item d-flex align-items-center" 
							onClick={() => this.props.onClick && this.props.onClick(alert)}>
	                <div className="mr-3">
	                    <div className="icon-circle bg-danger">
			                <i className="fas fa-exclamation-triangle text-white"></i>
			            </div>
	                </div>
	                <div>
	                    <div className="small text-gray-500">{this.props.item} #{alert.id}</div>
	                    <span className="small">{alert.value}</span>
	                </div>
	            </a>
            </React.Fragment>
		);
	}
	
	render() {
		const alerts = this.props.alerts || [];
		return (
			<React.Fragment>				
                <div className="alert-center-item dropdown no-arrow topbar justify-content-center" ref={this.alertCenter}>						
                    <a className="alert-center-link dropdown-toggle" href="#" role="button"
                    		onClick={this.toggleAlertCenter}
                        	data-toggle="dropdown" aria-haspopup="true" aria-expanded={this.state.alertsExpanded}>
                        <i className="fas fa-bell fa-fw"></i>	                        
                        <span className="badge badge-danger badge-counter">{alerts.length > 0 ? alerts.length + "+" : ""}</span>
                    </a>
                    <div className={"dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in " + (this.state.alertsExpanded ? "show" : "")}>
                        <h6 className="dropdown-header">
                            Уведомления
                        </h6>
                        {this.renderAlerts()}	                                             
                    </div>	                    
            	</div>
			</React.Fragment>
			
		);
	}
	
}

export default AlertCenter;
