import {Tab, Tabs} from "react-bootstrap";
import React, {PureComponent} from "react";
import SspYear from "./SspYear";
import MonthReport from "./MonthReport";
import ConsolidateReportLogistic from "./ConsolidateReportLogistic";
import ConsolidateReportCommercial from "./ConsolidateReportCommercial";
import PlanSales from "./PlanSales";
import './reportStyle.css';
import {
    ADMIN, COMMERCIAL,
    HEAD_OF_DEPARTMENT,
    REGIONAL_DIRECTOR,
    securityService
} from "../../services/SecurityService";

class PlanningReport extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: this.CONSOLIDATE_REPORT_LOGISTIC.id
        }
    }

    SSP_YEAR_REPORT = {id: "sppYear", name: "ССП-годовые показатели", title: "ССП-годовые показатели"}

    MONTH_REPORT = {id: "monthReport", name: "Годовые показатели по месяцам", title: "Годовые показатели по месяцам"}

    CONSOLIDATE_REPORT_LOGISTIC = {id: "consolidateReportLogistic", name: "Сводный отчет ОЛ", title: "Сводный отчет ОЛ"}

    CONSOLIDATE_REPORT_COMMERCIAL = {id: "consolidateReportCommercial", name: "Сводный отчет КО", title: "Сводный отчет КО"}

    PLAN_SALES = {id: "planSales", name: "План продаж", title: "План продаж"}

    render() {
        return <Tabs activeKey={this.state.activeTab} onSelect={key => this.setState({activeTab: key})}>
            {securityService.anyRoles(this.state.crmUser, ADMIN, HEAD_OF_DEPARTMENT, REGIONAL_DIRECTOR) &&
                <Tab key={this.SSP_YEAR_REPORT.id} eventKey={this.SSP_YEAR_REPORT.id}
                     title={this.SSP_YEAR_REPORT.name} tabClassName={"font-title"} mountOnEnter unmounOnExit>
                    <SspYear title={this.SSP_YEAR_REPORT.title}></SspYear>
                </Tab>
            }
            {securityService.anyRoles(this.state.crmUser, HEAD_OF_DEPARTMENT, REGIONAL_DIRECTOR, ADMIN, COMMERCIAL) &&
                <Tab key={this.PLAN_SALES.id} eventKey={this.PLAN_SALES.id} title={this.PLAN_SALES.name}
                     tabClassName={"font-title"} mountOnEnter unmounOnExit>
                    <PlanSales title={this.PLAN_SALES.title}></PlanSales>
                </Tab>}
            {securityService.anyRoles(this.state.crmUser, ADMIN, HEAD_OF_DEPARTMENT, REGIONAL_DIRECTOR) &&
                <Tab key={this.MONTH_REPORT.id} eventKey={this.MONTH_REPORT.id} title={this.MONTH_REPORT.name}
                     tabClassName={"font-title"}>
                    <MonthReport title={this.MONTH_REPORT.title} mountOnEnter unmounOnExit></MonthReport>
                </Tab>}
            {securityService.anyRoles(this.state.crmUser, HEAD_OF_DEPARTMENT, REGIONAL_DIRECTOR, ADMIN, COMMERCIAL) &&
                <Tab key={this.CONSOLIDATE_REPORT_COMMERCIAL.id} eventKey={this.CONSOLIDATE_REPORT_COMMERCIAL.id}
                     title={this.CONSOLIDATE_REPORT_COMMERCIAL.name}
                     tabClassName={"font-title"} mountOnEnter unmounOnExit>
                    <ConsolidateReportCommercial
                        title={this.CONSOLIDATE_REPORT_COMMERCIAL.title}></ConsolidateReportCommercial>
                </Tab>}
            <Tab key={this.CONSOLIDATE_REPORT_LOGISTIC.id} eventKey={this.CONSOLIDATE_REPORT_LOGISTIC.id}
                 title={this.CONSOLIDATE_REPORT_LOGISTIC.name} tabClassName={"font-title"}>
                <ConsolidateReportLogistic title={this.CONSOLIDATE_REPORT_LOGISTIC.title}></ConsolidateReportLogistic>
            </Tab>
        </Tabs>
    }
}

export default PlanningReport;