class Util {
	static safeDate(dt) {
		return typeof dt === "string" ? new Date(dt) : dt;
	}
	
	static calculateRemainingTime(dt) {
		const from = new Date(),
			to = Util.safeDate(dt);
		if (!from || !to || from >= to) {
			return '';
		}
		const msInMinute = 1000 * 60,
			msInHour = msInMinute * 60,
			msInDay = msInHour * 24,
			diff = to - from,
			days = Math.floor(diff / msInDay),
			hours = Math.floor(diff % msInDay / msInHour),
			minutes = Math.floor(diff % msInHour / msInMinute);
    	return (days ? (days + " дн. ") : "") + 
				(hours ? (hours + " ч. ") : "") + 
				minutes + " мин.";
	}
	
	static formatUTC = (dateInt, addOffset = false) => {
	    let date = (!dateInt || dateInt.length < 1) ? new Date : new Date(dateInt);
	    if (typeof dateInt === "string") {
	        return date;
	    } else {
	        const offset = addOffset ? date.getTimezoneOffset() : -(date.getTimezoneOffset());
	        const offsetDate = new Date();
	        offsetDate.setTime(date.getTime() + offset * 60000)
	        return offsetDate;
	    }
	}

	static getFileNameExtension(fileName) {
		return fileName.substring(fileName.lastIndexOf('.')) || "";
	}
	
	static formatFileName(name, model, index) {	
		return Util.normalizeString([(model || ""), Util.formatDate(new Date()), index + 1].join("_")) + Util.getFileNameExtension(name);
	}
	
	static normalizeString(string) {
		return string.replaceAll(" ", "_").replace(/[^a-zA-Zа-яА-ЯЁё0-9_\.]+/g, "");
	}
	
	static formatDate(dt) {
		return `${dt.getDate().pad(2)}.${(1 + dt.getMonth()).pad(2)}.${dt.getFullYear()}`;	
	}
	
	static formatDateTime(dt) {
		return `${dt.getDate().pad(2)}.${(1 + dt.getMonth()).pad(2)}.${dt.getFullYear()}_${dt.getHours().pad(2)}.${dt.getMinutes().pad(2)}.${dt.getSeconds().pad(2)}`;	
	}

	static formatIsoDate(dt) {
		return dt.toISOString().substring(0, 10);
	}

	static formatDecimal(value) {
		return value?.toLocaleString(undefined, { maximumFractionDigits: 2 });
	}

	static makeCancelablePromise = (promise) => {
		let hasCanceled_ = false;
		const wrappedPromise = new Promise((resolve, reject) => {
			promise.then(
				val => hasCanceled_ ? reject({ isCanceled: true }) : resolve(val),
				error => hasCanceled_ ? reject({ isCanceled: true }) : reject(error)
			);
		});
		return {
			promise: wrappedPromise,
			cancel() {
				hasCanceled_ = true;
			},
		};
	};

	static smartJoin(delimiter, parts) {
		return parts.filter(part => !!part).join(delimiter);
	}

	static checkNotNullOrEmpty(entity, current) {
		return !!entity ? entity : current;
	}
	
	static plusDays(dt, days) {
		dt = new Date(dt);
		dt.setDate(dt.getDate() + days);
		return dt;
	}
	
	static minusDays(dt, days) {
		dt = new Date(dt);
		dt.setDate(dt.getDate() - days);
		return dt;
	}
	
	static isPassed(dt) {
		return (new Date().getTime() - dt.getTime()) > 0;
	}
	
	static moveItemInArray(arr, oldIndex, newIndex) {
		if (newIndex >= arr.length) {
			var k = newIndex - arr.length + 1;
			while (k--) {
				arr.push(undefined);
			}
		}
		arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
		return arr;
	};

	static deepEquals(obj1, obj2) {
		for (let prop in obj1) {
			if ((typeof obj1[prop] === "object" && obj1[prop] !== null) && (typeof obj2[prop] === "object" && obj2[prop] !== null)) {
				if (!this.deepEquals(obj1[prop], obj2[prop])) {
					return false;
				}
			} else if (obj1[prop] !== obj2[prop]) {
				return false;
			}
		}
		return true;
	}

}

export default Util;