import React from "react";
import {Button, Form} from "react-bootstrap";
import {connect} from "react-redux";
import DataTable from "../../table/DataTable";
import StoreWrapper from "../../form/StoreWrapper";
import settingsService from "../../../services/SettingsService";
import TextEditor from "../../tasktracker/DraftEditor/TextEditor";
import htmlToDraft from "html-to-draftjs";
import ContentState from "draft-js/lib/ContentState";
import {convertToRaw, EditorState} from "draft-js";
import draftToHtml from "draftjs-to-html";
import {stateToHTML} from "draft-js-export-html";
class Alerts extends StoreWrapper{
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.submitAlert = this.submitAlert.bind(this);
        this.state = {
            alert: '<p></p>'
        };
    }
    async submitAlert(e) {
        const alert = this.state.alert
        typeof this.state.alert != "string" && await this.setState({alert: draftToHtml(convertToRaw(alert.getCurrentContent()))})
        settingsService.saveNew('alert', this.state.alert)
    }

    onChange(value) {
        this.setState({alert: value})
    }

    componentDidMount() {
        settingsService.get("alert")
            .then(data => {
                this.setState({alert: data})
            })
    }
    render() {
        return (
            <div>
                <Form.Group className={'tiny-editor'}>
                    <Form.Label>Объявление</Form.Label>
                    <TextEditor value={this.state.alert && this.state.alert} onEditorStateChange={this.onChange} emogi={true}/>
                </Form.Group>
                <Button variant="primary" type="submit" onClick={this.submitAlert}>
                    Отправить
                </Button>
            </div>
        );
    }
}

export default connect(DataTable.mapStateToProps)(Alerts);
