import React from "react";
import BaseForm from "../../form/BaseForm";
import { Card, Col, Form, ListGroup, ListGroupItem, Row } from "react-bootstrap";
import Loading from "../../Loading";
import "./Ati.css"

class Ati extends BaseForm {

    constructor(props) {
        super(props);
    }

    getRateFields() {
        return [
            { title: "Количество звёзд", name: "score", style: {fontWeight: "bold"}},
            { title: "Место в городе", name: "position_in_city" },
            { title: "Место в стране", name: "position_in_country" },
            { title: "Количество рекомендаций от фирм", name: "recommendations_count" },
            { title: "Количество упоминаний на форуме недобросовестных партнеров", name: "bad_partner_mentions_count" },
            { title: "Сумма отрицательных баллов", name: "negative_points_sum" },
            { title: "Количество претензий от фирм", name: "claims_count" }
        ];
    }

    getActivityFields() {
        return [
            { title: "Дата регистрации", name: "registration_date" },
            { title: "Дата последних изменений в истории фирмы", name: "contact_info_changed" },
            { title: "Число дней со входами на сайт за последние 30 дней", name: "last_month_active_days" },
            { title: "Дата удаления", name: "deletion_date", style: {color: "red"} }
        ];
    }

    getContactFields() {
        return [
            { title: "Телефон", name: "mobile_phone" },
            { title: "Email", name: "email" },
            { title: "Fax", name: "fax" },
            { title: "Skype", name: "skype_name" },
            { title: "ISQ", name: "icq" }
        ];
    }

    getAffiliatedFirmFields() {
        return [
            { title: "ИНН", name: "inn" }
        ];
    }

    getOtherInfoFields() {
        return [
            { title: "Город", name: "city" },
            { title: "Карточка фирмы", name: "info_reference" },
            { title: "Паспорт фирмы", name: "passport_reference" },
            { title: "Сайт", name: "web_site" }
        ];
    }

    renderListGroup(summary, fields) {
        for (let i = 0; i < fields.length; i++) {
            fields[i].value = summary[fields[i].name];
        }
        let index = 1;
        return (
            <ListGroup className="list-group list-group-flush">
                {fields.filter(it => it.value !== null && it.value !== "").map(it => {
                    if (typeof it.value === 'string' && it.value.startsWith("http")) {
                        return <ListGroupItem>{ index++ }) <a href={ it.value } target="_blank">{ it.title }</a></ListGroupItem>;
                    }
                    return <ListGroupItem style={it.style}>{ index++ }) { it.title }: { it.value }</ListGroupItem>;
                })}
            </ListGroup>
        );
    }

    renderInfoCard(title, fields) {
        return (
            <Col lg={6} className="col-scroll">
                <Card>
                    <Card.Body>
                        <Card.Title style={{textAlign: "center"}}>{ title }</Card.Title>
                    </Card.Body>
                    {this.renderListGroup(this.props.summary, fields)}
                </Card>
            </Col>
        );
    }

    renderCardWithBodyList(title, source, fields) {
        if (source.length === 0) {
            return (<></>);
        }
        return (
            <Col lg={6} className="col-scroll">
                <Card>
                    {source.map(it => {
                        return (
                            <Card.Body>
                                <Card.Title>{ title } { it.name || it.firm_name}</Card.Title>
                                {this.renderListGroup(it, fields)}
                            </Card.Body>
                        );
                    })}
                </Card>
            </Col>
        );
    }

    renderDocs(title, source) {
        if (source.length === 0) {
            return (<></>);
        }
        return (
            <Col lg={6} className="col-scroll">
                <Card>
                    {source.map(it => {
                        return (
                            <Card.Body>
                                <a href={ it.link } target="_blank">{ it.description }</a>
                            </Card.Body>
                        );
                    })}
                </Card>
            </Col>
        )
    }

    getValidClassName(...sources) {
        return sources.some(it => it.length === 0) ? "row-left" : "row-center";
    }

    renderForm() {
        const isReady = !!this.props.summary || !!this.props.contacts
        if (!isReady) {
            return (<Loading/>);
        }
        return (
            <Form>
                <Row className="row">
                    {this.renderInfoCard("Рейтинг", this.getRateFields())}
                    {this.renderInfoCard("Активность", this.getActivityFields())}
                </Row>
                <Row className={this.getValidClassName(this.props.summary.firm_documents)}>
                    {this.renderInfoCard("Дополнительная информация", this.getOtherInfoFields())}
                    {this.renderCardWithBodyList("Контакт:", this.props.contacts, this.getContactFields())}
                </Row>
                <Row className={this.getValidClassName(this.props.contacts, this.props.summary)}>
                    {this.renderDocs("Документ", this.props.summary.firm_documents)}
                </Row>
            </Form>
        );
    }
}

export default Ati;