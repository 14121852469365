import React from "react";
import DatePicker from "react-datepicker";
// import 'react-datepicker/dist/react-datepicker.css';
import ru from "date-fns/locale/ru"; // the locale you want

class TimePicker extends React.PureComponent {
	constructor(props) {
		super(props);
		this.timeFormat = "HH:mm";
		this.timeRegexp = /^([01][0-9]|2[0-3]):[0-5][0-9]$/;
	}
	
	render() {
		let className = "form-control form-control-sm";
		if (this.props.isInvalid) {
			className += " is-invalid";
		}
		return (
			<DatePicker
				placeholderText={this.props.placeholder}
				showTimeSelect={true}
				showTimeSelectOnly={true}
				timeFormat={this.timeFormat}
				dateFormat={this.timeFormat}
				name={this.props.name}
				onChange={date => this.props.onChange(date)}
 				className={className}
				autocomplete="off"
				isClearable={true}
				selected={this.dateFromTime()}
				readOnly={this.props.readOnly}
				locale={ru} />
		);
	}

	dateFromTime() {
		const timeString = this.props.value;
		if (timeString != null && typeof timeString === "string" && timeString.match(this.timeRegexp)) {
			return new Date(`1970-1-1 ${this.props.value}`);
		}
		return null;
	}
}

export default TimePicker;