import React from 'react';
import { connect } from "react-redux";
import { logout } from "../actions/auth";
import configService from '../services/ConfigService';
import {NavLink} from "react-router-dom";
import SettingsService from "../services/SettingsService";
import StoreWrapper from "../components/form/StoreWrapper";
import NoticesForm from "../components/Notices/Notices";
import Notices from "../components/Notices/Notices";

class Header extends StoreWrapper {
	constructor(props) {
		super(props);
		this.state = {
			userMenuExpanded: false,
		}

		this.toggleUserMenu = this.toggleUserMenu.bind(this);
		this.mouseListener = this.mouseListener.bind(this);
		this.openProfile = this.openProfile.bind(this);
		this.doLogout = this.doLogout.bind(this);

		this.userMenu = React.createRef();
	}

	mouseListener(e) {
		if (!this.userMenu.current.contains(e.target)) {
			this.toggleUserMenu();
		}
	}

	addRemoveMouseListener(listen) {
		const addRemove = listen ? document.body.addEventListener : document.body.removeEventListener;
		addRemove('mousedown', this.mouseListener, true);
	}

	toggleUserMenu() {
		const expanded = !this.state.userMenuExpanded;

		this.addRemoveMouseListener(expanded);

		this.setState({userMenuExpanded: expanded});
	}

	openProfile() {
		alert("Эта функция пока не реализована");
		this.toggleUserMenu();
	}

	doLogout() {
		this.props.dispatch(logout());
	}

	componentWillUnmount() {
		this.addRemoveMouseListener(false);
	}
	componentDidUpdate(prevProps, prevState, snapshot) {
	}

	componentDidMount() {
		SettingsService.get('alert')
			.then(data => this.setState({alert: data}))
	}

	render() {
		let alert = {__html: this.state.alert};
		return (
			<nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
				{this.state.alert && <div style={{"text-overflow": "ellipsis", "max-width": "100%","padding-bottom": "20px", "max-height": "100%", "word-break": "break-all", "overflow": "hidden"}} dangerouslySetInnerHTML={alert} />}
				<ul className="navbar-nav site-header ml-auto">
					<Notices/>
					<div className="topbar-divider d-none d-sm-block"></div>

					<li className="nav-item dropdown no-arrow" ref={this.userMenu}>
						<a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button"
							onClick={this.toggleUserMenu}
							data-toggle="dropdown" aria-haspopup="true" aria-expanded={this.state.userMenuExpanded}>
							<span className="mr-2 d-none d-lg-inline text-gray-600 small">{this.props.user.fio}</span>
							<img className="img-profile rounded-circle" src="//ssl.gstatic.com/accounts/ui/avatar_2x.png" />
						</a>
						<div className={"dropdown-menu dropdown-menu-right shadow animated--grow-in " + (this.state.userMenuExpanded ? "show" : "")}
							aria-labelledby="userDropdown">
							<a className="dropdown-item" href="#">
								<i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
								<NavLink to={"/user-profile"} target={"_blank"}>
									Профиль
								</NavLink>
                            </a>
							<div className="dropdown-divider"></div>
							<a className="dropdown-item" href="#" data-toggle="modal" data-target="#logoutModal" onClick={this.doLogout}>
								<i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
								Выход
                            </a>
						</div>
					</li>
				</ul>
			</nav>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.auth.user
	};
}

export default connect(mapStateToProps)(Header);
