import { NOTIFICATION_CLEAR, NOTIFICATION_SET } from "./types";

export const setNotification = (notification) => ({
	type: NOTIFICATION_SET,
	payload: notification,
});

export const clearNotification = () => ({
	type: NOTIFICATION_CLEAR,
});
