import {FORM_SET_VALUE, FORM_SET_ERROR, FORM_SET_ERRORS, FORM_DATA_LOADED, SET_TOAST_OBJ} from "./types";

export const setValue = (name, value) => ({
	type: FORM_SET_VALUE,
	name,
	value
});

export const setError = (name, error) => ({
	type: FORM_SET_ERROR, 
	value: error,
	name
});

export const setErrors = (errors) => ({
	type: FORM_SET_ERRORS, value: errors
});

export const setData = (data, action) => ({
	type: FORM_DATA_LOADED, value: data, action
});