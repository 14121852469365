import React, {PureComponent} from "react";
import "./OperationalReport.css";
import OperationalReport from "./OperationalReport";
import {Button, Card, Form, Pagination, Table} from "react-bootstrap";
import DatePicker from "react-datepicker";
import ru from "date-fns/locale/ru";
import operationalReportService from "../../services/OperationalReportService";
import motivationService from "../../services/MotivationService";

class TransportReport extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            sending: false,
            response: [],
            regions: [],
            selectPage: 1,
            quotesNumber: 0,
            [OperationalReport.FROM.id]: null,
            [OperationalReport.TO.id]: null,
            [OperationalReport.LOADING_REGION.id]: OperationalReport.ANY,
            [OperationalReport.UNLOADING_REGION.id]: OperationalReport.ANY,
            [OperationalReport.LOADING_CITY.id]: OperationalReport.ANY,
            [OperationalReport.UNLOADING_CITY.id]: OperationalReport.ANY,
            [OperationalReport.VEHICLE_TONNAGE.id]: OperationalReport.ANY,
            [OperationalReport.VEHICLE_CUBAGE.id]: OperationalReport.ANY,
            [OperationalReport.VEHICLE_CARCASS_VALUE.id]: OperationalReport.ANY,
            [OperationalReport.CARRIER_FULL_NAME.id]: OperationalReport.ANY,
            [OperationalReport.CARRIER_VAT_MODE.id]: OperationalReport.ANY,
            [OperationalReport.DEPARTMENT_VALUE.id]: OperationalReport.ANY,
            [OperationalReport.SOURCE_VALUE.id]: OperationalReport.ANY,
            [OperationalReport.CARGO_TYPE_VALUE.id]: OperationalReport.ANY
        }
    }

    PAGE_LIMIT = 10;

    componentDidMount() {
        this.loadRegionsList();
    }

    loadRegionsList() {
        operationalReportService.getRegions()
            .then(response => this.setState({regions : response}));
    }

    loadData() {
        if (this.state[OperationalReport.LOADING_REGION.id] === OperationalReport.ANY
            && this.state[OperationalReport.UNLOADING_REGION.id] === OperationalReport.ANY ) {
            alert("Нужно выбрать регион.");
            return;
        }

        let data = {
            [OperationalReport.LOADING_REGION.id]: this.state[OperationalReport.LOADING_REGION.id],
            [OperationalReport.UNLOADING_REGION.id]: this.state[OperationalReport.UNLOADING_REGION.id]
        }

        this.setState({sending: true});
        operationalReportService.post("cargo-analytic", data)
            .then(response => {
                this.setState({response: response});
                this.setState({pages: this.calculatePages(response.length)});
                this.setState({selectPage: 1});
                this.findAndSetMaxCarrierPrice(response);
                this.clearFilters();
                this.setState({sending: false});
            });
    }

    findAndSetMaxCarrierPrice(response) {
        const maxCarrierPrice = response
            .map(element => Number(element.carrierPrice))
            .reduce((a, b) => Math.max(a, b), -Infinity);
        this.setState({maxCarrierPrice: maxCarrierPrice})
    }

    clearFilters() {
        this.FILTERS.forEach(field => this.setState({[field.id]: OperationalReport.ANY}));
    }

    TABLE_FIELDS = [
        OperationalReport.LOADING_DT,
        OperationalReport.DEPARTMENT_VALUE,
        OperationalReport.LOADING_CITY,
        OperationalReport.UNLOADING_CITY,
        OperationalReport.CARRIER_FULL_NAME,
        OperationalReport.CONTRACTOR_FULL_NAME,
        OperationalReport.SOURCE_VALUE,
        OperationalReport.CARGO_TYPE_VALUE,
        OperationalReport.VEHICLE_TONNAGE,
        OperationalReport.VEHICLE_CUBAGE,
        OperationalReport.VEHICLE_CARCASS_VALUE,
        OperationalReport.CARRIER_VAT_MODE,
        OperationalReport.GSM_STRAIT,
        OperationalReport.CARRIER_PRICE
    ]

    FILTERS = [
        OperationalReport.LOADING_CITY,
        OperationalReport.UNLOADING_CITY,
        OperationalReport.VEHICLE_TONNAGE,
        OperationalReport.VEHICLE_CUBAGE,
        OperationalReport.VEHICLE_CARCASS_VALUE,
        OperationalReport.CARRIER_FULL_NAME,
        OperationalReport.CARRIER_VAT_MODE,
        OperationalReport.CONTRACTOR_FULL_NAME,
        OperationalReport.DEPARTMENT_VALUE,
        OperationalReport.SOURCE_VALUE,
        OperationalReport.CARGO_TYPE_VALUE
    ]

    renderDatePicker(label, stateKey) {
        return <Form.Group style={{width: "90px"}}>
            <DatePicker
                className="operational-report-date-picker"
                dateFormat="dd.MM.yyyy"
                locale={ru}
                selected={this.state[stateKey]}
                onChange={date => this.setState({[stateKey] : date})}/>
        </Form.Group>
    }

    renderSubmitFiltersButton() {
        return <div className="submit-filters-button">
            <Button variant="outline-primary"
                    size="sm"
                    onClick={() => this.loadData()}
                    disabled={this.state.sending}>
                {this.state.sending && (<span className="spinner-border spinner-border-sm"></span>)}
                <span> Выгрузить заявки</span>
            </Button>
        </div>
    }

    renderSelectPanel() {
        return <div >
            <div className="select-filter-panel-cargo" style={{marginTop: "10px"}}>
                {this.renderSelect(OperationalReport.LOADING_REGION.name, OperationalReport.LOADING_REGION.id, this.state.regions, true)}
                {this.renderSelect(OperationalReport.UNLOADING_REGION.name, OperationalReport.UNLOADING_REGION.id, this.state.regions, true)}
                {this.renderSubmitFiltersButton()}
            </div>

            <div className="select-filter-panel-cargo">
                {this.FILTERS.map(field =>
                    this.renderSelect(field.name, field.id, OperationalReport.mapAndFilterField(this.state.response, field.id), false))}
                {this.renderSelectRange()}
            </div>
        </div>
    }

    renderSelect(label, stateKey, options, isForRegions) {
        return <Form.Group>
            <Form.Label style={{whiteSpace : "noWrap"}}>{label}</Form.Label>
            <Form.Control size="sm" as="select"
                          onChange={event => this.setState({[stateKey]: event.target.value})}>
                <option value={OperationalReport.ANY}>{OperationalReport.ANY}</option>
                {options.map(option => isForRegions
                    ? <option value={option.iso}>{option.name}</option>
                    : <option value={option}>{option}</option>)}
            </Form.Control>
        </Form.Group>
    }

    renderSelectRange() {
        return <Form.Group>
            <Form.Label style={{whiteSpace : "noWrap"}}>Пролив ГСМ</Form.Label>
            <div style={{display: "flex"}}>
                <Form.Control size="sm" type="number"
                              style={{width: "50%"}}
                              placeholder={"от"}
                              onChange={e => this.setState({gsmStraitMin: e.target.value})} />
                <Form.Control size="sm" type="number"
                              style={{width: "50%"}}
                              placeholder={"до"}
                              onChange={e => this.setState({gsmStraitMax: e.target.value})} />
            </div>
        </Form.Group>
    }

    calculatePages(number) {
        return Math.ceil(number / this.PAGE_LIMIT);
    }

    filterData(data) {
        const filteredData = data?.filter(row => this.filterTable(row));
        const to = () => this.state.selectPage * this.PAGE_LIMIT - 1 > filteredData.length - 1
            ? filteredData.length - 1
            : this.state.selectPage * this.PAGE_LIMIT - 1;
        let result = [];

        for (let i = this.state.selectPage * this.PAGE_LIMIT - this.PAGE_LIMIT; i <= to(); i++) {
            result.push(filteredData[i]);
        }

        const pages = this.calculatePages(filteredData.length);
        this.setState({pages: pages});
        this.setState({quotesNumber: filteredData.length});

        if (this.state.selectPage > pages) {
            this.setState({selectPage: 1});
        }

        return result;
    }

    downloadReport() {
        let reports = new Array(this.state.response.filter(row => this.filterTable(row)));
        reports[0].map(r => {
                delete r[OperationalReport.UNLOADING_DT.id];
                delete r[OperationalReport.CONTRACTOR_FULL_NAME.id];
                delete r[OperationalReport.CARRIER_PHONE.id];
                delete r[OperationalReport.DRIVER_LAST_NAME.id];
                delete r[OperationalReport.VEHICLE_NUMBER.id];
                delete r[OperationalReport.LOADING_CITY.id];
                delete r[OperationalReport.LOADING_TYPE_VALUE.id];
                r[OperationalReport.LOADING_REGION.id] = this.regionIsoCodeToName(r[OperationalReport.LOADING_REGION.id]);
                r[OperationalReport.UNLOADING_REGION.id] = this.regionIsoCodeToName(r[OperationalReport.UNLOADING_REGION.id]);
            });

        motivationService.downloadFile(
            "CARGO_ANALYTIC" + ".xlsx",
            "xlsx",
            {headers: this.TABLE_FIELDS, content: reports[0]}
        );
    }

    regionIsoCodeToName(iso) {
        return this.state.regions.find(region => region.iso === iso)?.name;
    }

    renderResponseTable() {
        return <Card style={{width: "fit-content"}}>
            <Card.Header style={{height: "50px"}}>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div style={{display: "flex"}}>
                        {"с"}&nbsp;{this.renderDatePicker(OperationalReport.FROM.name, OperationalReport.FROM.id)}
                        &nbsp;{"по"}&nbsp;{this.renderDatePicker(OperationalReport.TO.name, OperationalReport.TO.id)}
                    </div>
                    <span>
                        {this.props.title}
                    </span>
                    <Button style={{margin: "5px", position: "relative", bottom: "10px"}}
                            variant="outline-success"
                            onClick={() => this.downloadReport()}>
                        <span>{this.state.quotesNumber + OperationalReport.getQuotesName(this.state.quotesNumber)} <i className="fa fa-download"/></span>
                    </Button>
                </div>
            </Card.Header>
            <Card.Body style={{padding: 0}}>
                <Table striped bordered hover size="sm">
                    <thead style={{fontWeight: "bold"}}>
                    <tr>
                        {this.TABLE_FIELDS.map(field => <td>{field.name}</td>)}
                    </tr>
                    </thead>
                    <tbody>
                    {this.filterData(this.state.response)?.map(row => this.filterTable(row) &&
                        <tr>{this.TABLE_FIELDS.map(field =>
                            <td className="cargo-report-table-body-cell">
                                {this.renderTableBodyCell(row, field.id)}
                            </td>)}
                        </tr>
                    )}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    }

    filterTable(row) {
        const from = new Date(this.state[OperationalReport.FROM.id]).getTime();
        const to = new Date(this.state[OperationalReport.TO.id]).getTime();
        const unloadingDt = new Date(row[OperationalReport.LOADING_DT.id]).getTime();

        const dateFilter = (this.state[OperationalReport.FROM.id] === null || from <= unloadingDt)
            && (this.state[OperationalReport.TO.id] === null || to >= unloadingDt);
        const selectFilter = this.FILTERS.every(field =>
            this.state[field.id] === OperationalReport.ANY || row[field.id] === this.state[field.id]);

        const gsmFilter = (!this.state.gsmStraitMin || Number(row[OperationalReport.GSM_STRAIT.id]) >= Number(this.state.gsmStraitMin)) &&
            (!this.state.gsmStraitMax || Number(row[OperationalReport.GSM_STRAIT.id]) <= Number(this.state.gsmStraitMax));

        return selectFilter && dateFilter && gsmFilter;
    }

    renderTableBodyCell(row, fieldId) {
        if (fieldId === OperationalReport.LOADING_DT.id || fieldId === OperationalReport.UNLOADING_DT.id) {
            return row[fieldId].substring(0, 10);
        }

        if (fieldId === OperationalReport.LOADING_REGION.id || fieldId === OperationalReport.UNLOADING_REGION.id) {
            const regionInResponse = row[fieldId];
            return this.state.regions.find(region => region.iso === regionInResponse)?.name;
        }

        if (fieldId === OperationalReport.CARRIER_PRICE.id) {
            return this.renderProgressDiv(row[fieldId]);
        }

        return row[fieldId];
    }

    renderProgressDiv(value) {
        const width = value / this.state.maxCarrierPrice * 100 + "%";
        return <div className="carrier-price-progress-bar" style={{width: width}}>{value.toLocaleString()}</div>
    }

    getArrayOfPages(selectPage, lastPage) {
        if (lastPage > 9) {
            if (selectPage < 6) return OperationalReport.range(2, 8);
            if (selectPage > 5 && selectPage < lastPage - 4) return OperationalReport.range(selectPage - 2, selectPage + 3);
            if (selectPage > lastPage - 5) return OperationalReport.range(lastPage - 6, lastPage);
        }

        return OperationalReport.range(2, lastPage);
    }

    renderPagination(selectPage, lastPage) {
        if (this.state.pages < 2) {
            return;
        }

        return <div className="operational-report-pagination">
            <Pagination>
                <Pagination.Prev onClick={() => this.setState({selectPage: selectPage > 1 ? selectPage - 1 : selectPage})} />
                <Pagination.Item onClick={() => this.setState({selectPage: 1})} active={selectPage === 1}>{1}</Pagination.Item>
                {lastPage > 9 && selectPage > 5 ? <Pagination.Ellipsis /> : <></>}
                {this.getArrayOfPages(selectPage, lastPage)
                    .map(page => <Pagination.Item onClick={() => this.setState({selectPage: page})} active={page === selectPage}>
                        {page}
                    </Pagination.Item>)}
                {lastPage > 9 && selectPage < lastPage - 4 ? <Pagination.Ellipsis /> : <></>}
                <Pagination.Item onClick={() => this.setState({selectPage: lastPage})} active={selectPage === lastPage}>{lastPage}</Pagination.Item>
                <Pagination.Next onClick={() => this.setState({selectPage: selectPage < lastPage ? selectPage + 1 : selectPage})} />
            </Pagination>
        </div>
    }

    render() {
        return <div style={{overflowX: "auto"}}>
            {this.renderSelectPanel()}
            {this.renderResponseTable()}
            {this.renderPagination(this.state.selectPage, this.state.pages)}
        </div>
    }
}

export default TransportReport;