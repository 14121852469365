import * as actionTypes from './auctionFormActionTypes'
import {initialState} from "./auctionFormInitialState";

const auctionFormReducer = (state = initialState.auctionForm, action) => {
    switch (action.type) {
        case actionTypes.SET_FORM_DATA:
            return {
                ...state,
                ...action.payload
            };
        case actionTypes.SET_FORM_ERRORS:
            return {
                ...state,
                error: action.payload
            };
        case actionTypes.SET_AUCTION_BID_TYPE:
            return {
                ...state,
                auctionBidType: action.payload
            };
        case actionTypes.SET_AUCTION_NDS_TYPE:
            return {
                ...state,
                auctionNdsType: action.payload
            };
        case actionTypes.SET_IS_ONE_BID_ONLY:
            return {
                ...state,
                isOneBidOnly: action.payload
            };
        case actionTypes.SET_BUYOUT_AVAILABLE:
            return {
                ...state,
                isBuyoutAvailable: action.payload
            };
        case actionTypes.SET_FIRST_PRICE:
            return {
                ...state,
                firstPrice: action.payload
            };
        case actionTypes.SET_MIN_PRICE:
            return {
                ...state,
                minPrice: action.payload
            };
        case actionTypes.SET_STATUS:
            return {
                ...state,
                status: action.payload
            };
        case actionTypes.SET_BID_STEP:
            return {
                ...state,
                bidStep: action.payload
            };
        case actionTypes.SET_TIME_START:
            return {
                ...state,
                timeStart: action.payload
            };
        case actionTypes.SET_TIME_FINISH:
            return {
                ...state,
                timeFinish: action.payload
            };
        case actionTypes.SUBMIT_FORM:
            return {
                ...state,
                isLoading: true,
                error: null
            };
        case actionTypes.SUBMIT_FORM_SUCCESS:
            return {
                ...state,
                ...action.payload,
                isLoading: false
            };
        case actionTypes.SUBMIT_FORM_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            };
        case actionTypes.FETCH_DATA:
            return {
                ...state,
                isLoading: true,
                error: null
            };
        case actionTypes.FETCH_DATA_SUCCESS:
            return {
                ...state,
                ...action.payload,
                isLoading: false
            };
        case actionTypes.FETCH_DATA_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            };
        case actionTypes.SET_VALIDATION_ERRORS:
            return {
                ...state,
                validationErrors: action.payload
            };
        default:
            return state;
    }
}

export default auctionFormReducer;