import {axios} from "../axios";

class DadataService {
	static URL_DADATA_ORGANIZATION = '/dadata/suggestions/api/4_1/rs/findById/party';
	static URL_DADATA_BANK = '/dadata/suggestions/api/4_1/rs/findById/bank';
	static URL_DADATA_ADDRESS_CLEAN = '/dadata/cleaner/api/v1/clean/address';

	static BRANCH_MARK = "BRANCH";
	static HEADQUARTER_MARK = "MAIN";

	static createCrmContractorState(organizationResponse) {
		if (organizationResponse.suggestions.length === 0) {
			return {};
		}

		const typesMap = {
			LEGAL: {id: 1, value: 'Юридическое лицо'},
			INDIVIDUAL: {id: 2, value: 'Физическое лицо'}
		}

		const organization = organizationResponse.suggestions[0].data,
			model = {
				inn: organization.inn,
				ogrn: organization.ogrn,
				kpp: organization.kpp,
				legalAddress: organization.address?.value,
				contractorMode: typesMap[organization.type] || {}
			};
		if (organization.name) {
			model.fullName = organization.name.full_with_opf;
			model.name = organization.name.short_with_opf;
		}
		if (organization.management) {
			model.authorityPosition = organization.management.post;
			model.authorityFio = organization.management.name;
		}
		if (organization.emails?.length) {
			model.email = organization.emails[0].source;
		}
		if (organization.phones?.length) {
			model.phone = organization.phones[0].source;
		}
		return model;
	};

	static createCrmBankState(bankResponse) {
		if (bankResponse.suggestions.length === 0) {
			return {};
		}
		const bank = bankResponse.suggestions[0].data;
		return {
			bik: bank.bic,
			bank: bank.name.payment,
			correspondentAccount: bank.correspondent_account,
		};
	};

	static createCrmAddressState(addressResponse) {
		if (addressResponse.length === 0) {
			return {};
		}
		const addr = addressResponse[0];

		const city_str = (addr.region_with_type ? (addr.region_with_type + ', ') : '')
			+ (addr.area_with_type ? (addr.area_with_type + ', ') : '')
			+ (addr.city_with_type ? (addr.city_with_type + ', ') : '')
			+ (addr.settlement_with_type ? addr.settlement_with_type : '');

		const addr_str = (addr.street_with_type ? (addr.street_with_type + ', ') : '')
			+ (addr.house_type_full ? (addr.house_type_full + ' ') : '')
			+ (addr.house ? (addr.house + ', ') : '')
			+ (addr.block_type ? (addr.block_type + ' ') : '')
			+ (addr.block ? (addr.block + ', ') : '')
			+ (addr.flat_type ? (addr.flat_type + ' ') : '')
			+ (addr.flat ? (addr.flat + ', ') : '');

		return {
			city: city_str.replace(/,\s*$/, ""),
			fullAddress: addr_str.replace(/,\s*$/, ""),
			fiasId: addr.fias_id,
			latitude: addr.geo_lat,
			longitude: addr.geo_lon,
			countryIso: addr.country_iso_code,
			regionIso: addr.region_iso_code,
		};
	};

	static createAddressParams({ fullAddress = ''}) {
		return [fullAddress];
	};

	static createOrgaizationParams({ inn = '', ogrn = '', kpp = '' }) {
		return {
			query: inn ? inn : ogrn,
			branch_type: inn && kpp ? undefined : DadataService.HEADQUARTER_MARK,
			kpp: inn && kpp ? kpp : undefined,
			count: 10,
		};
	};

	static createBankParams({ inn = '', kpp = '', bik = '' }) {
		return {
			query: inn && kpp ? inn : bik,
			branch_type: inn && kpp ? undefined : DadataService.HEADQUARTER_MARK,
			kpp: inn && kpp ? kpp : undefined,
			count: 10,
		};
	};
		
	async fetchContractor(params) {
		return await this.doRequest({
			url: DadataService.URL_DADATA_ORGANIZATION,
			params: DadataService.createOrgaizationParams(params),
			createCrmStateFn: DadataService.createCrmContractorState
		});
	};

	async fetchBank(params) {
		return await this.doRequest({
			url: DadataService.URL_DADATA_BANK,
			params: DadataService.createBankParams(params),
			createCrmStateFn: DadataService.createCrmBankState
		});
	};

	async fetchAddress(params) {
		return await this.doRequest({
			url: DadataService.URL_DADATA_ADDRESS_CLEAN,
			params: DadataService.createAddressParams(params),
			createCrmStateFn: DadataService.createCrmAddressState
		});
	};

	async doRequest(requestOptions) {
		const result = await axios.post(requestOptions.url, requestOptions.params);
		return requestOptions.createCrmStateFn(result.data);
	};
}

export default new DadataService();