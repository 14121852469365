import BaseSelect from "../../form/BaseSelect";
import vehicleMarkService from "../../../services/VehicleMarkService";
import vehicleModelService from "../../../services/VehicleModelService";
import optionsService from "../../../services/OptionsService";

class VehicleMarkModelSelect extends BaseSelect {
	constructor(props) {
		super(props);
		this.service = this.props.optionsType === "VEHICLE_MARK" ? vehicleMarkService : vehicleModelService;
	}

	fetchOptions() {
		return this.service.options(this.props.markId);
	}

	componentDidUpdate(prevProps) {
		if (this.props.optionsType === "VEHICLE_MODEL" && prevProps.markId !== this.props.markId ) {
			this.loaded = false;
			this.onChange(optionsService.createEmptyOption());
			this.setState({ options: [] });
		}
	}
}

export default VehicleMarkModelSelect;