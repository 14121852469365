import './App.css';
import {
    Router,
    Switch,
    Route
} from "react-router-dom";

import Login from './components/Login';
import Registration from "./components/registration/Registration";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Layout from './layout/Layout';
import "bootstrap/dist/css/bootstrap.min.css";
import ApiExceptionModal from './components/ApiExceptionModal';
import {history} from './index';
import 'react-toastify/dist/ReactToastify.css';
import {ToastWrapper} from "./hoc/ToastWrapper/ToastWrapper";
import {useSocket} from "./hooks/useSocket";
import {useEffect, useRef, useState} from "react";
import useSharedWorker from "./hooks/useSharedWorker";
import debounce from "lodash.debounce"
import {useSelector} from "react-redux";
import SimpleElectronicSignature from "./SimpleElectronicSignature";

function extendGlobals() {
    Number.prototype.pad = function (size) {
        let s = String(this);
        while (s.length < (size || 2)) {
            s = "0" + s;
        }
        return s;
    }

    Array.prototype.last = function () {
        return this.length ? this[this.length - 1] : null;
    };

    Array.prototype.first = function () {
        return this.length ? this[0] : null;
    };

    Array.prototype.removeAt = function (position) {
        this.splice(position, 1);
    };
}

function App() {
    extendGlobals();

    const user = useSelector(state => state.auth.user);
    const socket = useSelector(state => state.socket.socket);

    const [isConnected, setIsConnected] = useState(true);
    const timeoutId = useRef(null);

    useEffect(() => {
        if (!user) return;

        useSocket()
        document.addEventListener('visibilitychange', handleVisibilityChanges);

        return () => {
            if (socket) {
                socket.disconnect();
            }
            document.removeEventListener('visibilitychange', handleVisibilityChanges);
        }
    }, [user]);

    useEffect(() => {
        if(!socket) return;
        listenNotificationUpdates();
    }, [socket]);

    const handleVisibilityChanges = async () => {
        if (document.visibilityState === 'hidden') {
            const timeout = setTimeout(() => {
                setIsConnected(false);

                if (socket) {
                    socket.disconnect();
                }
            }, 5000);

            timeoutId.current = timeout
        } else {
            if (timeoutId.current) {
                clearTimeout(timeoutId.current);
            }

            if (!isConnected) {
                setIsConnected(true);
                useSocket();
            }
        }
    }

    const onWorkerMessage = debounce((message) => {
        const {sharedWorker} = useSharedWorker();
        const type = message.type;
        const data = JSON.parse(message?.data);

        if (type === "notification") {
            const status = data.status;
            const auctionId = data.id;
            sharedWorker.port.postMessage({type, message: {status}, auctionId});
        }
    }, 1000);

    const listenNotificationUpdates = () => {
        const userId = user?.id;
        socket.onNotification(userId, onWorkerMessage);
    }

    return (
        <div className="App">
            <Router history={history}>
                <ApiExceptionModal/>
                <Switch>
                    <Route exact path="/login" component={Login}/>
                    <Route exact path="/registration" component={Registration}/>
                    <Route exact path="/privacy-policy" component={PrivacyPolicy}/>
                    <Route exact path={"/simple-electronic-signature"} component={SimpleElectronicSignature}/>
                    <Route path="/" component={Layout}/>
                </Switch>
            </Router>

            <ToastWrapper/>
        </div>
    );
}

export default App;
