import React, {Fragment} from "react";
import {Card, Modal} from "react-bootstrap";
import {GUEST, securityService} from "../../services/SecurityService";
import {connect} from "react-redux";
import {HIDE_WELCOME_MODAL} from "../../actions/types";
import "./WelcomeModal.css"

function mapGlobalStateToProps(state) {
    return {
        show: state.showWelcomeModal
    };
}

class WelcomeModal extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const user = securityService.getUser();
        const isGuest = securityService.hasOnlyRoles(user, GUEST);
        const onHide = () => dispatch({type: HIDE_WELCOME_MODAL});
        const {show, dispatch} = this.props;

        if (!isGuest) {
            return <Fragment/>;
        }

        return (
            show === true && <div className={"blur"}>
                <Modal show={show === true} backdrop={false} onHide={onHide} className={"w-60 modal-form"} closeButton>
                    <Modal.Header className={"border-0"} closeButton/>
                    <Card className={"border-0"}>
                        <Card.Body className={"d-flex align-items-center flex-column"}>
                            <div>
                                <img src="/truck.png" alt="truck" className={"p-0 text-center"}/><br/>
                            </div>
                            <div>
                                <h1 className={"modal-text-header mt-2 mb-2"}>Добро пожаловать</h1>
                            </div>
                            <div>
                                <p className={"modal-text-p"}>
                                    <span>● Для продолжения работы в системе, необходимо перейти в меню "Организации" на панели слева.</span><br/>
                                    <span>● Далее добавить анкету, заполнить необходимые поля, и предоставить документы для проверки службой безопасности.</span><br/>
                                    <span>● После подтверждения анкеты, будет добавлена карточка организации и вы сможете добавить в систему собственный транспорт и водителей.</span><br/>
                                    <span>● После проверки службой безопасности транспорта и водителей, вы сможете использовать его для бронирования на рейсы.</span><br/>
                                </p>
                            </div>
                        </Card.Body>
                    </Card>
                </Modal>
            </div>
        );
    }
}


export default connect(mapGlobalStateToProps)(WelcomeModal);
