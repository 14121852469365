export const initialState = {
    auction: {
        id: null,

        currentBid: 0,

        lastBid: 0,
        userBid: 0,

        isLoading: false,
        error: null
    }
};