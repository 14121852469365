
import BaseSelect from "./BaseSelect";
import optionsService from '../../services/OptionsService';

class DriverSelect extends BaseSelect {
	constructor(props) {
		super(props);
	}

	fetchOptions() {
		return optionsService.loadDriverOptionsByContractorId(this.props.optionsType, this.props.contractor.id);
	}
	
	getViewLink() {
		if (["driver"].indexOf(this.props.name) >= 0) {
			return "/control-panel/drivers/driver/?id=" + this.props.value.id;
		}					
		return super.getViewLink();
	}
	
	componentDidUpdate(prevProps) {
		if (prevProps.contractor !== this.props.contractor) {
			this.loaded = false;
			this.onChange(null);
			this.setState({ options: [] });
		}
	}
}

export default DriverSelect;