import React, {PureComponent} from "react";

class LineChart extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            bigNumber: 0,
            percent: 0
        }
    }

    /**
     *  Данные для графика добавляются в виде объекта с полями
     *  { scale: [...{start: date, end: date}], values: [] }
     *  scale - шкала для оси X
     *  values - данные для оси Y (Number)
     * */
    DATA = this.props.data;
    LEFT_LABEL = this.props.leftLabel;
    RIGHT_LABEL = this.props.rightLabel;
    WIDTH = this.props.width ? this.props.width : "500";
    HEIGHT = this.props.height ? this.props.height : "200";
    COLOR = this.props.color ? this.props.color : "red";
    ID = "chart" + Math.random().toString(36);
    FONT_SIZE = {
        large: this.props.fontSize === "sm" ? "medium" : "large",
        xLarge: this.props.fontSize === "sm" ? "medium" : "x-large",
        xxxLarge: this.props.fontSize === "sm" ? "large" : "xxx-large"
    }

    componentDidMount() {
        if (this.DATA) {
            this.chart();
        }

        this.setState({ data: this.props.data });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.DATA && this.props.data !== prevProps.data) {
            this.DATA = this.props.data;
            this.LEFT_LABEL = this.props.leftLabel;
            this.RIGHT_LABEL = this.props.rightLabel;
            document.getElementById(this.ID + "-info").innerHTML = "&nbsp;<br/>&nbsp;";

            this.chart();
        }
    }

    chart() {
        const padding = 10;
        const chartColor = this.COLOR;
        const values = this.DATA.values;
        const length = values.length;
        const scale = this.DATA.scale;
        const period = `${new Date(scale?.[0].start).toLocaleDateString()} — ${new Date(scale?.[scale?.length - 1].end).toLocaleDateString()}`;
        const isPercentNegative = Math.sign(values?.[length - 1]) < 0 && Math.sign(values?.[length - 2]) < 0;
        const percent = Math.round(((values?.[length - 1] / values?.[length - 2]) + (isPercentNegative ? 1 : -1)) * 10000) / 100;
        const marginBottom = values.some(v => Math.sign(v) < 0) ? 50 : 0;
        const chartId = this.ID;
        const pointLineColor = "grey";

        this.setState({
            percent: percent ? percent : 0,
            period: period,
            bigNumber: values?.[length - 1]
        });

        // Клонирование элемента, чтобы избавиться от eventListener
        const element = document.getElementById(chartId);
        const canvas = element.cloneNode(true);
        element.parentNode.replaceChild(canvas, element);

        const context = canvas.getContext("2d");
        context.clearRect(0, 0, canvas.width, canvas.height);
        context.setLineDash([]);

        const width = canvas.width - padding * 2;
        const height = canvas.height - marginBottom;

        const scaleX = width / (length - 1);
        const scaleY = height / Math.max(...values);

        drawOX();

        const points = [];
        for (let i = 0; i < length; i++) {
            points.push({ x: scaleX * i + padding, scale: scale[i], value: values[i] });
        }

        drawChart();
        canvas.addEventListener("mousemove", showInfo);

        function drawOX() {
            context.beginPath();
            context.setLineDash([]);
            context.moveTo(padding, height);
            context.lineTo(width + padding, height);
            context.strokeStyle = "#999";
            context.stroke();
        }

        function drawChart() {
            context.beginPath();
            context.setLineDash([]);
            for (let i = 0; i < length; i++) {
                context.lineTo(scaleX * i + padding, height - values[i] * scaleY);
            }
            context.strokeStyle = chartColor;
            context.stroke();
        }

        function showInfo(event) {
            const mouseX = event.clientX - canvas.getBoundingClientRect().left;

            for (let i = 0; i < points.length; i++) {
                const period = `${new Date(points[i].scale.start).toLocaleDateString()} — ${new Date(points[i].scale.end).toLocaleDateString()}`;
                const point = { x: points[i].x, radius: scaleX / 2, info: `${period}\n${points[i].value.toLocaleString()}` };
                const distance = Math.sqrt(Math.pow(mouseX - point.x, 2));
                let color = "white";

                if (distance <= point.radius) {
                    canvas.title = point.info;
                    document.getElementById(chartId + "-info").innerHTML = `${period}<br>${points[i].value.toLocaleString()}`;

                    // Очистка canvas и повторная отрисовка
                    context.clearRect(0, 0, canvas.width, canvas.height);
                    drawOX();
                    drawChart();

                    // Нанесение вертикальной черты
                    color = pointLineColor;
                    context.beginPath();
                    context.lineWidth = 1;
                    context.moveTo(point.x, 0);
                    context.setLineDash([2, 4]);
                    context.lineTo(point.x, height + marginBottom);
                    context.strokeStyle = color;
                    context.stroke();
                }
            }
        }
    }


    render() {
        if (this.DATA) {
            return <div style={{padding: "15px", width: "fit-content"}}>
                <div style={{display: "flex", justifyContent: "space-between", fontSize: this.FONT_SIZE.large}}>
                    <div>{this.LEFT_LABEL}</div>
                    <div>{this.RIGHT_LABEL}</div>
                </div>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div>
                        <div style={{fontSize: this.FONT_SIZE.xxxLarge}}>
                            {(this.state.bigNumber?.toLocaleString().length > 6
                                ? Math.round(Number(this.state.bigNumber))
                                : Number(this.state.bigNumber))?.toLocaleString()}
                        </div>
                        <div style={{fontSize: this.FONT_SIZE.xLarge}}>
                            {Math.abs(this.state.percent) === Infinity ? "∞" : this.state.percent + "%"}
                        </div>
                    </div>
                </div>
                <canvas id={this.ID}
                        width={this.WIDTH}
                        height={this.HEIGHT}
                        className="canvas-chart">
                </canvas>
                <div style={{textAlign: "center"}}
                     id={this.ID + "-info"}>
                    &nbsp;<br/>&nbsp;
                </div>
            </div>
        }

        return <div>Нет данных для графика</div>
    }
}

export default LineChart;