import React from "react";
import { Row, Col, Form, Card } from "react-bootstrap";
import BaseForm from "../form/BaseForm";
import FileUploader from "../FileUploader";
import Util from "../../Util";
import quoteService from "../../services/QuoteService";
import { securityService } from "../../services/SecurityService";
import FormValidator from "../../validators/FormValidator";
import ValidationErrors from "../../validators/ValidationErrors";
import {Attachment} from "../../Const";
import {setData} from "../../actions/form";

class UploadForm extends BaseForm {
	
	constructor(props) {
		super(props);
		
		this.accompanyingDocumentsValidator = this.accompanyingDocumentsValidator.bind(this);
		this.configureValidators();
	}
	
	configureValidators() {
		this.validator.addValidator("accompanying-documents", this.accompanyingDocumentsValidator);
	}
	
	accompanyingDocumentsValidator() {
		return this.props.model.attachments.filter(a => a.type === Attachment.TYPE_ACCOMPANYING_DOCUMENTS)
					.every(a => !!a.documentType) ? FormValidator.OK : "укажите 'Тип документа' в каждой строке";
	}
	
	handleSubmit(e) {
		const toastData = {show: true, textHeader: "Сканкопия сохранена!", delay: 3000};
		e.preventDefault();
		this.submit(() => {
			quoteService.change({
				id: this.props.model.id,
				version: this.props.model.version,
				attachments: this.props.model.attachments
			}).then(data => {
				this.props.store.dispatch(setData(data, this.props.location.state?.action))
				this.props.setToastObjAC(toastData)
			});
		});
		this.setSending(false);		
	}

	formatFileName(attachment, index) {
		let prefix;
		switch (attachment.type) {
			case Attachment.TYPE_CLIENT_BILL:
				prefix = "Счёт_заказчика";
				break;
			case Attachment.TYPE_ACCOMPANYING_DOCUMENTS:
				prefix = attachment.documentType?.value || "";
				break;
			case Attachment.TYPE_CLIENT_QUOTE:
				prefix = "Заявка_заказчика";
				break;
			case Attachment.TYPE_CARRIER_QUOTE:
				prefix = "Заявка_перевозчика";
				break;
			case Attachment.TYPE_DRIVER_DOCUMENTS:
				prefix = "Документ_водителя";
				break;
			default:
				prefix = "";
		}
		return Util.normalizeString([prefix, this.props.model.id, Util.formatDate(new Date(attachment.dt)), index + 1].join("_"))
							+ Util.getFileNameExtension(attachment.originalName);
	}

	showDocumentType(type) {
		switch (type) {
			case Attachment.TYPE_ACCOMPANYING_DOCUMENTS:
				return true;
			default:
				return false;
		}
	}

	canView(type) {
		const isCarrier = securityService.isCarrier();
		const isClient = securityService.isClient();
		const isDriver = securityService.isDriver();
		switch (type) {
			case Attachment.TYPE_CLIENT_BILL:
				return !isClient && !isCarrier && !isDriver;
			case Attachment.TYPE_ACCOMPANYING_DOCUMENTS:
				return !isClient && !isCarrier && !isDriver;
			case Attachment.TYPE_CLIENT_QUOTE:
				return !isCarrier && !isDriver;
			case Attachment.TYPE_CARRIER_QUOTE:
				return !isClient && !isDriver;
			case Attachment.TYPE_DRIVER_DOCUMENTS:
				return  !isClient;
			default:
				return true;
		}
	}

	render() {
		const uploader = (type) => {
			return <FileUploader
				name={"attachments"}
				store={this.props.store}
				attachments={this.props.attachments}
				type={type}
				showDownloadLink={this.props.user.roles.indexOf("ADMIN") >= 0}
				showDocumentType={this.showDocumentType(type)}
				hideRemove={this.props.hideRemove || this.props.readOnly}
				filesLimit={this.props.readOnly ? FileUploader.MAX_FILES_READ_ONLY : FileUploader.MAX_FILES_PER_QUOTE}
				fileNameFunction={(attachment, index) => this.formatFileName(attachment, index)}
			/>
		};
		return (
			<Form>
				{this.canView(Attachment.TYPE_CLIENT_QUOTE) &&
					<React.Fragment>
						<br/>
						<Card className="client-quote">
							<Card.Header as="h6">Заявка Заказчика</Card.Header>
							<Card.Body>
								<Row>
									<Col>
										{uploader(Attachment.TYPE_CLIENT_QUOTE)}
									</Col>
								</Row>
							</Card.Body>
						</Card>
					</React.Fragment>
				}
				{this.canView(Attachment.TYPE_CARRIER_QUOTE) &&
					<React.Fragment>
						<br/>
						<Card className="carrier-quote">
							<Card.Header as="h6">Заявка Перевозчика</Card.Header>
							<Card.Body>
								<Row>
									<Col>
										{uploader(Attachment.TYPE_CARRIER_QUOTE)}
									</Col>
								</Row>
							</Card.Body>
						</Card>
					</React.Fragment>
				}
				{this.canView(Attachment.TYPE_ACCOMPANYING_DOCUMENTS) &&
					<React.Fragment>
						<br/>
						<Card className="accompanying-documents">
							<Card.Header as="h6">Сопроводительные документы</Card.Header>
							<Card.Body>
								<Row>
									<Col>
										{uploader(Attachment.TYPE_ACCOMPANYING_DOCUMENTS)}
									</Col>
								</Row>
								<ValidationErrors errors={this.props.errors["accompanying-documents"]} />
							</Card.Body>
						</Card>
					</React.Fragment>
				}
				{this.canView(Attachment.TYPE_CLIENT_BILL) &&
					<React.Fragment>
						<br/>
						<Card className="client-quote">
							<Card.Header as="h6">Счёт заказчика</Card.Header>
							<Card.Body>
								<Row>
									<Col>
										{uploader(Attachment.TYPE_CLIENT_BILL)}
									</Col>
								</Row>
							</Card.Body>
						</Card>
					</React.Fragment>
				}
				{this.canView(Attachment.TYPE_DRIVER_DOCUMENTS) &&
					<React.Fragment>
						<br/>
						<Card className="driver-docs">
							<Card.Header as="h6">Документы водителя</Card.Header>
							<Card.Body>
								<Row>
									<Col>
										{uploader(Attachment.TYPE_DRIVER_DOCUMENTS)}
									</Col>
								</Row>
							</Card.Body>
						</Card>
					</React.Fragment>
				}
				<br/>
				{!this.props.readOnly && <Row className="justify-content-center">
					<Col className="col-auto">
						{this.renderSaveButton()}
					</Col>					
				</Row>}
			</Form>		
		);
	}

}

export default UploadForm;
