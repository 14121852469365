import React, {Fragment} from "react";
import {Card, Col, Form, Row, Tab} from "react-bootstrap";
import {connect} from "react-redux";
import surveyService from "../../../../services/survey/SurveyService";
import StoreWrapper from "../../../form/StoreWrapper";
import BaseForm from "../../../form/BaseForm";
import FormGroup from "../../../form/FormGroup";
import {setData} from "../../../../actions/form";
import {ADMIN, APPROVAL, securityService} from "../../../../services/SecurityService";
import SurveyFileUploader from "../../SurveyFileUploader";
import surveyAttachmentService from "../../../../services/survey/SurveyAttachmentService";
import Button from "react-bootstrap/Button";
import Enums from "../../../../Enums";
import './SurveyForm.css'
import {SET_TOAST_OBJ} from "../../../../actions/types";
import {setToastObjAC} from "../../../../reducers/toastObj";
import {
    emailValidator,
    innValidator,
    kppValidator,
    ogrnValidator,
    requiredValidator,
    snilsValidator,
    passportNumberValidator,
    vehicleNumberValidator, licenseValidator, phoneValidator
} from "../../../../validators/simpleValidators";
import SurveyAttachmentWarning from "../SurveyAttachmentWarning";
import contractorService from "../../../../services/ContractorService";
import ReadOnlyContainer from "../../../ReadOnlyContainer";
import ValidationTable from "../ValidationTable";
import Loading from "../../../Loading";
import WarningMessage from "../../../form/WarningMessage";
import TextEditor from "../../../tasktracker/DraftEditor/TextEditor";
import draftToHtml from "draftjs-to-html";
import {convertToRaw} from "draft-js";
import FormValidator from "../../../../validators/FormValidator";
import Contractor from "../../../control-panel/contractors/Contractor";
import OverlayNumberHint from "../overlay/OverlayNumberHint";

class SurveyForm extends StoreWrapper {
    constructor(props) {
        super(props);
    }

    static get FIELD_ID() { return "id" };
    static get FIELD_TYPE() { return "type" };
    static get FIELD_STATUS() { return "status" };
    static get FIELD_SOLUTION() { return "solution" };
    static get FIELD_SECURITY_MANAGER() { return "securityManager" };
    static get FIELD_METADATA() { return "metadata" };
    static get FIELD_COMMENT() { return "comment" };
    static get FIELD_RESULT_COMMENT() { return "resultComment" };
    static get FIELD_CODE_FILES() { return "FILES" };
    static get FIELD_TYPE_FILE() { return "file" };
    static get FILED_CONTRACTOR_TYPE() { return "contractorType" }

    render() {
        return (
            <SurveyFormInnerConnected {...this.props} store={this.store} />
        );
    }
}

function mapStateToProps(state) {
    return {
        model: state.model,
        errors: state.errors,
        user: state.user
    };
}

class SurveyFormInner extends BaseForm {
    constructor(props) {
        super(props);

        this.state = {
            type: {},
            formProps: [],
            attachments: [],
            userContractors: [],
            loading: true,
            isApproved: false,
            errors: [],
            statusAfterSaving: null,
            solutionAfterSaving: null
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.applyApprovalValidator = this.applyApprovalValidator.bind(this);
    }

    getTabs() {
        return [this.getAttachmentTab()];
    }

    getAttachmentTab() {
        const {formProps} = this.state;
        return (
            <Tab key="attachment" eventKey="attachment" title="Вложения">
                {this.renderFileCard(formProps)}
            </Tab>
        );
    }

    getFormTabTitle() {
        return "Анкета";
    }

    renderForm() {
        if (this.state.loading) {
            return <Loading/>
        }
        const {formProps} = this.state;
        return (
            <Form>
                <Row>
                    {this.renderSurveyCard(formProps)}
                </Row>
            </Form>
        );
    }

    renderSurveyCard(fields) {
        const readOnly = this.isApprovedOrClosed() || this.isCompletedOrCanceled();
        const {solution} = this.props.model;
        const solutionValue = solution ? solution.value : "Не согласована";
        return (
            <Card className={"form-card survey-card"}>
                <Card.Header className="form-card-header shadow-bottom">
                    <h4>Анкета {this.getSurveyLabel()} - {this.getSurveyStatusLabel()} - {solutionValue}</h4>
                    {this.renderComment()}
                </Card.Header>
                <Card.Body>
                    <ReadOnlyContainer readOnly={readOnly}>
                        {this.renderSurveyColumns(fields)}
                    </ReadOnlyContainer>
                    <ReadOnlyContainer readOnly={this.isApprovedOrClosed()}>
                        {this.renderFooter()}
                    </ReadOnlyContainer>
                    {this.renderSurveyButtons()}
                </Card.Body>
            </Card>
        );
    }

    renderComment() {
        const {comment} = this.props.model;
        if (this.commentHasText(comment)) {
            return (
                <WarningMessage
                    variant="warning"
                    title="Комментарий службы безопасности:"
                    message={<div dangerouslySetInnerHTML={{__html: this.formatComment(comment)}}></div>}/>
            );
        }
    }

    commentHasText(comment) {
        let tempDiv = document.createElement('div');
        tempDiv.innerHTML = this.formatComment(comment);
        let elements = tempDiv.querySelectorAll('*');
        let hasText = false;
        elements.forEach(element => {
            if (element.textContent.trim() !== '') {
                hasText = true;
            }
        });
        return hasText;
    }

    getSurveyLabel() {
        const type = this.getSurveyType();
        let label;
        switch (type?.value) {
            case Enums.SURVEY_TYPE.ORGANIZATION.value: {
                label = "организации";
                break;
            }
            case Enums.SURVEY_TYPE.DRIVER.value: {
                label = "водителя";
                break;
            }
            case Enums.SURVEY_TYPE.VEHICLE.value: {
                label = "транспортного средства";
                break;
            }
            case Enums.SURVEY_TYPE.CONTACT_PERSON.value: {
                label = "контактного лица";
                break;
            }
            default: {
                label = null
            }
        }
        return label;
    }

    getSurveyStatusLabel() {
        const status = this.props.model.status;
        const label = Enums.SURVEY_STATUS[status?.value]?.label || Enums.SURVEY_STATUS.NEW.label;
        let variant;
        switch (status?.value) {
            case Enums.SURVEY_STATUS.NEW.value:
            case Enums.SURVEY_STATUS.IN_PROCESS.value: {
                variant = "warning"
                break;
            }
            case Enums.SURVEY_STATUS.ELIMINATE_COMMENTS.value: {
                variant = "danger"
                break;
            }
            case Enums.SURVEY_STATUS.COMMENTS_ELIMINATED.value:
            case Enums.SURVEY_STATUS.COMPLETE.value:
            case Enums.SURVEY_STATUS.CANCEL.value: {
                variant = "success"
                break;
            }
            default: variant = "warning";
        }

        return <span className={`text-${variant}`}>{label}</span>
    }

    renderSurveyButtons() {
        const status = this.props.model.status?.value;
        const statuses = [Enums.SURVEY_STATUS.COMPLETE.value, Enums.SURVEY_STATUS.CANCEL.value];
        return (
            <Row className={"d-flex justify-content-center mt-1"}>
                {!this.isApprovedOrClosed() &&
                    <div>
                        {this.renderSaveButton()}
                        &nbsp;
                        {this.renderCancelButton("Выйти")}
                    </div>
                }
                {status === Enums.SURVEY_STATUS.ELIMINATE_COMMENTS.value &&
                    this.renderChangeStatusButton("Замечания устранены", "success", Enums.SURVEY_STATUS.COMMENTS_ELIMINATED.value)
                }
                {this.getSurveyId() && !statuses.includes(status) &&
                    this.renderChangeStatusButton("Отменить подачу анкеты", "secondary", Enums.SURVEY_STATUS.CANCEL.value)
                }
            </Row>
        );
    }

    renderFooter() {
        return (
            <div>
                {this.renderSurveyStatusAndSolution()}
                {this.renderValidationTable()}
                {this.renderComments()}
            </div>
        );
    }

    renderSurveyStatusAndSolution() {
        if (!this.isAdminOrApproval()) {
            return <Fragment/>
        }
        return (
            <Card className={"form-card survey-status"}>
                <Card.Header className={"form-card-header shadow-bottom p-0"}>
                    <Row>
                        {this.renderStatus()}
                        {this.renderSolution()}
                        {this.renderSecurityManager()}
                    </Row>
                </Card.Header>
                <Card.Body/>
            </Card>
        );
    }

    renderStatus() {
        return (
            <Col>
                <FormGroup
                    title="Статус анкеты"
                    name={SurveyForm.FIELD_STATUS}
                    type="enum"
                    optionsType="SURVEY_STATUS"
                    store={this.props.store}
                    onChangeHandler={() => this.onChange(SurveyForm.FIELD_SOLUTION, this.formatSolution("false"))}
                    readOnly={this.isApprovedOrClosed()}
                />
            </Col>
        );
    }

    renderSolution() {
        const fetchOptions = Promise.resolve(
            [{id: 1, value: "Согласована", result: true}, {id: 2, value: "Не согласована", result: false}]
        );
        const currentStatus = this.props.model.status;
        const isCompleted = Enums.SURVEY_STATUS.COMPLETE.value === currentStatus?.value;
        return (
            <Col>
                <FormGroup
                    title="Решение"
                    name={SurveyForm.FIELD_SOLUTION}
                    type="enum"
                    fetchOptions={fetchOptions}
                    store={this.props.store}
                    readOnly={!isCompleted || this.isApprovedOrClosed()}
                />
            </Col>
        );
    }

    renderSecurityManager() {
        return (
            <Col>
                <FormGroup
                    title="Менеджер СБ"
                    name={SurveyForm.FIELD_SECURITY_MANAGER}
                    type="user"
                    role="APPROVAL"
                    readOnly={false}
                    store={this.props.store}
                    required={!this.isNew()}
                />
            </Col>
        );
    }

    renderChangeStatusButton(title, variant, status) {
        const user = securityService.getUser();
        const isCarrier = securityService.isCarrier(user);
        if (!isCarrier) {
            return <Fragment/>
        }
        return (
            <Button variant={variant} size="sm" className="ml-1" onClick={() => this.handleUpdateStatus(status)}>
                {title}
            </Button>
        );
    }

    async handleUpdateStatus(status) {
        const success = await surveyService.updateStatus(this.getSurveyId(), status);
        if (success) {
            this.onChange(SurveyForm.FIELD_STATUS, {id: 0, value: status});
            await this.surveySave();
        } else {
            this.showToast(false, "Ошибка", "Не удалось сохранить анкету");
        }
    }

    renderSurveyColumns(fields) {
        const filteredFields = fields.filter(field => this.excludeFilter(field));
        let rows = [];
        for (let i = 0; i < filteredFields.length; i += 4) {
            let rowFields = filteredFields.slice(i, i + 4);
            rows.push(rowFields);
        }
        return rows.map((fields, index) => (
            <Row key={index}>
                {this.renderFields(fields)}
            </Row>
        ));
    }

    excludeFilter(field) {
        return !this.typeIsFile(field.type) && field.name !== SurveyForm.FILED_CONTRACTOR_TYPE;
    }

    renderFields(fields) {
        return fields.map(field => this.renderFormGroup(field));
    }

    renderFormGroup(field) {
        return (
            <Col lg={3}>
                <FormGroup title={field.title}
                           name={this.formatFieldName(field.name)}
                           type={field.type}
                           optionsType={field.optionsType}
                           multiSelect={field.multiSelect}
                           store={this.props.store}
                           required={field.required}
                           markId={this.getValueFromModel("vehicleMark.id")}
                           readOnly={this.readOnly(field)}
                           renderCustomButton={this.renderOverlayNumberHint.bind(this, field)}
                           maxLength={field.maxLength}
                           mask={field.mask}
                />
            </Col>
        );
    }

    readOnly(field) {
        let readonly;
        switch (field.name) {
            case "newTypeVehicleModel":
                readonly = !this.getValueFromModel("vehicleMark.id");
                break;
            default:
                readonly = false;
        }
        return readonly;
    }

    renderOverlayNumberHint(field) {
        if (field.name === "number") {
            return <OverlayNumberHint/>;
        }
        return <Fragment/>
    }

    renderValidationTable() {
        if (!this.isAdminOrApproval()) {
            return <Fragment/>
        }

        const {errors} = this.state;
        if (errors && errors.length !== 0) {
            return <ValidationTable errors={errors}/>;
        }
    }

    renderComments() {
        if (!this.isAdminOrApproval()) {
            return <Fragment/>
        }
        return (
            <Row>
                <Col>
                    {this.renderApprovalComment()}
                </Col>
                <Col>
                    {this.renderResultComment()}
                </Col>
            </Row>
        );
    }

    renderApprovalComment() {
        const {comment} = this.props.model;
        if (this.isApprovedOrClosed()) {
            return (
                <Card style={{minHeight: "120px", maxHeight: "200px"}}>
                    <Card.Header>Комментарий для перевозчика</Card.Header>
                    <Card.Body>
                        <div dangerouslySetInnerHTML={{__html: this.formatComment(comment)}}></div>
                    </Card.Body>
                </Card>
            );
        } else {
            return (
                <div className={"text-editor"}>
                    <TextEditor
                        title="Комментарий для перевозчика" value={comment}
                        onEditorStateChange={value => this.onChange(SurveyForm.FIELD_COMMENT, value)}
                        minHeight={"70px"}
                    />
                </div>
            );
        }
    }

    renderResultComment() {
        const {resultComment} = this.props.model;
        if (this.isApprovedOrClosed()) {
            return (
                <Card style={{minHeight: "120px", maxHeight: "200px"}}>
                    <Card.Header>Результат проверки</Card.Header>
                    <Card.Body>
                        <div dangerouslySetInnerHTML={{__html: this.formatComment(resultComment)}}></div>
                    </Card.Body>
                </Card>
            );
        } else {
            return (
                <div className={"text-editor"}>
                    <TextEditor
                        title="Результат проверки" value={resultComment}
                        onEditorStateChange={value => this.onChange(SurveyForm.FIELD_RESULT_COMMENT, value)}
                        minHeight={"70px"}
                    />
                </div>
            );
        }
    }

    renderFileCard(fields) {
        const someFieldsIsFile = fields.some(field => this.typeIsFile(field.type))
        if (!someFieldsIsFile) {
            return <Fragment/>
        }
        return (
            <Form>
                <Card className={"form-card"}>
                    <Card.Header className="form-card-header shadow-bottom">
                        <h4>Вложения</h4>
                    </Card.Header>
                    <Card.Subtitle className={"mt-1 ml-1"}>
                        <SurveyAttachmentWarning surveyType={this.getSurveyType()}/>
                    </Card.Subtitle>
                    <Card.Body>
                        {this.renderFilesColumn(fields)}
                    </Card.Body>
                </Card>
            </Form>
        );
    }

    renderFilesColumn(fields) {
        return fields.filter(field => this.typeIsFile(field.type)).map(field => {
            return this.renderFileUploader(field);
        });
    }

    renderFileUploader(field) {
        return (
            <Row>
                <Col>
                    <SurveyFileUploader {...this.props}
                        store={this.props.store}
                        name={this.formatFieldName(field.name)}
                        title={field.title}
                        surveyId={this.getSurveyId()}
                        surveyType={this.getSurveyType()?.value}
                        propertyCode={SurveyForm.FIELD_CODE_FILES}
                        hideDropzpne={this.isApprovedOrClosed()}
                    />
                </Col>
            </Row>
        );
    }

    async componentDidMount() {
       await this.initialize();
    }

    async initialize() {
        await this.initSurveyType();
        await this.prepareStates();
        await this.initSurveyModel();
        this.configureValidators();
    }

    async initSurveyType() {
        const type = localStorage.getItem("surveyType") || this.props.type;
        this.setState({type: JSON.parse(type)});
    }

    configureValidators() {
        this.configureCommonsValidators();
        this.configureVehicleValidators();
        this.configureOrganizationValidators()
        this.configureDriverValidators()
    }

    configureCommonsValidators() {
        this.useValidatorFor(requiredValidator, ...this.getRequiredFields());
        this.useValidatorFor(emailValidator, this.formatFieldName("email"));
        this.useValidatorFor(phoneValidator, this.formatFieldName("phone"));
        this.useValidatorFor(innValidator, this.formatFieldName("inn"));
        this.useValidatorFor(passportNumberValidator, this.formatFieldName("passportNumber"));
        this.validator.addValidator('securityManager', this.applyApprovalValidator(this.securityManagerValidator.bind(this)));
    }

    configureVehicleValidators() {
        const isVehicle = Enums.SURVEY_TYPE.VEHICLE.value === this.getSurveyType().value;
        if (isVehicle) {
            this.useValidatorFor(vehicleNumberValidator.bind(this), this.formatFieldName("number"));
            this.validator.addValidator(this.formatFieldName("vin"), this.vehicleVinValidator.bind(this, "vin"));
        }
    }

    vehicleVinValidator() {
        const vin = this.getValueFromModel("vin");
        const valid = !vin || vin.match(/^\w{17}$/);
        return Promise.resolve(valid ? FormValidator.OK : "некорректный формат VIN");
    }

    configureOrganizationValidators() {
        const isOrganization = Enums.SURVEY_TYPE.ORGANIZATION.value === this.getSurveyType().value;
        if (isOrganization) {
            this.useValidatorFor(ogrnValidator, this.formatFieldName("ogrn"));
            this.useValidatorFor(kppValidator, this.formatFieldName("kpp"));
        }
    }

    configureDriverValidators() {
        const isDriver = Enums.SURVEY_TYPE.DRIVER.value === this.getSurveyType().value;
        if (isDriver) {
            this.useValidatorFor(licenseValidator, this.formatFieldName("license"));
            this.useValidatorFor(snilsValidator, this.formatFieldName("snils"));
        }
    }

    applyApprovalValidator(validator) {
        return value => this.approvalValidator(validator, value);
    }

    async approvalValidator(validator, value) {
        return this.isAdminOrApproval() ? validator(value) : FormValidator.OK;
    }

    securityManagerValidator() {
        const isNew = Enums.SURVEY_STATUS.NEW.value === this.props.model.status?.value;
        if (isNew) {
            return FormValidator.OK;
        }
        return this.props.model.securityManager ? FormValidator.OK : "Обязательное поле";
    }

    async prepareStates() {
        const formProps = await this.getFormProperties();
        this.setState({formProps: formProps});

        const user = securityService.getUser();
        const userContractors = await contractorService.optionsByUser(user.id);
        this.setState({userContractors: userContractors});

        if (!this.isNew()) {
            const attachments = await surveyAttachmentService.getAttachmentsBySurveyId(this.getSurveyId());
            this.setState({attachments: attachments});
        }
    }

    async getFormProperties() {
        const type = this.getSurveyType();
        const formProps = await surveyService.getForm(type.value);

        return formProps
            .sort((field1, field2) => field1.sort - field2.sort)
            .map(field => this.formatProp(field));
    }

    formatProp(prop) {
        return {
            title: prop.name,
            name: prop.code,
            type: prop.fieldType,
            optionsType: prop.optionType,
            multiSelect: prop.multiSelect,
            required: prop.required,
            maxLength: prop.maxLength,
            mask: prop.mask || undefined
        };
    }

    async initSurveyModel() {
        if (this.isNew()) {
            this.initDefaultValues();
            this.setState({loading: false});
            return;
        }

        const survey = await surveyService.read(this.getSurveyId());
        const status = this.formatStatus(survey.status);
        const solution = this.formatSolution(survey.solution);
        this.setState({statusAfterSaving: status, solutionAfterSaving: solution});

        survey.status = status;
        survey.solution = solution;
        survey.metadata.FILES = this.state.attachments;

        this.props.store.dispatch(setData(survey, this.props.location.state?.action));
        this.setState({loading: false});
    }

    initDefaultValues() {
        const {formProps} = this.state;
        const metadata = {};
        formProps.forEach(prop => {
            metadata[prop.name] = this.typeIsFile(prop.type) ? [] : '';
        });
        this.onChange(SurveyForm.FIELD_METADATA, metadata);
    }

    getSurveyType() {
        const {model} = this.props;
        return this.state.type || Enums.SURVEY_TYPE[model.type];
    }

    getSurveyId() {
        const {model} = this.props;
        return this.fromLocationState(SurveyForm.FIELD_ID) || model.id;
    }

    fromLocationState(field) {
        return new URLSearchParams(this.props.location.search).get(field);
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setSending(true);
        this.submit(() => this.isAdminOrApproval() ? this.adminUpdate() : this.surveySave());
    }

    async adminUpdate() {
        const surveyRequest = this.buildSurveyRequest(this.props.model);
        const adminRequest = {
            status: surveyRequest.status,
            securityManager: surveyRequest.securityManager,
            comment: surveyRequest.comment,
            resultComment: surveyRequest.resultComment,
            solution: surveyRequest.solution
        };

        surveyService.adminUpdate(this.getSurveyId(), surveyRequest, adminRequest).then(response => {
            if (this.isAdminOrApproval()) {
                const status = this.formatStatus(response.status);
                const solution = this.formatSolution(response.solution);
                this.setState({statusAfterSaving: status, solutionAfterSaving: solution});

                const errors = response?.errors;
                if (errors && errors.length !== 0) {
                    this.setErrors(errors);
                    this.showToast(false, "Внимание!", "Есть ошибки валидации");
                    return;
                }
            }

            this.setErrors([]);
            this.showToast(true, "Операция успешна","Анкета сохранена");
        });
    }

    async surveySave() {
        const surveyRequest = this.buildSurveyRequest(this.props.model);

        surveyService.save(this.getSurveyId(), surveyRequest).then(response => {
            const {surveyId} = response[0];
            this.onChange(SurveyForm.FIELD_ID, surveyId);
            this.setErrors([]);
            this.showToast(true, "Операция успешна","Анкета сохранена");
            this.initialize();
        });
    }

    formatStatus(status) {
        const value = status instanceof Object ? status.value : status;
        return {id: 0, value: value};
    }

    formatSolution(solution) {
        const value = solution === "true" ? "Согласована" : "Не согласована";
        return {id: 0, value: value, result: solution === "true"};
    }

    formatComment(comment) {
        if (comment && typeof comment != "string") {
            comment = draftToHtml(convertToRaw(comment.getCurrentContent()));
        }
        return comment;
    }

    showToast(success, header, body) {
        const className = success ? "bg-success" : "bg-danger";
        const data = {show: true, textHeader: header, textBody: body, delay: 3000, className: className };
        this.props.dispatch({type: SET_TOAST_OBJ, data});
    }

    buildSurveyRequest(model) {
        const user = securityService.getUser();
        const type = this.getSurveyType();
        return {
            crmUserId: user.id,
            type: type.value,
            status: this.isNew() ? 'NEW' : model.status.value,
            solution: model.solution?.result || false,
            securityManager: model.securityManager,
            comment: this.formatComment(model.comment),
            resultComment: this.formatComment(model.resultComment),
            metadata: Object.entries(model.metadata).map(([key, value]) => this.formatMetadata(key, value))
        };
    }

    formatMetadata(key, value) {
        const maskedFields = ["passportNumber", "snils", "phone", "license"];
        if (key === SurveyForm.FIELD_CODE_FILES) {
            value = value.map(v => v.id);
        } else if (maskedFields.includes(key)) {
            value = value ? value.replace(/\D/g, '') : '';
        }
        return {key, value: value};
    }

    getValueFromModel(field) {
        let propertyName = this.formatFieldName(field);
        let obj = this.props.model;
        let propsArray = propertyName.split('.');
        return propsArray.reduce((acc, cur) => acc && acc[cur], obj);
    }

    formatFieldName(field) {
        return `${SurveyForm.FIELD_METADATA}.${field}`;
    }

    getRequiredFields() {
        const {formProps} = this.state;
        return formProps.filter(prop => prop.required).map(prop => this.formatFieldName(prop.name));
    }

    setErrors(errors) {
        this.setState({errors: errors});
        if (errors && errors.length !== 0) {
            this.onChange(SurveyForm.FIELD_SOLUTION, this.formatSolution("false"));
        }
    }

    isNew() {
        return !this.getSurveyId();
    }

    isApprovedOrClosed() {
        const isApproved = this.state.solutionAfterSaving?.result === true;
        const status = this.state.statusAfterSaving?.value;
        const isClosed = status === Enums.SURVEY_STATUS.CANCEL.value;

        return isApproved || isClosed;
    }

    isCompletedOrCanceled() {
        const statuses = [Enums.SURVEY_STATUS.COMPLETE.value, Enums.SURVEY_STATUS.CANCEL.value];
        return statuses.includes(this.state.statusAfterSaving?.value);
    }

    typeIsFile(type) {
        return type === SurveyForm.FIELD_TYPE_FILE;
    }

    isAdminOrApproval() {
        const user = securityService.getUser();
        return securityService.anyRoles(user, ADMIN, APPROVAL);
    }
}

const SurveyFormInnerConnected = connect(mapStateToProps, {setToastObjAC})(SurveyFormInner);
export default connect(mapStateToProps)(SurveyForm);
