import BaseSelect from "./BaseSelect";
import optionsService from '../../services/OptionsService';
import contractService from "../../services/ContractService";

class ContractSelect extends BaseSelect {
	constructor(props) {
		super(props);
	}

	fetchOptions() {
		return contractService.options(this.props.contractor.id, this.props.contractorTypeId, this.props.organization.id);
	}
	
	componentDidUpdate(prevProps) {
		if (prevProps.contractor?.id !== this.props.contractor?.id || prevProps.organization?.id !== this.props.organization?.id) {
			this.loaded = false;
			this.onChange(optionsService.createEmptyOption());
			this.setState({ options: [] });
		}
	}
}

export default ContractSelect;