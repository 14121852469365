import React, {PureComponent} from "react";
import {Card} from "react-bootstrap";
import {
    fetchData,
    fetchDataFailure,
    fetchDataSuccess,
    makeBid,
    makeBidFailure,
    makeBidSuccess,
    setCurrentBid,
    setData,
    setErrors,
} from "../../../store/AuctionStore/auctionActions";
import {connect} from "react-redux";
import {AuctionService} from "../../../services/AuctionService/AuctionService";
import Timer from "../../ui/Timer/Timer";
import cls from './Auction.module.css'
import {ToastService} from "../../../services/ToastService/ToastService";
import AuctionHistory from "../AuctionHistory/AuctionHistory";
import AuctionResult from "../AuctionResult/AuctionResult";
import debounce from "lodash/debounce"
import {STATUSES} from "../consts/consts";
import {AuctionBidInput} from "../AuctionBidInput/AuctionBidInput";
import {AuctionInfo} from "../AuctionInfo/AuctionInfo";
import {AuctionBidButton} from "../AuctionButtons/AuctionBidButton/AuctionBidButton";
import {AuctionBuyoutButton} from "../AuctionButtons/AuctionBuyoutButton/AuctionBuyoutButton";
import {AuctionLastBidRow} from "../AuctionLastBidRow/AuctionLastBidRow";
import {AUCTION_NDS_OPTIONS} from "../../../store/AuctionFormStore/auctionFormInitialState";
import {AuctionFirstPrice} from "../AuctionFirstPrice/AuctionFirstPrice";
import {AuctionNdsType} from "../AuctionNdsType/AuctionNdsType";
import {useAuction} from "../../../hooks/useAuction";
import {
    fetchBidsHistoryData, fetchBidsHistoryDataFailure, fetchBidsHistoryDataSuccess,

} from "../../../store/BidsHistoryStore/bidsHistoryActions";

class Auction extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            worker: null,
            auctionIds: []
        }
    }

    async componentDidMount() {
        const {
            auctionId,
            userRoles
        } = this.props;

        if (auctionId) {
            const auctionData = await this.fetchAuctionData(auctionId);

            if (userRoles.includes("ADMIN") && auctionData?.status !== "planned") {
                await this.getBidsHistoryHandler(auctionId, auctionData.timeStart)
            }

            this.state.worker = useAuction(this.onWorkerMessageHandler, auctionId);
        }
    }

    componentWillUnmount() {
        if (this.state.worker && this.state.worker.port) {
            const worker = this.state.worker;
            worker.port.postMessage({type: 'closePort'})
        }
    }

    fetchAuctionData = async (auctionId) => {
        const {
            fetchData,
            fetchDataSuccess,
            fetchDataFailure,
        } = this.props;

        fetchData();
        const {data, error} = await AuctionService.getAuctionById(auctionId);

        if (data) {
            const formattedData = this.formatAuctionData(data);
            fetchDataSuccess(formattedData);
            return formattedData;
        }

        if (error) {
            fetchDataFailure(error);
        }
    }

    formatAuctionData = (data) => ({
        firstPrice: data.firstPrice / 100,
        status: data.status,
        timeStart: data.timeStart,
        timeFinish: data.timeFinish,
        bidType: data.bidType,
        lastBid: data.lastBid?.price && data.lastBid.price / 100,
        lastUserBid: data.lastUserBid?.price && data.lastUserBid.price / 100,
        minPrice: data.auctionOptions?.minPrice && data.auctionOptions.minPrice / 100,
        isBuyoutAvailable: !!data.auctionOptions.minPrice,
        isOneBidOnly: data.auctionOptions.isSingleBid,
        bidStep: data.bidType === "fixed" && data.auctionOptions.bidStep / 100,
        currentBid: data.lastBid?.price ? data.lastBid.price / 100 : data.firstPrice / 100,
        auctionNdsType: data?.auctionOptions?.nds
            ? AUCTION_NDS_OPTIONS[1].value
            : AUCTION_NDS_OPTIONS[0].value
    })

    getBidsHistoryHandler = async (auctionId, timeStart) => {
        const {
            fetchBidsHistoryData,
            fetchBidsHistoryDataSuccess,
            fetchBidsHistoryDataFailure
        } = this.props;

        fetchBidsHistoryData();
        const {data, error} = await AuctionService.getBidsHistory(auctionId);

        if (data) {
            const formattedData = this.formatBidsHistoryHandler(data, timeStart);
            fetchBidsHistoryDataSuccess({bidsHistory: formattedData});
        }

        if (error) {
            fetchBidsHistoryDataFailure(error);
        }
    }

    onWorkerMessageHandler = async (data) => {
        const {message, type} = data;
        const {
            auctionId,
            timeStart,
            userRoles,
            setData,
            status
        } = this.props;

        console.log("WORKER", message, type, 123)

        if (type === "subscription") {
            this.state.auctionIds = JSON.parse(message.ports);
            await this.listenAuctionUpdates(this.state.auctionIds);
        }

        if (type === "bid") {
            const {bid} = message;
            setData({lastBid: bid});

            ToastService.info({
                title: "Новая ставка",
                message: `Ставка обновлена, новое значение ставки: ₽${bid}`
            });

            if (userRoles.includes("ADMIN")) {
                await this.getBidsHistoryHandler(auctionId, timeStart);
            }
        }

        if (type === "lot") {
            const {status: statusFromSocket, id} = message;
            if(auctionId !== id) return;

            if (status !== statusFromSocket) {
                setData({status: statusFromSocket});
                ToastService.warning({
                    title: "Статус изменился",
                    message: `У аукциона изменился статус. Новый статус: ${STATUSES[statusFromSocket]}`
                });
            }
        }
    }

    onMessage = debounce((message) => {
        const worker = this.state.worker;

        if (worker) {
            const type = message.type;
            const data = JSON.parse(message?.data);

            if (type === "lot") {
                const status = data.status;
                const auctionId = data.id;
                worker.port.postMessage({type, message: {id: auctionId, status}, auctionId})
            }

            if (type === "bid") {
                const lastBid = data.bid / 100;
                const auctionId = data.id;
                worker.port.postMessage({type, message: {bid: lastBid}, auctionId})
            }
        }
    }, 1000);

    listenAuctionUpdates = async (auctionIds) => {
        const {socket} = this.props;
        auctionIds.forEach(auctionId => socket.socket.onBid(auctionId, this.onMessage))
        auctionIds.forEach(auctionId => socket.socket.onLot(auctionId, this.onMessage))
    }

    formatBidsHistoryHandler = (bids, timeStart) => {
        const timeCurrent = Date.now();
        const formattedBids = [];

        bids.forEach(bid => {
            formattedBids.push({
                date: bid.createdAt,
                name: bid.fio,
                bid: bid?.price / 100,
                organisation: bid.organization
            })
        })

        if (timeCurrent > new Date(timeStart)) {
            formattedBids.push({
                date: timeStart,
            })
        }

        return formattedBids;
    }

    makeBidHandler = async () => {
        const {
            currentBid,
            setErrors,
            lastBid,
            firstPrice
        } = this.props;

        if (currentBid <= 0) {
            const message = "Ставка не может быть пустой";
            ToastService.error({message});
            setErrors({general: message});
            return;
        }

        if (currentBid >= lastBid || currentBid >= firstPrice) {
            const message = "Вы не можете поставить больше текущей цены";
            ToastService.error({message});
            setErrors({general: message});
            return;
        }

        const bid = currentBid * 100;
        await this.handleMakeBid(bid)
    }

    redeemLotHandler = async () => {
        const {minPrice} = this.props;
        await this.handleMakeBid(minPrice * 100);
    }

    handleMakeBid = async (value) => {
        const {
            makeBid,
            makeBidSuccess,
            makeBidFailure,
            auctionId,
            setErrors,
        } = this.props;

        setErrors(null);
        makeBid();

        const reqData = {
            id: auctionId,
            bid: value
        };

        const {data, error} = await AuctionService.makeBid(reqData);

        if (data) {
            makeBidSuccess(data);

            const message = "Ставка принята успешно";
            ToastService.success({message});
        }

        if (error) {
            const err = error.errors;
            const message = "Ошибка при создании ставки";
            ToastService.error({message});
            makeBidFailure(err);
        }
    }

    isBidButtonsDisabled = () => {
        const {
            isLoading,
            status,
            lastBid,
            lastUserBid,
            isSingleBid,
        } = this.props;

        return (
            isLoading ||
            status !== "active" ||
            (lastBid === lastUserBid) && isSingleBid
        );
    }

    render() {
        const {
            currentBid,
            setCurrentBid,

            isLoading,
            error,

            requestData,

            userRoles,

            timeStart,
            timeFinish,
            firstPrice,
            bidType,
            status,
            lastBid,
            lastUserBid,
            minPrice,
            bidStep,
            auctionNdsType,

            bidsHistory,
        } = this.props;

        const isTimer =
            Date.now() < new Date(timeFinish) &&
            status !== "canceled" &&
            status !== "completed";

        const deadlineDate = status === "planned" ? timeStart : timeFinish;

        return (
            <div className={cls.wrapper}>
                <div className={cls.auction}>
                    <AuctionInfo
                        requestData={requestData}
                        timeStart={timeStart}
                        timeFinish={timeFinish}
                    />

                    <div className={cls.auctionRight}>
                        <AuctionResult
                            status={status}
                            isWinner={lastBid === lastUserBid}
                        />

                        {
                            (isTimer && deadlineDate) && (
                                <Card className={cls.auctionTimer}>
                                    <Card.Body className={cls.auctionTimerBody}>
                                        <Card.Title className={cls.timerTitle}>
                                            {
                                                status === "planned"
                                                    ? "До начала аукциона"
                                                    : "До конца аукциона"
                                            }
                                        </Card.Title>

                                        <Timer deadline={deadlineDate}/>
                                    </Card.Body>
                                </Card>
                            )
                        }

                        <Card className={cls.firstPrice}>
                            <Card.Body>
                                <AuctionFirstPrice
                                    price={firstPrice}
                                />

                                <AuctionNdsType
                                    nds={`${auctionNdsType === "nds" ? "с" : "без"}  НДС`}
                                    className={cls.ndsType}
                                />
                            </Card.Body>
                        </Card>


                        {
                            status === "active" && (
                                <Card className={cls.auctionActions}>
                                    <Card.Body>
                                        {
                                            error?.general && <div className={cls.auctionApiError}>
                                                {error?.general}
                                            </div>
                                        }

                                        <AuctionLastBidRow lastBid={lastBid ?? firstPrice}/>

                                        <div className={cls.auctionBids}>
                                            <AuctionBidInput
                                                bid={currentBid}
                                                setBid={setCurrentBid}
                                                bidStep={bidStep}
                                                isDisabled={isLoading || bidType === "fixed"}
                                            />

                                            <div className={cls.auctionButtons}>
                                                <AuctionBidButton
                                                    isDisabled={this.isBidButtonsDisabled()}
                                                    onClick={this.makeBidHandler}
                                                    bid={currentBid}
                                                />

                                                {
                                                    minPrice && (
                                                        <AuctionBuyoutButton
                                                            className={cls.auctionButtonBottom}
                                                            isDisabled={this.isBidButtonsDisabled()}
                                                            onClick={this.redeemLotHandler}
                                                            buyoutPrice={minPrice}
                                                        />
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            )
                        }
                    </div>
                </div>

                {
                    (userRoles.includes("ADMIN") && !!bidsHistory?.length) && (
                        <AuctionHistory
                            className={cls.auctionBidsHistory}
                            bidsHistory={bidsHistory}
                        />
                    )
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    currentBid: state.auction.currentBid,
    timeStart: state.auction.timeStart,
    timeFinish: state.auction.timeFinish,
    firstPrice: state.auction.firstPrice,
    bidType: state.auction.bidType,
    status: state.auction?.status,
    lastBid: state.auction?.lastBid,
    lastUserBid: state.auction?.lastUserBid,
    isLoading: state.auction.isLoading,
    error: state.auction.error,
    userRoles: state.auth.user.roles,
    minPrice: state.auction.minPrice,
    isBuyoutAvailable: state.auction.isBuyoutAvailable,
    isOneBidOnly: state.auction.isOneBidOnly,
    bidStep: state.auction.bidStep,
    auctionNdsType: state.auction.auctionNdsType,
    socket: state.socket,
    isBidsHistoryLoading: state.bidsHistory.isLoading,
    bidsHistory: state.bidsHistory.bidsHistory,
});

const mapDispatchToProps = {
    setCurrentBid,
    fetchData,
    fetchDataSuccess,
    fetchDataFailure,
    makeBid,
    makeBidSuccess,
    makeBidFailure,
    setErrors,
    setData,
    fetchBidsHistoryData,
    fetchBidsHistoryDataSuccess,
    fetchBidsHistoryDataFailure
};

export default connect(mapStateToProps, mapDispatchToProps)(Auction);
