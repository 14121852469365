import { connect } from 'react-redux';
import { Form, Row, Col, Tab, Button } from "react-bootstrap";

import contractorService from "../../../services/ContractorService";
import {securityService, ADMIN, APPROVAL} from '../../../services/SecurityService';
import {
	requiredValidator,
	innValidator,
	kppValidator,
	ogrnValidator,
	bikValidator,
	decimalValidator,
	positiveIntValidator,
	emailValidator,
	phoneValidator
} from "../../../validators/simpleValidators";
import StoreWrapper from '../../form/StoreWrapper';
import BaseForm from '../../form/BaseForm';
import FormGroup from '../../form/FormGroup';
import { setData } from '../../../actions/form';
import Loading from '../../Loading';
import FormValidator from '../../../validators/FormValidator';
import { APPROVAL_STATUSES, ENUM_NAME } from '../../../Enums';
import { CONTRACTOR } from '../../AuditTable/AuditTable';
import FileUploader from '../../FileUploader';
import Quotes from '../../quotes/quote/Quotes';
import ValidationErrors from '../../../validators/ValidationErrors';
import ContractsForm from './ContractsForm';
import Contacts from './contact/Contacts';
import Settings from './Settings';
import PaymentAccounts from '../payment-accounts/PaymentAccounts';
import paymentAccountService from "../../../services/PaymentAccountService";
import Addresses from '../addresses/Addresses';
import Util from "../../../Util";
import dadataService from "../../../services/DadataService";
import { setErrors } from '../../../actions/form';
import Ati from "./Ati";
import atiService from "../../../services/AtiService";
import moment from "moment";
import personService from "../../../services/PersonService";
import CopyContractorAddress from "./CopyContractorAddress";
import {setToastObjAC} from "../../../reducers/toastObj";
import React, {Fragment} from "react";
import WarningMessage from "../../form/WarningMessage";

function mapGlobalStateToProps(state) {
	const user = {
		...state.auth.user,
		isClient: securityService.isClient(state.auth.user),
		isCarrier: securityService.isCarrier(state.auth.user)
	}
	return {
		user: user
	};
}

class Contractor extends StoreWrapper {

	static get CONTRACTOR_TYPE_ID_CLIENT() { return 1 };
	static get CONTRACTOR_TYPE_ID_CARRIER() { return 2 };
	static get CONTRACTOR_TYPE_ID_CLIENT_OR_CARRIER() { return 3 };

	static get CONTRACTOR_MODE_ID_LEGAL() { return 1 };
	static get CONTRACTOR_MODE_ID_INDIVIDUAL() { return 2 };
	static get CONTRACTOR_MODE_ID_SELF_EMPLOYED() { return 3 };
	static get CONTRACTOR_MODE_ID_SEPARATE_DIVISION() { return 4 };
	static get CONTRACTOR_MODE_ID_GOVERNMENT_AGENCY() { return 5 };

	static get DEFAULT_APPROVAL_STATUS() { return { id: 0, value: "NEW" } };

	static CONTRACTOR_IS_CLIENT(contractor) {
		return [Contractor.CONTRACTOR_TYPE_ID_CLIENT,
		Contractor.CONTRACTOR_TYPE_ID_CLIENT_OR_CARRIER].indexOf(contractor.contractorType?.id) >= 0;
	};
	static CONTRACTOR_IS_CARRIER(contractor) {
		return [Contractor.CONTRACTOR_TYPE_ID_CARRIER,
		Contractor.CONTRACTOR_TYPE_ID_CLIENT_OR_CARRIER].indexOf(contractor.contractorType?.id) >= 0;
	};

	constructor(props) {
		super(props);		
	}

	render() {
		return (
			<ContractorInnerConnected {...this.props} store={this.store} />
		);
	}
}

function mapStateToProps(state) {
	return {
		errors: state.errors,
		model: state.model,
		componentSource: "contractor",
		user: state.user,
	};
}
class ContractorInner extends BaseForm {
	constructor(props) {
		super(props);
		this.configureValidators();
		this.state = {
			files: [],
			summary: null,
			contacts: null,
		};
		this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
		this.applyInternalValidator = this.applyInternalValidator.bind(this);
		this.getActiveTab = this.getActiveTab.bind(this);
	}

	configureValidators() {
		this.useValidatorFor(requiredValidator, "name", "inn", "contractorMode", "legalAddress");
		this.useValidatorFor(this.applyInternalValidator(phoneValidator.bind(this)), "phone");
		this.validator.addValidator('inn', this.innKppUniqueValidator.bind(this));
		this.validator.addValidator('inn', this.applyInternalValidator(this.innValidator.bind(this)));
		this.validator.addValidator('kpp', this.applyInternalValidator(this.kppValidator.bind(this)));
		this.useValidatorFor(this.applyInternalValidator(kppValidator.bind(this)), "kpp");
		this.useValidatorFor(this.applyInternalValidator(ogrnValidator.bind(this)), "ogrn");
		this.useValidatorFor(this.applyInternalValidator(positiveIntValidator.bind(this)), "atiId");
		this.useValidatorFor(this.applyInternalValidator(decimalValidator.bind(this)), "rating");
		this.useValidatorFor(this.applyInternalValidator(emailValidator.bind(this)), "email");
		this.validator.addValidator('contracts', this.contractsValidator.bind(this));
		this.validator.addValidator('paymentAccounts', this.paymentAccountsValidator.bind(this));
		this.validator.addValidator('addresses', this.addressesValidator.bind(this));
	}

	applyInternalValidator(validator) {
		return value => this.execInternalValidator(validator, value);
	}

	async execInternalValidator(validator, value) {
		return this.props.model.external ? FormValidator.OK : validator(value);
	}

	async innValidator() {
		const contractorModeId = this.props.model.contractorMode?.id;
		const inn = this.props.model.inn;
		const isIndividual = (contractorModeId && (contractorModeId === Contractor.CONTRACTOR_MODE_ID_INDIVIDUAL
			|| contractorModeId === Contractor.CONTRACTOR_MODE_ID_SELF_EMPLOYED)) && inn?.length !== 12;
		const isNotIndividual = contractorModeId && contractorModeId !== Contractor.CONTRACTOR_MODE_ID_INDIVIDUAL && inn?.length !== 10
		if (this.props.model.externalInn) {
			return FormValidator.OK;
		} else if (isIndividual) {
			return "ИНН должен содержать 12 символов";
		} else if (isNotIndividual) {
			return "ИНН должен содержать 10 символов";
		} else {
			return innValidator(inn);
		}
	};

	async kppValidator() {
		const contractorModeId = this.props.model.contractorMode?.id;
		const inn = this.props.model.inn;
		const kpp = this.props.model.kpp;
		if (inn?.length === 12 && !!kpp) {
			return "ИНН содержит 12 символов - КПП должно быть пустым";
		} else if (inn?.length === 10 && (contractorModeId === Contractor.CONTRACTOR_MODE_ID_LEGAL
			|| contractorModeId === Contractor.CONTRACTOR_MODE_ID_GOVERNMENT_AGENCY
			|| contractorModeId === Contractor.CONTRACTOR_MODE_ID_SEPARATE_DIVISION) && kpp?.length !== 9) {
			return requiredValidator(kpp);
		}
		return FormValidator.OK;
	};

	async contractsValidator() {
		const errors = [];
		const contracts = this.props.model.contracts;
		if (contracts && contracts.length) {
			for (let i = 0; i < contracts.length; i++) {
				const contract = contracts[i];
				if (!contract.number || !contract.dt || !contract.contractor || !contract.organization || !contract.contractType ||
					!contract.paymentDelay || !contract.paymentDelayType || !contract.paymentMode || !contract.vatMode ||
					!contract.contractorAuthority || !contract.organizationAuthority || !contract.paymentAccount || !contract.organizationPaymentAccount) {
					errors.push(i + 1)
				}
			}
		}
		return errors.length ? `Не заполнены обязательные поля (номер, дата, тип, исполнитель, заказчик,
		 отсрочка платежа, тип отсрочки, вид оплаты, форма оплаты, в лице, р.счет организации, р.счет контрагента, 
		 основной договор (для каждого типа договора)) договоров с порядковыми номерами: ${errors.join(', ')}` : FormValidator.OK;
	}

	async contactsValidator() {
		const contacts = this.props.model.contacts || [];
		const errors = [];

		for (let i = 0; i < contacts.length; i++) {
			const contact = contacts[i];
			const hasError = await this.validateContact(contact);
			if (hasError)  {
				errors.push(i + 1);
			}
		}

		return errors.length
			? `Ошибки валидации контактов с порядковыми номерами: ${errors.join(', ')}`
			: FormValidator.OK;
	}

	async validateContact(contact) {
		const requiredValid = await requiredValidator(contact.fio);
		const emailUniqValid = await this.emailUniqueValidator(contact);
		const phoneUniqValid = await this.phoneUniqueValidator(contact);
		const emailValid = await emailValidator(contact.email);
		const phoneValid = await phoneValidator(contact.phone);

		return this.hasError(requiredValid, emailUniqValid, phoneUniqValid, emailValid, phoneValid);
	}

	async emailUniqueValidator(model) {
		const email = model.email;
		if (!email) {
			return FormValidator.PROMISE_OK;
		}
		const isValid = await personService.isEmailUnique(model.id, email);
		return isValid ? FormValidator.OK : "этот email уже зарегистрирован в системе";
	}

	async phoneUniqueValidator(model) {
		const phone = model.phone;
		if (!phone) {
			return FormValidator.PROMISE_OK;
		}
		const data = await personService.isPhoneUnique(model.id, phone);
		return data.unique
			? FormValidator.OK
			: "этот телефон уже зарегистрирован в системе";
	}

	hasError(...validators) {
		return !validators.every(v => v === FormValidator.OK);
	}

	async addressesValidator() {
		const valid = this.props.model.addresses?.every(it => !!it.fullAddress) || true;
		return valid ? FormValidator.OK : "Введите адрес ещё раз";		 
	}

	async paymentAccountsValidator() {
		const errors = [];
		const paymentAccounts = this.props.model.paymentAccounts;
		if (paymentAccounts && paymentAccounts.length) {
			for (let i = 0; i < paymentAccounts.length; i++) {
				const paymentAccount = paymentAccounts[i];
				if (await bikValidator(paymentAccount.bik)
					|| await requiredValidator(paymentAccount.account)) {
					errors.push(i + 1)
				}
			}
		}
		return errors.length ? `Ошибки валидации Р/счетов с порядковыми номерами: ${errors.join(', ')}` : FormValidator.OK;
	}

	async innKppUniqueValidator() {
		const { inn, kpp } = this.props.model;
		if (!inn) {
			return FormValidator.PROMISE_OK;
		}
		const isValid = await contractorService.isInnKppUnique(this.getContractorId(), inn, kpp);
		const isBanned = await contractorService.checkBlacklistContractor(this.getContractorId(), inn, kpp);
		if (isValid) {
			return FormValidator.OK;
		} else {
			return isBanned ? "контрагент с таким ИНН в черном списке" : "этот ИНН уже зарегистрирован в системе";
		}
	}

	getContractorId() {
		return this.props.location.state?.id
			|| this.props.model?.id
			|| new URLSearchParams(this.props.location.search).get('id');
	}

	onChangeCheckbox(fieldName, e) {
		this.onChange(fieldName, e.target.checked);
	}

	readOnlyApprovalStatus() {
		if (securityService.hasRole(this.props.user, APPROVAL) && this.props.model?.approvalStatus?.value === APPROVAL_STATUSES.BLACK_LIST.value) {
			return true;
		}
		return !securityService.anyRoles(this.props.user, ADMIN, APPROVAL);
	}

	getActiveTab() {
		return this.props.location.state?.activeTab || new URLSearchParams(this.props.location.search).get('activeTab')
	}

	load() {
		const id = this.getContractorId();
		if (id) {
			contractorService.read(id).then(data => {
				this.props.store.dispatch(setData(data, this.props.location.state?.action));
				if (!!data.atiId) {
					atiService.getSummaryAndContacts(data.atiId)
						.then(res => {
							if (!!res.error) {
								alert("Контрагент с таким кодом в АТИ не найден! Удалите или измените значение в поле \"Код в АТИ\".");
								this.setState({summary: null})
							} else {
								res.summary && this.normalizeDate(res?.summary);
								this.setState({summary: res.summary, contacts: res.contacts});
							}
						});
				}
			});

			if (this.getActiveTab()) {
				this.setState({ activeTab: this.getActiveTab() });
			}

			if (new URLSearchParams(this.props.location.search).get("activeTab") === "addresses") {
				this.setState({ activeTab: "addresses" });
			}

		} else {
			this.props.store.dispatch(setData({ active: true }));
			this.props.store.dispatch(setData({ external: false }));
			this.props.store.dispatch(setData({ externalInn: false }));
			this.props.store.dispatch(setData({ approvalStatus: Contractor.DEFAULT_APPROVAL_STATUS }));
		}
	}

	renderWarning() {
		return (
			<WarningMessage
				variant="blue"
				message={"После внесения изменений, элемент справочника нельзя будет выбрать в заявке до проверки службы безопасности."}
			/>
		);
	}

	renderNamesRow() {
		const readOnly = this.props.user.isClient;

		return (
			<Row>
				<Col lg={4} md={12}>
					<FormGroup title="Полное наименование" name="fullName" store={this.props.store} readOnly={readOnly} />
				</Col>
				<Col lg={4} md={12}>
					<FormGroup title="Краткое наименование" name="name" store={this.props.store} required readOnly={readOnly} />
				</Col>
				<Col lg={2} md={12}>
					<FormGroup title="Тип контрагента" name="contractorType" store={this.props.store} readOnly={readOnly}
						type="dictionary" optionsType="CONTRACTOR_TYPE"
					/>
				</Col>
				<Col lg={2} md={12}>
					<FormGroup title="Вид контрагента" name="contractorMode" store={this.props.store} readOnly={readOnly}
							   type="dictionary" optionsType="CONTRACTOR_MODE" required
					/>
				</Col>
			</Row>
		);
	}

	renderInnRow() {
		const readOnly = this.props.user.isClient;

		return (
			<Row>
				<Col lg={3} md={12}>
					<FormGroup title="ИНН контрагента" name="inn" type={"number"} store={this.props.store} required readOnly={this.props.user.isClient} maxLength={12}/>
				</Col>
				<Col lg={1} md={12}>
					<Col>
						<Form.Group>
							<Form.Label>ИНН не РФ</Form.Label>
							<Form.Check id="externalInn"
										name="externalInn"
										defaultChecked={this.props.model.externalInn}
										onClick={(e) => this.onChangeCheckbox("externalInn", e)}
										disabled={readOnly} />
						</Form.Group>
					</Col>
				</Col>
				<Col lg={4} md={12}>
					<FormGroup title="ОГРН" name="ogrn" type={"number"} store={this.props.store} readOnly={readOnly} maxLength={15}/>
				</Col>
				<Col lg={4} md={12}>
					<FormGroup title="КПП контрагента" name="kpp" type={"number"} store={this.props.store} readOnly={readOnly} maxLength={9}/>
				</Col>
			</Row>
		);
	}

	renderAuthorityRow() {
		const readOnly = this.props.user.isClient;

		return (
			<Row>
				<Col lg={4} md={12}>
					<FormGroup title="Должность руководителя" name="authorityPosition" store={this.props.store} readOnly={readOnly} />
				</Col>
				<Col lg={4} md={12}>
					<FormGroup title="ФИО руководителя" name="authorityFio" store={this.props.store} readOnly={readOnly} />
				</Col>
				<Col lg={4} md={12}>
					<FormGroup title="В лице" name="authority" store={this.props.store} readOnly={readOnly} />
				</Col>
			</Row>
		);
	}

	fillSite() {
		if (!!this.state.summary) {
			return this.state.summary.web_site != null ? this.state.summary.web_site : "";
		}
		return this.props.model.site;
	}

	fillRating() {
		return !!this.state.summary ? this.state.summary.score : this.props.model.rating;
	}

	renderManagerRow() {
		const readOnly = this.props.user.isClient;

		return (
			<Row>
				{ Contractor.CONTRACTOR_IS_CLIENT(this.props.model) &&
					<Col lg={2} md={12}>
						<FormGroup title="Менеджер КО"
							name="commercialDepManager"
							type="user"
							role="COMMERCIAL"
							store={this.props.store}
						    readOnly={readOnly ||
								(this.props.user.isCarrier && this.props.model.contractorType?.id === Contractor.CONTRACTOR_TYPE_ID_CLIENT_OR_CARRIER)}
						/>
					</Col>
				}
				{ Contractor.CONTRACTOR_IS_CARRIER(this.props.model) &&
					<Col lg={2} md={12}>
						<FormGroup title="Менеджер ОЛ"
								   name="logisticDepManager"
								   type="user"
								   role="LOGISTIC"
								   store={this.props.store}
								   readOnly={readOnly}
						/>
					</Col>
				}
				<Col lg={2} md={12}>
					<Form.Group className={"form-group-margin"}>
						<Form.Label>Рейтинг контрагента</Form.Label>
						<Form.Control
							size="sm"
							value={this.fillRating()}
							type="text"
							onChange={e => this.onChange("rating", e.target.value)}
							readOnly={readOnly}
						/>
					</Form.Group>
				</Col>
				<Col lg={4} md={12}>
					<FormGroup title="Код в АТИ" name="atiId" store={this.props.store} readOnly={readOnly} />
					{this.renderSearchButton()}
				</Col>
				<Col lg={4} md={12}>
					<FormGroup title="Подразделение для отправки документов"
							   name="department"
							   type="dictionary"
							   optionsType="DEPARTMENT"
							   store={this.props.store}
					/>
				</Col>
			</Row>
		);
	}

	renderSearchButton() {
		const readOnly = this.props.user.isClient;

		return (
			<div className="form-group-ext search-button">
				<Button variant="outline-primary" size="sm" className="text-right" disabled={readOnly}
						onClick={() => this.handleAtiSearch()}>
					Поиск
				</Button>
			</div>
		);
	}

	renderAddressRow() {
		const readOnly = this.props.user.isClient;

		return (
			<Row>
				<Col lg={4} md={12}>
					<FormGroup title="Почтовый адрес" name="mailingAddress" store={this.props.store} readOnly={readOnly} />
				</Col>
				<Col lg={4} md={12}>
					<FormGroup title="Фактический адрес" name="address" store={this.props.store} readOnly={readOnly} />
				</Col>
				<Col lg={4} md={12}>
					<FormGroup title="Юридический адрес" name="legalAddress" store={this.props.store} required readOnly={readOnly} />
				</Col>
			</Row>
		);
	}

	renderContactsRow() {
		const readOnly = this.props.user.isClient;

		return (
			<Row>
				<Col lg={4} md={12}>
					<FormGroup title="Телефон" type="phone" name="phone" store={this.props.store} readOnly={readOnly} />
				</Col>
				<Col lg={4} md={12}>
					<FormGroup title="E-mail" name="email" store={this.props.store} readOnly={readOnly} />
				</Col>
				<Col lg={4} md={12}>
					<Form.Group className={"form-group-margin"}>
						<Form.Label>Сайт компании</Form.Label>
						<Form.Control
							size="sm"
							value={this.fillSite()}
							type="text"
							onChange={e => this.onChange("site", e.target.value)}
							readOnly={readOnly}
						/>
					</Form.Group>
				</Col>
			</Row>
		);
	}

	renderCheckboxesAndApprovalAndAgreementRow() {
		const isCarrierOrClient = this.props.user.isClient || this.props.user.isCarrier;

		return (
			<Row>
				<Col lg={4} md={12}>
					<Row>
						<Col lg={6} md={12}>
							<FormGroup title="Активен"
									   name="active"
									   type="checkbox"
									   readOnly={isCarrierOrClient}
									   store={this.props.store}
									   defaultChecked={this.props.model.active}
									   onClick={(e) => this.onChangeCheckbox("active", e)}
							/>
						</Col>
						<Col lg={6} md={12}>
							<Col>
								<Form.Group>
									<Form.Label>Контрагент не РФ</Form.Label>
									<Form.Check id="external"
												name="external"
												defaultChecked={this.props.model.external}
												onClick={(e) => this.onChangeCheckbox("external", e)}
												disabled={isCarrierOrClient} />
								</Form.Group>
							</Col>
						</Col>
						<Col lg={6} md={12}>
							<Col>
								<Form.Group>
									<Form.Label>Подлежит валютному контролю</Form.Label>
									<Form.Check id="currencyControl"
												name="currencyControl" d
												efaultChecked={this.props.model.currencyControl}
												onClick={(e) => this.onChangeCheckbox("currencyControl", e)}
												disabled={isCarrierOrClient} />
								</Form.Group>
							</Col>
						</Col>
						<Col lg={6} md={12}>
							<FormGroup title="Согласование"
								name="approvalStatus"
								type="enum"
								optionsType={ENUM_NAME.APPROVAL_STATUS}
								readOnly={this.props.user.isClient || this.readOnlyApprovalStatus()}
								store={this.props.store}
							/>
						</Col>
					</Row>
				</Col>
				<Col lg={8} md={12}>
					<Row>
						{	Contractor.CONTRACTOR_IS_CLIENT(this.props.model) &&
							<Col lg={6} md={12}>
								<FormGroup title="Регионы"
										   name="regions"
										   type="dictionary"
										   optionsType="REGION"
										   multiSelect={true}
										   store={this.props.store}
								/>
							</Col>
						}
						{	Contractor.CONTRACTOR_IS_CLIENT(this.props.model) &&
							<Col lg={6} md={12}>
								<FormGroup title="Типы транспорта"
										   name="carcasses"
										   type="dictionary"
										   optionsType="CARCASS"
										   multiSelect={true}
										   store={this.props.store}
								/>
							</Col>
						}
						<Col lg={8} md={12}>
							<FormGroup title="Заметки"
									   name="comment"
									   store={this.props.store}
									   type="textarea"
									   rows={4}
									   readOnly={this.props.user.isClient} />
						</Col>
					</Row>
				</Col>
			</Row>
		);
	}

	renderComment() {
		const readOnly = this.props.user.isClient;

		return (
			<Row>
				<Col lg={12} md={12}>
					<FileUploader
						store={this.props.store}
						name="attachments"
						title="Сканкопии документов"
						fileNameFunction={(attachment, index) => Util.formatFileName(attachment.originalName, "Контрагент_" + this.props.model.name, index)}
						hideRemove={readOnly}
						hideDropzpne={readOnly}
					/>
				</Col>
			</Row>
		);
	}

	handleSubmit(e) {
		e.preventDefault();
		this.submit(() =>
			contractorService.save(this.props.model).then(contractor => {
					this.afterSubmit(contractor)
				}));
	}

	afterSubmit(res) {
		const data = {show: true, textHeader: "Контрагент сохранен!", delay: 3000};
		if(!this.getContractorId()) {
			this.redirectToBackOrDefaultUrl({payload: "/control-panel/contractors/contractor", state:{id: res.id}});
			data.textHeader = "Контрагент создан!"
		}
		this.load()
		this.onChange('version', res.version);
		this.props.setToastObjAC(data)
	}

	onCancel() {
		this.redirectToBackOrDefaultUrl('/control-panel/contractors')
	}

	/**
	 * Validate INN, OGRN, KPP for organization data. 
	 * The fields are used to fill in the contractor form automatically.
	 * INN has higher priority than OGRN. KPP is used only in conjunction with INN.
	 */
	async validateAutoFillin() {
		// By default ogrn and kpp are not required fields but could be used for
		// organization data fetching
		const { ogrn, kpp } = this.props.model;
		const isInn = await this.validator.validateField('inn');
		const isOgrn = await this.validator.validateField('ogrn');
		const isKpp = kpp && await this.validator.validateField('kpp');
		/*
		 According to Feature #659: we can fetch organization data using
		 INN or OGRN or INN and KPP where INN has the highest priority
		 So that validate fields using the condition.
		 */
		const isValid = kpp
			? (isInn && isKpp)
			: (isInn || (ogrn && isOgrn));
		if (!isValid) {
			this.props.store.dispatch(setErrors(this.validator.results));
		} else {
			this.props.store.dispatch(setErrors([]));
		}
		return isValid;
	}

	async getFillInData() {
		const { inn, ogrn, kpp } = this.props.model;
		return await dadataService.fetchContractor({ inn, ogrn, kpp });
	}

	async handleAtiSearch() {
		if (!this.props.model.atiId) {
			return (<></>)
		}
		const atiResponse = await atiService.getSummaryAndContacts(this.props.model?.atiId);
		if (!!atiResponse.error) {
			alert("Контрагент с таким кодом в АТИ не найден!");
		} else {
			const firmName = atiResponse.summary.firm_name != null ? atiResponse.summary.firm_name : "";
			const choice = confirm(`Контрагент ${firmName} найден! Перенести данные из ATI?`);
			if (choice) {
				if (atiResponse.summary !== "null") {
					this.normalizeDate(atiResponse.summary);
					this.setState({summary: atiResponse.summary, contacts: atiResponse.contacts});
					this.onChange("rating", atiResponse.summary?.score);
					this.onChange("site", atiResponse.summary?.web_site);
				}
			} else {
				this.setState({summary: null, contacts: null});
				this.onChange("rating", this.props.model?.rate);
				this.onChange("site", this.props.model?.site);
			}
		}
	}

	normalizeDate(item) {
		const dateKeys = Object.keys(item)
			.filter(key => !!item[key] && !Number.isInteger(item[key]) &&
				moment(item[key], moment.ISO_8601, true).isValid());
		if (dateKeys.length > 0) {
			dateKeys.forEach(key => item[key] = new Date(item[key]).toLocaleDateString("ru-Ru"));
		}
		return item;
	}

	getTabs() {
		if (this.getContractorId()) {
			if (this.props.user.isClient) {
				return [
					this.getContractsTab(),
					this.getAddressesTab()
				];
			}
			if (this.props.user.isClient.isCarrier) {
				return [
					this.getContractsTab(),
					this.getContactsTab(),
					this.getPaymentAccountsTab(),
					this.getRelatedQuotesTab(),
					this.getAddressesTab(),
					this.getAtiTab()
				];
			}
			return [
				this.getContractsTab(),
				this.getContactsTab(),
				this.getPaymentAccountsTab(),
				this.getRelatedQuotesTab(),
				this.getAddressesTab(),
				this.getCopyAddressTab(),
				this.getAtiTab(),
				this.getAuditTab(this.getContractorId(), CONTRACTOR),
			];
		}
		return [];
	}
	
	getRelatedQuotesTab() {
		return (
			<Tab key="relatedQuotes" eventKey="relatedQuotes" title="Связанные заявки">
				{this.state.activeTab === "relatedQuotes" && <Quotes
					title={`Заявки ${this.getContractorName()}`}
					features={{ canAdd: false }}
					fetch={(params) => contractorService.quotes(params, this.getContractorId())}
					sort={{ field: 'dt', dir: 'desc' }}
					type={"contractor"}
				/>}
			</Tab>
		);
	}

	getContractsTab() {
		if (this.props.model.contracts) {
			return (
				<Tab key="contracts" eventKey="contracts" title="Договоры">
					{this.state.activeTab === "contracts" && <ContractsForm setToastObjAC={this.props.setToastObjAC} {...this.props} currentId={this.props.location.state?.contractId}/>}
				</Tab>
			);
		}
	}

	getContactsTab() {		
		return (
			<Tab key="contacts" eventKey="contacts" title="Контакты">
				{this.state.activeTab === "contacts" && <Contacts {...this.props} />}
			</Tab>
		);
	}
	
	getAddressesTab() {
		return (
			<Tab key="addresses" eventKey="addresses" title="Адреса">
				{this.state.activeTab === "addresses" && <Addresses {...this.props} />}
			</Tab>
		);
	}

	getAtiTab() {
		if (!!this.state.summary) {
			return (
				<Tab key="ati" eventKey="ati" title="ATI">
					{this.state.activeTab === "ati" && <Ati {...this.state} />}
				</Tab>
			);
		}
		return (<></>);
	}

	getCopyAddressTab() {
		return (
			<Tab key="copyAddress" eventKey="copyAddress" title="Копирование адресов">
				{this.state.activeTab === "copyAddress" && <CopyContractorAddress {...this.props} />}
			</Tab>
		);
	}

	getPaymentAccountsTab() {
		return (
			<Tab key="paymentAccounts" eventKey="paymentAccounts" title="Р/Счета">
				{this.state.activeTab === "paymentAccounts" && 
				<PaymentAccounts 
					{...this.props} 
					applyInternalValidator={this.applyInternalValidator}
					setToastObjAC={this.props.setToastObjAC}
					save={paymentAccountService.saveToContractor}
					delete={paymentAccountService.deleteFromContractor}
				/>}
			</Tab>
		);
	}

	getContractorName() {
		return this.props.model.name || (this.props.model.id > 0 ? this.props.model.id : '');
	}

	getFormTabTitle() {
		return "Контрагент " + this.getContractorName();
	}

	renderForm() {
		const isReady = !this.getContractorId() || this.props.model.id;

		if (!isReady) {
			return (<Loading />);
		}
		return (
			<Form>
				{this.renderWarning()}

				{this.renderNamesRow()}

				{this.renderAuthorityRow()}

				{this.renderInnRow()}

				{this.renderAddressRow()}

				{this.renderContactsRow()}

				{this.renderManagerRow()}

				{this.renderCheckboxesAndApprovalAndAgreementRow()}

				{this.renderComment()}
				{this.props.errors && this.props.errors["contracts"] && <ValidationErrors errors={this.props.errors["contracts"]} />}
				{this.props.errors && this.props.errors["contacts"] && <ValidationErrors errors={this.props.errors["contacts"]} />}
				{this.props.errors && this.props.errors["paymentAccounts"] && <ValidationErrors errors={this.props.errors["paymentAccounts"]} />}
				{this.props.errors && this.props.errors["addresses"] && <ValidationErrors errors={this.props.errors["addresses"]} />}

				{!this.props.user.isClient && this.renderAutoFillInSaveCancelButtons()}
			</Form>
		);
	}
}

const ContractorInnerConnected = connect(mapStateToProps)(ContractorInner);

export default connect(mapGlobalStateToProps, {setToastObjAC})(Contractor);