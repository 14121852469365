import React from "react";
import { Card, Row, Col, Button } from "react-bootstrap";
import exportImportService from "../../../services/ExportImportService";
import moment from 'moment';
import TimePicker from "../../form/TimePicker";

class SLExport extends React.PureComponent {

	constructor(props) {
		super(props);
		this.state = {
			exportScheduleTime: null
		}
		this.onChangeTime = this.onChangeTime.bind(this);
	}
	
	onChangeTime(value) {
		this.setState({
			exportScheduleTime: value != null ? `${value.getHours().pad(2)}:${value.getMinutes().pad(2)}` : null
		});	
	}

	render() {
		if (this.props.isBusy !== false) {
			return ("");
		}
		const scheduledDate = Date.now() + this.props.nextExportDelay;
		return (
			<Row className="import-card">
				<Col>
					<Card className="import-card">
						<Card.Body>
							<Row className="mb-3">
								<Col>
									Экспорт Заявок
								</Col>
							</Row>
							<Row className="mb-3">
								<Col>
									<Button variant="primary" size="sm" onClick={this.props.handleExport}>
										Выполнить
									</Button>
								</Col>
							</Row>
							<Row className="mb-3">
								<Col>
									Следующий запланированный экспорт: {moment(scheduledDate).format("DD.MM.YYYY HH:mm")}
								</Col>
							</Row>
							<div className="mb-2">Установить время запланорованного экспорта</div>
							<Row className="mb-3">
								<Col xs="auto">
										<TimePicker
											value={this.state.exportScheduleTime}
											onChange={this.onChangeTime} />
								</Col>
								<Col xs="auto">
										<Button size="sm" onClick={() => this.props.handleScheduleSave(this.state.exportScheduleTime)}>Сохранить</Button></Col>
							</Row>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		);
	}
	// this.setState({ exportScheduleTime: this.formatTime(newValue) })
}
export default SLExport;