class Enums {

	static ENUM_NAME_VEHICLE_TYPE = "VEHICLE_TYPE"
	static ENUM_NAME_APPROVAL_STATUS = "APPROVAL_STATUS"
	static ENUM_NAME_APPROVAL_ENTITY = "APPROVAL_ENTITY"
	static ENUM_API_EXCEPTION_ACTION = "API_EXCEPTION_ACTION"
	static ORG_ATTACHMENT_TYPE = "ORG_ATTACHMENT_TYPE"
	static ENUM_NAME_PERIODICITY_TYPE = "PERIODICITY_TYPE"
	static ENUM_NAME_DAY_OF_WEEK = "DAY_OF_WEEK"
	static ENUM_NAME_EVENT_SOURCE = "EVENT_SOURCE"
	static ENUM_NAME_EVENT_TYPE = "EVENT_TYPE"
	static ENUM_NAME_TASK_STATUS = "TASK_STATUS"
	static ENUM_NAME_NOTIFICATION_CLASSIFICATION = "NOTIFICATION_CLASSIFICATION"
	static ENUM_NAME_NOTIFICATION_TYPE = "NOTIFICATION_TYPE"
	static ENUM_NAME_NOTIFICATION_PRIORITY = "NOTIFICATION_PRIORITY"
	static ENUM_NAME_SURVEY_TYPE = "SURVEY_TYPE"
	static ENUM_NAME_SURVEY_STATUS = "SURVEY_STATUS"
	static ENUM_NAME_PAYMENT_TYPE = "PAYMENT_TYPE"
	static ENUM_NAME_SIGNING_STATUS = "SIGNING_STATUS"

	static ENUMS = {
		[Enums.ENUM_NAME_VEHICLE_TYPE] : {
			TRUCK : {
				value: 'TRUCK',
				label: "Тягач",
			},
			TRAILER : {
				value: 'TRAILER',
				label: "Полуприцеп",
			},
			VAN : {
				value: 'VAN',
				label: "Фургон",
			},
			BUS : {
				value: 'BUS',
				label: "Автобус",
			}
		},
		[Enums.ENUM_NAME_APPROVAL_STATUS] : {
			NEW : {
				value: 'NEW',
				label: "Новый",
			},
			APPROVED : {
				value: 'APPROVED',
				label: "Согласовано",
			},
			NOT_APPROVED : {
				value: 'NOT_APPROVED',
				label: "Не согласовано",
			},
			BLACK_LIST : {
				value: 'BLACK_LIST',
				label: "Черный список",
			}
		},
		[Enums.ENUM_NAME_APPROVAL_ENTITY] : {
			CONTRACTOR : {
				value: 'CONTRACTOR',
				label: "Контрагент",
			},
			DRIVER : {
				value: 'DRIVER',
				label: "Водитель",
			},
			VEHICLE : {
				value: 'VEHICLE',
				label: "Транспортное средство",
			}
		},
		[Enums.ENUM_API_EXCEPTION_ACTION] : {
			NO_ACTION : {
				value: 'NO_ACTION',
			},
			REDIRECT_HOME : {
				value: 'REDIRECT_HOME',
			},
		},
		[Enums.ORG_ATTACHMENT_TYPE] : {
			SIGN : {
				value: "SIGN",
				label: "Факсимиле руководителя",
			},
			SIGN_ACCOUNTANT : {
				value: "SIGN_ACCOUNTANT",
				label: "Факсимиле бухгалтера",
			},
			STAMP : {
				value: "STAMP",
				label: "Печать",
			},
			EMAIL_SIGNATURE : {
				value: "EMAIL_SIGNATURE",
				label: "Email подпись",
			},
			SIGN_KAMENSKY : {
				value: "SIGN_KAMENSKY",
				label: "Факсимиле Каменского",
			}
		},
		[Enums.ENUM_NAME_PERIODICITY_TYPE] : {
			DAY : {
				value: 'DAY',
				label: "День",
			},
			MONTH : {
				value: 'MONTH',
				label: "Месяц",
			},
			YEAR : {
				value: 'YEAR',
				label: "Год",
			}
		},
		[Enums.ENUM_NAME_DAY_OF_WEEK] : {
			MONDAY : {
				value: 'MONDAY',
				label: "Понедельник",
			},
			TUESDAY : {
				value: 'TUESDAY',
				label: "Вторник",
			},
			WEDNESDAY : {
				value: 'WEDNESDAY',
				label: "Среда",
			},
			THURSDAY : {
				value: 'THURSDAY',
				label: "Четверг",
			},
			FRIDAY : {
				value: 'FRIDAY',
				label: "Пятница",
			},
			SATURDAY : {
				value: 'SATURDAY',
				label: "Суббота",
			},
			SUNDAY : {
				value: 'SUNDAY',
				label: "Воскресенье",
			},
		},
		[Enums.ENUM_NAME_EVENT_SOURCE] : {
			TASK : {
				value: 'TASK',
				label: "Задача",
			}
		},
		[Enums.ENUM_NAME_EVENT_TYPE] : {
			TASK_OVERDUE : {
				value: 'TASK_OVERDUE',
				label: "Просроченная задача",
			},
			TASK_NEW : {
				value: 'TASK_NEW',
				label: "Новые запросы",
			},
			TASK_IN_PROGRESS : {
				value: 'TASK_IN_PROGRESS',
				label: "Выполняется",
			},
			TASK_REVIEW : {
				value: 'TASK_REVIEW',
				label: "На проверке",
			},
			TASK_DONE : {
				value: 'TASK_DONE',
				label: "Выполнено",
			},
			TASK_CANCEL : {
				value: 'TASK_CANCEL',
				label: "Отменена",
			}
		},
		[Enums.ENUM_NAME_TASK_STATUS] : {
			NEW : {
				value: 'NEW',
				label: "Новые запросы",
			},
			IN_PROGRESS : {
				value: 'IN_PROGRESS',
				label: "Выполняется",
			},
			REVIEW : {
				value: 'REVIEW',
				label: "На проверке",
			},
			DONE : {
				value: 'DONE',
				label: "Выполнено",
			},
			CANCEL : {
				value: 'CANCEL',
				label: "Отменена",
			}
		},
		[Enums.ENUM_NAME_NOTIFICATION_CLASSIFICATION] : {
			READ : {
				value: 'READ',
				label: "Прочитанное",
			},
			NOT_READ : {
				value: 'NOT_READ',
				label: "Не прочитанное",
			},
			ARCHIVE : {
				value: 'ARCHIVE',
				label: "Архив",
			}
		},
		[Enums.ENUM_NAME_NOTIFICATION_TYPE] : {
			QUOTE_CHANGE_STATUS : {
				value: 'QUOTE_CHANGE_STATUS',
				label: "Изменение статуса заявки",
			}
		},
		[Enums.ENUM_NAME_NOTIFICATION_PRIORITY] : {
			LOW : {
				value: 'LOW',
				label: "Низкий",
			},
			MEDIUM : {
				value: 'MEDIUM',
				label: "Средний",
			},
			HIGH : {
				value: 'HIGH',
				label: "Высокий",
			}
		},
		[Enums.ENUM_NAME_SURVEY_TYPE] : {
			ORGANIZATION : {
				value: 'ORGANIZATION',
				label: "Организация"
			},
			DRIVER : {
				value: 'DRIVER',
				label: "Водитель"
			},
			VEHICLE : {
				value: 'VEHICLE',
				label: "Транспорт"
			},
			CONTACT_PERSON : {
				value: 'CONTACT_PERSON',
				label: "Контактное лицо"
			}
		},
		[Enums.ENUM_NAME_SURVEY_STATUS] : {
			NEW : {
				value: 'NEW',
				label: "Новая"
			},
			IN_PROCESS : {
				value: 'IN_PROCESS',
				label: "В процессе"
			},
			ELIMINATE_COMMENTS : {
				value: 'ELIMINATE_COMMENTS',
				label: "Устраните замечания"
			},
			COMMENTS_ELIMINATED : {
				value: 'COMMENTS_ELIMINATED',
				label: "Замечания устранены"
			},
			COMPLETE : {
				value: 'COMPLETE',
				label: "Выполнена"
			},
			CANCEL : {
				value: 'CANCEL',
				label: "Отменена"
			}
		},
		[Enums.ENUM_NAME_PAYMENT_TYPE] : {
			AUCTION : {
				value: 'AUCTION',
				label: "Аукцион"
			},
			FIXED_BET : {
				value: 'FIXED_BET',
				label: "Фиксированная ставка"
			}
		},
		[Enums.ENUM_NAME_SIGNING_STATUS] : {
			SIGNED : {
				value: 'SIGNED',
				label: "Подписана"
			},
			NOT_SIGNED : {
				value: 'NOT_SIGNED',
				label: "Не подписана"
			}
		}
	}
}

export default Enums.ENUMS;

export const DAY = 'DAY';
export const MONTH = 'MONTH';
export const YEAR = 'YEAR';
export const TASK = 'TASK';

export const ENUM_NAME = {
	VEHICLE_TYPE: Enums.ENUM_NAME_VEHICLE_TYPE,
	APPROVAL_STATUS: Enums.ENUM_NAME_APPROVAL_STATUS,
	APPROVAL_ENTITY: Enums.ENUM_NAME_APPROVAL_ENTITY,
	API_EXCEPTION_ACTION: Enums.ENUM_API_EXCEPTION_ACTION,
	ORG_ATTACHMENT_TYPE: Enums.ORG_ATTACHMENT_TYPE,
	PERIODICITY_TYPE: Enums.ENUM_NAME_PERIODICITY_TYPE,
	DAY_OF_WEEK: Enums.ENUM_NAME_DAY_OF_WEEK,
	EVENT_SOURCE: Enums.ENUM_NAME_EVENT_SOURCE,
	EVENT_TYPE: Enums.ENUM_NAME_EVENT_TYPE,
	TASK_STATUS: Enums.ENUM_NAME_TASK_STATUS,
	NOTIFICATION_CLASSIFICATION: Enums.ENUM_NAME_NOTIFICATION_CLASSIFICATION,
	NOTIFICATION_TYPE: Enums.ENUM_NAME_NOTIFICATION_TYPE,
	NOTIFICATION_PRIORITY: Enums.ENUM_NAME_NOTIFICATION_PRIORITY,
	SURVEY_TYPE: Enums.ENUM_NAME_SURVEY_TYPE,
	SURVEY_STATUS: Enums.ENUM_NAME_SURVEY_STATUS,
	PAYMENT_TYPE: Enums.ENUM_NAME_PAYMENT_TYPE,
	SIGNING_STATUS: Enums.ENUM_NAME_SIGNING_STATUS
};

export const VEHICLE_TYPES = Enums.ENUMS[ENUM_NAME.VEHICLE_TYPE];
export const APPROVAL_STATUSES = Enums.ENUMS[ENUM_NAME.APPROVAL_STATUS];
export const APPROVAL_ENTITIES = Enums.ENUMS[ENUM_NAME.APPROVAL_ENTITY];
export const API_EXCEPTION_ACTION = Enums.ENUMS[ENUM_NAME.API_EXCEPTION_ACTION];
export const ORG_ATTACHMENT_TYPES = Enums.ENUMS[ENUM_NAME.ORG_ATTACHMENT_TYPE];
export const EVENT_SOURCES = Enums.ENUMS[ENUM_NAME.EVENT_SOURCE];
export const EVENT_TYPES = Enums.ENUMS[ENUM_NAME.EVENT_TYPE];
export const TASK_STATUS = Enums.ENUMS[ENUM_NAME.TASK_STATUS];
export const NOTIFICATION_CLASSIFICATIONS = Enums.ENUMS[ENUM_NAME.NOTIFICATION_CLASSIFICATION];
export const NOTIFICATION_TYPES = Enums.ENUMS[ENUM_NAME.NOTIFICATION_TYPE];
export const NOTIFICATION_PRIORITIES = Enums.ENUMS[ENUM_NAME.NOTIFICATION_PRIORITY];
export const SURVEY_TYPES = Enums.ENUMS[ENUM_NAME.SURVEY_TYPE];
export const SURVEY_STATUSES = Enums.ENUMS[ENUM_NAME.SURVEY_STATUS];
export const PAYMENT_TYPES = Enums.ENUMS[ENUM_NAME.PAYMENT_TYPE];
export const SIGNING_STATUSES = Enums.ENUMS[ENUM_NAME.SIGNING_STATUS];