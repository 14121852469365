import React from 'react';
import "./Notices.css"
import {connect} from "react-redux";
import StoreWrapper from "../form/StoreWrapper";
import NoticesService from "../../services/NoticesService";
import BaseForm from "../form/BaseForm";
import {Button, Col, Row, ToggleButton, ToggleButtonGroup, Form} from "react-bootstrap";
import FormGroup from "../form/FormGroup";
import Select from "react-select";
import Pagination from "../table/pagination";
import {useNotification} from "../../hooks/useNotification";
import {ToastService} from "../../services/ToastService/ToastService";

function mapGlobalStateToProps(state) {
    return {
        user: state.auth.user,
    };
}

function mapStateToProps(state, ownProps) {
    return {
        model: state.model
    };
}

class Notices extends StoreWrapper {
    constructor(props) {
        super(props);
    }

    render() {
        return <NoticesInnerConnected {...this.props} store={this.store}/>
    }
}

class NoticesInner extends BaseForm {

    constructor(props) {
        super(props);
        this.noticesRef = React.createRef()
        this.onClickNotice = this.onClickNotice.bind(this)
        this.handleSubmitSearch = this.handleSubmitSearch.bind(this)
        this.setDefaultReq = this.setDefaultReq.bind(this)
        this.onPageChanged = this.onPageChanged.bind(this)
        this.setNewMessCount = this.setNewMessCount.bind(this)
        this.messageClassification = this.messageClassification.bind(this)
        this.changeNewMessCount = this.changeNewMessCount.bind(this)
        this.isDsbldClassificationBtn = this.isDsbldClassificationBtn.bind(this)

        this.state = {
            reqListMess: {
                pageSize: 10,
                sort: {field: "created", dir: "desc"}
            },
            messages: [],
            isOpen: false,
            newMessCount: 0,
            searchMess: "",
            page: 0,
            pages: 0
        }
    }

    headerButtons = [
        {filter: "classification", value: "ALL", text: "Все", inAll: false},
        {filter: "classification", value: "READ", text: "Прочитанные", inAll: true},
        {filter: "classification", value: "NOT_READ", text: "Непрочитанные", inAll: true},
        {filter: "classification", value: "ARCHIVE", text: "Архив", inAll: false}
    ]

    prioritiesMess = [
        {label: "Неважно", value: "LOW"},
        {label: "Важно", value: "MEDIUM"},
        {label: "Срочно", value: "HIGH"}
    ]

    typesMess = [
        {label: "Заявка", value: "QUOTE"},
        {label: "Задача", value: "TASK"}
    ]

    messageClassification(id, action, actualClass) {
        const clsfs = this.headerButtons.filter(btn => btn.inAll).map(btn => btn.value)
        const data = {
            ids: [id],
            classification: action
        }
        NoticesService.updateClassification(data).then(res => {
            let msgs = this.state.messages
            if (JSON.stringify(clsfs) === JSON.stringify(this.state.reqListMess.classification) && action !== "ARCHIVE") {
                msgs = msgs.map(msg => msg.id === id ? {...msg, classification: action} : msg)
            } else {
                msgs = msgs.filter(msg => msg.id !== id)
            }
            this.changeNewMessCount(action, actualClass)
            this.setState({messages: msgs})
        })
    }

    changeNewMessCount(action, actualClass) {
        switch (action) {
            case "NOT_READ":
                this.setState({newMessCount: ++this.state.newMessCount})
                break
            case "READ":
                actualClass !== "ARCHIVE"
                && this.setState({newMessCount: --this.state.newMessCount})
                break
            case "ARCHIVE":
                actualClass === "NOT_READ"
                && this.setState({newMessCount: --this.state.newMessCount})
                break
            default:
                break
        }
    }

    deleteFilter(filter) {
        const req = this.state.reqListMess
        delete req[filter]
        this.setState({reqListMess: req})
        this.load()
    }

    setOpenMess(id) {
        this.setState({openIdMess: id})
    }

    setColorPriority(priority) {
        let priorityColor
        switch (priority) {
            case 'LOW':
                priorityColor = "bg-secondary"
                break
            case 'MEDIUM':
                priorityColor = "bg-dark"
                break
            case 'HIGH':
                priorityColor = "bg-danger"
                break
            default:
                priorityColor = "bg-primary"
        }
        return priorityColor
    }

    setMessValue(filter, value) {
        return filter.map(elem => elem.value === value && elem.label)
    }

    setMessUrl(metadata) {
        const baseUrl = window.location.origin;
        const id = metadata.entityId
        if (id != null) {
            return (metadata.entityType === "TASK")
                ? `${baseUrl}/task-tracker/task?id=${id}`
                : `${baseUrl}/quotes/quote?id=${id}`
        }
        return null
    }

    renderMessage(message) {
        const messUrl = this.setMessUrl(message.metadata)
        return <li className={"notices__item m-1"}>
            <div className={"notices__item-header"}>
                <div style={{display: "flex", flexWrap: "wrap"}}>
                    <span
                        className={`notices__header-item notices__importance text-white ${this.setColorPriority(message.priority)}`}>{this.setMessValue(this.prioritiesMess, message.priority)}</span>
                    <span
                        className={"notices__header-item notices__type"}>{this.setMessValue(this.typesMess, message.type)}</span>
                    <span
                        className={"notices__header-item notices__date"}>{new Date(message?.created).toLocaleString()}</span>
                </div>
                {message.classification === "ARCHIVE"
                    ? <Button size="sm"
                              onClick={() => this.messageClassification(message.id, "READ", message.classification)}
                              className={"notices__restore notices__header-item"}>Восстановить</Button>
                    : <span onClick={() => this.messageClassification(message.id, "ARCHIVE", message.classification)}
                            className={"notices__delete"}><div></div></span>
                }
            </div>
            <div onClick={() => this.setOpenMess(message.id)}
                 className={`notices__item-body ${this.state?.openIdMess === message.id && "notices__messageOpen"}`}>
                {message.body}
            </div>
            <div className={"notices__footer"} style={{display: "flex", justifyContent: "space-between"}}>
                <Button style={{border: 0}} size="sm"
                        onClick={() => this.messageClassification(message.id, message.classification === "NOT_READ" ? "READ" : "NOT_READ", message.classification)}
                        className={message.classification === "NOT_READ" ? "bg-secondary" : "bg-primary"}
                        disabled={this.isDsbldClassificationBtn()}>
                    {message.classification === "NOT_READ" ? "Непрочитанное" : "Прочитанное"}
                </Button>
                <Button href={messUrl} style={{width: "100px"}} className={"notices__link"} target={"_blank"}>Перейти</Button>
            </div>
        </li>
    }

    isDsbldClassificationBtn() {
        return this.state.reqListMess.classification.indexOf("ARCHIVE") !== -1
    }

    componentDidMount() {
        this.setNewMessCount()
        useNotification((data) => {
            const {type} = data;

            if (type === "notification") {
                this.setNewMessCount();
                ToastService.info({
                    message: "Вам пришло новое уведомление"
                });
            }
        })
    }

    componentDidUpdate(prevProps, props) {
        if (prevProps.model !== this.props.model) {
            const model = this.props.model
            for (let key in model) {
                if (model[key] === null) {
                    delete model[key]
                    break
                }
                !model[key]?.includes('Z') && (model[key] = `${model[key]}Z`)
            }
            Object.keys(model).length > 0
                ? this.addFilter("created", model)
                : this.onChangeSelect("created")
        }
    }

    renderMessages() {
        if (this.state.messages < 1) {
            return <p style={{textAlign: "center", padding: "25px"}} className={"text-secondary"}>Сообщений нет!</p>
        }
        return <div className={"notices__body"}>
            <ul className={"notices__items"}>
                {this.state.messages.map(message => {
                    return this.renderMessage(message)
                })}
            </ul>
        </div>
    }

    setDefaultReq() {
        const req = this.state.reqListMess
        const user = this.props.user
        if (user) {
            req.recipient = {
                id: user.id,
                value: user.fio
            }
        }
        req.page = this.state.page
        return req
    }

    load() {
        const req = this.setDefaultReq()
        NoticesService.listOfNotices(req).then(res => {
            const notifications = res.data;
            const pages = res.pages;

            this.setState({pages})
            this.setState({messages: notifications})
            this.setState({reqListMess: req})
        })
    }

    addAllFilter(btns) {
        const data = btns.filter(btn => btn.inAll).map(btn => btn.value)
        this.addFilter("classification", data)
    }

    addFilter(filter, classification) {
        const req = this.state.reqListMess
        req[filter] = classification
        this.load()
    }

    renderHeaderBtns(btns) {
        return <ToggleButtonGroup
            style={{display: "flex", width: "100%", gap: "5px", justifyContent: "start", "overflow": "hidden"}}
            type="radio"
            name="options"
            defaultValue={this.state.reqListMess.classification || "ALL"}>
            {btns.map(btn => <ToggleButton variant="outline-primary"
                                           onChange={() => btn.value === "ALL"
                                               ? this.addAllFilter(btns)
                                               : this.addFilter(btn.filter, [btn.value])}
                                           value={btn.value}>{btn.text}</ToggleButton>
            )}</ToggleButtonGroup>
    }

    handleSubmitSearch(e) {
        e.preventDefault()
        this.addFilter("body", this.state.searchMess)
    }

    onChangeSelect(filter, e) {
        e
            ? this.addFilter(filter, e.value)
            : this.deleteFilter(filter)
    }

    renderHeader() {
        return <Col style={{display: "flex", flexDirection: "column", gap: "5px", padding: "0"}}>
            <Row style={{display: "flex", gap: "5px", zIndex: "100"}}>
                <Col style={{paddingRight: "0"}} className={"notices__dataFilters"}>
                    <FormGroup
                        placeholder="От..."
                        name="from"
                        isClearable={true}
                        type="datetime"
                        store={this.props.store}
                        readOnly={false}
                    />
                </Col>
                <Col style={{padding: "0"}} className={"notices__dataFilters"}>
                    <FormGroup
                        placeholder="До..."
                        name="to"
                        isClearable={true}
                        type="datetime"
                        store={this.props.store}
                        readOnly={false}
                    />
                </Col>
                <Col style={{paddingLeft: 0}}>
                    <Select
                        onChange={e => this.onChangeSelect("priority", e)}
                        options={this.prioritiesMess}
                        loadingMessage={() => "загрузка"}
                        noOptionsMessage={() => "нет данных"}
                        placeholder="Приоритет..." isClearable/>
                </Col>
                {/*<Col style={{paddingLeft: 0}}>
                        <Select
                            onChange={e => this.onChangeSelect("type", e)}
                            options={this.typesMess}
                            loadingMessage={() => "загрузка"}
                            noOptionsMessage={() => "нет данных"}
                            placeholder="Тип..." isClearable/>
                    </Col>*/}
            </Row>
            {this.renderHeaderBtns(this.headerButtons)}
            <Form style={{display: "flex", gap: "5px"}} onSubmit={this.handleSubmitSearch}>
                <Form.Control onChange={e => this.setState({searchMess: e.target.value})} placeholder={"Поиск..."}
                              value={this.state.searchMess}></Form.Control>
                <Button type="submit" className={"notices__search"} variant="outline-primary"/>
            </Form>
        </Col>
    }

    onClickNotice(e) {
        if (this.noticesRef.current.compareDocumentPosition(e.target) < 20) {
            this.setState({isOpen: false})
            document.removeEventListener("click", this.onClickNotice)
        }
    }

    async onPageChanged(page) {
        await this.setState({page})
        this.load()
    }

    setNewMessCount() {
        const req = this.setDefaultReq()
        req.classification = ["NOT_READ"]
        NoticesService.listOfNotices(req).then(res => {
            this.setState({newMessCount: res.data.length})
            this.addAllFilter(this.headerButtons)
        })
    }

    setOpenToggle() {
        const isOpen = this.state.isOpen
        !isOpen && document.addEventListener('click', this.onClickNotice)
        this.setState({"isOpen": (!isOpen)})
    }

    render() {
        return (
            <div className={"d-flex align-items-center notice__mainBlock"} ref={this.noticesRef}>
                <div className={"NoticesBtn"} onClick={() => this.setOpenToggle()}>
                    <div className={"noticeBadge"}></div>
                    {this.state.newMessCount > 0 &&
                        <span className={"noticeBadgeCounter"}>{`${this.state.newMessCount}+`}</span>}
                </div>
                <div className={`notices ${!this.state.isOpen && "notices__close"}`}>
                    {this.renderHeader()}

                    {this.renderMessages()}

                    <div style={{display: "flex", justifyContent: "center"}}>
                        <Pagination page={this.state.page} pages={this.state.pages} onPageChanged={this.onPageChanged}/>
                    </div>
                </div>
            </div>
        );
    }
}

const NoticesInnerConnected = connect(mapStateToProps)(NoticesInner);

export default connect(mapGlobalStateToProps)(Notices);

