import React, {PureComponent} from "react";
import AuctionSettingsForm from "../AuctionSettingsForm/AuctionSettingsForm";
import {AuctionService} from "../../../services/AuctionService/AuctionService";
import {connect} from "react-redux";
import {
    fetchData,
    fetchDataFailure,
    fetchDataSuccess,
    setFormData,
    submitForm,
    submitFormFailure,
    submitFormSuccess
} from "../../../store/AuctionFormStore/auctionFormActions";
import {ToastService} from "../../../services/ToastService/ToastService";
import moment from 'moment'
import {AUCTION_NDS_OPTIONS} from "../../../store/AuctionFormStore/auctionFormInitialState";
import PropTypes from "prop-types";

class AuctionSettings extends PureComponent {
    async componentDidMount() {
        const {auctionId} = this.props;
        if (auctionId) {
            this.fetchAuctionDataById(auctionId);
        }
    }

    fetchAuctionDataById = async (id) => {
        const {
            fetchData,
            fetchDataSuccess,
            fetchDataFailure
        } = this.props;

        fetchData();

        const {data, error} = await AuctionService.getAuctionById(id);

        if (data) {
            const formattedData = this.formatAuctionData(data);
            fetchDataSuccess(formattedData);
        }

        if (error) {
            fetchDataFailure(error);
        }
    }

    formatAuctionData = (data) => {
        return {
            firstPrice: data.firstPrice / 100,
            status: data.status,
            timeStart: moment(data.timeStart).toISOString(),
            timeFinish: moment(data.timeFinish).toISOString(),
            auctionBidType: data.bidType,
            minPrice: data.auctionOptions.minPrice / 100,
            isBuyoutAvailable: !!data.auctionOptions.minPrice,
            isOneBidOnly: data.auctionOptions.isSingleBid,
            bidStep: data.bidType === "fixed" && data.auctionOptions.bidStep / 100,
            auctionNdsType: data?.auctionOptions?.nds
                ? AUCTION_NDS_OPTIONS[1].value
                : AUCTION_NDS_OPTIONS[0].value
        };
    }

    cancelAuctionHandler = async () => {
        const {
            submitForm,
            submitFormSuccess,
            submitFormFailure,
            auctionId
        } = this.props;

        submitForm();

        const {data, error} = await AuctionService.cancelAuction(auctionId);

        if (data) {
            const formattedData = this.formatAuctionData(data);
            submitFormSuccess(formattedData);
            ToastService.success({
                message: "Аукцион отменен успешно"
            });
        }

        if (error) {
            submitFormFailure(error);
            ToastService.error({
                message: "Ошибка отмены аукциона"
            });
        }
    }

    submitFormHandler = async (reqData) => {
        const {
            submitForm,
            submitFormSuccess,
            submitFormFailure,
            onSuccess,
            auctionId,
            quoteId
        } = this.props;

        const apiCallback = !auctionId || reqData.status === "canceled"
            ? AuctionService.createAuction
            : AuctionService.updateAuction;

        submitForm();

        const formattedRequestData = {
            quoteId: quoteId,
            firstPrice: reqData.firstPrice * 100,
            timeStart: reqData.timeStart,
            timeFinish: reqData.timeFinish,
            bidType: reqData.auctionBidType,
            auctionOptions: {
                minPrice: reqData.minPrice * 100,
                isSingleBid: reqData.isOneBidOnly,
                bidStep: reqData.bidStep * 100,
                nds: reqData.auctionNdsType === "nds"
            }
        }

        if (auctionId) {
            formattedRequestData["id"] = auctionId;
        }

        const {data, error} = await apiCallback(formattedRequestData);

        if (data) {
            const formattedData = this.formatAuctionData(data);
            submitFormSuccess(formattedData);
            ToastService.success({
                message: `Аукцион ${auctionId ? "обновлен" : "создан"} успешно`
            });
            onSuccess();
        }

        if (error) {
            submitFormFailure(error);
            ToastService.error({
                message: `Ошибка ${auctionId ? "обновления" : "создания"} аукциона`
            });
        }
    }

    render() {
        return (
            <AuctionSettingsForm
                onSubmit={this.submitFormHandler}
                onCancel={this.cancelAuctionHandler}
            />
        )
    }
}

const mapDispatchToProps = {
    setFormData,
    submitForm,
    submitFormSuccess,
    submitFormFailure,
    fetchData,
    fetchDataSuccess,
    fetchDataFailure,
};

AuctionSettings.propTypes = {
    auctionId: PropTypes.string.isRequired,
    quoteId: PropTypes.string.isRequired,
    onSuccess: PropTypes.func.isRequired,

    fetchData: PropTypes.func.isRequired,
    fetchDataSuccess: PropTypes.func.isRequired,
    fetchDataFailure: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    submitFormSuccess: PropTypes.func.isRequired,
    submitFormFailure: PropTypes.func.isRequired,
}

export default connect(null, mapDispatchToProps)(AuctionSettings);
