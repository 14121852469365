import {axios} from '../axios';

class ProductService {

    async getAllProducts(params) {
        const response = await axios.post("/api/v1/product/list", params);
        return response.data;
    }

    async read(id) {
        const response = await axios.get(`/api/v1/product/${id}`);
        return response.data;
    }

    async save(product) {
        const call = product.id
            ? axios.put
            : axios.post;
        const response = await call("/api/v1/product", product);

        return response.data;
    }
}

export default new ProductService();