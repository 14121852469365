import React, {PureComponent} from "react";
import {Button, Modal, Row} from "react-bootstrap";
import StyledEditor from "../../StyledEditor";
import TextEditor from "../DraftEditor/TextEditor";
import draftToHtml from "draftjs-to-html";
import {convertToRaw} from "draft-js";

class CommentModal extends PureComponent {

    constructor(props) {
        super(props);
        this.state = { message: "", isModalMess: false }
        this.handleSubmit = this.handleSubmit.bind(this)
    }
    componentDidUpdate() {
        if (this.props.show && !this.state.isModalMess) {
            this.setState({message: this.props.model.message})
            this.setState({isModalMess: true})
        }
        (!this.props.show && this.state.isModalMess) && this.setState({isModalMess: false})
    }
    async handleSubmit () {
        if (typeof this.state.message != "string") {
            await this.setState({message: draftToHtml(convertToRaw(this.state.message.getCurrentContent()))})
        }
        this.props.onSave(this.props.index, this.state.message || this.props.model?.message);
        this.props.onHide();
    }

    render() {
        return (
            <Modal size="lg" show={this.props.show} onHide={this.props.onHide}>
                <Modal.Header closeButton>
                    <h5>Редактирование комментария</h5>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <TextEditor value={this.state.message} onEditorStateChange={e => this.setState({message: e})} emogi={true} style={{minWidth:"700px", padding: "2vh"}}/>
                        <Row className={"justify-content-center"}>
                            <Button style={{width: "80%"}} size="sm" variant="primary" onClick={this.handleSubmit}>
                                Сохранить
                            </Button>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>);
    }
}

export default CommentModal;