import React from "react";

import { Form } from "react-bootstrap";
import { Editor } from "@tinymce/tinymce-react";

const StyledEditor = props => {
    return (
		<Form.Group style={props.style}>
			{props.title && <Form.Label>{props.title}</Form.Label>}
			<Editor
			    init={{
			        height: props.height || 250,
			        menubar: false,
				    selector: 'textarea',
 				    plugins: 'paste autosave save lists',
				    toolbar: 'styleselect bold italic alignleft aligncenter alignright alignjustify bullist numlist outdent indent'
			    }}
				initialValue={props.value}
				onChange={props.onChangeEditor}
				disabled={props.disabled}
			 />
		</Form.Group>
    );
};

export default StyledEditor;