import React, {PureComponent} from "react";
import {Card} from "react-bootstrap";
import cls from "./AuctionInfo.module.css";
import moment from "moment/moment";
import {classNames} from "../../../helpers/classNames";

export class AuctionInfo extends PureComponent {
    constructor(props) {
        super(props);
    }

    renderTextRow(title, text) {
        return (
            <div className={cls.requestRow}>
                <span>{title}</span>
                <span>{text}</span>
            </div>
        )
    }

    render() {
        const {
            requestData,
            timeStart,
            timeFinish
        } = this.props;

        const {
            customerOrganization,
            transporterOrganization,
            sender,
            receiver,
            cargo,
            transport
        } = requestData;

        return (
            <Card className={cls.request}>
                <Card.Body>
                    <div className={cls.row}>
                        <div>
                            {this.renderTextRow("Начало торогов:", moment(timeStart).format("DD.MM.YYYY, HH:mm:ss"))}
                        </div>

                        <div>
                            {this.renderTextRow("Окончание торгов:", moment(timeFinish).format("DD.MM.YYYY, HH:mm:ss"))}
                        </div>
                    </div>

                    <div className={cls.row}>
                        <div>
                            {this.renderTextRow("Заказчик:", customerOrganization ?? "-")}
                        </div>

                        <div>
                            {this.renderTextRow("Организация перевозчика:", transporterOrganization ?? "-")}
                        </div>
                    </div>

                    <div className={cls.row}>
                        <div>
                            {this.renderTextRow("Тип кузова:", transport?.type ?? "-")}
                        </div>

                        <div>
                            {this.renderTextRow("Груз:", cargo?.type ?? "-")}
                        </div>
                    </div>

                    <div className={cls.row}>
                        <div>
                            {this.renderTextRow("Вес (тонн):", cargo?.weight ?? "-")}
                        </div>

                        <div>
                            {this.renderTextRow("Расстояние (км):", cargo?.distance ?? "-")}
                        </div>
                    </div>

                    <div className={cls.row}>
                        <div>
                            {this.renderTextRow("Грузоотправитель:", sender?.name ?? "-")}
                        </div>

                        <div>
                            {this.renderTextRow("Грузополучатель:", receiver?.name ?? "-")}
                        </div>
                    </div>

                    <div className={classNames(cls.row, {}, [cls.lastRow])}>
                        <div>
                            {
                                this.renderTextRow(
                                    "Адрес грузоотправителя:",
                                    `${sender?.city} ${sender?.address}`
                                )
                            }
                        </div>

                        <div>
                            {
                                this.renderTextRow(
                                    "Адрес грузополучателя:",
                                    `${receiver?.city} ${receiver?.address}`
                                )
                            }
                        </div>
                    </div>
                </Card.Body>
            </Card>
        )
    }
}