import useSharedWorker from "./useSharedWorker";

export const useAuction = (callback, auctionId) => {
    const {sharedWorker} = useSharedWorker(callback);
    sharedWorker.port.postMessage({
        type: "subscription",
        auctionId
    });
    return sharedWorker;
}

