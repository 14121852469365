import React from "react";
import { connect } from 'react-redux';

import driverService from '../../../services/DriverService';
import DataTable from '../../table/DataTable';
import TableCell from '../../table/TableCell';
import {COMMERCIAL, LOGISTIC, securityService} from "../../../services/SecurityService";
import {EXTERNAL} from "../../../services/UserService";

class Drivers extends React.PureComponent {
	fetch(params) {
		return driverService.list(params);
	}

	openAddForm() {
		window.open("/control-panel/drivers/driver", "_blank")
	}

	openEditForm(row) {
		window.open(`/control-panel/drivers/driver?id=${row.id}`, "_blank")
	}

	checkMinimalSearch() {
		return securityService.hasOnlyRoles(this.props.user, LOGISTIC, COMMERCIAL);
	}

	checkIfCanAdd() {
		const user = securityService.getUser();
		const roles = securityService.getRolesByType(EXTERNAL);
		return !roles.some(role => securityService.hasRole(user, role));
	}

	render() {
		return (
			<DataTable {...this.props}
				title = "Водители"
				sort = {{field: 'fio', dir: 'asc'}}
				openAddForm = {this.openAddForm.bind(this)}
				openEditForm = {this.openEditForm.bind(this)}
				fetch = {this.fetch.bind(this)}
				features = {{
                    checkMinimalSearch: this.checkMinimalSearch(),
                    canAdd: this.checkIfCanAdd()
                }}
				columns = {[
					{ title: "#", field: "id", className: "id", filter: DataTable.STRING_FILTER },
					{ title: "Ф.И.О.", field: "fio", filter: DataTable.STRING_FILTER },
					{ title: "Паспортные данные", field: "passportNumber", filter: DataTable.STRING_FILTER },
					{ title: "Вод. удостоверение", field: "license", filter: DataTable.STRING_FILTER },
					{ title: "Телефон", field: "phone", filter: DataTable.STRING_FILTER },
					{ title: "Дата рождения", field: "birthday", filter: DataTable.DATE_RANGE_FILTER },
					{ title: "Комментарий", field: "comment", filter: DataTable.STRING_FILTER },
					{ title: "Активен", field: "active", className: "yes-no", 
						formatter: TableCell.booleanFormatter, filter: DataTable.BOOLEAN_FILTER },
					{ title: "Перевозчики", field: "contractors", filter: DataTable.DICTIONARY_FILTER, optionsType: 'CONTRACTOR_CARRIER',
						multiSelect: true, formatter: value => TableCell.arrayFormatter(value), sortable: false }
				]}
			/>
		);
	}	
}
export default connect(DataTable.mapStateToProps)(Drivers);
