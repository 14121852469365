import {Form} from "react-bootstrap";
import React from "react";
import {Editor} from "@tinymce/tinymce-react/lib/cjs/main/ts/components/Editor";

const TinyEditor = (props) => {
    return (
        <Form.Group className={'tiny-editor'} style={props.styles}>
            <Form.Label>{props.title}</Form.Label>
            <Editor
                onEditorChange={props.handleEdit}
                init={{
                    valid_children: '-p[img] -h1[img] -h2[img] -h3[img] -h4[img] -h5[img] -h6[img]',
                    language: 'ru',
                    browser_spellcheck: true,
                    selector: 'textarea',
                    plugins: 'autoresize'
                }}
                value={props.value}
            />
        </Form.Group>
    );
}

export default TinyEditor;