import React from "react";
import { Col, Container, ProgressBar, Row, Table } from "react-bootstrap";
import DictionarySelect from "./form/DictionarySelect";
import EnumSelect from "./form/EnumSelect";
import Enums from "../Enums";
import TableCell from "./table/TableCell";

class AttachmentsTable extends React.PureComponent {
	
	constructor(props) {
		super(props);
		
		this.state = { editor: null };
		this.columns = [
			{
				field: "dt", 
				title: "Дата", 
				type: "date"
			},
			{...this.props?.showFileName !== false && {
				field: "fileName",
				title: "Название",
				type: "title"
			}},
			{...this.props.showType && this.props.showType !== false && {
				field: "type",
				title: "Тип документа",
				type: "enum",
				editable: true,
				optionsType: this.props.optionsType,
				formatter: TableCell.enumFormatter(Enums[this.props.optionsType]),
				onChange: (attachment, value) => this.props.onChange({...attachment, type: value})
			}},
			{...this.props.showDocumentType && this.props.showDocumentType !== false && {
				field: "documentType",
				title: "Тип документа",
				type: "dictionary",
				editable: true,
				optionsType: "DOCUMENT_TYPE",
				onChange: (attachment, value) => this.props.onChange({...attachment, documentType: value})
			}},
			{...this.props.showDownloadLink && this.props.showDownloadLink !== false && {
				field: "fileName",
				title: "Прямая ссылка на файл",
				type: "directLink"
			}},
			{
				field: "creator", 
				title: "ФИО прикрепившего",
				type: "user"
			}
		];

		this.mouseListener = this.mouseListener.bind(this);
		this.attachmentsTable = React.createRef();

		this.fileStorageService = this.props.fileStorageService;
	}

	getColumns() {
		const {columns} = this.props;
		return columns ?? this.columns.filter(col => col.title);
	}
	
	mouseListener(e) {
		if (!this.attachmentsTable.current.contains(e.target)) {
			this.addRemoveMouseListener(false);
			this.setState({editor: null});
		}
	}

	addRemoveMouseListener(listen) {
		const addRemove = listen ? document.body.addEventListener : document.body.removeEventListener;
		addRemove('mousedown', this.mouseListener, true);
	}

	openEditor(position, field) {
		this.addRemoveMouseListener(true);
		this.setState({'editor': {position, field}})		
	}
	
	renderHeader() {
		return (
			<thead>
				<tr>
					{this.getColumns().map(col =>
						<th key={col.title}>
							{col.title}
						</th>
					)}
					<th/>
				</tr>
			</thead>
		);
	}

	renderProgress(file, index) {
		return (
			<Row key={`progress-${index}`}>
				<Col key={`progress-${index}`} className="upload-progress" xs="3">
					<ProgressBar min={0} now={file.loaded} max={file.file.size}/>
				</Col>
				<Col key={`filename-${index}`} className="text-break">
					{`${file.attachment.originalName}${file.error ? (" " + file.error) : ""}`}
				</Col>
				{file.error &&
					<Col key={`actions-${index}`} xs="1">
						<i className="fas fa-times" onClick={() => this.props.removePending(file)}></i>
					</Col>
				}
			</Row>
		);
	}
	
	renderRow(attachment, sequentialNumber, index) {
		const editor = this.state.editor,
			showEditor = editor && editor.position === index;
		return (
			<tr key={sequentialNumber}>
				{this.getColumns().map(col => {
					const isEditor = showEditor && editor.field === col.field && col.editable,
						className = (col.className || col.field) + (isEditor ? " editor" : "");
					const handler = () => !isEditor && this.openEditor(index, col.field);
					return ( 
						<td key={col.field} className={className} tabIndex={0} onClick={() => handler()} onFocus={() => handler()}>
							{isEditor ? this.renderEditor(attachment, col, sequentialNumber) : this.renderValue(attachment, col, sequentialNumber)}
						</td>);
					})
				}
				{ this.renderPreview(attachment, sequentialNumber) }
				{ this.renderDownload(attachment, sequentialNumber+1) }
				{ !this.props.hideRemove && this.renderRemove(attachment, sequentialNumber+2) }
			</tr>
		);
	}

	renderPrimitive(col, row, value) {
		const valueSafe = value || row[col.field];
		return col.formatter ? col.formatter(valueSafe, row) : valueSafe;
	}

	renderObject(col, row) {
		const value = row[col.field],
			isDictionary = value && value.id && value.value;
		if (value) {
			if (isDictionary) {
				return this.renderPrimitive(col, row, value.value);
			}
			return this.renderPrimitive(col, row);
		}
	}
	
	renderEditor(attachment, col, index) {
		switch (col.type) {
		    case "dictionary":
		      	return (<DictionarySelect
							menuPosition={"fixed"}
							closeMenuOnScroll={true}
							onChange={(value) => col.onChange(attachment, value)}
							optionsType={col.optionsType}
							value={attachment[col.field]} />
				);
			case "enum":
				return (<EnumSelect
							menuPosition={"fixed"}
							closeMenuOnScroll={true}
							onChange={(value) => col.onChange(attachment, value.value)}
							optionsType={col.optionsType}
							value={Enums[this.props.optionsType][attachment[col.field]]} />
				);
		}
	}
	
	renderValue(attachment, col, index) {
		const obj = attachment[col.field];
		if (!obj) {
			return null;
		}		
		switch (col.type) {
		  	case "date":
			  	return this.formatDate(new Date(obj));
		  	case "title":
				return attachment.displayedName;
			case "directLink":
				if (!attachment.id) {
					return null;
				}
				let fileName = obj.substring(obj.lastIndexOf("/") + 1);
				return this.fileStorageService.getDirectLink(attachment.id, fileName);
		  	case "user":
		      	return obj?.value || obj?.fio;
			case "enum":
				return this.renderObject(col, attachment);
		  	default:
		      	return obj && obj.value ? obj.value : obj;
		}
	}
	
	async handleDownload(attachment) {
		await this.fileStorageService.download(attachment);
	}
	
	async handlePreview(id, displayedName) {
		if (id != null && displayedName !== null) {
			await this.fileStorageService.preview(id, displayedName);
		}
	}
	
	renderPreview(attachment, index) {
		const { displayedName, originalName } = attachment;
		return (
			<td key={`action-${index}`} className="actions">
				<i className="fa fa-eye" onClick={() => this.handlePreview(attachment.id, displayedName || originalName)}/>
			</td>);
	}
	
	renderDownload(attachment, index) {
		return (
			<td key={`action-${index}`} className="actions">
				<i className="fa fa-download" onClick={() => this.handleDownload(attachment)}/>
			</td>);
	}

	renderRemove(attachment, index) {
		return (
			<td key={`action-${index}`} className="actions">
				<i className="fas fa-times" onClick={() => this.props.removeRow(attachment)}/>
			</td>);
	}

	formatDate(dt) {
		return `${dt.getDate().pad(2)}.${(1 + dt.getMonth()).pad(2)}.${dt.getFullYear()} ${dt.getHours().pad(2)}:${dt.getMinutes().pad(2)}`;	
	}	
	
	render() {
		return (
			<React.Fragment>
				<Table ref={this.attachmentsTable} striped bordered hover size="sm" className="attachments-table">
					{ this.renderHeader() }
					<tbody>
						{ this.props.attachments.map((e, index) => this.renderRow(e.attachment, e.sequentialNumber, index)) }
					</tbody>
				</Table>
				{ this.props.pendingFiles &&
					<Container fluid className="mb-3">
						{ this.props.pendingFiles.map((file, index) => this.renderProgress(file, index)) }
					</Container>
				}
			</React.Fragment>
		);
	}
	
}

export default AttachmentsTable;
