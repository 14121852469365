import React from "react";
import productService from "../../services/ProductService";
import DataTable from "../table/DataTable";
import TableCell from "../table/TableCell";

class Products extends React.PureComponent {
    fetch(params) {
        return productService.getAllProducts(params);
    }

    openAddForm() {
        window.open("/control-panel/products/product", "_blank")
    }

    openEditForm(row) {
        window.open(`/control-panel/products/product?id=${row.id}`, "_blank")
    }

    columns = [
        { title: "#", field: "id", className: "id", filter: DataTable.STRING_FILTER },
        { title: "Категория", field: "productType", filter: DataTable.DICTIONARY_FILTER, optionsType: "PRODUCT_TYPE", multiSelect: true },
        { title: "Название", field: "name", filter: DataTable.STRING_FILTER },
        { title: "Описание", field: "description", filter: DataTable.STRING_FILTER },
        { title: "Цена", field: "price", filter: DataTable.NUMERIC_RANGE_FILTER },
        { title: "Опубликовано", field: "active", className: "yes-no", formatter: TableCell.booleanFormatter,
            filter: DataTable.BOOLEAN_FILTER }
    ]

    render() {
        return (
            <DataTable {...this.props}
                       title = "Товары"
                       openAddForm = {this.openAddForm.bind(this)}
                       openEditForm = {this.openEditForm.bind(this)}
                       fetch = {this.fetch.bind(this)}
                       columns = {this.columns}
            />
        );
    }
}

export default Products