import BaseForm from "../form/BaseForm";
import {Tab} from "react-bootstrap";
import TaskTable from "./task/TaskTable";
import {TaskType} from "../../Const";
import { connect } from "react-redux";
import EventTable from "./event/EventTable";
import Reports from "./report/Reports";
import {securityService} from "../../services/SecurityService";
import "./TaskTracker.css"

function mapGlobalStateToProps(state) {
    return {
        user: state.auth.user
    };
}

class TaskTrackerForm extends BaseForm {
    constructor(props) {
        super(props);
    }

    load() {
        if (new URLSearchParams(this.props.location.search).get("activeTab") === "templates") {
            this.setState({ activeTab: "templates" });
        }
    }

    getTabs() {
        const tabs = [];
        tabs.push(this.getTemplateTab());
        tabs.push(this.getEventTab());

        if (securityService.isAdmin() || securityService.isHeadOfDepartment()) {
            tabs.push(this.getReportTab());
        }
        return tabs;
    }

    getFormTabTitle() {
        return "Задачи";
    }

    getTemplateTab() {
        return (
            <Tab key="templates" eventKey="templates" title="Шаблоны" mountOnEnter unmountOnExit>
                <TaskTable {... this.props} type={TaskType.TYPE_TEMPLATES()}/>
            </Tab>
        );
    }

    getEventTab() {
        return (
            <Tab key="event" eventKey="event" title="События" mountOnEnter unmountOnExit>
                <EventTable {... this.props}/>
            </Tab>
        );
    }

    getReportTab() {
        return (
            <Tab key="report" eventKey="report" title="Отчеты" mountOnEnter unmountOnExit>
                <Reports {... this.props}/>
            </Tab>
        );
    }

    renderForm() {
        return <div className={"mt-2"}><TaskTable {... this.props} type={TaskType.TYPE_TASKS()}/></div>
    }
}

export default connect(mapGlobalStateToProps)(TaskTrackerForm);