import BaseForm from "../../form/BaseForm";
import {HEAD_OF_DEPARTMENT, LOGISTIC} from "../../../services/SecurityService";
import motivationService from "../../../services/MotivationService";
import {tr} from "date-fns/locale";
import {Button, Form, Table} from "react-bootstrap";
import SalaryTable from "./SalaryTable";

class CoefficientTable extends BaseForm {
    static FIELD_DEPARTMENT_ID = "departmentId";
    static FIELD_BOTTOM_STEP = "bottomStep";
    static FIELD_TOP_STEP = "topStep";
    static FIELD_COEFFICIENT = "managerCoefficient";
    static FIELD_HEAD_OF_DEPARTMENT = "headOfDepartmentCoefficient";
    static START_COEFFICIENT = "startCoefficient";
    static FINAL_COEFFICIENT = "finalCoefficient";
    static STEPS = "steps";

    static FIELDS = [
        { key: CoefficientTable.FIELD_DEPARTMENT_ID, value: "Подразделение" },
        { key: CoefficientTable.FIELD_BOTTOM_STEP, value: "Нижний порог (включая)" },
        { key: CoefficientTable.FIELD_TOP_STEP, value: "Верхний порог (исключая)" },
        { key: CoefficientTable.FIELD_COEFFICIENT, value: "Коэффициент" },
        { key: CoefficientTable.FIELD_HEAD_OF_DEPARTMENT, value: "Добавка для руководителя" },
        { key: CoefficientTable.START_COEFFICIENT, value: "Начальный коэффициент" },
        { key: CoefficientTable.FINAL_COEFFICIENT, value: "Конечный коэффициент" },
        { key: CoefficientTable.STEPS, value: "Уровни" }
    ];

    static ROLES = [
        { id: LOGISTIC, value: "Логист" },
        { id: HEAD_OF_DEPARTMENT, value: "Руководитель" },
        { id: SalaryTable.DIRECTOR_OF_LOGISTIC, value: "Директор по логистике" },
        { id: SalaryTable.REGIONAL_HEAD, value: "Региональный руководитель" }
    ];

    static COEFFICIENT_TYPES =  [
        { id: "BONUS", value: "Кэффициенты мотиваци" },
        { id: "MARGIN_PLAN", value: "Кэффициенты выполнения плана по марже" },
        { id: "REVENUE_PLAN", value: "Кэффициенты выполнения плана по выручке" }
    ];

    constructor(props) {
        super(props);

        this.state = {
            toggleButtons: 0,
            coefficients: null,
            selectDepartmentId: "any",
            selectRole: "any",
            selectCoefficientType: "any",
            isEdit: false,
            key: null,
            departments: [],
            copyCoefficients: false
        }
    }

    componentDidMount() {
        motivationService.get("departments").then(response => {
            this.setState({departments: response});
        });

        motivationService.post("coefficient", { dt: this.props.dt })
            .then(response => this.setCoefficients(response));
    }

    renderHeader() {
        return (
            <thead className="table-header">
            <tr>
                {CoefficientTable.FIELDS.map(col =>
                    <th key={col.key}>
                        {col.value}
                    </th>
                )}
            </tr>
            </thead>
        );
    }

    setCoefficients(response) {
        this.setState({linear : response?.find(c => c.coefficientType === "LINEAR") !== undefined});
        this.setState({coefficients: response?.filter(c => c.coefficientType !== "LINEAR")});
        this.setState({copyCoefficients : false});
    }

    openOrCloseFieldEditor(key, isOpen) {
        this.setState({isEdit: isOpen, key});
    }

    tableSelect(label, stateKey, options) {
        return <Form.Group style={{width: "32%", margin: "5px"}}>
            <Form.Label>{label}</Form.Label>
            <Form.Control size="sm" as="select" onChange={event => this.setState({[stateKey]: event.target.value})}>
                <option value='any'>-</option>
                {options.map(option => <option value={option.id}>{option.value}</option>)}
            </Form.Control>
        </Form.Group>
    }

    renderCopyCoefficientButton() {
        return <Form style={{textAlign: "center"}}>
            <Button variant="primary" onClick={() => this.setState({copyCoefficients : true})}>
                Копировать коэффициенты предыдущего месяца
            </Button>
        </Form>;
    }

    areYouSure() {
        return <Form style={{textAlign: "center"}}>
            <Button style={{width: "150px", marginRight: "15px"}}
                    variant="primary"
                    onClick={() => this.copyCoefficients()}>
                Копировать
            </Button>
            <Button style={{width: "150px"}}
                    variant="danger"
                    onClick={() => this.setState({copyCoefficients : false})}>
                Отмена
            </Button>
        </Form>;
    }

    copyCoefficients() {
        motivationService.post("copyCoefficients", { dt: this.props.dt })
            .then(response => this.setCoefficients(response));
    }

    renderCheckBox() {
        return <Form>
            <Form.Check
                type="switch"
                id="custom-switch"
                label="Рассчитывать зарплату по оплаченным заявкам за этот месяц только по коэффициентам этого месяца"
                defaultChecked={this.state.linear}
                onClick={(e) =>
                    motivationService.post(
                        "changeCoefficientsType",
                        {dt : this.props.dt, isChecked : e.target.checked})}
            />
        </Form>;
    }

    render() {
        return (
            <>
                {this.renderCheckBox()}
                <Form>
                    <div style={{display: "flex", justifyContent: "space-evenly"}}>
                        {this.tableSelect("Тип", "selectCoefficientType", CoefficientTable.COEFFICIENT_TYPES)}
                        {this.tableSelect("Роль", "selectRole", CoefficientTable.ROLES)}
                        {this.tableSelect("Подразделение", "selectDepartmentId", this.state.departments)}
                    </div>
                    <Table striped bordered size="sm">
                        {this.renderHeader()}
                        <tbody>
                        {this.state.coefficients?.map(coefficient =>
                            this.selectedCoefficientType(coefficient) && this.selectedDepartment(coefficient) && this.selectedRole(coefficient) &&
                            <tr key={coefficient.id}>
                                {CoefficientTable.FIELDS.map(col =>
                                    <td style={{width: "100px"}}
                                        key={col.key + coefficient.id}>
                                        {this.editCell(col, coefficient)}
                                    </td>
                                )}
                            </tr>
                        )}
                        </tbody>
                    </Table>
                </Form>
                {this.state.copyCoefficients ? this.areYouSure() : this.renderCopyCoefficientButton()}
            </>
        );
    }

    editCell(col, coefficient) {
        return this.state.isEdit && this.state.key === col.key + coefficient.id && col.key !== CoefficientTable.FIELD_DEPARTMENT_ID
            ?
            <input
                className="table-content-edit"
                style={{width: "85px"}}
                type="number"
                defaultValue={coefficient[col.key]}
                onBlur={() => this.openOrCloseFieldEditor(col.key + coefficient.id, false)}
                autoFocus
                onChange={(event) => {
                    coefficient[col.key] = event.target.value;
                    motivationService.post("saveCoefficient", coefficient);
                }}
            />
            :
            <div style={col.key === CoefficientTable.FIELD_DEPARTMENT_ID ? {width: "200px"} : {width: "100%", height: "20px"}}
                 onClick={() => this.openOrCloseFieldEditor(col.key + coefficient.id, true)}
            >
                {col.key === CoefficientTable.FIELD_DEPARTMENT_ID
                    ? this.state.departments?.find(d => d.id === coefficient?.departmentId)?.value
                    : coefficient[col.key]}
            </div>
    }

    selectedCoefficientType(coefficient) {
        return this.state.selectCoefficientType === coefficient.coefficientType || this.state.selectCoefficientType === "any";
    }

    selectedDepartment(coefficient) {
        return this.state.selectDepartmentId === coefficient.departmentId + '' || this.state.selectDepartmentId === "any";
    }

    selectedRole(coefficient) {
        return this.state.selectRole === coefficient.role + '' || this.state.selectRole === "any";
    }
}

export default CoefficientTable;