import { connect } from 'react-redux';
import { Form, Row, Col, Tab } from "react-bootstrap";

import organizationService from "../../../services/OrganizationService";
import {
	requiredValidator,
	innValidator,
	kppValidator,
	ogrnValidator,
	bikValidator,
	bankAccountValidator,
	phoneValidator
} from "../../../validators/simpleValidators";
import StoreWrapper from '../../form/StoreWrapper';
import BaseForm from '../../form/BaseForm';
import FormGroup from '../../form/FormGroup';
import { setData } from '../../../actions/form';
import Loading from '../../Loading';
import FormValidator from '../../../validators/FormValidator';
import { ORGANIZATION } from '../../AuditTable/AuditTable';
import PaymentAccounts from '../payment-accounts/PaymentAccounts';
import paymentAccountService from "../../../services/PaymentAccountService";
import ValidationErrors from '../../../validators/ValidationErrors';
import UploadForm from "./UploadForm";
import {setToastObjAC} from "../../../reducers/toastObj";
import OrganizationContractorHistoryTable from "./OrganizationContractorHistoryTable";
import React from "react";

function mapGlobalStateToProps(state) {
	return {}
}

class Organization extends StoreWrapper {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<OrganizationInnerConnected {...this.props} store={this.store} />
		);
	}
}

function mapStateToProps(state) {
	return {
		errors: state.errors,
		model: state.model
	};
}
class OrganizationInner extends BaseForm {
	constructor(props) {
		super(props);
		this.applyInternalValidator = this.applyInternalValidator.bind(this)
		this.configureValidators();
	}

	configureValidators() {
		this.useValidatorFor(requiredValidator, "value", "name", "ogrn", "inn", "address", "phone", "authority", "forwarder", "authorityFio");
		this.validator.addValidator('value', this.valueUniqueValidator.bind(this));
		this.useValidatorFor(innValidator, "inn");
		this.useValidatorFor(kppValidator, "kpp");
		this.useValidatorFor(ogrnValidator, "ogrn");
		this.useValidatorFor(this.applyInternalValidator(phoneValidator.bind(this)), "phone");
		this.validator.addValidator('inn', this.innUniqueValidator.bind(this));
		this.validator.addValidator('paymentAccounts', this.paymentAccountsValidator.bind(this));
	}

	applyInternalValidator(validator) {
		return value => this.execInternalValidator(validator, value);
	}

	async execInternalValidator(validator, value) {
		return validator(value);
	}

	async valueUniqueValidator() {
		const value = this.props.model.value;
		if (!value) {
			return FormValidator.PROMISE_OK;
		}
		const isValid = await organizationService.isValueUnique(this.getOrganizationId(), value);
		return isValid ? FormValidator.OK : "организация с таким названием уже зарегистрирована в системе";
	}

	async innUniqueValidator() {
		const inn = this.props.model.inn;
		if (!inn) {
			return FormValidator.PROMISE_OK;
		}
		const isValid = await organizationService.isInnUnique(this.getOrganizationId(), inn);
		return isValid ? FormValidator.OK : "этот ИНН уже зарегистрирован в системе";
	}
	
	async paymentAccountsValidator() {
		const errors = [];
		const paymentAccounts = this.props.model.paymentAccounts;
		if (paymentAccounts && paymentAccounts.length) {
			for (let i = 0; i < paymentAccounts.length; i++) {
				const paymentAccount = paymentAccounts[i];
				if (await bikValidator(paymentAccount.bik)
					|| await requiredValidator(paymentAccount.account)) {
					errors.push(i + 1)
				}
			}
		}
		return errors.length ? `Ошибки валидации Р/счетов с порядковыми номерами: ${errors.join(', ')}` : FormValidator.OK;
	}

	getOrganizationId() {
		return this.props.location.state?.id
			|| new URLSearchParams(this.props.location.search).get('id');
	}

	load() {
		const id = this.getOrganizationId();
		if (id) {
			organizationService.read(id).then(data => {
				this.props.store.dispatch(setData(data, this.props.location.state?.action));
			});
		} else {
			this.props.store.dispatch(setData({ active: true }));
		}
	}

	handleSubmit(e) {
		e.preventDefault();
		this.submit(() =>
			organizationService.save(this.props.model).then(
				(res) => {
					this.afterSubmit(res)
				})
		);
	}

	afterSubmit(res) {
		const data = {show: true, textHeader: "Организация сохранена!", delay: 3000};
		if(!this.getOrganizationId()) {
			this.redirectToBackOrDefaultUrl('/control-panel/organizations');
			data.textHeader = "Организация создана!"
		}
		this.load()
		this.props.setToastObjAC(data)
	}

	onChangeCheckbox(fieldName, e) {
		this.onChange(fieldName, e.target.checked);
	}

	getTabs() {
		const tabs = [];
		if (this.getOrganizationId()) {
			tabs.push(this.getPaymentAccountsTab());
			tabs.push(this.getStampAndSignsTab());
			tabs.push(this.getAuditTab(this.getOrganizationId(), ORGANIZATION));
			tabs.push(this.getOrganizationContractorHistoryTab())
		}
		return tabs;
	}

	getFormTabTitle() {
		const name = this.props.model.name || (this.props.model.id > 0 ? this.props.model.id : ''),
			title = "Организация " + name;
		return title;
	}
	
	getPaymentAccountsTab() {
		return (
			<Tab eventKey="paymentAccounts" title="Р/Счета">
				{this.state.activeTab === "paymentAccounts" && 
				<PaymentAccounts 
					{...this.props} 
					applyInternalValidator={this.applyInternalValidator}
					save={paymentAccountService.saveToOrganization}
					delete={paymentAccountService.deleteFromOrganization}
				/>}
			</Tab>
		);
	}

	getStampAndSignsTab() {
		return (
			<Tab eventKey="stampAndSigns" title="Печать и факсимиле">
				{this.state.activeTab === "stampAndSigns" && <UploadForm {...this.props} />}
			</Tab>
		);
	}

	getOrganizationContractorHistoryTab() {
		return <Tab eventKey="organizationContractorHistory" title="Собственные контрагенты">
				{this.state.activeTab === "organizationContractorHistory"
					&& <OrganizationContractorHistoryTable organizationId={this.getOrganizationId()}/> }
			</Tab>
	}

	renderForm() {
		const isReady = !this.getOrganizationId() || this.props.model.id;
		if (!isReady) {
			return (<Loading/>);
		}
		return (
			<Form>

				<Row>
					<Col lg={4} md={12}>
						<FormGroup title="Наименование" name="value" store={this.props.store} required />
					</Col>
					<Col lg={4} md={12}>
						<FormGroup title="Полное наименование" name="name" store={this.props.store} required />
					</Col>
					<Col lg={4} md={12}>
						<FormGroup title="Тел." type="phone" name="phone" store={this.props.store} required />
					</Col>
				</Row>
				<Row>
					<Col lg={4} md={12}>
						<FormGroup title="ОГРН" name="ogrn" store={this.props.store} required />
					</Col>
					<Col lg={4} md={12}>
						<FormGroup title="КПП" name="kpp" store={this.props.store} />
					</Col>
					<Col lg={4} md={12}>
						<FormGroup title="ИНН" name="inn" store={this.props.store} required />
					</Col>
				</Row>				
				<Row>
					<Col lg={4} md={12}>
						<FormGroup title="Почтовый адрес" name="mailingAddress" store={this.props.store} />
					</Col>
					<Col lg={4} md={12}>
						<FormGroup title="Фактический адрес" name="address" store={this.props.store} required />
					</Col>
					<Col lg={4} md={12}>
						<FormGroup title="Юридический адрес" name="legalAddress" store={this.props.store} />
					</Col>
				</Row>
				<Row>
					<Col lg={4} md={12}>
						<FormGroup title="Должность руководителя" name="authorityPosition" store={this.props.store} />
					</Col>
					<Col lg={4} md={12}>
						<FormGroup title="ФИО руководителя" name="authorityFio" store={this.props.store} required />
					</Col>
					<Col lg={4} md={12}>
						<FormGroup title="В лице" name="authority" store={this.props.store} required />
					</Col>
					<Col lg={4} md={12}>
						<FormGroup title="Бухгалтер" name="accountant" store={this.props.store} />
					</Col>
					<Col lg={4}>
						<FormGroup title="Экспедитор"
								   name="forwarder"
								   type="dictionary"
								   optionsType="FORWARDER"
								   store={this.props.store}
								   required
						/>
					</Col>
					<Col>
						<FormGroup title="Запрет публикации в бирже"
								   name="locked"
								   type="checkbox"
								   store={this.props.store}
								   defaultChecked={this.props.model.locked}
								   onClick={(e) => this.onChangeCheckbox("locked", e)}
						/>
					</Col>
				</Row>
				
				{this.props.errors && this.props.errors["paymentAccounts"] && <ValidationErrors errors={this.props.errors["paymentAccounts"]} />}
				
				{this.renderSaveCancelButtons()}
			</Form>
		);
	}
}

const OrganizationInnerConnected = connect(mapStateToProps)(OrganizationInner);

export default connect(mapGlobalStateToProps, {setToastObjAC})(Organization);