export const SET_FIRST_PRICE = "SET_FIRST_PRICE";
export const SET_AUCTION_BID_TYPE = "SET_AUCTION_BID_TYPE";
export const SET_AUCTION_NDS_TYPE = "SET_AUCTION_NDS_TYPE";
export const SET_IS_ONE_BID_ONLY = "SET_IS_ONE_BID_ONLY";
export const SET_BUYOUT_AVAILABLE = "SET_BUYOUT_AVAILABLE";
export const SET_MIN_PRICE = "SET_MIN_PRICE";
export const SET_BID_STEP = "SET_BID_STEP";
export const SET_STATUS = "SET_STATUS";
export const SET_TIME_START = "SET_TIME_START";
export const SET_TIME_FINISH = "SET_TIME_FINISH";

export const SUBMIT_FORM = "SUBMIT_FORM"
export const SUBMIT_FORM_SUCCESS = "SUBMIT_FORM_SUCCESS"
export const SUBMIT_FORM_FAILURE = "SUBMIT_FORM_FAILURE"

export const SET_VALIDATION_ERRORS = 'SET_VALIDATION_ERRORS';

export const SET_FORM_DATA = 'SET_FORM_DATA';
export const SET_FORM_ERRORS = 'SET_FORM_ERRORS';

export const FETCH_DATA = 'FETCH_DATA';
export const FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS';
export const FETCH_DATA_FAILURE = 'FETCH_DATA_FAILURE';