import React from "react";
import {Card, Row, Col, Button} from "react-bootstrap";
import SLIntegration from "./SLIntegration";

class SLImport extends React.PureComponent {

	constructor(props) {
		super(props);
		this.state = {
			selectedFile: null,
		};
		this.changeHandler = this.changeHandler.bind(this);
		this.handleImport = this.handleImport.bind(this);
	}

	changeHandler(event) {
		this.setState({
			selectedFile: event.target.files[0],
		});
		if (this.props.updateProgress) {
			this.props.updateProgress(0);
		}
	}

	handleImport() {
		this.props.handleImport(this.props.type, this.state.selectedFile);
	}

	hasValidFile() {
		return this.state.selectedFile !== null
			&& this.state.selectedFile?.name !== null
			&& this.state.selectedFile?.name.match(SLIntegration.SL_IMPORT_FILE_EXTENSION_PATTERN);
	}

	renderCompleteButton() {
		return (
			<div>
				{!this.props.isBusy && this.hasValidFile() &&
					<Button variant="primary" size="sm" disabled={this.props.isLoading} onClick={this.handleImport}>
						{this.props.isLoading && (<span className="spinner-border spinner-border-sm"></span>)}
						<span>Выполнить</span>
					</Button>
				}
			</div>
		);
	}

	render() {
		if (this.props.isBusy !== false) {
			return ("");
		}
		return (
			<Row className="import-card">
				<Col>
					<Card>
						<Card.Body>
							<Row className="mb-3">
								<Col>
									Импорт - {this.props.title}
								</Col>
							</Row>
							<Row>
								<Col>
									<input type="file" name="file" onChange={this.changeHandler} />
								</Col>
								<Col>
									{this.renderCompleteButton()}
								</Col>
							</Row>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		);
	}

}
export default SLImport;