import React, {PureComponent} from "react";
import {connect} from "react-redux";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import Addresses from "./Addresses";
import contractorService from "../../../services/ContractorService";
import addressService from "../../../services/AddressService";
import {setValue} from "../../../actions/form";
import Address from "./Address";
import * as PropTypes from "prop-types";

function mapStateToProps(state, ownProps) {
    return {
        contractor:{
            id: state.model.contractor.id,
            value: state.model.contractor.value,
        },
        model: state.model,
        errors: state.errors,
        addresses: state.model.addresses
    };
}


class AddressModal extends PureComponent {
    constructor(props) {
        super(props);

        this.onModalEntering = this.onModalEntering.bind(this);
        this.onModalExit = this.onModalExit.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.selectAddressesIndex = this.selectAddressesIndex.bind(this);
    }

    handleAdd(addresses) {
        if(addresses) {
            addresses.unshift({
                [Address.FIELD_ADDR]: '',
                [Address.FIELD_LATITUDE]: null,
                [Address.FIELD_LONGITUDE]: null,
                [Address.FIELD_FIAS_ID]: null,
                [Address.FIELD_COUNTRY_ISO]: null,
                [Address.FIELD_REGION_ISO]: null,
                [Address.FIELD_CONTRACTOR]: this.props.contractor.id
            });
            this.props.store.dispatch(setValue('addresses', [...addresses]));
            this.selectAddressesIndex(0);
        } else {
            this.props.setToastObjAC({show: true, textHeader: "Заполните пустой адрес!", delay: 3000})
        }
    }

    selectAddressesIndex(index) {
        this.setState({
            addresses: this.props.addresses[index],
            currentIndex: index,
            showModal: true
        });
    }

    async onModalEntering() {
        const data = await contractorService.read(this.props.contractor.id)
        this.handleAdd(data.addresses)
    }
    async componentDidMount() {
        await this.onModalEntering()
    }

    onModalExit() {
        addressService.clearCache(this.props.contractor.id);
    }

    handleClose() {
        this.setState({showModal: false})
    }

    formatFieldName(field) {
        return `${this.getFieldNamePrefix()}.${field}`;
    }

    getFieldNamePrefix() {
        return `addresses.${0}`;
    }

//-------------------------------------------------------------------------------
    render() {
        return (
            <Modal
                onEntering={this.onModalEntering}
                onExited={this.onModalExit}
                size="lg"
                show={this.props.show}
                onHide={this.props.onHide}>
                <Modal.Body>
                    <Address type={Address.TYPE_ADDRESS}
                             addressModal={true}
                             reRender={() => this.forceUpdate()}
                             handleClose={() => this.props.onHide()}
                             currentIndex={0}
                             isQuoteAddress={true}
                             addAddRouteIndex={this.props.addAddRouteIndex}
                             onChangeRoutePoint={this.props.onChangeRoutePoint}
                             fieldNameFormatter={this.formatFieldName.bind(this)}
                             addresses={this.props.addresses} {...this.props} store={this.props.store}/>
                </Modal.Body>
            </Modal>);
    }
//-------------------------------------------------------------------------
}
export default connect(mapStateToProps)(AddressModal)
