import React, {PureComponent} from "react";
import {AuctionBidInfo} from "../AuctionBidInfo/AuctionBidInfo";
import {classNames} from "../../../helpers/classNames";
import cls from "./AuctionFirstPrice.module.css"

export class AuctionFirstPrice extends PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            price,
            className
        } = this.props;

        return (
            <AuctionBidInfo
                className={classNames(cls.wrapper, {}, [className])}
                textComponent={
                    <span className={cls.text}>Начальная цена</span>
                }
                priceComponent={
                    <div className={cls.price}>
                        <span className={cls.sign}>₽</span>

                        <span>
                            {price}
                        </span>
                    </div>
                }
            />
        )
    }
}