import { connect } from 'react-redux';
import { Form, Row, Col } from "react-bootstrap";

import BaseForm from '../../form/BaseForm';
import FormGroup from '../../form/FormGroup';
import { securityService, ADMIN, HEAD_OF_DEPARTMENT, REGIONAL_DIRECTOR } from "../../../services/SecurityService";
import contractorService from "../../../services/ContractorService";
import ReadOnlyContainer from "../../ReadOnlyContainer";
import { userService } from "../../../services/UserService";

function mapStateToProps(state) {
	return {
		errors: state.errors,
		model: state.model,
	};
}

class Settings extends BaseForm {	
	constructor(props) {
		super(props);
	}

	handleSubmit(e) {
		e.preventDefault();
		this.submit(() => contractorService.saveTradingSettings(this.props.model)
            		.then(() => this.setSending(false)));
	}

	load() {
		const id = this.props.user?.id;
		if (id) {
			userService.read(id).then(data => {
				this.setState({ userData: data });
			});
		}
	}

	authorizedToUpdate() {
		const user = this.props.user;
		if (securityService.hasRole(user, ADMIN)) {
			return true;
		}
		if (securityService.anyRoles(user, HEAD_OF_DEPARTMENT, REGIONAL_DIRECTOR)) {
			return !this.state.userData?.departments?.length;
		}
		return false;
	}

	render() {
		return (
			<Form>
				<ReadOnlyContainer readOnly={!this.authorizedToUpdate()}>
					{securityService.hasRole(this.props.user, ADMIN) &&
						<div>
							<Row>
								<Col lg={4} md={12}>
									<FormGroup title="Адрес площадки" name="platformUrl" store={this.props.store} />
								</Col>
								<Col lg={4} md={12}>
									<FormGroup title="Логин для площадки" name="platformLogin" store={this.props.store} />
								</Col>
								<Col lg={4} md={12}>
									<FormGroup title="Пароль для площадки" name="platformPassword" store={this.props.store} />
								</Col>
							</Row>
							<Row>
								<Col lg={4} md={12}>
									<FormGroup title="Дополнительный параметр (Контрольное слово)" name="additionalParameter" store={this.props.store} />
								</Col>
								<Col lg={4} md={12}>
								</Col>
								<Col lg={4} md={12}>
								</Col>
							</Row>
						</div>
					}
				</ReadOnlyContainer>
				{this.renderSaveCancelButtons()}
			</Form>
		);
	}
}

export default connect(mapStateToProps)(Settings);
