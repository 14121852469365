import {initialState} from "./socketInitialState";
import * as actionTypes from "./socketActionTypes";

const socketReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_SOCKET:
            return {
                ...state,
                socket: action.payload
            };
        case actionTypes.SET_ERRORS:
            return {
                ...state,
                errors: action.payload
            };
        default:
            return state;
    }
}

export default socketReducer;