import React from "react";
import {connect} from 'react-redux';
import {Button, Col, Collapse, Container, Row} from "react-bootstrap";
import DataTable from '../table/DataTable';
import './reportStyle.css';
import Util from "../../Util";
import reportService from "../../services/ReportService";
import BootstrapSwitchButton from "bootstrap-switch-button-react";

class MonthReport extends React.PureComponent {
    constructor(props) {

        super(props);

        const now = new Date(),
            dt = new Date(now.getFullYear(), now.getMonth(), 1),
            offset = dt.getTimezoneOffset();
        dt.setTime(dt.getTime() - offset * 60000);

        this.state = {
            editCell: {},
            tableHeight: this.getTableHeight(),
            dt,
            toggle: false,
            open: false,
            toggleCheck: false,
        };

        this.changeToggle = this.changeToggle.bind(this);
    }

    static COLUMS = [
        {title: "План", field: "plan"},
        {title: "факт", field: "fact"},
        {title: "Тренд", field: "March"},
        {title: "Предыдущий месяц", field: "previousMonthValue"},
        {title: "Отклонение от плана", field: "deviationPlan"},
        {title: "Относительное отклонение", field: "deviationRelative"},
    ];

    static get REQUEST_STATUS_APPROVED() {
        return 2
    };

    static get REQUEST_STATUS_UNLOADED() {
        return 8
    };

    static get REQUEST_STATUS_LOADED() {
        return 9
    };

    static get REQUEST_STATUS_ON_LOADING() {
        return 10
    };

    static get REQUEST_STATUS_ON_WAY() {
        return 11
    };

    static get REQUEST_STATUS_ON_UNLOADING() {
        return 12
    };

    getQuoteStatusIdList() {
        return this.state.toggleCheck
            ? [MonthReport.REQUEST_STATUS_APPROVED,
                MonthReport.REQUEST_STATUS_UNLOADED,
                MonthReport.REQUEST_STATUS_LOADED,
                MonthReport.REQUEST_STATUS_ON_LOADING,
                MonthReport.REQUEST_STATUS_ON_WAY,
                MonthReport.REQUEST_STATUS_ON_UNLOADING]
            : [MonthReport.REQUEST_STATUS_UNLOADED];
    }

    static HEIGHT = 1700;

    changeToggle(toggle) {
        this.setState({toggle});
    }

    getTableHeight() {
        return window.innerHeight + this.HEIGHT;
    }

    componentWillUnmount() {
        this.addRemoveResizeListener(false);
    }

    componentWillMount() {
        this.addRemoveResizeListener(true);
    }

    addRemoveResizeListener(listen) {
        const addRemove = listen ? document.body.addEventListener : document.body.removeEventListener;
        addRemove('resize', this.resizeListener.bind(this), true);
    }

    resizeListener() {
        this.setState({tableHeight: this.getTableHeight()});
    }

    async componentDidMount() {
        const result = await this.fetch();
        this.setState({data: result.data});
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.toggleCheck !== this.state.toggleCheck) {
            const result = await this.fetch();
            this.setState({data: result.data});
            this.changeToggle(false)
        }
    }

    async fetch(dt) {
        const quoteStatusIdList = this.getQuoteStatusIdList();
        return await reportService.sspYearMonth(Util.formatIsoDate(dt || this.state.dt), quoteStatusIdList).catch(() => {
            dt.setMonth(dt.getMonth() - 1)
            return reportService.sspYearMonth(Util.formatIsoDate(dt || this.state.dt), quoteStatusIdList);
        });
    }

    render() {
        return (
            <div className="tableFixHead">
                <Container fluid>
                    {this.renderTitle()}
                </Container>
                <table className="tableSsp">
                    <thead className={"thead"}>
                    {this.renderBodyTitle()}
                    </thead>
                    <tbody>
                    {this.renderBody()}
                    </tbody>
                </table>
            </div>);
    }

    renderBodyTitle() {
        return (
            <tr>
                <td className={"tdTitle tdAll"}>
                    <span className={"font-title"}>Показатели
                    </span>
                </td>
                {MonthReport.COLUMS.map(s =>
                    <td className={"tdCell tdAll"}>
                        <span className={"font-p"}>{s.title}</span>
                    </td>
                )}
            </tr>
        );
    }

    renderBody() {
        return (
            <>
                {this.state.data && this.state.data.map((row) => (
                    <Rows key={row.counter} row={row.counter} departments={row.departments}
                          changeToggle={this.changeToggle} datee={this.state.dt}/>
                ))}
            </>
        );
    }

    formatYear() {
        const date = Util.formatIsoDate(this.state.dt), parts = date.split('-');
        return parts[0] + '-' + parts[1];
    }

    async changeYear(e, step) {
        e.preventDefault();
        const dt = this.state.dt;
        dt.setMonth(dt.getMonth() + step);
        const date = new Date(dt.getTime()),
            result = await this.fetch(date);

        this.setState({
            dt: date,
            data: result.data
        });
    }

    renderTitle() {
        return (
            <Row>
                <Col className="mx-auto my-4 text-left" sm>
                    <span>Расчет по заявкам в процессе </span>
                    <BootstrapSwitchButton
                        toggleCheck={this.state.toggleCheck}
                        onstyle="outline-primary"
                        offstyle="outline-secondary"
                        size="sm"
                        onChange={value => this.setState({toggleCheck: value})}
                    />
                </Col>
                <Col className="mx-auto my-4 text-center" sm>
                    <a href="#" onClick={e => this.changeYear(e, -1)}>
                        <i className="fa fa-chevron-left" aria-hidden="true"></i>
                    </a>
                    <span className={"font-title"}>{"  " + " Годовые показатели ССП по месяцам " + this.formatYear() + "  "}</span>
                    <a href="#" onClick={e => this.changeYear(e, 1)}>
                        <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </a>
                </Col>
                <Col sm></Col>
            </Row>
        );
    }
}

class Rows extends React.PureComponent {
    constructor(props) {

        super(props);

        this.state = {
            open: false,
            isEdit: false,
            key: null,
            editCell: {},
            result: 'ИТОГО'
        };

        this.columns = [
            {title: "Подразделения", field: "Department", width: 90},
        ];

        this.editCellRef = React.createRef();
    }

    render() {
        const {row} = this.props;
        const {departments} = this.props;
        return (
            <>
                <tr className="rowColor">
                    <td className="tdTitle tdAll">
                        <Button
                            className={"button"}
                            size="sm"
                            onClick={() => this.setState({open: !this.state.open})}>
                            {this.state.open ? <i className="fa fa-chevron-up arrow-color"></i> :
                                <i className="fa fa-chevron-down arrow-color"></i>}
                        </Button>
                        <span className={"font-p"}> {row}</span>
                    </td>
                    {this.getYearAllDepartments(departments[departments.length - 1])}
                </tr>
                <>
                    {this.getDepartmentRow(departments)}
                </>
            </>
        );
    }

    getYearAllDepartments(row) {
        return (
            row.sspSubPeriodYearOfDepartments.map(row => (
                <>
                    <td className={"tdCell tdAll"}><span className={"font-p"}>{row.plan.toLocaleString('ru')}</span>
                    </td>
                    <td className={"tdCell tdAll"}><span className={"font-p"}>{row.fact.toLocaleString('ru')}</span>
                    </td>
                    <td className={"tdCell tdAll"}><span className={"font-p"}>{row.trend.toLocaleString('ru')}</span>
                    </td>
                    <td className={"tdCell tdAll"}><span
                        className={"font-p"}>{row.previousMonthValue.toLocaleString('ru')}</span></td>
                    {this.getColor(row.deviationPlan)}
                    {this.getColor(row.deviationRelative)}
                </>
            ))
        )
    }

    getDepartmentRow(departments) {
        return (
            departments.map((rows) => (
                <Collapse in={this.state.open}>
                    <tr className="colorCellWhite">
                        {
                            rows.department !== this.state.result &&
                            <td className={"tdTitle tdAll"}>
                            <span className={"font-p"}> {rows.department}</span>
                            </td>
                        }
                        {this.getResultCounterOfMonth(rows)}
                    </tr>
                </Collapse>
            ))
        )
    }

    getResultCounterOfMonth(rows) {
        return rows.department !== this.state.result &&
            rows.sspSubPeriodYearOfDepartments.map((row) => (
                <>
                    <td className={"tdCell tdAll"}>
                        <span className={"font-p"}>{row.plan.toLocaleString('ru')}</span>
                    </td>
                    <td className={"tdCell tdAll"}>
                        <span className={"font-p"}>{row.fact.toLocaleString('ru')}</span>
                    </td>
                    <td className={"tdCell tdAll"}>
                        <span className={"font-p"}>{row.trend.toLocaleString('ru')}</span>
                    </td>
                    <td className={"tdCell tdAll"}>
                        <span className={"font-p"}>{row.previousMonthValue.toLocaleString('ru')}</span>
                    </td>
                    {this.getColor(row.deviationPlan)}
                    {this.getColor(row.deviationRelative)}

                </>
            ))
    }

    getBackgroundColor(row) {
        if (row > 0) {
            return '#ceffbc';
        }
        if (row < 0) {
            return '#FF9999';
        }
        return 'white';
    }

    getColor(row) {
        return (
            <td className={"tdCell tdAll"} style={{backgroundColor: this.getBackgroundColor(row)}}><span
                className={"font-p"}>{row.toLocaleString('ru')}</span></td>
        )
    }

}

export default connect(DataTable.mapStateToProps)(MonthReport);