import DictionarySelect from "./DictionarySelect";
import optionsService from '../../services/OptionsService';
import requestStatusService from '../../services/RequestStatusService';

class RequestStatusSelect extends DictionarySelect {

	constructor(props) {
		super(props);
		this.optionsType = "REQUEST_STATUS";
	}

	fetchOptionByValue() {
		return optionsService.loadByValue(this.optionsType, this.props.value);
	}
	
	async fetchOptions() {
		const model = this.props.model;
		return await requestStatusService.load(model?.id);
	}
}

export default RequestStatusSelect;
