import React from "react";

import { Button, Tab, Tabs } from "react-bootstrap";
import { AuditTable } from '../AuditTable/AuditTable';

import FormValidator from "../../validators/FormValidator";
import { setValue, setErrors } from '../../actions/form';
import StoreWrapper from "./StoreWrapper";
import DadataService from "../../services/DadataService";

class BaseForm extends React.PureComponent {

	constructor(props) {
		super(props);

		this.state = {
			loaded: false,
			validated: false,
			sending: false
		};

		this.onCancel = this.onCancel.bind(this);
		this.validator = this.props.parentValidator || new FormValidator();
	}

	onChange(name, value) {
		this.props.store.dispatch(setValue(name, value));
	}

	componentDidUpdate(prevProps) {
		if (prevProps.model !== this.props.model) {
			// TODO: validate only changed field(s) using validateField(name)
			this.state.validated && this.validate();
		}
	}

	validate() {
		return new Promise(resolve => {
			this.validator.validate().then(valid => {
				this.props.store.dispatch(setErrors(this.validator.results));
				this.setState({ validated: true }, () => resolve(valid));
			});
		});
	}

	validateField(name) {
		return new Promise(resolve => {
			this.validator.validateField(name).then(valid => {
				this.props.store.dispatch(setErrors(this.validator.results));
				resolve(valid);
			});
		});
	}

	onCancel(e) {
		e.preventDefault();
		this.props.history.goBack();
	}

	submit(call) {
		if (this.state.sending !== true) {
			const {setToastObjAC, notShowToast} = this.props;
			this.setSending(true);
			this.validate().then(valid => {
				if (valid) {
					call();
				} else if (!notShowToast) {
					const data = {show: true, textHeader: "Ошибка сохранения!", textBody: "Обязательные поля заполнены некорректно либо пропущены",  delay: 3000, className: "bg-danger"};
					setToastObjAC(data)
				}
				this.setSending(false);
			})
		}
	}

	setSending(sending) {
		this.setState({ sending: sending });
	}

	useValidatorFor(validator, ...fields) {
		fields.forEach(field =>
			this.validator.addValidator(field, () => validator(StoreWrapper.getValue(this.props.store.getState().model, field))));
	}

	useDirectModelValidatorFor(validator, ...fields) {
		fields.forEach(field =>
			this.validator.addValidator(field, () => validator(this.props.store.getState().model))
		);
	}

	load() {
		// override in child control
	}

	renderForm() {
		// override in child control
	}

	getTabs() {
		// override in child control
	}

	getFormTabTitle() {
		// override in child control
	}

	componentDidMount() {
		this.load();
	}

	getErrors(field) {
		return this.props.store.getState().errors ? this.props.store.getState().errors[field] : [];
	}

	renderSaveCancelButtons() {
		return (
			<div className="text-center">
				{this.renderSaveButton()}
				&nbsp;
				{this.renderCancelButton()}
			</div>
		);
	}

	renderAutoFillInSaveCancelButtons() {
		return (
			<div className="text-center">
				{this.renderAutoFillInButton()}
				&nbsp;
				{this.renderSaveButton()}
				&nbsp;
				{this.renderCancelButton()}
			</div>
		);
	}

	async handleAutoFillIn(e) {
		e.preventDefault();
		const isValid = await this.validateAutoFillin();
		if (isValid) {
			this.setSending(true);
			const daDataResponse = await this.getFillInData();
			for (let field of Object.keys(daDataResponse)) {
				this.onChange(field, daDataResponse[field]);
			}
			this.setSending(false);
		}
	}

	// override int descendants, return object with model's fields to fill
	async getFillInData() {
		return {};
	}

	/**
	 * Validate fields for the autofill in function
	 */
	async validateAutoFillin() {
		return false;
	}

	renderCancelButton(title) {
		return (
			this.props.history?.length > 1 && <Button onClick={this.onCancel} size="sm" variant="secondary" className="pull-right" disabled={this.state.sending}>
				{title || "Отмена"}
				</Button>
		);
	}

	renderSaveButton(title) {
		return (
			<Button onClick={this.handleSubmit.bind(this)} size="sm" variant="primary" className="pull-right" disabled={this.state.sending}>
				{this.state.sending && (<span className="spinner-border spinner-border-sm"></span>)}
				<span>{title || "Сохранить"}</span>
			</Button>
		);
	}

	renderAutoFillInButton() {
		return (
			<Button onClick={this.handleAutoFillIn.bind(this)} size="sm" variant="primary" className="pull-right" disabled={this.state.sending}>
				{this.state.sending && (<span className="spinner-border spinner-border-sm"></span>)}
				<span>Заполнить</span>
			</Button>
		);
	}

	renderClearButton() {
		return (
			<Button onClick={this.handleClear.bind(this)} size="sm" variant="outline-primary" className="pull-right" disabled={this.state.sending}>
				{this.state.sending && (<span className="spinner-border spinner-border-sm"></span>)}
				<span>Очистить</span>
			</Button>
		);
	}

	getAuditTab(entityId, source, server) {
		return (
			<Tab key="log" eventKey="log" title="История изменений">
				{this.state.activeTab === "log" && <AuditTable sourceId={entityId} source={source} server={server} />}
			</Tab>
		);
	}

	render() {
		const tabs = this.getTabs();
		return (
			<div className={`form-container ${this.state.sending && "form-disabled"}`}>
				<div className="form-disabled-overlay"></div>
				<div className="form-contents">
					{!tabs && this.renderForm()}
					{tabs && (
						<Tabs defaultActiveKey="form" activeKey={this.state.activeTab} onSelect={key => this.setState({ activeTab: key })}>
							<Tab key="form" eventKey="form" title={this.getFormTabTitle()}>
								{this.renderForm()}
							</Tab>
							{tabs}
						</Tabs>
					)}
				</div>
			</div>
		);
	}

	getBackUrl(defaultUrl) {
		return new URLSearchParams(this.props.location.search).get('backUrl') || (defaultUrl || "/quotes");
	}

	redirectToBackOrDefaultUrl(defaultUrl) {
		this.props.history.push(this.getBackUrl(defaultUrl));
	}
	
}
export default BaseForm;