import BaseForm from "../../form/BaseForm";
import {Button, Col, Form, Spinner, Table} from "react-bootstrap";
import {tr} from "date-fns/locale";
import motivationService from "../../../services/MotivationService";
import {securityService} from "../../../services/SecurityService";

class PaidQuotes extends BaseForm {
    constructor(props) {
        super(props);

        this.state = {
            quotes: null,
            sending: true
        };
    }

    componentDidMount() {
        const data = {
            dt: this.props.dt,
            userId: this.props.userId,
            departmentId: this.props.departmentId,
            isHead: this.props.isHead
        };

        motivationService.post("paidQuotes", data)
            .then((response) => {
                this.setState({quotes: response});
                this.setSending(false);
            });
    }

    FIELDS = [
        { key: "id", value: "id" },
        { key: "number", value: "Номер заявки" },
        { key: "departmentName", value: "Подразделение" },
        { key: "logisticManagerDepFio", value: "Менеджер" },
        { key: "createdAt", value: "Время создания заявки"},
        { key: "contractorFullName",  value: "Заказчик" },
        { key: "paymentDt",  value: "Дата оплаты" },
        { key: "margin",  value: "Маржа (грязная)" },
        { key: "bonusCoefficient",  value: "Бонус" },
        { key: "revenuePlanCoefficient",  value: "Коэффициент выполнения плана по выручке" },
        { key: "marginPlanCoefficient",  value: "Коэффициент выполнения плана по чистой марже" }
    ];

    renderHeader() {
        return (
            <thead>
            <tr>
                {this.FIELDS.map(col =>
                    <td key={col.key}>
                        <b>{col.value}</b>
                    </td>
                )}
            </tr>
            </thead>
        );
    }

    getValueByFieldKey(col, r) {
        if (col.key === "createdAt") {
            return r[col.key].substring(0, 10);
        }

        return r[col.key]
    }

    render() {
        return (
            <Form>
                <Table className="wages-table" striped bordered size="sm">
                    {this.renderHeader()}
                    <tbody>
                    {this.state.quotes?.map(r =>
                        <tr key={r.id}>
                            {this.FIELDS.map(col =>
                                <td>
                                    {this.getValueByFieldKey(col, r)}
                                </td>
                            )}
                        </tr>
                    )}
                    </tbody>
                </Table>
                {this.state.sending &&
                    <div style={{textAlign: "center", padding: "10px"}}>
                        <p>Загрузка данных</p>
                        <Spinner animation="border" />
                    </div>}
                {(securityService.isAdmin(securityService.getUser()) || securityService.isSupervisor(this.state.user)) &&
                    <Col style={{padding: "5px", textAlign: "center"}}>
                        <Button size="sm" variant="success" id="dropdown-basic" onClick={() => this.getPaidQuotes(this.props.userId)}
                                disabled={this.state.sending}>
                            {this.state.sending && (<span className="spinner-border spinner-border-sm"></span>)}
                            <span> Скачать заявки в формате xlsx</span>
                        </Button>
                    </Col>
                }
            </Form>
        );
    }

    getPaidQuotes(userId) {
        this.state.quotes.map(q => {
            delete q.logisticManagerDepId;
        });

        motivationService.downloadFile(
            userId + "-QUOTES-" + this.props.dt.substring(0, 7) + ".xlsx",
            "xlsx",
            {headers: this.FIELDS, content: this.state.quotes}
        );
    }
}

export default PaidQuotes;