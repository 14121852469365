import axios from 'axios';
import authHeader, {HEADER_AUTHORIZATION} from './services/authHeader';
import {logout} from "./actions/auth";
import {processApiException} from './actions/exceptionsActions';
import store from "./store";
import {env} from "./env";

const transportAxios = axios.create({
    baseURL: env.REACT_APP_API_URL ?? "http://localhost"
});

const withAuthHeader = (req, axiosInstance) => {
    if (req && (!req.headers.common[HEADER_AUTHORIZATION] && !req.headers[HEADER_AUTHORIZATION])) {
        updateDefaultAuthHeader();
        req.headers[HEADER_AUTHORIZATION] = axiosInstance.defaults.headers.common[HEADER_AUTHORIZATION];
    }
    return req;
}

const configureAxiosRequest = (axiosInstance) => {
    axiosInstance.interceptors.request.use(
        req => withAuthHeader(req, axiosInstance),
        error => {
            alert('Error happened: ' + error);
        }
    );
}

configureAxiosRequest(transportAxios);

const configureAxiosResponse = (axiosInstance) => {
    axiosInstance.interceptors.response.use(response => {
        return response;
    }, error => {
        const status = error.response?.status;
        if ([401].includes(status)) {
            store.dispatch(logout());
        } else if (axios.isCancel(error)) {
            // canceled by user
        } else if (isMicroserviceConnectError(error.response)) {
            // an error occurred connecting to the microservice
        } else {
            const apiException = error.response ? {...error.response.data} : {message: error ? error.toString() : "Unknown Error"};
            store.dispatch(processApiException(apiException));
        }
        return Promise.reject(error);
    });
}

const isMicroserviceConnectError = (error) => {
    return error?.config.url?.indexOf("api/transport") < 0 && error?.data.sourceMessage?.indexOf("java.net.ConnectException") >= 0;
}

configureAxiosResponse(transportAxios);

export {transportAxios as axios};
export const updateDefaultAuthHeader = () => {
    transportAxios.defaults.headers.common[HEADER_AUTHORIZATION] = authHeader()[HEADER_AUTHORIZATION];
}

export const createCancelToken = () => axios.CancelToken.source()