import React from "react";
import { Row, Card, Col } from "react-bootstrap";
import importService from "../../../services/ExportImportService";

class Operations extends React.PureComponent {

	static IMPORT = "IMPORT"; // import entities for Smart Logistic
	static EXPORT = "EXPORT"; // export entities for Smart Logistic
	static EXPORT_XLS = "EXPORT_XLS"; // export entities from DataTable as XLS file

	constructor(props) {
		super(props);
		this.handleDownload = this.handleDownload.bind(this);
	}

	async handleDownload(fileName) {
		if (this.props.direction !== null) {
			await importService.downloadXLSFile(this.props.direction.toLowerCase(), fileName);
		}
	}

	getResultClassName(operation) {
		switch (operation.operationResult) {
			case "SUCCESS":
				return "";
			case "FAILED":
				return "text-danger";
			default:
				return "text-muted";
		}
	}

	getDownloadLinks() {
		if (!this.props.latestOperations) {
			return ("");
		}
		return this.props.latestOperations.filter(op => op.direction === this.props.direction).map((operation) => {
			return (
				<div key={operation.id}>
					<span> &#8226; </span>
					{
						operation.operationResult === "SUCCESS" && operation.fileName
							? <a href="#" onClick={() => this.handleDownload(operation.fileName)}>{operation.fileName}</a>
							: <span className={this.getResultClassName(operation)}>{`${operation.fileName ? operation.fileName : `${operation.completedAt} - отсутствует файл`} ${operation.description ? operation.description : ""}`}</span>
					}
				</div>
			);
		})
	}

	render() {
		if (this.props.linksOnly === true) {
			return (
				<React.Fragment>
					{this.getDownloadLinks()}
				</React.Fragment>
			);
		}
		return (
			<Row className="import-card">
				<Col>
					<Card className="import-card">
						<Card.Body>
							<Row className="mb-2">
								<Col>
									<strong>{this.props.title}</strong>
								</Col>
							</Row>
							<Row>
								<Col>
									{this.getDownloadLinks()}
								</Col>
							</Row>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		);
	}

}
export default Operations;