import React from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import DataTable from '../../table/DataTable';
import dictionaryService from '../../../services/AbstractDictionaryService';

class Dictionaries extends React.PureComponent {
	constructor(props) {
		super(props);
	}

	fetch(params) {
		return dictionaryService.list(params, this.props.requestType);
	}

	openAddForm() {
		this.props.history.push(this.props.pathname);
	}

	openEditForm(row) {
		this.props.history.push({ pathname: this.props.pathname, state: { id: row.id } });
	}
	
	render() {
		return (
			<DataTable {...this.props}
				title = {this.props.title || "Все записи"}
				sort = {{field: 'value', dir: 'asc'}}
				openAddForm = {this.openAddForm.bind(this)}
				openEditForm = {this.openEditForm.bind(this)}
				fetch = {this.fetch.bind(this)}
				columns = {[
					{ title: "#", field: "id", className: "id", filter: DataTable.STRING_FILTER },
					{ title: "Название", field: "value", filter: DataTable.STRING_FILTER }
				]}
			/>
		);
	}
}
export default withRouter(connect(DataTable.mapStateToProps)(Dictionaries));