import BaseSelect from "./BaseSelect";
import optionsService from '../../services/OptionsService';
import contractorService from "../../services/ContractorService";
import {securityService} from "../../services/SecurityService";

class ContractorSelect extends BaseSelect {
	constructor(props) {
		super(props);
	}

	fetchOptions() {
		const user = securityService.getUser();
		return contractorService.optionsByUser(user.id);
	}
	
	componentDidUpdate(prevProps) {
		if (prevProps.userId !== this.props.userId) {
			this.loaded = false;
			this.onChange(optionsService.createEmptyOption());
			this.setState({ options: [] });
		}
	}
}

export default ContractorSelect;
