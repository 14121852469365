import {axios} from '../axios';

class OrganizationContractorHistoryService {
    BASE_PATH = "/api/v1/organization-contractor-history";

    async getOrganizationContractorHistory(organizationId) {
        const response = await axios.get(`${this.BASE_PATH}/${organizationId}`);
        return response.data;
    }

    async addOrganizationContractorHistory(data) {
        const response = await axios.post(`${this.BASE_PATH}`, data);
        return response.data;
    }

    async updateOrganizationContractorHistory(data) {
        const response = await axios.put(`${this.BASE_PATH}`, data);
        return response.data;
    }

    async deleteOrganizationContractorHistory(id) {
        const response = await axios.delete(`${this.BASE_PATH}/${id}`);
        return response.data;
    }

    async getLastOrganizationsContractor(organizationId) {
        const response = await axios.get(`${this.BASE_PATH}/${organizationId}/last`);
        return response.data;
    }

    async isCarrieOrgForwarderMatchesUser(carrier, forwarder) {
        const response = await axios.post(`${this.BASE_PATH}/carrier/forwarder/check`, {carrier, forwarder});
        return response.data;
    }
}

export default new OrganizationContractorHistoryService();