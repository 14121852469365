import React, {PureComponent} from "react";

class FunnelChart extends PureComponent {
    constructor(props) {
        super(props);
    }

    /**
     *  Данные для графика добавляются в виде списка объектов с полями
     *  [  ...{ label, value } ]
     * */
    DATA = this.props.data;
    WIDTH = this.props.width ? this.props.width : "600";
    HEIGHT = this.props.height ? this.props.height : "400";
    ID = "chart" + Math.random().toString(36);

    componentDidMount() {
        if (this.DATA) {
            this.chart();
        }

        this.setState({ data: this.props.data });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.DATA && this.props.data !== prevProps.data) {
            this.DATA = this.props.data;

            this.chart();
        }
    }

    chart() {
        const data = this.DATA;

        const colors = [
            { light: "rgba(190,185,219,0.7)", dark: "rgba(190,185,219,0.9)"},
            { light: "rgba(253,204,229,0.7)", dark: "rgba(253,204,229,0.9)"},
            { light: "rgba(139,211,199,0.7)", dark: "rgba(139,211,199,0.9)"}
        ];

        // Клонирование элемента, чтобы избавиться от eventListener
        const element = document.getElementById(this.ID);
        const canvas = element.cloneNode(true);
        element.parentNode.replaceChild(canvas, element);

        const context = canvas.getContext("2d");
        context.clearRect(0, 0, canvas.width, canvas.height);

        const width = canvas.width;
        const height = canvas.height;
        const scaleY = height / data.length;
        const scaleX = width / data[0].value;
        const middle = width / 2;

        context.font = "18px arial";
        context.textAlign = "center";

        let blocks = [];
        for (let i = 0; i < data.length; i++) {
            const block = {
                ax: middle - ((data[i].value * scaleX) / 2),
                bx: middle + ((data[i].value * scaleX) / 2),
                cx: middle + (((data[i + 1] ? data[i + 1].value : 0) * scaleX) / 2),
                dx: middle - (((data[i + 1] ? data[i + 1].value : 0) * scaleX) / 2),
                yTop: scaleY * i,
                yBottom: scaleY * (i + 1),
                color: colors[i % 3],
                label: data[i].label,
                isHover: false,
                value: data[i].value
            };

            blocks.push(block);
        }

        drawChart(blocks);
        canvas.addEventListener("mousemove", showInfo);

        function setText(x, y, text, color) {
            context.fillStyle = color;
            context.fillText(text, x, y);
        }

        function drawTrapezoid(block, mouseX, mouseY) {
            context.beginPath();
            context.moveTo(block.ax, block.yTop);
            context.lineTo(block.bx, block.yTop);
            context.lineTo(block.cx, block.yBottom);
            context.lineTo(block.dx, block.yBottom);
            context.fillStyle = block.isHover ? block.color.dark : block.color.light;
            context.fill();
            context.closePath();

            setText(middle, block.yBottom - (scaleY / 2), block.label, "black");

            if (block.isHover) {
                drawLabel(mouseX, mouseY, block.value.toLocaleString())
            }
        }

        function drawLabel(x, y, label) {
            context.fillStyle = "black";
            context.fillRect(x + 10, y - 31, 81, 30);
            context.fillStyle = "white";
            context.fillRect(x + 11, y - 30, 79, 28);
            setText(x + 50, y - 10, label, "black");
        }

        function drawChart(blocks, mouseX, mouseY) {
            blocks?.forEach(block =>
                drawTrapezoid(block, mouseX, mouseY));
        }

        function getMousePosition(event) {
            return {
                x: event?.clientX - canvas.getBoundingClientRect().left,
                y: event?.clientY - canvas.getBoundingClientRect().top
            };
        }

        function showInfo(event) {
            const mouse = getMousePosition(event);

            for (let i = 0; i < blocks.length; i++) {
                blocks[i].isHover = mouse.y >= blocks[i].yTop && mouse.y <= blocks[i].yBottom
                    && mouse.x >= blocks[i].ax && mouse.x <= blocks[i].bx;
                context.clearRect(0, 0, width, height);
                drawChart(blocks, mouse.x, mouse.y);
            }
        }
    }


    render() {
        if (this.DATA) {
            return <div style={{padding: "15px", width: "fit-content"}}>
                <canvas id={this.ID}
                        width={this.WIDTH}
                        height={this.HEIGHT}
                        className="canvas-chart">
                </canvas>
            </div>
        }

        return <div>Нет данных для графика</div>
    }
}

export default FunnelChart;