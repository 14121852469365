import React from "react";
import {connect} from "react-redux";
import consolidateReport from "../../services/ConsolidateReport";
import Util from "../../Util";
import {Button, Col, Collapse, Container, Dropdown, Modal, Row, Table,} from "react-bootstrap";
import './reportStyle.css';
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import StoreWrapper from "../form/StoreWrapper";
import FormGroup from "../form/FormGroup";
import BaseForm from "../form/BaseForm";
import {
    ADMIN,
    COMMERCIAL,
    HEAD_OF_DEPARTMENT,
    REGIONAL_DIRECTOR,
    securityService
} from "../../services/SecurityService";
import {userService} from "../../services/UserService";
import CommentConsolidateReport from "./CommentConsolidateReport";

function mapStateToProps(state) {
    return {
        errors: state.errors,
        model: state.model
    };
}

class ConsolidateReportCommercial extends StoreWrapper {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <ConsolidateReportCommercialInnerConnected  {...this.props} store={this.store}/>
        );
    }
}

class ConsolidateReportCommercialInner extends BaseForm {
    constructor(props) {

        super(props);
        const now = new Date(),
            dt = new Date(now.getFullYear(), now.getMonth(), now.getDate()),
            offset = dt.getTimezoneOffset();
        dt.setTime(dt.getTime() - offset * 60000);

        this.state = {
            editCell: {},
            tableHeight: this.getTableHeight(),
            toggle: false,
            dt
        };
    }

    static HEIGHT = 1700;

    static get REQUEST_STATUS_APPROVED() {
        return 2
    };

    static get REQUEST_STATUS_UNLOADED() {
        return 8
    };

    static get REQUEST_STATUS_LOADED() {
        return 9
    };

    static get REQUEST_STATUS_ON_LOADING() {
        return 10
    };

    static get REQUEST_STATUS_ON_WAY() {
        return 11
    };

    static get REQUEST_STATUS_ON_UNLOADING() {
        return 12
    };

    getQuoteStatusIdList() {
        return this.state.toggle
            ? [ConsolidateReportCommercialInner.REQUEST_STATUS_APPROVED,
                ConsolidateReportCommercialInner.REQUEST_STATUS_UNLOADED,
                ConsolidateReportCommercialInner.REQUEST_STATUS_LOADED,
                ConsolidateReportCommercialInner.REQUEST_STATUS_ON_LOADING,
                ConsolidateReportCommercialInner.REQUEST_STATUS_ON_WAY,
                ConsolidateReportCommercialInner.REQUEST_STATUS_ON_UNLOADING]
            : [ConsolidateReportCommercialInner.REQUEST_STATUS_UNLOADED];
    }

    getTableHeight() {
        return window.innerHeight + this.HEIGHT;
    }

    componentWillUnmount() {
        this.addRemoveResizeListener(false);
    }

    componentWillMount() {
        this.addRemoveResizeListener(true);
    }

    addRemoveResizeListener(listen) {
        const addRemove = listen ? document.body.addEventListener : document.body.removeEventListener;
        addRemove('resize', this.resizeListener.bind(this), true);
    }

    resizeListener() {
        this.setState({tableHeight: this.getTableHeight()});
    }

    async componentDidMount() {
        const result = await this.fetch();
        const countDepUser = await this.fetchUser(securityService.getUser().id);
        this.setState({
            data: result.data,
            revenuePlan: result.revenuePlan,
            marginPlan: result.marginPlan,
            revenueFact: result.revenueFact,
            marginFact: result.marginFact,
            revenueTrend: result.revenueTrend,
            marginTrend: result.marginTrend,
            deviationRevenue: result.deviationRevenue,
            deviationMargin: result.deviationMargin,
            counterDepartment: countDepUser.departments.length
        });
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.toggle !== this.state.toggle || prevState.data === this.state.data) {
            const result = await this.fetch();
            const countDepUser = await this.fetchUser(securityService.getUser().id);
            this.setState({
                data: result.data,
                revenuePlan: result.revenuePlan,
                marginPlan: result.marginPlan,
                revenueFact: result.revenueFact,
                marginFact: result.marginFact,
                revenueTrend: result.revenueTrend,
                marginTrend: result.marginTrend,
                deviationRevenue: result.deviationRevenue,
                deviationMargin: result.deviationMargin,
                counterDepartment: countDepUser.departments.length
            });
        }
    }

    async fetch(dt) {
        const quoteStatusIdList = this.getQuoteStatusIdList();
        return await consolidateReport.reportCommercial(Util.formatIsoDate(dt || this.state.dt), quoteStatusIdList, this.props.model.users,
            this.props.model.usersCommercial,
            this.props.model.department).catch(() => {
            dt.setMonth(dt.getMonth() - 1)
            return consolidateReport.reportCommercial(Util.formatIsoDate(dt || this.state.dt), quoteStatusIdList, this.props.model.users,
                this.props.model.usersCommercial,
                this.props.model.department);
        });
    }

    async changeYear(e, step) {
        e.preventDefault();
        const dt = this.state.dt;
        dt.setMonth(dt.getMonth() + step);
        const date = new Date(dt.getTime()),
            result = await this.fetch(date);
        const countDepUser = await this.fetchUser(securityService.getUser().id);
        this.setState({
            dt: date,
            data: result.data,
            revenuePlan: result.revenuePlan,
            marginPlan: result.marginPlan,
            revenueFact: result.revenueFact,
            marginFact: result.marginFact,
            revenueTrend: result.revenueTrend,
            marginTrend: result.marginTrend,
            deviationRevenue: result.deviationRevenue,
            deviationMargin: result.deviationMargin,
            counterDepartment: countDepUser.departments.length
        });
    }

    formatYear() {
        const date = Util.formatIsoDate(this.state.dt), parts = date.split('-');
        return parts[0] + '-' + parts[1];
    }

    render() {
        return (
            <div className="tableFixHead">
                <Container fluid>
                    {this.renderTitle()}
                </Container>
                <table className="tableSsp">
                    <thead className={"theadKO"}>
                    {this.renderBodyTitle()}
                    </thead>
                    <tbody>
                    {this.renderBody()}
                    </tbody>
                </table>
            </div>
        );
    }

    renderBodyTitle() {
        return (
            <>
                <td colSpan="14" style={{backgroundColor: '#f8f9fc'}}>
                    <Col sm>
                        <Table striped bordered hover className={"tableCon"}>
                            <thead>
                            <td className={"tdCell tdAll"}>
                            <span
                                className={"font-p"}>{'План по выручке: ' + this.calculateTotalRevenuePlan(this.state.data).toLocaleString('ru')}</span>
                            </td>
                            <td className={"tdCell tdAll"}>
                            <span
                                className={"font-p"}>{'План по марже: ' + this.calculateTotalMarginPlan(this.state.data).toLocaleString('ru')}</span>
                            </td>
                            <td className={"tdCell tdAll"}>
                            <span
                                className={"font-p"}>{'Факт по выручке: ' + this.calculateTotalRevenueFact(this.state.data).toLocaleString('ru')}</span>
                            </td>
                            <td className={"tdCell tdAll"}>
                            <span
                                className={"font-p"}>{'Факт по марже (Лимит ГСМ): ' + this.calculateTotalMarginFactWithLimitGsm(this.state.data).toLocaleString('ru')}</span>
                            </td>
                            <td className={"tdCell tdAll"}>
                            <span
                                className={"font-p"}>{'Факт по марже (Пролив ГСМ): ' + this.calculateTotalMarginFactWithStraitGsm(this.state.data).toLocaleString('ru')}</span>
                            </td>
                            <td className={"tdCell tdAll"}>
                            <span
                                className={"font-p"}>{'Тренд по выручке: ' + this.calculateTotalRevenueTrend(this.state.data)}</span>
                            </td>
                            <td className={"tdCell tdAll"}>
                            <span
                                className={"font-p"}>{'Тренд по марже (Лимит ГСМ): ' + this.calculateTotalMarginTrend(this.state.data)}</span>
                            </td>
                            <td className={"tdCell tdAll"}>
                            <span
                                className={"font-p"}>{'Заявки: ' + this.getQuantityQuote(this.state.data)}</span>
                            </td>
                            <td className={"tdCell tdAll"}>
                            <span
                                className={"font-p"}>{'Откл. по выручке тек. день %: ' +
                                this.getDeviationRevenueOrMargin(this.calculateTotalRevenuePlanCurrentDay(this.state.data),
                                    this.calculateTotalRevenueFact(this.state.data))}</span>
                            </td>
                            <td className={"tdCell tdAll"}>
                            <span
                                className={"font-p"}>{'Откл. по марже(Лимит ГСМ) тек. день %: ' + this.getDeviationRevenueOrMargin(this.calculateTotalMarginPlanCurrentDay(this.state.data),
                                this.calculateTotalMarginFactWithLimitGsm(this.state.data))}</span>
                            </td>

                            <td className={"tdCell tdAll"}>
                            <span
                                className={"font-p"}>{'Откл. выручки %: ' + this.getDeviationRevenueOrMargin(this.calculateTotalRevenuePlan(this.state.data),
                                this.calculateTotalRevenueFact(this.state.data))}</span>
                            </td>
                            <td className={"tdCell tdAll"}>
                            <span
                                className={"font-p"}
                                title={"Относительное отклонение по марже (Лимит)"}>{'Откл. маржи(Лимит ГСМ) %: ' + this.getDeviationRevenueOrMargin(this.calculateTotalMarginPlan(this.state.data),
                                this.calculateTotalMarginFactWithLimitGsm(this.state.data))}</span>
                            </td>
                            </thead>
                        </Table>
                    </Col>
                </td>
                <tr style={{position: "relative", top: "-1px",  backgroundColor: "#d3d3d3"}}>
                    <td className={"tdTitle tdAll"}>
                        <span className={"font-title"} style={{marginLeft: "30px"}}>Менеджеры КО</span>
                    </td>
                    <td className={"tdCell tdAll"}></td>
                    <td className={"tdCell tdAll"}>
                        <span className={"font-p"}>План по выручке</span>
                    </td>
                    <td className={"tdCell tdAll"}>
                        <span className={"font-p"}>План по марже </span>
                    </td>
                    <td className={"tdCell tdAll"}>
                        <span className={"font-p"}>Факт по выручке </span>
                    </td>
                    <td className={"tdCell tdAll"}>
                        <span className={"font-p"}>Факт по марже (Лимит  ГСМ) </span>
                    </td>
                    <td className={"tdCell tdAll"}>
                        <span className={"font-p"}>Факт по марже (Пролив ГСМ) </span>
                    </td>
                    <td className={"tdCell tdAll"}>
                        <span className={"font-p"}>Тренд по выручке </span>
                    </td>
                    <td className={"tdCell tdAll"}>
                        <span className={"font-p"}>Тренд по марже (Лимит ГСМ) </span>
                    </td>
                    <td className={"tdCell tdAll"} title={"Количество заявок"}>
                        <span className={"font-p"}>Заявки </span>
                    </td>
                    <td className={"tdCell tdAll"} title={"Относительное отклонение по выручке"}>
                        <span className={"font-p"}>Откл. по выручке тек. день % </span>
                    </td>
                    <td className={"tdCell tdAll"} title={"Относительное отклонение по марже(Лимит)"}>
                        <span className={"font-p"}>Откл. по марже(Лимит ГСМ) тек. день % </span>
                    </td>
                    <td className={"tdCell tdAll"} title={"Относительное отклонение по выручке"}>
                        <span className={"font-p"}>Отн. откл. по выручке % </span>
                    </td>
                    <td className={"tdCell tdAll"} title={"Относительное отклонение по марже(Лимит)"}>
                        <span className={"font-p"}>Отн. откл. по марже (Лимит ГСМ) % </span>
                    </td>
                </tr>
            </>
        );
    }

    renderBody() {
        return (
            <>
                {this.state.data && this.state.data.map((row) => (
                    <RowsCommercial departmentPlan={row} date={this.state.dt}/>
                ))}
            </>
        );
    }

    async fetchUser(id) {
        return await userService.read(id);
    }

    colorButton() {
        let userCommercialEmpty = this.props.model.usersCommercial === null;
        let usersEmpty = this.props.model.users === null;
        let departmentEmpty = this.props.model.department === null;
        return (Object.keys(this.props.model).length === 0) || userCommercialEmpty || usersEmpty || departmentEmpty ? "secondary" : "success";
    }

    renderTitle() {
        return (
            <Row>
                <Col className="mx-auto my-4 text-left" sm>
                    <span>Расчет по заявкам в процессе </span>
                    <BootstrapSwitchButton
                        toggle={this.state.toggle}
                        onstyle="outline-primary"
                        offstyle="outline-secondary"
                        size="sm"
                        onChange={value => this.setState({toggle: value})}
                    />
                </Col>
                <Col className="mx-auto my-4 text-center" sm>
                    <a href="#" onClick={e => this.changeYear(e, -1)}>
                        <i className="fa fa-chevron-left" aria-hidden="true"></i>
                    </a>
                    <span className={"font-title"}>{"  " + " Сводный отчет КО " + this.formatYear() + "  "}</span>
                    <a href="#" onClick={e => this.changeYear(e, 1)}>
                        <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </a>
                </Col>
                <Col className="mx-auto my-4 text-right" sm>
                    <Row className="justify-content-md-end">
                        <Col md="auto">
                            <Dropdown>
                                <Dropdown.Toggle variant={this.colorButton()} id="dropdown-basic">
                                    Фильтры
                                </Dropdown.Toggle>
                                <Dropdown.Menu className={"menu"}>
                                    <Col sm>
                                        {(securityService.anyRoles(this.state.crmUser, ADMIN, HEAD_OF_DEPARTMENT, REGIONAL_DIRECTOR, COMMERCIAL) && this.state.counterDepartment === 0) &&
                                            <FormGroup placeholder="Менеджеры КО"
                                                       name="usersCommercial"
                                                       type="user"
                                                       role="COMMERCIAL"
                                                       multiSelect={true}
                                                       store={this.props.store}
                                            />
                                        }
                                    </Col>
                                    <Col sm>
                                        <FormGroup placeholder="Менеджеры ОЛ"
                                                   name="users"


                                                   type="dictionary"
                                                   optionsType="CRM_USER"
                                                   multiSelect={true}
                                                   store={this.props.store}
                                        />
                                    </Col>
                                    <Col sm>
                                        {(securityService.anyRoles(this.state.crmUser, ADMIN, HEAD_OF_DEPARTMENT, REGIONAL_DIRECTOR, COMMERCIAL) && this.state.counterDepartment === 0) &&
                                            <FormGroup placeholder="Подразделения"
                                                       name="department"
                                                       type="dictionary"
                                                       optionsType="DEPARTMENT"
                                                       multiSelect={true}
                                                       styles={"customStyles"}
                                                       store={this.props.store}
                                                       activeOnly={false}
                                            />
                                        }
                                    </Col>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }

    calculateTotalRevenuePlan(data) {
        return data !== undefined ? data.map(item => item.commercialUser.revenuePlan).reduce((prev, curr) => prev + curr, 0) : 0;
    }

    calculateTotalRevenuePlanCurrentDay(data) {
        const currentDate = new Date();
        const currentDay = currentDate.getDate();
        const countDay = this.getDaysInMonth(this.state.dt.getFullYear(), this.state.dt.getMonth() + 1);
        const currentMonth = currentDate.toLocaleString('default', {month: 'numeric'});
        if (countDay > currentDay && (this.state.dt.getMonth() + 1) >= currentMonth) {
            return (this.calculateTotalRevenuePlan(data) / countDay) * currentDay;
        } else {
            return this.calculateTotalRevenuePlan(data);
        }
    }

    calculateTotalMarginPlanCurrentDay(data) {
        const currentDate = new Date();
        const currentDay = currentDate.getDate();
        const countDay = this.getDaysInMonth(this.state.dt.getFullYear(), this.state.dt.getMonth() + 1);
        const currentMonth = currentDate.toLocaleString('default', {month: 'numeric'});
        if (countDay > currentDay && (this.state.dt.getMonth() + 1) >= currentMonth) {
            return (this.calculateTotalMarginPlan(data) / countDay) * currentDay;
        } else {
            return this.calculateTotalMarginPlan(data);
        }
    }

    calculateTotalMarginPlan(data) {
        return data !== undefined ? data.map(item => item.commercialUser.marginPlan).reduce((prev, curr) => prev + curr, 0) : 0;
    }

    calculateTotalRevenueFact(data) {
        return data !== undefined ? data.map(item => item.commercialUser.revenueFact).reduce((prev, curr) => prev + curr, 0) : 0;
    }

    getDaysInMonth(year, month) {
        return new Date(year, month, 0).getDate();
    }

    calculateTotalMarginFactWithStraitGsm(data) {
        return data !== undefined ? data.map(item => item.commercialUser.marginFactWithStraitGsm).reduce((prev, curr) => prev + curr, 0) : 0;
    }

    calculateTotalMarginFactWithLimitGsm(data) {
        return data !== undefined ? data.map(item => item.commercialUser.marginFactWithLimitGsm).reduce((prev, curr) => prev + curr, 0) : 0;
    }

    calculateTotalRevenueTrend(data) {
        return data !== undefined ? data.map(item => item.commercialUser.revenueTrend).reduce((prev, curr) => prev + curr, 0).toLocaleString('ru') : 0;
    }

    calculateTotalMarginTrend(data) {
        return data !== undefined ? data.map(item => item.commercialUser.marginTrend).reduce((prev, curr) => prev + curr, 0).toLocaleString('ru') : 0;
    }

    getQuantityQuote(data) {
        return data !== undefined ? data.map(item => item.commercialUser.quantityQuote).reduce((prev, curr) => prev + curr, 0).toLocaleString('ru') : 0;
    }

    getDeviationRevenueOrMargin(plan, fact) {
        return plan !== null && plan !== 0 ? ((fact - plan) / plan * 100).toFixed(2).toLocaleString('ru') : 100;
    }

}

class RowsCommercial extends React.PureComponent {
    constructor(props) {

        super(props);

        this.state = {
            open: false,
            isShowTable: false,
            prevDate: this.props.date
        };

    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.date !== this.props.date) {
            this.setState({isShowTable: false, open: false});
        }
    }

    render() {
        const {departmentPlan} = this.props;
        if (departmentPlan.commercialUser.revenuePlan === 0 && departmentPlan.commercialUser.marginPlan === 0
            && departmentPlan.commercialUser.revenueFact === 0 && departmentPlan.commercialUser.marginFact === 0) {
            return <></>;
        }
        return (
            <>
                <tr className={"rowColorKO"}>
                    <td className="tdTitle tdAll">
                        <Button
                            className={"buttonPlanContractor"}
                            size="sm"
                            onClick={() => this.setState({
                                open: !this.state.open,
                                isShowTable: !this.state.isShowTable
                            })}>
                            {this.state.open ? <i className="fa fa-chevron-up arrow-color"></i> :
                                <i className="fa fa-chevron-down arrow-color"></i>}
                        </Button>
                        <span className={"font-p"}>{" " + departmentPlan.commercialUser.name}</span>
                    </td>
                    {this.renderCommercialRow(departmentPlan.commercialUser)}
                </tr>
                {this.renderContractorRow(departmentPlan.contractorRows, departmentPlan.commercialUser)}
            </>
        );
    }

    renderContractorRow(rows, commercialUser) {
        return this.state.isShowTable && rows.map((row) => (
            <>
                <Collapse in={this.state.open}>
                    {<RowContractor key={row} contractorPlan={row}
                                    getBackgroundByValue={this.getBackgroundByValue}
                                    commercial={commercialUser}
                                    date={this.props.date}/>}
                </Collapse>
            </>
        ))
    }

    renderCommercialRow(commercial) {
        const {
            revenuePlan, marginPlan, revenueFact, deviationPresentDayMargin, deviationPresentDayRevenue,
            marginFactWithStraitGsm, marginFactWithLimitGsm, revenueTrend, marginTrend,
            deviationRevenue, deviationMargin, quantityQuote
        } = commercial;
        const formattedNumber = (value) => Number(value).toLocaleString('ru');
        const backgroundColorRevenue = this.getBackgroundByValue(deviationRevenue);
        const backgroundColorMargin = this.getBackgroundByValue(deviationMargin);
        const backgroundColorRevenueCurrentDay = this.getBackgroundByValue(deviationPresentDayRevenue);
        const backgroundColorMarginCurrentDay = this.getBackgroundByValue(deviationPresentDayMargin);
        return (
            <>
                <td className="tdCell tdAll"></td>
                <td className="tdCell tdAll">
                    <span className="font-p">{formattedNumber(revenuePlan)}</span>
                </td>
                <td className="tdCell tdAll">
                    <span className="font-p">{formattedNumber(marginPlan)}</span>
                </td>
                <td className="tdCell tdAll">
                    <span className="font-p">{formattedNumber(revenueFact)}</span>
                </td>
                <td className="tdCell tdAll">
                    <span className="font-p">{formattedNumber(marginFactWithLimitGsm)}</span>
                </td>
                <td className="tdCell tdAll">
                    <span className="font-p">{formattedNumber(marginFactWithStraitGsm)}</span>
                </td>
                <td className="tdCell tdAll">
                    <span className="font-p">{formattedNumber(revenueTrend)}</span>
                </td>
                <td className="tdCell tdAll">
                    <span className="font-p">{formattedNumber(marginTrend)}</span>
                </td>
                <td className="tdCell tdAll">
                    <span className="font-p">{formattedNumber(quantityQuote)}</span>
                </td>

                <td className="tdCell tdAll" style={{backgroundColor: backgroundColorRevenueCurrentDay}}>
                    <span className="font-p">{formattedNumber(deviationPresentDayRevenue)}</span>
                </td>
                <td className="tdCell tdAll" style={{backgroundColor: backgroundColorMarginCurrentDay}}>
                    <span className="font-p">{formattedNumber(deviationPresentDayMargin)}</span>
                </td>

                <td className="tdCell tdAll" style={{backgroundColor: backgroundColorRevenue}}>
                    <span className="font-p">{formattedNumber(deviationRevenue)}</span>
                </td>
                <td className="tdCell tdAll" style={{backgroundColor: backgroundColorMargin}}>
                    <span className="font-p">{formattedNumber(deviationMargin)}</span>
                </td>
            </>
        );
    }

    getBackgroundByValue(value, defaultColor = "white") {
        if (value > 0) {
            return '#ceffbc';
        }
        if (value < 0) {
            return '#FF9999';
        }
        return defaultColor;
    }

}

class RowContractor extends React.PureComponent {
    constructor(props) {

        super(props);

        this.state = {
            open: false,
            isShowTable: false,
        };

    }

    render() {
        const {contractorPlan, commercial} = this.props;
        if (contractorPlan.revenuePlan === 0 && contractorPlan.marginPlan === 0 && contractorPlan.revenueFact === 0 && contractorPlan.marginFact === 0) {
            return <></>;
        }
        return (
            <>
                <tr className={"rowColor"} style={{backgroundColor:"#e5e2e2"}}>
                    <td className="tdTitle tdAll">
                        <Button
                            className={"buttonPlanDepartment"}
                            size="sm"
                            onClick={() => this.setState({
                                open: !this.state.open,
                                isShowTable: !this.state.isShowTable
                            })}>
                            {this.state.open ? <i className="fa fa-chevron-up arrow-color"></i> :
                                <i className="fa fa-chevron-down arrow-color"></i>}
                        </Button>
                        <span className={"font-p"}>{contractorPlan.name}</span>
                    </td>
                    {this.renderContractorRow(contractorPlan)}
                </tr>
                {this.renderDepartmentRow(contractorPlan.departmentRows, commercial, contractorPlan)}
            </>
        )
    }

    renderDepartmentRow(rows, commercialUser, contractor) {
        return this.state.isShowTable && rows.map((row) => (
            <>
                <Collapse in={this.state.open}>
                    {<RowDepartment key={row} departmentPlan={row}
                                    getBackgroundByValue={this.props.getBackgroundByValue}
                                    commercial={commercialUser}
                                    date={this.props.date}
                                    contractor={contractor}/>}
                </Collapse>
            </>
        ))
    }

    renderContractorRow(row) {
        const {
            revenuePlan, marginPlan, revenueFact, deviationPresentDayRevenue,
            deviationPresentDayMargin, marginFactWithStraitGsm, marginFactWithLimitGsm,
            revenueTrend, marginTrend, deviationRevenue, deviationMargin, quantityQuote
        } = row;
        const formattedNumber = (value) => Number(value).toLocaleString('ru');
        const backgroundColorRevenue = this.props.getBackgroundByValue(deviationRevenue);
        const backgroundColorMargin = this.props.getBackgroundByValue(deviationMargin);
        const backgroundColorRevenueCurrentDay = this.props.getBackgroundByValue(deviationPresentDayRevenue);
        const backgroundColorMarginCurrentDay = this.props.getBackgroundByValue(deviationPresentDayMargin);
        return (
            <>
                <td className="tdCell tdAll"></td>
                <td className="tdCell tdAll"><span className="font-p">{formattedNumber(revenuePlan)}</span></td>
                <td className="tdCell tdAll"><span className="font-p">{formattedNumber(marginPlan)}</span></td>
                <td className="tdCell tdAll"><span className="font-p">{formattedNumber(revenueFact)}</span></td>
                <td className="tdCell tdAll"><span className="font-p">{formattedNumber(marginFactWithLimitGsm)}</span>
                </td>
                <td className="tdCell tdAll"><span className="font-p">{formattedNumber(marginFactWithStraitGsm)}</span>
                </td>
                <td className="tdCell tdAll"><span className="font-p">{formattedNumber(revenueTrend)}</span></td>
                <td className="tdCell tdAll"><span className="font-p">{formattedNumber(marginTrend)}</span></td>
                <td className="tdCell tdAll"><span className="font-p">{formattedNumber(quantityQuote)}</span></td>
                <td className="tdCell tdAll" style={{backgroundColor: backgroundColorRevenueCurrentDay}}>
                    <span className="font-p">{formattedNumber(deviationPresentDayRevenue)}</span>
                </td>
                <td className="tdCell tdAll" style={{backgroundColor: backgroundColorMarginCurrentDay}}>
                    <span className="font-p">{formattedNumber(deviationPresentDayMargin)}</span>
                </td>
                <td className="tdCell tdAll" style={{backgroundColor: backgroundColorRevenue}}>
                    <span className="font-p">{formattedNumber(deviationRevenue)}</span>
                </td>
                <td className="tdCell tdAll" style={{backgroundColor: backgroundColorMargin}}>
                    <span className="font-p">{formattedNumber(deviationMargin)}</span>
                </td>
            </>
        );
    }
}

class RowDepartment extends React.PureComponent {
    constructor(props) {

        super(props);

        this.state = {
            open: false,
            isShowTable: false,
            showCommentModal: false,
            commentCount: this.props.departmentPlan.quantityComment,
            commentWord: ""
        };

        this.setCommentCount = this.setCommentCount.bind(this);
    }

    showCommentRedactorModal(show) {
        this.setState({showCommentModal: show});
    }

    setCommentCount(count) {
        this.setState({commentCount: count, commentWord: this.getCommentNamePlural(this.state.commentCount)});
    }

    render() {
        const {departmentPlan} = this.props;
        const {revenuePlan, marginPlan, revenueFact, marginFact, name, logisticRows} = departmentPlan;

        if (revenuePlan === 0 && marginPlan === 0 && revenueFact === 0 && marginFact === 0) {
            return null;
        }

        return (
            <>
                <tr className="rowColorMarginOrGsm" style={{backgroundColor:"#efecec"}}>
                    <td className="tdTitle tdAll">
                        <Button
                            className="buttonKoDep"
                            size="sm"
                            onClick={() =>
                                this.setState({
                                    open: !this.state.open,
                                    isShowTable: !this.state.isShowTable
                                })}>
                            {this.state.open ? (<i className="fa fa-chevron-up arrow-color"></i>) : (
                                <i className="fa fa-chevron-down arrow-color"></i>)}
                        </Button>
                        <span className="font-p">{name}</span>
                    </td>
                    {this.renderDepartmentRow(departmentPlan)}
                </tr>
                {this.renderLogisticRow(logisticRows)}
            </>
        );
    }

    renderLogisticRow(rows) {
        return this.state.isShowTable && rows.map((row) => (
            <>
                <Collapse in={this.state.open}>
                    {<LogisticPlanRow key={row} logisticPlan={row}
                                      getBackgroundByValue={this.props.getBackgroundByValue}/>}
                </Collapse>
            </>
        ))
    }

    renderModal(department) {
        return (
            <Modal
                size="lg"
                aria-labelledby="example-modal-sizes-title-sm"
                show={this.state.showCommentModal}
                onHide={() => this.showCommentRedactorModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Комментарии</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CommentConsolidateReport {...this.props} commercial={this.props.commercial}
                                              department={department} date={this.props.date}
                                              contractor={this.props.contractor}
                                              setCommentCount={this.setCommentCount}>
                    </CommentConsolidateReport>
                </Modal.Body>
            </Modal>

        )
    }

    getCommentNamePlural(qn) {
        if (qn === 1) {
            return "Комментарий";
        } else if (qn >= 2 && qn <= 4) {
            return "Комментария";
        } else {
            return "Комментариев";
        }
    }

    renderDepartmentRow(department) {
        const {commentCount, commentWord} = this.state;
        const {
            quantityComment, revenuePlan, marginPlan, deviationPresentDayRevenue,
            deviationPresentDayMargin, revenueFact, marginFactWithLimitGsm, marginFactWithStraitGsm,
            revenueTrend, marginTrend, deviationRevenue, deviationMargin, quantityQuote
        } = department;

        const formattedNumber = (value) => Number(value).toLocaleString('ru');
        const commentNamePlural = this.getCommentNamePlural(quantityComment);
        const backgroundColorRevenue = this.props.getBackgroundByValue(deviationRevenue);
        const backgroundColorMargin = this.props.getBackgroundByValue(deviationMargin);

        const backgroundColorRevenueCurrentDay = this.props.getBackgroundByValue(deviationPresentDayRevenue);
        const backgroundColorMarginCurrentDay = this.props.getBackgroundByValue(deviationPresentDayMargin);
        return (
            <>
                <td className="tdCell tdAll" title="Комментарий от менеджера">
                    <Button
                        size="sm"
                        className={Number(commentCount) !== 0 ? 'customButtonSuccess' : 'customButton'}
                        onClick={() => this.showCommentRedactorModal(true)}>
                        <div className="buttonContent">
                        <span className="font-p" style={{color: "#212529"}}>
                            {commentWord === 'undefined' || commentWord === '' ? commentNamePlural : commentWord}&nbsp;
                        </span>
                            <span className="font-p" style={{color: "#212529"}}>{Number(commentCount)}</span>
                        </div>
                    </Button>
                    {this.renderModal(department)}
                </td>
                <td className="tdCell tdAll"><span className="font-p">{formattedNumber(revenuePlan)}</span></td>
                <td className="tdCell tdAll"><span className="font-p">{formattedNumber(marginPlan)}</span></td>
                <td className="tdCell tdAll"><span className="font-p">{formattedNumber(revenueFact)}</span></td>
                <td className="tdCell tdAll"><span className="font-p">{formattedNumber(marginFactWithLimitGsm)}</span>
                </td>
                <td className="tdCell tdAll"><span className="font-p">{formattedNumber(marginFactWithStraitGsm)}</span>
                </td>
                <td className="tdCell tdAll"><span className="font-p">{formattedNumber(revenueTrend)}</span></td>
                <td className="tdCell tdAll"><span className="font-p">{formattedNumber(marginTrend)}</span></td>
                <td className="tdCell tdAll"><span className="font-p">{formattedNumber(quantityQuote)}</span></td>

                <td className="tdCell tdAll" style={{backgroundColor: backgroundColorRevenueCurrentDay}}>
                    <span className="font-p">{formattedNumber(deviationPresentDayRevenue)}</span>
                </td>
                <td className="tdCell tdAll" style={{backgroundColor: backgroundColorMarginCurrentDay}}>
                    <span className="font-p">{formattedNumber(deviationPresentDayMargin)}</span>
                </td>

                <td className="tdCell tdAll" style={{backgroundColor: backgroundColorRevenue}}>
                    <span className="font-p">{formattedNumber(deviationRevenue)}</span>
                </td>
                <td className="tdCell tdAll" style={{backgroundColor: backgroundColorMargin}}>
                    <span className="font-p">{formattedNumber(deviationMargin)}</span>
                </td>
            </>
        );
    }
}

class LogisticPlanRow extends React.PureComponent {
    constructor(props) {

        super(props);

        this.state = {
            open: false,
            isShowTable: false,
        };

    }

    render() {
        const {logisticPlan} = this.props;

        if (logisticPlan.revenuePlan === 0 && logisticPlan.marginPlan === 0 && logisticPlan.revenueFact === 0 && logisticPlan.marginFact === 0) {
            return null;
        }

        const {
            name, revenuePlan, marginPlan, revenueFact, deviationPresentDayRevenue,
            deviationPresentDayMargin, marginFactWithLimitGsm, marginFactWithStraitGsm,
            revenueTrend, marginTrend, deviationRevenue, deviationMargin, quantityQuote
        } = logisticPlan;
        const formatNumber = (value: number) => new Intl.NumberFormat('ru').format(value);
        const backgroundColorRevenue = this.props.getBackgroundByValue(deviationRevenue);
        const backgroundColorMargin = this.props.getBackgroundByValue(deviationMargin);

        const backgroundColorRevenueCurrentDay = this.props.getBackgroundByValue(deviationPresentDayRevenue);
        const backgroundColorMarginCurrentDay = this.props.getBackgroundByValue(deviationPresentDayMargin);
        return (
            <tr>
                <td className="tdTitle tdAll">
                    <span className="font-p">{name}</span>
                </td>
                <td className="tdCell tdAll"></td>
                <td className="tdCell tdAll">
                    <span className="font-p">{formatNumber(revenuePlan)}</span>
                </td>
                <td className="tdCell tdAll">
                    <span className="font-p">{formatNumber(marginPlan)}</span>
                </td>
                <td className="tdCell tdAll">
                    <span className="font-p">{formatNumber(revenueFact)}</span>
                </td>
                <td className="tdCell tdAll">
                    <span className="font-p">{formatNumber(marginFactWithLimitGsm)}</span>
                </td>
                <td className="tdCell tdAll">
                    <span className="font-p">{formatNumber(marginFactWithStraitGsm)}</span>
                </td>
                <td className="tdCell tdAll">
                    <span className="font-p">{formatNumber(revenueTrend)}</span>
                </td>
                <td className="tdCell tdAll">
                    <span className="font-p">{formatNumber(marginTrend)}</span>
                </td>
                <td className="tdCell tdAll">
                    <span className="font-p">{formatNumber(quantityQuote)}</span>
                </td>
                <td className="tdCell tdAll" style={{backgroundColor: backgroundColorRevenueCurrentDay}}>
                    <span className="font-p">{formatNumber(deviationPresentDayRevenue)}</span>
                </td>
                <td className="tdCell tdAll" style={{backgroundColor: backgroundColorMarginCurrentDay}}>
                    <span className="font-p">{formatNumber(deviationPresentDayMargin)}</span>
                </td>
                <td className="tdCell tdAll" style={{backgroundColor: backgroundColorRevenue}}>
                    <span className="font-p">{formatNumber(deviationRevenue)}</span>
                </td>
                <td className="tdCell tdAll" style={{backgroundColor: backgroundColorMargin}}>
                    <span className="font-p">{formatNumber(deviationMargin)}</span>
                </td>
            </tr>
        );
    }
}

const ConsolidateReportCommercialInnerConnected = connect(mapStateToProps)(ConsolidateReportCommercialInner);

export default connect(mapStateToProps)(ConsolidateReportCommercial);