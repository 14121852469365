import {axios} from '../axios';
import Util from "../Util";

class DocService {
	
	static BASE_PATH = window.location.origin;

	static PDF = "pdf";
	static XLSX = "xlsx";
	static XLS = "xls";
	static DOCX = "docx";
	static DOC = "doc";

	static AGENT = "agent";
	static CARRIER = "carrier";
	static CLIENT = "client";

	static EFES = "efes";
	static EMPTYFOOTER = "emptyfooter"

	getQuoteCarrierAgreement(quoteId, type) {
		let url = `/api/doc/agreement/carrier/${quoteId}/${type}`, fileName = `заявка_П.${type}`;
		switch(type) {
			case DocService.PDF:
				this.openPdf(url);
				break;
			case DocService.DOCX:
			case DocService.XLSX:
				this.downloadFile(url, fileName);
				break;
		}
	}
	
	getQuoteClientAgreement(quoteId, type) {
		let url = `/api/doc/agreement/client/${quoteId}/${type}`, fileName = `договор_заявка_с_КА.${type}`;
		switch(type) {
			case DocService.PDF:
				this.openPdf(url);
				break;
			case DocService.XLSX:
				this.downloadFile(url, fileName);
				break;
		}
	}

	getProcuration(quoteId, type) {
		let url = `/api/doc/procuration/${quoteId}/${type}`, fileName = `доверенность_на_водителя.${type}`;
		switch(type) {
			case DocService.PDF:
				this.openPdf(url);
				break;
			case DocService.DOCX:
			case DocService.XLSX:
				this.downloadFile(url, fileName);
				break;
		}
	}

	getEmptyFooterProcuration(quoteId) {
		let url = `/api/doc/procuration/${quoteId}/pdf/emptyFooterEfes`, fileName = `доверенность_на_водителя.pdf`;
		this.downloadFile(url, fileName);
	}

	getEfesProcuration(quoteId) {
		let url = `/api/doc/procuration/${quoteId}/pdf/efes`, fileName = `доверенность_на_водителя.pdf`;
		this.downloadFile(url, fileName);
	}

	getClientBill(quoteId, type) {
		const url = `/api/doc/client-bill/${quoteId}/${type}`, fileName = `счет_заказчика_${quoteId}.${type}`;
		switch(type) {
			case DocService.PDF:
				this.openPdf(url);
				break;
			case DocService.XLSX:
				this.downloadFile(url, fileName);
				break;
		}
	}

	getCarrierBill(quoteId) {
		const url = `/api/doc/carrier-bill/${quoteId}`;
		this.downloadFile(url, "счет_перевозчика.xlsx");
	}

	getCarrierAct(quoteId) {
		const url = `/api/doc/carrier-act/${quoteId}`;
		this.downloadFile(url, "акт_перевозчика.xlsx");
	}
	
	getContractAgreement(contract, type) {
		const url = `/api/doc/contract/${type}/${contract.id}`;
		this.openPdf(url);
	}

	getContractAccession() {
		const url= "/api/doc/contract/accession";
		this.openPdf(url);
	}

	getTaskLinearReport(rows) {
		const url = `/api/export-import/export/task/report/xls`;
		const fileName = "Отчет по задачам CRM " + Util.formatDate(new Date()) + ".xlsx";
		this.downloadFile(url, fileName, rows);
	}

	async getQuoteRouteExport(params, call) {
		const url = `/api/export-import/export/quote-route/xls`;
		const fileName = "Экспорт рейсов " + Util.formatDate(new Date()) + ".xlsx";
		await this.downloadFile(url, fileName, params, call);
	}

	previewByUrl(url, displayedName) {
		const extension = displayedName.split('.').pop();
		switch(extension) {
			case DocService.XLSX:
			case DocService.XLS:
			case DocService.DOCX:
			case DocService.DOC:
				this.openMSOfficeDoc(url);
				break;
			case DocService.PDF:
				this.openPdf(url);
				break;
			default:
				this.openImage(url, extension);
		}
	}
	
	preview(id, displayedName) {
		const url = `/api/doc/preview/${id}/${displayedName}`;
		this.previewByUrl(url, displayedName);
	}

	openPdf(url) {
		this.openFile(url, 'application/pdf'); 
	}
	
	openImage(url, extension) {
		this.openFile(url, `image/${extension}`); 
	}
	
	openMSOfficeDoc(url) {
		const link = document.createElement('a');
		link.href = `https://docs.google.com/viewer?url=${DocService.BASE_PATH + url}`;
		link.target = '_blank';
		document.body.appendChild(link);
		link.click();		
	}
	
	async openFile(url, type) {
		const response = await axios(url, {
		    method: 'GET',
		    responseType: 'blob'
		});
		if (response?.data) {
			const file = new Blob(
				[response.data],
				{type: type});
			const fileURL = URL.createObjectURL(file);
			window.open(fileURL);
		}
	}
	
	async downloadFile(url, fileName, data, call) {
		axios({
		    url: url,
		    method: data ? 'POST' : 'GET',
		    responseType: 'blob',
			data: data
		}).then((response) => {
		    const url = window.URL.createObjectURL(new Blob([response.data]));
		    const link = document.createElement('a');
		    link.href = url;
		    link.setAttribute('download', fileName);
		    document.body.appendChild(link);
		    link.click();
			if (call) {
				call();
			}
		});
	}
}

export const PDF = DocService.PDF;
export const XLSX = DocService.XLSX;
export const DOCX = DocService.DOCX;

export const AGENT = DocService.AGENT;
export const CARRIER = DocService.CARRIER;
export const CLIENT = DocService.CLIENT;

export const EFES = DocService.EFES;
export const EMPTYFOOTER = DocService.EMPTYFOOTER

export default new DocService();