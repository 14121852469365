
import BaseSelect from "./BaseSelect";
import { userService } from '../../services/UserService';

class UserSelect extends BaseSelect {
	constructor(props) {
		super(props);
	}

	fetchOptions() {
		return this.props.role ? userService.loadByRole(this.props.role, this.props.activeOnly) : userService.load();	
	}
}

export default UserSelect;