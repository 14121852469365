import {axios} from '../axios';

class RequestStatusService {

    async load(quoteId) {
        const response = await axios.get(`/api/v1/status?quoteId=${quoteId}`);
        return response.data;
    }
}

export default new RequestStatusService();
