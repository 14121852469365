import React, {PureComponent} from "react";
import {Button, Card, Form} from "react-bootstrap";
import settingsService from "../../../services/SettingsService";
import {connect} from "react-redux";
import {setToastObjAC} from "../../../reducers/toastObj";

function mapGlobalStateToProps(state) {
    return {
        toastObj2: state.toastObj
    };
}

class Settings extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            [this.YANDEX_MAP_API_KEY]: null
        }
    }

    YANDEX_MAP_API_KEY = "yandexMapApiKey";
    TASK_TRACKER_EMAIL_NOTIFICATION_KEY = "enableTaskTrackerEmailNotifications";
    CHANGE_STATUS_AUTOMATICALLY = "changeStatusAutomatically";
    MIN_APPLICATION_VERSION = "minApplicationVersion";
    CURRENT_APPLICATION_VERSION = "currentApplicationVersion"

    STATE_KEYS = [
        this.YANDEX_MAP_API_KEY,
        this.TASK_TRACKER_EMAIL_NOTIFICATION_KEY,
        this.CHANGE_STATUS_AUTOMATICALLY,
        this.MIN_APPLICATION_VERSION,
        this.CURRENT_APPLICATION_VERSION
    ]

    componentDidMount() {
        this.STATE_KEYS.forEach(stateKey => this.fetchSetting(stateKey))
    }

    fetchSetting(stateKey) {
        settingsService.get(stateKey)
            .then(key => this.setState({[stateKey]: key}));
    }

    saveKey(stateKey) {
        settingsService.saveOne(stateKey, this.state[stateKey])
            .then(key => this.props.setToastObjAC({ textHeader: "Настройка сохранена" }))
    }

    renderSwitchPanel(label, stateKey) {
        return <Card style={{marginBottom: "20px"}}>
            <Card.Header>{label}</Card.Header>
            <Card.Body>
                <Form.Check
                    id={stateKey}
                    type="switch"
                    label="Включить / выключить"
                    defaultChecked={this.state[stateKey]}
                    onClick={(e) =>
                        settingsService.saveOne(stateKey, e.target.checked ? "true" : "false")}
                />
            </Card.Body>
        </Card>
    }

    renderInputAndSave(label, stateKey) {
        return <Card style={{marginBottom: "20px"}}>
            <Card.Header><div>{label}</div></Card.Header>
            <Card.Body>
                <Form.Group>
                    <div style={{display: "flex"}}>
                        <Form.Control type="text"
                                      style={{width: "400px"}}
                                      onChange={e => this.setState({[stateKey]: e.target.value})}
                                      defaultValue={this.state[stateKey]}
                                      placeholder={label} />
                        <Button variant="primary"
                                style={{marginLeft: "10px"}}
                                onClick={() => this.saveKey(stateKey)}>
                            Сохранить
                        </Button>
                    </div>
                </Form.Group>
            </Card.Body>
        </Card>
    }

    render() {
        return <Form style={{width: "50%"}}>
            {this.renderInputAndSave("Yandex Map API Key", this.YANDEX_MAP_API_KEY)}
            {this.renderInputAndSave("Минимальная версия мобильного приложения", this.MIN_APPLICATION_VERSION)}
            {this.renderInputAndSave("Текущая доступная версия мобильного приложения", this.CURRENT_APPLICATION_VERSION)}

            {this.renderSwitchPanel("Уведомления на почту при создании новых задач и комментариев к задаче", this.TASK_TRACKER_EMAIL_NOTIFICATION_KEY)}
            {this.renderSwitchPanel("Автоматическая смена статуса заявок по геолокации водителя", this.CHANGE_STATUS_AUTOMATICALLY)}
        </Form>
    }
}

export default connect(mapGlobalStateToProps, {setToastObjAC})(Settings);