import BaseForm from "../../form/BaseForm";
import LinearReport from "./LinearReport";

class Reports extends BaseForm {
    constructor(props) {
        super(props);
    }

    getFormTabTitle() {
        return "Линейный";
    }

    renderForm() {
        return <div><LinearReport {...this.props}/></div>
    }
}

export default Reports;
