import React from 'react';
import {
	Switch,
	Route
} from "react-router-dom";

import SurveyForm from "../components/survey/form/card/SurveyForm";
import EntitySurveyForm from "../components/survey/EntitySurveyForm";

import Enums from "../Enums";
import ContactForm from "../components/control-panel/contractors/contact/ContactForm";

import { QuoteType } from "../Const";

import Quote from '../components/quotes/quote/Quote';
import Quotes from '../components/quotes/quote/Quotes';

import User from '../components/control-panel/users/User';
import Users from '../components/control-panel/users/Users';
import { GENERAL, EXTERNAL } from '../services/UserService';

import Driver from '../components/control-panel/drivers/Driver';
import Drivers from '../components/control-panel/drivers/Drivers';

import Contractor from '../components/control-panel/contractors/Contractor';
import Contractors from '../components/control-panel/contractors/Contractors';

import Contracts from '../components/control-panel/contracts/Contracts';

import Organization from '../components/control-panel/organizations/Organization';
import Organizations from '../components/control-panel/organizations/Organizations';

import Dictionary from '../components/control-panel/dictionary/Dictionary';
import Dictionaries from '../components/control-panel/dictionary/Dictionaries';

import Vehicle from '../components/control-panel/vehicles/Vehicle';
import Vehicles from '../components/control-panel/vehicles/Vehicles';

import SLIntegration from '../components/control-panel/export-import/SLIntegration';
import Approval from '../components/control-panel/approval/Approval';

import {CARCASS, LOADING_TYPE, CARGO_TYPE, DOCUMENT_TYPE, DEPARTMENT, DIVISION, POSITION, PRODUCT_TYPE, MARK, MODEL} from '../components/AuditTable';

import Ssp from '../components/reports/Ssp';

import SspYear from '../components/report-plan/SspYear';

import Alert from '../components/control-panel/alert/Alert';
import Motivation from "../components/control-panel/motivation/Motivation";
import MonthReport from "../components/report-plan/MonthReport";
import OriginalAddresses from "../components/control-panel/addresses/OriginalAddresses";
import planSales from '../components/report-plan/PlanSales';
import ConsolidateReportLogistic from '../components/report-plan/ConsolidateReportLogistic';
import OriginalAddress from "../components/control-panel/addresses/OriginalAddress";
import ConsolidateReportCommercial from '../components/report-plan/ConsolidateReportCommercial';
import OperationalReport from "../components/operational-report/OperationalReport";
import PlanningReport from "../components/report-plan/PlanningReport";
import Task from "../components/tasktracker/task/Task";
import Topics from "../components/tasktracker/topic/Topics";
import Topic from "../components/tasktracker/topic/Topic";
import QuoteProcess from "../components/control-panel/quote-processing/QuoteProcess";
import TaskTrackerForm from "../components/tasktracker/TaskTrackerForm";
import Departments from "../components/control-panel/department/Departments";
import Department from "../components/control-panel/department/Department";

import Settings from "../components/control-panel/settings/Settings";
import MonitoringMap from "../components/map/MonitoringMap";
import UserProfile from "../components/UserProfile";
import SupplyImport from "../components/control-panel/export-import/SupplyImport";
import SmsSenderSettings from "../components/control-panel/settings/SmsSenderSettings";
import {ADMIN, CLIENT, GUEST, HEAD_OF_DEPARTMENT, CARRIER, securityService} from "../services/SecurityService";
import QuoteRouteExport from "../components/control-panel/export-import/QuoteRouteExport";
import Products from "../components/product/Products";
import Product from "../components/product/Product";
import NotificationTable from "../components/Notices/NotificationTable";
import QuoteStatusHistoryReport from "../components/reports/QuoteStatusHistoryReport";
import SmsTable from "../components/SmsMessages/SmsTable";
import Models from "../components/control-panel/vehiclemarkmodel/Models";
import Model from "../components/control-panel/vehiclemarkmodel/Model";
import Mark from "../components/control-panel/vehiclemarkmodel/Mark";
import Marks from "../components/control-panel/vehiclemarkmodel/Marks";
import Forwarders from "../components/control-panel/forwarder/Forwarders";
import Forwarder from "../components/control-panel/forwarder/Forwarder";
import Script from "../components/control-panel/script/Script"

class SafeRoute extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    checkRoles() {
        const user = securityService.getUser();
        const {roles, excludeRoles, forwarder} = this.props;
        const arrRoles = !roles || Array.isArray(roles) ? roles : [roles];

		if (!securityService.forwarderIsArttek(forwarder)) {
			return false;
		}
        if (excludeRoles) {
            return excludeRoles.every(role => !securityService.hasRoleButAngel(user, role));
        } else if (!arrRoles) {
            return true;
        }
        return arrRoles.some(role => securityService.hasRole(user, role));
    }

    render() {
        return this.checkRoles() &&
            <Route
                exact={this.props.exact}
                path={this.props.path}
                render={this.props.render}
                component={this.props.component}
                type={this.props.type}
            >
                {this.props.insideComponent}
            </Route>
    }
}

export default class ContentRouter extends React.PureComponent {

	render() {
		const GENERAL_ROLES = securityService.getRolesByType(GENERAL);

		return (
			<Switch>
                <SafeRoute exact path={["/", "/quotes"]} insideComponent=<Quotes title="Заявки" type={QuoteType.QUOTE}/> excludeRoles={[GUEST]} />
                <SafeRoute exact path="/quotes/quote" render={(props) => <Quote {...props} type={QuoteType.QUOTE} />} />

                <SafeRoute exact path="/survey/form" render={(props) => <SurveyForm {...props}/>}/>

                <SafeRoute exact path="/survey/organization" render={(props) =>
                    <EntitySurveyForm {...props} key="surveys_organization" type={Enums.SURVEY_TYPE.ORGANIZATION} title={"Анкеты организаций"}/>}
                />

                <SafeRoute exact path="/survey/driver" render={(props) =>
                    <EntitySurveyForm {...props} key="surveys_driver" type={Enums.SURVEY_TYPE.DRIVER} title={"Анкеты водителей"}/>}
                />

                <SafeRoute exact path="/survey/vehicle" render={(props) =>
                    <EntitySurveyForm {...props} key="surveys_vehicle" type={Enums.SURVEY_TYPE.VEHICLE} title={"Анкеты ТС"}/>}
                />

                <SafeRoute exact path="/survey/contact-person" render={(props) =>
                    <EntitySurveyForm {...props} key="surveys_contact-person" type={Enums.SURVEY_TYPE.CONTACT_PERSON} title={"Анкеты контактных лиц"}/>}
                />

                <SafeRoute exact path="/survey/list" render={(props) => <EntitySurveyForm {...props} key="surveys_list" title={"Все анкеты"} />}/>

                <SafeRoute exact path="/free-quotes" insideComponent=<Quotes title="Свободные заявки" type={QuoteType.FREE} /> />
                <SafeRoute exact path="/free-quotes/quote" render={(props) => <Quote {...props} type={QuoteType.FREE} />} />

				<SafeRoute exact path="/monitoring" insideComponent=<Quotes title="Мониторинг" type={QuoteType.MONITORING} /> roles={GENERAL_ROLES} />
				<SafeRoute exact path="/monitoring/quote" render={(props) => <Quote {...props} type={QuoteType.MONITORING} />} roles={GENERAL_ROLES} />

				<SafeRoute exact path="/control-panel/users" insideComponent=<Users title="Пользователи" type={GENERAL} /> roles={ADMIN} />
				<SafeRoute exact path="/control-panel/users/user" render={props => <User {...props} type={GENERAL} />} roles={ADMIN} />

				<SafeRoute exact path="/control-panel/external-users" insideComponent=<Users title="Внешние пользователи" type={EXTERNAL} /> roles={GENERAL_ROLES} />
				<SafeRoute exact path="/control-panel/external-users/user" render={(props) => <User {...props} type={EXTERNAL} roles={GENERAL_ROLES} />} />

                <SafeRoute exact path="/control-panel/drivers" component={Drivers} excludeRoles={[CLIENT]} />
                <SafeRoute exact path="/control-panel/drivers/driver" component={Driver} excludeRoles={[CLIENT]} />

                <SafeRoute exact path="/control-panel/contractors" component={Contractors} />
                <SafeRoute exact path="/control-panel/contractors/contractor" component={Contractor} />
                <SafeRoute exact path="/control-panel/contractors/contractor/contact" component={ContactForm} />
                <SafeRoute exact path="/control-panel/contractors/contractor/quote" component={Quote} roles={GENERAL_ROLES} />

                <SafeRoute exact path="/control-panel/organizations" component={Organizations} roles={GENERAL_ROLES} />
                <SafeRoute exact path="/control-panel/organizations/organization" component={Organization} roles={GENERAL_ROLES} />

				<SafeRoute exact path="/control-panel/carcasses"
						   insideComponent=<Dictionaries title="Типы кузова" pathname="/control-panel/carcasses/carcass" requestType="carcass" />
						   roles={GENERAL_ROLES}
				/>
				<SafeRoute exact path="/control-panel/carcasses/carcass"
						   insideComponent=<Dictionary tabTitle="Тип кузова" pathname="/control-panel/carcasses" auditType={CARCASS} requestType="carcass" />
						   roles={GENERAL_ROLES}
				/>
				<SafeRoute exact path="/control-panel/loading-types"
						   insideComponent=<Dictionaries title="Типы загрузки" pathname="/control-panel/loading-types/loading-type" requestType="loading-type" />
						   roles={GENERAL_ROLES}
				/>
				<SafeRoute exact path="/control-panel/loading-types/loading-type"
						   insideComponent=<Dictionary tabTitle="Тип загрузки" pathname="/control-panel/loading-types" auditType={LOADING_TYPE} requestType="loading-type"/>
						   roles={GENERAL_ROLES}
				/>
				<SafeRoute exact path="/control-panel/cargo-types"
						   insideComponent=<Dictionaries title="Груз" pathname="/control-panel/cargo-types/cargo-type" requestType="cargo-type" />
				           roles={GENERAL_ROLES}
				/>

				<SafeRoute exact path="/control-panel/cargo-types/cargo-type"
						   insideComponent=<Dictionary tabTitle="Груз" pathname="/control-panel/cargo-types" auditType={CARGO_TYPE} requestType="cargo-type" />
						   roles={GENERAL_ROLES}
				/>

                <SafeRoute exact path="/control-panel/vehicles" component={Vehicles} excludeRoles={[CLIENT]} />
                <SafeRoute exact path="/control-panel/vehicles/vehicle" component={Vehicle} excludeRoles={[CLIENT]} />

				<SafeRoute exact path="/control-panel/alert" component={Alert} roles={ADMIN} forwarder={Forwarder.ARTTEK()} />
				<SafeRoute exact path="/control-panel/approval" component={Approval} roles={GENERAL_ROLES} forwarder={Forwarder.ARTTEK()} />

				<SafeRoute exact path="/control-panel/sl-integration" component={SLIntegration} roles={GENERAL_ROLES} />

				<SafeRoute exact path="/report/ssp" component={Ssp} roles={GENERAL_ROLES} forwarder={Forwarder.ARTTEK()} />
				<SafeRoute exact path="/report/ssp/year" component={SspYear} roles={GENERAL_ROLES} forwarder={Forwarder.ARTTEK()} />
				<SafeRoute exact path="/report/ssp/year/month" component={MonthReport} roles={GENERAL_ROLES} forwarder={Forwarder.ARTTEK()} />

				<SafeRoute exact path="/control-panel/motivation" component={Motivation} roles={GENERAL_ROLES} forwarder={Forwarder.ARTTEK()}/>

				<SafeRoute exact path="/control-panel/document-types"
						   insideComponent=<Dictionaries title="Сопроводительные документы" pathname="/control-panel/document-types/document-type" requestType="document-type" />
						   roles={GENERAL_ROLES}
				/>
				<SafeRoute exact path="/control-panel/document-types/document-type"
						   insideComponent=<Dictionary tabTitle="Сопроводительный документ" pathname="/control-panel/document-types" auditType={DOCUMENT_TYPE} requestType="document-type" />
						   roles={GENERAL_ROLES}
				/>

				<SafeRoute exact path="/control-panel/contracts" component={Contracts} />

				<SafeRoute exact path="/monitoring" insideComponent=<OriginalAddresses normalized={false} /> roles={GENERAL_ROLES} />

				<SafeRoute exact path="/control-panel/original-addresses" render={props => <OriginalAddresses {...props} isNormalizedList={false} />} roles={GENERAL_ROLES} forwarder={Forwarder.ARTTEK()}/>
				<SafeRoute exact path="/control-panel/original-addresses/original-address"
						   render={props => <OriginalAddress {...props} title={"Распознавание адреса"} pathname={"/control-panel/original-addresses"} />}
						   roles={GENERAL_ROLES}
						   forwarder={Forwarder.ARTTEK()}
				/>

				<SafeRoute exact path="/control-panel/normalized-original-addresses" render={props => <OriginalAddresses {...props} isNormalizedList={true} />} forwarder={Forwarder.ARTTEK()}/>
				<SafeRoute exact path="/control-panel/normalized-original-addresses/original-address"
						   render={props => <OriginalAddress {...props} title={"Редактирование адреса"} pathname={"/control-panel/normalized-original-addresses"} />}
						   roles={GENERAL_ROLES}
						   forwarder={Forwarder.ARTTEK()}
				/>

				<SafeRoute exact path="/control-panel/departments"
						   insideComponent=<Departments title="Подразделения" pathname="/control-panel/departments/department" requestType="department" />
						   roles={ADMIN}
				/>
				<SafeRoute exact path="/control-panel/departments/department"
						   insideComponent=<Department tabTitle="Подразделение" pathname="/control-panel/departments" auditType={DEPARTMENT} requestType="department" />
						   roles={ADMIN}
				/>

				<SafeRoute exact path="/planSales/plan" component={planSales} roles={GENERAL_ROLES} />
				<SafeRoute exact path="/consolidated/reports/report" component={ConsolidateReportLogistic} roles={GENERAL_ROLES} />

				<SafeRoute exact path="/control-panel/quote/process" component={QuoteProcess} roles={GENERAL_ROLES} forwarder={Forwarder.ARTTEK()} />

				<SafeRoute exact path="/consolidated/reports/commercialReport" component={ConsolidateReportCommercial} roles={GENERAL_ROLES} forwarder={Forwarder.ARTTEK()} />

				<SafeRoute exact path="/planning-report" component={PlanningReport} roles={GENERAL_ROLES} forwarder={Forwarder.ARTTEK()} />
				<SafeRoute exact path="/operational-report" component={OperationalReport} roles={GENERAL_ROLES} forwarder={Forwarder.ARTTEK()} />

				<SafeRoute exact path="/task-tracker" component={TaskTrackerForm} roles={GENERAL_ROLES} forwarder={Forwarder.ARTTEK()} />
				<SafeRoute exact path="/task-tracker/task" component={Task} roles={GENERAL_ROLES} forwarder={Forwarder.ARTTEK()} />

				<SafeRoute exact path="/control-panel/topics"
						   insideComponent=<Topics title="Темы задач" pathname="/control-panel/topics/topic" requestType="topic" />
						   roles={GENERAL_ROLES} />
				<SafeRoute exact path="/control-panel/topics/topic"
						   insideComponent=<Topic tabTitle="Тема" pathname="/control-panel/topics" requestType="topic" />
						   roles={GENERAL_ROLES} />

                <SafeRoute exact path="/monitoring-map" component={MonitoringMap} roles={[...GENERAL_ROLES, CLIENT, CARRIER]} />

				<SafeRoute exact path="/control-panel/settings" component={Settings} roles={ADMIN} />
				<SafeRoute exact path="/user-profile" component={UserProfile} />

                <SafeRoute exact path="/control-panel/divisions"
						   insideComponent=<Dictionaries title="Отделы" pathname="/control-panel/divisions/division" requestType="division" />
						   roles={ADMIN}
				/>
                <SafeRoute exact path="/control-panel/divisions/division"
						   insideComponent=<Dictionary tabTitle="Отдел" pathname="/control-panel/divisions" auditType={DIVISION} requestType="division" />
						   roles={ADMIN}
				/>

				<SafeRoute exact path="/control-panel/positions"
						   insideComponent=<Dictionaries title="Должности" pathname="/control-panel/positions/position" requestType="position" />
						   roles={ADMIN}
				/>
				<SafeRoute exact path="/control-panel/positions/position"
						   insideComponent=<Dictionary tabTitle="Должность" pathname="/control-panel/positions" auditType={POSITION} requestType="position" />
						   roles={ADMIN}
				/>

				<SafeRoute exact path="/control-panel/import/supply" component={SupplyImport} roles={GENERAL_ROLES} forwarder={Forwarder.ARTTEK()} />
				<SafeRoute exact path="/control-panel/export/quote-route" render={(props) => <QuoteRouteExport {...props}/>} roles={GENERAL_ROLES} forwarder={Forwarder.ARTTEK()}/>

                <SafeRoute exact path="/control-panel/sms-sender-settings" component={SmsSenderSettings} />

				<SafeRoute exact path="/control-panel/notification-table" component={NotificationTable} roles={[ADMIN, HEAD_OF_DEPARTMENT]} forwarder={Forwarder.ARTTEK()} />

				<SafeRoute exact path="/control-panel/report/quote-status-history" component={QuoteStatusHistoryReport} roles={[ADMIN, HEAD_OF_DEPARTMENT]} forwarder={Forwarder.ARTTEK()} />

				<SafeRoute exact path="/control-panel/products" component={Products} roles={ADMIN} />
				<SafeRoute exact path="/control-panel/products/product" component={Product} roles={ADMIN} />

				<SafeRoute exact path="/control-panel/product-types"
						   insideComponent=<Dictionaries title="Категории товаров" pathname="/control-panel/product-types/product-type" requestType="product-type" />
				           roles={ADMIN}
				/>
				<SafeRoute exact path="/control-panel/product-types/product-type"
						   insideComponent=<Dictionary tabTitle="Категория товара" pathname="/control-panel/product-types" auditType={PRODUCT_TYPE} requestType="product-type" />
				           roles={ADMIN}
				/>

				<SafeRoute exact path="/control-panel/sms-table" component={SmsTable} roles={[ADMIN ]} forwarder={Forwarder.ARTTEK()} />

				<SafeRoute exact path="/control-panel/marks"
						   insideComponent=<Marks title="Марки ТС" pathname="/control-panel/marks/mark" requestType="mark" />
				           roles={GENERAL_ROLES}
				/>
				<SafeRoute exact path="/control-panel/marks/mark"
						   insideComponent=<Mark tabTitle="Марка ТС" pathname="/control-panel/marks" auditType={MARK} requestType="mark" />
				           roles={GENERAL_ROLES}
				/>
				<SafeRoute exact path="/control-panel/models"
						   insideComponent=<Models title="Модели ТС" pathname="/control-panel/models/model" requestType="model" />
				           roles={GENERAL_ROLES}
				/>
				<SafeRoute exact path="/control-panel/models/model"
						   insideComponent=<Model tabTitle="Модель ТС" pathname="/control-panel/models" auditType={MODEL} requestType="model" />
						   roles={GENERAL_ROLES}
				/>

				<SafeRoute exact path="/control-panel/forwarders" component={Forwarders} roles={GENERAL_ROLES} />
				<SafeRoute exact path="/control-panel/forwarders/forwarder" component={Forwarder} roles={GENERAL_ROLES} />

				<SafeRoute exact path="/control-panel/scripts" component={Script} roles={[ADMIN]} forwarder={Forwarder.ARTTEK()}/>
            </Switch>
		);
	}
}
