import {axios} from '../axios';

class QuoteService {
	async byId(id) {
		const response = await axios.get("/api/quotes/" + id);
		return response.data;
	}

	async list(params) {
		const response = await axios.post("/api/quotes/list", params);
		return response.data;
	}
	
	async setMonitoringModeInLocalStorage() {
		const response = await axios.get("/api/control-panel/settings/setting/monitoringMode");
		localStorage.setItem("monitoringMode", JSON.stringify(response.data));
	}		
	
	async save(quote, baseOn) {
		const call = (quote.id && quote.id > 0) ? axios.put : axios.post;
		const response = await call("/api/quotes?baseOn=" + baseOn, quote);
		return response.data;
	}
	
	async change(partialData) {
		const response = await axios.put("/api/quotes/change", partialData);
		return response.data;
	}

	async calculatePaymentDateWithDelay(quoteId, delayField, delay) {
		const response = await axios.get(`/api/quotes/${quoteId}/payment-date-with-delay/${delayField}/${delay}`);
		return response.data;
	}

	async resetFieldsInActiveEntity(quote) {
		const response = await axios.post("/api/quotes/reset-fields", quote);
		return response.data;
	}

	async calculateMarginProfitability(quote) {
		const request = {
			carrierVatMode: quote.carrierVatMode,
			contractorVatMode: quote.contractorVatMode,
			carrierPrice: quote.carrierPrice,
			contractorPrice: quote.contractorPrice,
			carrierGsm: quote.carrierGsm,
			gsmStrait: quote.gsmStrait,
			gsmDiscount: quote.gsmDiscount,
			carrierExpenses: quote.carrierExpenses,
			contractorExpenses: quote.contractorExpenses,
			clientContractId: quote.clientContract?.id
		}
		const response = await axios.post("/api/quotes/margin-profitability", request);
		return response.data;
	}

	async findLastQuoteByDriver(driver) {
		const response = await axios.post("api/quotes/last/by-driver", driver);
		return response.data;
	}

	async updateDepartmentByUsers(params) {
		const response = await axios.post("api/quotes/update/department", params);
		return response.data;
	}

	async updateCommercial(params) {
		const response = await axios.post("api/quotes/update/commercial", params);
		return response.data;
	}

	async verificationBeforePrintClientBill(quoteId) {
		const response = await axios.get(`/api/quotes/client-bill-verify/${quoteId}`);
		return response.data;
	}

	async checkCarrierContractExistence(quoteId) {
		const response = await axios.get(`/api/quotes/carrier-contract-existence/${quoteId}`);
		return response.data;
	}
}

export default new QuoteService();