import BaseForm from "../../form/BaseForm";
import motivationService from "../../../services/MotivationService";
import {Form, Table} from "react-bootstrap";
import DatePicker from "react-datepicker";
import ru from "date-fns/locale/ru";

class SalaryTable extends BaseForm {
    static FIELD_USER_ID = "userId";
    static FIELD_LAST_NAME = "lastName";
    static FIELD_FIRST_NAME = "firstName";
    static FIELD_MIDDLE_NAME = "middleName";
    static FIELD_SALARY = "salary";
    static POSITION = "position";
    static DIRECTOR_OF_LOGISTIC = "DIRECTOR_OF_LOGISTIC";
    static REGIONAL_HEAD = "REGIONAL_HEAD";
    static START_DATE = "created";
    static DEACTIVATION_DATE = "deactivated";

    static FIELDS = [
        { key: SalaryTable.FIELD_LAST_NAME,  value: "Фамилия" },
        { key: SalaryTable.FIELD_FIRST_NAME, value: "Имя" },
        { key: SalaryTable.FIELD_MIDDLE_NAME, value: "Отчество" },
        { key: SalaryTable.FIELD_SALARY, value: "Оклад" },
        { key: SalaryTable.POSITION, value: "Должность" },
        { key: SalaryTable.START_DATE, value: "Начало работы" },
        { key: SalaryTable.DEACTIVATION_DATE, value: "Дата увольнения" }
    ];

    constructor(props) {
        super(props);

        this.state = {
            salaries: null,
            isEdit: false,
            key: null,
            filter: ''
        }
    }

    componentDidMount() {
        motivationService.get("salary")
            .then(response => {
                this.setState({salaries: response})
            });
    }

    renderHeader() {
        return (
            <thead className="wages-table-thead">
            <div className="table-content-static-head">
                {SalaryTable.FIELDS.map(col =>
                    <td className={col.key === SalaryTable.POSITION ? "container-position-header" : "table-cell-lg"}
                        key={col.key}>
                        {col.value}
                    </td>
                )}
            </div>
            </thead>
        );
    }

    renderBody() {
        return (
            <tbody>
            {this.state.salaries?.map((salary) => (
                    this.filter(salary) &&
                    <div className="table-cell" key={salary.userId}>
                        {SalaryTable.FIELDS.map(col =>
                            <td className={col.key === SalaryTable.POSITION ? "container-position-body" : "table-cell-lg"}
                                key={col.key + salary.userId}>
                                {this.renderCell(col, salary)}
                            </td>
                        )}
                    </div>
                )
            )}
            </tbody>
        );
    }

    renderCell(col, salary) {
        if (col.key === SalaryTable.FIELD_SALARY) {
            return this.editCell(col, salary);
        }
        if (col.key === SalaryTable.POSITION) {
            return this.renderSelectPosition(col, salary);
        }
        if (col.key === SalaryTable.START_DATE) {
            return this.renderDatePicker(salary, SalaryTable.START_DATE);
        }
        if (col.key === SalaryTable.DEACTIVATION_DATE) {
            return this.renderDatePicker(salary, SalaryTable.DEACTIVATION_DATE);
        }

        return salary[col.key];
    }

    renderDatePicker(salary, key) {
        return <DatePicker
            dateFormat="dd.MM.yyyy"
            locale={ru}
            className="motivation-date-picker"
            selected={salary[key] !== null ? new Date(salary[key]) : salary[key]}
            onChange={date => {
                salary[key] = date !== null ? date.toISOString() : null;
                this.setState({salaries: this.state.salaries?.map(s => s.userId === salary.userId ? salary : s)});
                this.saveSalary(salary);
            }}
        />
    }

    renderSelectPosition(col, salary) {
        return <Form.Group style={{marginBottom: 0}}>
            <Form.Control id="position" size="sm" as="select"
                          defaultValue={salary.position}
                          onChange={(e) => {
                              salary.position = e.target.value;
                              this.saveSalary(salary);
                          }}
            >
                <option value="">Не выбранно</option>
                <option value="Директор по логистике">Директор по догистике</option>
                <option value="Региональный руководитель">Региональный руководитель</option>
            </Form.Control>
        </Form.Group>;
    }

    editCell(col, salary) {
        const key = col.key + salary.userId;
        return this.state.isEdit && this.state.key === key
            ? <input
                className="table-content-edit"
                style={{width: "115px"}}
                type="number"
                defaultValue={salary[col.key]}
                onBlur={() => this.openOrCloseFieldEditor(key, false)}
                autoFocus
                onChange={(e) => {
                    salary.salary = e.target.value;
                    this.saveSalary(salary);
                }}
            />
            : <div style={{height: "20px"}}
                   onClick={() => this.openOrCloseFieldEditor(key, true)}>
                {salary[col.key]}
            </div>
    }

    saveSalary(salary) {
        motivationService.post("salary", salary);
    }

    openOrCloseFieldEditor(key, isOpen) {
        this.setState({isEdit: isOpen, key});
    }

    filter(salary) {
        const word = this.state.filter.toLowerCase();
        return [salary.lastName, salary.firstName, salary.middleName]
            .filter(it => !!it)
            .map(it => it.toLowerCase())
            .some(it => it.includes(word));
    }

    render() {
        return (
            <Form>
                <div>
                    <Form.Control type="text"
                                  placeholder="Поиск"
                                  onChange={e => this.setState({filter: e.target.value})}
                    />
                </div>
                <Table striped bordered size="sm">
                    {this.renderHeader()}
                    {this.renderBody()}
                </Table>
            </Form>
        );
    }
}

export default SalaryTable;