import React, {PureComponent} from "react";

import {connect} from "react-redux";
import DataTable from "../table/DataTable";
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import moment from "moment";
import commentReportService from "../../services/CommentReportService";
import Util from "../../Util";
import {securityService} from "../../services/SecurityService";
import StyledEditor from "../StyledEditor";

class CommentConsolidateReport extends PureComponent {

    constructor(props) {
        super(props);
        this.listRef = React.createRef();

        this.state = {
            message: "",
            editCell: {},
            tableHeight: this.getTableHeight(),
            isEditorKeyUpdated: false,
            isUpdateShow: false,
            editComment: {
                contractorId: '',
                createdAt: '',
                departmentId: '',
                id: '',
                message: '',
                period: '',
                updatedAt: ''
            }
        };

        this.handleAddComment = this.handleAddComment.bind(this);
    }

    static HEIGHT = 1700;


    getTableHeight() {
        return window.innerHeight + this.HEIGHT;
    }

    componentWillUnmount() {
        this.addRemoveResizeListener(false);
    }

    componentWillMount() {
        this.addRemoveResizeListener(true);
    }

    addRemoveResizeListener(listen) {
        const addRemove = listen ? document.body.addEventListener : document.body.removeEventListener;
        addRemove('resize', this.resizeListener.bind(this), true);
    }

    resizeListener() {
        this.setState({tableHeight: this.getTableHeight()});
    }


    render() {
        return (
            <> {!this.state.isUpdateShow &&
                <div style={{minWidth: "700px"}}>
                    {this.renderCommentForm()}
                </div>}
                {this.edit()}
            </>
        );
    }

    renderCommentForm() {
        return (
            <Form>
                <div style={{maxHeight: "450px", overflowX: "auto",}} ref={this.listRef}>
                    {this.renderListComments()}
                </div>
                <div>
                    {this.renderCommentEditor()}
                </div>
            </Form>
        );
    }

    edit() {
        return this.state.isUpdateShow && (
            <>
                <div>
                    <h4>Редактирование</h4>
                </div>
                <div>
                    <StyledEditor
                        style={{minWidth: "700px", padding: "2vh"}}
                        value={this.state.editComment.message}
                        onChangeEditor={e => this.setState({message: e.level.content})}/>
                    <Row className={"justify-content-center"}>
                        <Button size="sm" variant="primary" onClick={() => {
                            this.onSave(this.state.editComment.id, this.state.message);
                        }}>
                            Сохранить
                        </Button>
                        &nbsp;
                        &nbsp;
                        <Button size="sm" variant="primary" onClick={() => {
                            this.setState({isUpdateShow: false});
                        }}>
                            Закрыть
                        </Button>
                    </Row>

                </div>
            </>
        );
    }

    onSave(index, message) {
        const comment = message.length === 0 ? this.state.editComment.message : message;
        commentReportService.update(index, Util.formatIsoDate(this.props.date),
            this.props.commercial.userId,
            securityService.getUser().id,
            this.props.department.id,
            this.props.contractor.id,
            comment
        ).then(() => {
            this.setState({message: "", isUpdateShow: !this.state.isUpdateShow});
        });
    }

    renderCommentEditor() {
        return (
            <Row className={"d-flex ml-2 mr-2 mt-5 justify-content-center border-top"}>
                <StyledEditor
                    key={this.state.isEditorKeyUpdated}
                    style={{minWidth: "95%", marginTop: "2vh"}}
                    height={"125px"}
                    title="Добавить комментарий"
                    defaultValue={this.state.message}
                    onChangeEditor={e => this.setState({message: e.level.content})}
                />
                <i className="fa fa-paper-plane mb-3 align-self-end icon-hover icon-comment-send"
                   aria-hidden="true"
                   role={"button"}
                   title={"Отправить"}
                   onClick={this.handleAddComment}
                ></i>
            </Row>
        );
    }

    handleAddComment() {
        if (this.state.message?.length === 0) {
            return;
        }
        commentReportService.save(Util.formatIsoDate(this.props.date),
            this.props.commercial.userId,
            securityService.getUser().id,
            this.props.department.id,
            this.props.contractor.id,
            this.state.message
        ).then(() => {
            this.setState({message: "", isEditorKeyUpdated: !this.state.isEditorKeyUpdated});
        });
    }


    async componentDidMount() {
        const result = await this.fetch();
        this.setState({
            data: result
        });
    }

    setSending(sending) {
        this.setState({sending: sending});
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.data === this.state.data) {
            const result = await this.fetch();
            this.setState({
                data: result
            });
            this.props.setCommentCount(result.length);
        }
    }

    async fetch() {
        return await commentReportService.commentsList(Util.formatIsoDate(this.props.date), this.props.commercial.userId, this.props.department.id, this.props.contractor.id);
    }

    compare(it1, it2) { //ASC
        if (it1 > it2) return 1;
        if (it1 === it2) return 0;
        if (it1 < it2) return -1;
    }

    renderListComments() {
        const comments = this.state.data || [];
        return comments.sort((it1, it2) => this.compare(it1.id, it2.id))
            .map((comment, index) => {
                return (
                    <Card key={comment.id}>
                        <Card.Header as="h6">
                            {this.renderComment(comment, index)}
                        </Card.Header>
                        <Card.Body>
                            <Card.Text>
                                <div dangerouslySetInnerHTML={{__html: comment.message}}></div>
                            </Card.Text>
                        </Card.Body>
                        <Card.Footer>
                            Изменено: {moment(comment.updatedAt).format("DD.MM.yyyy HH:mm")}
                        </Card.Footer>
                    </Card>
                );
            });
    }

    handleRemove(comment) {
        const choice = confirm("Вы уверены, что хотите удалить комментарий?")
        if (choice) {
            this.setSending(true);
            commentReportService.delete(comment.id).then(() => {
                this.setSending(false);
            });
        }
    }

    renderComment(comment, index) {
        const isAdmin = securityService.isAdmin();
        const isOwner = securityService.getUser().id === comment.idUserFrom;
        return (
            <Row>
                <i className="fa fa-user-circle task-comment-icon-margin" aria-hidden="true"></i>&nbsp;
                {comment.fioUserFrom}
                <Col>
                    {isAdmin &&
                        <i className="fa fa-times task-icon-float icon-hover"
                           aria-hidden="true"
                           role={"button"}
                           onClick={() => this.handleRemove(comment, index)}
                        />
                    }
                    {(isAdmin || isOwner) &&
                        <i className="fa fa-pencil-alt task-icon-float icon-hover"
                           aria-hidden="true"
                           role={"button"}
                           onClick={() => this.showEditBlock(true, comment)}
                        >
                            &nbsp;&nbsp;
                        </i>
                    }
                </Col>
            </Row>
        );
    }

    showEditBlock(update, comment) {
        this.setState({isUpdateShow: update, editComment: comment});
    }

}

export default connect(DataTable.mapStateToProps)(CommentConsolidateReport);