import {axios} from '../axios';

class DepartmentService {

	constructor(server) {
		this.server = server;
	}
	static BASE_PATH = "/api/department";

	async list(params) {
		const response = await axios.post(`${DepartmentService.BASE_PATH}/list`, params);
		return response.data;
	}

	async listByCrmUserId(crmUserId) {
		const response = await axios.get(`${DepartmentService.BASE_PATH}/list/${crmUserId}`);
		return response.data;
	}

	async read(id) {
		const response = await axios.get(`${DepartmentService.BASE_PATH}/` + id);
		return response.data;
	}
}

export default new DepartmentService();