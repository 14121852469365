import DataTable from "../../table/DataTable";
import TableCell from "../../table/TableCell";
import {RequestStatus} from "../../../Const";

const guestColumns = () => {
    return [
        { title: "#", field: "id", className: "id overflow", formatter: (value, row) => row.number || value, editable: true, filter: DataTable.STRING_FILTER },
        { title: "Статус", field: "status", filter: DataTable.DICTIONARY_FILTER, optionsType: "REQUEST_STATUS", filteredOptions: [RequestStatus.FREE], multiSelect: true },
        { title: "Дата", field: "createdAt", formatter: TableCell.dtFormatter, filter: DataTable.DATE_RANGE_FILTER },
        { title: "Дата загрузки", field: "loadingDt", filter: DataTable.DATE_RANGE_FILTER, formatter: (value) => TableCell.dtFormatter(value) },
        { title: "Адрес загрузки", field: "loadingAddress", className: "overflow", filter: DataTable.STRING_FILTER },
        { title: "Дата разгрузки", field: "unloadingDt", filter: DataTable.DATE_RANGE_FILTER, formatter: (value) => TableCell.dtFormatter(value) },
        { title: "Адрес разгрузки", field: "unloadingAddress", className: "overflow", filter: DataTable.STRING_FILTER },
        { title: "Тип кузова", field: "carcass", sortable: false, filter: DataTable.DICTIONARY_FILTER, optionsType: "CARCASS" },
        { title: "Тип загрузки", field: "loadingType", sortable: false, filter: DataTable.DICTIONARY_FILTER, optionsType: "LOADING_TYPE" },
        { title: "Тип груза", field: "cargoType", sortable: false, hidden: true, filter: DataTable.DICTIONARY_FILTER, optionsType: "CARGO_TYPE" },
        { title: "Вес", field: "weight", sortable: false },
        { title: "Объем", field: "volume", sortable: false },
        { title: "Темп. режим", field: "temperature", sortable: false }
    ];
};

export {guestColumns};
