import React from "react";
import { createStore } from 'redux';
import update from 'immutability-helper';

import { FORM_SET_VALUE, FORM_SET_ERROR, FORM_SET_ERRORS, FORM_DATA_LOADED, FORM_DATA_AUTOFILLIN, ADD_ALERT, SET_TOAST_OBJ } from "../../actions/types";

class StoreWrapper extends React.PureComponent {
	constructor(props) {
		super(props);

		this.createFormStore();
		this.reducer = this.reducer.bind(this);
	}

	createFormStore() {
		this.store = createStore(this.reducer, {
			errors: {},
			model: {},
			user: this.props.user
		});
	}

	static getValue(model, path) {
		const fields = StoreWrapper.parsePathToArray(path);
		return fields.reduce((obj, field) => field && obj ? obj[field] : undefined, model);
	}

	static parsePathToArray(path) {
		return path.split('.');
	}

	static getUpdateModelObject(action) {
		const fields = StoreWrapper.parsePathToArray(action.name),
			tree = {},
			current = fields.reduce((obj, field) => {
				obj[field] = {};
				return obj[field];
			}, tree);
		current.$set = action.value;
		return tree;
	}

	reducer(state, action) {
		let newState = state;
		if (action.type === FORM_SET_VALUE) {
			newState = update(state, { model: StoreWrapper.getUpdateModelObject(action) });
		} else if (action.type === FORM_SET_ERROR) {
			newState = update(state, { errors: { $set: { [action.name]: action.value } } });
		} else if (action.type === FORM_SET_ERRORS) {
			newState = update(state, { errors: { $set: action.value } });
		} else if (action.type === FORM_DATA_LOADED) {
			if (action.action === "copy") {
				action.value.id = -1;
			}
			newState = update(state, { model: { $set: action.value } });
		} else if (action.type === ADD_ALERT) {
			newState = update(state, {alert: action.alert})
		}
		return newState;
	}
}

export const actionAddAlert = (alert) => {type: ADD_ALERT, alert}

export default StoreWrapper;
