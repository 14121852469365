import { connect } from 'react-redux';
import React from "react";

import { Card, Form, Row, Col, Button } from "react-bootstrap";
import FormGroup from '../../form/FormGroup';
import CitySelect from '../../form/CitySelect';
import AddressSelect from '../../form/AddressSelect';
import ValidationErrors from "../../../validators/ValidationErrors";
import { setValue } from '../../../actions/form';
import RouteTable from '../RouteTable';
import RouteTableButtons from '../RouteTableButtons';
import contractorService from "../../../services/ContractorService";
import AddressModal from "../../control-panel/addresses/AddressModal";
import optionsService from "../../../services/OptionsService";
import {RoutePoint, RequestStatus, Option} from "../../../Const";
import {
	ADMIN,
	COMMERCIAL,
	HEAD_OF_DEPARTMENT,
	LOGISTIC,
	REGIONAL_DIRECTOR,
	securityService
} from "../../../services/SecurityService";
import {userService} from "../../../services/UserService";
import positionHistoryService from "../../../services/PositionHistoryService";
import dateTimeService from "../../../services/DateTimeService";
import moment from "moment";
import Enums from "../../../Enums";

function mapStateToProps(state) {
	return {
		model: state.model,
		errors: state.errors,
		componentSource: "quoteForm"
	};
}

class QuoteForm extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			showAddressModal: false,
			userDepartments: []
		};

		this.onChange = this.onChange.bind(this);
		this.onChangeStatus = this.onChangeStatus.bind(this);
		this.filterDepartments = this.filterDepartments.bind(this);
	}

	showAddressModal(show, index){
		this.setState({addAddRouteIndex: index});
		this.setState({showAddressModal: show});
	}

	onChange(name, value) {
		this.props.store.dispatch(setValue(name, value));
	}
	
	updateCommercialDepManager = async (value) => {
		if (value) {
			const contractor = await contractorService.read(value?.id);
			this.onChange("commercialDepManager", contractor.commercialDepManager);	
		}	  	
	}

	async updateContractorPaymentDelay(contractorId) {
		const contracts = await contractorService.contracts(contractorId);
		if(contracts && contracts.length !== 0) {
			this.onChange("contractorPaymentDelay", contracts[0].paymentDelay)
		}
	}

	async componentDidMount() {
		this.initialize();
		const user = await userService.read(this.props.user.id);
		this.setState({userDepartments: user.departments});

		if (this.props.model.department === null || this.props.model.department === undefined) {
			this.logisticDepManagerDepartmentByPosition(user.id);
		}
	}

	initialize() {
		if (this.getQuoteId()) {
			return;
		}
		this.initDt();
	}

	initDt() {
		const currentDate = moment(new Date());
		const dt = moment
			.parseZone(currentDate)
			.format("YYYY-MM-DDTHH:mm:ss");
		const expireDt = moment
			.parseZone(moment(currentDate).add(1, "hours"))
			.format("YYYY-MM-DDTHH:mm:ss");
		this.onChange("dt", dt);
		this.onChange("expireDt", expireDt);
	}

	initStatus() {
		this.onChange("paymentType", Option.of(Enums.PAYMENT_TYPE.FIXED_BET));
		this.onChange("signingStatus", Option.of(Enums.SIGNING_STATUS.NOT_SIGNED));
	}

	getQuoteId() {
		return this.props.location.state?.id || this.props.model?.id || new URLSearchParams(this.props.location.search).get("id");
	}

	componentDidUpdate(prevProps) {
		if (prevProps.model.contractor?.id !== this.props.model.contractor?.id) {
			this.updateContractorPaymentDelay(this.props.model.contractor.id);
		}
	}

	fetchContractorOptionsByOrg(optionsType, org) {
		return org ? optionsService.loadContractorOptionsByOrganizationId(optionsType, org.id) : Promise.resolve();
	}

	renderClient() {
		const isNotContractorOrganizationSelected = this.props.model.organization === null || this.props.model.organization === undefined
		return (
			<Card>
				<Card.Body>
					<FormGroup placeholder="Выбор организации"
					   title="Организация заказчика"
						name="organization"
						store={this.props.store}
						type="dictionary"
						optionsType="FORWARDER_ORGANIZATION"
						required
					/>
					<FormGroup placeholder="Выбор заказчика"
					    title="Заказчик"
						name="contractor"
						store={this.props.store}
						type="dictionary"
						optionsType="CONTRACTOR_CONTRACT"
					   	fetchOptions={() => this.fetchContractorOptionsByOrg("CONTRACTOR_CONTRACT", this.props.model.organization)}
					 	onChangeHandler={this.updateCommercialDepManager}
                        readOnly={isNotContractorOrganizationSelected}
						required
					/>
				</Card.Body>
				<Card.Footer>Заказчик</Card.Footer>
			</Card>
		);
	}

	accessEdit() {
		return securityService.isLogistic() && this.props.model.status.id !== RequestStatus.ACTUAL.id;
	}

	onChangeStatus(value) {
		if (value.id !== RequestStatus.ACTUAL.id) {
			// The field "number" from QuoteForm equals to "clientQuoteNumber" in FinalQuoteForm
			this.onChange("clientQuoteNumber", this.props.model.number);
			// but field "number" in FinalQuoteForm can be updated through API only
			this.onChange("number", "");

			this.onChange("clientQuoteDt", this.props.model.dt);
		}

		if (value.id === RequestStatus.FREE.id && !this.props.model.freeTo) {
			this.onChange("freeTo", dateTimeService.plusHours(new Date(), 1));
		}
	}

	filterDepartments(option) {
		const departments = this.state.userDepartments;
		return departments.length > 0 ? departments.some(d => d.id === option.id) : true;
	}

	logisticDepManagerDepartmentByPosition(id) {
		positionHistoryService.getCurrentUserDepartment(id)
			.then(department => {
				if (department && department !== "") this.onChange("department", department);
			});
	}

	renderOrder() {
		return (
			<Card>
				<Card.Body>
					<Row>
						<Col>
							<FormGroup title="Номер заявки заказчика"
							    name="number"
							    readOnly={this.accessEdit()}
							    store={this.props.store}
							/>
						</Col>
						<Col>
							<FormGroup title="Дата заявки"
							    name="dt"
							    type="datetime"
							    store={this.props.store}
							    required
							/>
						</Col>
					</Row>
					<Row>
						<Col>							
							<FormGroup title="Ставка стартовая"
								name="priceInitial"
								type="money"
								store={this.props.store}
							/>							
						</Col>
						<Col>							
							<FormGroup title="Действует до"
								name="expireDt"
								type="datetime"
								store={this.props.store}
								required
							/>							
						</Col>
					</Row>
					<Row>
						<Col>							
							<FormGroup title="Ставка конечная"
								name="contractorPrice"
								type="money"
								store={this.props.store}
							/>							
						</Col>
						<Col>							
							{this.props.model.status && <FormGroup placeholder="Статус"
							    title="Статус"
								name="status"
								type="status"
								store={this.props.store}
								readOnly={!this.props.model.expireDt || !this.props.model.id}
							    onChangeHandler={this.onChangeStatus}
							    required
							/>}							
						</Col>
					</Row>
					<Row>
						<Col>
							<FormGroup title="Подразделение"
								name="department"
								type="dictionary"
								optionsType="DEPARTMENT"
								optionsFilter={this.filterDepartments}
								store={this.props.store}
								readOnly={!securityService.anyRoles(this.props.user, ADMIN, HEAD_OF_DEPARTMENT, LOGISTIC, COMMERCIAL)}
							/>
						</Col>
						<Col>							
							<FormGroup placeholder="Источник"
							    title="Источник"
								name="source"
								type="dictionary"
								optionsType="REQUEST_SOURCE"
								store={this.props.store}
								required
							/>
						</Col>
					</Row>
				</Card.Body>
				<Card.Footer>Заявка</Card.Footer>
			</Card>
		);
	}

	renderManagers() {
		const isSuperUser = securityService.anyRoles(this.props.user, ADMIN, HEAD_OF_DEPARTMENT, REGIONAL_DIRECTOR);
		const isCommercial = securityService.isCommercial(this.props.user);
		return (
			<Card>
				<Card.Body>
					<Row>
						<Col>
							<FormGroup title="Менеджер КО"
								name="commercialDepManager"
								type="user"
								role="COMMERCIAL"
								readOnly={!isSuperUser}
								store={this.props.store}
								required
							/>
						</Col>
						<Col>
							<FormGroup title="Менеджер ОЛ"
								name="logisticDepManager"
								type="user"
								role="LOGISTIC"
								store={this.props.store}
							    onChangeHandler={value => this.logisticDepManagerDepartmentByPosition(value.id)}
									   readOnly={!isSuperUser && !isCommercial}
							/>
						</Col>
					</Row>
				</Card.Body>
				<Card.Footer>Менеджеры</Card.Footer>
			</Card>
		);
	}
	
	renderDescription() {
		return (
			<Card>
				<Card.Body>
					<Form.Group>
						<Form.Control className="form-control form-control-sm" as="textarea" rows="3" name="description"
							value={this.props.model.description || ''}
							onChange={e => this.onChange('description', e.target.value)} />
					</Form.Group>
				</Card.Body>
				<Card.Footer>Описание</Card.Footer>
			</Card>
		);
	}

	renderTransportAndCargo() {
		if (this.props.model.multiRoute) {
			return null;
		}
		return (
			<Card>
				<Card.Body>
					<Row>
						<Col>
							<FormGroup title="Тип кузова"
								placeholder="Тип кузова"
								name="routePoints.0.carcass"
								type="dictionary"
								optionsType="CARCASS"
								store={this.props.store}
							    onChangeHandler={(value) => this.onChange("routePoints.1.carcass", value)}
							/>
							
						</Col>
						<Col>
							<FormGroup title="Тип груза" 
								placeholder="Тип груза"
								name="routePoints.0.cargoType"
								type="dictionary"
								optionsType="CARGO_TYPE"
								store={this.props.store}
								onChangeHandler={(value) => this.onChange('routePoints.1.cargoType', value)}
							/>
						</Col>
					</Row>
					<Row>
						<Col>							
							<FormGroup title="Тип загрузки"
								placeholder="Тип загрузки"
								name="routePoints.0.loadingType"
								type="dictionary"
								optionsType="LOADING_TYPE"
								store={this.props.store}
								onChangeHandler={(value) => this.onChange('routePoints.1.loadingType', value)}
							/>							
						</Col>
						<Col>
							<FormGroup title="Температурный режим"
								name="routePoints.0.temperature"
								store={this.props.store}
								onChangeHandler={(value) => this.onChange('routePoints.1.temperature', value)}
							/>							
						</Col>
					</Row>
					<Row>
						<Col>							
							<FormGroup title="Вес груза"
								name="routePoints.0.weight"
								type="decimal"
								store={this.props.store}
								onChangeHandler={(value) => this.onChange('routePoints.1.weight', value)}
							/>
						</Col>
						<Col>							
							<FormGroup title="Объем груза"
								name="routePoints.0.volume"
								type="decimal"
								store={this.props.store}
								onChangeHandler={(value) => this.onChange('routePoints.1.volume', value)}
							/>
						</Col>
					</Row>
				</Card.Body>
				<Card.Footer>Транспорт и груз</Card.Footer>
			</Card>
		);
	}

	renderRoute() {
		const multiRoute = !!this.props.model.multiRoute,
			routeErrors = this.props.errors["route"],
			routePoints = this.props.model.routePoints || [],
			firstPoint = routePoints.filter(rp => rp.id === RoutePoint.POINT_TYPE_LOADING).first() || routePoints.first(),
			firstIndex = firstPoint ? firstPoint.position : 0, 
			lastPoint = routePoints.filter(rp => rp.id === RoutePoint.POINT_TYPE_UNLOADING).last() || routePoints.last(),
			lastIndex = lastPoint ? lastPoint.position : 1;
		const contractor = this.props.model.contractor;
		return (
			// important to not setting any overflow: scroll here
			<Card className={ multiRoute && "route" }>
				<Card.Header>
					{contractor && <AddressModal {...this.props}
												 store={this.props.store}
												 show={this.state.showAddressModal}
												 onChangeRoutePoint={this.onChange}
												 addAddRouteIndex={this.state.addAddRouteIndex || 0}
												 onHide={() => this.showAddressModal(false)}/>}

					<Form.Check inline label="сложный маршрут"
						id="multiRouteComplex" type="switch" name="multiRoute"
						onChange={e => this.onChange('multiRoute', e.target.checked)}
						checked={multiRoute}
					/>

					<span className="add-links">
					{multiRoute &&
						<Button variant="outline-primary" size="sm" onClick = {() => this.showAddressModal(true)}>
							<i className="fas fa-plus mr-1"></i>
							Адрес
						</Button>}
					</span>

					{multiRoute && <RouteTableButtons
						addLoading={this.props.addLoading.bind(this)}
						addUnloading={this.props.addUnloading.bind(this)}
						addCustoms={this.props.addCustoms.bind(this)}
					/>}
					
				</Card.Header>
				<Card.Body>
					{multiRoute && <RouteTable store={this.props.store} contractor={this.props.model.contractor} />}
					{!multiRoute && <div>
						<Row>
							<Col>
								<FormGroup title="Дата загрузки"
									name={`routePoints.${firstIndex}.dt`}
									type="date"
									store={this.props.store}
								    required
								/>
							</Col>
							<Col>
								<FormGroup title="Дата разгрузки"
									name={`routePoints.${lastIndex}.dt`}
									type="date"
									store={this.props.store}
									required
								/>
							</Col>
						</Row>
						<Row>
							<Col>
								<Col>
									<Form.Group>
										<Form.Label>Адрес загрузки</Form.Label>
										{this.props.model.contractor &&
											<a className="fas fa-fw fa-plus-circle address-add" onClick={() => this.showAddressModal(true, firstIndex)}></a>}
										<CitySelect
											name={`routePoints.${firstIndex}.city`}
											contractor={this.props.model.contractor}
											readOnly={!this.props.model.contractor}
											onChange={value => this.onChange(`routePoints.${firstIndex}.city`, value)}
											value={firstPoint?.city} />
										<AddressSelect
											name={`routePoints.${firstIndex}.address`}
											contractor={this.props.model.contractor}
											city={firstPoint?.city}
											readOnly={!firstPoint?.city?.id}
											onChange={value => this.onChange(`routePoints.${firstIndex}.address`, value)}
											value={firstPoint?.address} />
									</Form.Group>
								</Col>
							</Col>
							<Col>
								<Col>
									<Form.Group>
										<Form.Label>Адрес разгрузки</Form.Label>
										{this.props.model.contractor &&
											<a className="fas fa-fw fa-plus-circle address-add" onClick={() => this.showAddressModal(true, lastIndex)}></a>}
										<CitySelect
											name={`routePoints.${lastIndex}.city`}
											contractor={this.props.model.contractor}
											readOnly={!this.props.model.contractor}
											onChange={value => this.onChange(`routePoints.${lastIndex}.city`, value)}
											value={lastPoint?.city} />
										<AddressSelect
											name={`routePoints.${firstIndex}.address`}
											contractor={this.props.model.contractor}
											city={lastPoint?.city}
											readOnly={!lastPoint?.city?.id}
											onChange={value => this.onChange(`routePoints.${lastIndex}.address`, value)}
											value={lastPoint?.address} />
									</Form.Group>
								</Col>
							</Col>
						</Row>
					</div>}
				</Card.Body>
				<Card.Footer>
					<ValidationErrors errors={routeErrors} />
					Маршрут
				</Card.Footer>
			</Card>
		);
	}

	render() {
		return <Row>
			<Col lg={6} md={12}>
				{this.renderClient()}
				<br />
				{this.renderOrder()}
				<br />
				{this.renderManagers()}
			</Col>
			<Col lg={6} md={12}>
				{this.renderRoute()}
				<br />
				{this.renderTransportAndCargo()}
				<br />
				{this.renderDescription()}
			</Col>
		</Row>;
	}

}

export default connect(mapStateToProps)(QuoteForm);
