import React, {Fragment} from "react";
import { connect } from 'react-redux';
import { Button, Row, Col, Table, Form, Container } from "react-bootstrap";
import BaseForm from '../../../form/BaseForm';
import { setValue } from '../../../../actions/form';
import TableCell from "../../../table/TableCell";
import contactService from "../../../../services/ContactService";
import ContactForm from "./ContactForm";
import {securityService} from "../../../../services/SecurityService";
import {EXTERNAL} from "../../../../services/UserService";

function mapStateToProps(state) {
	return {
		contacts: state.model.contacts,
		errors: state.errors,
		contractor: {
			id: state.model.id,
			value: state.model.name,
		},
	};
}

class Contacts extends BaseForm {

	constructor(props) {
		super(props);

		this.state = {
			currentIndex: -1
		}

		this.onChange = this.onChange.bind(this);
		this.onChangeContact = this.onChangeContact.bind(this);
		this.handleAdd = this.handleAdd.bind(this);
		this.handleRemove = this.handleRemove.bind(this);
		this.setCurrentIndex = this.setCurrentIndex.bind(this);
	}

	componentDidMount() {
		this.initCurrentIndex();
	}

	initCurrentIndex() {
		this.setCurrentIndex(this.props.contacts.length ? 0 : -1);
	}

	setCurrentIndex(index) {
		this.setState({currentIndex: index});
	}

	handleAdd() {
		const contacts = this.props.contacts;
		contacts.push({
			[ContactForm.FIELD_FIO]: '',
			[ContactForm.FIELD_POSITION]: '',
			[ContactForm.FIELD_EXECUTIVE]: false,
			[ContactForm.FIELD_DOCUMENT]: '',
			[ContactForm.FIELD_PHONE]: '',
			[ContactForm.FIELD_EMAIL]: '',
			[ContactForm.FIELD_CONTRACTORS]: [this.props.contractor],
			[ContactForm.FIELD_IS_DRIVER]: false,
		});
		this.onChange("contacts", [...contacts]);
		this.setCurrentIndex(contacts.length - 1)
	}

	async handleRemove(e, index) {
		e.stopPropagation();
		const contacts = this.props.contacts;
		if (contacts[index].id) {
			this.setSending(true);
			const deleteResult = await contactService.delete(contacts[index].id, this.props.contractor.id);
			if (deleteResult.success) {
				contacts.removeAt(index);
				this.onChange("contacts", [...contacts]);
				this.initCurrentIndex();
			} else {
				alert(deleteResult.message);
			}
			this.setSending(false);
		}
	}

	onChangeContact(field, value) {
		this.onChange(this.formatFieldName(field), value);
	}

	onChange(field, value) {
		this.props.store.dispatch(setValue(field, value));
	}
	
	formatFieldName(field) {
		return `${this.getFieldNamePrefix()}.${field}`;
	}

	getFieldNamePrefix() {
		return `contacts.${this.state.currentIndex}`;
	}

	getCurrentContact() {
		return this.props.contacts[this.state.currentIndex];
	}

	render() {
		return (
			<Form>
				<Container fluid>
					<Row>
						<Col>
							{this.renderContactForm()}
						</Col>
					</Row>
					<br />
					<Row className="text-right">
						<Col>
							{this.renderContactTable()}
						</Col>
					</Row>
				</Container>
			</Form>
		);
	}

	renderContactForm() {
		const contact = this.getCurrentContact();
		return contact
			?
			<ContactForm key={contact.id}
						 contactModel={contact}
						 location={this.props.location}
						 banEditDriver={true}
						 onChangeContact={this.onChangeContact} />
			: <Fragment/>
	}

	renderContactTable() {
		const contacts = this.props.contacts;
		if (!contacts?.length) {
			return (
				<Row className="text-center">
					<Col className="form-group">
						<div className="mb-2">контактов нет</div>
						<div>
							<Button onClick={this.handleAdd} disabled={!this.checkIfCanAdd()} size="sm" variant="primary">
								<span>добавить</span>
							</Button>
						</div>
					</Col>
				</Row>
			);
		}
		return (
			<Row>
				<Col>
					<Col>
						<Table bordered hover size="sm" className="selectable">
							<thead>
								<tr>
									<th className="col-1">№</th>
									<th className="col-2">ФИО</th>
									<th className="col-1">Должность</th>
									<th className="col-1">Должностное лицо</th>
									<th className="col-2">Действует на основании</th>
									<th className="col-2">Номер телефона</th>
									<th className="col-2">E-mail</th>
									<th className="col-1 text-center">
										<Button onClick={this.handleAdd} size="sm" variant="primary" className="btn-xs">
											<span>добавить</span>
										</Button>
									</th>
								</tr>
							</thead>
							<tbody>
								{contacts.map(this.renderContactTableRow.bind(this))}
							</tbody>
						</Table>
					</Col>
				</Col>
			</Row>
		);
	}

	checkIfCanAdd() {
		const user = securityService.getUser();
		const roles = securityService.getRolesByType(EXTERNAL);
		return !roles.some(role => securityService.hasRole(user, role));
	}

	renderContactTableRow(contact, index) {
		const handler = () => this.setCurrentIndex(index);
		return (
			<tr key={`contact-table-row-${index}`} onClick={() => handler()} onFocus={() => handler()} className={index === this.state.currentIndex ? "active" : null}>
				<td>{(index + 1)}</td>
				<td>{contact.fio}</td>
				<td>{contact.position}</td>
				<td>{TableCell.booleanFormatter(contact.executive)}</td>
				<td>{contact.document}</td>
				<td>{contact.phone}</td>
				<td>{contact.email}</td>
				<td className="col-1 text-center">
					<Button onClick={e => this.handleRemove(e, index)} size="sm" variant="danger" className="btn-xs">
						<span>удалить</span>
					</Button>
				</td>
			</tr>
		);
	}
}

export default connect(mapStateToProps)(Contacts);