import React from "react";
import Toast from 'react-bootstrap/Toast';
import {connect} from "react-redux";
import StoreWrapper from "../components/form/StoreWrapper";
import {setToastObjAC} from "../reducers/toastObj";
import {UNSHOW_TOAST} from "../actions/types";

class LayoutTost extends StoreWrapper {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <LayoutTostInnerConnected {...this.props} store={this.store} />
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {

    };
}

function mapGlobalStateToProps(state) {
    return {
        toastObj: state.toastObj
    };
}

class LayoutTostInner extends React.PureComponent {
    constructor(props) {
        super(props);
        this.onShow = this.onShow.bind(this)
        this.state={
            toastObj: {
                show: false,
                textHeader: "",
                textBody: "",
                className: ""
            }
        }
    }

    componentDidUpdate() {
        this.props.toastObj && this.setState({toastObj: this.props.toastObj})
    }

    onShow() {
        this.props.dispatch({type: UNSHOW_TOAST})
    }

    render() {
        return (
            <div style={{"position": "fixed", "bottom": "50px", "width": "100%"}}>
                <ToastComp
                    show={this.state.toastObj.show}
                    delay={this.state.toastObj.delay}
                    onShow={this.onShow}
                    textHeader={this.state.toastObj.textHeader}
                    textBody={this.state.toastObj.textBody}
                    className={this.state.toastObj.className}
                />
            </div>
        )
    }
}

const ToastComp = (props) => {
    return (
        <Toast style={{maxWidth: "100%", margin: "0 15px"}}
               show={props.show}
               delay={props.delay}
               autohide={!!props.delay}
               onClose={() => {props.onShow()}}
               className={props.className || "bg-warning"}
        >
            <Toast.Header>
                <strong className="me-auto">{props.textHeader}</strong>
            </Toast.Header>
            <Toast.Body style={{backgroundColor: "#f8f9fa"}}>{props.textBody}</Toast.Body>
        </Toast>
    )
}

const LayoutTostInnerConnected = connect(mapStateToProps, {setToastObjAC})(LayoutTostInner)

export default connect(mapGlobalStateToProps)(LayoutTost);