import React from "react";
import smsService from "../../services/SmsService";
import DataTable from "../table/DataTable";
import TableCell from "../table/TableCell";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
class SmsTable extends React.PureComponent {
    fetch(params) {
        return smsService.listOfSms(params);
    }

    render() {
        return (
            <DataTable {...this.props}
                title="Sms-сообщения"
                sort = {{field: "created", dir: "desc"}}
                fetch={this.fetch.bind(this)}
                columns={[
                    { title: "#", field: "id", filter: DataTable.STRING_FILTER },
                    { title: "Телефон", field: "phone", filter: DataTable.STRING_FILTER },
                    { title: "Сообщение", field: "message", filter: DataTable.STRING_FILTER },
                    { title: "Ошибка", field: "errors", filter: DataTable.STRING_FILTER },
                    { title: "Отправитель", field: "crmUser", filter: DataTable.DICTIONARY_FILTER,
                        optionsType: "CRM_USER", activeOnly: false },
                    { title: "Статус Отправки", field: "status", className: "yes-no",
                        formatter: TableCell.booleanFormatter, filter: DataTable.BOOLEAN_FILTER },
                    { title: "Дата Создания", field: "created", formatter: TableCell.dtFormatter,
                        filter: DataTable.INSTANT_DATE_RANGE_FILTER }
                ]}
                />
        );
    }
}

export default withRouter(connect(DataTable.mapStateToProps)(SmsTable));
