import React from "react";
import { connect } from 'react-redux';

import { Card, Col, Row, Table } from "react-bootstrap";

import reportService from '../../services/ReportService';
import DataTable from '../table/DataTable';
import Util from '../../Util';
import './Ssp.css';
import TableCell from "../table/TableCell";
import clsx from "clsx";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import {securityService} from "../../services/SecurityService";
import {RequestStatus} from "../../Const";

const TOTAL_DEPARTMENT = "ИТОГО";
const MARGIN = "Маржа";
const VAT_DEDUCTIBLE = "НДС к вычету";
const PROFIT_NO_VAT = "Рентабельность по без-НДСным Перевозчикам, %";
const PROFIT_NO_VAT_WITH_GSM = "Рентабельность по без НДС c ГСМ";
const FUEL_SUM = "Сумма зачета ГСМ";
const GSM_STRAIT = "Скидка ГСМ";
const NUMBER_QUOTES = "Количество заявок";

class Ssp extends React.PureComponent {
	constructor(props) {
		super(props);

		const now = new Date(),
			dt = new Date(now.getFullYear(), now.getMonth(), 1),
			offset = dt.getTimezoneOffset();

		dt.setTime(dt.getTime() - offset * 60000);
		this.state = {
			displaySubPeriods: false,
			displaySubPeriod: null,
			displayDepartment: null,
			editCell: {},
			tableHeight: this.getTableHeight(),
			dt,
			toggle: false
		};
		this.columns = [
			{ title: "Подразделение", field: "department", className: "department", width: 110,
				onClick: this.setDisplayDepartment.bind(this)},
			{ title: "Менеджер ОЛ", field: "manager", className: "manager", width: 150,
				hidden: () => !this.state.displayDepartment},
			{ title: "Показатель", field: "counter", width: 120 },
			{ title: "План на мес.", field: "plan", width: 90, className: "plan decimal",
				formatter: this.formatPlan.bind(this), onClick: this.setCellEditor.bind(this) },
			{ title: "Факт текущ.", field: "fact", width: 90,
				className: (col, item) => 'decimal ' + this.getItemFactClassName(item),
				formatter: this.formatDecimal },
			{ title: "Тренд", field: "trend", width: 90,
				className: (col, item) => 'decimal ' + this.getItemTrendClassName(item),
				formatter: this.formatDecimal },
			{ title: "План на сегодня.", field: "todayPlan", width: 90, className: "plan-today decimal",
				formatter: this.formatDecimal,
				onClickHeader: () => this.setState({displaySubPeriods: !this.state.displaySubPeriods}) },
			{ title: "Факт на сегодня", field: "todayFact", width: 90,
				className: (col, item) => 'decimal ' + this.getItemFactClassName(item),
				formatter: this.formatDecimal },
			{ title: "% плана", field: "planPct", width: 90,
				className: (col, item) => 'decimal ' + this.getItemFactClassName(item),
				formatter: this.formatDecimal },
			{ title: "% тренда", field: "trendPct", width: 90,
				className: (col, item) => 'decimal ' + this.getItemFactClassName(item),
				formatter: this.formatDecimal },

		];
		this.editCellRef = React.createRef();
	}

	addRemoveResizeListener(listen) {
		const addRemove = listen ? document.body.addEventListener : document.body.removeEventListener;
		addRemove('resize', this.resizeListener.bind(this), true);
	}

	componentWillMount() {
		this.addRemoveResizeListener(true);
	}

	getTableHeight() {
		return window.innerHeight - 170;
	}

	componentWillUnmount() {
		this.addRemoveResizeListener(false);
	}

	resizeListener() {
		this.setState({tableHeight: this.getTableHeight()});
	}

	async fetch(dt) {
		const quoteStatusIdList = RequestStatus.getQuoteStatusIdsByToggleButton(this.state.toggle);
		return await reportService.ssp(Util.formatIsoDate(dt || this.state.dt), quoteStatusIdList);
	}

	getItemFactClassName(item) {
		return item.plan > 0 ? this.getFactClassName(item.plan, item.fact) : "";
	}

	getItemTrendClassName(item) {
		return item.plan > 0
			? this.getFactClassName(item.counter === PROFIT_NO_VAT ? item.recalculatedPlan : item.plan, item.trend)
			: "";
	}

	getSubPeriodFactClassName(item, subPeriod) {
		return (item.userId || item.plan > 0) ? this.getFactClassName(subPeriod.plan, subPeriod.fact) : "";
	}

	getFactClassName(plan, fact) {
		const classResolver = () => {
			if (1 * plan === 0) {
				return 'yellow';
			} else if (fact >= plan) {
				return 'green';
			} else {
				return 'red';
			}
		}
		return `${classResolver()}`;
	}

	getInputClassName(item) {
		return item.counter === PROFIT_NO_VAT ? "double-cell-input" : "cell-input";
	}

	setDisplayDepartment(e, col, item) {
		if (item.department !== TOTAL_DEPARTMENT) {
			const dep = item.department === this.state.displayDepartment ? '' : item.department;
			this.setState({displayDepartment: dep});
		}
	}

	isOpenForEditing(item) {
		return item.department !== TOTAL_DEPARTMENT && (securityService.isAdmin() || item.counter === GSM_STRAIT || item.counter === NUMBER_QUOTES);
	}

	setCellEditor(e, col, item) {
		e.preventDefault();
		if (this.isOpenForEditing(item)) {
			this.setState({
				editCell: {
					counter: item.counter,
					userId: item.userId,
					department: item.department
				}
			});
		}
	}

	completeCellEditor(e, col, item) {
		const value = 1 * this.editCellRef.value;
		let choice = true;
		if (value === 0 && item.manager === '') {
			choice = confirm(`Вы уверены, что хотите сбросить персональные планы менеджеров ОЛ по показателю "${item.counter}"?`);
		}
		if (this.state.editCell.counter && value >= 0 && choice) {
			const quoteStatusIdList = RequestStatus.getQuoteStatusIdsByToggleButton(this.state.toggle);
			reportService.setPlan(item.department, item.userId, Util.formatIsoDate(this.state.dt), item.counter, value, quoteStatusIdList)
				.then(result => this.setState({ data: result.data, editCell: {} }))
				.catch(() => this.setState({ editCell: {} }));
		} else {
			this.setState({ editCell: {} });
		}
	}

	formatPlan(col, item) {
		const value = item[col.field],
			editCell = this.state.editCell;
		if (editCell.counter === item.counter && editCell.userId === item.userId && editCell.department === item.department) {
			return (<input
				className={this.getInputClassName(item)}
				autoFocus={true}
				ref={r => this.editCellRef = r}
			   	defaultValue={value}
			   	onBlur={e => this.completeCellEditor(e, col, item)}
			/>);
		} else {
			return TableCell.decimalFormatter(value);
		}
	}

	formatDecimal(col, item) {
		return TableCell.decimalFormatter(item[col.field]);
	}

	async changeMonth(e, step) {
		e.preventDefault();

		const dt = this.state.dt;
		dt.setMonth(dt.getMonth() + step);

		const date = new Date(dt.getTime()),
			result = await this.fetch(date);
		this.setState({
			dt: date,
			data: result.data
		});
	}

	async componentDidMount() {
		const result = await this.fetch();
		this.setState({ data: result.data });
	}

	async componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevState.toggle !== this.state.toggle) {
			const result = await this.fetch();
			this.setState({ data: result.data });
		}
	}

	formatMonth() {
		const date = Util.formatIsoDate(this.state.dt),
			parts = date.split('-');
		return parts[0] + '-' + parts[1];
	}

	renderTitle() {
		return (
			<Row>
				<Col/>
				<Col>
					<a className="change-month" href="#" onClick={e => this.changeMonth(e,-1)}>&lt;&lt;</a>
					<span>{"ССП " + this.formatMonth()}</span>
					<a className="change-month" href="#" onClick={e => this.changeMonth(e,1)}>&gt;&gt;</a>
				</Col>
				<Col>
					<span>Расчет по заявкам в процессе </span>
					<BootstrapSwitchButton
						toggle={this.state.toggle}
						onstyle="outline-primary"
						offstyle="outline-secondary"
						size="sm"
						onChange={value => this.setState({toggle: value})}
					/>
				</Col>
			</Row>
		);
	}

	renderHeader() {
		const subPeriods = this.state.data?.first()?.subPeriods.map(it => it.period) || [];
		return (
			<tr>
				{this.columns
					.filter(col => !(col.hidden && col.hidden()))
					.map(col =>
					<th key={col.field} style={this.getHeaderStickyStyle(col)} className={col.className}
						onClick={() => col.onClickHeader && col.onClickHeader()}
					>{col.title}</th>
				)}
				{this.state.displaySubPeriods && subPeriods.map(subPeriod =>
					<th key={subPeriod} className='sub-period'
						onClick={() => this.setState(
							{displaySubPeriod: subPeriod === this.state.displaySubPeriod ? '' : subPeriod})}
						colSpan={subPeriod === this.state.displaySubPeriod ? 2 : 1}
					>{new Date(Date.parse(subPeriod)).getDate()}</th>
				)}
			</tr>);
	}

	getCellClassName(col, item) {
		if (col.className) {
			return typeof col.className === "function" ? col.className(col, item) : col.className;
		} else {
			return null;
		}
	}

	getHeaderStickyStyle(col) {
		const style = this.getStickyStyle(col, {});
		if (style.position) {
			return {
				left: style.left,
				zIndex: 3
			};
		}
		return style;
	}

	getStyle(col, item, idx) {
		const style = this.getStickyStyle(col, item);
		return this.applyBorderStyle(style, item, idx);
	}

	applyBorderStyle(style, item, idx) {
		if (idx > 0) {
			const prev = this.state.data[idx-1];
			if (prev.userId !== item.userId || prev.department !== item.department) {
				style.borderTopWidth = 3;
				style.borderTopColor = 'cadetblue';
			}
		}
		return style;
	}

	getStickyStyle(col, item) {
		if (!col.width) {
			return {};
		}
		let widthFromLeft = -1;
		for (let c of this.columns) {
			if (col.field === c.field) {
				break;
			} else {
				const hidden = c.hidden && c.hidden();
				if (!hidden && c.width) {
					widthFromLeft += c.width - 1;
				}
			}
		}

		const cellClassName = this.getCellClassName(col, item);
		let  background = 'white';
		if (cellClassName?.includes('decimal')) {
			const className = this.getItemTrendClassName(item);
			if (className === 'red') background = 'palevioletred';
			if (className === 'green') background = 'darkseagreen';
		}

		return {
			position: 'sticky',
			left: `${widthFromLeft}px`,
			maxWidth: `${col.width}px`,
			minWidth: `${col.width}px`,
			background: background,
			zIndex: 2
		};
	}

	renderBody() {
		if (!this.state.data) {
			return <></>;
		}
		return this.state.data.map((item, idx) => {
				const isDepartmentSelected = item.department === this.state.displayDepartment,
					display = (isDepartmentSelected && item.userId) || (!isDepartmentSelected && !item.userId);
				const hiddenCounter = item.counter !== PROFIT_NO_VAT_WITH_GSM;
				return display ? <tr key={idx}>
					{this.columns
						.filter(col => !(col.hidden && col.hidden()))
						.map(col => { return hiddenCounter && this.renderCell(col, item, idx) }
					)}
					{this.state.displaySubPeriods && hiddenCounter && item.subPeriods.map(subPeriod =>
						<React.Fragment key={idx + subPeriod.period}>
							{subPeriod.period === this.state.displaySubPeriod &&
								<td className={clsx('decimal')} style={this.applyBorderStyle({}, item, idx)}>
									{TableCell.decimalFormatter(subPeriod.plan > 0 ? subPeriod.plan : 0)}
								</td>}
							<td className={clsx('decimal', this.getSubPeriodFactClassName(item, subPeriod))}
									style={this.applyBorderStyle({}, item, idx)}>
								{TableCell.decimalFormatter(subPeriod.fact > 0 ? subPeriod.fact : 0)}
							</td>
						</React.Fragment>
					)}
				</tr> : null;
			}
		);
	}

	renderCell(col, item, idx) {
		return col.field === 'plan' && item.counter === PROFIT_NO_VAT
			? (
				<div className={"grid"}>
					<table>
						<tr>
							<td key={idx + col.field}
								className={clsx(this.getCellClassName(col, item)) + " double-cell"}
								onClick={e => col.onClick && col.onClick(e, col, item)}>
								{col.formatter ? col.formatter(col, item) : item[col.field]}
							</td>
							<td style={{background: this.getStickyStyle(col, item).background}}
								className={"double-cell"}>
								{TableCell.decimalFormatter(item.recalculatedPlan)}
							</td>
						</tr>
					</table>
				</div>)
			: (<td key={idx + col.field} style={this.getStyle(col, item, idx)}
				   className={clsx(this.getCellClassName(col, item))}
				   onClick={e => col.onClick && col.onClick(e, col, item)}>
				{col.formatter ? col.formatter(col, item) : item[col.field]}
			</td>);
	}

	render() {
		return (<>
			<Card className='data-table ssp'>
				<Card.Header>
					<Row>
						<Col className="title text-center">
							{this.renderTitle()}
						</Col>
					</Row>
				</Card.Header>
				<Card.Body style={{overflow: "auto", maxHeight: this.state.tableHeight}}>
					<Table striped bordered hover size="sm">
						<thead>{this.renderHeader()}</thead>
						<tbody>{this.renderBody()}</tbody>
					</Table>
				</Card.Body>
			</Card>
		</>);
	}
}

export default connect(DataTable.mapStateToProps)(Ssp);
