import {axios} from '../axios';

class MotivationService {
    static BASE_PATH = "/api/control-panel/motivation";

    async post(path, data) {
        const response = await axios.post(`${MotivationService.BASE_PATH}/${path}`, data);
        return response.data;
    }

    async get(path) {
        const response = await axios.get(`${MotivationService.BASE_PATH}/${path}`);
        return response.data;
    }

    async downloadFile(fileName, path, data) {
        axios({
            url: `${MotivationService.BASE_PATH}/${path}`,
            method: 'POST',
            responseType: 'blob',
            data: data
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
        });
    }
}

export default new MotivationService();