import React from "react";
import exportImportService from "../../../services/ExportImportService";
import {SLIntegrationInner} from "./SLIntegration";
import DatatablaXlsExport from "./DatatablaXlsExport";
import Operations from "./Operations";

class ExportImport extends React.PureComponent {

	static get DATATABLE_XLS_EXPORT() { return 'datatable-xls-export'; }
	static get SL_INTEGRATION() { return 'sl-integration'; }

	constructor(props) {
		super(props);
		this.state = {
			selectedFile: null,
			progress: 0,
			isUploading: false,
			isBusy: false,
			isExport: false,
			currentDirection: null,
			latestOperations: null,
		};
		this.pollIntervalIdle = 3000;
		this.pollIntervalBusy = 1000;
		this.updateProgress = this.updateProgress.bind(this);
		this.handleImport = this.handleImport.bind(this);
		this.handleExport = this.handleExport.bind(this);
		this.pollStatus = this.pollStatus.bind(this);
		this.stopPollingStatus = this.stopPollingStatus.bind(this);
		this.saveSchedule = this.saveSchedule.bind(this);
	}

	updateProgress(value) {
		this.setState({
			progress: value,
		});
	}

	componentDidMount() {
		if (!this.props.noInitialPolling) {
			this.pollStatus();
		}
	}

	getIsBusy() {
		return this.state.isBusy || this.state.isUploading;
	}

	getPollInterval() {
		return this.getIsBusy() ? this.pollIntervalBusy : this.pollIntervalIdle;
	}

	async handleSubmission(callback) {
		await this.pollStatus();
		if (!this.getIsBusy() && callback) {
			await callback();
			await this.pollStatus();
		}
	}

	async handleImport(type, file) {
		if (type === null || file === null) {
			return;
		}
		await this.handleSubmission(async () => {
			this.setState({
				isUploading: true,
			});
			if (this.props.handlerImport) {
				await this.props.handlerImport(type, file, this.getProgressHandler());
			}
		});
		this.pollStatus(() => this.setState({
			isUploading: false,
			selectedFile: null,
			progress: 0,
		}));
	}

	componentWillUnmount() {
		this.stopPollingStatus();
	}

	async checkStatus(callback) {
		const status = await exportImportService.status(!this.getIsBusy(), this.getDirections());
		let isUploading = this.state.isUploading;
		if (status.busy === true) {
			isUploading = false;
		}
		this.setState({
			isBusy: isUploading || status.busy,
			isUploading: isUploading,
			isExport: status.export,
			currentDirection: status.direction,
			progress: this.getPercent(status.currentRow, status.totalRows),
			fileName: status.currentFileName,
			latestOperations: status.latestOperations,
			nextExportDelay: status.nextExportDelay,
		}, () => callback && callback(status.busy));
	}

	async pollStatus(callback, interval) {
		if (this.pollTimeout) {
			clearTimeout(this.pollTimeout);
		}
		await this.checkStatus((isBusy) => {
			if (callback) {
				callback(isBusy);
			}
			this.pollTimeout = setTimeout(() => {
				this.pollStatus();
			}, this.getPollInterval());
		});
	}

	stopPollingStatus() {
		clearTimeout(this.pollTimeout);
	}

	getProgressHandler() {
		return (progressEvent) => {
			const { loaded, total } = progressEvent;
			const percent = this.getPercent(loaded, total);
			this.setState({
				progress: percent,
			});
		}
	}

	getPercent(current, total) {
		return Math.floor(current / total * 100);
		this.props.handlerExport();
	}

	getDirections() {
		switch (this.props.type) {
			case ExportImport.SL_INTEGRATION:
				return [Operations.EXPORT, Operations.IMPORT];
			case ExportImport.DATATABLE_XLS_EXPORT:
				return [Operations.EXPORT_XLS];
		}
		return [];
	}

	async handleExport() {
		if(!this.props.noInitialPolling) {
			await this.handleSubmission(async () => {
				this.setState({
					isBusy: true,
					isExport: true,
				});
				if (this.props.handlerExport) {
					await this.props.handlerExport();
				}
			});
			this.pollStatus((isBusy) => this.setState({
				isBusy: isBusy,
				isExport: false,
			}));
		} else await this.props.handlerExport();
	}

	isDifferentOperationProgress() {
		return this.getDirections().indexOf(this.state.currentDirection) === -1;
	}

	getProgressTitle() {
		return (
			<React.Fragment>
				{this.isDifferentOperationProgress() && <div className={"text-warning"}>Ожидается окончание другой операции</div>}
				{this.state.isUploading && <div>Отправка файла</div>}
				{!this.state.fileName && <div>{`Подготовка к ${this.state.isExport ? "экспорту" : "импорту"}`}</div>}
				{this.state.fileName && <div>{`${this.state.isExport ? "Экспорт" : "Импорт"} ${this.state.fileName}`}</div>}
			</React.Fragment>
		);
	}

	getProps() {
		return {
			...this.state,
			handleExport: this.handleExport,
			handleImport: this.handleImport,
			progressTitle: this.getProgressTitle(),
			pollStatus: this.pollStatus,
			stopPollingStatus: this.stopPollingStatus,
			onChangeXlsExportInterval: e => this.props.onChangeXlsExportInterval(e),
			getQuoteStatuses: this.props.getQuoteStatuses,
			onChangeXlsExportStatuses: e => this.props.onChangeXlsExportStatuses(e),
			xlsExportStatuses: this.props.xlsExportStatuses
		};
	}

	saveSchedule(time) {
		if (!time) {
			return;
		}
		exportImportService.saveSchedule(time).then(
			() => this.pollStatus()
		);
	}

	render() {
		const props = this.getProps();
		switch (this.props.type) {
			case ExportImport.SL_INTEGRATION:
				return (
					<SLIntegrationInner {...props} handleScheduleSave={this.saveSchedule} />
				);
			case ExportImport.DATATABLE_XLS_EXPORT:
				return (
					<DatatablaXlsExport isDownloadXls={this.props.isDownloadXls} {...props} />
				);
		}
	}

}
export default ExportImport;