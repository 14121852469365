import {axios} from '../axios';

class ConfigService {

	async load() {
		const response = await axios.get(`/api/config`);
		return response.data;
	}
}

export default new ConfigService();