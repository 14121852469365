import React from "react";
import { Card, Row, Col, ProgressBar } from "react-bootstrap";
import exportImportService from "../../../services/ExportImportService";
import SLExport from "./SLExport";
import SLImport from "./SLImport";
import Operations from "./Operations";
import ExportImport from "./ExportImport";

class SLIntegration extends React.PureComponent {

	static SL_IMPORT_FILE_EXTENSION_PATTERN = /\.xlsx$/g;
	static IMPORT_TYPE_QUOTE = "SL_QUOTE";
	static IMPORT_TYPE_QUOTE_INITIAL = "SL_QUOTE_INITIAL";
	static IMPORT_TYPE_DRIVER = "DRIVER";
	static IMPORT_TYPE_CARRIER = "CARRIER";
	static IMPORT_TYPE_CLIENT = "CLIENT";

	render() {
		return (
			<ExportImport
				type={ExportImport.SL_INTEGRATION}
				handlerImport={exportImportService.slImportFile}
				handlerExport={exportImportService.slQuoteExport}
			/>
		);
	}

}
class SLIntegrationInner extends React.PureComponent {

	render() {
		return (
			<Row>
				<Col>
					{this.props.isBusy && this.renderProgress()}
					{!this.props.isBusy && this.renderSelectors()}
				</Col>
				<Col>
					<SLExport handleExport={this.props.handleExport}
						isBusy={this.props.isBusy}
						latestOperations={this.props.latestOperations}
						nextExportDelay={this.props.nextExportDelay}
						handleScheduleSave={this.props.handleScheduleSave} />
					{!this.props.isBusy && this.props.latestOperations != null &&
						<React.Fragment>
							<Operations
								{...this.props}
								title={"Файлы экспорта:"}
								direction={Operations.EXPORT} />
							<Operations
								{...this.props}
								title={"Журналы импорта заявок:"}
								direction={Operations.IMPORT} />
						</React.Fragment>
					}
				</Col>
			</Row>
		);
	}

	renderSelectors() {
		return (
			<React.Fragment>
				<SLImport
					title={"Заявки (первоначальный)"}
					handleImport={this.props.handleImport}
					isBusy={this.props.isBusy}
					updateProgress={this.props.updateProgress}
					type={SLIntegration.IMPORT_TYPE_QUOTE_INITIAL}></SLImport>
				<SLImport
					title={"Заявки"}
					handleImport={this.props.handleImport}
					isBusy={this.props.isBusy}
					updateProgress={this.props.updateProgress}
					type={SLIntegration.IMPORT_TYPE_QUOTE}></SLImport>
				<SLImport
					title={"Водители"}
					handleImport={this.props.handleImport}
					isBusy={this.props.isBusy}
					updateProgress={this.props.updateProgress}
					type={SLIntegration.IMPORT_TYPE_DRIVER}></SLImport>
				<SLImport
					title={"Контрагенты"}
					handleImport={this.props.handleImport}
					isBusy={this.props.isBusy}
					updateProgress={this.props.updateProgress}
					type={SLIntegration.IMPORT_TYPE_CARRIER}></SLImport>
			</React.Fragment>
		);
	}

	renderProgress() {
		return (
			<Row className="import-card">
				<Col>
					<Card>
						<Card.Body>
							<Row className="mb-3">
								<Col className="col-auto">
									{this.props.progressTitle}
								</Col>
							</Row>
							<Row>
								<Col>
									<ProgressBar now={this.props.progress} />
								</Col>
							</Row>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		);
	}

}
export default SLIntegration;
export { SLIntegrationInner };