import {axios} from '../axios';

class ContractorService {

	async read(id) {
		const response = await axios.get(`/api/control-panel/contractors/${id}`);
		return response.data;
	}

	async isInnKppUnique(id, inn, kpp) {
		const response = await axios.post(`/api/control-panel/contractors/inn-kpp/unique`, {id, inn, kpp});
		return response.data;
	}

	async checkBlacklistContractor(id, inn, kpp) {
		const response = await axios.post(`/api/control-panel/contractors/check-blacklist`, {id, inn, kpp});
		return response.data;
	}

	async checkUserContractors(contractorId) {
		const response = await axios.get(`/api/control-panel/contractors/check/${contractorId}`);
		return response.data;
	}
	
	async list(params) {
		const response = await axios.post("/api/control-panel/contractors/list", params);
		return response.data;
	}
	
	async save(contractor) {
		const call = contractor.id ? axios.put : axios.post,
 			response = await call("/api/control-panel/contractors", contractor);
 		return response.data;
	}
	
	async saveTradingSettings(contractor) {
		const response = await axios.put("/api/control-panel/contractors/trading/settings", contractor);
 		return response.data;
	}

	async quotes(params, contractorId) {
		const response = await axios.post(`/api/control-panel/contractors/${contractorId}/quotes`, params);
		return response.data;
	}

	async contracts(contractorId) {
		const response = await axios.get(`/api/control-panel/contractors/${contractorId}/contracts`);
		return response.data;
	}

	async mainContractExists(quoteId, contractorId, carrierId) {
		const response = await axios.get(`/api/control-panel/contractors/mainContractExists/${quoteId}/${contractorId}/${carrierId}`);
		return response.data;
	}

	async copyContractorAddress(contractorToCopy, targetContractor) {
		const response = await axios.post(`/api/control-panel/contractors/copy-address`, {contractorToCopy, targetContractor});
		return response.data;
	}

	async getContacts(id) {
		const response = await axios.get(`/api/control-panel/contractors/${id}/contacts`);
		return response.data;
	}

	async optionsByUser(userId) {
		const response = await axios.get(`/api/control-panel/contractors/options/by-user/${userId}`);
		return response.data;
	}
}

export default new ContractorService();