import * as actionTypes from './socketActionTypes';

export const setSocket = (value) => {
    return {
        type: actionTypes.SET_SOCKET,
        payload: value
    }
}

export const setErrors = (value) => {
    return {
        type: actionTypes.SET_ERRORS,
        payload: value
    }
}