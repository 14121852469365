import React from "react";
import {connect} from 'react-redux';
import './reportStyle.css';
import Util from "../../Util";
import DataTable from "../table/DataTable";
import planSalesService from "../../services/PlanSalesService";
import {Button, Col, Collapse, Container, Dropdown, Row, Table} from "react-bootstrap";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import {ADMIN, COMMERCIAL, HEAD_OF_DEPARTMENT, REGIONAL_DIRECTOR, securityService, SUPERVISOR} from "../../services/SecurityService";
import {userService} from "../../services/UserService";
class PlanSales extends React.PureComponent {
    constructor(props) {

        super(props);

        const now = new Date(),
            dt = new Date(now.getFullYear(), now.getMonth(), 1),
            offset = dt.getTimezoneOffset();
        dt.setTime(dt.getTime() - offset * 60000);


        this.state = {
            tableHeight: this.getTableHeight(),
            dt,
            toggle: false,
            toggleCheck: false,
            sending: false,
            checkPlan: false,
        };

        this.changeToggle = this.changeToggle.bind(this);
    }

    static refreshData = false;

    setSending(sending) {
        this.setState({sending: sending});
    }

    static get REQUEST_STATUS_APPROVED() {
        return 2
    };

    static get REQUEST_STATUS_UNLOADED() {
        return 8
    };

    static get REQUEST_STATUS_LOADED() {
        return 9
    };

    static get REQUEST_STATUS_ON_LOADING() {
        return 10
    };

    static get REQUEST_STATUS_ON_WAY() {
        return 11
    };

    static get REQUEST_STATUS_ON_UNLOADING() {
        return 12
    };

    getQuoteStatusIdList(toggleCheckProps) {
        return toggleCheckProps
            ? [PlanSales.REQUEST_STATUS_APPROVED,
                PlanSales.REQUEST_STATUS_UNLOADED,
                PlanSales.REQUEST_STATUS_LOADED,
                PlanSales.REQUEST_STATUS_ON_LOADING,
                PlanSales.REQUEST_STATUS_ON_WAY,
                PlanSales.REQUEST_STATUS_ON_UNLOADING]
            : [PlanSales.REQUEST_STATUS_UNLOADED];
    }

    changeToggle(toggle) {
        this.setState({toggle});
    }

    checkPlanToggle(checkPlan) {
        this.setState({checkPlan: checkPlan});
    }

    async fetch(dt) {
        const quoteStatusIdList = this.getQuoteStatusIdList(this.state.toggleCheck);
        return await planSalesService.planSales(Util.formatIsoDate(this.state.dt || dt), quoteStatusIdList, this.state.checkPlan).catch(() => {
                dt.setMonth(dt.getMonth() - 1)
                return planSalesService.planSales(Util.formatIsoDate(dt), quoteStatusIdList, this.state.checkPlan)
            }
        );
    }

    async getUserById(id) {
        return await userService.read(id);
    }

    async changeYear(e, step) {
        e.preventDefault();
        const dt = this.state.dt;
        dt.setMonth(dt.getMonth() + step);

        const date = new Date(dt.getTime()),
            result = await this.fetch(date);
        const user = await this.getUserById(securityService.getUser().id);
        this.setState({
            dt: date,
            data: result.data,
            monthlyRevenueOfDepartments: result.monthlyRevenueOfDepartments,
            remainderOfRevenueByLogistic: result.remainderOfRevenueByLogistic,
            remainderOfRevenueByContractor: result.remainderOfRevenueByContractor,
            remainderOfRevenueByDepartment: result.remainderOfRevenueByDepartment,
            factAllContractors: result.factAllContractors,
            remainderOfRevenue: result.remainderOfRevenue,
            statusPlan: result.statusPlan,
            counterDepartment: user.departments.length
        });
    }

    formatYear() {
        const date = Util.formatIsoDate(this.state.dt),
            parts = date.split('-');
        return parts[0] + '-' + parts[1];
    }

    getTableHeight() {
        return window.innerHeight + 1700;
    }

    componentWillUnmount() {
        this.addRemoveResizeListener(false);
    }

    componentWillMount() {
        this.addRemoveResizeListener(true);
    }

    addRemoveResizeListener(listen) {
        const addRemove = listen ? document.body.addEventListener : document.body.removeEventListener;
        addRemove('resize', this.resizeListener.bind(this), true);
    }

    resizeListener() {
        this.setState({tableHeight: this.getTableHeight()});
    }

    async componentDidMount() {
        const result = await this.fetch();
        const user = await this.getUserById(securityService.getUser().id);
        this.setState({
            data: result.data,
            monthlyRevenueOfDepartments: result.monthlyRevenueOfDepartments,
            remainderOfRevenueByLogistic: result.remainderOfRevenueByLogistic,
            remainderOfRevenueByContractor: result.remainderOfRevenueByContractor,
            remainderOfRevenueByDepartment: result.remainderOfRevenueByDepartment,
            factAllContractors: result.factAllContractors,
            statusPlan: result.statusPlan,
            counterDepartment: user.departments.length
        });
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if ((prevState.data === this.state.data || PlanSales.refreshData === true) || prevState.toggleCheck !== this.state.toggleCheck
            || prevState.statusPlan !== this.state.statusPlan) {
            const result = await this.fetch();
            const user = await this.getUserById(securityService.getUser().id);
            this.setState({
                data: result.data,
                monthlyRevenueOfDepartments: result.monthlyRevenueOfDepartments,
                remainderOfRevenueByLogistic: result.remainderOfRevenueByLogistic,
                remainderOfRevenueByContractor: result.remainderOfRevenueByContractor,
                remainderOfRevenueByDepartment: result.remainderOfRevenueByDepartment,
                factAllContractors: result.factAllContractors,
                statusPlan: result.statusPlan,
                counterDepartment: user.departments.length
            });
            this.changeToggle(false)
            PlanSales.refreshData = false;
        }
    }

    render() {
        return (
            <div className="tableFixHead">
                <Container fluid>
                    {this.renderTitle()}
                </Container>
                <table className="tableSsp">
                    <thead className={"thead"}>
                    {this.renderBodyTitle()}
                    </thead>
                    <tbody>
                    {this.renderBody()}
                    </tbody>
                </table>
            </div>
        );
    }

    renderBody() {
        return (
            <>
                {this.state.data && this.state.data.map((row) => (
                    <RowsContractor key={row.contractor.contractorId}
                                    contractor={row.contractor}
                                    departments={row.departmentByContractors}
                                    changeToggle={this.changeToggle}
                                    date={this.state.dt}
                                    getBackgroundColor={this.getBackgroundColor}
                                    getQuoteStatusIdList={this.getQuoteStatusIdList}
                                    toggleCheck={this.state.toggleCheck}
                                    statusPlan={this.state.statusPlan}
                                    counterDepartment={this.state.counterDepartment}
                                    checkPlan={this.state.checkPlan}/>
                ))}
            </>
        );
    }

    renderBodyTitle() {
        return (
            <>
                <td colSpan="9" style={{backgroundColor: '#f8f9fc'}}>
                    <Col sm>
                        <Table striped bordered hover className={"tableCon"}>
                            <thead>
                            <td className={"tdCell tdAll"}>
                                <span
                                    className={"font-p"}>{'Выручка:' + Number(this.state.monthlyRevenueOfDepartments).toLocaleString('ru')}</span>
                            </td>
                            <td className={"tdCell tdAll"}>
                                <span className={"font-p"}>{'Факт:' + Number(this.state.factAllContractors).toLocaleString('ru')}</span>
                            </td>
                            {(securityService.anyRoles(this.state.crmUser, REGIONAL_DIRECTOR, ADMIN, SUPERVISOR, HEAD_OF_DEPARTMENT)
                                    && this.state.counterDepartment === 0) &&
                                <td className={"tdCell tdAll"}
                                    title="Остаток выручки, который необходимо распределить по контрагентам"
                                    style={{backgroundColor: this.getBackgroundColor(this.state.remainderOfRevenueByContractor)}}>
                                    <span className={"font-p"}>{'Остаток контрагентов:' + Number(this.state.remainderOfRevenueByContractor).toLocaleString('ru')}</span>
                                </td>}
                            {(securityService.anyRoles(this.state.crmUser, REGIONAL_DIRECTOR, ADMIN, SUPERVISOR, HEAD_OF_DEPARTMENT, COMMERCIAL)
                                    && this.state.counterDepartment === 0) &&
                                <td className={"tdCell tdAll"}
                                    title="Остаток выручки, который необходимо распределить по подразделениям"
                                    style={{backgroundColor: this.getBackgroundColor(this.state.remainderOfRevenueByDepartment)}}>
                                   <span
                                       className={"font-p"}>{'Остаток по подразделениям:' + Number(this.state.remainderOfRevenueByDepartment).toLocaleString('ru')}</span>
                                </td>
                            }
                            {!securityService.isCommercial(this.state.crmUser) &&
                                <td className={"tdCell tdAll"}
                                    title="Остаток выручки, который необходимо распределить по логистам"
                                    style={{backgroundColor: this.getBackgroundColor(this.state.remainderOfRevenueByLogistic)}}>
                                    <span
                                        className={"font-p"}>{'Остаток по логистам:' + Number(this.state.remainderOfRevenueByLogistic).toLocaleString('ru')}</span>
                                </td>
                            }
                            </thead>
                        </Table>
                    </Col>
                </td>
                <tr style={{position: "relative", top: "-1px", backgroundColor: "#d3d3d3"}}>
                    <td className={"tdTitle tdAll"}>
                        <span className={"font-title"} style={{marginLeft: "30px"}}>Контрагенты</span>
                    </td>
                    <td className={"tdCell tdAll"}
                        title={"Выручка за аналогичный период прошлого года, выручка за прошлый месяц."}>
                        <span className={"font-p"}>Выручка за период прошлого года</span>
                    </td>
                    <td className={"tdCell tdAll"} title={"Выручка за аналогичный период прошлого месяца."}>
                        <span className={"font-p"}>Выручка за прошлый месяц</span>
                    </td>
                    <td className={"tdCell tdAll"}>
                        <span className={"font-p"}>Остаток по подразделению из ССП</span>
                    </td>
                    <td className={"tdCell tdAll"}
                        title={"Остаток по контрагенту, который необходимо распределить по подразделениям."}>
                        <span className={"font-p"}>Нераспределенный остаток</span>
                    </td>
                    <td className={"tdCell tdAll"}>
                        <span className={"font-p"}>Выручка</span>
                    </td>
                    <td className={"tdCell tdAll"}>
                        <span className={"font-p"}>Рентабельность</span>
                    </td>
                    <td className={"tdCell tdAll"}>
                        <span className={"font-p"}>Маржа</span>
                    </td>
                    <td className={"tdCell tdAll"}>
                        <span className={"font-p"}>Факт</span>
                    </td>
                </tr>
            </>
        );
    }

    fillSsp(dt) {
        this.setSending(true)
        planSalesService.setFutureSsp((dt + "-01"))
            .then(() => this.setSending(false));
    }

    fillFuturePlanSales(dt) {
        this.setSending(true);
        planSalesService.setFuturePlanSales((dt + "-01"))
            .then((r) => {
                this.setSending(false)
            });
    }

    updatePlanSales(dt) {
        const quoteStatusIdList = this.getQuoteStatusIdList(this.state.toggleCheck);
        this.setSending(true);
        planSalesService.updatePlanSales((dt + "-01"), quoteStatusIdList, this.state.checkPlan)
            .then(() => {
                this.setSending(false);
                PlanSales.refreshData = true;
            });
    }

    setStatus(dt, statusPlan) {
        const quoteStatusIdList = this.getQuoteStatusIdList(this.state.toggleCheck);
        this.setSending(true)
        planSalesService.setStatus((dt + "-01"), statusPlan, quoteStatusIdList)
            .then(() => this.setSending(false));
    }

    statusPlanSales(dt) {
        return this.state.statusPlan ?
            <Button variant="success" title={"Закрыть план продаж"} size="sm"
                    onClick={() => {
                        this.setStatus(dt, !this.state.statusPlan)
                    }}>
                {this.state.sending && (<span className="spinner-border spinner-border-sm"></span>)}
                Закрыть </Button>
            :
            <Button variant="danger" title={"Открыть план продаж"} size="sm"
                    onClick={() => {
                        this.setStatus(dt, !this.state.statusPlan)
                    }}>
                {this.state.sending && (<span className="spinner-border spinner-border-sm"></span>)}
                Открыть </Button>
    }

    getAccessForButton() {
        return securityService.anyRoles(this.state.crmUser, ADMIN, HEAD_OF_DEPARTMENT, REGIONAL_DIRECTOR) && this.state.counterDepartment === 0;
    }

    renderTitle() {
        return (
            <Row>
                <Col className="mx-auto my-4 text-left" sm>
                    <span>Расчет по заявкам в процессе </span>
                    <BootstrapSwitchButton
                        toggleCheck={this.state.toggleCheck}
                        onstyle="outline-primary"
                        offstyle="outline-secondary"
                        size="sm"
                        onChange={value => this.setState({toggleCheck: value})}
                    />
                </Col>
                <Col className="mx-auto my-4 text-center" sm>
                    <a href="#" onClick={e => this.changeYear(e, -1)}>
                        <i className="fa fa-chevron-left" aria-hidden="true"></i>
                    </a>
                    <span className={"font-title"}>{"  " + " План продаж " + this.formatYear() + "  "}</span>
                    <a href="#" onClick={e => this.changeYear(e, 1)}>
                        <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </a>
                </Col>
                <Col className="mx-auto my-4 text-right" sm>
                    <Dropdown>
                        <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                            Панель управления
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item> <Button variant="secondary" title={"Проверка заполнения"} size="sm"
                                                    onClick={() => {
                                                        this.checkPlanToggle(!this.state.checkPlan)
                                                    }}>
                                Проверка </Button></Dropdown.Item>
                            {this.getAccessForButton() &&
                                <Dropdown.Item> <Button variant="secondary" title={"Обновить план продаж"} size="sm"
                                                        onClick={() => {
                                                            this.updatePlanSales(this.formatYear())
                                                        }}>
                                    {this.state.sending && (
                                        <span className="spinner-border spinner-border-sm"></span>)}
                                    Обновить </Button></Dropdown.Item>}
                            {this.getAccessForButton() &&
                                <Dropdown.Item>{this.statusPlanSales(this.formatYear())}</Dropdown.Item>}
                            {this.getAccessForButton() && <Dropdown.Item><Button variant="secondary"
                                                                                 title={"Копировать план продаж на следующий месяц"}
                                                                                 size="sm"
                                                                                 onClick={() => {
                                                                                     this.fillFuturePlanSales(this.formatYear())
                                                                                 }}>
                                {this.state.sending && (<span className="spinner-border spinner-border-sm"></span>)}
                                Копировать </Button></Dropdown.Item>}
                            {this.getAccessForButton() &&
                                <Dropdown.Item><Button variant="secondary"
                                                       title={"Заполнить планы логистов и подразделений в ССП"} size="sm"
                                                       onClick={() => {
                                                           this.fillSsp(this.formatYear())
                                                       }}>
                                    {this.state.sending && (<span className="spinner-border spinner-border-sm"></span>)}Заполнить
                                    ССП</Button></Dropdown.Item>}

                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
            </Row>
        );
    }

    getBackgroundColor(field, defaultColor = "white") {
        if (field > 0) {
            return '#ceffbc';
        }
        if (field < 0) {
            return '#FF9999';
        }
        return defaultColor;
    }
}

class RowsContractor extends React.PureComponent {
    constructor(props) {

        super(props);

        this.state = {
            open: false,
            isShowTable: false,
            isEdit: false,
            key: null,
            editCell: {}
        };

        this.editCellRef = React.createRef();
    }

    getFormatterMonth(idx) {
        if (idx.toString().length === 1) {
            return "0" + idx;
        } else {
            return idx;
        }
    }

    render() {
        const {contractor, departments, changeToggle, counterDepartment} = this.props;
        let date = this.props.date.getFullYear() + "-" + this.getFormatterMonth(this.props.date.getMonth() + 1) + "-" + "01";
        if (contractor.revenue === 0 && (securityService.anyRoles(this.state.crmUser, REGIONAL_DIRECTOR, SUPERVISOR, HEAD_OF_DEPARTMENT))
            && counterDepartment !== 0) {
            return <></>;
        }
        return (
            <>
                <tr className="rowColor" style={{backgroundColor:"#d3d3d3"}}>
                    <td className="tdTitle tdAll">
                        <Button
                            className={"buttonPlanContractor"}
                            size="sm"
                            onClick={() => this.setState({
                                open: !this.state.open,
                                isShowTable: !this.state.isShowTable
                            })}>
                            {this.state.open ? <i className="fa fa-chevron-up arrow-color"></i> :
                                <i className="fa fa-chevron-down arrow-color"></i>}
                        </Button>
                        <span className={"font-p"}
                              title={`ИНН : ${contractor.contractorInn} \nКПП : ${contractor.contractorKpp}`}>
                            {" " + contractor.contractor}</span>
                    </td>
                    {this.renderContractor(contractor, date, changeToggle)}
                </tr>
                {this.renderDepartments(departments, contractor.contractorId, date, changeToggle)}
            </>

        );
    }

    openOrCloseFieldEditor(key, isOpen) {
        this.setState({isEdit: isOpen, key});
    }

    renderContractor(row, date, changeToggle) {
        const backColor = "#d3d3d3";
        return (
            <>
                <td className={"tdCell tdAll"}><span
                    className={"font-p"}>{row.pastYearRevenue.toLocaleString('ru')}</span></td>
                <td className={"tdCell tdAll"}><span
                    className={"font-p"}>{row.pastMonthRevenue.toLocaleString('ru')}</span></td>
                <td className={"tdCell tdAll"}></td>
                <td className={"tdCell tdAll"} style={{backgroundColor: this.props.getBackgroundColor(row.remainder, backColor)}}>
                    <span className={"font-p"}>{row.remainder.toLocaleString('ru')}</span></td>
                {this.handleCellClick(row, date, changeToggle)}
                <td className={"tdCell tdAll"}><span
                    className={"font-p"}>{row.profitNoVatWithGsm.toLocaleString('ru')}</span></td>
                <td className={"tdCell tdAll"}><span
                    className={"font-p"}>{row.margin.toLocaleString('ru')}</span></td>
                <td className={"tdCell tdAll"} style={{backgroundColor: this.props.getBackgroundColor(row.fact, backColor)}}>
                        <span
                            className={"font-p"}> {row.fact.toLocaleString('ru')}</span>
                </td>
            </>
        )
    }

    handleCellClick(row, date, changeToggle) {
        return this.state.isEdit && this.state.key === row.contractor
            ?
            <td className={"tdCell tdAll"}>
                <input
                    className={"inputStyle"}
                    autoFocus={true}
                    align={"right"}
                    type="number"
                    defaultValue={row.revenue === 0 ? row.revenue = '' : row.revenue}
                    onBlur={(e) => {
                        row.revenue = e.target.value;
                        this.completeCellEditor(row.contractor, Number(row.contractorId), date, row.revenue, changeToggle);
                        this.openOrCloseFieldEditor(row.contractor, false);
                    }}
                />
            </td>
            :
            <td className={"tdCell tdAll"}
                onClick={() => this.openOrCloseFieldEditor(row.contractor, true)}>
                {this.getRecommendedRevenue(row.revenue, row.pastMonthRevenue)}
            </td>
    }
    getRecommendedRevenue(revenue, pastMonthRevenue) {
        return revenue === 0 ?
            <span className={"font-p"} style={{opacity: "0.65"}}>{ Math.floor(pastMonthRevenue * 1.1).toLocaleString('ru')}</span>
            :
            <span className={"font-p"}>{ revenue.toLocaleString('ru')}</span>
    }

    completeCellEditor(rowDep, contractorId, dt, plan, changeToggle) {
        const quoteStatusIdList = this.props.getQuoteStatusIdList(this.props.toggleCheck);
        if (plan === "") {
            planSalesService.setContractorDatePlan(contractorId, dt, 0, quoteStatusIdList, this.props.statusPlan, this.props.checkPlan)
                .then(result => {
                    this.setState({data: result.data, editCell: {}})
                })
                .catch(() => this.setState({editCell: {}}));
        } else {
            planSalesService.setContractorDatePlan(contractorId, dt, plan, quoteStatusIdList, this.props.statusPlan, this.props.checkPlan)
                .then(result => {
                    this.setState({data: result.data, editCell: {}})
                })
                .catch(() => this.setState({editCell: {}}));
        }
        changeToggle(true); // Если меняет у контрагента сумму, то сравниваем состояния
        {
            this.openOrCloseFieldEditor(contractorId, true);
        }
    }

    renderDepartments(departments, contractorId, date, changeToggle) {
        return this.state.isShowTable &&
            <>
                {<RowsDepartment key={contractorId + departments}
                                 contractorId={contractorId}
                                 department={departments}
                                 date={date}
                                 inContractor={this.state.open}
                                 changeToggle={changeToggle}
                                 getBackgroundColor={this.props.getBackgroundColor}
                                 getQuoteStatusIdList={this.props.getQuoteStatusIdList}
                                 toggleCheck={this.props.toggleCheck}
                                 statusPlan={this.props.statusPlan}
                                 checkPlan={this.props.checkPlan}
                                 getRecommendedRevenue={this.getRecommendedRevenue}
                />}
            </>

    }
}


class RowsDepartment extends React.PureComponent {

    constructor(props) {

        super(props);

        this.state = {
            openDep: false,
            isEdit: false,
            key: null,
            editCell: {}
        };

        this.editCellRef = React.createRef();

    }

    render() {
        const {department, contractorId, date, inContractor, changeToggle, getRecommendedRevenue} = this.props;
        return department.map((rows) => (
            <>
                <Collapse in={inContractor}>
                    {<RowsDepartmentCollapse key={contractorId + rows}
                                             contractorId={contractorId}
                                             rows={rows}
                                             date={date}
                                             changeToggle={changeToggle}
                                             getBackgroundColor={this.props.getBackgroundColor}
                                             getQuoteStatusIdList={this.props.getQuoteStatusIdList}
                                             toggleCheck={this.props.toggleCheck}
                                             statusPlan={this.props.statusPlan}
                                             checkPlan={this.props.checkPlan}
                                             getRecommendedRevenue={getRecommendedRevenue}
                    />}
                </Collapse>
            </>
        ));
    }
}

class RowsDepartmentCollapse extends React.PureComponent {

    constructor(props) {

        super(props);

        this.state = {
            open: false,
            isEdit: false,
            key: null,
            editCell: {},
            dataUser: [],
        };

        this.editCellRef = React.createRef();

    }

    render() {
        const {rows, date, changeToggle, contractorId} = this.props;
        const backColor = "#e5e2e2";
        return (
            <>
                <tr style={{backgroundColor:"#e5e2e2"}}>
                    <td className={"tdTitle tdAll"}>
                        <Button
                            className={"buttonPlanDepartment"}
                            size="sm"
                            onClick={() => {
                                this.getLogisticByDepartment(rows, date, this.state.open);
                                this.getOpen();
                            }}>
                            {this.state.open ? <i className="fa fa-chevron-up arrow-color"></i> :
                                <i className="fa fa-chevron-down arrow-color"></i>}
                        </Button>
                        <small>{" " + rows.department}</small>
                    </td>
                    <td className={"tdCell tdAll"}></td>
                    <td className={"tdCell tdAll"}><span
                        className={"font-p"}>{rows.pastMonthRevenue.toLocaleString('ru')}</span></td>
                    <td className={"tdCell tdAll"}
                        style={{backgroundColor: this.props.getBackgroundColor(rows.remainderRevenueSspYear,backColor)}}><span
                        className={"font-p"}>{rows.remainderRevenueSspYear.toLocaleString('ru')}</span></td>
                    <td className={"tdCell tdAll"}
                        style={{backgroundColor: this.props.getBackgroundColor(rows.remainderRevenue,backColor)}}><span
                        className={"font-p"}>{rows.remainderRevenue.toLocaleString('ru')}</span></td>
                    {this.handleCellClickDepartment(rows, date, rows.contractor, changeToggle)}
                    <td className={"tdCell tdAll"}><span
                        className={"font-p"}>{rows.profitNoVatWithGsm.toLocaleString('ru')}</span></td>
                    <td className={"tdCell tdAll"}><span
                        className={"font-p"}>{rows.margin.toLocaleString('ru')}</span></td>
                    <td className={"tdCell tdAll"} style={{backgroundColor: this.props.getBackgroundColor(rows.fact,backColor)}}>
                        <span
                            className={"font-p"}> {rows.fact.toLocaleString('ru')}</span>
                    </td>

                </tr>
                {this.renderLogisticByDepartments(this.state.dataUser, contractorId, date, changeToggle, rows)}
            </>
        );
    }

    renderLogisticByDepartments(data, contractorId, date, changeToggle, rows) {
        return (
            <RowsLogisticForDepartment key={contractorId + data} rowsOne={data} date={date} changeToggle={changeToggle}
                                       inOpen={this.state.open} check={rows}
                                       getBackgroundColor={this.props.getBackgroundColor}
                                       getQuoteStatusIdList={this.props.getQuoteStatusIdList}
                                       toggleCheck={this.props.toggleCheck}
                                       statusPlan={this.props.statusPlan}
                                       checkPlan={this.props.checkPlan}/>
        )
    }

    async getLogisticByDepartment(rows, date, open) {
        if (open === false) {
            const result = await this.fetchLogistic(rows, date);
            this.setState({dataUser: result.data[0].userDepartments});
        }
    }

    getOpen() {
        this.setState({open: !this.state.open});
    }

    async fetchLogistic(rows, date) {
        const quoteStatusIdList = this.props.getQuoteStatusIdList(this.props.toggleCheck);
        return await planSalesService.planSalesDepartment(date, rows.contractorId, rows.department, quoteStatusIdList, this.props.checkPlan);
    }

    handleCellClickDepartment(rows, date, contractor, changeToggle) {
        return this.state.isEdit && this.state.key === (rows.department + contractor)
            ?
            <td className={"tdCell tdAll"}>
                <input
                    className={"inputStyle"}
                    autoFocus={true}
                    align={"center"}
                    type="number"
                    defaultValue={rows.revenue === 0 ? rows.revenue = '' : rows.revenue}
                    onBlur={(e) => {
                        rows.revenue = e.target.value;
                        this.completeCellEditorDepartment(contractor, rows.contractorId, rows.department, date, rows.revenue, rows.contractorInn, rows.contractorKpp, changeToggle);
                        this.openOrCloseFieldEditor(rows.department + contractor, false);
                    }}
                />
            </td>
            :
            <td className={"tdCell tdAll"}
                onClick={() => this.openOrCloseFieldEditor(rows.department + contractor, true)}>
                {this.props.getRecommendedRevenue(rows.revenue, rows.pastMonthRevenue)}
            </td>
    }

    completeCellEditorDepartment(rowDep, contractorId, department, dt, plan, inn, kpp, changeToggle) {
        const quoteStatusIdList = this.props.getQuoteStatusIdList(this.props.toggleCheck);
        if (plan === "") {
            planSalesService.setDepartmentByContractorDatePlan(contractorId, department, dt, 0, quoteStatusIdList, this.props.statusPlan)
                .then(result => {
                    this.setState({data: result.data, editCell: {}})
                })
                .catch(() => this.setState({editCell: {}}));
        } else {
            planSalesService.setDepartmentByContractorDatePlan(contractorId, department, dt, plan, quoteStatusIdList, this.props.statusPlan)
                .then(result => {
                    this.setState({data: result.data, editCell: {}})
                })
                .catch(() => this.setState({editCell: {}}));
        }
        PlanSales.refreshData = true;
        changeToggle(true);
        {
            this.openOrCloseFieldEditor(department, true)
        }
    }

    openOrCloseFieldEditor(key, isOpen) {
        this.setState({isEdit: isOpen, key});
    }

}

class RowsLogisticForDepartment extends React.PureComponent {
    constructor(props) {

        super(props);

        this.state = {
            isEditLog: false,
            key: null,
            editCell: {},
            department: this.props.rowsOne,
            dataContractor: this.props.check,
            date: this.props.date,
            toggleCheck: this.props.toggleCheck
        };

        this.editCellRef = React.createRef();

    }

    render() {
        const {date, changeToggle, inOpen} = this.props;
        return this.state.department.map((rows) => (
            <Collapse in={inOpen}>
                <tr >
                    <td className={"tdTitle tdAll"}><span className={"font-p"}>{rows.crmUserName}</span></td>
                    <td className={"tdCell tdAll"}></td>
                    <td className={"tdCell tdAll"}></td>
                    <td className={"tdCell tdAll"}></td>
                    <td className={"tdCell tdAll"}></td>
                    {this.handleCellClickLogistic(rows, date, changeToggle)}
                    <td className={"tdCell tdAll"}><span
                        className={"font-p"}>{Number(rows.profitability).toLocaleString('ru')}</span></td>
                    <td className={"tdCell tdAll"}><span
                        className={"font-p"}>{Number(rows.margin).toLocaleString('ru')}</span></td>
                    <td className={"tdCell tdAll"} style={{backgroundColor: this.props.getBackgroundColor(rows.fact)}}>
                        <span className={"font-p"}>{rows.fact.toLocaleString('ru')}</span></td>
                </tr>
            </Collapse>
        ))
    }

    handleCellClickLogistic(rows, date, changeToggle) {
        return this.state.isEditLog && this.state.key === (rows.departmentId + rows.contractor + rows.crmUserId)
            ?
            <td className={"tdCell tdAll"}>
                <input
                    className={"inputStyle"}
                    autoFocus={true}
                    align={"center"}
                    type="number"
                    defaultValue={rows.revenue === 0 ? rows.revenue = '' : rows.revenue}
                    onBlur={(e) => {
                        rows.revenue = e.target.value;
                        this.completeCellEditorLogistic(rows, date, changeToggle);
                        this.openOrCloseFieldEditor(rows.departmentId + rows.contractor + rows.crmUserId, false);
                    }}
                />
            </td>
            :
            <td className={"tdCell tdAll"}
                onClick={() => this.openOrCloseFieldEditor(rows.departmentId + rows.contractor + rows.crmUserId, true)}>
                <span className={"font-p"}>{Number(rows.revenue).toLocaleString('ru')}</span>
            </td>
    }

    completeCellEditorLogistic(rows, date, changeToggle) {
        const quoteStatusIdList = this.props.getQuoteStatusIdList(this.props.toggleCheck);
        if (rows.revenue === "") {
            planSalesService.setLogisticByContractorDatePlan(rows.contractorId, rows.crmUserId, date, 0, rows.departmentName, quoteStatusIdList, this.props.statusPlan)
                .then(result => {
                    this.setState({department: result.data[0].userDepartments, editCell: {}})
                })
                .catch(() => this.setState({editCell: {}}));
        } else {
            planSalesService.setLogisticByContractorDatePlan(rows.contractorId, rows.crmUserId, date, rows.revenue, rows.departmentName, quoteStatusIdList, this.props.statusPlan)
                .then(result => {
                    this.setState({department: result.data[0].userDepartments, editCell: {}})
                })
                .catch(() => this.setState({editCell: {}}));
        }
        changeToggle(true);
        PlanSales.refreshData = true;
        {
            this.openOrCloseFieldEditor(rows.departmentId + rows.contractorId + rows.crmUserId, true)
        }
    }

    openOrCloseFieldEditor(key, isOpen) {
        this.setState({isEditLog: isOpen, key});
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.data === this.state.department || this.state.toggleCheck !== this.props.toggleCheck || PlanSales.refreshData === true) {
            const result = await this.fetchLogistic(this.state.dataContractor, this.state.date);
            this.setState({
                department: result.data[0].userDepartments,
                toggleCheck: !this.state.toggleCheck
            });
            PlanSales.refreshData = false;
        }
    }

    async fetchLogistic(rows, date) {
        const quoteStatusIdList = this.props.getQuoteStatusIdList(this.props.toggleCheck);
        return await planSalesService.planSalesDepartment(date, rows.contractorId, rows.department, quoteStatusIdList, this.props.checkPlan);
    }
}

export default connect(DataTable.mapStateToProps)(PlanSales);