import React, {PureComponent} from "react";
import {Card, Table} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import './card/SurveyForm.css';
import {APPROVAL, securityService} from "../../../services/SecurityService";

class ValidationTable extends PureComponent {

    constructor(props) {
        super(props);
    }

    static get FIELD_EXCEPTION() { return "exceptionMessage"};

    render() {
        return (
            <Card className={"form-card"}>
                <Card.Header className={"form-card-header shadow-bottom-danger p-0"}>
                    <h6>Ошибки заполнения</h6>
                </Card.Header>
                <Card.Body>
                    {this.renderTable()}
                </Card.Body>
            </Card>
        )
    }

    renderTable() {
        const {errors} = this.props;
        return (
            <Table bordered size="sm">
                <thead>
                    <tr>
                        <th className="col-3">Ошибка</th>
                        <th className="col-3">Ссылка</th>
                    </tr>
                </thead>
                <tbody>
                    {errors.map(this.renderRow.bind(this))}
                </tbody>
            </Table>
        );
    }

    renderRow(error, index) {
        return (
            <tr key={`validation-table-row-${index}`}>
                <td className={"text-danger"}>{error[ValidationTable.FIELD_EXCEPTION]}</td>
                <td>
                    {this.renderLink(error)}
                </td>
            </tr>
        );
    }

    renderLink(error) {
        let path;
        switch(error.type) {
            case "Driver": {
                path = "/control-panel/drivers/driver";
                break;
            }
            case "CrmUser": {
                path = "/control-panel/users/user";
                break;
            }
            case "Person": {
                path = "/control-panel/contractors/contractor/contact";
                break;
            }
            case "Contractor": {
                path = "/control-panel/contractors/contractor";
                break;
            }
            case "Vehicle": {
                path = "/control-panel/vehicles/vehicle";
                break;
            }
        }

        const user = securityService.getUser();
        const hideLink = error.type === "CrmUser" && securityService.hasRole(user, APPROVAL);
        return (!hideLink && <NavLink to={`${path}?id=${error.id}`} target={"_blank"}>Перейти в карточку</NavLink>);
    }
}

export default ValidationTable;