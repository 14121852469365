import React, {PureComponent} from "react";
import {Card} from "react-bootstrap";
import cls from "./AuctionHistory.module.css";
import {classNames} from "../../../helpers/classNames";
import moment from "moment";
import {AuctionBidInfo} from "../AuctionBidInfo/AuctionBidInfo";

class AuctionHistory extends PureComponent {
    constructor(props) {
        super(props);
    }

    renderBidRow(data) {
        const {
            name,
            organisation,
            date,
            bid
        } = data;
        const formattedDate = moment(date).format("DD.MM.YYYY, HH:mm:ss");

        if (!name) {
            return (
                <ul key={date} className={classNames(cls.bid, {}, [cls.bidViolet])}>
                    <li className={classNames(cls.bidInfo, {}, [cls.mergeCell])}>
                        {formattedDate}
                    </li>

                    <li className={classNames(cls.bidText, {}, [cls.mergeCells])}>
                        <span className={cls.bidAmount}>лот открыт</span>
                    </li>
                </ul>
            )
        }

        return (
            <ul key={date} className={cls.bid}>
                <li>
                    {formattedDate}
                </li>

                <li>
                    {organisation}
                </li>

                <li>
                    {name}
                </li>

                <li className={cls.bidText}>
                    <span>₽</span>

                    <span className={cls.bidAmount}>{bid}</span>
                </li>
            </ul>
        )
    }

    render() {
        const {
            bidsHistory,
            className,
        } = this.props;

        return (
            <Card className={classNames(cls.bidsHistory, {}, [className])}>
                <Card.Body>
                    <AuctionBidInfo
                        textComponent={
                            <div>
                                История ставок
                            </div>
                        }
                    />

                    <div className={cls.list}>
                        <div>
                            {
                                this.renderBidRow(bidsHistory[0])
                            }
                        </div>

                        <div>
                            {bidsHistory.slice(1).map(bid => this.renderBidRow(bid))}
                        </div>
                    </div>
                </Card.Body>
            </Card>
        )
    }
}

export default AuctionHistory;