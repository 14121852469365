import {SET_TOAST_OBJ, UNSHOW_TOAST} from "../actions/types";

const initialState = {};

export default function(state = initialState, action) {
    const { type, data } = action;

    switch (type) {
        case SET_TOAST_OBJ:
            return {...data};

        case UNSHOW_TOAST:
            return {...state, show: false }

        default:
            return state;
    }
}

export const setToastObjAC = (data) => ({type: SET_TOAST_OBJ, data})
