import BaseForm from "./form/BaseForm";


class PrivacyPolicy extends BaseForm{
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className="privacy-policy">
				<div className="container">
					<h2 className="text-center m-4">ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ
						(ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ)
					</h2>
					<ol style={{display: "flex", flexDirection: "column", gap: "30px"}}>
						<li>
							Общие положения
							<p>Настоящая политика обработки персональных данных составлена в соответствии с требованиями
								законодательства Российской Федерации и определяет порядок обработки персональных данных
								и меры по обеспечению безопасности персональных данных, предпринимаемые ООО «ПОСТМАТ»
								(далее – Оператор).</p>
							<ol>
								<li>
									<p>Оператор ставит своей важнейшей целью и условием осуществления своей деятельности
										соблюдение прав и свобод человека и гражданина при обработке его персональных
										данных, в том числе защиты прав на неприкосновенность частной жизни, личную и
										семейную тайну.</p>
								</li>
								<li>
									<p>Настоящая политика Оператора в отношении обработки персональных данных (далее –
										Политика) применяется ко всей информации, которую Оператор может получить о
										посетителях веб-сайта: "https://trans-port.pro".</p>
								</li>
							</ol>
						</li>
						<li>
							Основные понятия, используемые в Политике
							<ol>
								<li>
									<p>Автоматизированная обработка персональных данных – обработка персональных данных
										с помощью средств вычислительной техники.</p>
								</li>
								<li>
									<p>Блокирование персональных данных – временное прекращение обработки персональных
										данных (за исключением случаев, если обработка необходима для уточнения
										персональных данных).</p>
								</li>
								<li>
									<p>Веб-сайт – совокупность графических и информационных материалов, а также программ
										для ЭВМ и баз данных, обеспечивающих их доступность в сети интернет по сетевому
										адресу "https://trans-port.pro".</p>
								</li>
								<li>
									<p>Информационная система персональных данных – совокупность содержащихся в базах
										данных персональных данных, и обеспечивающих их обработку информационных
										технологий и технических средств.</p>
								</li>
								<li>
									<p>Обезличивание персональных данных – действия, в результате которых невозможно
										определить без использования дополнительной информации принадлежность
										персональных данных конкретному Пользователю или иному субъекту персональных
										данных.</p>
								</li>
								<li>
									<p>Обработка персональных данных – любое действие (операция) или совокупность
										действий (операций), совершаемых с использованием средств автоматизации или без
										использования таких средств с персональными данными, включая сбор, запись,
										систематизацию, накопление, хранение, уточнение (обновление, изменение),
										извлечение, использование, передачу (распространение, предоставление, доступ),
										обезличивание, блокирование, удаление, уничтожение персональных данных.</p>
								</li>
								<li>
									<p>Согласие на обработку персональных данных – воля пользователя, выраженная в
										заполнении веб-формы с запросом получения согласия на обработку персональных
										данных (проставления «галочки» в веб-форме).</p>
								</li>
								<li>
									<p>Контролёр данных – физическое или юридическое лицо, государственный орган,
										агентство или иной орган, который самостоятельно или совместно с другими,
										определяет цели и средства обработки персональных данных.</p>
								</li>
								<li>
									<p>Процессор данных – физическое или юридическое лицо, государственный орган,
										агентство или иной орган, который обрабатывает персональные данные от имени и по
										поручению контролёра.</p>
								</li>
								<li>
									<p>Персональные данные – любая информация, относящаяся прямо или косвенно к
										определенному или определяемому Пользователю веб-сайта "https://trans-port.pro".</p>
								</li>
								<li>
									<p>Персональные данные, разрешенные субъектом персональных данных для
										распространения – персональные данные, доступ неограниченного круга лиц, к
										которым предоставлен субъектом персональных данных путем дачи согласия на
										обработку персональных данных, разрешенных субъектом персональных данных для
										распространения в порядке, предусмотренном законодательством Российской
										Федерации (далее – персональные данные, разрешенные для распространения).</p>
								</li>
								<li>
									<p>Пользователь – индивидуальный предприниматель, юридическое лицо или физическое
										лицо, в качестве самозанятого, пользующиеся сайтом "https://trans-port.pro".</p>
								</li>
								<li>
									<p>Предоставление персональных данных – действия, направленные на раскрытие
										персональных данных неопределенному лицу или определенному кругу лиц.</p>
								</li>
								<li>
									<p>Распространение персональных данных – любые действия, направленные на раскрытие
										персональных данных неопределенному кругу лиц (передача персональных данных) или
										на ознакомление с персональными данными неограниченного круга лиц, в том числе
										обнародование персональных данных в средствах массовой информации, размещение в
										информационно-телекоммуникационных сетях или предоставление доступа к
										персональным данным каким-либо иным способом.</p>
								</li>
								<li>
									<p>Трансграничная передача персональных данных – передача персональных данных на
										территорию иностранного государства органу власти иностранного государства,
										иностранному физическому или иностранному юридическому лицу.</p>
								</li>
								<li>
									<p>Уничтожение персональных данных – любые действия, в результате которых
										персональные данные уничтожаются безвозвратно с невозможностью дальнейшего
										восстановления содержания персональных данных в информационной системе
										персональных данных и (или) уничтожаются материальные носители персональных
										данных.</p>
								</li>
							</ol>
						</li>
						<li>
							Основные права и обязанности Оператора
							<ol>
								<li>
									<p>Оператор имеет право:</p>
									<ul>
										<li>Получать от субъекта персональных данных достоверные информацию и/или
											документы, содержащие персональные данные;
										</li>
										<li>В случае отзыва субъектом персональных данных согласия на обработку
											персональных данных Оператор вправе продолжить обработку персональных данных
											без согласия субъекта персональных данных при наличии оснований, указанных в
											Законе о персональных данных;
										</li>
										<li>Самостоятельно определять состав и перечень мер, необходимых и достаточных
											для обеспечения выполнения обязанностей, предусмотренных Законодательством
											Российской Федерации и принятыми в соответствии с ним нормативными правовыми
											актами, если иное не предусмотрено Законодательством Российской Федерации
											или другими федеральными законами.
										</li>
									</ul>
								</li>
								<li>
									<p>Оператор обязан:</p>
									<ul>
										<li>Предоставлять субъекту персональных данных по его просьбе информацию,
											касающуюся обработки его персональных данных;
										</li>
										<li>Организовывать обработку персональных данных в порядке, установленном
											действующим законодательством Российской Федерации;
										</li>
										<li>Отвечать на обращения и запросы субъектов персональных данных и их законных
											представителей в соответствии с требованиями Закона о персональных данных;
										</li>
										<li>Публиковать или иным образом обеспечивать неограниченный доступ к настоящей
											Политике в отношении обработки персональных данных;
										</li>
										<li>Принимать правовые, организационные и технические меры для защиты
											персональных данных от неправомерного или случайного доступа к ним,
											уничтожения, изменения, блокирования, копирования, предоставления,
											распространения персональных данных, а также от иных неправомерных действий
											в отношении персональных данных;
										</li>
										<li>Прекратить передачу (распространение, предоставление, доступ) персональных
											данных, прекратить обработку и уничтожить персональные данные в порядке и
											случаях, предусмотренных Законодательством Российской Федерации;
										</li>
										<li>Исполнять иные обязанности, предусмотренные Законодательством Российской
											Федерации.
										</li>
									</ul>
								</li>
							</ol>
						</li>
						<li>
							Основные права и обязанности субъектов персональных данных
							<ol>
								<li>
									<p>Субъекты персональных данных имеют право:</p>
									<ul>
										<li>Получать информацию, касающуюся обработки его персональных данных, за
											исключением случаев, предусмотренных федеральными законами. Сведения
											предоставляются субъекту персональных данных Оператором в доступной форме, и
											в них не должны содержаться персональные данные, относящиеся к другим
											субъектам персональных данных, за исключением случаев, когда имеются
											законные основания для раскрытия таких персональных данных. Перечень
											информации и порядок ее получения установлен законодательством Российской
											Федерации;
										</li>
										<li>Требовать от оператора уточнения его персональных данных, их блокирования
											или уничтожения в случае, если персональные данные являются неполными,
											устаревшими, неточными, незаконно полученными или не являются необходимыми
											для заявленной цели обработки, а также принимать предусмотренные законом
											меры по защите своих прав;
										</li>
										<li>Выдвигать условие предварительного согласия при обработке персональных
											данных в целях продвижения на рынке товаров, работ и услуг;
										</li>
										<li>На отзыв согласия на обработку персональных данных;</li>
										<li>Обжаловать в уполномоченный орган по защите прав субъектов персональных
											данных или в судебном порядке неправомерные действия или бездействие
											Оператора при обработке его персональных данных;
										</li>
										<li>На осуществление иных прав, предусмотренных законодательством Российской
											Федерации.
										</li>
									</ul>
								</li>
								<li>
									<p>Субъекты персональных данных обязаны:</p>
									<ul>
										<li>Предоставлять Оператору достоверные данные о себе;</li>
										<li>Сообщать Оператору об уточнении (обновлении, изменении) своих персональных
											данных.
										</li>
									</ul>
								</li>
								<li>
									<p>Лица, передавшие Оператору недостоверные сведения о себе, либо сведения о другом
										субъекте персональных данных без согласия последнего, несут ответственность в
										соответствии с законодательством Российской Федерации.</p>
								</li>
							</ol>
						</li>
						<li>
							Оператор может обрабатывать следующие персональные данные Пользователя
							<ol>
								<li>
									<p>В отношении физических лиц:</p>
									<ul>
										<li>Фамилия, имя, отчество;</li>
										<li>Почтовый адрес;</li>
										<li>Электронный адрес;</li>
										<li>Номера телефонов;</li>
										<li>Год, месяц, дата рождения;</li>
										<li>Паспортные данные;</li>
										<li>Уникальный id устройства;</li>
										<li>Пароль;</li>
										<li>Местоположение пользователя.</li>
									</ul>
								</li>
								<li>
									<p>В отношении юридических лиц и индивидуальных предпринимателей:</p>
									<ul>
										<li>Фамилия, имя, отчество руководителя юридического лица или индивидуального
											предпринимателя;
										</li>
										<li>Номер телефона;</li>
										<li>Электронный адрес;</li>
										<li>Реквизиты юридического лица или индивидуального предпринимателя (ИНН,
											ОГРН);
										</li>
										<li>Фамилия Имя Отчество индивидуального предпринимателя;</li>
										<li>Реквизиты счета для получения денежных средств;</li>
										<li>Почтовый адрес;</li>
										<li>Электронный адрес;</li>
										<li>Номера телефонов;</li>
										<li>Уникальный id устройства;</li>
										<li>Пароль;</li>
										<li>Местоположение пользователя.</li>
									</ul>
								</li>
								<li>
									<p>Оператор также может обрабатывать иные персональные данные, указанные в договоре
										между Пользователем и Оператором.</p>
								</li>
								<li>
									<p>Согласие на обработку персональных данных, разрешенных для распространения,
										Пользователь предоставляет Оператору непосредственно.</p>
								</li>
								<li>
									<p>Также на сайте происходит сбор и обработка обезличенных данных о посетителях (в
										т.ч. файлов «cookie») с помощью сервисов интернет-статистики.</p>
								</li>
								<li>
									<p>Вышеперечисленные данные далее по тексту Политики объединены общим понятием
										Персональные данные.</p>
								</li>
								<li>
									<p>Обработка специальных категорий персональных данных, касающихся расовой,
										национальной принадлежности, политических взглядов, религиозных или философских
										убеждений, интимной жизни, Оператором не осуществляется.</p>
								</li>
								<li>
									<p>Согласие Пользователя на обработку персональных данных осуществляется посредством
										заполнения веб-формы, установленной Оператором.</p>
								</li>
								<li>
									<p>Согласие Пользователя на обработку персональных данных, разрешенных для
										распространения, оформляется отдельно от других согласий на обработку его
										персональных данных. Требования к содержанию такого согласия устанавливаются
										уполномоченным органом по защите прав субъектов персональных данных.</p>
								</li>
								<li>
									<p>Оператор обязан в срок не позднее трех рабочих дней с момента получения
										указанного согласия Пользователя опубликовать информацию об условиях обработки,
										о наличии запретов и условий на обработку неограниченным кругом лиц персональных
										данных, разрешенных для распространения.</p>
								</li>
								<li>
									<p>Передача (распространение, предоставление, доступ) персональных данных,
										разрешенных субъектом персональных данных для распространения, должна быть
										прекращена в любое время по требованию субъекта персональных данных. Данное
										требование должно включать в себя фамилию, имя, отчество (при наличии),
										контактную информацию (номер телефона, адрес электронной почты или почтовый
										адрес) субъекта персональных данных, а также перечень персональных данных,
										обработка которых подлежит прекращению. Указанные в данном требовании
										персональные данные могут обрабатываться только Оператором, которому оно
										направлено.</p>
								</li>
								<li>
									<p>Согласие на обработку персональных данных, разрешенных для распространения,
										прекращает свое действие с момента поступления Оператору требования.</p>
								</li>
							</ol>
						</li>
						<li>
							Принципы обработки персональных данных
							<ol>
								<li>
									<p>Обработка персональных данных осуществляется на законной и справедливой
										основе.</p>
								</li>
								<li>
									<p>Обработка персональных данных ограничивается достижением конкретных, заранее
										определенных и законных целей. Не допускается обработка персональных данных,
										несовместимая с целями сбора персональных данных.</p>
								</li>
								<li>
									<p>Не допускается объединение баз данных, содержащих персональные данные, обработка
										которых осуществляется в целях, несовместимых между собой.</p>
								</li>
								<li>
									<p>Обработке подлежат только персональные данные, которые отвечают целям их
										обработки.</p>
								</li>
								<li>
									<p>Содержание и объем обрабатываемых персональных данных соответствуют заявленным
										целям обработки. Не допускается избыточность обрабатываемых персональных данных
										по отношению к заявленным целям их обработки.</p>
								</li>
								<li>
									<p>При обработке персональных данных обеспечивается точность персональных данных, их
										достаточность, а в необходимых случаях и актуальность по отношению к целям
										обработки персональных данных. Оператор принимает необходимые меры и/или
										обеспечивает их принятие по удалению или уточнению неполных или неточных
										данных.</p>
								</li>
							</ol>
						</li>
						<li>
							Цели обработки персональных данных
							<ol>
								<li>
									<p>Цель обработки персональных данных Пользователя:</p>
									<ul>
										<li>Предоставление доступа Пользователю к сервисам, информации и/или материалам,
											содержащимся на веб-сайте "https://trans-port.pro";
										</li>
										<li>Заключение, исполнение и прекращение гражданско-правовых договоров;</li>
										<li>Администрирования и совершенствования предоставляемых услуг;</li>
										<li>Обеспечения безопасности и мониторинга деятельности, осуществляемой с
											использованием сайта;
										</li>
										<li>Соблюдение требований Законодательства Российской Федерации в области
											персональных данных.
										</li>
									</ul>
								</li>
								<li>
									<p>Также Оператор имеет право направлять Пользователю уведомления о новых продуктах
										и услугах, специальных предложениях и различных событиях. Пользователь всегда
										может отказаться от получения информационных сообщений, направив Оператору
										письмо на адрес электронной почты "https://trans-port.pro" с пометкой «Отказ от
										уведомлений о новых продуктах и услугах и специальных предложениях».</p>
								</li>
								<li>
									<p>Обезличенные данные Пользователей, собираемые с помощью сервисов
										интернет-статистики, служат для сбора информации о действиях Пользователей на
										сайте, улучшения качества сайта и его содержания.</p>
								</li>
							</ol>
						</li>
						<li>
							Правовые основания обработки персональных данных
							<ol>
								<li>
									<p>Правовыми основаниями обработки персональных данных Оператором являются:</p>
									<ul>
										<li>Договоры, заключаемые между оператором и субъектом персональных данных;</li>
										<li>Законы и иные нормативно-правовые акты в сфере защиты персональных данных
											Российской Федерации;
										</li>
										<li>Согласия Пользователей на обработку их персональных данных, на обработку
											персональных данных, разрешенных для распространения.
										</li>
									</ul>
								</li>
								<li>
									<p>Оператор обрабатывает персональные данные Пользователя только в случае их
										заполнения и/или отправки Пользователем самостоятельно через специальные формы,
										расположенные на сайте "https://trans-port.pro" или направленные Оператору
										посредством электронной почты. Заполняя соответствующие формы и/или отправляя
										свои персональные данные Оператору, Пользователь выражает свое согласие с данной
										Политикой.</p>
								</li>
								<li>
									<p>Оператор обрабатывает обезличенные данные о Пользователе в случае, если это
										разрешено в настройках браузера Пользователя.</p>
								</li>
								<li>
									<p>Субъект персональных данных самостоятельно принимает решение о предоставлении его
										персональных данных и дает согласие свободно, своей волей и в своем
										интересе.</p>
								</li>
							</ol>
						</li>
						<li>
							Условия обработки персональных данных
							<ol>
								<li>
									<p>Обработка персональных данных осуществляется с согласия субъекта персональных
										данных на обработку его персональных данных.</p>
								</li>
								<li>
									<p>Обработка персональных данных необходима для достижения целей, предусмотренных
										международным договором Российской Федерации или законом, для осуществления
										возложенных законодательством Российской Федерации на оператора функций,
										полномочий и обязанностей.</p>
								</li>
								<li>
									<p>Обработка персональных данных необходима для осуществления правосудия, исполнения
										судебного акта, акта другого органа или должностного лица, подлежащих исполнению
										в соответствии с законодательством Российской Федерации об исполнительном
										производстве.</p>
								</li>
								<li>
									<p>Обработка персональных данных необходима для исполнения договора, стороной
										которого либо выгодоприобретателем или поручителем, по которому является субъект
										персональных данных, а также для заключения договора по инициативе субъекта
										персональных данных или договора, по которому субъект персональных данных будет
										являться выгодоприобретателем или поручителем.</p>
								</li>
								<li>
									<p>Обработка персональных данных необходима для осуществления прав и законных
										интересов оператора или третьих лиц либо для достижения общественно значимых
										целей при условии, что при этом не нарушаются права и свободы субъекта
										персональных данных.</p>
								</li>
								<li>
									<p>Осуществляется обработка персональных данных, доступ неограниченного круга лиц,
										которым предоставлен субъектом персональных данных либо по его просьбе (далее –
										общедоступные персональные данные).</p>
								</li>
								<li>
									<p>Осуществляется обработка персональных данных, подлежащих опубликованию или
										обязательному раскрытию в соответствии с федеральным законом.</p>
								</li>
							</ol>
						</li>
						<li>
							Порядок сбора, хранения, передачи и других видов обработки персональных данных
							<p>Безопасность персональных данных, которые обрабатываются Оператором, обеспечивается путем
								реализации правовых, организационных и технических мер, необходимых для выполнения в
								полном объеме требований действующего законодательства в области защиты персональных
								данных.</p>
							<ol>
								<li><p>Оператор обеспечивает сохранность персональных данных и принимает все возможные
									меры, исключающие доступ к персональным данным неуполномоченных лиц.</p></li>
								<li><p>Персональные данные Пользователя никогда, ни при каких условиях не будут переданы
									третьим лицам, за исключением случаев, связанных с исполнением действующего
									законодательства либо в случае, если субъектом персональных данных дано согласие
									Оператору на передачу данных третьему лицу для исполнения обязательств по
									гражданско-правовому договору.</p></li>
								<li><p>В случае выявления неточностей в персональных данных, Пользователь может
									актуализировать их самостоятельно, путем направления Оператору уведомление на адрес
									электронной почты Оператора <a
										href="mailto:support@postmat.su">support@postmat.su</a> с пометкой «Актуализация
									персональных данных».</p></li>
								<li><p>Срок обработки персональных данных определяется достижением целей, для которых
									были собраны персональные данные, если иной срок не предусмотрен договором или
									действующим законодательством. Пользователь может в любой момент отозвать свое
									согласие на обработку персональных данных, направив Оператору уведомление
									посредством электронной почты на электронный адрес Оператора <a
										href="mailto:support@postmat.su">support@postmat.su</a> с пометкой «Отзыв
									согласия на обработку персональных данных».</p></li>
								<li><p>Вся информация, которая собирается сторонними сервисами, в том числе платежными
									системами, средствами связи и другими поставщиками услуг, хранится и обрабатывается
									указанными лицами (Операторами) в соответствии с их Пользовательским соглашением и
									Политикой конфиденциальности. Субъект персональных данных и/или Пользователь обязан
									самостоятельно своевременно ознакомиться с указанными документами. Оператор не несет
									ответственность за действия третьих лиц, в том числе указанных в настоящем пункте
									поставщиков услуг.</p></li>
								<li><p>Установленные субъектом персональных данных запреты на передачу (кроме
									предоставления доступа), а также на обработку или условия обработки (кроме получения
									доступа) персональных данных, разрешенных для распространения, не действуют в
									случаях обработки персональных данных в государственных, общественных и иных
									публичных интересах, определенных законодательством Российской Федерации.</p></li>
								<li><p>Оператор при обработке персональных данных обеспечивает конфиденциальность
									персональных данных.</p></li>
								<li><p>Оператор осуществляет хранение персональных данных в форме, позволяющей
									определить субъекта персональных данных, не дольше, чем этого требуют цели обработки
									персональных данных, если срок хранения персональных данных не установлен
									федеральным законом, договором, стороной которого, выгодоприобретателем или
									поручителем, по которому является субъект персональных данных.</p></li>
								<li><p>Условием прекращения обработки персональных данных может являться достижение
									целей обработки персональных данных, истечение срока действия согласия субъекта
									персональных данных или отзыв согласия субъектом персональных данных, а также
									выявление неправомерной обработки персональных данных.</p></li>
							</ol>
						</li>
						<li>
							Перечень действий, производимых Оператором с полученными персональными данными
							<ol>
								<li>
									<p>Оператор осуществляет сбор, запись, систематизацию, накопление, хранение,
										уточнение (обновление, изменение), извлечение, использование, передачу
										(распространение, предоставление, доступ), обезличивание, блокирование, удаление
										и уничтожение персональных данных.</p>
								</li>
								<li>
									<p>Оператор осуществляет автоматизированную обработку персональных данных с
										получением и/или передачей полученной информации по
										информационно-телекоммуникационным сетям или без таковой.</p>
								</li>
							</ol>
						</li>
						<li>
							Конфиденциальность персональных данных
							<ol>
								<li>
									<p>Оператор и иные лица, получившие доступ к персональным данным, обязаны не
										раскрывать третьим лицам и не распространять персональные данные без согласия
										субъекта персональных данных, если иное не предусмотрено федеральным
										законом.</p>
								</li>
								<li>
									<p>Под конфиденциальной информацией понимаются не являющиеся общедоступными
										сведения, разглашение которых может привести к возникновению убытков и/или
										повлиять на деловую репутацию любой из Сторон, в том числе: информация о
										Персональных данных работников юридического лица, оплатах, объемах операций.
										Весь объем информации, предоставляемой юридическими лицами, считается
										конфиденциальной информацией (в пределах, допускаемых действующим
										законодательством РФ – коммерческой тайной).</p>
									<p>Оператор обязуется ограничить перечень лиц, имеющих доступ к конфиденциальной
										информации, исключительно своими сотрудниками, непосредственно участвующими в
										исполнении Договора.</p>
								</li>
							</ol>
						</li>
						<li>
							Заключительные положения
							<ol>
								<li>
									<p>Пользователь может получить любые разъяснения по интересующим вопросам,
										касающимся обработки его персональных данных, обратившись к Оператору с помощью
										электронной почты <a href="mailto:support@postmat.su">support@postmat.su</a>.
									</p>
								</li>
								<li>
									<p>В данном документе будут отражены любые изменения политики обработки персональных
										данных Оператором. Политика действует бессрочно до замены ее новой версией.</p>
								</li>
								<li>
									<p>Актуальная версия Политики в свободном доступе расположена в сети Интернет по
										адресу <a href="https://trans-port.pro" target="_blank">https://trans-port.pro</a>.</p>
								</li>
							</ol>
						</li>
					</ol>
				</div>
			</div>
		);
	}
}


export default PrivacyPolicy;
