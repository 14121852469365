import React, {PureComponent} from "react";
import "./OperationalReport.css";
import LineChart from "../charts/LineChart";
import {Button, Card, Form} from "react-bootstrap";
import operationalReportService from "../../services/OperationalReportService";
import OperationalReport from "./OperationalReport";
import DatePicker from "react-datepicker";
import ru from "date-fns/locale/ru";
import Select from "react-select";
import dateTimeService from "../../services/DateTimeService";
class GeneralReport extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            sending: false,
            department: this.ANY,
            logistic: this.ANY,
            scaleType: this.MONTH
        }
    }

    ANY = { value: 0, label: OperationalReport.ANY };
    DAY = { value: "DAY", label: "День" };
    WEEK = { value: "WEEK", label: "Неделя" };
    MONTH = { value: "MONTH", label: "Месяц" };
    YEAR = { value: "YEAR", label: "Год" };
    SCALE_TYPES = [ this.DAY, this.WEEK, this.MONTH ];
    CHARTS = [
        "quotesNumber", "quotesNumberWithVAT", "quotesNumberWithoutVAT",
        "revenue", "revenueWithVAT", "revenueWithoutVAT",
        "pureMargin", "pureMarginWithVAT", "pureMarginWithoutVAT",
        "pureProfitability", "pureProfitabilityWithVAT", "pureProfitabilityWithoutVAT",
        "grossMargin", "grossMarginWithVAT", "grossMarginWithoutVAT",
        "grossProfitability", "grossProfitabilityWithVAT", "grossProfitabilityWithoutVAT",
        "gsmPercent", "gsmCarrier", "gsmStrait"
    ];

    componentDidMount() {
        const chartBoxWidth = document.getElementById("content")?.offsetWidth - 10;
        this.setState({chartBoxWidth: chartBoxWidth});

        operationalReportService.getDepartments()
            .then(response => this.setState({departments: response}));

        operationalReportService.getLogistics()
            .then(response => this.setState({logistics: response}));
    }

    loadData() {
        const to = this.state.to
            ? this.state.to
            : new Date();
        const from = this.state.from
            ? this.state.from
            : dateTimeService.subtractMonths(new Date(), 12);

        const data = {
            departmentId: this.state.department.value,
            logisticId: this.state.logistic.value,
            scaleType: this.state.scaleType
                ? this.state.scaleType.value
                : this.MONTH.value,
            from: dateTimeService.createDateAsUTC(from),
            to: dateTimeService.createDateAsUTC(to)
        }

        this.setState({sending: true});
        operationalReportService.post("general", data)
            .then(response => {
                for(let i = 0; i < response.length; i++) {
                    this.setState({[this.CHARTS[i]]: response[i]});
                }
            })
            .finally(() => this.setState({sending: false}));
    }

    arrayToSelectOptions(array) {
        let res = array?.map(a => ({value: a.id, label: a.value}));
        if (res?.length > 1) res?.unshift(this.ANY);
        return res;
    }

    arrayToOptionsForUsers(array) {
        let res = array
            ?.filter(u => u.departments.map(d => d.id).includes(this.state.department.value) || this.state.department.value === 0)
            .map(a => ({value: a.id, label: a.lastName + " " + a.firstName?.substring(0, 1) + ". "}));
        if (res?.length > 1) res?.unshift(this.ANY);
        return res;
    }

    renderSelect(label, stateKey, options) {
        return <Form.Group style={{marginBottom: 0, width: "300px"}}>
            <Form.Label style={{whiteSpace : "noWrap"}}>{label}</Form.Label>
            <Select options={options}
                    placeholder={label}
                    hideSelectedOptions={false}
                    value={this.state[stateKey]}
                    onChange={selected => this.selectRow(stateKey, selected)}>
            </Select>
        </Form.Group>
    }

    selectRow(stateKey, selected) {
        this.setState({[stateKey]: selected});

        if (stateKey === "department") {
            this.setState({logistic: this.ANY});
        }
    }

    renderDatePicker(label, stateKey, defaultValue) {
        return <Form.Group style={{marginBottom: 0, width: "90px"}}>
            <Form.Label style={{whiteSpace : "noWrap"}}>{label}</Form.Label>
            <DatePicker
                className="operational-report-date-picker general-report-date-picker"
                dateFormat="dd.MM.yyyy"
                locale={ru}
                selected={this.state[stateKey] ? this.state[stateKey] : defaultValue}
                onChange={date => this.setState({[stateKey] : date})}/>
        </Form.Group>
    }

    renderSubmitFiltersButton() {
        return <div>
            <Button variant="outline-primary"
                    size="md"
                    onClick={() => this.loadData()}
                    style={{marginTop: "30px"}}
                    disabled={this.state.sending}>
                {this.state.sending && (<span className="spinner-border spinner-border-sm"></span>)}
                <span> Применить</span>
            </Button>
        </div>
    }

    renderSmallChart(stateKey, color, label) {
        return this.state[stateKey] &&
            <LineChart data={this.state[stateKey]}
                       fontSize="sm"
                       withPoint={false}
                       color={color}
                       width={this.state.chartBoxWidth ? this.state.chartBoxWidth / 6 : 200}
                       height={80}
                       leftLabel={label}>
            </LineChart>
    }

    renderChart(stateKey, color, label, isLarge) {
        let width = 450;
        if (this.state.chartBoxWidth) {
            width = this.state.chartBoxWidth;
        }

        return <Card>
            {this.state[stateKey] &&
                <LineChart data={this.state[stateKey]}
                           withPoint={false}
                           color={color}
                           width={isLarge ? width / 3.34 : width / 3.67}
                           height={200}
                           leftLabel={label}>
                </LineChart>}
        </Card>
    }

    renderFilterPanel() {
        return <Card.Header>
            <div style={{display: "flex", flexWrap: "wrap"}}>
                {this.renderSelect("Подразделение", "department", this.arrayToSelectOptions(this.state.departments))}
                {this.renderSelect("Логист", "logistic", this.arrayToOptionsForUsers(this.state.logistics))}
                {this.renderSelect("Разбиение", "scaleType", this.SCALE_TYPES)}&nbsp;
                {this.renderDatePicker("От", "from", dateTimeService.subtractMonths(new Date(), 12))}
                {this.renderDatePicker("До", "to", new Date())}&nbsp;
                {this.renderSubmitFiltersButton()}
            </div>
        </Card.Header>
    }

    renderTripleChart(stateKey, color, label) {
        return <>{this.renderChart(stateKey, color, label)}
        <Card>
            {this.renderSmallChart(stateKey + "WithoutVAT", color, label + " без НДС")}
            {this.renderSmallChart(stateKey + "WithVAT", color, label + " с НДС")}
        </Card></>
    }

    render() {
        return <div id="chart-box">
            {this.renderFilterPanel()}
            <div className="charts-row">
                {this.renderTripleChart("quotesNumber", "#007bff", "Количество заявок")}
                {this.renderTripleChart("revenue", "orange", "Выручка")}
            </div>
            <div className="charts-row">
                {this.renderTripleChart("pureMargin","red", "Маржа чистая")}
                {this.renderTripleChart("pureProfitability","limegreen", "Рентабельность чистая")}
            </div>
            <div className="charts-row">
                {this.renderTripleChart("grossMargin","maroon", "Маржа валовая")}
                {this.renderTripleChart("grossProfitability","green", "Рентабельность валовая")}
            </div>
            <div className="charts-row">
                {this.renderChart("gsmPercent","black", "Процент пролива ГСМ", true)}
                {this.renderChart("gsmCarrier","black", "Лимит ГСМ", true)}
                {this.renderChart("gsmStrait","black", "Фактический пролив", true)}
            </div>
        </div>
    }
}

export default GeneralReport;