import React from "react";
import DataTable from "../../../table/DataTable";
import {connect} from "react-redux";
import TableCell from "../../../table/TableCell";
import contactService from "../../../../services/ContactService";
import StoreWrapper from "../../../form/StoreWrapper";

function mapGlobalStateToProps(state) {
    return {
        user: state.auth.user
    };
}
class ContactTable extends StoreWrapper {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <ContactTableInnerConnected {...this.props} store={this.store} />
        );
    }
}
class ContactTableInner extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            columns: []
        }
    }

    render() {
        return (
            <DataTable
                title={"Контактные лица"}
                openEditForm = {this.openEditForm.bind(this)}
                fetch = {this.fetch.bind(this)}
                columns = {this.columns()}
                features = {{ canAdd: false }}
            />
        );
    }

    openEditForm(row) {
        this.props.history.push({ pathname: "/control-panel/contractors/contractor/contact", state: { id: row.id } });
    }

    async fetch(params) {
        const {user} = this.props;
        params.crmUser = {id: user.id, value: user.fio}
        return contactService.list(params);
    }

    isCarrier(user) {
        return user.roles.includes("CARRIER");
    }

    columns() {
        return [
            { title: "#", field: "id", className: "id overflow", filter: DataTable.STRING_FILTER },
            { title: "ФИО", field: "fio", filter: DataTable.STRING_FILTER },
            { title: "Контрагенты", field: "contractors", filter: DataTable.DICTIONARY_FILTER, optionsType: "CONTRACTOR",
                formatter: value => this.formatContractors(value), multiSelect: true, sortable: false },
            { title: "Должность", field: "position", filter: DataTable.STRING_FILTER },
            { title: "Телефон", field: "phone", filter: DataTable.STRING_FILTER },
            { title: "Email", field: "email", filter: DataTable.STRING_FILTER },
        ];
    }

    formatContractors(options) {
        const contractors = options || [];
        return TableCell.arrayFormatter(contractors.length > 0 ? contractors.map(it => it?.value) : contractors);
    }

}

const ContactTableInnerConnected = connect(DataTable.mapStateToProps)(ContactTableInner);
export default connect(mapGlobalStateToProps)(ContactTable);