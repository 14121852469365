import { history } from "../index";
import { API_EXCEPTION_ACTION } from "../Enums";
import { API_EXCEPTION_POP, API_EXCEPTION_PUSH } from "./types";

export const processApiException = (apiException) => (dispatch) => {
	// DO ACTION FIRST
	switch (API_EXCEPTION_ACTION[apiException.action]) {
		case API_EXCEPTION_ACTION.REDIRECT_HOME: {
			history.push("/");
			break;
		}
		case API_EXCEPTION_ACTION.NO_ACTION:
		default: {
			// do nothing
			break;
		}
	}
	// NOTIFY USER
	dispatch(pushApiException(apiException));
};

export const pushApiException = (exception) => ({
	type: API_EXCEPTION_PUSH, payload: exception
});

export const popApiException = (exception) => ({
	type: API_EXCEPTION_POP, payload: exception
});