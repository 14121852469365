import axios from 'axios';
import authHeader, {HEADER_AUTHORIZATION} from './services/authHeader';
import {env} from "./env";

const transportAxios = axios.create({
    baseURL: env.REACT_APP_API_URL ?? "http://localhost"
});

const withAuthHeader = (req, axiosInstance) => {
    if (req && (!req.headers.common[HEADER_AUTHORIZATION] && !req.headers[HEADER_AUTHORIZATION])) {
        updateDefaultAuthHeader();
        req.headers[HEADER_AUTHORIZATION] = axiosInstance.defaults.headers.common[HEADER_AUTHORIZATION];
    }
    return req;
}

const configureAxiosRequest = (axiosInstance) => {
    axiosInstance.interceptors.request.use(
        req => withAuthHeader(req, axiosInstance),
        error => {
            alert('Error happened: ' + error);
        }
    );
}

configureAxiosRequest(transportAxios);

export {transportAxios as axios};
export const updateDefaultAuthHeader = () => {
    transportAxios.defaults.headers.common[HEADER_AUTHORIZATION] = authHeader()[HEADER_AUTHORIZATION];
}

export const createCancelToken = () => axios.CancelToken.source()