import React from "react";
import {Tab, Tabs} from "react-bootstrap";
import TransportReport from "./TransportReport";
import CargoAnalytic from "./CargoAnalytic";
import DocumentStatus from "./DocumentStatus";
import StoreWrapper from "../form/StoreWrapper";
import {securityService} from "../../services/SecurityService";
import GeneralReport from "./GeneralReport";
import CommercialReport from "./CommercialReport";
import Downtime from "./Downtime";

class OperationalReport extends StoreWrapper {
    constructor(props) {
        super(props);

        this.state = {
            activeTab : this.GENERAL_REPORT.id
        }
    }

    GENERAL_REPORT = { id: "generalReport", name: "Общий отчёт", title: "ОБЩИЙ ОТЧЁТ" }
    COMMERCIAL_REPORT = { id: "commercialReport", name: "Коммерческий отчёт", title: "КОММЕРЧЕСКИЙ ОТЧЁТ" }
    TRANSPORT = { id: "transport", name: "Транспорт", title: "ОТЧЕТ ПО ВЫСВОБОЖДАЮЩЕМУСЯ ТРАНСПОРТУ" }
    CARGO_ANALYTIC = { id: "cargoAnalytic", name: "Анализ ставок", title: "ОТЧЕТ ПО АНАЛИЗУ ЦЕН ПО НАПРАВЛЕНИЯМ" }
    DOCUMENT_STATUS = { id: "documentStatus", name: "Статус документов", title: "СТАТУС ДОКУМЕНТОВ" }
    DOWNTIME = { id: "downTime", name: "Простои собственного транспорта", title: "ПРОСТОИ СОБСТВЕННОГО ТРАНСПОРТА" }

    static ANY = " — ";
    static BEFORE = { id: "before", name: "до" };
    static AFTER = { id: "after", name: "после" };
    static FROM = { id: "from", name: "От" };
    static TO = { id: "to", name: "До" };
    static UNLOADING_DT = { id: "unloadingDt", name: "Дата разгрузки" }
    static UNLOADING_REGION = { id: "unloadingRegion", name: "Регион разгрузки" }
    static LOADING_REGION = { id: "loadingRegion", name: "Регион загрузки" }
    static CONTRACTOR_FULL_NAME = { id: "contractorFullName", name: "Заказчик" }
    static CARRIER_FULL_NAME = { id: "carrierFullName", name: "Перевозчик" }
    static CARRIER_PHONE = { id: "carrierPhone", name: "Телефон перевозчика" }
    static DRIVER_LAST_NAME = { id: "driverLastName", name: "Фамилия водителя" }
    static VEHICLE_TONNAGE = { id: "vehicleTonnage", name: "Тоннаж ТС" }
    static VEHICLE_NUMBER = { id: "vehicleNumber", name: "Номер ТС" }
    static VEHICLE_CARCASS_VALUE = { id: "vehicleCarcassValue", name: "Тип кузова" }
    static VEHICLE_CUBAGE = { id: "vehicleCubage", name: "Кубатура ТС" }
    static LOADING_TYPE_VALUE = { id: "loadingTypeValue", name: "Тип загрузки" }
    static LOADING_DT = { id: "loadingDt", name: "Дата загрузки" }
    static CARRIER_PRICE = { id: "carrierPrice", name: "Ставка перевозчика" }
    static UNLOADING_CITY = { id: "unloadingCity", name: "Город разгрузки" }
    static LOADING_CITY = { id: "loadingCity", name: "Город загрузки" }
    static CARRIER_VAT_MODE = { id: "carrierVatMode", name: "Режим НДС" }
    static GSM_STRAIT = { id: "gsmStrait", name: "Пролив ГСМ" }
    static DEPARTMENT_VALUE = { id: "departmentValue", name: "Подразделение" }
    static SOURCE_VALUE = { id: "sourceValue", name: "Источник заявки" }
    static CARGO_TYPE_VALUE = { id: "cargoTypeValue", name: "Груз" }

    static mapAndFilterField(array, field) {
        return array?.map(o => o[field]).filter(this.onlyUnique);
    }

    static onlyUnique(value, index, array) {
        return value !== null && array.indexOf(value) === index;
    }

    static range = (start, end, length = end - start) =>
        Array.from({length}, (_, i) => start + i);

    static getQuotesName(qn) {
        if (qn % 100 < 10 || qn % 100 > 20) {
            if (qn % 10 === 1) {
                return " заявка";
            }
            if (qn % 10 === 2 || qn % 10 === 3 || qn % 10 === 4) {
                return " заявки";
            }
        }

        return " заявок";
    }

    render() {
        return <Tabs activeKey={this.state.activeTab}
                     onSelect={key => this.setState({ activeTab: key })}>

            <Tab key={this.GENERAL_REPORT.id} eventKey={this.GENERAL_REPORT.id} title={this.GENERAL_REPORT.name}>
                <GeneralReport title={this.GENERAL_REPORT.title}></GeneralReport>
            </Tab>
            <Tab key={this.COMMERCIAL_REPORT.id} eventKey={this.COMMERCIAL_REPORT.id} title={this.COMMERCIAL_REPORT.name}>
                <CommercialReport title={this.COMMERCIAL_REPORT.title}></CommercialReport>
            </Tab>
            <Tab key={this.TRANSPORT.id} eventKey={this.TRANSPORT.id} title={this.TRANSPORT.name}>
                <TransportReport title={this.TRANSPORT.title}></TransportReport>
            </Tab>
            <Tab key={this.CARGO_ANALYTIC.id} eventKey={this.CARGO_ANALYTIC.id} title={this.CARGO_ANALYTIC.name}>
                <CargoAnalytic title={this.CARGO_ANALYTIC.title}></CargoAnalytic>
            </Tab>
            {!securityService.isCommercial(securityService.getUser()) &&
                <Tab key={this.DOCUMENT_STATUS.id} eventKey={this.DOCUMENT_STATUS.id} title={this.DOCUMENT_STATUS.name}
                     mountOnEnter unmountOnExit>
                <DocumentStatus {...this.props} store={this.store} title={this.DOCUMENT_STATUS.title}></DocumentStatus>
            </Tab>}
            <Tab key={this.DOWNTIME.id} eventKey={this.DOWNTIME.id} title={this.DOWNTIME.name} mountOnEnter>
                <Downtime title={this.DOWNTIME.title}></Downtime>
            </Tab>
        </Tabs>
    }
}

export default OperationalReport;