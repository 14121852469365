import React from 'react';
import { Button, Collapse } from 'react-bootstrap';

class CollapseBlock extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        };
    }

    handleClick = () => {
        this.setState({ open: !this.state.open });
    };

    render() {
        return (
            <React.Fragment>
                <Button
                    variant="link"
                    size="sm"
                    onClick={this.handleClick}
                    aria-controls="example-collapse-text"
                    aria-expanded={this.state.open}
                    className="text-left"
                    style={{ fontSize: 'inherit' }}
                >
                    {this.props.title}
                </Button>
                <Collapse in={this.state.open}>
                    <div>
                        {this.props.children}
                    </div>
                </Collapse>
            </React.Fragment>
        );
    }
}

export default CollapseBlock;