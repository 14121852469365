import {connect} from "react-redux";
import React, {Fragment} from "react";
import {Accordion, Alert, Badge, Button, Card, Col, Form, ListGroup, Modal, Nav, Row} from "react-bootstrap";
import FormGroup from "../../form/FormGroup";
import Enums, {VEHICLE_TYPES} from "../../../Enums";
import FinalQuoteForm from "./FinalQuoteForm";
import confirmationCodeService from "../../../services/ConfirmationCodeService";
import BaseForm from "../../form/BaseForm";
import {ConfirmationType, RequestStatus, Option} from "../../../Const";
import quoteService from "../../../services/QuoteService";
import dateTimeService from "../../../services/DateTimeService";
import contractorService from "../../../services/ContractorService";
import vehicleService from "../../../services/VehicleService";
import {ADMIN, HEAD_OF_DEPARTMENT, LOGISTIC, securityService} from "../../../services/SecurityService";
import organizationContractorHistoryService from "../../../services/OrganizationContractorHistoryService";
import '../css/CarrierQuoteForm.css'
import {requiredValidator} from "../../../validators/simpleValidators";
import {AuditTable} from "../../AuditTable/AuditTable";
import FormValidator from "../../../validators/FormValidator";
import QuoteRoute from "./QuoteRoute";
import contractService from "../../../services/ContractService";

function mapStateToProps(state) {
    return {
        model: state.model,
        errors: state.errors
    };
}

class CarrierQuoteForm extends BaseForm {
    constructor(props) {
        super(props);
        this.openRoutePointInfo = this.openRoutePointInfo.bind(this);
        this.onToggleAgreement = this.onToggleAgreement.bind(this);
        this.onToggleDescription = this.onToggleDescription.bind(this);
        this.state = {
            showAcceptCodeModal: false,
            mainClientContractExists: true,
            isCarrieOrgForwarderMatchesUser: false
        }
    }

    static get FIELD_LABELS() {
        return {
            "weight": "Вес (тонн)",
            "contact": "Контактное лицо",
            "width": "Ширина (м)",
            "height": "Высота (м)",
            "length": "Длина (м)",
            "volume":  <div style={{whiteSpace: "noWrap"}}>Объём (м<sup>3</sup>)</div>,
            "createdAt": "Дата создания",
            "arrivalDt": "Дата прибытия",
            "departureDt": "Дата отьезда",
            "description": "Описание",
            "cargoType": "Груз/Упаковка",
            "temperature": "Температура (°C)",
            "carcass": "Тип кузова"
        };
    }

    userHasQuoteCarrier() {
        return !this.isBooked() || this.state.isCarrieOrgForwarderMatchesUser
    }

    async componentDidMount() {
        await this.initCarrieOrgForwarderMatchesUser();
        this.setAgreementValue()
    }

    setAgreementValue() {
        const agreementDiv = document.querySelector(".agreementMessage")
        agreementDiv && (agreementDiv.innerHTML = this.props.model.agreement)
    }

    async initCarrieOrgForwarderMatchesUser() {
        if (this.isCarrier()) {
            this.setState({ isCarrieOrgForwarderMatchesUser: true })
            return;
        }

        const model = this.props.model
        const userForwarder = securityService.getUser()?.forwarder;
        if (model.carrier) {
            const response = await organizationContractorHistoryService.isCarrieOrgForwarderMatchesUser(model.carrier, userForwarder);
            this.setState({ isCarrieOrgForwarderMatchesUser: response });
        }
    }

    canBooked() {
        const model = this.props.model;

        const isTruckWithTrailer = this.state.vehicleType && this.state.vehicleType === VEHICLE_TYPES.TRUCK.value && model.trailer;
        const isNotTruck = this.state.vehicleType && this.state.vehicleType !== VEHICLE_TYPES.TRUCK.value;

        const trailerIsValid = isTruckWithTrailer || isNotTruck;

        return model.carrier
            && model.driver
            && model.truck
            && trailerIsValid
            && model.carrierContract
            && model.carrierContract.value !== Option.EMPTY_VALUE;
    }

    isFree() {
        return this.props.model.status.id === RequestStatus.FREE.id;
    }

    isNeedSignature() {
        return this.props.model.status.id === RequestStatus.ON_SIGNING.id;
    }

    isBooked() {
        return this.props.model.status.id === RequestStatus.BOOKED_BY_CARRIER.id;
    }

    isApproved() {
        return this.props.model.status.id === RequestStatus.APPROVED.id;
    }

    isOnWay() {
        return RequestStatus.getOnWayStatuses().map(s => s.id).includes(this.props.model.status.id);
    }

    isCarrier() {
        return securityService.isCarrier();
    }

    isUnloaded() {
        return this.props.model.status.id === RequestStatus.UNLOADED.id
    }

    goToQuoteList() {
        this.redirectToBackOrDefaultUrl('/quotes')
    }

    normalizeDateForRoute(date) {
        return new Date(date).toLocaleString("ru-RU",{ day: "numeric", month: "long" });
    }

    normalizeDateForFreeTo(date) {
        return new Date(date).toLocaleString("ru-RU", { day: "numeric", month: "long", hour: "numeric", minute: "numeric" });
    }

    getLabelForTopAlertPanel() {
        switch (this.props.model.status.id) {
            case RequestStatus.FREE.id:
                return <h5>Возможность забронировать завершается <Badge variant="success">{this.normalizeDateForFreeTo(this.props.model.freeTo)}</Badge></h5>
            case RequestStatus.BOOKED_BY_CARRIER.id:
                return <h5 style={{ color: "#28a745" }}>Заявка успешно забронирована и ожидает согласования</h5>
            case RequestStatus.REMOVED_FROM_PUBLICATION.id:
                return <h5>Заявка снята с публикации</h5>
        }
    }

    openChangeStatusWindow(isNext) {
        this.setState({showChangeStatusModal: true, isNext});
    }

    async sendSmsForConfirmationCode() {
        const errorString = await this.getValidationErrorString();
        if (errorString) {
            this.showToast(false, "Заполните обязательные поля", errorString);
            return;
        }

        this.setState({ code: "" });

        confirmationCodeService.sendCodeForQuote(this.props.model.id, this.getConfirmationType())
            .then(response => {
                this.setState({
                    showAcceptCodeModal: true,
                    nextTryDatetime: response.nextTryDatetime,
                    senderPhoneNumber: response.senderPhoneNumber
                });
                this.setTimerForRemainingSeconds();
            });
    }

    timer = null;
    setTimerForRemainingSeconds() {
        this.timer = setInterval(
            () => this.setState({ remainingTime: dateTimeService.calculateRemainingSeconds(this.state.nextTryDatetime) }),
            1000
        );
    }

    handleSubmitButton() {
        confirmationCodeService.submitCodeAndSaveQuote(
            this.props.model,
            this.state.code,
            this.getConfirmationType()
        )
            .then(() => {
                this.setState({showAcceptCodeModal: false});
                location.reload();
            })
            .catch(error => this.setState({ errorMessage: error.response ? error.response.data.message : null }));
    }

    getConfirmationType() {
        const quoteStatus = this.props.model.status.value;
        let confirmationType;
        switch (quoteStatus) {
            case RequestStatus.FREE.value:
            case RequestStatus.ON_SIGNING.value: {
                confirmationType = ConfirmationType.BOOKING;
                break;
            }
            default: {
                confirmationType = ConfirmationType.CANCELED;
                break;
            }
        }
        return confirmationType;
    }

    isSendingConfirmationCodeBlocked() {
        return new Date(this.state.nextTryDatetime) >= new Date()
    }

    getOnlyDigits(value) {
        return value?.replace(/\D/g, '')
    }

    handleSubmitInputConfirmationCode(newStatus) {
        let data = this.props.model;

        if (!this.isApproved()) {
            data.routePoints = this.state.isNext
                ? this.setArrivalOrDepartureDt()
                : this.clearArrivalOrDepartureDt();
        }

        data.status = newStatus;

        quoteService.save(data)
            .then(() => this.setState({showChangeStatusModal: false}))
    }

    setArrivalOrDepartureDt() {
        let routePoints = this.props.model.routePoints;
        const now = new Date();

        for (let i = 0; i < routePoints.length; i++) {
            const isArrival = routePoints[i].arrivalDt;
            const isDeparture = routePoints[i].departureDt;

            if (!isArrival) {
                routePoints[i].arrivalDt = now;
                return routePoints;
            }

            if (!isDeparture) {
                routePoints[i].departureDt = now;
                return routePoints;
            }
        }
    }

    clearArrivalOrDepartureDt() {
        let routePoints = this.props.model.routePoints;

        for (let i = 0; i < routePoints.length; i++) {
            const isArrival = routePoints[i].arrivalDt;
            const isDeparture = routePoints[i].departureDt;
            const isFirstRoutePoint = i === 0;

            if (!isFirstRoutePoint && !isArrival && !isDeparture) {
                routePoints[i - 1].departureDt = null;
                return routePoints;
            }

            if (isArrival && !isDeparture) {
                routePoints[i].arrivalDt = null;
                return routePoints;
            }
        }
    }

    isLoadingPoint(routePoint) {
        return routePoint.pointType.id === 1
    }

    isUnloadingPoint(routePoint) {
        return routePoint.pointType.id === 2
    }

    getDriverPreviousRequestStatus () {
        const routePoints = this.props.model.routePoints;

        for (let i = 0; i < routePoints.length; i++) {
            const rp = routePoints[i];
            const isFirst = i === 0;
            const isArrival = rp.arrivalDt;
            const isDeparture = rp.departureDt;

            if (isFirst && !isArrival) {
                return null
            }

            if (!isFirst && !isArrival) {
                const previousRp = routePoints[i - 1];

                if (this.isLoadingPoint(previousRp)) {
                    return RequestStatus.ON_LOADING
                }

                if (this.isUnloadingPoint(previousRp)) {
                    return RequestStatus.ON_UNLOADING
                }
            }

            if (isArrival && !isDeparture) {
                if (this.isLoadingPoint(rp)) {
                    return RequestStatus.ON_WAY_TO_LOADING
                }

                if (this.isUnloadingPoint(rp)) {
                    return RequestStatus.ON_WAY_TO_UNLOADING
                }
            }
        }
    }

    getDriverNextRequestStatus() {
        const routePoints = this.props.model.routePoints;

        for (let i = 0; i < routePoints.length; i++) {
            const rp = routePoints[i];
            const isArrival = rp.arrivalDt;
            const isDeparture = rp.departureDt;
            const isFirst = i === 0;
            const isLast = i === routePoints.length - 1
            const isStart = this.isApproved() && isFirst && !isArrival && !isDeparture;

            if (isStart) {
                if (this.isLoadingPoint(rp)) {
                    return RequestStatus.ON_WAY_TO_LOADING
                }

                if (this.isUnloadingPoint(rp)) {
                    return RequestStatus.ON_WAY_TO_UNLOADING
                }
            }

            if (!isArrival && !isDeparture && !isStart) {
                if (this.isLoadingPoint(rp)) {
                    return RequestStatus.ON_LOADING
                }

                if (this.isUnloadingPoint(rp)) {
                    return RequestStatus.ON_UNLOADING
                }
            }

            if (isArrival && !isDeparture && !isLast) {
                const nextRp = routePoints[i + 1];

                if (this.isLoadingPoint(nextRp)) {
                    return RequestStatus.ON_WAY_TO_LOADING
                }

                if (this.isUnloadingPoint(nextRp)) {
                    return RequestStatus.ON_WAY_TO_UNLOADING
                }
            }

            if (isLast && isArrival && !isDeparture) {
                return RequestStatus.UNLOADED
            }
        }
    }

    renderItem(label, value) {
        if (value) {
            return <ListGroup.Item style={{display: "flex", justifyContent: "space-between"}}>
                <span>{label}</span>
                <span>{value}</span>
            </ListGroup.Item>
        }
    }

    renderOrganizationItem(label, value) {
        if (value) {
            return <ListGroup.Item>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <span>{label}</span>
                    <span>{value}</span>
                </div>
                <div>
                    {!this.state.mainClientContractExists && <Alert
                        variant="danger"
                        style={{ marginTop: "5px", marginBottom: 0 }}
                    >
                        У перевозчика отсутствует основной договор с организацией
                    </Alert>}
                </div>
            </ListGroup.Item>
        }
    }

    handleTruckSelector(value) {
         value?.id && vehicleService.read(value.id)
             .then(vehicle => this.setState({ vehicleType: vehicle.vtype.value }))
    }

    renderCarrierData() {
        const readOnly = !this.props.model.carrier || !(this.isFree() || this.isNeedSignature());

        return <Card>
            <Card.Header>
                <h5>Данные перевозчика</h5>
            </Card.Header>
            <Card.Body style={{display: "flex"}}>
                <FormGroup title="Перевозчик"
                           name="carrier"
                           store={this.props.store}
                           type="dictionary"
                           optionsType="CONTRACTOR_CARRIER"
                           showViewIcon={true}
                           showClearIcon={this.isFree()}
                           onChangeHandler={value => this.handleChangeCarrier(value)}
                           readOnly={!this.isCarrier()  || !(this.isFree() || this.isNeedSignature())}
                />
                <FormGroup title="Водитель"
                           name="driver"
                           store={this.props.store}
                           type="driver"
                           optionsType="DRIVER"
                           showViewIcon={true}
                           showClearIcon={this.isFree()}
                           readOnly={readOnly}
                />
                <FormGroup title="Тягач"
                           name="truck"
                           vtype={[VEHICLE_TYPES.TRUCK, VEHICLE_TYPES.VAN, VEHICLE_TYPES.BUS]}
                           store={this.props.store}
                           type="vehicle"
                           showClearIcon={this.isFree()}
                           showViewIcon={true}
                           onChangeHandler={value =>this.handleTruckSelector(value)}
                           readOnly={readOnly}
                />
                <FormGroup title="Полуприцеп"
                           name="trailer"
                           vtype={VEHICLE_TYPES.TRAILER}
                           store={this.props.store}
                           type="vehicle"
                           showClearIcon={this.isFree()}
                           showViewIcon={true}
                           readOnly={readOnly}
                />
                <FormGroup title="Договор"
                           name="carrierContract"
                           store={this.props.store}
                           contractor={this.props.model.carrier}
                           contractorTypeId={FinalQuoteForm.CONTRACTOR_CARRIER_ID}
                           organization={this.props.model.carrierOrganization}
                           type="contract"
                           readOnly={readOnly}
                           onChangeHandler={async (value) => {await this.updateFieldsFromContract(
                               value, FinalQuoteForm.CONTRACT_FIELD_MAP_CARRIER, FinalQuoteForm.CONTRACTOR_CARRIER_ID);
                           }}
                />
            </Card.Body>
        </Card>
    }

    async handleChangeCarrier(carrier) {
        const model = this.props.model;

        if (model.id && model.contractor && carrier) {
            const response = await contractorService.mainContractExists(model.id, model.contractor.id, carrier.id);
            this.setState({ mainClientContractExists: response.carrierContractExists });
        }
    }

    async updateFieldsFromContract(contract, fieldMap) {
        if (contract?.id) {
            const contractDto = await contractService.read(contract.id);
            this.updateContractFields(contractDto, fieldMap);
        }
    }

    updateContractFields(contract, fieldMap) {
        Object.entries(fieldMap).forEach(([key, value]) => {
            this.onChange(key, contract[value]);
        });
    }

    renderButtons() {
        const renderCancelButton = (this.isBooked() || this.isOnWay()) && this.userHasQuoteCarrier() && this.isCarrier();
        const cancelLabel = this.isBooked() ? "Отменить бронирование" : "Отказаться от рейса";

        return <Card.Body style={{ display: "flex", justifyContent: "center" }}>
            {this.renderAddLinkedQuoteButton()}
            {this.renderSignedButton()}
            &nbsp;
            {this.isFree() && this.isCarrier() &&
                <Button
                    size="md"
                    variant="primary"
                    className="pull-right"
                    disabled={!this.canBooked()}
                    onClick={() => this.sendSmsForConfirmationCode()}
                >
                    <span>Забронировать</span>
                </Button>
            }
            {renderCancelButton &&
                <Button
                    size="md"
                    variant="danger"
                    className="pull-right"
                    onClick={() => this.sendSmsForConfirmationCode()}
                >
                    <span>{cancelLabel}</span>
                </Button>
            }
            &nbsp;
            <Button
                size="md"
                variant="secondary"
                onClick={() => this.goToQuoteList()}
            >
                <span>Вернуться к списку</span>
            </Button>
        </Card.Body>
    }

    renderSignedButton() {
        const show = !this.isFree() && this.isCarrier();
        if (!show) {
            return <Fragment/>
        }

        const {signingStatus} = this.props.model;
        const currentQuoteIsSigning = Enums.SIGNING_STATUS[signingStatus.value].value === Enums.SIGNING_STATUS.SIGNED.value;
        return (
            <Button
                hidden={currentQuoteIsSigning}
                size="md"
                variant="primary"
                className="pull-right"
                onClick={() => this.sendSmsForConfirmationCode()}>
                <span>Подписать</span>
            </Button>
        );
    }

    async getValidationErrorString() {
        const {model} = this.props;

        const validationMap = {
            carrier: await requiredValidator(model.carrier),
            carrierContract: await this.carrierContractValidator(model.carrierContract),
            driver: await requiredValidator(model.driver),
            truck: await requiredValidator(model.truck),
            trailer: await requiredValidator(model.trailer)
        };

        let errorFields = [];
        Object.keys(validationMap).forEach(key => {
            if (validationMap[key]) {
                errorFields.push(AuditTable.FIELD_LABELS[key])
            }
        });

        return errorFields.join(', ');
    }

    async carrierContractValidator() {
        const isValid = this.props.model.carrierContract && this.props.model.carrierContract.id;
        return Promise.resolve(isValid ? FormValidator.OK : "Обязательное поле");
    }

    showToast(success, header, message) {
        const className = success ? "bg-success" : "bg-danger";
        const data = { show: true, delay: 3000, textHeader: header, textBody: message, className: className };
        this.props.setToast(data);
    }

    renderAddLinkedQuoteButton() {
        const userForwarder = securityService.getUser().forwarder;
        const quoteForwarder = this.props.model.forwarder;
        const forwarderIsDifferent = userForwarder?.id !== quoteForwarder?.id;
        const isFree = RequestStatus.FREE.value === this.props.model?.status?.value;
        const render = securityService.anyRoles(this.props.user, ADMIN, LOGISTIC, HEAD_OF_DEPARTMENT) && forwarderIsDifferent && isFree;
        return render &&
            <Button className="mr-4" variant="outline-primary" size="md"
                    href={"/quotes/quote?id=" + this.props.model.id  + "&mode=creation&baseOn=true"}
                    target="_blank"
            >
                <i className="fas fa-plus mr-1"></i>
                <span>Создать заявку на основании</span>
            </Button>
    }

    renderTopAlertPanel(label) {
        return label && <Card>
            <Card.Body style={{display: "flex", justifyContent: "center"}}>
                {label}
            </Card.Body>
        </Card>
    }

    renderStatusChangePanel() {
        const previousStatus = this.getDriverPreviousRequestStatus();
        const nextStatus = this.getDriverNextRequestStatus();
        const currentStatus = this.props.model.status;

        return <div style={{display: "flex", justifyContent: "space-between", opacity: 0.9}}>
            {previousStatus
                ? <Button onClick={() => this.openChangeStatusWindow(false)}
                          variant="outline-danger">
                    {"« " + previousStatus?.value}
                </Button>
                : <div></div>
            }

            <Button disabled
                    variant="light"
                    style={{opacity: 1}}>
                <b>{this.props.model.status.value}</b>
            </Button>

            {nextStatus
                ? <Button disabled={currentStatus.id === RequestStatus.UNLOADED.id}
                          onClick={() => this.openChangeStatusWindow(true)}
                          variant="primary">
                    {nextStatus.value + " »"}
                </Button>
                : <div></div>
            }
        </div>
    }

    renderModalForChangeQuoteStatus() {
        const newStatus = this.state.isNext
            ? this.getDriverNextRequestStatus()
            : this.getDriverPreviousRequestStatus();

        return <Modal show={this.state.showChangeStatusModal}
                      onHide={!this.state.showChangeStatusModal}
                      aria-labelledby="example-modal-sizes-title-sm">
            <Modal.Header>
                <Modal.Title>Изменение статуса заявки</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Label>
                    Поменять статус заявки на <b>{newStatus?.value}</b>?
                </Form.Label>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary"
                        onClick={() => this.handleSubmitInputConfirmationCode(newStatus)}>
                    Подтвердить
                </Button>
                <Button variant="secondary"
                        onClick={() => this.setState({showChangeStatusModal: false})}>
                    Закрыть
                </Button>
            </Modal.Footer>
        </Modal>
    }

    renderModalAcceptCode() {
        return <Modal show={this.state.showAcceptCodeModal}
                      onHide={!this.state.showAcceptCodeModal}
                      aria-labelledby="example-modal-sizes-title-sm">
            <Modal.Header>
                <Modal.Title>Подтверждение {this.isBooked() && "отмены"} бронирования</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {this.isBooked() || this.isOnWay() &&
                    <Alert variant="danger">
                        Внимание! Отмена бронирования может<br/>повлечь за собой штрафные санкции.
                    </Alert>
                }
                <Form.Label>Введите код подтверждения:</Form.Label>
                <Form.Control
                    type="text"
                    value={this.state.code}
                    maxLength={6}
                    min={0}
                    step={1}
                    onKeyUp={event => {if (event.key === 'Enter') this.handleSubmitButton()}}
                    onChange={e => this.setState({code: this.getOnlyDigits(e.target.value)})}
                />
                {this.state.errorMessage &&
                    <Alert variant="danger" style={{ marginTop: "5px" }}>
                        {this.state.errorMessage}
                    </Alert>
                }
                <Form.Text muted>
                    <div>СМС с кодом подтверждения был выслан по номеру {this.state.senderPhoneNumber}</div>
                    <Nav.Link variant="link"
                              style={{ padding: 0 }}
                              disabled={this.isSendingConfirmationCodeBlocked()}
                              onClick={() => this.sendSmsForConfirmationCode()}
                    >
                        Запросить код подтверждения повторно {this.state.remainingTime ? "можно через " + this.state.remainingTime : ""}
                    </Nav.Link>
                </Form.Text>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary"
                        onClick={() => this.handleSubmitButton()}>
                    Подтвердить
                </Button>
                <Button variant="secondary"
                        onClick={() => this.setState({ showAcceptCodeModal: false, errorMessage: null })}>
                    Закрыть
                </Button>
            </Modal.Footer>
        </Modal>
    }

    renderRoute() {
        return <QuoteRoute quote={this.props.model} renderHeader={() => this.renderRouteHeader()}/>;
    }

    renderRouteHeader() {
        return (
            <Card.Header>
                {
                    RequestStatus.getOnWayStatuses().map(rs => rs.id).includes(this.props.model.status.id)
                        ? this.renderStatusChangePanel()
                        : this.renderRouteTitle()
                }
            </Card.Header>
        );
    }

    renderRouteTitle() {
        const {model} = this.props;
        const signingStatus = Enums.SIGNING_STATUS[model.signingStatus?.value].label;
        return (
            <Card.Title>
                <Row>
                    <Col>
                        {this.isUnloaded() ? "Заявка завершена!" : "Маршрут"}
                    </Col>
                    <div className={"d-flex flex-column justify-end"}>
                        <div>
                            <span className={"h6"}>Статус заявки: {model.status?.value} </span>
                        </div>
                        <div>
                            <span className={"h6"}>Статус подписания: {signingStatus} </span>
                        </div>
                    </div>
                </Row>
            </Card.Title>
        );
    }

    openRoutePointInfo(id) {
        const routePointInfoElem = document.querySelector(`.routePointInfo_${id}`)
        const openRoutePBtn = document.querySelector(".openRoutePBtn")
        openRoutePBtn.style.display = "none"
        routePointInfoElem.style.display = "flex"
    }

    renderQuoteInfo() {
        return <Card style={{ width: "30%" }}>
            <Card.Header>
                <Card.Title>Ставка: {Number(this.props.model.carrierPrice).toLocaleString()} ₽</Card.Title>
            </Card.Header>
            <ListGroup>
                {this.renderOrganizationItem("Организация перевозчика", this.props.model.carrierOrganization?.value)}
                {this.renderItem("Режим НДС", this.props.model.carrierVatMode?.value)}
                {this.renderItem("Условия оплаты", this.props.model.carrierPaymentMode?.value)}
                {this.renderItem("Дополнительные расходы", Number(this.props.model.carrierExpenses).toLocaleString() + " ₽")}
                {this.renderItem("Доля ГСМ от ставки", Number(this.props.model.carrierPaymentDelay) + " %")}
                {this.renderItem("Отсрочка платежа", Number(this.props.model.carrierPaymentDelay) + " дней")}
                {this.renderItem("Лимит ГСМ", Number(this.props.model.carrierGsm) + " ₽")}
            </ListGroup>
        </Card>
    }

    renderDescription() {
        const {isOpenDescription} = this.state;

        return <Card className="carrierQuote__description">
            <Card.Header onClick={this.onToggleDescription}>
                <Card.Title>Описание заявки</Card.Title>
                <div className={isOpenDescription ? "arrow-up" : "arrow-down"}></div>
            </Card.Header>
            <Card.Body>
                <pre style={{maxHeight: isOpenDescription ? "400px" : "50px", overflow: isOpenDescription ? "auto" : "hidden"}}>{this.props.model.description}</pre>
            </Card.Body>
        </Card>
    }

    onToggleAgreement() {
        this.setState({isOpenAgreement: !this.state.isOpenAgreement})
    }

    onToggleDescription() {
        this.setState({isOpenDescription: !this.state.isOpenDescription})
    }

    renderAgreement() {
        const {isOpenAgreement} = this.state;
        return <Card className="carrierQuote__agreementQuote">
            <Card.Header onClick={this.onToggleAgreement}>
                <Card.Title>Условия заказчика</Card.Title>
                <div className={isOpenAgreement ? "arrow-up" : "arrow-down"}></div>
            </Card.Header>
            <Card.Body>
                <pre style={{maxHeight: isOpenAgreement ? "400px" : "50px", overflow: isOpenAgreement ? "auto" : "hidden"}} className="agreementMessage"/>
            </Card.Body>
        </Card>
    }

    renderMainForm() {
        return <Form>
            {this.renderModalAcceptCode()}
            {this.renderModalForChangeQuoteStatus()}

            {this.renderTopAlertPanel(this.getLabelForTopAlertPanel())}

            <div className="carrierQuote__mainForm">
                <div className="carrierQuote__mainForm-div">
                    {this.renderRoute()}
                    {this.renderDescription()}
                    {this.renderAgreement()}
                </div>
                {this.renderQuoteInfo()}
            </div>

            {this.renderCarrierData()}
        </Form>
    }

    render() {
        return <Form>
            {
                this.userHasQuoteCarrier()
                    ? this.renderMainForm()
                    : this.renderTopAlertPanel("Заявка отменена или забронирована другим пользователем")
            }
            {this.renderButtons()}
        </Form>
    }
}
export default connect(mapStateToProps)(CarrierQuoteForm);