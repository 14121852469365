import React, {PureComponent} from "react";

class AreaChart extends PureComponent {
    constructor(props) {
        super(props);
    }

    /**
     *  График показывает доли от общего количества с разрезом по датам.
     *  Данные для графика добавляются в виде объекта с полями:
     *  {names: [string], values: [...[number]], scale: [...{start: date, end: date}], totals: [number]}
     *  x, y - количество элементов по осям
     *  names - подписи к долям (y штук)
     *  scale - шкала / срезы для oX (x штук)
     *  values - список (y штук) списков (x штук) значений names по oY
     *  totals- суммы values по срезу (x штук)
     * */
    DATA = this.props.data;
    WIDTH = this.props.width ? this.props.width : "500";
    HEIGHT = this.props.height ? this.props.height : "200";
    ID = "chart" + Math.random().toString(36);

    componentDidMount() {
        if (this.DATA) {
            this.chart();
        }

        this.setState({ data: this.props.data });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.DATA && this.props.data !== prevProps.data) {
            this.DATA = this.props.data;
            this.chart();
        }
    }

    chart() {
        const colors = [
            { light: "rgba(253,127,111,0.7)", dark: "rgba(253,127,111,0.9)"},
            { light: "rgba(126,176,213,0.7)", dark: "rgba(126,176,213,0.9)"},
            { light: "rgba(178,224,97,0.7)",  dark: "rgba(178,224,97,0.9)"},
            { light: "rgba(189,126,190,0.7)", dark: "rgba(189,126,190,0.9)"},
            { light: "rgba(255,181,90,0.7)",  dark: "rgba(255,181,90,0.9)"},
            { light: "rgba(255,238,101,0.7)", dark: "rgba(255,238,101,0.9)"},
            { light: "rgba(190,185,219,0.7)", dark: "rgba(190,185,219,0.9)"},
            { light: "rgba(253,204,229,0.7)", dark: "rgba(253,204,229,0.9)"},
            { light: "rgba(139,211,199,0.7)", dark: "rgba(139,211,199,0.9)"}
        ];
        let lineChartNumber;
        const data = this.DATA;
        const lengthX = this.DATA.scale.length;
        const lengthY = this.DATA.values.length;

        // Клонирование элемента, чтобы избавиться от всех eventListener`ов
        const element = document.getElementById(this.ID);
        const canvas = element.cloneNode(true);
        element.parentNode.replaceChild(canvas, element);

        const context = canvas.getContext("2d");
        context.font = "18px Segoe UI";

        const width = canvas.width;
        const height = canvas.height;

        const scaleX = width / (lengthX - 1);
        const scaleY = height / Math.max(...data.totals);

        // определение точек для зон
        let areas = [];
        for (let i = 0; i < lengthY; i++) {
            const previousValue = (j) => {
                let res = 0;
                for (let k = 0; k < i; k++) {
                    res += data.values[k][j];
                }

                return res;
            }

            const points = [];
            for (let j = 0; j < lengthX; j++) {
                points.push({x: scaleX * j, y: height - ((data.values[i][j])  * scaleY) - (previousValue(j) * scaleY)});
            }

            for (let j = lengthX - 1; j >= 0; j--) {
                points.push({x: scaleX * j, y: height - (previousValue(j)  * scaleY)});
            }

            areas.push({
                points: points,
                label: data.names[i],
                color: colors[i % colors.length],
                totals: data.totals,
                isHover: false
            });
        }

        drawChartsAndAddEventListeners();

        function drawChartsAndAddEventListeners() {
            canvas.removeEventListener("click", drawCharts);
            canvas.removeEventListener("mousemove", drawLineChart);
            drawCharts();
            canvas.addEventListener("mousemove", showInfo);
            canvas.addEventListener("click", choseChart);
        }

        function choseChart(event) {
            const mouse = getMousePosition(event);

            for (let i = 0; i < areas.length; i++) {
                for (let j = 0; j < areas[i].points.length / 2; j++) {
                    if (isMouseOnArea(i, j, mouse)) {

                        lineChartNumber = i;
                        drawLineChart();

                        canvas.removeEventListener('mousemove', showInfo);
                        canvas.removeEventListener("click", choseChart);

                        canvas.addEventListener("mousemove", drawLineChart);
                        canvas.addEventListener("click", drawChartsAndAddEventListeners);
                    }
                }
            }
        }

        function getMousePosition(event) {
            return {
                x: event?.clientX - canvas.getBoundingClientRect().left,
                y: event?.clientY - canvas.getBoundingClientRect().top
            };
        }

        function drawLineChart(event) {
            const maxValue = Math.max(...data.values[lineChartNumber]);
            const minValue = Math.min(...data.values[lineChartNumber]);

            context.clearRect(0, 0, width, height);
            drawOxOy();
            context.beginPath();
            context.lineTo(0, height);

            const marginBottom = minValue < 0 ? Math.abs(minValue) : 0;
            const localScaleY = height / (Math.abs(maxValue) + marginBottom);
            const localHigh = height - (marginBottom * localScaleY);
            for (let i = 0; i < lengthX; i++) {
                context.lineTo(scaleX * i, localHigh - data.values[lineChartNumber][i] * localScaleY);
            }

            context.lineTo(width, localHigh);
            context.lineTo(0, localHigh);
            context.fillStyle = areas[lineChartNumber].color.light;
            context.fill();

            const mouse = getMousePosition(event);
            for (let i = 0; i < lengthX; i++) {
                const xStart = scaleX * i;
                const xEnd = scaleX * (i + 1);
                const xMid = xStart + scaleX / 2;

                if (mouse.x > xStart && mouse.x < xMid) {
                    drawVerticalLine(xStart);
                    drawLabel(mouse, lineChartNumber, i)
                }

                if (mouse.x > xMid && mouse.x < xEnd) {
                    drawVerticalLine(xEnd);
                    drawLabel(mouse, lineChartNumber, i + 1)
                }
            }

            drawTopScale();
            drawLeftScale(maxValue, minValue, localScaleY);
        }

        function drawOxOy() {
            context.lineWidth = 1;
            context.strokeStyle = "rgba(0,0,0,0.2)";

            // Ox
            for (let i = 1; i < lengthX - 1; i++) {
                context.beginPath();
                context.moveTo(scaleX * i, 0);
                context.lineTo(scaleX * i, height);
                context.stroke();
            }

            // Oy
            for (let i = 1; i < 10; i++) {
                context.beginPath();
                context.moveTo(0, (height / 10) * i);
                context.lineTo(width, (height / 10) * i);
                context.stroke();
            }
        }

        function drawTopScale() {
            context.fillStyle = "black";
            context.textAlign = "center";
            context.font = lengthX > 12 ? "14px" : "18px";

            for (let i = 1; i < lengthX - 1; i++) {
                context.fillText(getMonthName(data.scale[i].start), scaleX * i, 15);
            }

            context.font = "18px";
        }

        function drawLeftScale(maxValue, minValue) {
            context.fillStyle = "black";

            const marginBottom = minValue < 0 ? Math.abs(minValue) : 0;
            const localScaleY = height / (Math.abs(maxValue) + marginBottom);
            const localHigh = height - (marginBottom * localScaleY);

            for (let i = 0; i < 10; i++) {
                context.textAlign = "right";
                const value = (minValue + ((maxValue + Math.abs(minValue)) / 10) * i);
                context.fillText(Math.round(value).toLocaleString(), width, localHigh - value * localScaleY);
            }
        }

        function getMonthName(date) {
            return new Date(date).toLocaleString("ru-RU", { month: lengthX > 12 ? "short" : "long" });
        }

        function drawCharts() {
            context.clearRect(0, 0, width, height);
            drawOxOy();
            for (let i = 0; i < lengthY; i++) {
                drawChart(i);
            }
            drawTopScale();
        }

        function drawChart(chartNumber) {
            context.beginPath();

            for (let i = 0; i < areas[chartNumber].points.length; i++) {
                context.lineTo(areas[chartNumber].points[i].x, areas[chartNumber].points[i].y);
            }

            context.fillStyle = areas[chartNumber].isHover ? areas[chartNumber].color.dark : areas[chartNumber].color.light;
            context.fill();
        }

        function isMouseOnArea(i, j, mouse) {
            const pointsNumber = areas[i].points.length;

            const p1 = { x: areas[i].points[j].x, y: areas[i].points[j].y }
            const p2 = { x: areas[i].points[j + 1].x, y: areas[i].points[j + 1].y }
            const p3 = { x: areas[i].points[pointsNumber - 2 - j].x, y: areas[i].points[pointsNumber - 2 - j].y }
            const p4 = { x: areas[i].points[pointsNumber - 1 - j].x, y: areas[i].points[pointsNumber - 1 - j].y }

            const eq1 = equationOfLine(p1, p2);
            const eq2 = equationOfLine(p3, p4);

            const val1 = eq1.A * mouse.x + eq1.B * mouse.y + eq1.C;
            const val2 = eq2.A * mouse.x + eq2.B * mouse.y + eq2.C;

            const isOnX = mouse.x > p1.x && mouse.x < p2.x;
            const isOnY = (val1 > 0 && val2 > 0) || (val1 < 0 && val2 < 0);

            return isOnX && isOnY;
        }

        function showInfo(event) {
            const mouse = getMousePosition(event);

            for (let i = 0; i < areas.length; i++) {
                for (let j = 0; j < areas[i].points.length / 2; j++) {
                    if (isMouseOnArea(i, j, mouse)) {
                        areas[i].isHover = true;
                        drawCharts();
                        const pointNumber = mouse.x - areas[i].points[j].x < scaleX / 2 ? j : j + 1;
                        drawVerticalLine(areas[i].points[pointNumber].x);
                        drawLabel(mouse, i, pointNumber);
                    }

                    areas[i].isHover = false;
                }
            }
        }

        function drawLabel(mouse, oyNumber, oxNumber) {
            const marginRight = width - mouse.x - 450 < 0 ? 450 : -20;
            const marginBottom = height - mouse.y - 70 < 0 ? 70 : 0;
            const padding = 5;

            context.fillStyle = "rgba(255, 255, 255, 0.7)";
            context.fillRect(mouse.x - marginRight, mouse.y - marginBottom, 440, 65);

            context.textAlign = "left";
            context.fillStyle = "black";
            context.fillText(data.scale[oxNumber].start + " - " + data.scale[oxNumber].end,
                mouse.x - marginRight + padding, mouse.y + 20 - marginBottom);
            context.fillText(data.names[oyNumber], mouse.x - marginRight + padding, mouse.y + 40 - marginBottom);
            context.fillText(Number(data.values[oyNumber][oxNumber]).toLocaleString() + " / "
                + Number(data.totals[oxNumber]).toLocaleString() + " = "
                + (Math.round(data.values[oyNumber][oxNumber] / data.totals[oxNumber] * 10000) / 100) + "%",
                mouse.x - marginRight + padding, mouse.y + 60 - marginBottom);
        }

        function drawVerticalLine(x) {
            context.beginPath();
            context.moveTo(x, 0);
            context.lineTo(x, height);
            context.lineWidth = 2;
            context.strokeStyle = "black";
            context.setLineDash([2, 2]);
            context.stroke();
            context.setLineDash([]);

        }

        function equationOfLine(p1, p2) {
            const A = p1.y - p2.y;
            const B = p2.x - p1.x;
            const C = p1.x * p2.y - p2.x * p1.y;
            return { A, B, C };
        }
    }

    render() {
        if (this.DATA) {
            return <div style={{padding: "15px", width: "fit-content"}}>
                <canvas id={this.ID}
                        width={this.WIDTH}
                        height={this.HEIGHT}
                        className="canvas-chart">
                </canvas>
            </div>
        }

        return <div>Нет данных для графика</div>
    }
}

export default AreaChart;