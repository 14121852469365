import {HttpService} from "../HttpService/HttpService";

export class AuctionService {
    static async createAuction(data) {
        return HttpService.post("/api/v1/auction/", data)
    }

    static async updateAuction(data) {
        const {id} = data;
        return HttpService.put(`/api/v1/auction/${id}`, data)
    }

    static async getAuctionById(id) {
        return HttpService.get(`/api/v1/auction/${id}`)
    }

    static async makeBid(data) {
        const {id} = data;
        return HttpService.post(`/api/v1/auction/${id}/bid`, data)
    }

    static async getBidsHistory(id) {
        return HttpService.get(`/api/v1/auction/${id}/bids/`)
    }

    static async cancelAuction(id) {
        return HttpService.put(`/api/v1/auction/${id}/cancel/`)
    }
}