import React from "react";
import AsyncSelect from 'react-select/async'

class BaseAsyncSelect extends React.PureComponent {

	constructor(props) {
		super(props);
	}

	loadOptions() {
		// implement in child class
	}

	render() {
		return (
			<AsyncSelect
				value={this.props.value}
				loadOptions={this.loadOptions}
				onChange={(e) => this.props.onChange(e)}
				getOptionLabel={option => option.value}
				getOptionValue={option => option.id}
				loadingMessage={() => "загрузка"}
				noOptionsMessage={() => "нет данных"}
				placeholder={"введите 3 символа"}
			/>
		);
	}
}

export default BaseAsyncSelect;
