import {PureComponent} from "react";
import {classNames} from "../../../helpers/classNames";
import cls from "./AuctionBidInfo.module.css"

export class AuctionBidInfo extends PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            textComponent,
            ndsComponent,
            priceComponent,
            className
        } = this.props;

        return (
            <div className={classNames(cls.wrapper, {}, [className])}>
                {textComponent}
                {ndsComponent}
                {priceComponent}
            </div>
        )
    }
}