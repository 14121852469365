import BaseForm from "./form/BaseForm";
import {Button, Modal} from "react-bootstrap";

class Notification extends BaseForm{
    constructor(props) {
        super(props);
    }

    render() {
        const { show, onClose, message } = this.props;
        return (
            <Modal show={show} onHide={onClose} backdrop={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Уведомление</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {message}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onClose}>Закрыть</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
export default Notification;