import {PureComponent} from "react";
import {Alert} from "react-bootstrap";
import {classNames} from "../../../helpers/classNames";
import cls from './AuctionResult.module.css'

class AuctionResult extends PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            className,
            isWinner,
            status
        } = this.props;

        let component;

        if (status === "canceled") {
            component = (
                <Alert variant="danger">
                    <Alert.Heading>Аукцион отменен</Alert.Heading>

                    <p>
                        По техническим причинам аукцион отменен.
                    </p>
                </Alert>
            )
        }

        if (status === "completed") {
            component = (
                <Alert variant="success">
                    <Alert.Heading>Аукцион завершен</Alert.Heading>

                    {
                        isWinner && (
                            <p>Вы выиграли.</p>
                        )
                    }
                </Alert>
            )
        }

        if(!component) return;

        return (
            <div className={classNames(cls.results, {}, [className])}>
                {component}
            </div>
        )
    }
}

export default AuctionResult;