import React, {PureComponent} from "react";
import cls from "./TheDatepicker.module.css";
import {Form} from "react-bootstrap";
import DatePicker from "react-datepicker";
import {classNames} from "../../../helpers/classNames";
import moment from "moment/moment";
import FieldErrors from "../FieldError/FieldError";
import ru from "date-fns/locale/ru";

export class TheDatepicker extends PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            label,
            className,
            value,
            errors,
            name,
            onChange,
        } = this.props;

        const dt = value ? new Date(value) : null;

        const onChangeHandler = (date) => {
            const value = moment(date).toISOString();

            onChange(value);
        }

        return (
            <div className={classNames(cls.datepicker, {}, [className])}>
                <Form.Label>{label}</Form.Label>

                <DatePicker
                    showTimeSelect={true}
                    name={name}
                    onChange={onChangeHandler}
                    dateFormat={["dd.MM.yyyy HH:mm"]}
                    autoComplete="off"
                    className="form-control form-control-sm"
                    selected={dt}
                    locale={ru}
                />

                {(!!errors?.length) && <FieldErrors errors={errors}/>}
            </div>
        )
    }
}