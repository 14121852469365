import * as actionTypes from './bidsHistoryActionTypes';

export const fetchBidsHistoryData = () => ({
    type: actionTypes.FETCH_DATA
});

export const fetchBidsHistoryDataSuccess = (data) => ({
    type: actionTypes.FETCH_DATA_SUCCESS,
    payload: data
});

export const fetchBidsHistoryDataFailure = (error) => ({
    type: actionTypes.FETCH_DATA_FAILURE,
    payload: error
});

export const setBidsHistory = (value) => {
    return {
        type: actionTypes.SET_BIDS,
        payload: value
    }
}

export const setBidsHistoryErrors = (value) => {
    return {
        type: actionTypes.SET_ERROR,
        payload: value
    }
}

export const setBidsHistoryIsLoading = (value) => {
    return {
        type: actionTypes.SET_LOADING,
        payload: value
    }
}