import BaseForm from "../../form/BaseForm";
import {Alert, Button, Col, Form, Modal, Nav, Row} from "react-bootstrap";
import React, {Fragment} from "react";
import dateTimeService from "../../../services/DateTimeService";
import QuoteRoute from "./QuoteRoute";
import FormGroup from "../../form/FormGroup";
import FinalQuoteForm from "./FinalQuoteForm";
import StoreWrapper from "../../form/StoreWrapper";
import {connect} from "react-redux";
import organizationContractorHistoryService from "../../../services/OrganizationContractorHistoryService";
import FormValidator from "../../../validators/FormValidator";
import confirmationCodeService from "../../../services/ConfirmationCodeService";
import {ConfirmationType} from "../../../Const";

class QuoteSignatureModal extends StoreWrapper {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <QuoteSignatureModalInnerConnected {...this.props} store={this.store} />
        );
    }
}

function mapStateToProps(state) {
    return {
        model: state.model,
        errors: state.errors,
        notShowToast: true
    };
}

class QuoteSignatureModalInner extends BaseForm {
    constructor(props) {
        super(props);

        this.state = {
            code : "",
            errorMessage: "",
            senderPhoneNumber: "",
            codeIsSending: false,
            carrier: null
        };

        this.configureValidators();
    }

    configureValidators() {
        this.validator.addValidator('contract', this.contractValidator.bind(this));
    }

    async contractValidator() {
        const {model} = this.props;
        const isValid = model.contract && model.contract.id;
        return Promise.resolve(isValid ? FormValidator.OK : "Обязательное поле");
    }

    render() {
        const {show, onClose} = this.props;
        return (
            <Modal show={show} onHide={onClose} backdrop="static">
                {this.renderModalHeader()}
                {this.renderModalBody()}
                {this.renderModalFooter()}
            </Modal>
        );
    }

    renderModalHeader() {
        return (
            <Modal.Header>
                <Modal.Title>Подтверждение бронирования</Modal.Title>
            </Modal.Header>
        );
    }

    renderModalBody() {
        return (
            <Modal.Body>
                <Form>
                    <QuoteRoute quote={this.props.quote}/>
                    {this.renderContractorForm()}
                    {this.renderCodeConfirmationForm()}
                </Form>
            </Modal.Body>
        );
    }

    renderContractorForm() {
        const {quote} = this.props;
        const {carrier} = this.state;
        return (
            <div>
                <Row className={"ml-1"}>
                    <Col>
                        <span className={"h6"}>
                            Заказчик: {quote.mainQuote.carrierOrganization?.value}
                        </span>
                    </Col>
                    <Col>
                        <span className={"h6"}>
                            Перевозчик: {carrier?.value}
                        </span>
                    </Col>
                </Row>
                <Row className={"mt-4 d-flex justify-center"}>
                    <Col>
                        <FormGroup
                            title="Выберите договор, в рамках которого будет выполняться заказ"
                            name="contract"
                            store={this.props.store}
                            contractor={carrier}
                            contractorTypeId={FinalQuoteForm.CONTRACTOR_CARRIER_ID}
                            organization={quote.mainQuote.carrierOrganization}
                            type="contract"
                        />
                    </Col>
                </Row>
            </div>
        );
    }

    renderCodeConfirmationForm() {
        const {code, senderPhoneNumber, errorMessage, remainingTime, codeIsSending} = this.state;
        const onKeyUp = async (e) => {
            if (e.key === 'Enter') {
                await this.handleConfirm();
            }
        }

        if (!codeIsSending) {
            return <Fragment/>
        }

        return (
            <div className={"ml-1 mr-1"}>
                <Form.Label>Введите код подтверждения:</Form.Label>
                <Form.Control
                    type="text"
                    value={code}
                    maxLength={6}
                    min={0}
                    step={1}
                    onKeyUp={ (e) => onKeyUp(e)}
                    onChange={e => this.setState({code: this.getOnlyDigits(e.target.value)})}
                />
                {errorMessage &&
                    <Alert variant="danger" className="mt-3">
                        {errorMessage}
                    </Alert>
                }
                <Form.Text muted>
                    <div>СМС с кодом подтверждения был выслан по номеру {senderPhoneNumber}</div>
                    <Nav.Link variant="link"
                              className="p-0"
                              disabled={this.isSendingConfirmationCodeBlocked()}
                              onClick={() => this.smsRequest()}>
                        Запросить код подтверждения повторно {remainingTime ? "можно через " + remainingTime : ""}
                    </Nav.Link>
                </Form.Text>
            </div>
        );
    }

    renderModalFooter() {
        const {onClose} = this.props;
        const {codeIsSending} = this.state;
        const handleClose = () => {
            onClose();
            this.setState({ errorMessage: null });
        }
        return (
            <Modal.Footer>
                <Button className={"center"} hidden={codeIsSending} onClick={() => this.smsRequest()}>
                    Получить код подтверждения
                </Button>
                <Button variant="primary" onClick={() => this.handleConfirm()} hidden={!codeIsSending}>
                    Подтвердить
                </Button>
                <Button variant="secondary"
                        onClick={() => handleClose()}>
                    Закрыть
                </Button>
            </Modal.Footer>
        );
    }

    smsRequest() {
        this.submit(async () => {
            this.setState({codeIsSending: true});

            const quoteId = this.props.quote.mainQuote.id;
            const response = await confirmationCodeService.sendCodeForQuote(quoteId, ConfirmationType.BOOKING);
            this.setState({
                showSignatureModal: true,
                senderPhoneNumber: response.senderPhoneNumber
            });
            this.setTimerForRemainingSeconds(response.nextTryDatetime);
        });
    }

    setTimerForRemainingSeconds(nextTryDatetime) {
        this.timer = setInterval(() => {
            const remainingTime = dateTimeService.calculateRemainingSeconds(nextTryDatetime);
            this.setState({ remainingTime });

            if (remainingTime <= 0) {
                clearInterval(this.timer);
            }
        }, 1000);
    }

    handleConfirm() {
        const {submit, onClose, model} = this.props;
        const {code, carrier} = this.state;
        const request = {code, carrier, contract: model.contract};
        submit(request)
            .then(() => {
                onClose();
                location.reload();
            })
            .catch(error => this.setState({ errorMessage: error.response ? error.response.data.message : null }));
    }

    getOnlyDigits(value) {
        return value?.replace(/\D/g, '')
    }

    isSendingConfirmationCodeBlocked() {
        return new Date(this.state.nextTryDatetime) >= new Date();
    }

    async componentDidMount() {
        const response = await organizationContractorHistoryService.getLastOrganizationsContractor(this.props.quote.organization.id);
        this.setState({carrier: response});
    }
}


const QuoteSignatureModalInnerConnected = connect(mapStateToProps)(QuoteSignatureModalInner);

export default QuoteSignatureModal;
