import BaseSelect from "./BaseSelect";
import paymentAccountService from '../../services/PaymentAccountService';

class PaymentAccountSelect extends BaseSelect {

	constructor(props) {
		super(props);
	}
	
	fetchOptions() {
		return paymentAccountService.options({owner: this.props.owner, field: this.props.field});
	}
}

export default PaymentAccountSelect;