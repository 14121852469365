export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const ADD_ALERT = "ADD_ALERT"

export const REGISTRATION_SUCCESS = "REGISTRATION_SUCCESS";
export const REGISTRATION_FAIL = "REGISTRATION_FAIL";

export const CONFIRMATION_SUCCESS = "CONFIRMATION_SUCCESS";
export const CONFIRMATION_FAIL = "CONFIRMATION_FAIL";

export const MESSAGE_SET = "MESSAGE_SET";
export const MESSAGE_CLEAR = "MESSAGE_CLEAR";
export const SET_TOAST_OBJ = "SET_TOAST_OBJ"
export const UNSHOW_TOAST = "UNSHOW_TOAST"

export const SHOW_WELCOME_MODAL = "SHOW_WELCOME_MODAL";
export const HIDE_WELCOME_MODAL = "HIDE_WELCOME_MODAL";

export const NOTIFICATION_SET = "NOTIFICATION_SET";
export const NOTIFICATION_CLEAR = "NOTIFICATION_CLEAR";

export const FORM_SET_VALUE = "FORM_SET_VALUE";
export const FORM_SET_ERROR = "FORM_SET_ERROR";
export const FORM_SET_ERRORS = "FORM_SET_ERRORS";
export const FORM_DATA_LOADED = "FORM_DATA_LOADED";

export const DATA_TABLE_SORT = "DATA_TABLE_SORT";
export const DATA_TABLE_PAGE = "DATA_TABLE_PAGE";
export const DATA_TABLE_SEARCH = "DATA_TABLE_SEARCH";
export const DATA_TABLE_SHOW_FILTER = "DATA_TABLE_SHOW_FILTER";
export const DATA_TABLE_FILTER = "DATA_TABLE_FILTER";
export const DATA_TABLE_CLEAR_FILTERS = "DATA_TABLE_CLEAR_FILTERS";
export const DATA_TABLE_HIDE_COLUMNS = "DATA_TABLE_HIDE_COLUMNS";
export const DATA_TABLE_SORT_COLUMNS = "DATA_TABLE_SORT_COLUMNS";

export const API_EXCEPTION_PUSH = "API_EXCEPTION_PUSH";
export const API_EXCEPTION_POP = "API_EXCEPTION_POP";

export const SMS_SEND_SUCCESS = "SMS_SEND_SUCCESS";
export const SMS_SEND_FAIL = "SMS_SEND_FAIL";
export const GET_BALANCE_SUCCESS = "GET_BALANCE_SUCCESS";
export const GET_BALANCE_FAIL = "GET_BALANCE_FAIL";

export const SUCCESS = "SUCCESS";
export const ERROR = "ERROR";
