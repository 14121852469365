import * as actionTypes from './auctionFormActionTypes';

export const setFormData = (data) => ({
    type: actionTypes.SET_FORM_DATA,
    payload: data
});

export const setFormErrors = (data) => ({
    type: actionTypes.SET_FORM_ERRORS,
    payload: data
});

export const setAuctionBidType = (value) => {
    return {
        type: actionTypes.SET_AUCTION_BID_TYPE,
        payload: value
    }
}

export const setAuctionNdsType = (value) => {
    return {
        type: actionTypes.SET_AUCTION_NDS_TYPE,
        payload: value
    }
}

export const setIsOneBidOnly = (value) => {
    return {
        type: actionTypes.SET_IS_ONE_BID_ONLY,
        payload: value
    }
}

export const setIsBuyoutAvailable = (value) => {
    return {
        type: actionTypes.SET_BUYOUT_AVAILABLE,
        payload: value
    }
}


export const setFirstPrice = (value) => {
    return {
        type: actionTypes.SET_FIRST_PRICE,
        payload: value
    }
}

export const setMinPrice = (value) => {
    return {
        type: actionTypes.SET_MIN_PRICE,
        payload: value
    }
}

export const setBidStep = (value) => {
    return {
        type: actionTypes.SET_BID_STEP,
        payload: value
    }
}

export const setStatus = (value) => {
    return {
        type: actionTypes.SET_STATUS,
        payload: value
    }
}

export const setTimeStart = (value) => {
    return {
        type: actionTypes.SET_TIME_START,
        payload: value
    }
}

export const setTimeFinish = (value) => {
    return {
        type: actionTypes.SET_TIME_FINISH,
        payload: value
    }
}

export const submitForm = () => ({
    type: actionTypes.SUBMIT_FORM
});

export const submitFormSuccess = (payload) => ({
    type: actionTypes.SUBMIT_FORM_SUCCESS,
    payload
});

export const submitFormFailure = (error) => ({
    type: actionTypes.SUBMIT_FORM_FAILURE,
    payload: error
});

export const setValidationErrors = (errors) => ({
    type: actionTypes.SET_VALIDATION_ERRORS,
    payload: errors
});

export const fetchData = () => ({
    type: actionTypes.FETCH_DATA
});

export const fetchDataSuccess = (data) => ({
    type: actionTypes.FETCH_DATA_SUCCESS,
    payload: data
});

export const fetchDataFailure = (error) => ({
    type: actionTypes.FETCH_DATA_FAILURE,
    payload: error
});


