import React, {PureComponent} from "react";
import {Button, Card, Form, Table} from "react-bootstrap";
import operationalReportService from "../../services/OperationalReportService";
import Util from "../../Util";
import "./OperationalReport.css";
import OperationalReport from "./OperationalReport";

class TransportReport extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            sending: false,
            periodType: OperationalReport.AFTER.id,
            days: 7,
            response: [],
            regions: [],
            [OperationalReport.VEHICLE_TONNAGE.id]: OperationalReport.ANY,
            [OperationalReport.VEHICLE_CUBAGE.id]: OperationalReport.ANY,
            [OperationalReport.VEHICLE_CARCASS_VALUE.id]: OperationalReport.ANY,
            [OperationalReport.UNLOADING_REGION.id]: OperationalReport.ANY,
            [OperationalReport.UNLOADING_REGION.name]: OperationalReport.ANY
        }
    }

    TABLE_FIELDS = [
        OperationalReport.UNLOADING_DT,
        OperationalReport.UNLOADING_REGION,
        OperationalReport.CONTRACTOR_FULL_NAME,
        OperationalReport.CARRIER_FULL_NAME,
        OperationalReport.CARRIER_PHONE,
        OperationalReport.DRIVER_LAST_NAME,
        OperationalReport.VEHICLE_TONNAGE,
        OperationalReport.VEHICLE_NUMBER,
        OperationalReport.VEHICLE_CARCASS_VALUE,
        OperationalReport.VEHICLE_CUBAGE,
        OperationalReport.LOADING_TYPE_VALUE
    ]

    FILTERS = [
        OperationalReport.VEHICLE_TONNAGE,
        OperationalReport.VEHICLE_CUBAGE,
        OperationalReport.VEHICLE_CARCASS_VALUE
    ]

    componentDidMount() {
        this.loadRegionsList();
    }

    loadRegionsList() {
        operationalReportService.getRegions()
            .then(response => this.setState({regions : response}));
    }

    loadData() {
        const days = Number(this.state.days);
        if (days <= 0 || days > 7) {
            alert("Период должен быть больше нуля и меньше 7 дней");
            return;
        }

        let data = this.getPeriodByDaysFromNow();
        data.unloadingRegion = this.state.unloadingRegion;

        this.setState({sending: true});
        operationalReportService.post("transport", data)
            .then(response => {
                this.setState({response: response});
                this.setState({sending: false});
            });
    }

    addDays(date, days) {
        let result = new Date(date);
        result.setDate(result.getDate() + days);

        return result;
    }

    getPeriodByDaysFromNow() {
        const now = new Date();
        let from, to;
        const days = Number(this.state.days);

        switch (this.state.periodType) {
            case OperationalReport.BEFORE.id:
                from = this.addDays(now, -days);
                to = now;
                break;
            case OperationalReport.AFTER.id:
                from = now;
                to = this.addDays(now, days);
                break;
        }

        return {from: from, to: to}
    }

    renderInputDaysFieldAndSelectPeriod() {
        return <>
            {this.renderInputField("Кол-во дней", "days")}
            <Form.Group style={{marginRight: "15px", width : "150px"}}>
                <Form.Label style={{whiteSpace : "noWrap"}}>после/до сегодня</Form.Label>
                <Form.Control size="sm" as="select"
                              onChange={event => this.setState({periodType: event.target.value})}>
                    <option value={OperationalReport.AFTER.id}>{OperationalReport.AFTER.name}</option>
                    <option value={OperationalReport.BEFORE.id}>{OperationalReport.BEFORE.name}</option>
                </Form.Control>
            </Form.Group>
        </>
    }

    renderSelect(label, stateKey, options, isForRegions) {
        return <Form.Group style={isForRegions ? {width : "250px"} : {width : "150px"}}>
            <Form.Label>{label}</Form.Label>
            <Form.Control size="sm" as="select"
                          onChange={event => this.setState({[stateKey]: event.target.value})}>
                <option value={OperationalReport.ANY}>{OperationalReport.ANY}</option>
                {options.map(option => isForRegions
                    ? <option value={option.iso}>{option.name}</option>
                    : <option value={option}>{option}</option>)}
            </Form.Control>
        </Form.Group>
    }

    renderInputField(label, stateKey) {
        return <Form.Group>
            <Form.Label>{label}</Form.Label>
            <Form.Control size="sm" type="number"
                          style={{width: "100px"}}
                          placeholder={label}
                          value={this.state.days}
                          onChange={e => this.setState({[stateKey]: e.target.value})}/>
        </Form.Group>
    }

    renderSubmitFiltersButton() {
        return <div className="submit-filters-button">
            <Button variant="outline-primary"
                       size="sm"
                       onClick={() => this.loadData()}
                       disabled={this.state.sending}>
                {this.state.sending && (<span className="spinner-border spinner-border-sm"></span>)}
                <span> Выгрузить заявки</span>
            </Button>
        </div>
    }

    renderSelectPanel() {
        return <div style={{display: "flex", justifyContent: "space-between"}}>
            <div className="select-filter-panel-transport">
                {this.renderInputDaysFieldAndSelectPeriod()}
                {this.renderSelect(OperationalReport.UNLOADING_REGION.name, OperationalReport.UNLOADING_REGION.id, this.state.regions, true)}
                {this.renderSubmitFiltersButton()}
            </div>
            <div className="select-filter-panel-transport">
                {this.FILTERS.map(field => this.renderSelect(field.name, field.id, OperationalReport.mapAndFilterField(this.state.response, field.id), false))}
            </div>
        </div>
    }

    renderResponseTable() {
        const period = this.getPeriodByDaysFromNow();
        return <Card>
            <Card.Header>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <span>{this.formatMonth(period.from) + " — " + this.formatMonth(period.to)}</span>
                    <span>{this.props.title}</span>
                    <span>{this.state.response?.length + OperationalReport.getQuotesName(this.state.response?.length)}</span>
                </div>
            </Card.Header>
            <Card.Body style={{padding: 0}}>
                <Table striped bordered hover size="sm">
                    <thead style={{fontWeight: "bold"}}>
                    <tr>
                        {this.TABLE_FIELDS.map(field => <td>{field.name}</td>)}
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.response?.map(row => this.filterTable(row) &&
                        <tr>{this.TABLE_FIELDS.map(field =>
                            <td>
                                {this.renderTableBodyCell(row, field.id)}
                            </td>)}
                        </tr>
                    )}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    }

    renderTableBodyCell(row, fieldId) {
        switch (fieldId) {
            case OperationalReport.UNLOADING_DT.id:
                return row[fieldId].substring(0, 10);
            case OperationalReport.UNLOADING_REGION.id:
                const regionInResponse = this.state.response?.first()[OperationalReport.UNLOADING_REGION.id]
                return this.state.regions.find(region => region.iso === regionInResponse).name;
            default: return  row[fieldId];
        }
    }

    formatMonth(dateToChange) {
        return Util.formatIsoDate(dateToChange).substring(0, 10);
    }

    filterTable(row) {
        return this.FILTERS.every(field => this.state[field.id] === OperationalReport.ANY || row[field.id] === this.state[field.id]);
    }

    render() {
        return <>
            {this.renderSelectPanel()}
            {this.renderResponseTable()}
        </>
    }
}

export default TransportReport;