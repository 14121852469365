import React, {PureComponent} from "react";
import {AuctionBidInfo} from "../AuctionBidInfo/AuctionBidInfo";
import {classNames} from "../../../helpers/classNames";
import cls from "./AuctionNdsType.module.css"

export class AuctionNdsType extends PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            nds,
            className
        } = this.props;

        return (
            <AuctionBidInfo
                className={classNames(cls.wrapper, {}, [className])}
                textComponent={
                    <span className={cls.text}>Режим НДС</span>
                }
                priceComponent={
                    <div className={cls.price}>
                        <span>
                            {nds}
                        </span>
                    </div>
                }
            />
        )
    }
}