import React from "react";
import { connect } from 'react-redux';

import { requiredValidator } from "../../../validators/simpleValidators";
import {setData, setErrors, setValue} from "../../../actions/form";

import {Button, Card, Col, Container, Form, Row} from "react-bootstrap";
import FormGroup from "../../form/FormGroup";
import BaseForm from "../../form/BaseForm";
import StoreWrapper from "../../form/StoreWrapper";
import optionsService from "../../../services/OptionsService";
import originalAddressService from '../../../services/OriginalAddressService';
import AddressModal from "./AddressModal";
import "./style.css";
import FormValidator from "../../../validators/FormValidator";
import Modal from "react-bootstrap/Modal";
import {NavLink} from "react-router-dom";

class OriginalAddress extends StoreWrapper {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <OriginalAddressInnerConnected {...this.props} store={this.store} />
        );
    }

}

function mapStateToProps(state, ownProps) {
    return {
        errors: state.errors,
        model: state.model
    };
}

class OriginalAddressInner extends BaseForm {

    constructor(props) {
        super(props);

        this.state = {
            showAddressModal: false
        };

        this.onChange = this.onChange.bind(this);
        this.configureValidators();
    }

    configureValidators() {
        this.validator.addValidator("normalizedCity", this.addressesValidator.bind(this));
        this.validator.addValidator("normalizedFullAddress", this.addressesValidator.bind(this));
    }

    addressesValidator() {
        return this.props.model.normalizedCity?.id && this.props.model.normalizedFullAddress?.id
            ? FormValidator.OK
            : "город и адрес не должны быть пустыми - выберите другие или добавьте новые, нажав кнопку '+Адрес' над таблицей";
    }

    onChange(field, value) {
        this.props.store.dispatch(setValue(field, value));
    }

    handleSubmit(e) {
        e.preventDefault();
        this.submit(() => {
            originalAddressService.save(this.props.model).then(() => {
                this.setSending(false);
                this.props.history.push(this.props.pathname);
            });
        });
    }

    getId() {
        return this.props.location.state?.id
            || this.props.model?.id
            || new URLSearchParams(this.props.location.search).get('id');
    }

    load() {
        const id = this.getId();
        if (id) {
            originalAddressService.read(id).then(data => {
                this.props.store.dispatch(setData(data, this.props.location.state?.action));
            });
        }
    }

    renderCitySelector() {
        const onChange = value => {
            this.onChange("normalizedCity", value);
            this.onChange("normalizedFullAddress", optionsService.createEmptyOption());
        };
        return (
            <FormGroup title={"Город"}
                name={"normalizedCity"}
                type="city"
                contractor={this.props.model.contractor}
                onChange={onChange}
                value={this.props.model.normalizedCity}
                store={this.props.store}
            />
        );
    }

    renderAddressSelector() {
        return (
            <FormGroup title={"Адрес"}
                name={"normalizedFullAddress"}
                type="address"
                contractor={this.props.model.contractor}
                city={this.props.model.normalizedCity}
                onChange={(value) => this.onChange("normalizedFullAddress", value)}
                store={this.props.store}
            />
        );
    }

    showAddressModal(show) {
        this.setState({ showAddressModal: show });
    }

    showDeleteModal() {
        this.setState({ deleteOriginalAddress :  !this.state.deleteOriginalAddress})
    }

    deleteOriginalAddressById() {
        originalAddressService.deleteById(this.props.model.id)
            .then(() => window.history.back());
    }

    deleteOriginalAddressesByCityAndFullAddress() {
        originalAddressService.deleteByCityAndFullAddress(this.props.model.city, this.props.model.fullAddress)
            .then(() => window.history.back());
    }

    renderModal() {
        return <Modal size="sm"
               aria-labelledby="example-modal-sizes-title-sm"
               show={this.state.deleteOriginalAddress}
               onHide={() => this.showDeleteModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Удалить адрес
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Button variant="outline-danger" size="sm" onClick={() => this.deleteOriginalAddressById()}>
                    Удалить адрес для {this.props.model.contractor?.value}
                </Button>&nbsp;
                <Button variant="outline-danger" size="sm" onClick={() => this.deleteOriginalAddressesByCityAndFullAddress()}>
                    Удалить адрес для всех контрагентов
                </Button>
            </Modal.Body>
        </Modal>
    }

    render() {
        return (
            <Form>
                {this.renderModal()}
                <Container fluid>
                    <Card className="m-1 bg-light address-controls">
                        <Card.Header as="h6">
                            {this.props.title}
                            {this.props.model.contractor &&
                                <AddressModal {...this.props}
                                              store={this.props.store}
                                              show={this.state.showAddressModal}
                                              onHide={() => this.showAddressModal(false)}/>
                            }
                            <span className="add-links">
                                <NavLink to={"/control-panel/contractors/contractor?id=" + this.props.model.contractor?.id + "&activeTab=addresses"}
                                         target={"_blank"}
                                >
                                    <Button variant="outline-primary" size="sm">
                                        <i className="fas fa-plus mr-1"></i> Добавить адрес
                                    </Button>
                                </NavLink>


                                <Button variant="outline-danger" size="sm" onClick={() => this.setState({ deleteOriginalAddress : true })}>
                                    <i className="fas fa-minus mr-1"></i> Удалить адрес
                                </Button>
                            </span>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <FormGroup title={"Исходный город"}
                                       name={"city"}
                                       readOnly={true}
                                       store={this.props.store}
                                    />
                                </Col>
                                <Col>
                                    <FormGroup title={"Исходный адрес"}
                                       name={"fullAddress"}
                                       readOnly={true}
                                       store={this.props.store}
                                    />
                                </Col>
                            </Row>
                            {this.props.model.contractor &&
                                <Row>
                                    <Col>
                                        {this.renderCitySelector()}
                                    </Col>
                                    <Col>
                                        {this.renderAddressSelector()}
                                    </Col>
                                </Row>
                            }
                            <Row className="justify-content-center">
                                <Col className="col-auto">
                                    {this.state.sending && (<span>Распознавание адреса может занять некоторое время, если он был использован в большом количестве заявок. Пожалуйста, подождите.</span>)}
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col className="col-auto">
                                    {this.renderSaveCancelButtons()}
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Container>
            </Form>
        );
    }

}

const OriginalAddressInnerConnected = connect(mapStateToProps)(OriginalAddressInner);
export default OriginalAddress;
