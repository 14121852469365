import React from "react";
import {connect} from 'react-redux';
import {Button, Col, Collapse, Row, Container} from "react-bootstrap";
import DataTable from '../table/DataTable';
import './reportStyle.css';
import Util from "../../Util";
import reportService from "../../services/ReportService";
import {
    ADMIN,
    HEAD_OF_DEPARTMENT,
    REGIONAL_DIRECTOR,
    securityService
} from "../../services/SecurityService";
import {userService} from "../../services/UserService";

class SspYear extends React.PureComponent {
    constructor(props) {

        super(props);

        const now = new Date(),
            dt = new Date(now.getFullYear(), 1),
            offset = dt.getTimezoneOffset();
        dt.setTime(dt.getTime() - offset * 60000);

        this.state = {
            editCell: {},
            tableHeight: this.getTableHeight(),
            dt,
            toggle: false,
            open: false,
        };

        this.changeToggle = this.changeToggle.bind(this);
    }

    static COLUMS = [
        {title: "Январь", field: "January"},
        {title: "Февраль", field: "February"},
        {title: "Март", field: "March"},
        {title: "Апрель", field: "April"},
        {title: "Май", field: "May"},
        {title: "Июнь", field: "June"},
        {title: "Июль", field: "July"},
        {title: "Август", field: "August"},
        {title: "Сентябрь", field: "September"},
        {title: "Октябрь", field: "October"},
        {title: "Ноябрь", field: "November"},
        {title: "Декабрь", field: "December"},
        {title: "Итого год", field: "ResultYear"},
    ];

    static HEIGHT = 1700;

    changeToggle(toggle) {
        this.setState({toggle});
    }

    getTableHeight() {
        return window.innerHeight + this.HEIGHT;
    }

    componentWillUnmount() {
        this.addRemoveResizeListener(false);
    }

    componentWillMount() {
        this.addRemoveResizeListener(true);
    }

    addRemoveResizeListener(listen) {
        const addRemove = listen ? document.body.addEventListener : document.body.removeEventListener;
        addRemove('resize', this.resizeListener.bind(this), true);
    }

    resizeListener() {
        this.setState({tableHeight: this.getTableHeight()});
    }

    async changeYear(e, step) {
        e.preventDefault();
        const dt = this.state.dt;
        dt.setYear(dt.getFullYear() + step);
        const date = new Date(dt.getTime()),
            result = await this.fetch(date);
        this.setState({
            dt: date,
            data: result.data
        });
    }

    async componentDidMount() {
        const result = await this.fetch();
        const countDepUser = await this.fetchUser(securityService.getUser().id);
        this.setState({data: result.data, counterDepartment: countDepUser.departments.length});
    }

    async fetchUser(id) {
        return await userService.read(id);
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.toggle !== this.state.toggle) {
            const result = await this.fetch();
            this.setState({
                data: result.data
            });
            this.changeToggle(false)
        }
    }

    async fetch(dt) {
        return await reportService.sspYear(Util.formatIsoDate(dt || this.state.dt));
    }

    formatYear() {
        const date = Util.formatIsoDate(this.state.dt),
            parts = date.split('-');
        return parts[0];
    }

    render() {
        return (
            <div className={"tableFixHead"}>
                <Container fluid>
                    {this.renderTitle()}
                </Container>
                <table className={"tableSsp"}>
                    <thead className={"thead"}>
                    {this.renderBodyTitle()}
                    </thead>
                    <tbody>
                    {this.renderBody()}
                    </tbody>
                </table>
            </div>);
    }

    renderBodyTitle() {
        return (
            <tr>
                <td className={"tdTitle tdAll"}>
                    <span className={"font-title"}>Показатели
                    </span>
                </td>
                {SspYear.COLUMS.map(s =>
                    <td className={"tdCell tdAll"}>
                        <span className={"font-p"}>{s.title}</span>
                    </td>
                )}
            </tr>
        );
    }

    renderTitle() {
        return (
            <Row>
                <Col className="mx-auto my-4 text-center">
                    <a href="#" onClick={e => this.changeYear(e, -1)}>
                        <i className="fa fa-chevron-left" aria-hidden="true"></i>
                    </a>
                    <span className={"font-title"}>{"  " + "Годовые показатели ССП " + this.formatYear() + "  "}</span>
                    <a href="#" onClick={e => this.changeYear(e, 1)}>
                        <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </a>
                </Col>
            </Row>
        );
    }

    renderBody() {
        return (
            <>
                {this.state.data && this.state.data.map((row) => (
                    <Rows key={row.counter}
                          counter={row.counter}
                          departmentByPeriod={row.departments}
                          changeToggle={this.changeToggle}
                          date={this.state.dt}
                          counterDepartment={this.state.counterDepartment}/>
                ))}
            </>
        );
    }

}

class Rows extends React.PureComponent {
    constructor(props) {

        super(props);

        this.state = {
            open: false,
            isEdit: false,
            editCell: {}
        };

    }

    render() {
        const {counter, departmentByPeriod} = this.props;
        return (
            <>
                <tr className="rowColor">
                    <td className="tdTitle tdAll">
                        <Button
                            className={"button"}
                            size="sm"
                            onClick={() => this.setState({open: !this.state.open})}>
                            {this.state.open ? <i className="fa fa-chevron-up arrow-color"></i> :
                                <i className="fa fa-chevron-down arrow-color"></i>}
                        </Button>
                        <span className={"font-p"}> {counter}</span>
                    </td>
                    {this.getYearAllDepartments(departmentByPeriod[departmentByPeriod.length - 1])}
                </tr>
                {this.getDepartmentRow(counter, departmentByPeriod, this.props.date.getFullYear())}
            </>
        );
    }

    getYearAllDepartments(row) {
        return (
            row.sspSubPeriodYearOfDepartments.map(row => (
                <td className={"tdCell tdAll"}><span className={"font-p"}>{row.plan.toLocaleString('ru')}</span></td>
            ))
        )
    }

    getDepartmentRow(counter, departmentByPeriod, year) {
        return departmentByPeriod.map(rows => (
            <Collapse in={this.state.open}>
                <tr className={counter === 'Маржа' || counter === 'Сумма зачета ГСМ' ? "rowColorMarginOrGsm" : "colorCellWhite"}>
                    {
                        rows.department !== null &&
                        <td className={"tdTitle tdAll"}>
                            <span className={"font-p"}> {rows.department}</span>
                        </td>
                    }
                    {this.getResultCounterOfMonth(counter, rows, year)}
                </tr>
            </Collapse>
        ))
    }

    getResultCounterOfMonth(counter, rows, year) {
        return (
            rows.department !== null && rows.sspSubPeriodYearOfDepartments.map((row, idx) => (
                <>
                    {this.handleCellClick((idx + 1), counter, rows.department, row, year)}
                </>
            ))
        )
    }

    openOrCloseFieldEditor(key, isOpen) {
        this.setState({isEdit: isOpen, key});
    }

    handleCellClick(idx, counter, department, departmentPeriodAndValue, years) {
        let dt = years + "-" + this.getFormatterMonth(idx) + "-" + "01";
        return this.state.isEdit && this.state.key === (idx + counter + department) && departmentPeriodAndValue.period !== null
            ?
            <td className={"tdCell tdAll"}>
                {this.validityMarginAndAmountOfFuel(idx, counter, department, departmentPeriodAndValue.plan, dt)}
            </td>
            :
            <td className={"tdCell tdAll"}
                onClick={() => this.openOrCloseFieldEditor(idx + counter + department, true)}>
                <span className={"font-p"}>{departmentPeriodAndValue.plan.toLocaleString('ru')}</span>
            </td>
    }


    validityMarginAndAmountOfFuel(idx, counter, department, plan, dt) {
        return counter === 'Маржа' || counter === 'Сумма зачета ГСМ' ||
        ((securityService.anyRoles(this.state.crmUser, ADMIN, HEAD_OF_DEPARTMENT, REGIONAL_DIRECTOR)) && this.props.counterDepartment !== 0) ?
            <span className={"font-p"}>{plan.toLocaleString('ru')}</span>
            :
            <input
                className={"inputStyle"}
                autoFocus={true}
                align={"right"}
                type="number"
                defaultValue={plan == '0' ? plan = '' : plan}
                onBlur={(e) => {
                    plan = e.target.value;
                    this.completeCellEditor(department, dt, counter, plan, idx, e);
                    this.openOrCloseFieldEditor(idx + counter + department, false);
                }}
            />
    }

    completeCellEditor(rowDep, dt, idxCounter, plan, idx) {
        if (plan === "") {
            reportService.setYearPlan(rowDep, null, dt, idxCounter, 0)
                .then(result => this.setState({data: result.data, editCell: {}}))
                .catch(() => this.setState({editCell: {}}));
        } else {
            reportService.setYearPlan(rowDep, null, dt, idxCounter, plan)
                .then(result => this.setState({data: result.data, editCell: {}}))
                .catch(() => this.setState({editCell: {}}));
        }
        this.props.changeToggle(true);
        {
            this.openOrCloseFieldEditor(idx + idxCounter + rowDep, true)
        }
    }

    getFormatterMonth(idx) {
        if (idx.toString().length === 1) {
            return "0" + idx;
        } else {
            return idx;
        }
    }
}
export default connect(DataTable.mapStateToProps)(SspYear);
