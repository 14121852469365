import React from "react";
import { connect } from 'react-redux';
import {Form, Row, Col, Button} from "react-bootstrap";
import { userService, GENERAL } from "../../../services/UserService";
import personService from "../../../services/PersonService";
import {securityService, DRIVER, CARRIER, CLIENT, GUEST} from "../../../services/SecurityService";
import optionsService from "../../../services/OptionsService";
import { emailValidator, requiredValidator, phoneValidator } from "../../../validators/simpleValidators";
import StoreWrapper from '../../form/StoreWrapper';
import BaseForm from '../../form/BaseForm';
import FormGroup from '../../form/FormGroup';
import ValidationErrors from "../../../validators/ValidationErrors";
import Password from "./Password";
import { setData } from '../../../actions/form';
import Loading from '../../Loading';
import FormValidator from '../../../validators/FormValidator';
import { USER } from '../../AuditTable/AuditTable';
import { DriverInner } from "../drivers/Driver";
import driverService from "../../../services/DriverService";
import PositionHistoryTable from "./PositionHistoryTable";
import {SET_TOAST_OBJ} from "../../../actions/types";
import {setToastObjAC} from "../../../reducers/toastObj";
import smsService from "../../../services/SmsService";

class User extends StoreWrapper {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<UserInnerConnected {...this.props} store={this.store} />
		);
	}
}

function mapGlobalStateToProps(state) {
	return {
		toastObj2: state.toastObj
	};
}

function mapStateToProps(state) {
	return {
		errors: state.errors,
		model: state.model,
		toastObj: state.toastObj
	};
}
class UserInner extends BaseForm {
	constructor(props) {
		super(props);

		this.configureValidators();

		this.onCancel = this.onCancel.bind(this);
		this.onChangeRole = this.onChangeRole.bind(this);
		this.onChangeActive = this.onChangeActive.bind(this);
		this.onChangeDepartment = this.onChangeDepartment.bind(this);
		this.applyInternalValidator = this.applyInternalValidator.bind(this);
		this.sendPassword = this.sendPassword.bind(this);
	}

	configureValidators() {
		this.useValidatorFor(requiredValidator, "lastName");
		this.useValidatorFor(this.applyInternalValidator(emailValidator.bind(this)), "email");
		this.useValidatorFor(this.applyInternalValidator(phoneValidator.bind(this)), "phone");
		this.validator.addValidator('email', this.emailOrPhoneRequiredValidator.bind(this));
		this.validator.addValidator('email', this.emailUniqueValidator.bind(this));
		this.validator.addValidator('phone', this.phoneUniqueValidator.bind(this));
		this.validator.addValidator('roles', this.isRoleSelectedValidator.bind(this));
		this.validator.addValidator('password', this.passwordValidator.bind(this));
		this.validator.addValidator('contractors', this.contractorValidator.bind(this));
		this.validator.addValidator('forwarder', this.forwarderValidator.bind(this));
	}

	applyInternalValidator(validator) {
		return value => this.execInternalValidator(validator, value);
	}


	async execInternalValidator(validator, value) {
		return validator(value);
	}

	forwarderValidator() {
		if (!this.isExternal()) {
			return Promise.resolve( this.props.model.forwarder ? FormValidator.OK : "выберите экспедитора");
		}
		return Promise.resolve(FormValidator.OK);
	}

	contractorValidator() {
		let isValid = true;
		if (this.isRoleChecked(CLIENT) || this.isRoleChecked(CARRIER)) {
			isValid = this.props.model.contractors?.length > 0;
		}
		return Promise.resolve(isValid ? FormValidator.OK : "выберите контрагента");
	}

	emailOrPhoneRequiredValidator() {
		const isValid = !!this.props.model.email || !!this.props.model.phone;
		const isExternal = !!this.props.model.roles &&
			(this.props.model.roles.includes('CARRIER') || this.props.model.roles.includes('DRIVER'));
		return isExternal
			? !!this.props.model.email ? FormValidator.OK : "обязательное поле"
			: Promise.resolve(isValid ? FormValidator.OK : "укажите email или телефон");
	}

	isRoleSelectedValidator() {
		return Promise.resolve(this.props.model.roles && this.props.model.roles.length ? FormValidator.OK : "выберите хотя бы одну роль");
	}

	passwordValidator() {
		let message = "";
		if (!this.props.model.id && !this.props.model.password) {
			message = "укажите пароль";
		} else if (!!this.props.model.password && this.props.model.password.length < 6) {
			message = "пароль должен содержать не меньше 6 символов"
		} else if ((this.props.model.password || this.props.model.password2) &&
			this.props.model.password !== this.props.model.password2) {
			message = "пароли не совпадают";
		}
		return Promise.resolve(message);
	}

	async emailUniqueValidator() {
		const email = this.props.model.email;
		if (!email) {
			return FormValidator.OK;
		}
		const data = await userService.isEmailUnique(this.props.model.id, email);
		return data.unique ? FormValidator.OK : "этот email уже зарегистрирован в системе " + data.info;
	}

	async phoneUniqueValidator() {
		const phone = this.props.model.phone;
		if (!phone) {
			return FormValidator.OK;
		}
		const data = await userService.isPhoneUnique(this.props.model.id, phone);
		return data.unique ? FormValidator.OK : "этот телефон уже зарегистрирован в системе " + data.info;
	}

	getUserId() {
		return this.props.location.state?.id
			|| this.props.model?.id
			|| new URLSearchParams(this.props.location.search).get("id");
	}

	getDriverId() {
		return new URLSearchParams(this.props.location.search).get("driverId");
	}

	isExternal() {
		return this.isRoleChecked(CLIENT) || this.isRoleChecked(CARRIER) || this.isRoleChecked(DRIVER) || this.isRoleChecked(GUEST);
	}

	load() {
		const id = this.getUserId();
		const driverId = this.getDriverId();
		if (id) {
			userService.read(id).then(data => {
				this.props.store.dispatch(setData(data, this.props.location.state?.action));
			});
		} else if (driverId) {
			driverService.read(driverId).then(data => {
				const user = {
					driver: data,
					roles: [DRIVER],
					...this.getPerson(data)
				};
				this.props.store.dispatch(setData(user, this.props.location.state?.action));
			});
		}
		optionsService.load("DEPARTMENT").then(data => this.setState({ allDepartments: data }));
	}

	renderFioRow() {
		return (
			<Row>
				<Col lg={4} md={12}>
					<FormGroup title="Фамилия" name="lastName" store={this.props.store} required />
				</Col>
				<Col lg={4} md={12}>
					<FormGroup title="Имя" name="firstName" store={this.props.store}/>
				</Col>
				<Col lg={4} md={12}>
					<FormGroup title="Отчество" name="middleName" store={this.props.store}/>
				</Col>
			</Row>
		);
	}

	renderContactsRow() {
		return (
			<Row>
				<Col lg={2} md={6}>
					<FormGroup title="Email" type="email" name="email" store={this.props.store}/>
				</Col>
				<Col lg={2} md={6} style={{maxWidth: "300px"}}>
					<FormGroup width="190px" title="Номер телефона" type="phone" name="phone" store={this.props.store}/>
				</Col>
				<Col lg={3} md={12}>
					<FormGroup title="Представление наименования" name="viewName" store={this.props.store}/>
				</Col>
				<Col lg={1} md={12}>
					<FormGroup title="ID" name="id" store={this.props.store} readOnly />
				</Col>
				<Col lg={3} md={12}>
					<Col>
						<Password id={this.getUserId()} onChange={(name, value) => this.onChange(name, value)}/>
						<ValidationErrors errors={this.getErrors("password")} />
					</Col>
				</Col>
			</Row>
		);
	}

	isRoleChecked(role) {
		const roles = this.props.model.roles || [];
		return roles.indexOf(role) >= 0;
	}

	onChangeRole(e) {
		const role = e.target.name,
			roles = this.props.model.roles || [],
			index = roles.indexOf(role),
			onChange = arr => this.onChange("roles", arr);
		if (e.target.checked) {
			if (index < 0) {
				onChange(roles.concat(role));
			}
		} else {
			if (index >= 0) {
				onChange(roles.filter(r => r !== role));
			}
		}
	}

	isDepartmentChecked(department) {
		const departments = this.props.model.departments || [];
		return departments.some(d => d.id === department.id);
	}

	onChangeDepartment(e) {
		const departments = this.props.model.departments || [],
			department = this.state.allDepartments?.find(d => d.value === e.target.name),
			index = departments.findIndex(d => d.id === department.id),
			onChange = arr => this.onChange("departments", arr);
		if (e.target.checked && index < 0) {
			onChange(departments.concat(department));
		} else if (index >= 0) {
			onChange(departments.filter(d => d.id !== department.id));
		}
	}

	onChangeActive(e) {
		this.onChange("active", e.target.checked);
	}

	renderCheckboxesRow() {
		return (
			<Row>
				{ this.props.type === GENERAL &&
					<>
						<Col className={"ml-1"}>
							<FormGroup title="Экспедитор"
									   name="forwarder"
									   type="dictionary"
									   optionsType="FORWARDER"
									   store={this.props.store}
									   required
							/>
						</Col>
						<Col>
							<FormGroup title="Подразделения"
									   name="departments"
									   type="dictionary"
									   optionsType="DEPARTMENT"
									   multiSelect={true}
									   store={this.props.store}
							/>
						</Col>
						<Col>
							<FormGroup title="Отделы"
									   name="divisions"
									   type="dictionary"
									   optionsType="DIVISION"
									   multiSelect={true}
									   store={this.props.store}
							/>
						</Col>
					</>
				}
				<Col lg={4} md={12}>
					<Col>
						<Form.Group>
							<Form.Label>Роли</Form.Label>
							{securityService.getRolesByType(this.props.type).map(role =>
								<Form.Check key={role} label={securityService.getRoleName(role)}
											id={"role_" + role} name={role}
											checked={this.isRoleChecked(role)}
											onChange={this.onChangeRole} />
							)}
							<ValidationErrors errors={this.getErrors("roles")} />
						</Form.Group>
					</Col>
				</Col>
				<Col lg={4} md={12}>
					<Col>
						<Form.Group>
							<Form.Label>Активен</Form.Label>
							<Form.Check id="active" name="active" defaultChecked={this.props.model.active} onChange={this.onChangeActive} />
						</Form.Group>
					</Col>
				</Col>
				{(this.isRoleChecked(CLIENT) || this.isRoleChecked(CARRIER)) &&
					<Col lg={4} md={12}>
						<Col>
							<FormGroup title="Контрагенты"
									   placeholder="Контрагенты"
									   name="contractors"
									   store={this.props.store}
									   type="dictionary"
									   optionsType={this.isRoleChecked(CLIENT) ? "CONTRACTOR_CLIENT" : "CONTRACTOR_CARRIER"}
									   multiSelect={true}
							/>
						</Col>
					</Col>
				}
			</Row>
		);
	}

	handleSubmit(e) {
		e.preventDefault();
		if (this.isRoleChecked(DRIVER)) {
			this.updateDriverPersonFields();
		}
		this.submit(() => {
			userService.save(this.props.model)
				.then(user => this.afterSubmit(user))
				.finally(() => this.setSending(false));
		});
	}

	afterSubmit(user) {
		const id = user.id;
		const data = {show: true, textHeader: "Пользователь сохранен!", delay: 3000};
		this.props.store.dispatch(setData(user, this.props.location.state?.action));
		if (this.props.model.driver) {
			this.onChange("driver.personUpdate", user.personUpdate);
		}
		this.props.history.push({payload: '/control-panel/users/user', state:{id}});
		if(!this.props.model.id) {
			data.textHeader = "Пользователь создан!"
		}
		this.props.setToastObjAC(data);
	}

	updateDriverPersonFields() {
		Object.entries(this.getPerson()).filter(([key, value]) => value).forEach(([key, value]) => {
			this.onChange(`driver.${key}`, value);
		});
	}

	getPerson(model) {
		model = model || this.props.model;
		return {
			personId: model.personId,
			firstName: model.firstName,
			lastName: model.lastName,
			middleName: model.middleName,
			email: model.email,
			phone: model.phone
		}
	}

	getTabs() {
		const tabs = [];
		if (this.getUserId()) {
			tabs.push(this.getAuditTab(this.getUserId(), USER));
		}
		return tabs;
	}

	renderDriver() {
		return (
			{ ...this.isRoleChecked(DRIVER)
					? <DriverInner {...this.props} isInner={true} parentValidator={this.validator} />
					: <React.Fragment/>

			}
		);
	}

	getFormTabTitle() {
		const user = this.props.model.lastName || (this.props.model.id > 0 ? this.props.model.id : ''),
			title = (this.props.type === GENERAL ? "Пользователь " : "Внешний пользователь ") + user;
		return title;
	}

	onCancel() {
		this.redirectToBackOrDefaultUrl('/control-panel/users')
	}

	renderSendPasswordButton() {
		return (
			<Button onClick={this.sendPassword} size="sm" variant="primary" className="mr-4" disabled={this.state.sending}>
				{this.state.sending && (<span className="spinner-border spinner-border-sm"></span>)}
				<span>Отправить новый пароль</span>
			</Button>

		)
	}

	async sendPassword() {
		this.props.model.phone = this.props.model.phone.replace(/\D/g, '');
		const newPassword =  this.generateNewPassword();
		const message = "Пароль для входа CRM/Bursa: " + newPassword;
		const passwordReset =  await userService.changePassword({userId: this.getUserId(), password: newPassword});
			if (passwordReset) {
				await smsService.sendSms({phone: this.props.model.phone, text: message});
					const data = {show: true, textHeader: "Пароль отправлен!", delay: 3000};
				this.props.setToastObjAC(data);
			}
	}

	generateNewPassword() {
		const length = 8;
		const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
		let password = "";
		for (let i = 0, n = charset.length; i < length; ++i) {
			password += charset.charAt(Math.floor(Math.random() * n));
		}
		return password;
	}

	renderSaveCancelButtons() {
		return (
			<div className="text-center">
				{this.renderSendPasswordButton()}
				{this.renderSaveButton()}
				&nbsp;
				{this.renderCancelButton("Выйти")}
			</div>
		);
	}

	renderForm() {
		const isReady = !this.getUserId() || this.props.model.id;
		if (!isReady) {
			return (<Loading/>);
		}
		return (
			<>
			<Form>
				{this.renderFioRow()}

				{this.renderContactsRow()}

				{this.renderCheckboxesRow()}

				{this.renderDriver()}

				{this.renderSaveCancelButtons()}
				{this.props.type === GENERAL && <PositionHistoryTable userId={this.getUserId()} />}
			</Form>
			</>
		);
	}
}

const UserInnerConnected = connect(mapStateToProps)(UserInner);

export default connect(mapGlobalStateToProps, {setToastObjAC})(User);