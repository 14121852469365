import Enums from "./Enums";

class RoutePoint {

    static get POINT_TYPE_LOADING() { return 1 };
    static get POINT_TYPE_UNLOADING() { return 2 };
    static get POINT_TYPE_CUSTOMS() { return 3 };

    static get CARCASS_REFRIGERATED() { return { id: 3, value: "Рефрижераторный" } };

    static createDefaultRoutePoints() {
        return [
            { position: 0, pointType: { id: RoutePoint.POINT_TYPE_LOADING, value: "Загрузка" } },
            { position: 1, pointType: { id: RoutePoint.POINT_TYPE_UNLOADING, value: "Разгрузка" } }
        ];
    }

    static addLoading(routePoints) {
        const lastLoading = routePoints.filter(point => point.pointType.id === RoutePoint.POINT_TYPE_LOADING).last() || routePoints[0];
        const newPoint = { ...this.getDefaultsForNewPoint(routePoints, lastLoading) };

        if (lastLoading.pointType.id !== RoutePoint.POINT_TYPE_LOADING) {
            const loadingPoint = RoutePoint.createDefaultRoutePoints()[0];
            newPoint.position = 0;
            newPoint.pointType = loadingPoint.pointType;
            routePoints.forEach(point => point.position++);
        } else {
            routePoints.filter(point => point.position > lastLoading.position).forEach(point => point.position++);
        }

        routePoints.splice(newPoint.position, 0, newPoint);
        return routePoints;
    }

    static addCustoms(routePoints) {
        const lastPoint = routePoints
            .filter(point => point.pointType.id === RoutePoint.POINT_TYPE_LOADING || point.pointType.id === RoutePoint.POINT_TYPE_CUSTOMS)
            .last() || routePoints[0];
        const newPoint = {
                ...this.getDefaultsForNewPoint(routePoints, lastPoint),
                pointType: {
                    id: RoutePoint.POINT_TYPE_CUSTOMS,
                    value: "Таможня"
                }
            };

        if (lastPoint.pointType.id !== RoutePoint.POINT_TYPE_LOADING && lastPoint.pointType.id !== RoutePoint.POINT_TYPE_CUSTOMS) {
            newPoint.position = 0;
            routePoints.forEach(point => point.position++);
        } else {
            routePoints.filter(point => point.position > lastPoint.position).forEach(point => point.position++);
        }

        routePoints.splice(newPoint.position, 0, newPoint);
        return routePoints;
    }

    static addUnloading(routePoints) {
        const lastUnloading = routePoints.last();
        const newPoint = { ...this.getDefaultsForNewPoint(routePoints, lastUnloading) };

        if (lastUnloading.pointType.id !== RoutePoint.POINT_TYPE_UNLOADING) {
            const unloadingPoint = RoutePoint.createDefaultRoutePoints()[1];
            newPoint.pointType = unloadingPoint.pointType;
        }

        routePoints.push(newPoint);
        return routePoints;
    }

    static getDefaultsForNewPoint(routePoints, sourcePoint) {
        const initialNewPoint = {
            ...sourcePoint,
            id: null,
            address: null,
            position: sourcePoint.position + 1
        };
        const fieldsToCopy = ["cargoType", "loadingType", "carcass"].filter(fieldName => !initialNewPoint[fieldName]);
        if (fieldsToCopy.length) {
            return routePoints.reduce((result, point) => {
                let withDefaults = { ...result };
                for (const fieldName of fieldsToCopy) {
                    if (!withDefaults[fieldName] && point[fieldName]) {
                        withDefaults[fieldName] = point[fieldName];
                    }
                }
                return withDefaults;
            }, initialNewPoint);
        }
        return initialNewPoint;
    }

    static setDefaultsOnMultiRoute(routePoints) {
        const firstPoint = routePoints.filter(rp => rp.id === RoutePoint.POINT_TYPE_LOADING).first() || routePoints.first(),
            lastPoint = routePoints.filter(rp => rp.id === RoutePoint.POINT_TYPE_UNLOADING).last() || routePoints.last();
        const fieldsToCopy = ["cargoType", "loadingType", "carcass", "temperature", "weight", "volume"];
        for (const fieldName of fieldsToCopy) {
            if (!lastPoint[fieldName] && firstPoint[fieldName]) {
                lastPoint[fieldName] = firstPoint[fieldName];
            }
        }
        return routePoints;
    }

}

class Attachment {

    static get TYPE_CLIENT_BILL() { return "CLIENT_BILL" };
    static get TYPE_ACCOMPANYING_DOCUMENTS() { return "ACCOMPANYING_DOCUMENTS" };
    static get TYPE_CLIENT_QUOTE() { return "CLIENT_QUOTE" };
    static get TYPE_CARRIER_QUOTE() { return "CARRIER_QUOTE" };
    static get TYPE_DRIVER_DOCUMENTS() { return "DRIVER_DOCUMENTS" };

    static get DOC_TYPE_CLIENT() { return "CLIENT" };
    static get DOC_TYPE_CARRIER() { return "CARRIER" };
    static get DOC_TYPE_PROCURATION() { return "PROCURATION" };
    static get DOC_TYPE_CLIENT_BILL() { return "CLIENT_BILL" };
    static get DOC_TYPE_CARRIER_BILL() { return "CARRIER_BILL" };
    static get DOC_TYPE_CARRIER_ACT() { return "CARRIER_ACT" };

}

class RequestStatus {
    
    static get ACTUAL() { return { id: 1, value: "Актуальная" } };
    static get APPROVED() { return { id: 2, value: "Согласована" } };
    static get NOT_APPROVED() { return { id: 3, value: "Не согласована" } };
    static get CANCELED() { return { id: 4, value: "Отменена заказчиком" } };
    static get REJECTED() { return { id: 5, value: "Отказ перевозчику" } };
    static get BROKEN() { return { id: 6, value: "Срыв перевозчиком" } };
    static get PROBLEM() { return { id: 7, value: "Проблема" } };
    static get UNLOADED() { return { id: 8, value: "Разгрузилась" } };
    static get LOADED() { return { id: 9, value: "Погрузилась" } };
    static get ON_LOADING() { return { id: 10, value: "На погрузке" } };
    static get ON_WAY() { return { id: 11, value: "В Пути" } };
    static get ON_UNLOADING() { return { id: 12, value: "На разгрузке" } };
    static get WASTED() { return { id: 13, value: "Истек срок действия" } };
    static get ON_SIGNING() { return { id: 22, value: "Требуется подписание" } };
    static get SIGNED() { return { id: 23, value: "Договор подписан" } };
    static get ON_WAY_TO_LOADING() { return { id: 25, value: "В пути на погрузку" } };
    static get ON_WAY_TO_UNLOADING() { return { id: 26, value: "В пути на разгрузку" } };
    static get FREE() { return { id: 27, value: "Свободная" } };
    static get REMOVED_FROM_PUBLICATION() { return { id: 28, value: "Снята с публикации" } };
    static get AT_WORK() { return { id: 31, value: "В работе" } };
    static get BOOKED_BY_CARRIER() { return { id: 29, value: "Забронирована перевозчиком" } };
    static get DECLINED_BY_CARRIER() { return { id: 32, value: "Отклонена перевозчиком" } };

    static getCancelStatuses() {
        return [
            RequestStatus.CANCELED, RequestStatus.DECLINED_BY_CARRIER, RequestStatus.BROKEN, RequestStatus.REJECTED
        ].map(s => s.id)
    }

    static getPreRequestStatuses() {
        return [
            RequestStatus.ACTUAL, RequestStatus.FREE,
            RequestStatus.NOT_APPROVED, RequestStatus.AT_WORK,
            RequestStatus.ON_SIGNING
        ].map(s => s.value)
    };

    static getPreRequestStatusIds() {
        return [
            RequestStatus.ACTUAL, RequestStatus.FREE, RequestStatus.NOT_APPROVED, RequestStatus.AT_WORK
        ].map(s => s.id)
    }

    static getRequestsWithoutTruck() {
        return [
            RequestStatus.ACTUAL, RequestStatus.FREE,
            RequestStatus.NOT_APPROVED, RequestStatus.CANCELED,
            RequestStatus.REJECTED, RequestStatus.BROKEN,
            RequestStatus.AT_WORK, RequestStatus.REMOVED_FROM_PUBLICATION
        ].map(s => s.id)
    }

    static getRequestsWithoutRoutePoints() {
        return [
            RequestStatus.ACTUAL,
            RequestStatus.CANCELED, RequestStatus.REJECTED,
            RequestStatus.BROKEN, RequestStatus.AT_WORK
        ].map(s => s.id)
    }

    static getPostRequestStatuses() {
        return [
            RequestStatus.APPROVED, RequestStatus.CANCELED,
            RequestStatus.REJECTED, RequestStatus.BROKEN,
            RequestStatus.UNLOADED, RequestStatus.ON_WAY_TO_LOADING,
            RequestStatus.ON_LOADING, RequestStatus.ON_WAY_TO_UNLOADING,
            RequestStatus.ON_UNLOADING, RequestStatus.AT_WORK,
            RequestStatus.BOOKED_BY_CARRIER, RequestStatus.DECLINED_BY_CARRIER
        ].map(s => s.value)
    };
    
    static getQuoteRequestStatuses() {
        return [
            RequestStatus.ACTUAL, RequestStatus.FREE,
            RequestStatus.NOT_APPROVED, RequestStatus.APPROVED,
            RequestStatus.CANCELED, RequestStatus.REJECTED,
            RequestStatus.BROKEN, RequestStatus.UNLOADED,
            RequestStatus.ON_WAY_TO_LOADING, RequestStatus.ON_LOADING,
            RequestStatus.ON_WAY_TO_UNLOADING, RequestStatus.ON_UNLOADING,
            RequestStatus.WASTED, RequestStatus.AT_WORK,
            RequestStatus.REMOVED_FROM_PUBLICATION, RequestStatus.BOOKED_BY_CARRIER,
            RequestStatus.DECLINED_BY_CARRIER, RequestStatus.ON_SIGNING
        ];
    }

    static getActualStatuses() {
        return [
            RequestStatus.ACTUAL
        ]
    }

    static getAtWorkStatuses() {
        return [
            RequestStatus.AT_WORK,
            RequestStatus.NOT_APPROVED,
            RequestStatus.BOOKED_BY_CARRIER,
            RequestStatus.ON_SIGNING


        ]
    }

    static getOnWayStatuses() {
        return [
            RequestStatus.APPROVED, RequestStatus.ON_WAY_TO_LOADING,
            RequestStatus.ON_WAY_TO_UNLOADING, RequestStatus.ON_LOADING,
            RequestStatus.ON_UNLOADING
        ]
    }

    static getExchangeStatuses() {
        return [
            RequestStatus.FREE
        ]
    }

    static getCompletedStatuses() {
        return [
            RequestStatus.UNLOADED, RequestStatus.CANCELED,
            RequestStatus.REJECTED, RequestStatus.DECLINED_BY_CARRIER
        ]
    }

    static getRequestStatusRequestStatuses() {
        return [
            RequestStatus.ACTUAL, RequestStatus.NOT_APPROVED,
            RequestStatus.APPROVED, RequestStatus.CANCELED,
            RequestStatus.REJECTED, RequestStatus.BROKEN,
            RequestStatus.UNLOADED, RequestStatus.ON_WAY_TO_LOADING,
            RequestStatus.ON_LOADING, RequestStatus.ON_WAY_TO_UNLOADING,
            RequestStatus.ON_UNLOADING, RequestStatus.WASTED
        ];
    }

    static getMonitoringHeadOfDeptRequestStatuses() {
        return [
            RequestStatus.NOT_APPROVED
        ];
    }

    static getMonitoringLogisticRequestStatuses() {
        return [
            RequestStatus.APPROVED, RequestStatus.ON_LOADING,
            RequestStatus.ON_WAY_TO_LOADING, RequestStatus.ON_WAY_TO_UNLOADING,
            RequestStatus.ON_UNLOADING, RequestStatus.NOT_APPROVED
        ];
    }

    static getCarrierAndDriverRequestStatuses() {
        return [
            RequestStatus.ON_LOADING,
            RequestStatus.ON_WAY_TO_LOADING, RequestStatus.ON_WAY_TO_UNLOADING,
            RequestStatus.ON_UNLOADING, RequestStatus.UNLOADED
        ];
    }

    static getCarrierAndDriverAvailableRequestStatuses(requestStatus) {
        const result = [ requestStatus ];

        switch (requestStatus.id) {
            case 2: result.push(RequestStatus.ON_WAY_TO_LOADING); break;
            case 25: result.push(RequestStatus.ON_LOADING); break;
            case 10:  result.push(RequestStatus.ON_WAY_TO_UNLOADING); break;
            case 26: result.push(RequestStatus.ON_UNLOADING); break;
            case 12: result.push(RequestStatus.UNLOADED);
        }

        return result;
    }

    static getGarbageStatuses() {
        return [
            RequestStatus.CANCELED, RequestStatus.REJECTED,
            RequestStatus.BROKEN, RequestStatus.WASTED
        ]
    }

    static get AFTER_LOADING_STATUS_IDS() {
        return [
            RequestStatus.APPROVED, RequestStatus.NOT_APPROVED
        ].map(s => s.id)
    };

    static get AFTER_UNLOADING_STATUS_IDS() {
        return [
            RequestStatus.APPROVED, RequestStatus.ON_LOADING,
            RequestStatus.ON_WAY_TO_LOADING, RequestStatus.ON_WAY_TO_UNLOADING,
            RequestStatus.NOT_APPROVED, RequestStatus.UNLOADED
        ].map(s => s.id)
    };

    static getQuoteStatusIdsByToggleButton(toggleOn) {
        return (toggleOn
            ? [
                RequestStatus.APPROVED,
                RequestStatus.UNLOADED,
                RequestStatus.ON_LOADING,
                RequestStatus.ON_WAY_TO_LOADING,
                RequestStatus.ON_WAY_TO_UNLOADING,
                RequestStatus.ON_UNLOADING
            ]
            : [RequestStatus.UNLOADED])
            .map(rs => rs.id);
    }

    static getLogisticDepManagerQuoteStatuses() {
        return [
            RequestStatus.NOT_APPROVED,
            RequestStatus.APPROVED,
            RequestStatus.AT_WORK,
            RequestStatus.UNLOADED,
            RequestStatus.ON_LOADING,
            RequestStatus.ON_WAY_TO_LOADING,
            RequestStatus.ON_WAY_TO_UNLOADING,
            RequestStatus.ON_UNLOADING
        ];
    }

    static getDriverValidatorQuoteStatuses() {
        return [
            RequestStatus.NOT_APPROVED,RequestStatus.APPROVED,RequestStatus.UNLOADED,
            RequestStatus.ON_LOADING, RequestStatus.ON_UNLOADING,
            RequestStatus.ON_WAY_TO_UNLOADING, RequestStatus.ON_WAY_TO_LOADING
        ]
    }

}

class QuoteType {

    static get QUOTE() { return "quotes" };
    static get MONITORING() { return "monitoring" };
    static get FREE() { return "free-quotes" };

}

class TaskType {

    static TYPE_EPIC_TEMPLATE() { return { id: 1, value: 'Шаблон Epic' } };
    static TYPE_TEMPLATE() { return { id: 3, value: 'Шаблон задача' } };
    static TYPE_EPIC() { return { id: 4, value: 'Epic' } };
    static TYPE_DEFAULT() { return { id: 2, value: 'Задача' } };

    static TYPE_TASKS() { return "tasks" };
    static TYPE_TEMPLATES() { return "templates" };

    static getTemplateTypes() {return [TaskType.TYPE_EPIC_TEMPLATE(), TaskType.TYPE_TEMPLATE()]};
    static getActiveTypes() {return [TaskType.TYPE_EPIC(), TaskType.TYPE_DEFAULT()]};

}

class TaskTopic {

    static OTHER() { return { id: 1, value: "Другое" } };
    static TTN_ON_RECOVERY() { return { id: 2, value: "ТТН на восстановлении" } };
    static CARRIER_INCOMING_DOC() { return { id: 5, value: "Входящие документы перевозчика" } };

}

class Pathname {
    static get TRANSPORT_QUOTE() { return `/${QuoteType.QUOTE}/quote` };
}

class PaymentMode {
    static PREPAYMENT() { return { id: 5, value: 'Предоплата' } };
}

class ExternalLinks{
    static KNOWLEDGE_BASE_LINK() {return "https://transport-help.online/display/CRM"};
    static TECHNICAL_SUPPORT_LINK() {return "http://arttekdc.ru/servicedesk/customer/portals"};
}

class QuoteClothingMethod {
    static get FIXED_RATE() { return { name: "FIXED_RATE", value: "Фиксированная ставка" } };
    static get BY_LOGISTIC() { return { name: "BY_LOGISTIC", value: "Подбор логистом" } };
}

class Forwarder {
    static ARTTEK() { return {id: 1, value: "АРТТЭК"} }
}

class PaymentType {
    static get AUCTION() { return Option.of(Enums.PAYMENT_TYPE.AUCTION) }
    static get FIXED_BET() { return Option.of(Enums.PAYMENT_TYPE.FIXED_BET) }
    static get ALL() { return [this.AUCTION, this.FIXED_BET] }
}

class ConfirmationType {
    static get BOOKING()  { return "BOOKING" };
    static get CANCELED() { return "CANCELED" };
    static get SIGNING() { return "SIGNING" };
}

class Option {
    static of(type) {
        return {id: 0, value: type.value}
    }
    static get EMPTY_VALUE() { return '(выбрать)' };
}

export {
    RoutePoint,
    Attachment,
    RequestStatus,
    QuoteType,
    Pathname,
    TaskType,
    TaskTopic,
    PaymentMode,
    ExternalLinks,
    QuoteClothingMethod,
    Forwarder,
    PaymentType,
    ConfirmationType,
    Option
}