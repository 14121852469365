import React from "react";
import DatePicker from "react-datepicker";
import "./DtPicker.css";
import ru from "date-fns/locale/ru"; // the locale you want
import moment from 'moment';
import { parseISO } from 'date-fns';

class DtPicker extends React.PureComponent {
	constructor(props) {
		super(props);
		
		if (this.props.value) {	
			this.props.onChange(moment(this.props.value).format("YYYY-MM-DDTHH:mm:ss"));
		}
	}
	
	render() {
		let className = "form-control form-control-sm";
		if (this.props.isInvalid) {
			className += " is-invalid";
		}
		const dt = this.props.value ? parseISO(moment(this.props.value).format("YYYY-MM-DDTHH:mm:ss")) : null;
		return (
			<DatePicker
				placeholderText={this.props.placeholder}
				showTimeSelect={!!this.props.showTimeSelect}
				timeFormat="HH:mm"
				isClearable={this.props.isClearable}
				dateFormat={[this.props.showTimeSelect ? "dd.MM.yyyy HH:mm" : "d MMMM, yyyy", "dd.MM.yyyy"]}
				name={this.props.name}
				onChange={date => this.props.onChange(date ? moment(date).format("YYYY-MM-DDTHH:mm:ss") : null)}
 				className={className}
				autocomplete="off"
				selected={dt} 
				readOnly={this.props.readOnly}
				locale={ru} />
		);
	}
}

export default DtPicker;