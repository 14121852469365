import React, {PureComponent} from "react";
import {Button, Col, ListGroup, Row} from "react-bootstrap";
import scriptService from "../../../services/ScriptService";
import {connect} from "react-redux";
import {setToastObjAC} from "../../../reducers/toastObj";


function mapGlobalStateToProps(state) {
    return {}
}

class Script extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {loading: false, internalEmails: [], externalEmails: []}
    }

    render() {
        const {loading, internalEmails, externalEmails} = this.state;
        return (
            <Row>
                <Col>
                    <Button disabled={loading} onClick={() => this.handleChangePassword("INTERNAL")}>
                        {loading && (<span className="spinner-border spinner-border-sm"></span>)}
                        <span>Изменить пароль внутренним пользователям</span>
                    </Button>
                    <ListGroup>
                        {internalEmails.map(email =>
                            <ListGroup.Item>
                                <span>{email}</span>
                            </ListGroup.Item>
                        )}
                    </ListGroup>
                </Col>
                <Col>
                    <Button disabled={loading} onClick={() => this.handleChangePassword("EXTERNAL")}>
                        {loading && (<span className="spinner-border spinner-border-sm"></span>)}
                        <span>Изменить пароль внешним пользователям</span>
                    </Button>
                    <ListGroup>
                        {externalEmails.map(email =>
                            <ListGroup.Item>
                                <span>{email}</span>
                            </ListGroup.Item>
                        )}
                    </ListGroup>
                </Col>
            </Row>
        );
    }

    async handleChangePassword(type) {
        await this.submit(async () => {
            const response = await scriptService.changePassword(type);
            const data = {show: true, textHeader: `Пароль изменён у ${response.length} пользователей!`, delay: 3000};
            this.showToast(data);
            type === "INTERNAL"
                ? this.setState({internalEmails: response})
                : this.setState({externalEmails: response});
        })
    }

    async submit(call) {
        this.setState({loading: true});
        await call();
        this.setState({loading: false});
    }


    showToast(data) {
        this.props.setToastObjAC(data);
    }
}

export default connect(mapGlobalStateToProps, {setToastObjAC})(Script);
