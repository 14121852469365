import React from "react";
import { connect } from 'react-redux';
import organizationService from '../../../services/OrganizationService';
import DataTable from '../../table/DataTable';

import localStorageService from "../../../services/LocalStorageService";

class Organizations extends React.PureComponent {
	constructor(props) {
		super(props);
	}

	fetch(params) {
		return organizationService.list(params);
	}

	openAddForm() {
		window.open("/control-panel/organizations/organization", "_blank")
	}

	openEditForm(row) {
		window.open(`/control-panel/organizations/organization?id=${row.id}`, "_blank")
	}
	
	render() {
		return (
			<DataTable {...this.props}
				title = "Организации"
				sort = {{field: 'value', dir: 'asc'}}
				openAddForm = {this.openAddForm.bind(this)}
				openEditForm = {this.openEditForm.bind(this)}
				fetch = {this.fetch.bind(this)}
				columns = {[
					{ title: "#", field: "id", className: "id", filter: DataTable.STRING_FILTER },
					{ title: "Название", field: "value", filter: DataTable.STRING_FILTER }
				]}
			/>
		);
	}
}
export default connect(DataTable.mapStateToProps)(Organizations);