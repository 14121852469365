import React, {PureComponent} from "react";
import {Button, Card, Form} from "react-bootstrap"
import {TheSelect} from "../../ui/TheSelect/TheSelect"
import {connect} from "react-redux";
import {
    setAuctionBidType,
    setAuctionNdsType,
    setBidStep,
    setFirstPrice,
    setFormErrors,
    setIsBuyoutAvailable,
    setIsOneBidOnly,
    setMinPrice,
    setStatus,
    setTimeFinish,
    setTimeStart,
    setValidationErrors
} from "../../../store/AuctionFormStore/auctionFormActions";
import ValidationErrors from "../../../validators/ValidationErrors";
import auctionFormValidator from "./validation/auctionFormValidator";
import {setToastObjAC} from "../../../reducers/toastObj";
import {ToastService} from "../../../services/ToastService/ToastService";
import cls from './AuctionSettingsForm.module.css'
import {STATUSES} from "../consts/consts";
import {TheInput} from "../../ui/TheInput/TheInput";
import {TheDatepicker} from "../../ui/TheDatepicker/TheDatepicker";
import PropTypes from "prop-types";
import {classNames} from "../../../helpers/classNames";
import FieldErrors from "../../ui/FieldError/FieldError";

class AuctionSettingsForm extends PureComponent {
    constructor(props) {
        super(props);
    }

    changeAuctionBidTypeHandler = (option) => {
        this.props.setAuctionBidType(option.value);
    }

    changeAuctionNdsTypeHandler = (option) => {
        this.props.setAuctionNdsType(option.value);
    }

    changeIsOneBidOnlyHandler = () => {
        const {
            isOneBidOnly,
            setIsOneBidOnly
        } = this.props;

        setIsOneBidOnly(!isOneBidOnly);
    }

    changeIsBuyoutAvailableHandler = () => {
        const {
            isBuyoutAvailable,
            setIsBuyoutAvailable
        } = this.props;

        setIsBuyoutAvailable(!isBuyoutAvailable);
    }

    changeFirstPriceHandler = (event) => {
        const value = parseInt(event.target.value)
        this.props.setFirstPrice(value);
    }

    changeMinPriceHandler = (event) => {
        const value = parseInt(event.target.value)
        this.props.setMinPrice(value);
    }

    changeBidStepHandler = (event) => {
        const value = parseInt(event.target.value)
        this.props.setBidStep(value);
    }

    changeTimeStartHandler = (value) => {
        this.props.setTimeStart(value);
    }

    changeTimeFinishHandler = (value) => {
        this.props.setTimeFinish(value);
    }

    submitFormHandler = async (event) => {
        event.preventDefault()
        const {
            auctionBidType,
            auctionNdsType,
            isOneBidOnly,
            isBuyoutAvailable,
            firstPrice,
            minPrice,
            bidStep,
            status,
            timeStart,
            timeFinish,
            setFormErrors
        } = this.props;

        setFormErrors(null);

        const formData = {
            auctionBidType,
            auctionNdsType,
            firstPrice,
            timeStart,
            timeFinish,
            isOneBidOnly,
        }

        if (isBuyoutAvailable) {
            formData["minPrice"] = minPrice;
        }

        if (auctionBidType === "fixed") {
            formData["bidStep"] = bidStep;
        }

        if (status) {
            formData['status'] = status;
        }

        await this.validateForm(formData);

        if (this.isAnyErrors()) {
            ToastService.error({
                title: "Ошибка валидации",
                message: "Часть полей заполнены неверно"
            })
            return;
        }

        this.props.onSubmit && this.props.onSubmit(formData)
    }

    validateForm = (formData) => {
        const {setValidationErrors} = this.props;
        const errors = auctionFormValidator(formData);
        setValidationErrors(errors);
    }

    isAnyErrors = () => {
        const {validationErrors} = this.props;
        return Object.values(validationErrors).some((field) => field.length);
    }

    render() {
        const {
            auctionBidType,
            auctionNdsType,
            firstPrice,
            minPrice,
            status,
            timeStart,
            timeFinish,

            bidStep,
            isOneBidOnly,
            isBuyoutAvailable,

            auctionBidTypeOptions,
            auctionNdsOptions,

            error,
            isLoading,
            validationErrors,

            onCancel
        } = this.props;

        return (
            <Card className={cls.auctionForm}>
                <Card.Body>
                    <form className={cls.form} onSubmit={this.submitFormHandler}>
                        <div className={cls.wrapper}>
                            {
                                status && (
                                    <div className={cls.status}>
                                        <div>
                                            Статус: {STATUSES[status]}

                                            {error?.status && (
                                                <FieldErrors errors={error?.status}/>
                                            )}
                                        </div>
                                    </div>
                                )
                            }

                            {
                                (
                                    error?.general && (
                                        <div className={cls.httpError}>
                                            {error?.general}
                                        </div>
                                    )
                                )
                            }

                            <div className={classNames(cls.fieldsRow, {}, [cls.firstRow])}>
                                <TheDatepicker
                                    label="Время начала аукциона"
                                    className={cls.row}
                                    value={timeStart}
                                    name="timeStart"
                                    errors={error?.timeStart || validationErrors.timeStart}
                                    onChange={this.changeTimeStartHandler}
                                />

                                <TheDatepicker
                                    label="Время окончания аукциона"
                                    className={cls.row}
                                    value={timeFinish}
                                    name="timeFinish"
                                    errors={error?.timeFinish || validationErrors.timeFinish}
                                    onChange={this.changeTimeFinishHandler}
                                />
                            </div>

                            <div className={cls.fieldsRow}>
                                <TheInput
                                    label="Начальная цена"
                                    type="number"
                                    id="firstPrice"
                                    value={firstPrice || ""}
                                    className={cls.row}
                                    errors={error?.firstPrice || validationErrors.firstPrice}
                                    onChange={this.changeFirstPriceHandler}
                                />

                                <TheSelect
                                    className={cls.row}
                                    label="Режим НДС"
                                    value={auctionNdsOptions.find(option => option.value === auctionNdsType)}
                                    name="nds_type"
                                    options={auctionNdsOptions}
                                    errors={error?.auctionNdsType || validationErrors.auctionNdsType}
                                    onChange={this.changeAuctionNdsTypeHandler}
                                />
                            </div>

                            <div className={cls.fieldsRow}>
                                <TheSelect
                                    className={cls.row}
                                    label="Тип ставки"
                                    value={auctionBidTypeOptions.find(option => option.value === auctionBidType)}
                                    name="auction_type"
                                    placeholder="Выберите..."
                                    options={auctionBidTypeOptions}
                                    errors={error?.auctionBidType || validationErrors.auctionBidType}
                                    onChange={this.changeAuctionBidTypeHandler}
                                />

                                {
                                    auctionBidType === "fixed" && (
                                        <TheInput
                                            label="Шаг цены"
                                            type="number"
                                            id="bidStep"
                                            value={bidStep || ""}
                                            className={cls.row}
                                            errors={error?.bidStep || validationErrors.bidStep}
                                            onChange={this.changeBidStepHandler}
                                        />
                                    )
                                }
                            </div>

                            <div className={cls.fieldsRow}>
                                <div className={cls.row}>
                                    <Form.Check
                                        label="Аукцион с выкупом"
                                        checked={isBuyoutAvailable}
                                        onChange={this.changeIsBuyoutAvailableHandler}
                                    />
                                </div>

                                <div className={cls.row}>
                                    <Form.Check
                                        label="Аукцион с одной ставкой"
                                        checked={isOneBidOnly}
                                        onChange={this.changeIsOneBidOnlyHandler}
                                    />
                                </div>
                            </div>

                            <div className={cls.fieldsRow}>
                                {
                                    isBuyoutAvailable && (
                                        <TheInput
                                            label="Цена выкупа"
                                            type="number"
                                            id="minPrice"
                                            value={minPrice || ""}
                                            className={cls.row}
                                            error={error?.minPrice || validationErrors.minPrice}
                                            onChange={this.changeMinPriceHandler}
                                        />
                                    )
                                }
                            </div>

                            <div className={cls.buttons}>
                                <Button
                                    type="submit"
                                    disabled={isLoading}
                                    className={cls.submit}
                                >
                                    {status ? "Изменить " : "Создать "}
                                    аукцион
                                </Button>

                                {
                                    (
                                        status === "planned"
                                        || status === "active"
                                    ) && (
                                        <Button
                                            type="button"
                                            variant="danger"
                                            disabled={isLoading}
                                            className={cls.submit}
                                            onClick={onCancel}
                                        >
                                            Отменить аукцион
                                        </Button>
                                    )
                                }
                            </div>
                        </div>
                    </form>
                </Card.Body>
            </Card>
        )
    }
}

const mapStateToProps = (state) => ({
    id: state.auctionForm?.id,
    firstPrice: state.auctionForm?.firstPrice,
    minPrice: state.auctionForm?.minPrice,
    status: state.auctionForm?.status,
    timeStart: state.auctionForm?.timeStart,
    timeFinish: state.auctionForm?.timeFinish,

    bidStep: state.auctionForm?.bidStep,
    isOneBidOnly: state.auctionForm?.isOneBidOnly,
    isBuyoutAvailable: state.auctionForm?.isBuyoutAvailable,

    auctionBidType: state.auctionForm?.auctionBidType,
    auctionNdsType: state.auctionForm?.auctionNdsType,

    auctionBidTypeOptions: state.auctionForm?.auctionBidTypeOptions,
    auctionNdsOptions: state.auctionForm?.auctionNdsOptions,

    isLoading: state.auctionForm?.isLoading,
    error: state.auctionForm?.error,
    validationErrors: state.auctionForm?.validationErrors,
});

const mapDispatchToProps = {
    setFormErrors,
    setAuctionBidType,
    setAuctionNdsType,
    setIsOneBidOnly,
    setIsBuyoutAvailable,
    setFirstPrice,
    setMinPrice,
    setBidStep,
    setStatus,
    setTimeStart,
    setTimeFinish,
    setValidationErrors,
    setToastObjAC
};

AuctionSettingsForm.propTypes = {
    auctionBidType: PropTypes.string.isRequired,
    auctionNdsType: PropTypes.string.isRequired,
    firstPrice: PropTypes.number.isRequired,
    minPrice: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
    timeStart: PropTypes.string.isRequired,
    timeFinish: PropTypes.string.isRequired,
    bidStep: PropTypes.number.isRequired,
    isOneBidOnly: PropTypes.bool.isRequired,
    isBuyoutAvailable: PropTypes.bool.isRequired,

    auctionBidTypeOptions: PropTypes.array.isRequired,
    auctionNdsOptions: PropTypes.array.isRequired,

    error: PropTypes.object,
    isLoading: PropTypes.bool.isRequired,

    validationErrors: PropTypes.object.isRequired,

    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,

    setAuctionBidType: PropTypes.func.isRequired,
    setAuctionNdsType: PropTypes.func.isRequired,
    setBidStep: PropTypes.func.isRequired,
    setFirstPrice: PropTypes.func.isRequired,
    setMinPrice: PropTypes.func.isRequired,
    setTimeStart: PropTypes.func.isRequired,
    setTimeFinish: PropTypes.func.isRequired,
    setIsOneBidOnly: PropTypes.func.isRequired,
    setIsBuyoutAvailable: PropTypes.func.isRequired,
    setFormErrors: PropTypes.func.isRequired,
    setValidationErrors: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(AuctionSettingsForm);
