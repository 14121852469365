import DataTable from "../../table/DataTable";
import React from "react";
import taskService from "../../../services/tasktracker/TaskService";
import TableCell from "../../table/TableCell";
import {
    COMMERCIAL,
    securityService
} from "../../../services/SecurityService";
import {TaskType} from "../../../Const";
import {ENUM_NAME, TASK_STATUS} from "../../../Enums";

class TaskTable extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {dataTableAlerts: [], dataTableNotifications: []}
        this.columns = this.columns.bind(this);
    }

    fetch(params) {
        params.types = this.getTypes();
        params.quoteNumber = this.props.quoteNumber || params.quoteNumber;
        return taskService.list(params);
    }

    async componentDidMount() {
        if (this.props.type === TaskType.TYPE_TASKS()) {
            await this.formatDueDateErrors();
        }
    }

    formatTaskAlerts(tasks, message) {
        const formatQuoteNumber = (task) => !!task.quoteNumber ? <span><br/>Заявка: {task.quoteNumber}</span> : "";
        const formatAlertMessage = (task) => <span>{message}<br/>Статус: {task.status.value} {formatQuoteNumber(task)}</span>;

        return tasks
            .filter(task => this.props.quoteNumber ? task.quoteNumber === this.props.quoteNumber : true)
            .map(task => {return {id: task.id, value: formatAlertMessage(task)}}).filter(task => !!task);
    }

    formatDueDateErrors() {
        const user = securityService.getUser();
        return taskService.findOverdueTasks(user.id).then(response => {
            const overdueAssigneeAlerts = this.formatTaskAlerts(response.overdueAssigneeTasks, "Необходимо сделать задачу.");
            const overdueReviewedAlerts = this.formatTaskAlerts(response.overdueReviewedTasks, "Необходимо проверить задачу.");
            this.setState({dataTableAlerts: response.overdueTasks, dataTableNotifications: overdueAssigneeAlerts.concat(overdueReviewedAlerts)});
        })
    }

    formatUsers(options) {
        const users = options || [];
        return TableCell.arrayFormatter(users.length > 0 ? users.map(it => it?.value) : users);
    }

    getTypes() {
        switch (this.props.type) {
            case TaskType.TYPE_TASKS():
                return [TaskType.TYPE_EPIC(), TaskType.TYPE_DEFAULT()];
            case TaskType.TYPE_TEMPLATES():
                return [TaskType.TYPE_EPIC_TEMPLATE(), TaskType.TYPE_TEMPLATE()];
        }
    }

    columns() {
        const isTemplates = this.props.type === TaskType.TYPE_TEMPLATES();
        return [
            { title: "#", field: "id", filter: DataTable.STRING_FILTER, className: "id overflow" },
            { title: "Дата", field: "createdAt", filter: DataTable.DATE_RANGE_FILTER, formatter: TableCell.dtFormatter },
            { title: "Заявка", field: "quoteNumber", filter: DataTable.STRING_FILTER, className: "overflow", sortable: false },
            { title: "Тип", field: "type", filter: DataTable.DICTIONARY_FILTER, optionsType: "TASK_TYPE", filteredOptions: this.getTypes() },
            { title: "Статус", field: "status", filter: DataTable.ENUM_FILTER, optionsType: ENUM_NAME.TASK_STATUS, multiSelect: true,
                formatter: TableCell.enumFormatter(TASK_STATUS) },
            { title: "Подразделение", field: "department", filter: DataTable.DICTIONARY_FILTER, optionsType: "DEPARTMENT", activeOnly: false, multiSelect: true },
            { title: "Отдел", field: "division", filter: DataTable.DICTIONARY_FILTER, optionsType: "DIVISION", activeOnly: false },
            { title: "Создал", field: "owner", filter: DataTable.DICTIONARY_FILTER, optionsType: "CRM_USER", activeOnly: false },
            { title: "Ответственные", field: "assignees", filter: DataTable.DICTIONARY_FILTER,
                formatter: value => this.formatUsers(value), optionsType: "CRM_USER", activeOnly: false, multiSelect: true, sortable: false },
            { title: "Проверяющие", field: "reporters", filter: DataTable.DICTIONARY_FILTER,
                formatter: value => this.formatUsers(value), optionsType: "CRM_USER", activeOnly: false, multiSelect: true, sortable: false },
            { title: "Тема", field: "topic", filter: DataTable.DICTIONARY_FILTER, optionsType: "TOPIC", activeOnly: false },
            { title: "Название", field: "title", filter: DataTable.STRING_FILTER, className: "overflow" },
            { title: "Заказчик", field: "client", filter: DataTable.DICTIONARY_FILTER, optionsType: "CONTRACTOR_CLIENT", activeOnly: false, sortable: false },
            { title: "Перевозчик", field: "carrier", filter: DataTable.DICTIONARY_FILTER, optionsType: "CONTRACTOR_CARRIER", activeOnly: false, sortable: false },
            { title: "Менеджер КО", field: "commercialDepManager", filter: DataTable.USER_FILTER, role: "COMMERCIAL", activeOnly: false, sortable: false },
            { title: "Начало", field: "startDate", filter: DataTable.DATE_RANGE_FILTER, formatter: TableCell.dtFormatter },
            { title: "Конец", field: "dueDate", filter: DataTable.DATE_RANGE_FILTER, formatter: TableCell.dtFormatter },
            { title: "Родительская задача", field: "parentTask", filter: DataTable.STRING_FILTER },
            { ...isTemplates && { title: "Активирован", field: "periodicityActivation", formatter: TableCell.booleanFormatter, filter: DataTable.BOOLEAN_FILTER }}
        ];
    }

    openEditForm(row) {
        window.open(`/task-tracker/task?id=${row.id}`, "_blank");
    }

    openAddForm() {
        window.open(`/task-tracker/task?isTemplate=${this.props.type === TaskType.TYPE_TEMPLATES()}`, "_blank");
    }

    render() {
        return (
          <DataTable {...this.props}
              title = {this.props.type === TaskType.TYPE_TASKS() ? "Задачи" : "Шаблоны"}
              fetch = {this.fetch.bind(this)}
              openEditForm = {this.openEditForm.bind(this)}
              openAddForm = {this.openAddForm.bind(this)}
              resizable = {true}
              columns = {this.columns().filter(col => { return Object.keys(col).length !== 0 })}
              features = { {
                  canAdd: true,
                  showAlertCenter: true,
                  search: false,
              } }
              dataTableAlerts = {this.state.dataTableAlerts}
              dataTableNotifications = {this.state.dataTableNotifications}
              alertClickHandler = {this.openEditForm.bind(this)}
              alertItemTitle = {"Задача"}
              tableClassName = "no-line-break"
          />
        );
    }
}

export default TaskTable;
