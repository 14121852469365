import { connect } from 'react-redux';
import { Form, Row, Col, Tab } from "react-bootstrap";

import { requiredValidator} from "../../../validators/simpleValidators";
import StoreWrapper from '../../form/StoreWrapper';
import BaseForm from '../../form/BaseForm';
import FormGroup from '../../form/FormGroup';
import { setData } from '../../../actions/form';
import Loading from '../../Loading';
import {setToastObjAC} from "../../../reducers/toastObj";
import forwarderService from "../../../services/ForwarderService";

function mapGlobalStateToProps(state) {
	return {}
}

class Forwarder extends StoreWrapper {
	constructor(props) {
		super(props);
	}

	static ARTTEK() { return {id: 1, value: "АРТТЭК"} }

	render() {
		return (
			<ForwarderInnerConnected {...this.props} store={this.store} />
		);
	}
}

function mapStateToProps(state) {
	return {
		errors: state.errors,
		model: state.model
	};
}
class ForwarderInner extends BaseForm {
	constructor(props) {
		super(props);

		this.configureValidators();
	}

	configureValidators() {
		this.useValidatorFor(requiredValidator, "value");
	}

	getForwarderId() {
		return this.props.location.state?.id
			|| new URLSearchParams(this.props.location.search).get('id');
	}

	load() {
		const id = this.getForwarderId();
		if (id) {
			forwarderService.read(id).then(data => {
				this.props.store.dispatch(setData(data, this.props.location.state?.action));
			});
		}
	}

	handleSubmit(e) {
		e.preventDefault();
		this.submit(() => forwarderService.save(this.props.model).then(()  => this.afterSubmit()));
	}

	afterSubmit() {
		const data = {show: true, textHeader: "Экспедитор сохранен!", delay: 3000};
		if(!this.getForwarderId()) {
			this.redirectToBackOrDefaultUrl('/control-panel/forwarders');
			data.textHeader = "Экспедитор создан!"
		}
		this.props.setToastObjAC(data)
	}

	render() {
		const isReady = !this.getForwarderId() || this.props.model.id;
		if (!isReady) {
			return (<Loading/>);
		}
		return (
			<Form>

				<Row>
					<Col lg={4} md={12}>
						<FormGroup title="Наименование" name="value" store={this.props.store} required />
					</Col>
				</Row>
				
				{this.renderSaveCancelButtons()}
			</Form>
		);
	}
}

const ForwarderInnerConnected = connect(mapStateToProps)(ForwarderInner);

export default connect(mapGlobalStateToProps, {setToastObjAC})(Forwarder);