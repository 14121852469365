export class AbstractWebSocket {
    connect(url) {
        throw new Error('Method not implemented');
    }

    sendMessage(message) {
        throw new Error('Method not implemented');
    }

    onMessage(callback) {
        throw new Error('Method not implemented');
    }

    disconnect() {
        throw new Error('Method not implemented');
    }
}
