import React, {PureComponent} from "react";
import cls from "./AuctionLastBidRow.module.css";
import {classNames} from "../../../helpers/classNames";
import {AuctionBidInfo} from "../AuctionBidInfo/AuctionBidInfo";

export class AuctionLastBidRow extends PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            lastBid,
            className
        } = this.props;

        return (
            <AuctionBidInfo
                className={classNames(cls.wrapper, {}, [className])}
                textComponent={
                    <span className={cls.text}>Текущая цена</span>
                }
                priceComponent={
                    <div className={cls.price}>
                        <span className={cls.sign}>₽</span>

                        <span>
                            {lastBid}
                        </span>
                    </div>
                }
            />
        )
    }
}