import FormValidator from "./FormValidator";
import {Attachment, RequestStatus, RoutePoint} from "../Const";
import Util from "../Util";
import moment from "moment";
import contractorService from "../services/ContractorService";
import {requiredValidator} from "./simpleValidators";
import organizationService from "../services/OrganizationService";

const REQUIRED_FIELD_TEXT = "Обязательное поле";

const isActual = model => {
	return model.status?.id === RequestStatus.ACTUAL.id;
}

const clientContractValidator = model => {
	return Promise.resolve(!isActual(model) && !model.clientContract?.id ? REQUIRED_FIELD_TEXT : FormValidator.OK);
};

const carrierContractValidator = model => {
	const quoteStatuses = [RequestStatus.ACTUAL.id, RequestStatus.WASTED.id];
	if (quoteStatuses.includes(model.status?.id)
		|| (!model.carrier || !model.carrierOrganization)) {
		return FormValidator.OK;
	} else {
		return Promise.resolve(model.carrierContract?.id ? FormValidator.OK : REQUIRED_FIELD_TEXT);
	}
};

const truckNumberValidator = model => {
	return Promise.resolve(
		RequestStatus.getRequestsWithoutTruck().includes(model.status?.id) || model.truck?.id
			? FormValidator.OK
			: REQUIRED_FIELD_TEXT
	)
}

const clientOrganizationValidator = model => organizationValidator(model, "organization");
const carrierOrganizationValidator = model => organizationValidator(model, "carrierOrganization");
const freeCarrierOrganizationValidator = model => freeOrganizationValidator(model, "carrierOrganization");
const carrierValidator = model => organizationValidator(model, "carrier");
const driverValidator = model => organizationValidator(model, "driver");
const carrierPriceValidatorAsAppr = model => counterValidatorAsAppr(model, "carrierPrice");
const carrierPriceValidator = model => counterValidator(model, "carrierPrice");
const driverValidatorAsAppr = model => organizationValidatorAsNotAppr(model, "driver");
const clientOrganizationValidatorAsAppr = model => organizationValidatorAsNotAppr(model, "organization");
const carrierOrganizationValidatorAsAppr = model => organizationValidatorAsNotAppr(model, "carrierOrganization");
const carrierValidatorAsAppr = model => organizationValidatorAsNotAppr(model, "carrier");

const statusFreeEmptyFieldsValidator = model => model.status?.id === RequestStatus.FREE.id
	? fieldsMustBeEmpty(model, "driver", "truck", "carrier")
	: Promise.resolve(FormValidator.OK);

const contractorPriceValidator = model => Promise.resolve(
	(![RequestStatus.FREE.id, RequestStatus.NOT_APPROVED.id, RequestStatus.APPROVED.id].includes(model.status?.id) || (model.contractorPrice && model.contractorPrice > 0))
		? FormValidator.OK
		: REQUIRED_FIELD_TEXT
);

const freeOrganizationValidator = (model, organizationField)  => {
	if (model.status?.id === RequestStatus.FREE.id) {
		return Promise.resolve(!!model[organizationField] ? FormValidator.OK : REQUIRED_FIELD_TEXT);
	}
}
const departmentValidator = model => isActual(model) ||model?.department ? FormValidator.OK : REQUIRED_FIELD_TEXT;
const kilometreValidator = model => {
	if (model.kilometre) {
		return Promise.resolve(model.kilometre >= 0 && model.kilometre < 90_000
			? FormValidator.OK
			: "Не меньше 0 и меньше 90000");
	}
}

const carrierPaymentDelayValidator = model => {
	const isActualOrFree = model.status?.id === RequestStatus.ACTUAL.id || model.status?.id === RequestStatus.FREE.id;
	const isCorrectCarrierPaymentDelay = model.carrierPaymentDelay !== null && model.carrierPaymentDelay >= 10;

	return Promise.resolve(!isActualOrFree && !isCorrectCarrierPaymentDelay ? "Не меньше 10" : FormValidator.OK);

}

const lockedValidator = async model => {
	if (model.carrierOrganization?.id) {
		return model.status?.id === RequestStatus.FREE.id && await organizationService.isLocked(model.carrierOrganization?.id)
			? Promise.resolve("\"Внимание! Запрещено публиковать заявки от организации \"" + model?.carrierOrganization.value + "\"")
			: Promise.resolve(FormValidator.OK);
	}
	return Promise.resolve(FormValidator.OK);
}

const organizationValidator = (model, organizationField)  => {
	if (isApprOrNotAppr(model)) {
		return Promise.resolve(!!model[organizationField] ? FormValidator.OK : REQUIRED_FIELD_TEXT);
	}
}
const organizationValidatorAsNotAppr = (model, organizationField)  => {
	if (model.status?.id === RequestStatus.NOT_APPROVED.id) {
		return Promise.resolve(!!model[organizationField] ? FormValidator.OK : REQUIRED_FIELD_TEXT);
	}
}
const requiredFields = (model, ...fields)  => {
	return Promise.resolve(fields.some(field => !model[field]) ? REQUIRED_FIELD_TEXT : FormValidator.OK);
}
const fieldsMustBeEmpty = (model, ...fields)  => {
	return Promise.resolve(fields.some(field => model[field]) ? "Поле должно быть пустым" : FormValidator.OK);
}

const counterValidator = (model, organizationField)  => {
	if (isApprOrNotAppr(model) || model.status?.id === RequestStatus.FREE.id) {
		return Promise.resolve(!!model[organizationField] && model[organizationField] > 0 ? FormValidator.OK : "Не заполнена ставка!");
	}
}
const counterValidatorAsAppr = (model, organizationField)  => {
	if ([RequestStatus.FREE.id, RequestStatus.NOT_APPROVED.id].includes(model.status?.id)) {
		return Promise.resolve(!!model[organizationField] && model[organizationField] > 0 ? FormValidator.OK : "Не заполнена ставка!");
	}
}
const attachmentsValidator = model => {
	const attachments = model.attachments || [];
	const isValid = attachments.filter(it => it.type === Attachment.TYPE_ACCOMPANYING_DOCUMENTS).every(it => !!it.documentType);
	return Promise.resolve(isValid ? FormValidator.OK : "Укажите 'Тип документа' у каждого прикрепленного документа во вкладке 'Сканкопии документов'");
};

const routeValidator = model => {
	if ((isApprOrNotAppr(model) || model.status.id === RequestStatus.FREE.id) && routePointCoordinatesValidator(model.routePoints)) {
			return "Заполните координаты адресов";
	}
	if (!RequestStatus.getRequestsWithoutRoutePoints().includes(model.status.id)) {
		return areAllRoutePointsValid(model.routePoints) ? FormValidator.OK : "Укажите тип загрузки, груз и упаковку для всех пунктов погрузки/разгрузки";
	}
};

const routePointCoordinatesValidator = routePoints => {
	return routePoints?.length > 0 && routePoints.some(rp => !(rp.address && rp.address.latitude && rp.address.longitude));
};

const areAllRoutePointsValid = routePoints => {
	return routePoints.every(p => !!p.address &&
		!!p.dt && !!p.city && p.loadingType && p.loadingType.id > 0 && !!p.cargoType);
}

const temperatureValidator = model => {
	const isValid = model.routePoints.filter(p => !!p.carcass && p.carcass?.id === RoutePoint.CARCASS_REFRIGERATED.id).every(p => !!p.temperature);
	return Promise.resolve(isValid ? FormValidator.OK : "Укажите температурный режим для всех пунктов погрузки/разгрузки");
}

const loadUnloadDatesValidator = model => {
	let earliestUnload;
	let latestLoad;
	const getDt = (point) => moment(Util.formatDate(new Date(point.dt)) + " " + point.timeFrom, "DD/MM/YYYY HH:mm:ss").toDate();
	for (const point of model.routePoints) {
		const dt = getDt(point);
		if (point.pointType.id === RoutePoint.POINT_TYPE_LOADING && !earliestUnload
			&& (!latestLoad || latestLoad < dt)) {
			latestLoad = dt;
		} else if (point.pointType.id === RoutePoint.POINT_TYPE_UNLOADING
			&& (!earliestUnload || earliestUnload > dt)) {
			earliestUnload = dt;
		}
	}
	const isValid = !earliestUnload || !latestLoad || earliestUnload.getTime() >= latestLoad.getTime();
	return Promise.resolve(isValid ? FormValidator.OK : "Самая ранняя дата разгрузки должна быть больше или равна самой поздней дате загрузки");
}

const fromDateBeforeToValidator = model => {
	const from = model.dt,
		to = model.expireDt;
	return Promise.resolve(isActual(model) && from && to && from >= to ? "Дата окончания заявки должна быть после даты начала" : FormValidator.OK);
}

const statusAtWorkValidator = model => {
	const {status, logisticDepManager, department} = model;
	const isAtWorkStatus = status?.id === RequestStatus.AT_WORK.id;
	const isValid = isAtWorkStatus && logisticDepManager?.id && department?.id;

	return !isAtWorkStatus || isValid
		? Promise.resolve(FormValidator.OK)
		: requiredFields(model, "logisticDepManager");
}

const statusFreeValidator = model => {
	const {status, organization, contractor, carrier, contractorPrice,
		carrierPrice, driver, truck, freeTo, carrierOrganization} = model;
	const isFreeStatus = status?.id === RequestStatus.FREE.id;

	const isValid = isFreeStatus &&
		organization?.id &&
		contractor?.id &&
		contractorPrice >= 0 &&
		carrierPrice > 0 &&
		!carrier &&
		!driver &&
		!truck &&
		freeTo &&
		carrierOrganization
		areAllRoutePointsValid(model.routePoints);

	return Promise.resolve(!isFreeStatus || isValid
		? FormValidator.OK
		: "Заполните обязательные поля!");
}

const freeToValidator = model => {
	const {status, freeTo} = model;
	const isFreeStatus = status?.id === RequestStatus.FREE.id;

	return Promise.resolve(!isFreeStatus || freeTo
		? FormValidator.OK
		: "Укажите время");
}

const statusValidator = model => {
	const {status, organization, contractor, carrier, contractorPrice,
		carrierPrice, driver, truck} = model;
	let isValid = !status || ![RequestStatus.APPROVED.id, RequestStatus.NOT_APPROVED.id].includes(status.value);
	if (!isValid) {
		isValid = organization && organization.id &&
			contractor && contractor.id &&
			carrier && carrier.id &&
			contractorPrice >= 0 && carrierPrice > 0 &&
			driver && driver.id &&
			truck && truck.id &&
			areAllRoutePointsValid(model.routePoints)
	}
	return Promise.resolve(isValid ? FormValidator.OK : "Заполните обязательные поля!");
}

const clientContractsValidator = model => { return contractsValidator(model, model.contractor?.id); };

const carrierContractsValidator = model => { return contractsValidator(model, model.carrier?.id); };

const contractsValidator = async (model, contractorId) => {
	const errors = [];
	if ((isApprOrNotAppr(model)) && contractorId) {
		await contractorService.contracts(contractorId).then((contracts) => {
			if (contracts && contracts.length) {
				for (let i = 0; i < contracts.length; i++) {
					const contract = contracts[i];
					const paymentDelayIsNull = contract.paymentDelay === undefined || contract.paymentDelay === '';
					if (!contract.number || !contract.dt || !contract.contractor || !contract.organization || !contract.contractType ||
						paymentDelayIsNull || !contract.paymentDelayType || !contract.paymentMode || !contract.vatMode ||
						!contract.contractorAuthority || !contract.organizationAuthority || !contract.paymentAccount || !contract.organizationPaymentAccount) {
						errors.push(i + 1)
					}
				}
			}
		});
	}
	return Promise.resolve(errors.length ? `Не заполнены обязательные поля договоров с порядковыми номерами: ${errors.join(", ")}. Перейдите на страницу контрагента и заполните их.` : FormValidator.OK);
}

const logisticDepManagerValidator = model => {
	return Promise.resolve(
		(model.logisticDepManager === undefined || model.logisticDepManager === null) &&
		![RequestStatus.ACTUAL.id, RequestStatus.FREE.id, RequestStatus.REMOVED_FROM_PUBLICATION.id].includes(model.status.id) &&
		![RequestStatus.DECLINED_BY_CARRIER.value].includes(model.status.value) && [RequestStatus.APPROVED.id].includes(model.status.id)
			? REQUIRED_FIELD_TEXT
			: FormValidator.OK
	);
}

const statusRequiresSignatureValidator = model => {
	const {status, carrierOrganization, clientContract, carrierContract,
		carrier, carrierPrice, driver, truck} = model;
	const isRequiresSignatureStatus = status?.id === RequestStatus.ON_SIGNING.id;

	const isValid = isRequiresSignatureStatus &&
		carrierOrganization?.id &&
		clientContract?.id &&
		carrier?.id &&
		carrierContract?.id &&
		carrierPrice > 0 &&
		driver?.id &&
		truck?.id &&
		areAllRoutePointsValid(model.routePoints);

	return Promise.resolve(!isRequiresSignatureStatus || isValid
		? FormValidator.OK
		: requiredFields(model, "carrierOrganization", "clientContract", "carrier", "carrierContract", "driver", "truck"));
}

const isApprOrNotAppr = (model) => {
	return [RequestStatus.APPROVED.id, RequestStatus.NOT_APPROVED.id].includes(model.status?.id)
}

export {
	clientContractValidator,
	carrierContractValidator,
	clientOrganizationValidator,
	clientOrganizationValidatorAsAppr,
	carrierOrganizationValidatorAsAppr,
	carrierPriceValidatorAsAppr,
	driverValidatorAsAppr,
	carrierValidatorAsAppr,
	carrierOrganizationValidator,
	clientContractsValidator,
	carrierContractsValidator,
	attachmentsValidator,
	routeValidator,
	temperatureValidator,
	loadUnloadDatesValidator,
	fromDateBeforeToValidator,
	statusValidator,
	truckNumberValidator,
	statusFreeValidator,
	carrierValidator,
	carrierPriceValidator,
	driverValidator,
	kilometreValidator,
	departmentValidator,
	carrierPaymentDelayValidator,
	logisticDepManagerValidator,
	statusAtWorkValidator,
	statusFreeEmptyFieldsValidator,
	freeToValidator,
	statusRequiresSignatureValidator,
	contractorPriceValidator,
	freeCarrierOrganizationValidator,
	lockedValidator
};
