import React from "react";
import { Accordion, Button, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { popApiException } from "../actions/exceptionsActions";

class ApiExceptionModal extends React.PureComponent {
	constructor(props) {
		super(props);
		this.handleClose = this.handleClose.bind(this);
	}

	handleClose() {
		this.props.dispatch(popApiException(this.props.exception));
	}

	render() {
		const exception = this.props.exception,
			{ status, message, sourceMessage } = exception ? exception : {};
		if (!exception) {
			return (<div />);
		}
		return (
			<Modal show={!!exception}>
			<Modal.Header style={{ fontSize: "20px" , justifyContent: "center", fontWeight: "bold"}}>
				Ошибка
			</Modal.Header>
				<Modal.Body>
					{`${message}`}
				</Modal.Body>
				<Accordion>
					{sourceMessage &&
						<Accordion.Collapse eventKey="0">
							<Modal.Body>
								Описание: {sourceMessage}
							</Modal.Body>
						</Accordion.Collapse>
					}
					<Modal.Footer className="d-flex justify-content-end">
						{sourceMessage &&
							<Accordion.Toggle as={Button} variant="link" eventKey="0">
								Детали ошибки
							</Accordion.Toggle>
						}
						<Button variant="light" onClick={this.handleClose}>
							Закрыть
						</Button>
					</Modal.Footer>
				</Accordion>
			</Modal>
		);
	}
}

function mapStateToProps(state) {
	const exceptions = state.exceptions.api;
	return {
		exception: exceptions.length ? exceptions[exceptions.length - 1] : null
	};
}

export default connect(mapStateToProps)(ApiExceptionModal);