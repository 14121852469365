import React from "react";
import {TaskType} from "../../../Const";
import reportService from "../../../services/tasktracker/ReportService";
import Report from "../../reports/Report";
import TableCell from "../../table/TableCell";
import docService from "../../../services/DocService";
import {ENUM_NAME, TASK_STATUS} from "../../../Enums";

class LinearReport extends React.PureComponent {

    constructor(props) {
        super(props);
        this.columns = this.columns.bind(this);
    }

    fetch(params) {
        params.taskTypes = [TaskType.TYPE_EPIC(), TaskType.TYPE_DEFAULT()];
        return reportService.linearReport(params);
    }

    fetchXLS(rows) {
        return docService.getTaskLinearReport(rows);
    }

    descriptionFormatter(value, row) {
        return value ? value.replace(/<\/?[^>]+(>|$)/g, "") : "";
    }

    columns() {
        return [
            { title: "Номер задачи", name: "taskId", className: "id" },
            { title: "Дата задачи", name: "taskCreatedAt", type: "datetime", formatter: TableCell.dtFormatter, hideFilter: true },
            { title: "Статус задачи", name: "taskStatus", optionsType: ENUM_NAME.TASK_STATUS, type: "enum", formatter: TableCell.enumFormatter(TASK_STATUS), multiSelect: true },
            { title: "Автор", name: "taskOwner", type: "dictionary", optionsType: "CRM_USER", activeOnly: false},
            { title: "Тема", name: "taskTopic", type: "dictionary", optionsType: "TOPIC" },
            { title: "Начало задачи", name: "taskStartDate", type: "datetime", formatter: TableCell.dtFormatter },
            { title: "Конец задачи", name: "taskDueDate", type: "datetime", formatter: TableCell.dtFormatter },
            { title: "Ответственный", name: "taskAssignee", type: "dictionary", optionsType: "CRM_USER", activeOnly: false },
            { title: "Подразделение задачи", name: "taskDepartment", type: "dictionary", optionsType: "DEPARTMENT", activeOnly: false, multiSelect: true },
            { title: "Отдел задачи", name: "taskDivision", optionsType: "DIVISION", type: "dictionary", activeOnly: false },
            { title: "Заявка", name: "quoteNumber" },
            { title: "Дата заявки", name: "quoteCreatedAt", type: "datetime", formatter: TableCell.dtFormatter },
            { title: "Подразделение заявки", name: "quoteDepartment", type: "dictionary", optionsType: "DEPARTMENT", activeOnly: false },
            { title: "Заказчик", name: "client", type: "dictionary", optionsType: "CONTRACTOR_CLIENT", activeOnly: false },
            { title: "Ставка заказчика", name: "clientPrice", type: "decimal" },
            { title: "Перевозчик", name: "carrier", type: "dictionary", optionsType: "CONTRACTOR_CARRIER", activeOnly: false },
            { title: "Ставка перевозчика", name: "carrierPrice", type: "decimal" },
            { title: "Описание задачи", name: "taskDescription", type: "dictionary", formatter: this.descriptionFormatter.bind(this), className: "overflow", hideFilter: true },
            { title: "Дата перевода ответственным на проверку", name: "taskReviewAssignmentDate", type: "datetime", formatter: TableCell.dtFormatter },
            { title: "Дата выполнения задачи", name: "taskDoneDate", type: "datetime", formatter: TableCell.dtFormatter }
        ];
    }

    render() {
        return (
          <Report {...this.props}
              title = {"Линейный отчёт"}
              fetch = {this.fetch.bind(this)}
              columns = {this.columns().filter(col => { return Object.keys(col).length !== 0 })}
              from = {{ title: "С", name: "taskCreatedAtFrom", type: "datetime" }}
              to = {{ title: "По", name: "taskCreatedAtTo", type: "datetime" }}
              fetchXLS = {this.fetchXLS.bind(this)}
              requiredFields={["taskCreatedAtFrom", "taskCreatedAtTo"]}
          />
        );
    }
}

export default LinearReport;
